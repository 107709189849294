import React from "react";
import { Select, message } from "antd";
import { api } from "../config";

export default class OnlineTestQuestionTopicDropdown extends React.Component {
  state = {
    topics: [],
    subject_id: undefined,
  };

  static getDerivedStateFromProps(props, state) {
    if ("subject_id" in props && props.subject_id !== undefined) {
      return { subject_id: props.subject_id };
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.subject_id !== this.state.subject_id) {
      this.get_topics_list(this.state.subject_id);
    }
  }

  componentDidMount() {
    this.get_topics_list();
  }

  get_topics_list = (subject_id) => {
    let url = "/ot/question/topics/" + (subject_id ? subject_id + "/" : "");
    api
      .get(url)
      .then((res) => {
        if (res.data.success) {
          this.setState({ topics: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const { topics } = this.state;
    const { Option } = Select;
    return (
      <Select
        placeholder="Topic"
        allowClear
        style={{ width: 160, maxWidth: "100%" }}
        {...this.props}
      >
        {topics.map((item, index) => {
          return (
            <Option key={index} value={item.topic}>
              {item.topic}
            </Option>
          );
        })}
      </Select>
    );
  }
}
