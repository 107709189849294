import React, { Component } from "react";
import { Layout, Row, Col, Card } from "antd";
import { connect } from "react-redux";
import StudentProfile from "./profile/StudentProfile";
import ChangePassword from "./ChangePassword";

class ProfileScreen extends Component {
  state = {};

  componentDidMount() {}

  render() {
    const role = this.props.user.role;
    return (
      <Layout.Content>
        <h1 style={{ textAlign: "center" }}>User Profile</h1>
        <Row gutter={32}>
          <Col sm={8}>
            <Card title="Change Password">
              <ChangePassword />
            </Card>
          </Col>
          <Col sm={16}>
            {role === 1 ? (
              <Card title="Profile">
                <StudentProfile user={this.props.user} />
              </Card>
            ) : null}
          </Col>
        </Row>
      </Layout.Content>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user
  };
}

export default connect(mapStateToProps, null)(ProfileScreen);
