import React from "react";
import { Switch, Route } from "react-router-dom";
import AnnouncementsList from "../../admin/announcements/AnnouncementsList";
import NewAnnouncements from "../../admin/announcements/NewAnnouncements";

const TeacherAnnouncementRouter = props => (
  <Switch>
    <Route exact path="/announcements" component={AnnouncementsList} />
    <Route exact path="/announcements/new" component={NewAnnouncements} />
  </Switch>
);

export { TeacherAnnouncementRouter };
