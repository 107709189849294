import React, { Component } from "react";
import { Layout, Row, Col, Button, Table, message, Icon } from "antd";
import { Link } from "react-router-dom";
import { api } from "../../../config";
import moment from "moment";

class ExamsList extends Component {
  state = {
    exams: [],
  };

  componentDidMount() {
    this.get_exams_list();
  }

  get_exams_list = () => {
    api
      .get("/exams/")
      .then((res) => {
        if (res.data.success) {
          this.setState({ exams: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  _delete_exam = (id) => {
    api
      .delete(`/exam/${id}/`)
      .then((res) => {
        if (res.data.success) {
          message.success("Exam deleted");
          this.get_exams_list();
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const columns = [
      { title: "ID", dataIndex: "id" },
      { title: "Name", dataIndex: "name" },
      {
        title: "Duration",
        dataIndex: "max_duration",
        render: (v) => <span>{v / 60} mins</span>,
      },
      {
        title: "Start Time",
        dataIndex: "start_time",
        render: (v, r) => (
          <span>
            {v ? moment.utc(v).local().format("YYYY-MM-DD HH:mm A") : " - "}
          </span>
        ),
      },
      {
        title: "Timestamp",
        dataIndex: "timestamp",
        render: (v, r) => (
          <span>{moment.utc(v).local().format("YYYY-MM-DD HH:mm A")}</span>
        ),
      },
      {
        title: "Actions",
        render: (r) => (
          <span>
            <Icon
              type="question-circle"
              className="action-icon"
              onClick={() => {
                this.props.history.push("/exam/questions/" + r.id + "/");
              }}
              title="Questions"
            />
            {/* <Icon type="check-circle" className="action-icon" /> */}
            <Icon
              type="ordered-list"
              className="action-icon"
              title="Test Assignments"
              onClick={() => {
                this.props.history.push("/exam/assign/" + r.id + "/");
              }}
            />
            <Icon
              type="delete"
              className="action-icon absent"
              onClick={() => this._delete_exam(r.id)}
            />
          </span>
        ),
      },
    ];
    return (
      <Layout.Content>
        <Row>
          <Col sm={12}>
            <h1>Exams</h1>
          </Col>
          <Col sm={12} style={{ textAlign: "right" }}>
            <Link to="/exam/new">
              <Button type="primary" icon="plus">
                New Exam
              </Button>
            </Link>
          </Col>
        </Row>
        <Table columns={columns} dataSource={this.state.exams} rowKey="id" />
      </Layout.Content>
    );
  }
}

export default ExamsList;
