import React, { Component } from "react";
import { Layout, Row, Col, Card, Button, message, notification } from "antd";
import { api, baseurl } from "../../../../config";

class ExportsList extends Component {
  state = {
    student_url: "",
    student_loading: false,
    fees_url: "",
    fees_loading: false
  };

  handleStudentExport = () => {
    this.setState({ student_loading: true });
    api
      .get("/export/students/")
      .then(res => {
        if (res.data && res.data.data) {
          this.setState({
            student_url: baseurl + "/" + res.data.data.path,
            student_loading: false
          });
          notification["success"]({
            message: "Export Completed",
            description: "You can now download the exported file"
          });
        } else {
          message.error(res.data.error);
          this.setState({ student_loading: false });
          notification["error"]({
            message: "Export Failed",
            description: res.data.error
          });
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ student_loading: false });
      });
  };

  handleFeesExport = () => {
    this.setState({ fees_loading: true });
    api
      .get("/export/fees/")
      .then(res => {
        if (res.data && res.data.data) {
          this.setState({
            fees_url: baseurl + "/" + res.data.data.path,
            fees_loading: false
          });
          notification["success"]({
            message: "Export Completed",
            description: "You can now download the exported file"
          });
        } else {
          message.error(res.data.error);
          this.setState({ fees_loading: false });
          notification["error"]({
            message: "Export Failed",
            description: res.data.error
          });
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ fees_loading: false });
      });
  };

  render() {
    const { student_url, student_loading, fees_loading, fees_url } = this.state;
    return (
      <Layout.Content>
        <h1>Exports</h1>
        <Row gutter={32}>
          <Col sm={6}>
            <div>
              <Card title="Export Student Data">
                <Button
                  type="primary"
                  style={{ marginRight: 20 }}
                  onClick={this.handleStudentExport}
                  loading={student_loading}
                >
                  Export
                </Button>
                <a href={student_url} target="_blank" rel="noopener noreferrer">
                  <Button type="ghost" disabled={student_url ? false : true}>
                    Download
                  </Button>
                </a>
              </Card>
            </div>
          </Col>
          <Col sm={6}>
            <div>
              <Card title="Export Fees Data">
                <Button
                  type="primary"
                  style={{ marginRight: 20 }}
                  onClick={this.handleFeesExport}
                  loading={fees_loading}
                >
                  Export
                </Button>
                <a href={fees_url} target="_blank" rel="noopener noreferrer">
                  <Button type="ghost" disabled={fees_url ? false : true}>
                    Download
                  </Button>
                </a>
              </Card>
            </div>
          </Col>
        </Row>
      </Layout.Content>
    );
  }
}

export default ExportsList;
