import React, { useState, useEffect } from "react";
import { Select } from "antd";
import { api } from "../config";

export default function EmployeeDropdown(props) {
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    api
      .get("/hr/employees/")
      .then((res) => {
        if (res.data.success) {
          setEmployees(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Select
      placeholder="Select Employees"
      showSearch
      filterOption={(input, option) =>
        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      allowClear
      {...props}
    >
      {employees.map((item, index) => {
        return (
          <Select.Option value={item.id} key={index}>
            {item.name}
          </Select.Option>
        );
      })}
    </Select>
  );
}
