import React, { Component } from "react";
import { Layout, Switch, message, Table, Row, Col, Button } from "antd";
import { connect } from "react-redux";
import { api } from "../../../../config";

class AdminModules extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: undefined,
      modules: [],
    };
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.setState({ id: this.props.match.params.id }, this.get_modules_list);
    }
  }

  get_modules_list = () => {
    let { id } = this.state;
    api
      .get("/admin/modules/" + id + "/")
      .then((res) => {
        if (res.data.success) {
          this.setState({ modules: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  setChecked = (index, checked) => {
    let { modules } = this.state;
    modules[index].create = checked;
    modules[index].read = checked;
    modules[index].update = checked;
    modules[index].delete = checked;
    this.setState({ modules });
  };

  handleSave = () => {
    let data = {
      user_id: this.state.id,
      data: this.state.modules,
    };
    api
      .post("/admin/modules/", JSON.stringify(data))
      .then((res) => {
        if (res.data.success) {
          message.success("Modules Access Saved");
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const { modules } = this.state;
    const columns = [
      { title: "Sl. No.", render: (v, r, i) => <span>{i + 1}</span> },
      { title: "ID", dataIndex: "id" },
      { title: "Module Name", dataIndex: "module_name" },
      {
        title: "Access",
        render: (v, r, i) => {
          if (r.can_disable === 0) {
            r.create = r.read = r.update = r.delete = true;
          }
          return (
            <span>
              <Switch
                disabled={r.can_disable === 0}
                checked={r.can_disable === 0 || r.create}
                onChange={this.setChecked.bind(this, i)}
              />
            </span>
          );
        },
      },
    ];
    return (
      <Layout.Content>
        <Row>
          <Col sm={12}>
            <h1>Admin Modules Access List</h1>
          </Col>
          <Col sm={12} className="action-buttons">
            <Button
              icon="save"
              type="primary"
              className="action-button"
              onClick={this.handleSave}
            >
              Save
            </Button>
          </Col>
        </Row>
        <Table
          columns={columns}
          dataSource={modules}
          rowKey="id"
          pagination={false}
        />
      </Layout.Content>
    );
  }
}

function mapStateToProps(state) {
  return {
    about_school: state.about_school,
  };
}

export default connect(mapStateToProps, null)(AdminModules);
