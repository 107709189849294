import React, { Component } from "react";
import { Layout, Menu, Icon } from "antd";
import { UserHeaderMenu, PageFooter } from "./HeaderFooter";
import { TeacherRouter } from "../../config/Router";
import { Link } from "react-router-dom";

const width = window.innerWidth;
const { Sider } = Layout;

class TeacherLayout extends Component {
  state = {
    collapsed: false,
    selectedKeys: [],
  };

  onCollapse = (collapsed) => {
    this.setState({ collapsed });
  };

  onSelect = ({ item, key, keyPath, selectedKeys, e }) => {
    if (width < 480) {
      this.setState({ collapsed: true, selectedKeys });
    } else {
      this.setState({ selectedKeys });
    }
  };

  render() {
    return (
      <Layout>
        <UserHeaderMenu />
        <Layout style={{ minHeight: "100vh" }}>
          <TeacherSiderMenu
            collapsed={this.state.collapsed}
            onCollapse={this.onCollapse}
            onSelect={this.onSelect}
            selectedKeys={this.state.selectedKeys}
          />
          <Layout className="box-container">
            <TeacherRouter />
          </Layout>
        </Layout>
        <PageFooter />
      </Layout>
    );
  }
}

const TeacherSiderMenu = (props) => (
  <Sider
    width={212}
    breakpoint="md"
    collapsible
    collapsed={props.collapsed}
    onCollapse={props.onCollapse}
    collapsedWidth={width < 480 ? 0 : 80}
    theme="light"
    style={{
      boxShadow: "2px 0px 2px #e8e8e8",
      borderRight: "1px solid #e8e8e8",
    }}
  >
    <div className="sider-fixed-container">
      <Menu
        mode="inline"
        theme="light"
        onSelect={props.onSelect}
        selectedKeys={props.selectedKeys}
      >
        <Menu.Item key="1">
          <Link to="/">
            <Icon type="dashboard" /> <span>Dashboard</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="2">
          <Link to="/students/">
            <Icon type="user" /> <span>Students</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="8">
          <Link to="/academics/tests/">
            <Icon type="check-square" /> <span>Tests</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="3">
          <Link to="/marks/">
            <Icon type="percentage" /> <span>Marks</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="4">
          <Link to="/notes/">
            <Icon type="file-pdf" /> <span>Notes</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="5">
          <Link to="/attendance/">
            <Icon type="calendar" /> <span>Attendance</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="6">
          <Link to="/timetable">
            <Icon type="carry-out" /> <span>Timetable</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="7">
          <Link to="/announcements">
            <Icon type="notification" /> <span>Announcements</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="9">
          <Link to="/messages">
            <Icon type="message" /> <span>Messages</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="10">
          <Link to="/homework/">
            <Icon type="home" /> <span>Homework</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="11">
          <Link to="/question-paper/">
            <Icon type="file-unknown" /> <span>Question Paper</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="28">
          <Link to="/simple-test/">
            <Icon type="check" /> <span>Simple Online Test</span>
          </Link>
        </Menu.Item>
        <Menu.SubMenu
          title={
            <span>
              <Icon type="check" />
              <span>Online Test</span>
            </span>
          }
        >
          <Menu.Item key="ot-1">
            <Link to="/online-test/">
              <span>Test List</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="ot-2">
            <Link to="/online-test/questions">
              <span>Questions</span>
            </Link>
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.Item key="12">
          <Link to="/virtual-classroom/">
            <Icon type="solution" /> <span>Virtual Classroom</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="13">
          <Link to="/meeting/">
            <Icon type="video-camera" /> <span>Meeting</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="30">
          <Link to="/payroll">
            <Icon type="dollar" /> <span>Payroll</span>
          </Link>
        </Menu.Item>
      </Menu>
    </div>
  </Sider>
);

export default TeacherLayout;
