import React, { Component } from "react";
import {
  Layout,
  message,
  Table,
  Row,
  Col,
  Button,
  Icon,
  Spin,
  Modal,
  Popconfirm,
  Dropdown,
  Menu,
} from "antd";
import { Link } from "react-router-dom";
import { api } from "../../../../config";
import { Currency } from "../../../../components";
import UpdateFeeDiscount from "./UpdateFeeDiscount";

var moment = require("moment");

class FeePaymentRecord extends Component {
  state = {
    student_id: null,
    student: {},
    fees: [],
    spinning: false,
    sms_loading: false,
    fee_discount_modal: false,
    fee: {},
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      this.get_fees_history(this.props.match.params.id);
      this.setState({ student_id: this.props.match.params.id });
    }
  }

  get_fees_history = (student_id) => {
    this.setState({ spinning: true });
    api
      .get("/fees/history/" + student_id + "/")
      .then((res) => {
        if (res.data && res.data.success) {
          this.setState({
            fees: res.data.data.fees,
            student: res.data.data.student,
          });
        } else {
          message.error(res.data.error);
        }
        this.setState({ spinning: false });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ spinning: false });
      });
  };

  send_fee_due_sms = (record) => {
    console.log(record);
    let due = record.payment.total_amount - record.payment.paid_amount;
    let that = this;
    Modal.confirm({
      title: "Do you want to send fees due sms?",
      content: "Due amount : ₹" + due,
      confirmLoading: this.state.sms_loading,
      onOk() {
        let id = record.payment.id;
        that.setState({ sms_loading: true });
        api
          .get("/fees/due/alert/" + id + "/")
          .then((res) => {
            if (res.data && res.data.success) {
              message.success("Alert SMS Sent");
            } else {
              message.error(res.data.error);
            }
            that.setState({ sms_loading: false });
          })
          .catch((err) => {
            console.log(err);
            that.setState({ sms_loading: false });
          });
      },
      onCancel() {},
    });
  };

  _deleteFeeEntry = (record) => {
    api
      .delete(`/fees/pay/${record.payment.id}/`)
      .then((res) => {
        if (res.data.success) {
          message.success("Fee Entry Deleted");
          this.get_fees_history(this.state.student_id);
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  __closeModal = () => {
    this.setState({ fee_discount_modal: false });
    this.get_fees_history(this.state.student_id);
  };

  render() {
    const data = this.state.fees;
    const { student, fee_discount_modal, fee } = this.state;
    const expandedRowRender = (record) => {
      const columns = [
        {
          title: "Name",
          render: (item, record, index) => <span>Installment {index + 1}</span>,
        },
        { title: "Amount Paid", dataIndex: "paid_amount" },
        { title: "Payment Mode", dataIndex: "mode" },
        {
          title: "Date",
          render: (record) => (
            <span>{moment(record.timestamp).format("DD-MM-YYYY")}</span>
          ),
        },
      ];
      return (
        <Table
          columns={columns}
          dataSource={record.installments}
          pagination={false}
          rowKey="id"
        />
      );
    };
    const columns = [
      { title: "Acadamic Year", dataIndex: "payment.ac_year_name" },
      { title: "Name", dataIndex: "payment.name" },
      {
        title: "Total Fee Amount",
        dataIndex: "payment.total_amount",
        align: "right",
        render: (v) => <Currency value={v} />,
      },
      {
        title: "Amount Paid",
        dataIndex: "payment.paid_amount",
        align: "right",
        render: (v) => <Currency value={v} />,
      },
      {
        title: "Discount Amount",
        dataIndex: "payment.discount_amount",
        align: "right",
        render: (v) => <Currency value={v} />,
      },
      {
        title: "Amount Due",
        align: "right",
        render: (r) => (
          <Currency value={r.payment.total_amount - r.payment.paid_amount} />
        ),
      },
      {
        title: "Date",
        render: (record) => (
          <span>{moment(record.payment.timestamp).format("DD-MM-YYYY")}</span>
        ),
      },
      {
        title: "Actions",
        render: (record) => (
          <span>
            <Icon
              type="printer"
              className="action-icon"
              onClick={() => {
                this.props.history.push("/fees/receipt/" + record.payment.id);
              }}
            />
            {record.payment.total_amount - record.payment.paid_amount > 0 && (
              <Icon
                type="message"
                className="action-icon"
                title="Send Due SMS"
                onClick={this.send_fee_due_sms.bind(this, record)}
              />
            )}
            <Popconfirm
              title="Are you sure?"
              onConfirm={this._deleteFeeEntry.bind(this, record)}
            >
              <Icon
                type="delete"
                title="Delete Fee Record"
                className="action-icon absent"
              />
            </Popconfirm>
            <span>
              <Dropdown
                overlay={() => (
                  <Menu>
                    <Menu.Item
                      onClick={() =>
                        this.props.history.push(
                          `/fees/pay/installment/${record.payment.id}/`
                        )
                      }
                      disabled={
                        record.payment.total_amount -
                          record.payment.paid_amount <
                        0
                      }
                    >
                      Pay Installment
                    </Menu.Item>
                    <Menu.Item
                      onClick={() =>
                        this.props.history.push(
                          `/fees/refund/${record.payment.id}/`
                        )
                      }
                      disabled={record.payment.paid_amount <= 0}
                    >
                      Refund
                    </Menu.Item>
                    <Menu.Item
                      onClick={() =>
                        this.setState({
                          fee: record,
                          fee_discount_modal: true,
                        })
                      }
                    >
                      Discount
                    </Menu.Item>
                    <Menu.Item disabled={true}>Forfeit</Menu.Item>
                  </Menu>
                )}
              >
                <Button size="small" type="primary" ghost>
                  Actions
                </Button>
              </Dropdown>
            </span>
          </span>
        ),
      },
    ];
    return (
      <Layout.Content>
        <Row>
          <Col sm={12}>
            <h1>Fee Payment Record</h1>
          </Col>
          <Col sm={12} style={{ textAlign: "right" }}>
            <Link to={"/fees/pay/" + this.state.student_id + "/"}>
              <Button type="primary" icon="plus">
                Pay Fees
              </Button>
            </Link>
          </Col>
        </Row>
        <Spin spinning={this.state.spinning}>
          <div style={{ display: "flex", marginBottom: 20, gap: 20 }}>
            <div>
              <strong>Student Name : </strong>
              {student.fullname}
            </div>
            <div>
              <strong>Admission No : </strong>
              {student.admission_no}
            </div>
            <div>
              <strong>Roll No : </strong>
              {student.roll_no}
            </div>
            <div>
              <strong>Class : </strong>
              {student.class + " " + student.section}
            </div>
          </div>
          <Table
            columns={columns}
            dataSource={data}
            expandedRowRender={expandedRowRender}
            rowKey="payment.id"
          />
        </Spin>
        {fee_discount_modal && (
          <UpdateFeeDiscount fee={fee} close={this.__closeModal} />
        )}
      </Layout.Content>
    );
  }
}

export default FeePaymentRecord;
