import React, { Component } from "react";
import {
  Layout,
  Table,
  message,
  Popconfirm,
  Icon,
  Row,
  Col,
  Button,
  Spin,
} from "antd";
import { api } from "../../../../config";

var moment = require("moment");

class AdminsList extends Component {
  state = {
    admins_list: [],
    spinning: false,
  };

  componentDidMount() {
    this.get_admins_list();
  }

  get_admins_list = () => {
    this.setState({ spinning: true });
    api
      .get("/admins/")
      .then((res) => {
        if (res.data && res.data.success) {
          this.setState({ admins_list: res.data.data });
        } else {
          message.error(res.data.error);
        }
        this.setState({ spinning: false });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ spinning: false });
      });
  };

  render() {
    const columns = [
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
      },
      {
        title: "Registration Date",
        render: (record) => (
          <span>{moment(record.registration).format("DD-MM-YYYY")}</span>
        ),
      },
      {
        title: "Actions",
        render: (record) => (
          <span>
            <Icon
              type="bars"
              className="action-icon"
              onClick={() =>
                this.props.history.push(
                  `/admin-actions/admin/${record.id}/modules/access`
                )
              }
            />
            <Popconfirm title="Are you sure?">
              <Icon type="delete" className="action-icon" />
            </Popconfirm>
          </span>
        ),
      },
    ];
    return (
      <Layout.Content>
        <Row>
          <Col sm={8}>
            <h1>Admins List</h1>
          </Col>
          <Col sm={12} />
          <Col sm={4}>
            <Button
              icon="plus"
              type="primary"
              onClick={() => {
                this.props.history.push("/admin-actions/admin/new");
              }}
            >
              Add New Admin
            </Button>
          </Col>
        </Row>
        <Spin spinning={this.state.spinning}>
          <Table
            columns={columns}
            dataSource={this.state.admins_list}
            key="id"
          />
        </Spin>
      </Layout.Content>
    );
  }
}

export default AdminsList;
