import React, { Component } from "react";
import { Layout, Menu, Icon } from "antd";
import { UserHeaderMenu, PageFooter } from "./HeaderFooter";
import { LibrarianRouter } from "../../config/Router";
import { Link } from "react-router-dom";

const width = window.innerWidth;
const { Sider } = Layout;

class LibrarianLayout extends Component {
  state = {
    collapsed: false,
  };

  onCollapse = (collapsed) => {
    this.setState({ collapsed });
  };

  render() {
    return (
      <Layout>
        <UserHeaderMenu />
        <Layout style={{ minHeight: "100vh" }}>
          <LibrarianSiderMenu
            collapsed={this.state.collapsed}
            onCollapse={this.onCollapse}
          />
          <Layout className="box-container">
            <LibrarianRouter />
          </Layout>
        </Layout>
        <PageFooter />
      </Layout>
    );
  }
}

const LibrarianSiderMenu = (props) => (
  <Sider
    width={200}
    breakpoint="md"
    collapsible
    collapsed={props.collapsed}
    onCollapse={props.onCollapse}
    collapsedWidth={width < 480 ? 0 : 80}
    zeroWidthTriggerStyle={{ position: "fixed" }}
  >
    <Menu mode="inline" theme="dark">
      <Menu.Item key={1}>
        <Link to="/">
          <Icon type="dashboard" /> <span>Dashboard</span>
        </Link>
      </Menu.Item>
      <Menu.SubMenu
        key="library"
        title={
          <span>
            <Icon type="book" />
            <span>Library</span>
          </span>
        }
      >
        <Menu.Item key={2}>
          <Link to="/library/books/">Books</Link>
        </Menu.Item>
        <Menu.Item key={3}>
          <Link to="/library/issued/">Issued</Link>
        </Menu.Item>
        <Menu.Item key={4}>
          <Link to="/library/reports/">Reports</Link>
        </Menu.Item>
      </Menu.SubMenu>
    </Menu>
  </Sider>
);

export default LibrarianLayout;
