import { Button, Card, Layout, message } from "antd";
import React, { Component } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { api } from "../../../config";

class WebAnnouncements extends Component {
  state = {
    data: "",
    saving: false,
  };

  componentDidMount() {
    this._get_web_announcement();
  }

  _get_web_announcement = () => {
    api
      .get("/announcements/web/")
      .then((res) => {
        if (res.data.success) {
          this.setState({ data: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  _handle_save = () => {
    let data = {
      data: this.state.data,
    };
    api
      .post("/announcements/web/", JSON.stringify(data))
      .then((res) => {
        if (res.data.success) {
          message.success("Announcement data saved");
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const { data, saving } = this.state;
    return (
      <Layout.Content>
        <Card
          title="Web Announcements"
          extra={[
            <Button
              size="small"
              icon="save"
              type="primary"
              loading={saving}
              onClick={this._handle_save}
            >
              Save
            </Button>,
          ]}
        >
          <ReactQuill
            theme="snow"
            value={data}
            onChange={(data) => this.setState({ data })}
          />
        </Card>
      </Layout.Content>
    );
  }
}

export default WebAnnouncements;
