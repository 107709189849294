import {
  Button,
  Card,
  Col,
  DatePicker,
  Layout,
  message,
  Row,
  Table,
} from "antd";
import React, { Component } from "react";
import { Pie } from "react-chartjs-2";
import {
  AcademicYearDropdown,
  ClassSectionDropdown,
  Currency,
} from "../../../../components";
import { api } from "../../../../config";

const colors = [
  "#7cb5ec",
  "#434348",
  "#90ed7d",
  "#f7a35c",
  "#8085e9",
  "#f15c80",
  "#e4d354",
  "#2b908f",
  "#f45b5b",
  "#91e8e1",
];

class FeeItemReports extends Component {
  state = {
    fee_item: {},
    class_id: undefined,
    ac_year_id: undefined,
    date: [],
    fee_items: [],
    pie_chart: {},
    total_amount: 0.0,
  };

  componentDidMount() {
    if (this.props.location.state) {
      this.setState({ fee_item: this.props.location.state });
    }
  }

  _handleGo = () => {
    let { class_id, ac_year_id, date } = this.state;
    let start_date = (date[0] && date[0].format("YYYY-MM-DD")) || "";
    let end_date = (date[1] && date[1].format("YYYY-MM-DD")) || "";
    let url = `/reports/fees/items/?class_id=${class_id || ""}&ac_year_id=${
      ac_year_id || ""
    }&start_date=${start_date}&end_date=${end_date}`;
    api
      .get(url)
      .then((res) => {
        if (res.data.success) {
          this._rearrange_chart_data(res.data.data);
          this.setState({ fee_items: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  _rearrange_chart_data = (data) => {
    let piedata = [],
      labels = [],
      total = 0.0;
    data.forEach((item) => {
      piedata.push(item.total_amount);
      labels.push(item.fee_item_name);
      total += item.total_amount;
    });
    let chart_data = {
      datasets: [
        { data: piedata, backgroundColor: colors.slice(0, piedata.length) },
      ],
      labels,
    };
    this.setState({ pie_chart: chart_data, total_amount: total });
  };

  render() {
    const { class_id, ac_year_id, date, fee_items, pie_chart } = this.state;
    const columns = [
      { title: "Sl. No.", render: (v, r, i) => <span>{i + 1}</span> },
      { title: "Fee Item Name", dataIndex: "fee_item_name" },
      {
        title: "Total Amount",
        dataIndex: "total_amount",
        align: "right",
        render: (v) => <Currency value={v} />,
      },
      { title: "Quantity", dataIndex: "quantity", align: "right" },
      { title: "Count", dataIndex: "count", align: "right" },
    ];
    return (
      <Layout.Content>
        <h1>Fee Item Reports</h1>
        <Row gutter={16} style={{ marginBottom: 16 }}>
          <Col>
            <span>
              <strong>AC Year : </strong>
              <AcademicYearDropdown
                value={ac_year_id}
                onChange={(v) => this.setState({ ac_year_id: v })}
              />
            </span>
            <span>
              <strong> Class : </strong>
              <ClassSectionDropdown
                value={class_id}
                onChange={(v) => this.setState({ class_id: v })}
              />
            </span>
            <span>
              <strong> Date Range : </strong>
              <DatePicker.RangePicker
                value={date}
                onChange={(v) => this.setState({ date: v })}
              />
            </span>
            <Button
              type="primary"
              ghost
              style={{ marginLeft: 10 }}
              onClick={this._handleGo}
            >
              Go
            </Button>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col sm={12}>
            <Card>
              <Table
                columns={columns}
                dataSource={fee_items}
                rowKey="id"
                size="small"
              />
            </Card>
          </Col>
          <Col sm={12}>
            <Card>
              <Pie data={pie_chart} />
            </Card>
          </Col>
        </Row>
      </Layout.Content>
    );
  }
}

export default FeeItemReports;
