import React, { Component } from "react";
import {
  Layout,
  Table,
  Icon,
  message,
  Row,
  Col,
  Button,
  Popconfirm,
} from "antd";
import { api } from "../../../../config";
import { Link } from "react-router-dom";
import { ShowBreadcrumbs } from "../../../../components";

class BranchesList extends Component {
  state = {
    branches: [],
    spinning: false,
  };

  componentDidMount() {
    this.get_branches();
  }

  get_branches = () => {
    api
      .get("/branches/")
      .then((res) => {
        if (res.data.success) {
          this.setState({ branches: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleDelete = (id) => {
    api
      .delete("/branch/" + id + "/")
      .then((res) => {
        if (res.data.success) {
          message.success("Branch Deleted");
          this.get_branches();
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const columns = [
      { title: "ID", dataIndex: "id" },
      { title: "Name", dataIndex: "name" },
      { title: "Code", dataIndex: "code" },
      {
        title: "Action",
        render: (record) => (
          <span>
            <Icon
              type="edit"
              className="action-icon"
              onClick={() => {
                this.props.history.push("/academics/branch/" + record.id + "/");
              }}
              title="Edit"
            />
            <Popconfirm
              title="Are you sure?"
              onConfirm={this.handleDelete.bind(this, record.id)}
            >
              <Icon
                type="delete"
                className="action-icon absent"
                title="Delete"
              />
            </Popconfirm>
          </span>
        ),
      },
    ];
    return (
      <Layout.Content>
        <ShowBreadcrumbs
          items={[
            { name: "Home", link: "/" },
            { name: "Academics", link: "/academics/" },
            { name: "Branches", link: "/academics/branches/" },
          ]}
        />
        <Row className="ribbon-row">
          <Col sm={12}>
            <h1>Branches List</h1>
          </Col>
          <Col sm={12} style={{ textAlign: "right" }}>
            <Link to="/academics/branch/new">
              <Button type="primary" icon="plus" className="action-button">
                New Branch
              </Button>
            </Link>
          </Col>
        </Row>
        <Table columns={columns} dataSource={this.state.branches} rowKey="id" />
      </Layout.Content>
    );
  }
}

export default BranchesList;
