import React, { Component } from "react";
import { Layout, message, Spin, InputNumber, Radio, Input, Button } from "antd";
import { api } from "../../../../config";

class FeeRefund extends Component {
  state = {
    fees: {},
    spinning: true,
    fee_id: null,
    loading: false,
    payment: { amount_paid: 0, mode: "cheque", extras: {} }
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      this.get_fees_details(this.props.match.params.id);
      this.setState({ fee_id: this.props.match.params.id });
    }
  }

  get_fees_details = fee_id => {
    api
      .get("/fees/receipt/" + fee_id + "/")
      .then(res => {
        if (res.data && res.data.success) {
          this.setState({ fees: res.data.data, spinning: false });
        } else {
          message.error(res.data.error);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  handleRefund = () => {
    const { fees, payment, fee_id } = this.state;
    var data = { payment_id: fee_id, ...payment };
    if (!payment.amount_paid) {
      message.error("Refund amount is required!");
      return;
    }
    if (!payment.extras.transaction_details) {
      if (payment.mode !== "cash") {
        message.error("Transaction Details is requried!");
        return;
      }
    }
    if (fees.payment.amount_paid < payment.amount_paid) {
      message.error("Refund amount cannot be greater than amount paid");
      return;
    }
    this.setState({ loading: true });
    api
      .put("/fees/refund/", JSON.stringify(data))
      .then(res => {
        if (res.data.success) {
          message.success("Refund Data Updated");
          this.props.history.push("/fees/receipt/" + data.payment_id + "/");
        } else {
          message.error(res.data.error);
        }
        this.setState({ loading: false });
      })
      .catch(err => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  render() {
    const { payment, installments, student } = this.state.fees;
    return (
      <Layout.Content>
        <h1 style={{ textAlign: "center" }}>Refund Fees</h1>
        <Spin spinning={this.state.spinning}>
          {!this.state.spinning && (
            <div className="installment-table">
              <table>
                <tbody>
                  <tr>
                    <td>Student Name : </td>
                    <td>{student.fullname}</td>
                  </tr>
                  <tr>
                    <td>Student Admission No. : </td>
                    <td>{student.admission_no}</td>
                  </tr>
                  <tr>
                    <td>Total Fees Amount : </td>
                    <td>₹ {payment.total_amount}</td>
                  </tr>
                  <tr>
                    <td>Amount Paid : </td>
                    <td>₹ {payment.paid_amount}</td>
                  </tr>
                  {installments.map((item, index) => {
                    return (
                      <tr>
                        <td>Installment {index + 1} : </td>
                        <td>₹ {item.paid_amount}</td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td>
                      <b>Amount Pending : </b>
                    </td>
                    <td>
                      <b>₹ {payment.total_amount - payment.paid_amount}</b>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Refund Amount : </b>
                    </td>
                    <td>
                      <InputNumber
                        onChange={val => {
                          let payment = this.state.payment;
                          payment.amount_paid = val;
                          this.setState({ payment });
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Refund Mode</td>
                    <td>
                      <Radio.Group
                        defaultValue="cash"
                        onChange={e => {
                          let payment = this.state.payment;
                          payment.mode = e.target.value;
                          this.setState({ payment });
                        }}
                      >
                        <Radio value="cash">Cash</Radio>
                        <Radio value="cheque">Cheque</Radio>
                        <Radio value="transfer">NEFT/RTGS/IMPS</Radio>
                        <Radio value="upi">UPI</Radio>
                      </Radio.Group>
                    </td>
                  </tr>
                  <tr>
                    <td>Transaction Details</td>
                    <td>
                      <Input
                        onChange={e => {
                          let payment = this.state.payment;
                          payment.extras.transaction_details = e.target.value;
                          this.setState({ payment });
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>
                      <Button
                        className="pay-button"
                        type="primary"
                        loading={this.state.loading}
                        onClick={this.handleRefund}
                      >
                        Refund Fees
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </Spin>
      </Layout.Content>
    );
  }
}

export default FeeRefund;
