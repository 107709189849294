import React, { useEffect, useState } from "react";

export default function Currency({ value, currency, style, options }) {
  const [amount, setAmount] = useState(undefined);

  useEffect(() => {
    if (!isNaN(parseFloat(value))) {
      let v = parseFloat(value);
      const c = new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: currency || "INR",
        ...(options || {}),
      }).format(v);
      setAmount(c);
    }
  }, [value]);

  return <span style={style}>{amount}</span>;
}
