import React, { Component } from "react";
import {
  Layout,
  message,
  Card,
  Descriptions,
  InputNumber,
  Form,
  Button,
  Input,
} from "antd";
import { api } from "../../../config";
import { connect } from "react-redux";

class OnlineTestAnswers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      test_id: undefined,
      test_attempt_id: undefined,
      answers: [],
    };
  }

  componentDidMount() {
    let { test_attempt_id, test_id } = this.props.match.params;
    if (test_attempt_id && test_id) {
      this.setState({ test_attempt_id, test_id }, this.get_test_answers);
    }
  }

  get_test_answers = () => {
    let { test_attempt_id, test_id } = this.state;
    api
      .get(`/ot/${test_id}/attempt/${test_attempt_id}/`)
      .then((res) => {
        if (res.data.success) {
          this.setState({ answers: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => console.log(err));
  };

  handleSave = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        let { teacher } = this.props;
        if (!(teacher && teacher.id)) {
          message.error("You must be a teacher to save test marks");
          return;
        }
        val.teacher_id = teacher.id;
        let { test_id, test_attempt_id } = this.state;
        let url = `/ot/${test_id}/attempt/${test_attempt_id}/`;
        api
          .patch(url, JSON.stringify(val))
          .then((res) => {
            if (res.data.success) {
              message.success("Marks Updated");
              // this.props.history.push(
              //   `/online-test/${test_id}/attempts/${test_attempt_id}/`
              // );
            } else {
              message.error(res.data.error);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  render() {
    const { answers } = this.state;
    const { Item } = Descriptions;
    const { getFieldDecorator } = this.props.form;
    const FormItem = Form.Item;
    return (
      <Layout.Content>
        <Form>
          <Card
            title="Student Answers"
            extra={
              <Button size="small" type="primary" onClick={this.handleSave}>
                Save
              </Button>
            }
          >
            {answers.map((item, index) => {
              const files = JSON.parse(item.files);
              const ans = item.answer ? item.answer[0] : {};
              return (
                <Descriptions
                  bordered
                  style={{ marginBottom: 16 }}
                  layout="vertical"
                  key={index}
                >
                  <Item label="Question" span={2}>
                    <div>
                      {item.question}
                      {files.map((file, ind) => {
                        return (
                          <div key={ind}>
                            <img
                              src={file.url}
                              alt="Question Attachments"
                              style={{
                                maxWidth: 200,
                                maxHeight: 200,
                                padding: 10,
                              }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </Item>
                  <Item label="Marks">
                    {[2, 3].includes(item.type) ? (
                      <span>
                        <FormItem style={{ marginBottom: 0 }}>
                          {getFieldDecorator(`data[${index}].id`, {
                            initialValue: ans.id,
                          })(<Input hidden />)}
                        </FormItem>
                        <FormItem style={{ marginBottom: 0 }}>
                          {getFieldDecorator(`data[${index}].type`, {
                            initialValue: item.type,
                          })(<Input hidden />)}
                        </FormItem>
                        <FormItem style={{ marginBottom: 0 }}>
                          {getFieldDecorator(`data[${index}].marks`, {
                            initialValue: ans.marks_awarded || 0,
                          })(<InputNumber />)}
                        </FormItem>
                        Max Marks: {item.correct_marks}
                      </span>
                    ) : (
                      <span>
                        <FormItem style={{ marginBottom: 0 }}>
                          {getFieldDecorator(`data[${index}].id`, {
                            initialValue: ans.id,
                          })(<Input hidden />)}
                        </FormItem>
                        <FormItem style={{ marginBottom: 0 }}>
                          {getFieldDecorator(`data[${index}].type`, {
                            initialValue: item.type,
                          })(<Input hidden />)}
                        </FormItem>
                        <FormItem style={{ marginBottom: 0 }}>
                          {getFieldDecorator(`data[${index}].marks`, {
                            initialValue: ans.marks_awarded,
                          })(<InputNumber disabled />)}
                        </FormItem>
                        Correct: {item.correct_marks}, wrong:{" "}
                        {item.incorrect_marks}, no answer:{" "}
                        {item.no_answer_marks}
                      </span>
                    )}
                  </Item>
                  {[0, 1].includes(item.type) && (
                    <Item label="Options" span={3}>
                      {item.options &&
                        item.options.map((option, i) => {
                          const files = JSON.parse(option.files);
                          return (
                            <div>
                              <span>
                                ID {option.id}) {option.text}
                              </span>
                              {files.map((file, ind) => {
                                return (
                                  <div
                                    key={ind}
                                    style={{ display: "inline-block" }}
                                  >
                                    <img
                                      src={file.url}
                                      alt="Question Attachments"
                                      style={{
                                        maxWidth: 200,
                                        maxHeight: 200,
                                        padding: 10,
                                      }}
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          );
                        })}
                    </Item>
                  )}
                  <Item label="Answers" span={3}>
                    {item.answer &&
                      item.answer.map((ans, i) => {
                        const files = JSON.parse(ans.ot_files);
                        return (
                          <div>
                            {ans.ot_answer || ans.ot_option_id}
                            {files.map((file, i) => {
                              return (
                                <a href={file.url} target="_blank" key={i} rel="noopener noreferrer">
                                  {file.name}
                                </a>
                              );
                            })}
                          </div>
                        );
                      })}
                  </Item>
                </Descriptions>
              );
            })}
          </Card>
        </Form>
      </Layout.Content>
    );
  }
}

function mapStateToProps(state) {
  return {
    teacher: state.teacher,
    teacher_settings: state.teacher_settings,
  };
}

export default connect(mapStateToProps, null)(Form.create()(OnlineTestAnswers));
