import React, { Component } from "react";
import { Layout, Table, message, Icon, Row, Col, Button } from "antd";
import { api } from "../../../config";
import moment from "moment";
import AssignOnlineTest from "./AssignOnlineTest";

class OnlineTestAssignments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      test_assignments: [],
      test_id: undefined,
      assign_test: false,
    };
  }

  componentDidMount() {
    if (this.props.match.params.test_id) {
      this.setState(
        { test_id: this.props.match.params.test_id },
        this.get_test_assignment
      );
    }
  }

  closeModal = (submitted) => {
    if (submitted) {
      let { test_id } = this.state;
      this.props.history.push(`/online-test/${test_id}/assign/`);
      this.get_test_assignment && this.get_test_assignment();
    }
    this.setState({ assign_test: false });
  };

  get_test_assignment = () => {
    const { test_id } = this.state;
    api
      .get(`/ot/test/${test_id}/assign/`)
      .then((res) => {
        if (res.data.success) {
          this.setState({ test_assignments: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => console.log(err));
  };

  render() {
    const { test_id, assign_test, test_assignments } = this.state;
    const columns = [
      { title: "ID", dataIndex: "id" },
      { title: "Class", dataIndex: "class" },
      { title: "Subject", dataIndex: "subject" },
      { title: "Pass Marks", dataIndex: "pass_marks" },
      {
        title: "Duration",
        dataIndex: "duration",
        render: (v) => <span>{v} mins</span>,
      },
      {
        title: "Start Time",
        dataIndex: "start_time",
        render: (v) => (
          <span>
            {v ? moment.utc(v).local().format("YYYY-MM-DD hh:mm A") : "-"}
          </span>
        ),
      },
      {
        title: "Actions",
        render: (r) => (
          <span>
            <Button
              type="primary"
              size="small"
              ghost
              onClick={() =>
                this.props.history.push(
                  `/online-test/${test_id}/attempts/${r.id}/`
                )
              }
            >
              Attempts
            </Button>
            <Icon
              type="delete"
              style={{ marginLeft: 10, fontSize: 18 }}
              className="absent"
            />
          </span>
        ),
      },
    ];
    return (
      <Layout.Content>
        <Row>
          <Col sm={12}>
            <h1>Online Test Assignment</h1>
          </Col>
          <Col sm={12} className="action-buttons">
            <Button
              type="primary"
              icon="play-circle"
              onClick={() => this.setState({ assign_test: true })}
            >
              Assign Test to Class
            </Button>
          </Col>
        </Row>
        <Table columns={columns} dataSource={test_assignments} rowKey="id" />
        {assign_test && (
          <AssignOnlineTest closeModal={this.closeModal} test_id={test_id} />
        )}
      </Layout.Content>
    );
  }
}

export default OnlineTestAssignments;
