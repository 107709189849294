import React, { Component } from "react";
import { Modal, Form, Input, Select, message } from "antd";
import { api } from "../config";

class SendTextMessages extends Component {
  state = {
    loading: false,
    mobile_nos: [],
    message: "",
  };

  componentDidMount() {
    if (this.props.selectedStudents) {
      let students = this.props.selectedStudents;
      let mobile_nos = students.map((item, index) => {
        return item.mobile;
      });
      this.props.form.setFieldsValue({ mobile_nos: mobile_nos });
      this.setState({ mobile_nos });
    }
  }

  handleFormSubmit = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        this.setState({ loading: true });
        api
          .put("/text-message/", JSON.stringify(val))
          .then((res) => {
            if (res.data.success) {
              message.success("Messages Sent");
              this.props.closeModal();
            } else {
              message.error(res.data.error);
            }
            this.setState({ loading: false });
          })
          .catch((err) => {
            console.log(err);
            this.setState({ loading: false });
          });
      }
    });
  };

  render() {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    const { mobile_nos, message } = this.state;
    return (
      <Modal
        title="Send Text Messages"
        visible={true}
        onCancel={this.props.closeModal}
        onOk={this.handleFormSubmit}
      >
        <Form>
          <FormItem label="Mobile No">
            {getFieldDecorator("mobile_nos", {
              rules: [{ required: true, message: "Mobile Nos is required!" }],
            })(<Select mode="tags" />)}
            <span>Total Numbers {mobile_nos.length}</span>
          </FormItem>
          <FormItem label="Message">
            {getFieldDecorator("message", {
              rules: [{ required: true, message: "Message is required!" }],
            })(
              <Input.TextArea
                onChange={(e) => {
                  this.setState({ message: e.target.value });
                }}
              />
            )}
            <span style={{ color: message.length > 159 ? "red" : "black" }}>
              Characters: {message.length}({Math.ceil(message.length / 160)}{" "}
              messages)
            </span>
          </FormItem>
        </Form>
      </Modal>
    );
  }
}

export default Form.create()(SendTextMessages);
