import { Modal, Form, InputNumber } from "antd";
import React, { Component } from "react";

class BuyCredits extends Component {
  state = {
    loading: false,
  };

  __handle_submit = () => {};

  render() {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    const { buy } = this.props;
    return (
      <Modal
        visible={true}
        title={`Buy ${this.props.buy} Credits`}
        onOk={this.__handle_submit}
        onCancel={this.props.close.bind(this, false)}
      >
        <Form>
          <FormItem
            label="Quantity"
            help={
              buy
                ? buy === "SMS"
                  ? "SMS Quantity in Nos"
                  : "Storage space in GB"
                : ""
            }
          >
            {getFieldDecorator("quantity")(<InputNumber />)}
          </FormItem>
          <FormItem label="Price">
            {getFieldDecorator("price")(
              <InputNumber formatter={(v) => `₹ ${v}`} />
            )}
          </FormItem>
        </Form>
      </Modal>
    );
  }
}

export default Form.create()(BuyCredits);
