import React from "react";
import { Switch, Route } from "react-router";
import { ClassesList, ClassesForm } from "./classes";
import { SubjectsList, SubjectsForm } from "./subjects";
import {
  TestsList,
  TestsForm,
  TestsDetailsList,
  TestDetailsEditForm,
  TestDetailsAddForm
} from "./tests";
import { ACYearList, ACYearForm } from "./acyear";
import PromoteStudents from "./promote/PromoteStudents";
import BranchesList from "./branches/BranchesList";
import BranchesForm from "./branches/BranchesForm";

const AdminAcademicsRouter = () => (
  <Switch>
    <Route exact path="/academics/subjects/" component={SubjectsList} />
    <Route exact path="/academics/subjects/new" component={SubjectsForm} />
    <Route exact path="/academics/subjects/:id" component={SubjectsForm} />
    <Route exact path="/academics/tests/" component={TestsList} />
    <Route exact path="/academics/tests/new" component={TestsForm} />
    <Route exact path="/academics/tests/:id" component={TestsForm} />
    <Route exact path="/academics/test/list/:id" component={TestsDetailsList} />
    <Route
      exact
      path="/academics/test/details/:testid"
      component={TestDetailsAddForm}
    />
    <Route
      exact
      path="/academics/test/details/edit/:id/"
      component={TestDetailsEditForm}
    />
    <Route exact path="/academics/acyears/" component={ACYearList} />
    <Route exact path="/academics/acyear/new" component={ACYearForm} />
    <Route exact path="/academics/acyear/:id" component={ACYearForm} />
    <Route exact path="/academics/classes/" component={ClassesList} />
    <Route exact path="/academics/classes/new" component={ClassesForm} />
    <Route exact path="/academics/classes/:id" component={ClassesForm} />

    <Route exact path="/academics/branches/" component={BranchesList} />
    <Route exact path="/academics/branch/new" component={BranchesForm} />
    <Route exact path="/academics/branch/:id" component={BranchesForm} />

    <Route exact path="/academics/promote/" component={PromoteStudents} />
  </Switch>
);

export { AdminAcademicsRouter };
