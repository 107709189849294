import React from "react";
import { Switch, Route } from "react-router";
import ExamsList from "./ExamsList";
import ExamForm from "./ExamForm";
import QuestionsList from "./QuestionsList";
import QuestionForm from "./QuestionForm";
import ViewQuestion from "./ViewQuestion";
import AssignExam from "./AssignExam";

const AdminExamRouter = (props) => (
  <Switch>
    <Route exact path="/exam/" component={ExamsList} />
    <Route exact path="/exam/new" component={ExamForm} />
    <Route exact path="/exam/questions/:id" component={QuestionsList} />
    <Route exact path="/exam/question/:id/new" component={QuestionForm} />
    <Route exact path="/exam/question/:exam_id/:id" component={ViewQuestion} />
    <Route exact path="/exam/assign/:exam_id" component={AssignExam} />
  </Switch>
);

export { AdminExamRouter };
