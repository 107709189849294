import React, { Component } from "react";
import { Layout } from "antd";
import { GuestHeaderMenu, PageFooter } from "./HeaderFooter";
import { GuestRouter } from "../../config/Router";

class GuestLayout extends Component {
  state = {
    about_school: {},
  };
  render() {
    const { about_school } = this.state;
    return (
      <Layout>
        <GuestHeaderMenu school_name={about_school.school_name || "Eduga"} />
        <Layout className="box-container">
          <GuestRouter />
        </Layout>
        <PageFooter />
      </Layout>
    );
  }
}

export default GuestLayout;
