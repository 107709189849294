import React, { Component } from "react";
import {
  Layout,
  Row,
  Col,
  Table,
  Card,
  message,
  Descriptions,
  Button,
  List,
} from "antd";
import { api } from "../../../config";
import moment from "moment";

class OnlineTestDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      test_id: undefined,
      student_id: undefined,
      attempts: [],
      test: {},
      can_start: false,
    };
  }

  componentDidMount() {
    let params = this.props.match.params;
    let { student_id, test } = this.props.location.state;
    if (params.test_id && student_id && test) {
      this.setState(
        { test_id: params.test_id, student_id, test },
        this.get_test_attempts
      );
    } else {
      this.props.history.push("/online-test/");
    }
  }

  get_test_attempts = () => {
    let { test_id, student_id } = this.state;
    let url = `/ot/student/${student_id}/test/${test_id}/attempts/`;
    api
      .get(url)
      .then((res) => {
        if (res.data.success) {
          let data = res.data.data;
          this.set_can_start(data);
          this.setState({ attempts: data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  set_can_start = (data) => {
    let can_start = data.length === 0;
    this.setState({ can_start: can_start });
  };

  start_test = () => {
    let { student_id, test_id, test } = this.state;
    this.props.history.push({
      pathname: "/online-test/test/",
      state: { student_id, test_id, test },
    });
  };

  render() {
    const { test, can_start, attempts } = this.state;
    const columns = [
      { title: "ID", dataIndex: "id" },
      {
        title: "Start Time",
        dataIndex: "start_time",
        render: (v) => (
          <span>{moment.utc(v).local().format("YYYY-MM-DD hh:mm A")}</span>
        ),
      },
      {
        title: "End Time",
        dataIndex: "end_time",
        render: (v) => (
          <span>
            {v ? moment.utc(v).local().format("YYYY-MM-DD hh:mm A") : " - "}
          </span>
        ),
      },
      { title: "Marks Obtained", dataIndex: "marks_obtained" },
      {
        title: "Status",
        dataIndex: "status",
        render: (v, r) => {
          switch (v) {
            case 1:
              return <span>Not submitted</span>;
            case 2:
              return <span>Test Submitted</span>;
            default:
              return <span>Unknown</span>;
          }
        },
      },
    ];
    const { Item } = Descriptions;
    return (
      <Layout.Content>
        <Row gutter={24}>
          <Col sm={12}>
            <h1>Online Test Details</h1>
          </Col>
          <Col sm={12} className="action-buttons">
            {can_start && (
              <Button
                icon="play-circle"
                type="primary"
                onClick={this.start_test}
              >
                Start
              </Button>
            )}
          </Col>
        </Row>
        <Card title="Test Details" style={{ marginBottom: 16 }}>
          <Descriptions>
            <Item label="Name">{test.name}</Item>
            <Item label="Category">{test.category}</Item>
            <Item label="Class">{test.class}</Item>
            <Item label="Subject">{test.subject}</Item>
            <Item label="Pass Marks">{test.pass_marks}</Item>
            <Item label="Duration">{test.duration} mins</Item>
            <Item label="Start Time">
              {test.start_time
                ? moment
                    .utc(test.start_time)
                    .local()
                    .format("YYYY-MM-DD hh:mm A") +
                  " (" +
                  moment.utc(test.start_time).local().fromNow() +
                  ")"
                : " - "}
            </Item>
          </Descriptions>
        </Card>
        <Card title="Attempts" style={{ marginBottom: 16 }}>
          <Table columns={columns} dataSource={attempts} rowKey="id" />
        </Card>
        <Card title="Note">
          <List>
            <List.Item>
              Use latest version of Firefox/Chrome for online tests
            </List.Item>
            <List.Item>
              Once you click start, the browser goes into fullscreen mode and
              the test starts.
            </List.Item>
            <List.Item>
              Exiting from fullscreen manually will stop the test and disqualfy
              you from test (avoid hitting Esc key).
            </List.Item>
            <List.Item>
              The timer for the test is shown in top right corner of the screen.
            </List.Item>
            <List.Item>
              Once you finish the test you can click submit button (in top right
              corner of screen)
            </List.Item>
            <List.Item>
              The test results will be automatically submitted if the timer
              counter hits 0
            </List.Item>
          </List>
        </Card>
      </Layout.Content>
    );
  }
}

export default OnlineTestDetails;
