import React, { Component } from "react";
import {
  Layout,
  Table,
  message,
  Row,
  Col,
  Button,
  Icon,
  Tag,
  Popconfirm,
  Modal,
  Input,
  Upload,
} from "antd";
import { Link } from "react-router-dom";
import { api, baseurl, getToken } from "../../../../config";
import moment from "moment";
import { SendTextMessages } from "../../../../components";

class LeadsList extends Component {
  state = {
    leads: [],
    spinning: false,
    status_list: [],
    selected_leads: [],
    show_modal: false,
    message_all_modal: false,
    send_messages_loading: false,
    message: null,
    importing: false,
  };

  componentDidMount() {
    let status_list = [
      { text: "Not Interested", color: "red", value: -3 },
      { text: "Rejected (by parent)", color: "red", value: -2 },
      { text: "Rejected (by school)", color: "red", value: -1 },
      { text: "Unknown", color: "", value: 0 },
      { text: "Follow up (positive)", color: "lime", value: 1 },
      { text: "Follow up (negative)", color: "volcano", value: 2 },
      { text: "Pending (by parent)", color: "blue", value: 3 },
      { text: "Pending (by school)", color: "blue", value: 4 },
      { text: "Confirmed", color: "green", value: 5 },
      { text: "Qualified", color: "green", value: 6 },
    ];
    this.setState({ status_list });
    this.get_leads_list();
  }

  get_leads_list = () => {
    this.setState({ spinning: true });
    api
      .get("/admissions/leads/")
      .then((res) => {
        if (res.data.success) {
          this.setState({ leads: res.data.data });
        } else {
          message.error(res.data.error);
        }
        this.setState({ spinning: false });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ spinning: false });
      });
  };

  get_lead_status_text = (status) => {
    switch (status) {
      case -3:
        return { text: "Not Interested", color: "red" };
      case -2:
        return { text: "Rejected (by parent)", color: "red" };
      case -1:
        return { text: "Rejected (by school)", color: "red" };
      case 0:
        return { text: "Unknown", color: "" };
      case 1:
        return { text: "Follow up (positive)", color: "lime" };
      case 2:
        return { text: "Follow up (negative)", color: "volcano" };
      case 3:
        return { text: "Pending (by parent)", color: "blue" };
      case 4:
        return { text: "Pending (by school)", color: "blue" };
      case 5:
        return { text: "Confirmed", color: "green" };
      case 6:
        return { text: "Qualified", color: "green" };
      default:
        return { text: "Unknown", color: "" };
    }
  };

  selectedStudents = (selectedRowKeys, selectedRows) => {
    this.setState({ selected_leads: selectedRows });
  };

  closeModal = () => {
    this.setState({ show_modal: false, message_all_modal: false });
  };

  delete_lead = (id) => {
    api
      .delete("/admissions/lead/" + id + "/")
      .then((res) => {
        if (res.data.success) {
          message.success("Lead Deleted");
          this.get_leads_list();
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  message_all_leads = () => {
    this.setState({ send_messages_loading: true });
    let msg = this.state.message;
    if (msg.length === 0) {
      message.error("Message is empty");
      return;
    }
    api
      .post("/admissions/leads/message/", JSON.stringify({ message: msg }))
      .then((res) => {
        if (res.data.success) {
          message.success("SMS Sent");
          this.closeModal();
        } else {
          message.error(res.data.error);
        }
        this.setState({ send_messages_loading: false });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ send_messages_loading: false });
      });
  };

  upload_file = (info) => {
    if (info.file.status !== "uploading") {
      this.setState({ importing: true });
    }
    if (info.file.status === "done") {
      this.import_leads_list(info.file.response.path);
    } else if (info.file.status === "error") {
      this.setState({ importing: true });
    }
  };

  import_leads_list = (path) => {
    let data = {
      path,
    };
    api
      .put(`/leads/import/`, JSON.stringify(data))
      .then((res) => {
        if (res.data.success) {
          message.success("Leads list imported!");
          this.get_leads_list();
        } else {
          message.error(res.data.error);
        }
        this.setState({ importing: false });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ importing: false });
      });
  };

  export_leads_list = () => {
    let { leads } = this.state;
    var csvContent = "data:text/csv;charset=utf-8,";
    let header =
      "ID,Student Name,Class,Parent Name,Email,Mobile,Created,Updated,Status\r\n";
    csvContent += header;
    leads.forEach((item) => {
      let line = `${item.id},${item.student_name},${item.class},${item.parent_name},${item.email},${item.mobile},${item.created},${item.updated},${item.status}\r\n`;
      csvContent += line;
    });
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "leads_list.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  render() {
    const columns = [
      { title: "ID", dataIndex: "id" },
      {
        title: "Parent Name",
        dataIndex: "parent_name",
        render: (v, r) => <Link to={`/admissions/lead/${r.id}/`}>{v}</Link>,
      },
      { title: "Student Name", dataIndex: "student_name" },
      { title: "Class", dataIndex: "class" },
      { title: "Mobile", dataIndex: "mobile" },
      {
        title: "Created",
        dataIndex: "created",
        sorter: (a, b) =>
          moment(a.created).format("X") - moment(b.created).format("X"),
        render: (val) => <span>{moment.utc(val).fromNow()}</span>,
      },
      {
        title: "Updated",
        dataIndex: "updated",
        sorter: (a, b) =>
          moment(a.updated).format("X") - moment(b.updated).format("X"),
        render: (val) => <span>{moment.utc(val).fromNow()}</span>,
      },
      {
        title: "Status",
        dataIndex: "status",
        sorter: (a, b) => a.status - b.status,
        filters: this.state.status_list,
        onFilter: (value, record) => value === record.status,
        render: (val, record) => {
          const status = this.get_lead_status_text(record.status);
          return (
            <span>
              <Tag color={status.color}>{status.text}</Tag>
            </span>
          );
        },
      },
      {
        title: "Comments",
        render: (record) => (
          <span>{record.extras && record.extras.reason}</span>
        ),
      },
      {
        title: "Actions",
        render: (record) => (
          <span>
            <Icon
              type="edit"
              className="action-icon"
              onClick={() => {
                this.props.history.push({
                  pathname: "/admissions/lead/" + record.id + "/edit",
                  state: record,
                });
              }}
            />
            <Popconfirm
              title="Are you sure?"
              onConfirm={this.delete_lead.bind(this, record.id)}
            >
              <Icon type="delete" className="action-icon absent" />
            </Popconfirm>
          </span>
        ),
      },
    ];
    const rowSelection = {
      onChange: this.selectedStudents,
    };
    const { show_modal, selected_leads, importing } = this.state;
    return (
      <Layout.Content>
        <Row>
          <Col xl={12} xxl={12}>
            <h1>Leads List</h1>
          </Col>
          <Col xl={12} xxl={12} style={{ textAlign: "right" }}>
            <Button
              style={{ marginRight: 12 }}
              onClick={this.export_leads_list}
              icon="export"
            >
              CSV
            </Button>
            <Upload
              name="file"
              action={`${baseurl}/upload/?file_path=documents`}
              headers={{ authorization: getToken() }}
              onChange={this.upload_file}
              multiple={false}
              showUploadList={false}
              accept=".csv"
            >
              <Button
                type="primary"
                icon="upload"
                loading={importing}
                style={{ marginRight: 12 }}
              >
                Import
              </Button>
            </Upload>
            <Button
              icon="message"
              onClick={() => {
                if (selected_leads.length === 0) {
                  this.setState({ message_all_modal: true });
                } else {
                  this.setState({ show_modal: true });
                }
              }}
              style={{ marginRight: 12 }}
            >
              Message
            </Button>
            <Link to="/admissions/lead/new/">
              <Button icon="plus" type="primary">
                New Lead
              </Button>
            </Link>
          </Col>
        </Row>
        <Table
          loading={this.state.spinning}
          columns={columns}
          dataSource={this.state.leads}
          rowSelection={rowSelection}
          rowKey="id"
        />
        {show_modal && (
          <SendTextMessages
            closeModal={this.closeModal}
            selectedStudents={selected_leads}
          />
        )}
        <Modal
          title="Message All Leads"
          visible={this.state.message_all_modal}
          onOk={this.message_all_leads}
          onCancel={this.closeModal}
          okText="Send"
          confirmLoading={this.state.send_messages_loading}
        >
          <Input.TextArea
            placeholder="Your message here"
            onChange={(e) => {
              this.setState({ message: e.target.value });
            }}
          />
        </Modal>
      </Layout.Content>
    );
  }
}

export default LeadsList;
