import React, { Component } from "react";
import {
  Layout,
  Form,
  Row,
  Col,
  Table,
  Input,
  Button,
  Icon,
  message,
} from "antd";
import { api } from "../../../../config";

class LeavesTypes extends Component {
  state = {
    leave_types: [],
  };

  componentDidMount() {
    this.get_leaves_types();
  }

  get_leaves_types = () => {
    api
      .get("/hr/leave-types/")
      .then((res) => {
        if (res.data.success) {
          this.setState({ leave_types: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  add_leave_type = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        api
          .put("/hr/leave-types/", JSON.stringify(val))
          .then((res) => {
            if (res.data.success) {
              message.success("New leave type added");
              this.get_leaves_types();
            } else {
              message.error(res.data.error);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  delete_leave_type = (id) => {
    api
      .delete(`/hr/leave-type/${id}/`)
      .then((res) => {
        if (res.data.success) {
          message.success("Leave type deleted");
          this.get_leaves_types();
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const { leave_types } = this.state;
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    const columns = [
      { title: "ID", dataIndex: "id" },
      { title: "Name", dataIndex: "name" },
      {
        title: "Actions",
        render: (r) => (
          <span>
            <Icon
              type="delete"
              className="action-icon absent"
              onClick={this.delete_leave_type.bind(this, r.id)}
            />
          </span>
        ),
      },
    ];
    return (
      <Layout.Content>
        <Row gutter={32} className="ribbon-row">
          <Col sm={12}>
            <h3>Leave Types</h3>
            <Table columns={columns} dataSource={leave_types} rowKey="id" />
          </Col>
          <Col sm={12}>
            <h3>Leave Types</h3>
            <Form>
              <FormItem label="Leave Type">
                {getFieldDecorator("name", {
                  rules: [
                    { required: true, message: "Leave type is requried!" },
                  ],
                })(<Input />)}
              </FormItem>
              <FormItem>
                <Button type="primary" onClick={this.add_leave_type}>
                  Save
                </Button>
              </FormItem>
            </Form>
          </Col>
        </Row>
      </Layout.Content>
    );
  }
}

export default Form.create()(LeavesTypes);
