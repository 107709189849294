import React, { Component } from "react";
import { Layout, Row, Col, Button, InputNumber, Spin, message } from "antd";
import { api } from "../../../config";

const styles = {
  rowStyle: {
    marginBottom: 15
  }
};

class LibrarySettings extends Component {
  state = {
    settings: {
      lib_max_books_allowed: null,
      lib_fine_per_day: null
    },
    spinning: false,
    loading: false
  };

  componentDidMount() {
    this.get_library_settings();
  }

  get_library_settings = () => {
    this.setState({ spinning: true });
    api
      .get("/settings/library/")
      .then(res => {
        if (res.data.success) {
          let settings = this.state.settings;
          this.setState({ settings: { ...settings, ...res.data.data } });
        } else {
          message.error(res.data.error);
        }
        this.setState({ spinning: false });
      })
      .catch(err => {
        console.log(err);
        this.setState({ spinning: false });
      });
  };

  update_library_settings = () => {
    this.setState({ loading: true });
    let data = this.state.settings;
    api
      .post("/settings/library/", JSON.stringify(data))
      .then(res => {
        if (res.data.success) {
          message.success("Library settings updated");
        } else {
          message.error(res.data.error);
        }
        this.setState({ loading: false });
      })
      .catch(err => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  render() {
    const { settings } = this.state;
    return (
      <Layout.Content>
        <Row style={styles.rowStyle}>
          <Col sm={16}>
            <h1>Library Settings</h1>
          </Col>
          <Col sm={8}>
            <Button
              type="primary"
              loading={this.state.loading}
              onClick={this.update_library_settings}
            >
              Save
            </Button>
          </Col>
        </Row>
        <Spin spinning={this.state.spinning}>
          <Row style={styles.rowStyle}>
            <Col sm={16}>
              <h3>Max Books Allowed</h3>
            </Col>
            <Col sm={8}>
              <InputNumber
                value={settings.lib_max_books_allowed}
                onChange={val => {
                  let { settings } = this.state;
                  settings.lib_max_books_allowed = val;
                  this.setState({ settings });
                }}
              />
            </Col>
          </Row>
          <Row style={styles.rowStyle}>
            <Col sm={16}>
              <h3>Late submission fine per day</h3>
            </Col>
            <Col sm={8}>
              <InputNumber
                value={settings.lib_fine_per_day}
                onChange={val => {
                  let { settings } = this.state;
                  settings.lib_fine_per_day = val;
                  this.setState({ settings });
                }}
              />
            </Col>
          </Row>
        </Spin>
      </Layout.Content>
    );
  }
}

export default LibrarySettings;
