import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Layout,
  Form,
  Input,
  Icon,
  Button,
  Checkbox,
  Col,
  Row,
  message,
} from "antd";
import Axios from "axios";

const { Content } = Layout;

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = { username: "", password: "", loading: false, schools: [] };
  }

  componentDidMount() {
    this.getSchoolsList();
  }

  getSchoolsList = () => {
    Axios.get(
      "https://ambegaalu.in/eduga/schools_list.json?nocache=" +
        new Date().getTime()
    )
      .then((res) => {
        this.setState({ schools: res.data.schools });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  loginUser = (data) => {
    this.setState({ loading: true });
    Axios.post(data.school_url + "/login/", JSON.stringify(data), {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.data && res.data.success) {
          this.props.login(res.data.data);
          if (data.remember === true) {
            window.localStorage.setItem("api_base_url", data.school_url);
            window.localStorage.setItem("token", res.data.data.token);
          } else {
            window.sessionStorage.setItem("api_base_url", data.school_url);
            window.sessionStorage.setItem("token", res.data.data.token);
          }
          message.success("Logged in");
          setTimeout(() => {
            // this.props.history.push("/");
            window.location.reload();
          }, 750);
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
        message.error("Something went wrong");
      });
    this.setState({ loading: false });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let flag = false;
        this.state.schools.forEach((item) => {
          if (item.code === values.school_code.toUpperCase()) {
            values.school_url = item.api;
            this.loginUser(values);
            flag = true;
          }
        });
        if (flag === false) {
          message.error("Incorrect Institution Code");
        }
      }
    });
  };

  render() {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    return (
      <Content className="content" style={{ marginTop: 40 }}>
        <h1 style={{ textAlign: "center" }}>Login</h1>
        <Form
          onSubmit={this.handleSubmit}
          style={{ maxWidth: 300, margin: "auto" }}
        >
          <FormItem>
            {getFieldDecorator("school_code", {
              rules: [
                { required: true, message: "Please enter institution code" },
              ],
            })(<Input placeholder="Institution Code" />)}
          </FormItem>
          <FormItem>
            {getFieldDecorator("email", {
              rules: [{ required: true, message: "This field is required" }],
            })(
              <Input
                prefix={
                  <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                }
                placeholder="Email or Mobile"
              />
            )}
          </FormItem>
          <FormItem>
            {getFieldDecorator("password", {
              rules: [{ required: true, message: "This field is required" }],
            })(
              <Input.Password
                prefix={
                  <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                }
                placeholder="Password"
              />
            )}
          </FormItem>
          <FormItem>
            <Row>
              <Col sm={12}>
                {getFieldDecorator("remember", {
                  valuePropName: "checked",
                  initialValue: true,
                })(<Checkbox>Remember me</Checkbox>)}
              </Col>
              <Col sm={12} style={{ textAlign: "right" }}>
                <Link to="/forgot-password">Forgot password</Link>
              </Col>
            </Row>
          </FormItem>
          <Button type="primary" htmlType="submit" loading={this.state.loading}>
            Login
          </Button>
        </Form>
      </Content>
    );
  }
}
function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    login: (credentials) => {
      dispatch({ type: "LOGIN", payload: credentials });
    },
  };
}
const LoginForm = Form.create()(Login);
export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
