import React, { Component } from "react";
import {
  Layout,
  Form,
  Input,
  Button,
  DatePicker,
  Select,
  message,
  Spin
} from "antd";
import { connect } from "react-redux";
import { ShowBreadcrumbs } from "../../../../components";
import { api } from "../../../../config";

class TestsForm extends Component {
  state = {
    ac_years: [],
    spinning: false,
    loading: false
  };
  componentDidMount() {
    this.getAcademicYears();
  }

  handleSubmit = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        this.addNewTest(val);
      }
    });
  };

  getAcademicYears = () => {
    api
      .get("/acyears/")
      .then(res => {
        if (res.data && res.data.success) {
          this.setState({ ac_years: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  addNewTest = val => {
    this.setState({ loading: true });
    api
      .put("/tests/", JSON.stringify(val))
      .then(res => {
        if (res.data && res.data.success) {
          message.success("New Test Added");
          this.props.history.push("/academics/tests/");
        } else {
          message.error(res.data.error);
        }
        this.setState({ loading: false });
      })
      .catch(err => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  render() {
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      }
    };
    const { getFieldDecorator } = this.props.form;
    const FormItem = Form.Item;
    const Option = Select.Option;
    const { ac_years } = this.state;
    return (
      <Layout.Content>
        <ShowBreadcrumbs
          items={[
            { name: "Home", link: "/" },
            { name: "Academics", link: "/academics/" },
            { name: "Tests", link: "/academics/tests/" },
            { name: "Add", link: "/academics/tests/add" }
          ]}
        />
        <h1 style={{ textAlign: "center" }}>Add Tests</h1>
        <Spin spinning={this.state.spinning}>
          <Form {...formItemLayout}>
            <FormItem label="Name">
              {getFieldDecorator("name", {
                rules: [{ required: true, message: "Please enter name!" }]
              })(<Input />)}
            </FormItem>
            <FormItem label="Acadamic Year">
              {getFieldDecorator("ac_year", {
                rules: [
                  { required: true, message: "Please select academic year" }
                ]
              })(
                <Select>
                  {ac_years.map((item, index) => {
                    return (
                      <Option value={item.id} key={index}>
                        {item.name} - {item.syllabus}
                      </Option>
                    );
                  })}
                </Select>
              )}
            </FormItem>
            <FormItem label="Start Date">
              {getFieldDecorator("start_date", {
                rules: [
                  { required: true, message: "Please Select Start Date!" }
                ]
              })(<DatePicker />)}
            </FormItem>
            <FormItem label="End Date">
              {getFieldDecorator("end_date", {
                rules: [{ required: true, message: "Please Select End Date!" }]
              })(<DatePicker />)}
            </FormItem>
            <FormItem wrapperCol={{ offset: 8 }}>
              <Button
                type="primary"
                onClick={this.handleSubmit}
                loading={this.state.loading}
              >
                Add
              </Button>
            </FormItem>
          </Form>
        </Spin>
      </Layout.Content>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    get_teachers: payload => {
      dispatch({ type: "GET_TEACHERS", payload: payload });
    }
  };
}

function mapStateToProps(state) {
  return {
    user: state.user,
    teachers: state.teachers
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(TestsForm));
