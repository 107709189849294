import React, { Component } from "react";
import { Modal, Form, Input, message } from "antd";
import { api } from "../../../config";

class NewVCRoomQuestion extends Component {
  state = {
    loading: false,
  };

  handleOk = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        this.setState({ loading: true });
        let data = val;
        data.vc_class_id = this.props.vc_class_id;
        data.student_id = this.props.student.id;
        let url = "/virtual-class/" + this.props.vc_class_id + "/questions/";
        api
          .put(url, JSON.stringify(data))
          .then((res) => {
            if (res.data.success) {
              message.success("New Question Asked");
              this.props.closeModal();
            } else {
              message.error(res.data.error);
            }
            this.setState({ loading: false });
          })
          .catch((err) => {
            console.log(err);
            this.setState({ loading: false });
          });
      }
    });
  };

  render() {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    return (
      <Modal
        visible={true}
        title="New Question"
        onCancel={this.props.closeModal}
        onOk={this.handleOk}
        confirmLoading={this.state.loading}
      >
        <Form>
          <FormItem label="Question">
            {getFieldDecorator("question", {
              rules: [{ required: true, message: "Question is required!" }],
            })(<Input.TextArea />)}
          </FormItem>
          <FormItem label="Description">
            {getFieldDecorator("description")(<Input.TextArea />)}
          </FormItem>
        </Form>
      </Modal>
    );
  }
}

export default Form.create()(NewVCRoomQuestion);
