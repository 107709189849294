import React, { Component } from "react";
import { Layout, Descriptions, Card, Button, message, Result } from "antd";
import moment from "moment";
import Axios from "axios";
import { Link } from "react-router-dom";

class ApplicationPreview extends Component {
  state = {
    imageUrl: null,
    base_url: null,
    settings: {},
    school: [],
    params: {},
    sch_details: {},
    token: null,
    formData: {},
    loading: false,
    application_id: null,
    order_details: {},
    order_response: {},
    status: null,
  };

  componentDidMount() {
    this.check_session_storage();
    let { state, formData } = this.props.location.state;
    this.setState({ ...state, formData });
  }

  check_session_storage = async () => {
    let status = await window.sessionStorage.getItem("status");
    status === "done" && this.setState({ status: "403" });
  };

  write_session_storage = async (status) => {
    await window.sessionStorage.setItem("status", status);
  };

  handleSubmit = () => {
    this.setState({ loading: true });
    const { formData, base_url, token, application_id, params } = this.state;
    if (application_id) {
      this.generate_razorpay_order_id({ application_id });
      return;
    }
    let headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    let data = { ...formData, sch_code: params.sch_code };
    let url = base_url + "/admissions/applications/";
    Axios.put(url, JSON.stringify(data), { headers })
      .then((res) => {
        if (res.data.success) {
          this.setState({ application_id: res.data.data.application_id });
          this.generate_razorpay_order_id(res.data.data);
        } else {
          message.error(res.data.error);
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        console.log(err);
        message.error("Check network");
        this.setState({ loading: false });
      });
  };

  generate_razorpay_order_id = (res) => {
    let { base_url, token, settings, order_details, params } = this.state;
    if (order_details && order_details.id) {
      this.razorpay_payment({ ...order_details });
      return;
    }
    let amt = settings.adm_application_fees;
    if (amt === 0 || amt === "0") {
      this.write_session_storage("done");
      console.log("Zero Fees");
      this.props.history.push({
        pathname: "/admissions/apply/payment-status/",
        state: {
          application_id: res.application_id,
          order_details: { id: " - ", amount: 0 },
          status: 1,
          params,
        },
      });
      return;
    }
    let data = {
      application_id: res.application_id,
      payment_mode: "razorpay",
      amount: settings.adm_application_fees,
    };
    let headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    let url = base_url + "/admissions/application/fees/";
    Axios.put(url, JSON.stringify(data), { headers })
      .then((res) => {
        if (res.data.success) {
          this.razorpay_payment(res.data.data);
          this.setState({ order_details: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  razorpay_payment = (res) => {
    const {
      base_url,
      sch_details,
      formData,
      token,
      order_response,
      params,
    } = this.state;
    if (order_response.razorpay_payment_id) {
      message.info("Payment already done!");
      return;
    }
    let image = base_url + "/" + sch_details.school_logo;
    let order = res.razorpay_order;
    let headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    let url = base_url + "/admissions/application/fee/" + res.id + "/";
    let name = sch_details.school_name + " | Eduga";
    let description = sch_details.school_name + " new student application fees";
    let options = {
      key: res.razorpay_key_id,
      amount: order.amount,
      currency: "INR",
      name: name,
      description: description,
      image: image,
      order_id: order.id,
      handler: (response) => {
        this.setState({ order_response: response });
        if (!response.razorpay_payment_id) {
          console.log("Payment Error - Razorpay");
          message.error("Payment Error - Razorpay");
        }
        let data = {
          application_id: res.application_id,
          order_id: order.id,
          razorpay_payment_id: response.razorpay_payment_id,
          razorpay_order_id: response.razorpay_order_id,
          razorpay_signature: response.razorpay_signature,
          payment_mode: "razorpay",
        };
        Axios.post(url, JSON.stringify(data), { headers })
          .then((results) => {
            if (results.data.success) {
              this.write_session_storage("done");
              this.props.history.push({
                pathname: "/admissions/apply/payment-status/",
                state: {
                  application_id: res.application_id,
                  order_details: order,
                  status: results.data.data.status,
                  params,
                },
              });
            } else {
              message.error(results.data.error);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
      prefill: {
        name: formData.parent_name,
        email: formData.email,
        contact: formData.mobile,
      },
      modal: {
        ondismiss: () => {
          message.error("Payment Cancelled by User");
          this.setState({ loading: false });
        },
      },
    };
    let rzpay = new window.Razorpay(options);
    rzpay.open();
  };

  handleEdit = () => {
    this.props.history.goBack();
  };

  render() {
    const data = this.state.formData;
    const { settings, status, params } = this.state;
    const Item = Descriptions.Item;
    return (
      <Layout.Content className="admission-details container">
        <h1 className="align-center">Application Preview</h1>
        {data && data.details && status !== "403" && (
          <div style={{ marginBottom: 40 }}>
            <Card>
              <Descriptions title="Student Details" bordered>
                <Item label="Student Name">{data.student_name}</Item>
                <Item label="Class Applying To">{data.application_class}</Item>
                <Item label="Student Photo">
                  <img
                    src={data.student_photo.url}
                    alt="Student Pic"
                    style={{
                      width: 120,
                      height: 160,
                      border: "1px dashed grey",
                      //   padding: "0 10px 100%",
                      padding: 10,
                    }}
                  />
                </Item>
                <Item label="Date of Birth">
                  {data.details.student.dob
                    ? moment(data.details.student.dob).format("YYYY-MM-DD")
                    : "-"}
                </Item>
                <Item label="Gender">{data.details.student.gender || "-"}</Item>
              </Descriptions>
              <Descriptions
                title="Parent/Guardian Details"
                bordered
                style={{ marginTop: 20 }}
              >
                <Item label="Name">{data.parent_name}</Item>
                <Item label="Mobile">{data.mobile}</Item>
                <Item label="Email">{data.email}</Item>
                <Item label="Address">
                  {data.details.guardian.address || "-"}
                </Item>
              </Descriptions>
              <Descriptions
                title="Father's Details"
                bordered
                style={{ marginTop: 20 }}
              >
                <Item label="Father's Name">
                  {data.details.father.name || "-"}
                </Item>
                <Item label="Father's Mobile">
                  {data.details.father.mobile || "-"}
                </Item>
                <Item label="Father's Profession">
                  {data.details.father.profession || "-"}
                </Item>
                <Item label="Father's Salary">
                  {data.details.father.salary || "-"}
                </Item>
              </Descriptions>
              <Descriptions
                title="Mother's Details"
                bordered
                style={{ marginTop: 20 }}
              >
                <Item label="Mother's Name">
                  {data.details.mother.name || "-"}
                </Item>
                <Item label="Mother's Mobile">
                  {data.details.mother.mobile || "-"}
                </Item>
                <Item label="Mother's Profession">
                  {data.details.mother.profession || "-"}
                </Item>
                <Item label="Mother's Salary">
                  {data.details.mother.salary || "-"}
                </Item>
              </Descriptions>
              <Descriptions
                title="Previous School/College"
                bordered
                style={{ marginTop: 20 }}
              >
                <Item label="Name">{data.details.prev_school.name || "-"}</Item>
                <Item label="City">{data.details.prev_school.city || "-"}</Item>
                <Item label="Percent/CGPA">
                  {data.details.prev_school.marks || "-"}
                </Item>
                <Item label="Previous Marks Card">
                  {(data.details.prev_school.marks_card &&
                    data.details.prev_school.marks_card.map((item, index) => {
                      return <span>{item.name}, </span>;
                    })) ||
                    "-"}
                </Item>
              </Descriptions>
            </Card>
            <div className="adm-buttons" style={{ marginTop: 20 }}>
              <Button
                type="primary"
                style={{ width: 200, minWidth: "unset" }}
                onClick={this.handleSubmit}
                loading={this.state.loading}
              >
                Submit
                {settings.adm_application_fees &&
                  settings.adm_application_fees > 0 && (
                    <span>
                      &nbsp;&amp; Pay ₹{settings.adm_application_fees}
                    </span>
                  )}
              </Button>
              <Button
                style={{ width: 200, minWidth: "unset" }}
                onClick={this.handleEdit}
              >
                Edit
              </Button>
            </div>
          </div>
        )}
        {status === "403" && (
          <Card style={{ marginBottom: 20 }}>
            <Result
              status="403"
              title="403 Forbidden"
              subTitle="Document expired"
              extra={
                <Button type="primary">
                  <Link
                    to={`/admissions/apply/${params.sch_code}/${params.sch_token}/`}
                    replace
                  >
                    Home
                  </Link>
                </Button>
              }
            />
          </Card>
        )}
      </Layout.Content>
    );
  }
}

export default ApplicationPreview;
