import React from "react";
import { Switch, Route } from "react-router-dom";
import StudentHomework from "./StudentHomework";
import StudentHomeworkViewer from "./StudentHomeworkViewer";

const StudentHomeworkRouter = (props) => (
  <Switch>
    <Route exact path="/homework/" component={StudentHomework} />
    <Route exact path="/homework/:id" component={StudentHomeworkViewer} />
  </Switch>
);

export { StudentHomeworkRouter };
