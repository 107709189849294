import React from "react";
import { Switch, Route } from "react-router-dom";
import SimpleTestList from "./SimpleTestsList";
import NewSimpleTest from "./NewSimpleTest";
import ViewTestSolutions from "./ViewTestSolutions";

const AdminSimpleTestsRouter = (props) => (
  <Switch>
    <Route exact path="/simple-test/" component={SimpleTestList} />
    <Route exact path="/simple-test/new" component={NewSimpleTest} />
    <Route exact path="/simple-test/:id" component={ViewTestSolutions} />
  </Switch>
);

export { AdminSimpleTestsRouter };
