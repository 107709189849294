import React, { Component } from "react";
import { Layout, Row, Col, Button, message, Table } from "antd";
import {
  ClassSectionDropdown,
  SubjectSelectionDropdown,
  OnlineTestQuestionTopicDropdown,
} from "../../../components";
import { api } from "../../../config";

class OnlineTestAddQuestions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questions: [],
      class_id: undefined,
      subject_id: undefined,
      topic: undefined,
      selected_questions: [],
      test_id: undefined,
    };
  }

  componentDidMount() {
    if (this.props.match.params.test_id) {
      this.setState({ test_id: this.props.match.params.test_id });
    }
  }

  get_questions_list = () => {
    let { class_id, subject_id, topic } = this.state;
    if (!(class_id && subject_id)) {
      message.error("Select both class and subject");
      return;
    }
    let url =
      "/ot/questions/" +
      class_id +
      "/" +
      subject_id +
      "/" +
      (topic ? topic + "/" : "");
    api
      .get(url)
      .then((res) => {
        if (res.data.success) {
          this.setState({ questions: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleAddQuestionsToTest = () => {
    let { test_id, selected_questions } = this.state;
    let data = {
      questions: selected_questions,
    };
    api
      .post(`/ot/test/${test_id}/questions/`, JSON.stringify(data))
      .then((res) => {
        if (res.data.success) {
          message.success("Questions added to test");
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const { class_id, subject_id, topic, questions } = this.state;
    const columns = [
      { title: "ID", dataIndex: "id" },
      { title: "Question", dataIndex: "question" },
      { title: "Topic", dataIndex: "topic" },
      { title: "Question Type", dataIndex: "type" },
      { title: "Marks", dataIndex: "correct_marks" },
    ];
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({ selected_questions: selectedRowKeys });
      },
    };
    return (
      <Layout.Content>
        <Row>
          <Col sm={12}>
            <h1>Add Question to Test</h1>
          </Col>
          <Col sm={12} className="action-buttons">
            <Button type="primary" onClick={this.handleAddQuestionsToTest}>
              Add
            </Button>
          </Col>
        </Row>
        <Row className="ribbon-row">
          <Col sm={24}>
            <span style={{ marginRight: 10 }}>
              <strong>Class : </strong>
              <ClassSectionDropdown
                value={class_id}
                onChange={(class_id) => this.setState({ class_id })}
              />
            </span>
            <span style={{ marginRight: 10 }}>
              <strong>Subject : </strong>
              <SubjectSelectionDropdown
                class={class_id}
                value={subject_id}
                onChange={(subject_id) => this.setState({ subject_id })}
              />
            </span>
            <span style={{ marginRight: 10 }}>
              <strong>Topic : </strong>
              <OnlineTestQuestionTopicDropdown
                subject_id={subject_id}
                value={topic}
                onChange={(topic) => this.setState({ topic })}
              />
            </span>
            <Button onClick={this.get_questions_list}>Go</Button>
          </Col>
        </Row>
        <Table
          columns={columns}
          dataSource={questions}
          rowKey="id"
          rowSelection={rowSelection}
          pagination={{ defaultPageSize: 25 }}
        />
      </Layout.Content>
    );
  }
}

export default OnlineTestAddQuestions;
