import React, { Component } from "react";
import { Layout, Table, Row, Col, Button, message, Icon } from "antd";
import { Link } from "react-router-dom";
import { api } from "../../../../config";
import moment from "moment";

class EmployeesList extends Component {
  state = {
    employees: [],
  };

  componentDidMount() {
    this.get_employees_list();
  }

  get_employees_list = () => {
    api
      .get("/hr/employees/")
      .then((res) => {
        if (res.data.success) {
          this.setState({ employees: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => console.log(err));
  };

  render() {
    const { employees } = this.state;
    const columns = [
      { title: "ID", dataIndex: "id" },
      { title: "Name", dataIndex: "name" },
      { title: "Job Title", dataIndex: "job_title" },
      {
        title: "DoB",
        dataIndex: "dob",
        render: (v) => <span>{moment(v).format("YYYY-MM-DD")}</span>,
      },
      {
        title: "Join Date",
        dataIndex: "join_date",
        render: (v) => <span>{moment(v).format("YYYY-MM-DD")}</span>,
      },
      { title: "Department", dataIndex: "department" },
      {
        title: "Actions",
        render: (r) => (
          <span>
            <Icon
              type="calculator"
              className="action-icon"
              onClick={() => {
                this.props.history.push(`/hr/employee/${r.id}/leaves`);
              }}
            />
          </span>
        ),
      },
    ];
    return (
      <Layout.Content>
        <Row>
          <Col sm={12}>
            <h1>Employees List</h1>
          </Col>
          <Col sm={12} style={{ textAlign: "right" }}>
            <Link to="/hr/departments/" style={{ marginRight: 20 }}>
              <Button type="primary">Departments</Button>
            </Link>
            <Link to="/hr/employee/new">
              <Button type="primary" icon="plus">
                New Employee
              </Button>
            </Link>
          </Col>
        </Row>
        <Table columns={columns} dataSource={employees} rowKey="id" />
      </Layout.Content>
    );
  }
}

export default EmployeesList;
