import React from "react";
import { Switch, Route } from "react-router-dom";
import RoutesList from "./RoutesList";
import RoutesForm from "./RoutesForm";
import StudentsList from "./StudentsList";

const AdminSimpleTransportationRouter = props => (
  <Switch>
    <Route exact path="/simple-transportation/routes/" component={RoutesList} />
    <Route
      exact
      path="/simple-transportation/routes/new"
      component={RoutesForm}
    />
    <Route
      exact
      path="/simple-transportation/route/:id"
      component={RoutesForm}
    />
    <Route
      exact
      path="/simple-transportation/students/:id"
      component={StudentsList}
    />
  </Switch>
);

export { AdminSimpleTransportationRouter };
