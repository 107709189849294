import React from "react";
import { Route, Switch } from "react-router";
import BillDetails from "./BillDetails";
import BillingList from "./BillingList";

const AdminBillingRouter = () => (
  <Switch>
    <Route exact path="/billing/" component={BillingList} />
    <Route exact path="/billing/:id" component={BillDetails} />
  </Switch>
);

export { AdminBillingRouter };
