import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Layout,
  message,
  Select,
} from "antd";
import React, { Component } from "react";
import { AccountsDropdown, Currency } from "../../../../components";
import { api } from "../../../../config";

class DepositForm extends Component {
  state = {
    deposit_type: undefined,
    loading: false,
    credit: {},
    debit: {},
  };

  handleSubmit = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        this.setState({ loading: true });
        api
          .put("/finance/deposits/", JSON.stringify(val))
          .then((res) => {
            if (res.data.success) {
              message.success("Deposit entry added");
              this.props.history.goBack();
            } else {
              message.error(res.data.error);
            }
            this.setState({ loading: false });
          })
          .catch((err) => {
            console.log(err);
            this.setState({ loading: false });
          });
      }
    });
  };

  __set_credit = (acc) => {
    this.setState({ credit: acc });
  };

  __set_debit = (acc) => {
    this.setState({ debit: acc });
  };

  render() {
    const { deposit_type, loading, debit, credit } = this.state;
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    const { Option } = Select;
    return (
      <Layout.Content>
        <h1 className="align-center">Deposit Form</h1>
        <Form wrapperCol={{ sm: 8 }} labelCol={{ sm: 8 }}>
          <FormItem label="Credit to account">
            {getFieldDecorator("fin_account_id", {
              rules: [{ required: true, message: "Account is required!" }],
            })(<AccountsDropdown account={this.__set_credit} />)}
            {credit.id && (
              <span>
                Balance : <Currency value={credit.credit - credit.debit} />
              </span>
            )}
          </FormItem>
          <FormItem label="Title/Name">
            {getFieldDecorator("name", {
              rules: [{ required: true, message: "Title is required!" }],
            })(<Input />)}
          </FormItem>
          <FormItem label="Description">
            {getFieldDecorator("description")(<Input.TextArea />)}
          </FormItem>
          <FormItem label="Deposit Type">
            {getFieldDecorator("deposit_type", {
              rules: [{ required: true, message: "Deposit Type is required!" }],
            })(
              <Select onChange={(val) => this.setState({ deposit_type: val })}>
                <Option value={1}>Fees</Option>
                <Option value={2}>Donations</Option>
                <Option value={3}>Transfer</Option>
                <Option value={4}>Other Deposit</Option>
              </Select>
            )}
          </FormItem>
          {deposit_type === 3 && (
            <FormItem label="Debit from account">
              {getFieldDecorator("from_account")(
                <AccountsDropdown account={this.__set_debit} />
              )}
              {debit.id && (
                <span>
                  Balance : <Currency value={debit.credit - debit.debit} />
                </span>
              )}
            </FormItem>
          )}
          <FormItem label="Date">
            {getFieldDecorator("date")(<DatePicker />)}
          </FormItem>
          <FormItem label="Amount">
            {getFieldDecorator("amount", {
              rules: [{ required: true, message: "Amount is required!" }],
            })(<InputNumber />)}
          </FormItem>
          <FormItem label="Mode">
            {getFieldDecorator("mode")(<Input />)}
          </FormItem>
          <FormItem wrapperCol={{ offset: 8 }}>
            <Button
              type="primary"
              loading={loading}
              onClick={this.handleSubmit}
            >
              Submit
            </Button>
          </FormItem>
        </Form>
      </Layout.Content>
    );
  }
}

export default Form.create()(DepositForm);
