import React from "react";
import { Switch, Route } from "react-router-dom";
import EmployeesList from "./employees/EmployeesList";
import Departments from "./employees/Departments";
import EmployeesForm from "./employees/EmployeesForm";
import HolidaysList from "./leaves/HolidaysList";
import EmployeeLeaves from "./leaves/EmployeeLeaves";
import LeavesTypes from "./leaves/LeavesTypes";
import EmployeeLeaveForm from "./leaves/EmployeeLeaveForm";

const AdminHrRouter = (props) => (
  <Switch>
    <Route exact path="/hr/employees/" component={EmployeesList} />
    <Route exact path="/hr/employee/new" component={EmployeesForm} />
    <Route exact path="/hr/employee/:id" component={EmployeesForm} />
    <Route exact path="/hr/departments/" component={Departments} />

    <Route exact path="/hr/holidays" component={HolidaysList} />
    <Route exact path="/hr/employee/:id/leaves" component={EmployeeLeaves} />
    <Route
      exact
      path="/hr/employee/:id/leave/assign"
      component={EmployeeLeaveForm}
    />
    <Route exact path="/hr/leaves/type/" component={LeavesTypes} />
  </Switch>
);

export { AdminHrRouter };
