import React, { Component } from "react";
import {
  Layout,
  Row,
  Col,
  Button,
  message,
  Table,
  Icon,
  Popconfirm,
  Tag,
  DatePicker,
} from "antd";
import { Link } from "react-router-dom";
import { api } from "../../../config";
import moment from "moment";
import { connect } from "react-redux";
import {
  SubjectSelectionDropdown,
  ClassSectionDropdown,
} from "../../../components";
import StudentSelector from "../../../components/StudentSelector";

let state = {
  loading: false,
  classes: [],
  student: null,
  class_id: undefined,
  subject_id: undefined,
  start_date_range: [],
  student_id: undefined,
  page: undefined,
};

class ClassRoomList extends Component {
  state = state;

  componentDidMount() {
    if ([2, 3, 4].includes(this.props.user.role)) {
      this.get_virtual_classes_list();
    }
  }

  componentWillUnmount() {
    state = this.state;
  }

  get_virtual_classes_list = (subject_id) => {
    this.setState({ loading: true });
    let url = "/virtual-classes/" + (subject_id ? subject_id + "/" : "");
    api
      .get(url)
      .then((res) => {
        if (res.data.success) {
          this.filter_classes_list(res.data.data);
        } else {
          message.error(res.data.error);
        }
        this.setState({ loading: false });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  filter_classes_list = (vc_classes) => {
    let { user, teacher_settings, teacher } = this.props;
    if (
      user.role === 2 &&
      teacher_settings &&
      teacher_settings.teacher_access_mode === "restricted"
    ) {
      let vc_cls = vc_classes.filter((item) => {
        return teacher.id === item.teacher_id;
      });
      this.setState({ classes: vc_cls });
    } else {
      this.setState({ classes: vc_classes });
    }
  };

  expandedRowRender = (r) => <p style={{ margin: 0 }}>{r.description}</p>;

  handleStudentChange = (val) => {
    let student = this.props.students.find((item) => {
      return item.id === val;
    });
    this.setState({ student, class_id: student.class_id, student_id: val });
  };

  cancelClass = (id) => {
    api
      .patch("/virtual-class/" + id + "/")
      .then((res) => {
        if (res.data.success) {
          message.success("Class Cancelled");
          this.get_virtual_classes_list();
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  deleteClass = (id) => {
    api
      .delete("/virtual-class/" + id + "/")
      .then((res) => {
        if (res.data.success) {
          message.success("Class Deleted");
          this.get_virtual_classes_list();
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleSubjectChange = (subject_id) => {
    let { student } = this.state;
    if (this.props.user.role === 1 && !student) {
      message.info("Select Student");
      return;
    }
    this.get_virtual_classes_list(subject_id);
    this.setState({ subject_id });
  };

  render() {
    const { user, students } = this.props;
    const { start_date_range } = this.state;
    const columns = [
      { title: "ID", dataIndex: "id" },
      {
        title: "Name",
        dataIndex: "name",
        render: (val, r) => {
          let url = `/virtual-classroom/${r.id}/`;
          let extras = r.extras;
          if (typeof extras === "string") {
            extras = JSON.parse(extras);
          }
          if (extras.class_type === "jitsi") {
            url = url + "jitsi";
          }
          return (
            <span>
              <Link
                to={{
                  pathname: url,
                  state: {
                    vc_class: r,
                    student: this.state.student,
                  },
                }}
              >
                {val}
              </Link>
            </span>
          );
        },
      },
      {
        title: "Class",
        render: (r) => <span>{r.class + " " + r.section}</span>,
      },
      { title: "Subject", dataIndex: "subject" },
      { title: "Teacher", dataIndex: "teacher" },
      {
        title: "Created on",
        sorter: (a, b) =>
          moment(a.timestamp).format("X") - moment(b.timestamp).format("X"),
        render: (r) => (
          <span>
            {moment.utc(r.timestamp).local().format("YYYY-MM-DD hh:mm A")}
          </span>
        ),
      },
      {
        title: "Start Time",
        dataIndex: "start_time",
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
        }) => (
          <div style={{ padding: 0 }}>
            <DatePicker.RangePicker
              autoFocus={true}
              value={selectedKeys}
              onChange={(val) => {
                // this.setState({ start_date_range: val });
                setSelectedKeys(val);
              }}
              style={{ padding: 10 }}
              size="small"
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                borderTop: "1px solid #e8e8e8",
              }}
            >
              <Button
                type="link"
                onClick={() => {
                  confirm();
                  this.setState({ start_date_range: selectedKeys });
                }}
              >
                OK
              </Button>
              <Button
                type="link"
                onClick={() => {
                  clearFilters();
                  this.setState({ start_date_range: [] });
                }}
              >
                Reset
              </Button>
            </div>
          </div>
        ),
        onFilter: (value, record) =>
          moment(start_date_range[0])
            .set({ hour: 0, minute: 0, second: 0 })
            .format("X") < moment(record.start_time).format("X") &&
          moment(record.start_time).format("X") <
            moment(start_date_range[1])
              .set({ hour: 23, minute: 59, second: 59 })
              .format("X"),
        sorter: (a, b) =>
          moment(a.start_time).format("X") - moment(b.start_time).format("X"),
        render: (v) => <span>{moment(v).format("YYYY-MM-DD hh:mm A")}</span>,
      },
      {
        title: "Status",
        dataIndex: "status",
        sorter: (a, b) => a.status - b.status,
        filters: [
          { text: "Cancelled", value: -1 },
          { text: "Upcoming", value: 0 },
          { text: "Ongoing", value: 1 },
          { text: "Ended", value: 2 },
          { text: "Stream", value: 3 },
        ],
        onFilter: (v, r) => v === r.status,
        render: (v, r, i) => (
          <span>
            {(() => {
              switch (v) {
                case -1:
                  return <Tag color="red">Cancelled</Tag>;
                case 0:
                  return <Tag color="cyan">Upcoming</Tag>;
                case 1:
                  return <Tag color="green">Ongoing</Tag>;
                case 2:
                  return <Tag color="volcano">Ended</Tag>;
                case 3:
                  return <Tag color="blue">Stream</Tag>;
                default:
                  return <Tag>Unknown</Tag>;
              }
            })()}
          </span>
        ),
      },
      {
        title: "Actions",
        render: (r) => (
          <span>
            {[2, 3, 4].includes(user.role) && (
              <>
                {r.status === 0 && (
                  <Popconfirm
                    title="Cancel the class?"
                    onConfirm={this.cancelClass.bind(this, r.id)}
                  >
                    <Icon
                      type="close-circle"
                      className="action-icon"
                      title="Cancel"
                    />
                  </Popconfirm>
                )}
                <Popconfirm
                  title="Delete the class?"
                  icon={
                    <Icon type="question-circle-o" style={{ color: "red" }} />
                  }
                  onConfirm={this.deleteClass.bind(this, r.id)}
                >
                  <Icon
                    type="delete"
                    className="action-icon absent"
                    title="Delete"
                  />
                </Popconfirm>
              </>
            )}
          </span>
        ),
      },
    ];
    const { class_id, subject_id, page } = this.state;
    return (
      <Layout.Content>
        <Row>
          <Col sm={8}>
            <h1>Virtual Classroom List</h1>
          </Col>
          <Col sm={14}>
            <div>
              {students.length > 0 && (
                <span>
                  <span>
                    <strong>Student : </strong>
                  </span>
                  <StudentSelector
                    value={this.state.student_id}
                    onChange={this.handleStudentChange}
                    style={{ width: 140, maxWidth: "100%", marginRight: 10 }}
                  />
                </span>
              )}
              <span>
                <strong>Class : </strong>
              </span>
              <ClassSectionDropdown
                value={class_id}
                disabled={students.length > 0}
                onChange={(class_id) => {
                  this.setState({ class_id });
                }}
              />
              <span>
                <strong>Subject : </strong>
              </span>
              <SubjectSelectionDropdown
                class={class_id}
                value={subject_id}
                onChange={this.handleSubjectChange}
              />
            </div>
          </Col>
          <Col sm={2} style={{ textAlign: "right" }}>
            {[2, 3, 4].includes(user.role) && (
              <Link to="/virtual-classroom/new">
                <Button type="primary" icon="plus">
                  New class
                </Button>
              </Link>
            )}
          </Col>
        </Row>
        <Table
          columns={columns}
          dataSource={this.state.classes}
          expandedRowRender={this.expandedRowRender}
          rowKey="id"
          loading={this.state.loading}
          pagination={{ pageSize: 50, current: page }}
          onChange={(pagination) => this.setState({ page: pagination.current })}
        />
        <div style={{ marginBottom: 40 }}></div>
      </Layout.Content>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    students: state.students,
    teacher: state.teacher,
    teacher_settings: state.teacher_settings,
  };
}

export default connect(mapStateToProps, null)(ClassRoomList);
