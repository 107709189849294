import React, { Component } from "react";
import {
  Button,
  Col,
  Layout,
  message,
  Row,
  Table,
  Icon,
  Input,
  Popconfirm,
} from "antd";
import { api } from "../../../../config";
import moment from "moment";
import Highlighter from "react-highlight-words";
import { Link } from "react-router-dom";
import { Currency } from "../../../../components";

class PayrollPaymentList extends Component {
  state = {
    payroll_list: [],
    searchText: undefined,
    searchColumn: undefined,
    id: undefined,
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      this.setState(
        { id: this.props.match.params.id },
        this._get_simple_payroll_list
      );
    } else {
      this._get_simple_payroll_list();
    }
  }

  _get_simple_payroll_list = () => {
    let { id } = this.state;
    let url = "/simple/payroll" + (id ? `/employee/${id}/` : "/");
    api
      .get(url)
      .then((res) => {
        if (res.data.success) {
          this.setState({ payroll_list: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search by name`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text, r) =>
      this.state.searchedColumn === dataIndex ? (
        <Link to={{ pathname: `/payroll/view/${r.id}`, state: { payroll: r } }}>
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        </Link>
      ) : (
        <Link to={{ pathname: `/payroll/view/${r.id}`, state: { payroll: r } }}>
          {text}
        </Link>
      ),
  });

  _delete_payroll_item = (id) => {
    api
      .delete(`/simple/payroll/${id}/`)
      .then((res) => {
        if (res.data.success) {
          message.success("Payroll item deleted");
          this._get_simple_payroll_list();
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const columns = [
      { title: "ID", dataIndex: "id" },
      {
        title: "Name",
        dataIndex: "emp_name",
        ...this.getColumnSearchProps("emp_name"),
      },
      {
        title: "Month",
        dataIndex: "month_year",
        render: (v) => <span>{moment.utc(v).format("MMM YY")}</span>,
      },
      {
        title: "Amount Paid",
        dataIndex: "amount_paid",
        align: "right",
        render: (v) => <Currency value={v} />,
      },
      {
        title: "Payment Date",
        dataIndex: "payment_date",
        render: (v) => (
          <span>{moment.utc(v).local().format("YYYY-MM-DD hh:mm A")}</span>
        ),
      },
      {
        title: "Action",
        render: (r) => (
          <span>
            <Icon type="edit" className="action-icon" />
            <Popconfirm
              title="Are you sure?"
              onConfirm={this._delete_payroll_item.bind(this, r.id)}
            >
              <Icon type="delete" className="action-icon absent" />
            </Popconfirm>
          </span>
        ),
      },
    ];
    return (
      <Layout.Content>
        <Row>
          <Col sm={12}>
            <h1>Payroll List</h1>
          </Col>
          <Col sm={12} className="action-buttons">
            <Button
              type="primary"
              onClick={() => this.props.history.push("/payroll/salary/")}
              ghost
            >
              Set Employee Salary
            </Button>
            <Button
              icon="plus"
              type="primary"
              style={{ marginLeft: 10 }}
              onClick={() => this.props.history.push("/payroll/new")}
            >
              New Entry
            </Button>
          </Col>
        </Row>
        <Table
          columns={columns}
          dataSource={this.state.payroll_list}
          rowKey="id"
        />
      </Layout.Content>
    );
  }
}

export default PayrollPaymentList;
