import React, { Component } from "react";
import { Icon, Button, Input } from "antd";
import CanvasDraw from "react-canvas-draw";

class WhiteBoard extends Component {
  state = {
    canvas: {
      canvasWidth: 800,
      canvasHeight: 800,
      lazyRadius: 0,
      brushRadius: 2,
      brushColor: "#444",
      imgSrc: ""
    }
  };

  componentDidMount() {
    this.set_canvas_dimensions();
    this.allow_image_drop();
  }

  allow_image_drop = () => {
    let canvas = document.querySelector("canvas");
    canvas.ondragover = e => {
      e.preventDefault();
    };
    canvas.ondrop = e => {
      console.log("Drop Event ", e);
    };
  };

  handleImageUpload = e => {
    let { canvas } = this.state;
    let reader = new FileReader();
    reader.onload = event => {
      var img = new Image();
      img.onload = () => {
        let c = this.canvas;
        let ctx = c.canvas.drawing.getContext("2d");
        ctx.drawImage(img, 0, 0, canvas.canvasWidth, canvas.canvasHeight);
      };
      img.src = event.target.result;
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  set_canvas_dimensions = () => {
    let { canvas } = this.state;
    let c = document.getElementById("canvas-parent");
    canvas.canvasWidth = c.offsetWidth;
    canvas.canvasHeight = c.offsetHeight;
    this.setState({ canvas });
  };

  render() {
    const { canvas } = this.state;
    return (
      <div className="whiteboard">
        <div className="whiteboard-controls">
          <Button.Group size="small" style={{ marginRight: 20 }}>
            <Button
              type="danger"
              onClick={() => {
                this.canvas.clear();
              }}
            >
              <Icon type="stop" />
              Clear
            </Button>
            <Button
              onClick={() => {
                this.canvas.undo();
              }}
            >
              Undo
              <Icon type="undo" />
            </Button>
          </Button.Group>
          <span className="whiteboard-control-item">
            <label>Color : </label>
            <Input
              type="color"
              size="small"
              className="color-picker"
              onChange={e => {
                let { canvas } = this.state;
                canvas.brushColor = e.currentTarget.value;
                this.setState({ canvas });
              }}
            />
          </span>
          <span className="whiteboard-control-item">
            <label>Image :</label>
            <Input type="file" size="small" onChange={this.handleImageUpload} />
          </span>
          <span className="whiteboard-close" onClick={this.props.close}>
            <Icon
              type="close-square"
              style={{ fontSize: 24, color: "#ff7875" }}
            />
          </span>
        </div>
        <div className="canvas-parent" id="canvas-parent">
          <CanvasDraw ref={canvas => (this.canvas = canvas)} {...canvas} />
        </div>
      </div>
    );
  }
}

export default WhiteBoard;
