const teacherSettingsReducer = (state = {}, action) => {
  switch (action.type) {
    case "GET_TEACHER_SETTINGS":
      return { ...action.payload };
    default:
      return state;
  }
};

export default teacherSettingsReducer;
