import React, { Component } from "react";
import { Layout, Row, Col, Table, message } from "antd";
import StudentSelector from "../../../components/StudentSelector";
import { api } from "../../../config";
import moment from "moment";
import { Link } from "react-router-dom";

let state = {
  tests: [],
  student_id: undefined,
};

class OnlineTestsList extends Component {
  constructor(props) {
    super(props);
    this.state = state;
  }

  componentWillUnmount() {
    state = this.state;
  }

  handleStudentChange = (student_id) => {
    this.setState({ student_id }, this.get_student_online_tests);
  };

  get_student_online_tests = () => {
    let { student_id } = this.state;
    api
      .get(`/ot/student/${student_id}/tests/`)
      .then((res) => {
        if (res.data.success) {
          this.setState({ tests: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const { student_id, tests } = this.state;
    const columns = [
      { title: "ID", dataIndex: "id" },
      {
        title: "Name",
        dataIndex: "name",
        render: (v, r) => (
          <span>
            <Link
              to={{
                pathname: `/online-test/${r.id}/`,
                state: { student_id, test: r },
              }}
            >
              {v}
            </Link>
          </span>
        ),
      },
      { title: "Category", dataIndex: "category" },
      { title: "Subject", dataIndex: "subject" },
      { title: "Pass Marks", dataIndex: "pass_marks" },
      { title: "Duration", dataIndex: "duration" },
      {
        title: "Start Time",
        dataIndex: "start_time",
        render: (v) => (
          <span>
            {v ? moment.utc(v).local().format("YYYY-MM-DD hh:mm A") : "-"}
          </span>
        ),
      },
    ];
    return (
      <Layout.Content>
        <Row gutter={24}>
          <Col sm={8}>
            <h1>Online Tests</h1>
          </Col>
          <Col sm={8}>
            <span>
              <strong>Student : </strong>
              <StudentSelector
                value={student_id}
                onChange={this.handleStudentChange}
              />
            </span>
          </Col>
        </Row>
        <Table columns={columns} dataSource={tests} rowKey="id" />
      </Layout.Content>
    );
  }
}

export default OnlineTestsList;
