import {
  Button,
  Card,
  Col,
  DatePicker,
  Empty,
  Layout,
  message,
  Row,
  Select,
  Table,
} from "antd";
import React, { Component } from "react";
import { Pie } from "react-chartjs-2";
import {
  AccountsDropdown,
  Currency,
  ExpensesCategoryDropdown,
  PaymentsModeDropdown,
  PrintTable,
  VendorsDropdown,
} from "../../../../components";
import { api } from "../../../../config";
import moment from "moment";

const colors = [
  "#7cb5ec",
  "#434348",
  "#90ed7d",
  "#f7a35c",
  "#8085e9",
  "#f15c80",
  "#e4d354",
  "#2b908f",
  "#f45b5b",
  "#91e8e1",
];

const style = {
  dropdown: {
    minWidth: 120,
  },
  ddblock: {
    display: "inline-block",
    marginBottom: 8,
    marginRight: 8,
  },
};

let state = {
  reports: {
    type: undefined,
    date: [undefined, undefined],
  },
  category: undefined,
  payment_mode: undefined,
  account: undefined,
  vendor: undefined,
  date: [undefined, undefined],
  expenses: [],
  exp_reports: [],
  exp_chart: {},
};

class ExpenseReports extends Component {
  state = state;

  componentDidMount() {}

  componentWillUnmount() {
    state = this.state;
  }

  _handleFilter = () => {
    const { category, payment_mode, account, vendor, date } = this.state;
    let params = `cat_id=${category || ""}&payment_mode=${
      payment_mode || ""
    }&account_id=${account || ""}&vendor_id=${vendor || ""}&start_date=${
      date[0] ? date[0].format("YYYY-MM-DD") : ""
    }&end_date=${date[1] ? date[1].format("YYYY-MM-DD") : ""}`;
    api
      .get(`/reports/expense/?filter&${params}`)
      .then((res) => {
        if (res.data.success) {
          this.setState({ expenses: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  exportExpenseReports = () => {
    let { expenses } = this.state;

    var csvContent = "data:text/csv;charset=utf-8,";
    let header =
      "ID,Name,Date,Category,Vendor,Payment Mode,Account,Total Amount,Paid Amount\r\n";
    csvContent += header;
    expenses.forEach((item) => {
      let line = `${item.id},${item.name},${moment(item.date).format(
        "YYYY-MM-DD"
      )},${item.exp_category || ""},${item.vendor || ""},${
        item.payment_mode || ""
      },${item.account},${item.total_amount},${item.installment_amount}\r\n`;
      csvContent += line;
    });
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute(
      "download",
      `expense_reports_${new Date().getTime()}.csv`
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  exportExpenseSubReports = () => {
    let { exp_reports } = this.state;

    var csvContent = "data:text/csv;charset=utf-8,";
    let header = "ID,Title,Amount\r\n";
    csvContent += header;
    exp_reports.forEach((item) => {
      let line = `${item.id},${item.title},${item.total_amount}\r\n`;
      csvContent += line;
    });
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute(
      "download",
      `expense_aggregate_reports_${new Date().getTime()}.csv`
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  _get_expense_report = () => {
    let { type, date } = this.state.reports;
    if (!type) {
      message.error("Report type is required!");
      return;
    }
    let params = `type=${type}&start_date=${
      date[0] ? date[0].format("YYYY-MM-DD") : ""
    }&end_date=${date[1] ? date[1].format("YYYY-MM-DD") : ""}`;
    api
      .get(`/reports/expense/?${params}`)
      .then((res) => {
        if (res.data.success) {
          let data = res.data.data;
          if (!Array.isArray(data)) {
            return;
          }
          this.setState({ exp_reports: data });
          this._generate_pie_chart(data);
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  _generate_pie_chart = (data) => {
    let labels = [],
      piedata = [];
    data.forEach((item) => {
      labels.push(item.title || "Others");
      piedata.push(item.total_amount);
    });
    let datasets = {
      datasets: [
        {
          data: piedata,
          backgroundColor: colors.slice(0, piedata.length),
        },
      ],
      labels,
    };
    this.setState({ exp_chart: datasets });
  };

  __print_expense_transactions_report = () => {
    let { date, expenses } = this.state;
    this.props.history.push("/finance/reports/expense/transactions/print", {
      expenses,
      date: [
        moment.isMoment(date[0]) ? date[0].format("YYYY-MM-DD") : undefined,
        moment.isMoment(date[1]) ? date[1].format("YYYY-MM-DD") : undefined,
      ],
    });
    console.log(date, expenses);
  };

  render() {
    const { Option } = Select;
    const columns = [
      { title: "ID", dataIndex: "id", render: (v) => <span>{v || "-"}</span> },
      {
        title: "Title",
        dataIndex: "title",
        render: (v) => <span>{v || "Others"}</span>,
      },
      {
        title: "Amount",
        dataIndex: "total_amount",
        align: "right",
        render: (v) => <Currency value={v} />,
      },
    ];
    const expense_columns = [
      { title: "ID", dataIndex: "id", print: true },
      { title: "Name", dataIndex: "name", print: true },
      {
        title: "Date",
        dataIndex: "date",
        print: true,
        render: (v) => (
          <span>{moment.utc(v).local().format("YYYY-MM-DD")}</span>
        ),
      },
      {
        title: "Total Amount",
        dataIndex: "total_amount",
        align: "right",
        print: true,
        render: (v) => <Currency value={v} />,
      },
      {
        title: "Paid Amount",
        dataIndex: "installment_amount",
        align: "right",
        print: true,
        render: (v) => <Currency value={v} />,
      },
    ];
    const { expenses, reports, exp_reports, exp_chart } = this.state;
    const { category, payment_mode, account, vendor, date } = this.state;
    return (
      <Layout.Content>
        <h1>Expense Reports</h1>
        <Row gutter={16}>
          <Col lg={12} sm={24}>
            <Card
              title="Expense Reports"
              extra={
                <span>
                  <Button
                    onClick={this.exportExpenseSubReports}
                    icon="export"
                    size="small"
                  >
                    CSV
                  </Button>
                  <Button
                    icon="file-pdf"
                    size="small"
                    style={{ marginLeft: 8 }}
                    onClick={() =>
                      this.props.history.push(
                        "/finance/reports/expense/print",
                        {
                          exp_reports,
                          reports: {
                            type: reports.type,
                            date: [
                              moment.isMoment(reports.date[0])
                                ? reports.date[0].format("YYYY-MM-DD")
                                : undefined,
                              moment.isMoment(reports.date[1])
                                ? reports.date[1].format("YYYY-MM-DD")
                                : undefined,
                            ],
                          },
                        }
                      )
                    }
                  >
                    PDF
                  </Button>
                </span>
              }
            >
              <div>
                <div
                  style={{
                    marginBottom: 8,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span>
                    <strong>Report Type : </strong>
                    <Select
                      value={reports.type}
                      onChange={(val) => {
                        let { reports } = this.state;
                        reports.type = val;
                        this.setState({ reports });
                      }}
                      style={{ minWidth: 150 }}
                      placeholder="Type"
                    >
                      <Option value="category">Categories</Option>
                      <Option value="payment_mode">Payment Mode</Option>
                      <Option value="account">Account</Option>
                      <Option value="vendor">Vendor</Option>
                    </Select>
                  </span>
                  <Button type="primary" onClick={this._get_expense_report}>
                    Go
                  </Button>
                </div>
                <div style={{ marginBottom: 8 }}>
                  <strong>Date : </strong>
                  <DatePicker.RangePicker
                    value={reports.date}
                    onChange={(val) => {
                      let { reports } = this.state;
                      reports.date = val;
                      this.setState({ reports });
                    }}
                  />
                </div>
              </div>
              <Table
                columns={columns}
                dataSource={exp_reports}
                pagination={false}
                rowKey="id"
              />
              <h3 style={{ margin: "16px 0px", textAlign: "center" }}>
                Pie Chart
              </h3>
              {Object.keys(exp_chart).length > 0 ? (
                <Pie data={exp_chart} />
              ) : (
                <Empty />
              )}
            </Card>
          </Col>
          <Col lg={12} sm={24}>
            <Card
              title="Expense Reports"
              extra={[
                <Button
                  size="small"
                  icon="export"
                  type="primary"
                  ghost
                  onClick={this.exportExpenseReports}
                >
                  CSV
                </Button>,
                <Button
                  size="small"
                  icon="file-pdf"
                  type="primary"
                  style={{ marginLeft: 8 }}
                  onClick={this.__print_expense_transactions_report}
                >
                  PDF
                </Button>,
              ]}
              style={{ marginBottom: 8 }}
            >
              <div>
                <span style={style.ddblock}>
                  <span>Category : </span>
                  <ExpensesCategoryDropdown
                    style={style.dropdown}
                    value={category}
                    onChange={(v) => this.setState({ category: v })}
                  />
                </span>
                <span style={style.ddblock}>
                  <span>Payment Mode : </span>
                  <PaymentsModeDropdown
                    style={style.dropdown}
                    value={payment_mode}
                    onChange={(v) => this.setState({ payment_mode: v })}
                  />
                </span>
                <span style={style.ddblock}>
                  <span>Account : </span>
                  <AccountsDropdown
                    style={style.dropdown}
                    value={account}
                    onChange={(v) => this.setState({ account: v })}
                  />
                </span>
                <span style={style.ddblock}>
                  <span>Vendor : </span>
                  <VendorsDropdown
                    style={style.dropdown}
                    value={vendor}
                    onChange={(v) => this.setState({ vendor: v })}
                  />
                </span>
                <span style={style.ddblock}>
                  <span>Date : </span>
                  <DatePicker.RangePicker
                    value={date}
                    onChange={(v) => this.setState({ date: v })}
                  />
                </span>
                <span style={style.ddblock}>
                  <Button
                    icon="filter"
                    type="primary"
                    ghost
                    onClick={this._handleFilter}
                  >
                    Filter
                  </Button>
                </span>
              </div>
              <Table columns={expense_columns} dataSource={expenses} />
              <PrintTable
                columns={expense_columns}
                data={expenses}
                type="Expense Reports"
              />
            </Card>
          </Col>
        </Row>
      </Layout.Content>
    );
  }
}

export default ExpenseReports;
