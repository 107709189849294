import React from "react";
import { Switch, Route } from "react-router-dom";

import InventoryList from "./InventoryList";
import InventoryCategories from "./InventoryCategories";
import InventoryForm from "./InventoryForm";

const AdminInventoryRouter = props => (
  <Switch>
    <Route exact path="/inventory" />
    <Route exact path="/inventory/list" component={InventoryList} />
    <Route exact path="/inventory/new" component={InventoryForm} />
    <Route exact path="/inventory/edit/:id" component={InventoryForm} />
    <Route exact path="/inventory/category" component={InventoryCategories} />
  </Switch>
);

export { AdminInventoryRouter };
