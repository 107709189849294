import React, { Component } from "react";
import {
  Layout,
  Row,
  Col,
  Table,
  message,
  Button,
  Card,
  Descriptions,
  DatePicker,
} from "antd";
import { api } from "../../../config";
import { SendTextMessages } from "../../../components";
import moment from "moment";

class SMSList extends Component {
  state = {
    messages: [],
    message_modal: false,
    card_data: {},
    start_date_range: [],
  };

  componentDidMount() {
    this.get_sms_log();
    this.get_sms_card_data();
  }

  get_sms_log = () => {
    api
      .get("/reports/sms/")
      .then((res) => {
        if (res.data && res.data.success) {
          this.setState({ messages: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  get_sms_card_data = () => {
    api
      .get("/reports/sms/?card")
      .then((res) => {
        if (res.data && res.data.success) {
          this.setState({ card_data: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  closeModal = () => {
    this.setState({ message_modal: false });
  };

  render() {
    const { message_modal, card_data, start_date_range } = this.state;
    const columns = [
      { title: "ID", dataIndex: "id" },
      { title: "To", dataIndex: "to" },
      { title: "Message", dataIndex: "message" },
      {
        title: "Time",
        dataIndex: "timestamp",
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
        }) => (
          <div style={{ padding: 0 }}>
            <DatePicker.RangePicker
              autoFocus={true}
              value={selectedKeys}
              onChange={(val) => {
                // this.setState({ start_date_range: val });
                setSelectedKeys(val);
              }}
              style={{ padding: 10 }}
              size="small"
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                borderTop: "1px solid #e8e8e8",
              }}
            >
              <Button
                type="link"
                onClick={() => {
                  confirm();
                  this.setState({ start_date_range: selectedKeys });
                }}
              >
                OK
              </Button>
              <Button
                type="link"
                onClick={() => {
                  clearFilters();
                  this.setState({ start_date_range: [] });
                }}
              >
                Reset
              </Button>
            </div>
          </div>
        ),
        onFilter: (value, record) =>
          moment(start_date_range[0]).startOf("day").format("X") <
            moment(record.timestamp).format("X") &&
          moment(record.timestamp).format("X") <
            moment(start_date_range[1]).endOf("day").format("X"),
        sorter: (a, b) =>
          moment(a.timestamp).format("X") - moment(b.timestamp).format("X"),
      },
      {
        title: "MSG Count",
        render: (record) => (
          <span>{Math.ceil(record.message.length / 160)}</span>
        ),
      },
      {
        title: "Status",
        render: (record) => (
          <span>
            {record.response &&
            (record.response.success === true ||
              record.response.status === "Success")
              ? "Sent"
              : record.response.code === "200 OK"
              ? "Sent"
              : "Failed"}
          </span>
        ),
      },
    ];
    const { Item } = Descriptions;
    return (
      <Layout.Content>
        <Row gutter={32}>
          <Col sm={12}>
            <h1>SMS List</h1>
          </Col>
          <Col sm={12} style={{ textAlign: "right" }}>
            <Button
              icon="plus"
              onClick={() => {
                this.setState({ message_modal: true });
              }}
            >
              New Message
            </Button>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col sm={24}>
            <Card>
              <Descriptions title="SMS Sent">
                <Item label="Today">{card_data.today}</Item>
                <Item label="This Week">{card_data.week}</Item>
                <Item label="This Month">{card_data.month}</Item>
              </Descriptions>
            </Card>
          </Col>
        </Row>
        <Table
          columns={columns}
          dataSource={this.state.messages}
          rowKey="id"
          pagination={{ defaultPageSize: 50 }}
        />
        {message_modal && <SendTextMessages closeModal={this.closeModal} />}
      </Layout.Content>
    );
  }
}

export default SMSList;
