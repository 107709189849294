import React, { Component } from "react";
import {
  Card,
  DatePicker,
  Checkbox,
  Form,
  Button,
  Spin,
  Input,
  message
} from "antd";
import { api } from "../../../config";
var moment = require("moment");

class StudentAttendanceEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      student: {},
      attendance: {}
    };
  }

  componentDidMount() {
    this.setState({ student: this.props.student });
  }

  static getDerivedStateFromProps(props, state) {
    if (props.student !== state.student) {
      state.student = props.student;
      if (state.student.id !== undefined) {
        state.loading = false;
      }
      return { ...state };
    }
    return null;
  }

  getAttendanceStatus = date => {
    const { student } = this.state;
    let dt = moment(date).format("YYYY-MM-DD");
    api
      .get("/attendance/student/" + student.id + "/" + dt + "/")
      .then(res => {
        if (res.data && res.data.success) {
          let data = res.data.data;
          if (!data.id) {
            message.error("Attendance Not Taken");
          }
          this.props.form.setFieldsValue({
            present: data.present
          });
          this.setState({ attendance: data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  handleSubmit = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        const { attendance } = this.state;
        val.id = attendance.id;
        api
          .post("/attendance/students/", JSON.stringify(val))
          .then(res => {
            if (res.data && res.data.success) {
              message.success("Attendance Updated");
            //   this.props.form.resetFields();
            } else {
              message.error(res.data.error);
            }
          })
          .catch(err => {
            console.log(err);
          });
      }
    });
  };

  render() {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    return (
      <Card title="Attendance Editor">
        <Spin spinning={this.state.loading}>
          <Form labelCol={{ sm: 8 }} wrapperCol={{ sm: 16 }}>
            <FormItem label="Date">
              {getFieldDecorator("date", { rules: [{ required: true }] })(
                <DatePicker onChange={this.getAttendanceStatus} />
              )}
            </FormItem>
            <FormItem label="Attendance">
              {getFieldDecorator("present", {
                rules: [{ required: true, message: "Attendance is required" }],
                valuePropName: "checked"
              })(<Checkbox>Present</Checkbox>)}
            </FormItem>
            <FormItem label="Reason">
              {getFieldDecorator("reason")(
                <Input placeholder="Reason for Changing" />
              )}
            </FormItem>
            <FormItem wrapperCol={{ offset: 8 }}>
              <Button onClick={this.handleSubmit}>Update Attendance</Button>
            </FormItem>
          </Form>
        </Spin>
      </Card>
    );
  }
}

export default Form.create()(StudentAttendanceEditor);
