import React, { Component } from "react";
import { Modal, Form, message, Checkbox, InputNumber } from "antd";
import {
  StudentNameSearchDropdown,
  STRouteListDropdown,
  STRouteStopsDropdown,
  AcademicYearDropdown,
} from "../../../../components";
import { api } from "../../../../config";

class AddStudentModal extends Component {
  state = {
    route_id: null,
    confirmLoading: false,
    fee_included: true,
  };

  componentDidMount() {
    if (this.props.route_id) {
      this.setState({ route_id: this.props.route_id });
    }
  }

  handleSubmit = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        this.setState({ confirmLoading: true });
        api
          .put("/simple/transportation/students/", JSON.stringify(val))
          .then((res) => {
            if (res.data.success) {
              message.success("Student Added to Route");
              this.props.closeModal();
            } else {
              message.error(res.data.error);
            }
            this.setState({ confirmLoading: false });
          })
          .catch((err) => {
            console.log(err);
            this.setState({ confirmLoading: false });
          });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const FormItem = Form.Item;
    const { route_id, confirmLoading, fee_included } = this.state;
    return (
      <Modal
        visible={this.props.visible}
        onCancel={this.props.closeModal}
        title="Add Student"
        confirmLoading={confirmLoading}
        onOk={this.handleSubmit}
      >
        <Form>
          <FormItem label="Student">
            {getFieldDecorator("student_id", {
              rules: [{ required: true, message: "Student is required!" }],
            })(<StudentNameSearchDropdown />)}
          </FormItem>
          <FormItem label="Academic Year">
            {getFieldDecorator("ac_year_id", {
              rules: [
                { required: true, message: "Academic year is required!" },
              ],
            })(<AcademicYearDropdown />)}
          </FormItem>
          <FormItem label="Bus Route">
            {getFieldDecorator("route_id", {
              rules: [{ required: true, message: "Bus route is requried!" }],
            })(
              <STRouteListDropdown
                onChange={(id) => {
                  this.setState({ route_id: id });
                }}
              />
            )}
          </FormItem>
          <FormItem label="Stop Name">
            {getFieldDecorator("stop_name", {
              rules: [{ required: true, message: "Stop name is required!" }],
            })(<STRouteStopsDropdown route_id={route_id} />)}
          </FormItem>
          <FormItem>
            {getFieldDecorator("fee_included", {
              initialValue: true,
              valuePropName: "checked",
            })(
              <Checkbox
                onChange={(e) =>
                  this.setState({ fee_included: e.target.checked })
                }
              >
                Transportation fee included in Main Fees?
              </Checkbox>
            )}
          </FormItem>
          {!fee_included && (
            <FormItem label="Fee Amount">
              {getFieldDecorator("amount", {
                initialValue: 0,
              })(<InputNumber />)}
            </FormItem>
          )}
        </Form>
      </Modal>
    );
  }
}

export default Form.create()(AddStudentModal);
