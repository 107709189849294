import React from "react";
import { Switch, Route } from "react-router-dom";
import RoutesList from "./routes/RoutesList";
import BusRouteListForm from "./routes/BusRouteListForm";
import DriversList from "./drivers/DriversList";
import DriverForm from "./drivers/DriverForm";
import VehiclesList from "./vehicles/VehiclesList";
import VehiclesForm from "./vehicles/VehiclesForm";
import BusStopsList from "./stops/BusStopsList";
import BusStopForm from "./stops/BusStopForm";
import BusRouteStops from "./routes/BusRouteStops";
import BusRouteStopsList from "./routes/BusRouteStopsList";
import StudentsList from "./students/StudentsList";
import TripsList from "./trips/TripsList";

const AdminTransportationRouter = props => (
  <Switch>
    <Route exact path="/transportation/drivers/" component={DriversList} />
    <Route exact path="/transportation/drivers/new" component={DriverForm} />
    <Route exact path="/transportation/driver/:id" component={DriverForm} />
    <Route exact path="/transportation/vehicles/" component={VehiclesList} />
    <Route exact path="/transportation/vehicles/new" component={VehiclesForm} />
    <Route exact path="/transportation/vehicle/:id" component={VehiclesForm} />
    <Route exact path="/transportation/stops/" component={BusStopsList} />
    <Route exact path="/transportation/stops/new" component={BusStopForm} />
    <Route exact path="/transportation/stop/:id" component={BusStopForm} />
    <Route exact path="/transportation/routes/" component={RoutesList} />
    <Route
      exact
      path="/transportation/routes/new"
      component={BusRouteListForm}
    />
    <Route
      exact
      path="/transportation/route/:id/"
      component={BusRouteListForm}
    />
    <Route
      exact
      path="/transportation/routes/stops/:id"
      component={BusRouteStopsList}
    />
    <Route
      exact
      path="/transportation/routes/stops/:id/edit"
      component={BusRouteStops}
    />
    <Route
      exact
      path="/transportation/students/"
      key={0}
      component={StudentsList}
    />
    <Route
      exact
      path="/transportation/students/:id"
      key={1}
      component={StudentsList}
    />
    <Route exact path="/transportation/trips/" component={TripsList} />
  </Switch>
);

export { AdminTransportationRouter };
