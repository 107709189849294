import React, { Component } from "react";
import { Layout, Form, Input, Select, Button, message, Spin } from "antd";
import { api } from "../../../../config";

class FeeItemsForm extends Component {
  state = {
    update: null,
    loading: false,
    spinning: false
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      this.getFeeItem(this.props.match.params.id);
      this.setState({ update: this.props.match.params.id });
    }
  }

  getFeeItem = id => {
    this.setState({ spinning: true });
    api
      .get("/fees/item/" + id + "/")
      .then(res => {
        if (res.data && res.data.success) {
          this.props.form.setFieldsValue(res.data.data);
        } else {
          message.error(res.data.error);
        }
        this.setState({ spinning: false });
      })
      .catch(err => {
        console.log(err);
        this.setState({ spinning: false });
      });
  };

  handleSubmit = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        if (!this.state.update) {
          this.addNewItem(val);
        } else {
          this.updateFeeItem(this.state.update, val);
        }
      }
    });
  };

  addNewItem = val => {
    this.setState({ loading: true });
    api
      .put("/fees/items/", JSON.stringify(val))
      .then(res => {
        if (res.data && res.data.success) {
          message.success("New fee item added");
          this.props.history.push("/fees/items/");
        } else {
          message.error(res.data.error);
        }
        this.setState({ loading: false });
      })
      .catch(err => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  updateFeeItem = (id, val) => {
    this.setState({ loading: true });
    api
      .post("/fees/item/" + id + "/", JSON.stringify(val))
      .then(res => {
        if (res.data && res.data.success) {
          message.success("Fee Item Updated");
          this.props.history.push("/fees/items/");
        } else {
          message.error(res.data.error);
        }
        this.setState({ loading: false });
      })
      .catch(err => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  render() {
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      }
    };
    const { Option } = Select;
    const { getFieldDecorator } = this.props.form;
    const FormItem = Form.Item;
    return (
      <Layout.Content>
        <h1 style={{ textAlign: "center" }}>
          {this.state.update ? "Update " : "Add "} Fee Item
        </h1>
        <Spin spinning={this.state.spinning}>
          <Form {...formItemLayout}>
            <FormItem label="Name">
              {getFieldDecorator("name", {
                rules: [{ required: true, message: "Please enter name!" }]
              })(<Input />)}
            </FormItem>
            <FormItem label="Fee Type">
              {getFieldDecorator("type", {
                rules: [{ required: true, message: "Please select fee type!" }]
              })(
                <Select>
                  <Option value="1">Primary</Option>
                  <Option value="2">Secondary</Option>
                  <Option value="3">Transportation</Option>
                  <Option value="4">Others</Option>
                </Select>
              )}
            </FormItem>
            <FormItem label="Amount">
              {getFieldDecorator("amount", {
                rules: [{ required: true, message: "Please enter amount!" }]
              })(<Input type="number" min="0" />)}
            </FormItem>
            <FormItem wrapperCol={{ offset: 8 }}>
              <Button
                type="primary"
                onClick={this.handleSubmit}
                loading={this.state.loading}
              >
                {this.state.update ? "Update" : "Add"}
              </Button>
            </FormItem>
          </Form>
        </Spin>
      </Layout.Content>
    );
  }
}

export default Form.create()(FeeItemsForm);
