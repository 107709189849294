import React, { Component } from "react";
import {
  Layout,
  Card,
  Descriptions,
  Row,
  Col,
  Button,
  message,
  Input,
  Icon,
} from "antd";
import { RecordRTCPromisesHandler, invokeSaveAsDialog } from "recordrtc";
import io from "socket.io-client";
import { baseurl, getToken, api } from "../../../config";
import { connect } from "react-redux";
import SimplePeer from "simple-peer";
import { MicOnIcon, MicOffIcon } from "../../../icons/mic";
import moment from "moment";

class VirtualClassRoom extends Component {
  state = {
    details: {},
    socket: null,
    status: {
      code: null,
      state: "NO_START",
      message: "Class not yet started",
    },
    peer: null,
    stream: null,
    mute: false,
    buttons: {
      start: false,
      stop: false,
      upload: false,
      download: false,
    },
    recorder: null,
    remote_stream: null,
    student: {},
    questions: [],
    question: "",
    video_details: null,
    video_playing: false,
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      this.get_class_details(this.props.match.params.id);
    }
    if (this.props.location.state.student) {
      this.setState({ student: this.props.location.state.student });
    }
    this.enter_press_detector();
  }

  enter_press_detector = () => {
    let q = document.getElementById("question");
    if (!q) return;
    q.onkeyup = (e) => {
      if (!(e.key === "Enter" && e.shiftKey)) {
        if (e.keyCode === 13) {
          this.handleSendQuestion();
        }
      }
    };
  };

  get_class_details = (id) => {
    api
      .get("/virtual-class/" + id + "/")
      .then((res) => {
        if (res.data.success) {
          let { status } = this.state;
          let details = res.data.data;
          let video_details = details.public_url;
          status.code = details.status;
          switch (status.code) {
            case -1:
              status.message = "Class cancelled";
              break;
            case 0:
              status.message = "Class not yet started";
              break;
            case 1:
              status.message = "Class started";
              break;
            case 2:
              status.message = "Class ended";
              break;
            case 3:
              status.message = "Streaming Available";
              break;
            default:
              status.message = "";
          }
          if (video_details) {
            video_details = JSON.parse(video_details);
          }
          this.get_permissions(status);
          this.setState({ details, status, video_details }, () => {
            if ([0, 1].includes(status.code)) {
              this.enable_socket();
            }
          });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {});
  };

  get_permissions = async (status) => {
    if (status.code === 1 || status.code === 0) {
      try {
        let stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: { width: { ideal: 320 }, height: { ideal: 240 } },
        });
        var video = document.getElementById("source-video");
        if ("srcObject" in video) {
          video.srcObject = stream;
        } else {
          video.src = window.URL.createObjectURL(stream); // for older browsers
        }
        video.play();
        this.source_video = video;
        if (this.image_interval) {
          clearInterval(this.image_interval);
        }
        this.image_interval = setInterval(this.take_pitcture, 2000);
        this.setState({ stream: stream });
      } catch (err) {
        if (err.name && err.name === "NotAllowedError") {
          message.error("Please allow Microphone and/or Camera Access");
        } else if (err.name && err.name === "NotFoundError") {
          message.error("Microphone or Camera not found");
          console.log(err);
        } else {
          console.log(err);
          message.error(err.message);
        }
      }
    }
  };

  take_pitcture = () => {
    var canvas = document.createElement("canvas");
    canvas.width = 320;
    canvas.height = 240;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(this.source_video, 0, 0, 320, 240);
    var dataURI = canvas.toDataURL("image/jpeg");
    let data = {
      type: "image",
      uri: dataURI,
    };
    this.state.peer && this.state.peer.send(JSON.stringify(data));
  };

  enable_socket = () => {
    let { details } = this.state;
    let data = {
      token: getToken(),
      vc_class: details,
      user: this.props.user,
    };
    let socket = io(baseurl);
    socket.on("connect", () => {
      socket.emit("vc_room", { type: "authenticate", data });
    });
    socket.on("vc_room", (data) => {
      console.log(data);
    });
    socket.on("vc_room_offer", (data) => {
      this.peer_connection(data);
    });
    socket.on("vc_room_session", (data) => {
      let { details } = this.state;
      try {
        if (data.type === "session" && details.id === data.data.vc_class.id) {
          if (data.data.status === "START") {
            this.setState({
              status: { state: "START", message: "Class Started", code: 1 },
            });
          } else if (data.data.status === "END") {
            let { stream } = this.state;
            stream &&
              stream.getTracks().forEach((track) => {
                track.stop();
              });
            clearInterval(this.image_interval);
            this.setState({
              status: { state: "END", message: "Class Ended", code: 2 },
            });
          } else if (data.data.status === "CANCEL") {
            this.setState({
              status: {
                state: "CANCEL",
                message: "Class Cancelled",
                code: -1,
              },
            });
          } else if (data.data.status === "RESTART") {
            this.join_class();
          }
        }
      } catch (err) {
        console.log(err);
      }
    });

    this.setState({ socket });
  };

  join_class = () => {
    let { socket, student } = this.state;
    let data = {
      type: "join",
      data: {
        student: student,
      },
    };
    socket.emit("vc_room", data);
  };

  peer_connection = (offer_data) => {
    let { stream } = this.state;
    var peer = new SimplePeer({ trickle: false, stream });
    peer.signal(JSON.parse(offer_data));
    peer.on("connect", () => {
      console.log("connected");
    });
    peer.on("error", (err) => {
      console.log(err);
    });
    peer.on("signal", (data) => {
      if (data && data.type && data.type === "answer") {
        let req = {
          type: "answer",
          data: JSON.stringify(data),
        };
        this.state.socket.emit("vc_room", req);
      }
    });
    peer.on("data", (data) => {
      console.log("Data", data);
    });
    peer.on("stream", (stream) => {
      if (stream.getVideoTracks().length > 0) {
        let { buttons } = this.state;
        var video = document.getElementById("video");
        if ("srcObject" in video) {
          video.srcObject = stream;
        } else {
          video.src = window.URL.createObjectURL(stream); // for older browsers
        }
        video.play();
        buttons.start = true;
        this.setState({ remote_stream: stream, buttons, video_playing: true });
      }
    });
    this.setState({ peer });
  };

  handleMute = () => {
    let { stream, mute } = this.state;
    stream.getAudioTracks().forEach((track) => {
      track.enabled = mute;
    });
    this.setState({ mute: !mute });
  };

  handleStartRecording = () => {
    let { remote_stream, buttons } = this.state;
    if (remote_stream) {
      let recorder = new RecordRTCPromisesHandler(remote_stream, {
        mimeType: "video/webm;codecs=h264",
      });
      recorder.startRecording();
      buttons.start = false;
      buttons.stop = true;
      this.setState({ buttons, recorder });
    }
  };

  handleStopRecording = () => {
    let { recorder, buttons } = this.state;
    buttons.download = true;
    buttons.start = false;
    buttons.stop = false;
    recorder.stopRecording();
    this.setState({ buttons });
  };

  handleUploadRecording = () => {};

  handleDownloadRecording = async () => {
    let { recorder, details } = this.state;
    let blob = await recorder.getBlob();
    invokeSaveAsDialog(blob, details.name);
  };

  handleSendQuestion = () => {
    let { question, questions } = this.state;
    if (question.length > 0) {
      questions.push(question);
      this.state.socket.emit("vc_room", { type: "question", data: question });
      this.setState({ questions, question: "" });
    }
  };

  handlePlayClick = () => {
    let { video_details, status } = this.state;
    if (status.code === 3 && video_details) {
      let video = document.getElementById("video");
      if (!video.src) {
        this.get_video_link();
      }
    }
  };

  get_video_link = (type) => {
    let { details, student } = this.state;
    api
      .get("/virtual-class/video/" + details.id + "/" + student.id + "/")
      .then((res) => {
        if (res.data.success) {
          let url = res.data.data.url;
          if (type && type === "download") {
            this.downloadFile(res.data.data);
          } else {
            this.play_video(url);
          }
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
        message.error("Network Error");
      });
  };

  downloadFile = (video) => {
    let { details } = this.state;
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = video.url;
    a.download = details.name;
    a.target = "_blank";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  play_video = (url) => {
    let video = document.getElementById("video");
    if (!video.src) {
      video.src = url;
    }
    video.play();
    this.setState({ video_playing: true });
  };

  render() {
    const Item = Descriptions.Item;
    const { details, status, mute, question, questions } = this.state;
    const { video_details, video_playing } = this.state;
    return (
      <Layout.Content className="student-virtual-classroom">
        <Row>
          <Col sm={12}>
            <h1>Virtual Class Room</h1>
          </Col>
          <Col sm={12} style={{ textAlign: "right" }}>
            {status.code > 1 && (
              <Button
                onClick={() => {
                  this.props.history.push({
                    pathname:
                      "/virtual-classroom/" + details.id + "/questions/",
                    state: { student: this.state.student },
                  });
                }}
              >
                Questions
              </Button>
            )}
            {status.code === 3 && (
              <Button
                icon="download"
                style={{ marginLeft: 10 }}
                onClick={this.get_video_link.bind(this, "download")}
              >
                Download
              </Button>
            )}
          </Col>
        </Row>
        <Card>
          <Descriptions>
            <Item label="Class Name">{details.name}</Item>
            <Item label="Class">{details.class + " " + details.section}</Item>
            <Item label="Description">{details.description}</Item>
            <Item label="Subject">{details.subject}</Item>
            <Item label="Teachers">{details.teacher}</Item>
            <Item label="Class Created On">{details.timestamp}</Item>
            <Item label="Start Time">
              {moment
                .utc(details.start_time)
                .local()
                .format("YYYY-MM-DD HH:mm A")}
            </Item>
            <Item label="End Time">
              {moment
                .utc(details.end_time)
                .local()
                .format("YYYY-MM-DD HH:mm A")}
            </Item>
            <Item></Item>
            <Item label="Actual Start Time">
              {details.actual_start_time
                ? moment
                    .utc(details.actual_start_time)
                    .local()
                    .format("YYYY-MM-DD HH:mm A")
                : "-"}
            </Item>
            <Item label="Actual End Time">
              {details.actual_end_time
                ? moment
                    .utc(details.actual_end_time)
                    .local()
                    .format("YYYY-MM-DD HH:mm A")
                : "-"}
            </Item>
            <Item></Item>
            <Item label="Status">
              <p style={{ margin: 0, fontWeight: "bold", color: "#1890ff" }}>
                {status.message}
              </p>
            </Item>
          </Descriptions>
        </Card>

        <Row style={{ margin: "20px 0" }}>
          <Col sm={4}>
            <Button
              type="primary"
              onClick={this.join_class}
              disabled={status.state !== "START"}
            >
              Join
            </Button>
          </Col>
          <Col sm={16}>
            {/* <Button.Group style={{ marginBottom: 16 }}>
              <Button
                disabled={!buttons.start}
                onClick={this.handleStartRecording}
              >
                <Icon type="play-circle" /> Start Recording
              </Button>
              <Button
                disabled={!buttons.stop}
                onClick={this.handleStopRecording}
              >
                Stop Recording
                <Icon type="stop" />
              </Button>
              <Button
                disabled={!buttons.upload}
                onClick={this.handleUploadRecording}
              >
                <Icon type="cloud-upload" />
                Upload
              </Button>
              <Button
                disabled={!buttons.download}
                onClick={this.handleDownloadRecording}
              >
                Download
                <Icon type="download" />
              </Button>
            </Button.Group> */}
          </Col>
          <Col sm={4} style={{ textAlign: "right" }}>
            <Button onClick={this.handleMute}>
              {!mute ? (
                <span>
                  <MicOffIcon style={{ marginRight: 6 }} />
                  Mute Local Audio
                </span>
              ) : (
                <span>
                  <MicOnIcon style={{ marginRight: 6 }} />
                  Unmute Local Audio
                </span>
              )}
            </Button>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col sm={16}>
            <div className="video-block">
              {(status.code === 1 || (status.code === 3 && video_details)) && (
                <>
                  {status.code === 2 && video_details ? (
                    <p>Click on play to play recorded video</p>
                  ) : (
                    <p>Video will start playing here once class starts</p>
                  )}
                  <div className="video-player">
                    {!video_playing && status.code === 3 && (
                      <Icon
                        type="play-circle"
                        className="play-icon"
                        onClick={this.handlePlayClick}
                      />
                    )}
                    <video
                      id="video"
                      controls
                      className="video"
                      onPlay={this.handlePlayClick}
                    ></video>
                  </div>
                </>
              )}
            </div>
          </Col>
          <Col sm={8}>
            {status.code < 2 && (
              <Card title="Questions">
                <div className="vc-question-block">
                  {questions.map((item, index) => {
                    return (
                      <div className="vc-question-item" key={index}>
                        {item}
                      </div>
                    );
                  })}
                </div>
                <div>
                  <Input.TextArea
                    placeholder="Type Your Question"
                    value={question}
                    onChange={(e) => {
                      this.setState({ question: e.target.value });
                    }}
                    id="question"
                    disabled={status.code !== 1}
                  />
                </div>
              </Card>
            )}
          </Col>
        </Row>

        {status.code < 2 && (
          <div className="source-video">
            <video id="source-video" muted={true}></video>
          </div>
        )}
      </Layout.Content>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps, null)(VirtualClassRoom);
