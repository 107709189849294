import React, { Component } from "react";
import {
  Layout,
  Row,
  Col,
  Table,
  Icon,
  message,
  Button,
  Popconfirm,
  Spin,
} from "antd";
import { api } from "../../../../config";
import { Currency } from "../../../../components";

class FeeItemsList extends Component {
  state = {
    fee_items: [],
    spinning: false,
  };

  componentDidMount() {
    this.getFeesItems();
  }

  getFeesItems = () => {
    this.setState({ spinning: true });
    api
      .get("/fees/items/")
      .then((res) => {
        if (res.data && res.data.success) {
          this.setState({ fee_items: res.data.data });
        } else {
          message.error(res.data.error);
        }
        this.setState({ spinning: false });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ spinning: false });
      });
  };

  handleDelete = (record) => {
    api
      .delete("/fees/item/" + record.id + "/")
      .then((res) => {
        if (res.data && res.data.success) {
          message.success("Fee item deleted");
          this.getFeesItems();
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const columns = [
      {
        title: "ID",
        dataIndex: "id",
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
        align: "right",
        render: (v) => <Currency value={v} />,
      },
      {
        title: "Type",
        render: (record) => (
          <span>
            {record.type === 1
              ? "Primary"
              : record.type === 2
              ? "Secondary"
              : record.type === 3
              ? "Transportation"
              : record.type === 4
              ? "Others"
              : "Others"}
          </span>
        ),
      },
      {
        title: "Actions",
        render: (record) => (
          <span>
            <Icon
              type="edit"
              className="action-icon"
              onClick={() => {
                this.props.history.push("/fees/items/" + record.id);
              }}
            />
            <Popconfirm
              title="Are you sure?"
              onConfirm={this.handleDelete.bind(this, record)}
            >
              <Icon type="delete" className="action-icon" />
            </Popconfirm>
          </span>
        ),
      },
    ];
    return (
      <Layout.Content>
        <Row>
          <Col sm={12}>
            <h1>Fee Items</h1>
          </Col>
          <Col sm={12} className="action-buttons">
            <Button
              type="primary"
              ghost
              icon="pie-chart"
              onClick={() => this.props.history.push("/fees/item/reports/")}
              style={{ marginRight: 10 }}
            >
              Reports
            </Button>
            <Button
              icon="plus"
              type="primary"
              onClick={() => this.props.history.push("/fees/items/new/")}
            >
              Add New
            </Button>
          </Col>
        </Row>
        <Spin spinning={this.state.spinning}>
          <Table
            columns={columns}
            dataSource={this.state.fee_items}
            rowKey="id"
            pagination={{ pageSize: 50 }}
          />
        </Spin>
      </Layout.Content>
    );
  }
}

export default FeeItemsList;
