import React from "react";
import {
  Layout,
  Card,
  message,
  Descriptions,
  Comment,
  Tooltip,
  Avatar,
  Input,
  Upload,
  Button,
  InputNumber,
  Popconfirm,
} from "antd";
import { api, baseurl, getToken } from "../../../../config";
import moment from "moment";
import HomeworkCorrector from "../../../teacher/homework/HomeworkCorrector";
import { connect } from "react-redux";

class ViewTestSolutions extends React.Component {
  state = {
    test: {},
    solutions: [],
    test_id: undefined,
    reply_id: undefined,
    loading: false,
    show_solution: false,
    solution_image: undefined,
    annotations: [],
    fileList: [],
    reply_text: "",
    solution_files: [],
    marks_obtained: undefined,
  };

  componentDidMount() {
    let test_id = this.props.match.params.id;
    let state = this.props.location.state;
    if (!(state && state.test)) {
      this.props.history.push("/simple-test/");
      return;
    }
    this.setState(
      { test_id, test: state.test },
      this._get_test_details_and_solutions
    );
  }

  _get_test_details_and_solutions = () => {
    let { test_id } = this.state;
    api
      .get(`/simple/test/${test_id}/solutions/`)
      .then((res) => {
        if (res.data.success) {
          this.setState({ solutions: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  _reply_answer = () => {
    let { marks_obtained, solution_files, reply_text, reply_id } = this.state;
    if (!marks_obtained) {
      message.error("Marks is required!");
      return;
    }
    let data = {
      marks_obtained,
      teacher_remarks: reply_text,
      teacher_files: solution_files,
      test_solution_id: reply_id,
      teacher_id: this.props.teacher.id,
    };
    this.setState({ loading: true });
    api
      .patch("/simple/test/attempts/", JSON.stringify(data))
      .then((res) => {
        if (res.data.success) {
          message.success("Marks Updated");
          this._get_test_details_and_solutions();
          this.setState({
            solution_files: [],
            fileList: [],
            marks_obtained: undefined,
            reply_text: "",
            reply_id: undefined,
          });
        } else {
          message.error(res.data.error);
        }
        this.setState({ loading: false });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  handleUpload = (info) => {
    if (info.file.status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === "done") {
      let { solution_files } = this.state;
      solution_files.push({
        uid: new Date().getTime(),
        path: info.file.response.path,
        name: info.file.name,
        size: info.file.size,
        type: info.file.type,
        status: "done",
        url: baseurl + "/" + info.file.response.path,
      });
      this.setState({ solution_files });
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
    this.setState({ fileList: [...info.fileList] });
  };

  closeModal = () => {
    this.setState({ show_solution: false, solution_image: null });
  };

  submitImage = (blob, img) => {
    let { annotations } = this.state;
    annotations.push(blob);
    this.uploadImage(blob);
    this.setState({
      annotations,
      solution_image: null,
      show_solution: false,
    });
  };

  uploadImage = (blob) => {
    let { solution_files, fileList } = this.state;
    let fd = new FormData();
    fd.append("file", blob, "attachment-file.png");
    let file = {
      uid: new Date().getTime(),
      name: "attachment-file.png",
      status: "uploading",
      size: blob.size,
      url: window.URL.createObjectURL(blob),
      type: blob.type,
    };
    api
      .post("/upload/?file_type=uploads", fd, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.status === 200) {
          message.success("File uploaded");
          file.url = baseurl + "/" + res.data.path;
          file.path = res.data.path;
          file.status = "done";
          solution_files.push(file);
          fileList.push(file);
          this.setState({ solution_files, fileList });
        } else {
          message.error(res.data.error);
          file.status = "error";
          solution_files.push(file);
          fileList.push(file);
          this.setState({ solution_files, fileList });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  removeFile = (file) => {
    let { solution_files } = this.state;
    let s = solution_files.filter((item) => {
      return file.uid === item.uid;
    });
    this.setState({ solution_files: s });
  };

  _delete_teacher_remarks = (attempt_id, extras) => {
    let { teacher, user } = this.props;
    if (!(teacher.id === extras.evaluator && user.id === extras.user_id)) {
      if (![3, 4].includes(user.role)) {
        message.error("You are not the evaluator");
        return;
      }
    }
    api
      .delete(`/simple/test/attempt/${attempt_id}/`)
      .then((res) => {
        if (res.data.success) {
          message.success("Teacher Remarks Removed");
          this._get_test_details_and_solutions();
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const {
      test,
      solutions,
      loading,
      reply_id,
      show_solution,
      solution_image,
    } = this.state;
    const files = (test.files && JSON.parse(test.files)) || [];
    const { Item } = Descriptions;
    return (
      <Layout.Content>
        <Card title="Test Details" style={{ marginBottom: 10 }}>
          <Descriptions>
            <Item label="Test Name">{test.test_name}</Item>
            <Item label="Subject">{test.subject}</Item>
            <Item label="Duration">{test.duration} mins</Item>
            <Item label="Start Time">
              {test.start_time
                ? moment
                    .utc(test.start_time)
                    .local()
                    .format("YYYY-MM-DD hh:mm A")
                : " - "}
            </Item>
            <Item label="Max Marks">{test.max_marks}</Item>
            <Item label="Pass Marks">{test.pass_marks}</Item>
            <Item label="Questions">
              {files.map((file, index) => {
                return (
                  <p key={index}>
                    <a
                      href={file.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {file.name}
                    </a>
                  </p>
                );
              })}
            </Item>
          </Descriptions>
        </Card>
        <Card title="Solutions">
          {solutions.map((item, index) => {
            let sfiles = JSON.parse(item.student_files) || [];
            let tfiles = JSON.parse(item.teacher_files) || [];
            let extras = JSON.parse(item.extras) || {};
            return (
              <Comment
                key={index}
                className={
                  item.status === 2
                    ? "hw_sol_with_remarks"
                    : "hw_sol_wo_remarks"
                }
                avatar={
                  <Avatar
                    src="https://www.w3schools.com/w3images/avatar6.png"
                    alt="Avatar"
                  />
                }
                author={item.student_name}
                content={
                  <div>
                    <p>{item.student_remarks}</p>
                    <p>(Marks Obtained : {item.marks_obtained})</p>
                    {sfiles.map((file, ind) => {
                      return (
                        <p key={ind}>
                          <a
                            href={file.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {file.name}
                          </a>
                          &nbsp; or &nbsp;
                          {[
                            "image/jpg",
                            "image/jpeg",
                            "image/png",
                            "image/gif",
                          ].includes(file.type) && (
                            <Button
                              type="dashed"
                              size="small"
                              onClick={() => {
                                this.setState({
                                  show_solution: true,
                                  solution_image: file.url,
                                  reply_id: item.id,
                                });
                              }}
                            >
                              Correct : {file.name}
                            </Button>
                          )}
                        </p>
                      );
                    })}
                  </div>
                }
                datetime={
                  <Tooltip
                    title={moment(item.start_time)
                      .utc()
                      .local()
                      .format("YYYY-MM-DD hh:mm A")}
                  >
                    <span>
                      {moment(item.start_time).utc().local().fromNow()}
                    </span>
                  </Tooltip>
                }
                actions={
                  item.status === 1 && [
                    <span
                      key="reply"
                      onClick={() => this.setState({ reply_id: item.id })}
                    >
                      Reply
                    </span>,
                  ]
                }
              >
                {item.status === 2 ? (
                  <div>
                    <Comment
                      avatar={
                        <Avatar
                          src="https://www.w3schools.com/howto/img_avatar2.png"
                          alt="Avatar"
                        />
                      }
                      actions={[
                        <Popconfirm
                          title="Are you sure?"
                          onConfirm={this._delete_teacher_remarks.bind(
                            this,
                            item.id,
                            extras
                          )}
                        >
                          <span>Delete Teacher Remarks</span>
                        </Popconfirm>,
                      ]}
                      content={
                        <div>
                          <p>{item.teacher_remarks}</p>
                          <div>
                            {tfiles.map((file, ind) => {
                              return (
                                <p key={ind}>
                                  <a
                                    href={file.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {file.name}
                                  </a>
                                </p>
                              );
                            })}
                          </div>
                        </div>
                      }
                    />
                  </div>
                ) : (
                  reply_id === item.id && (
                    <div>
                      <Input.TextArea
                        style={{ marginBottom: 10 }}
                        value={this.state.reply_text}
                        onChange={(e) =>
                          this.setState({ reply_text: e.target.value })
                        }
                      />
                      <div style={{ display: "flex" }}>
                        <div style={{ flex: 1 }}>
                          <Upload
                            name="file"
                            action={baseurl + "/upload/?file_type=uploads"}
                            fileList={this.state.fileList}
                            headers={{ Authorization: getToken() }}
                            accept=".jpg,.jpeg,.png,.pdf,.doc,.docx,.ppt,.pptx,.odt,.odp,.txt,.rtf,.mp4,.webm"
                            onChange={this.handleUpload}
                            multiple={true}
                            onRemove={this.removeFile}
                          >
                            <Button icon="upload">Attach File</Button>
                          </Upload>
                        </div>
                        <div>
                          <InputNumber
                            placeholder="Marks"
                            value={this.state.marks_obtained}
                            onChange={(val) =>
                              this.setState({ marks_obtained: val })
                            }
                          />
                        </div>
                        <div style={{ textAlign: "right", marginLeft: 10 }}>
                          <Button
                            type="primary"
                            onClick={this._reply_answer}
                            loading={loading}
                            disabled={!this.props.teacher.id}
                            title="Must be teacher to submit"
                          >
                            Submit
                          </Button>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </Comment>
            );
          })}
        </Card>
        {show_solution && (
          <HomeworkCorrector
            imageSrc={solution_image}
            closeModal={this.closeModal}
            submitImage={this.submitImage}
          />
        )}
      </Layout.Content>
    );
  }
}

function mapStateToProps(state) {
  return {
    teacher: state.teacher,
    user: state.user,
  };
}

export default connect(mapStateToProps, null)(ViewTestSolutions);
