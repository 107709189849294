import React from "react";
import { Switch, Route } from "react-router-dom";

import AccountsList from "./accounts/AccountsList";
import AccountsForm from "./accounts/AccountsForm";
import VendorsList from "./vendors/VendorsList";
import VendorsForm from "./vendors/VendorsForm";
import ExpenseCategory from "./expenses/ExpenseCategory";
import ExpensesList from "./expenses/ExpensesList";
import ExpensesForm from "./expenses/ExpensesForm";
import InventoryList from "./inventory/InventoryList";
import InventoryForm from "./inventory/InventoryForm";
import InventoryCategory from "./inventory/InventoryCategory";
import PaymentModes from "./expenses/PaymentModes";
import FinanceDashboard from "./dashboard/FinanceDashboard";
import Incomes from "./income/Incomes";
import DepositForm from "./income/DepositForm";
import FinanceReports from "./reports/FinanceReports";
import AccountEntries from "./accounts/AccountEntries";
import SimpleFinanceDashboard from "../simple/dashboard/SimpleFinanceDashboard";
import ExpensesByCategory from "./reports/ExpensesByCategory";
import ExpenseReports from "./reports/ExpenseReports";
import ExpenseDetails from "./expenses/ExpenseDetails";
import AccountTransfer from "./accounts/AccountTransfer";
import PrintExpenseReport from "./reports/PrintExpenseReport";
import PrintExpenseTransactionsReport from "./reports/PrintExpenseTransactionsReport";

const AdminFinanceRouter = (props) => (
  <Switch>
    <Route exact path="/finance/accounts/" component={AccountsList} />
    <Route exact path="/finance/account/:id" component={AccountEntries} />
    <Route exact path="/finance/accounts/new" component={AccountsForm} />
    <Route exact path="/finance/accounts/:id" component={AccountsForm} />
    <Route exact path="/finance/transfer/" component={AccountTransfer} />

    <Route exact path="/finance/vendors/" component={VendorsList} />
    <Route exact path="/finance/vendor/new" component={VendorsForm} />
    <Route exact path="/finance/vendor/:id" component={VendorsForm} />

    <Route
      exact
      path="/finance/expenses/category"
      component={ExpenseCategory}
    />
    <Route exact path="/finance/expenses/new" component={ExpensesForm} />
    <Route exact path="/finance/expenses/" component={ExpensesList} />
    <Route exact path="/finance/expense/reports" component={ExpenseReports} />
    <Route exact path="/finance/expense/:id" component={ExpensesForm} />
    <Route
      exact
      path="/finance/expense/:id/details"
      component={ExpenseDetails}
    />

    <Route exact path="/finance/inventory/" component={InventoryList} />
    <Route exact path="/finance/inventory/new" component={InventoryForm} />
    <Route
      exact
      path="/finance/inventory/category"
      component={InventoryCategory}
    />
    <Route exact path="/finance/inventory/:id" component={InventoryForm} />

    <Route exact path="/finance/payment-modes/" component={PaymentModes} />

    <Route exact path="/finance/income/" component={Incomes} />
    <Route exact path="/finance/income/new" component={DepositForm} />

    <Route exact path="/finance/reports/" component={FinanceReports} />

    <Route
      exact
      path="/finance/reports/expenses/category/:id"
      component={ExpensesByCategory}
    />

    <Route
      exact
      path="/finance/dashboard/"
      component={SimpleFinanceDashboard}
    />

    <Route exact path="/finance/" component={FinanceDashboard} />

    <Route
      exact
      path="/finance/reports/expense/print"
      component={PrintExpenseReport}
    />
    <Route
      exact
      path="/finance/reports/expense/transactions/print"
      component={PrintExpenseTransactionsReport}
    />
  </Switch>
);

export { AdminFinanceRouter };
