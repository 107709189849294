import React from "react";
import { Switch, Route } from "react-router-dom";
import AddNote from "../../teacher/notes/AddNote";
import NotesList from "../../teacher/notes/NotesList";

const AdminNotesRouter = (props) => (
  <Switch>
    <Route exact path="/notes" component={NotesList} />
    <Route exact path="/notes/new" component={AddNote} />
  </Switch>
);

export { AdminNotesRouter };
