import React from "react";
import { Switch, Route } from "react-router-dom";
import HomeworkList from "./HomeworkList";
import HomeworkForm from "./HomeworkForm";
import HomeworkViewer from "./HomeworkViewer";
import HomeworkDetails from "./HomeworkDetails";

const TeacherHomeworkRouter = (props) => (
  <Switch>
    <Route exact path="/homework/" component={HomeworkList} />
    <Route exact path="/homework/new/" component={HomeworkForm} />
    <Route exact path="/homework/:id/solutions" component={HomeworkDetails} />
    <Route exact path="/homework/:id" component={HomeworkViewer} />
  </Switch>
);

export { TeacherHomeworkRouter };
