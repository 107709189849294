import React from "react";
import { Switch, Route } from "react-router-dom";

import AttendanceList from "../../teacher/attendance/AttendanceList";
import AttendanceUpdate from "../../teacher/attendance/AttendanceUpdate";

const AdminAttendanceRouter = props => (
  <Switch>
    <Route exact path="/attendance/" component={AttendanceList} />
    <Route exact path="/attendance/update" component={AttendanceUpdate} />
  </Switch>
);

export { AdminAttendanceRouter };
