import React, { Component } from "react";
import { Select, message } from "antd";
import { api } from "../config";

class TeacherSelectionDropdown extends Component {
  state = { teachers: [] };

  componentDidMount() {
    this.getTeachers();
  }

  getTeachers = () => {
    api
      .get("/teachers/")
      .then(res => {
        if (res.data && res.data.success) {
          this.setState({ teachers: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  render() {
    return (
      <Select
        allowClear
        showSearch
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        onChange={this.props.onChange}
        value={this.props.value}
        style={this.props.style}
      >
        {this.state.teachers.map((item, index) => {
          return (
            <Select.Option value={item.id} key={index}>
              {item.fullname}
            </Select.Option>
          );
        })}
      </Select>
    );
  }
}

export default TeacherSelectionDropdown;
