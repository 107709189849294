import React, { useState, useEffect } from "react";
import { Radio, Checkbox, Input, Button, Upload, Icon } from "antd";
import { baseurl, getToken } from "../../../config";

export default function TestQuestion(props) {
  const { questions, question, answer, next, setAnswer } = props;
  const [files, setFiles] = useState([]);
  const [ans, setAns] = useState(undefined);
  const [option, setOption] = useState(undefined);

  useEffect(() => {
    let f = (question.files && JSON.parse(question.files)) || [];
    setFiles(f);
  }, [question]);

  useEffect(() => {
    answer && answer.answer ? setAns(answer.answer) : setAns(undefined);
    answer && answer.option ? setOption(answer.option) : setOption(undefined);
  }, [answer]);

  const handleSubmit = () => {
    answer.answer = ans;
    answer.state = 2;
    answer.answered = true;
    answer.option = option;
    answer.files = files;
    setAnswer(answer);
  };

  const handleClear = () => {
    answer.answer = undefined;
    answer.state = 0;
    answer.answered = false;
    answer.option = undefined;
    answer.files = [];
    setAnswer(answer);
    setAns(undefined);
    setFiles([]);
    setOption(undefined);
  };

  const handleNext = () => {
    handleSubmit();
    next();
  };

  const handleUpload = (e) => {
    const { status } = e.file;
    if (status === "done") {
      let file_list = e.fileList;
      file_list = file_list.map((item, index) => {
        return {
          url: baseurl + "/" + item.response.path,
          path: item.response.path,
          name: item.name,
          size: item.size,
          type: item.type,
          uid: new Date().getTime(),
          status: "done",
        };
      });
      setFiles(file_list);
    }
  };

  const uploadProps = {
    name: "file",
    multiple: true,
    action: baseurl + "/upload/?file_path=uploads",
    headers: { Authorization: getToken() },
    accept: ".jpg,.jpeg,.png,.gif,.mp4,.3gp,.mp3,.doc,.docx,.ppt,.pptx",
    onChange: handleUpload,
  };

  const answerLater = () => {
    if (ans || files.length > 0 || option) {
      answer.answer = ans;
      answer.state = 5;
      answer.answered = true;
      answer.option = option;
      answer.files = files;
    } else {
      answer.state = 3;
    }
    setAnswer(answer);
    next();
  };

  return (
    <div>
      <div className="question">
        <div className="question-head">
          <div className="question-text">
            <h2>
              Question {question.index + 1} : {question.question}
            </h2>
          </div>
        </div>
        <p>{question.description}</p>
        <div className="question-type">
          ({question.type === 0 && <span>Single Correct Answer</span>}
          {question.type === 1 && <span>One or More Correct Answer</span>}
          {question.type === 2 && <span>Subjective Answer (Text Entry)</span>}
          {question.type === 3 && <span>Media Answer Upload your answer</span>})
        </div>
      </div>
      <div className="question-images">
        {files.map((item, index) => {
          return (
            <div className="question-image" key={index}>
              <img src={item.url} alt="Question pic" />
            </div>
          );
        })}
      </div>
      <div className="question-answer">
        {question.type === 0 && (
          <div>
            <Radio.Group
              value={option}
              onChange={(e) => setOption(e.target.value)}
            >
              {question.options &&
                question.options.map((item, index) => {
                  const images = JSON.parse(item.files);
                  return (
                    <Radio key={index} value={item.id}>
                      <div className="option-radio-box">
                        <span className="option-text">{item.text}</span>
                        {images.map((image, i) => {
                          return (
                            <div key={i} className="option-image">
                              <img src={image.url} alt="Option pic" />
                            </div>
                          );
                        })}
                      </div>
                    </Radio>
                  );
                })}
            </Radio.Group>
          </div>
        )}
        {question.type === 1 && (
          <div>
            {(() => {
              let options = question.options.map((item, index) => {
                const images = JSON.parse(item.files);
                return {
                  value: item.id,
                  label: (
                    <div className="option-radio-box">
                      <span>{item.text}</span>
                      {images.map((image, i) => {
                        return (
                          <div key={i} className="option-image">
                            <img src={image.url} alt="Option pic" />
                          </div>
                        );
                      })}
                    </div>
                  ),
                };
              });
              return (
                <Checkbox.Group
                  options={options}
                  value={option}
                  onChange={(val) => setOption(val)}
                />
              );
            }).call(this)}
          </div>
        )}
        {question.type === 2 && (
          <div>
            <Input.TextArea
              autoFocus
              value={ans}
              onChange={(e) => setAns(e.target.value)}
              placeholder="Your Answer Here"
            />
          </div>
        )}
        {question.type === 3 && (
          <div>
            {answer.files &&
              answer.files.map((item, index) => {
                return (
                  <div key={index} style={{ display: "inline-block" }}>
                    <img
                      src={item.url}
                      alt="Answer attachment"
                      style={{ maxWidth: 100, maxHeight: 100 }}
                    />
                  </div>
                );
              })}
            <Upload.Dragger {...uploadProps}>
              <p className="ant-upload-drag-icon">
                <Icon type="inbox" />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint">
                Support for a single or bulk upload. Strictly prohibit from
                uploading company data or other band files
              </p>
            </Upload.Dragger>
          </div>
        )}
      </div>
      <div className="test-footer">
        <div className="icons-left">
          <Button onClick={answerLater}>
            {questions.length - 1 === question.index
              ? "Mark for Review"
              : "Mark for Review & Next"}
          </Button>
          <Button icon="stop" style={{ marginLeft: 10 }} onClick={handleClear}>
            Clear
          </Button>
        </div>
        <div className="icons-right">
          <Button icon="right-circle" onClick={handleNext} type="primary">
            {questions.length - 1 === question.index ? "Save" : "Save & Next"}
          </Button>
        </div>
      </div>
    </div>
  );
}
