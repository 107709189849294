import React, { Component } from "react";
import {
  Layout,
  Table,
  Row,
  Col,
  Button,
  message,
  Icon,
  Popconfirm,
  Spin,
  Modal,
  Upload,
} from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { ShowBreadcrumbs, AcademicYearDropdown } from "../../../../components";
import { api, baseurl, getToken } from "../../../../config";

class ClassesList extends Component {
  state = {
    classes: [],
    spinning: false,
    show_upload_modal: false,
    file: {},
    class_id: undefined,
    ac_year_id: undefined,
  };
  componentDidMount() {
    this.get_classes_list();
  }

  get_classes_list = () => {
    this.setState({ spinning: true });
    api
      .get("/class/")
      .then((res) => {
        if (res.data && res.data.success) {
          this.setState({ classes: res.data.data });
          this.rearrangeData(res.data.data);
        } else {
          message.error(res.data.error);
        }
        this.setState({ spinning: false });
      })
      .catch((err) => {
        message.error("Something Went Wrong");
        console.log(err);
        this.setState({ spinning: false });
      });
  };

  rearrangeData = (data) => {
    let classes = {};
    data.forEach((item) => {
      if (!(item.class in classes)) {
        classes[item.class] = [item.section];
      } else {
        classes[item.class].push(item.section);
      }
    });
    this.props.get_classes({ classes, data });
  };

  handleDelete = (id) => {
    api
      .delete("/class/" + id + "/")
      .then((res) => {
        if (res.data && res.data.success) {
          message.success("Class Deleted");
          this.get_classes_list();
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleDownloadClick = (class_id) => {
    Modal.info({
      title: "Note",
      content: (
        <div>
          <p>
            The admission no must be unique (unique for each student
            irrespective of class, branch and year),
          </p>
          <p>
            Please enter admission date and date of birth in YYYY-MM-DD format
            only. (if unknown, then enter present date in YYYY-MM-DD format)
          </p>
          <p>
            Mobile no is required and it'll be the student's username and
            password for login. (Please enter 10 digit mobile no without leading
            zero, spaces, hyphens and country code)
          </p>
        </div>
      ),
      onOk: () => {
        let url = "https://eduga.co.in/uploads/students_list.csv";
        let a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = "students_list_class_" + class_id + ".csv";
        a.target = "_blank";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      },
    });
  };

  closeModal = () => {
    this.setState({ show_upload_modal: false });
  };

  handleUploadClick = () => {
    this.closeModal();
    let data = {
      path: this.state.file.path,
      class_id: this.state.class_id,
      ac_year_id: this.state.ac_year_id,
    };
    api
      .put("/import/students/", JSON.stringify(data))
      .then((res) => {
        if (res.data.success) {
          message.success("Students Added");
          this.get_classes_list();
        } else if (res.data.errors.length > 0) {
          Modal.error({
            title: "Errors",
            content: (
              <div>
                {res.data.errors.map((item, index) => {
                  return <p>{item.fullname + " - " + item.error}</p>;
                })}
              </div>
            ),
            onOk: () => {
              this.get_classes_list();
            },
          });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
        message.error("Failed to add students");
      });
  };

  handleFileUpload = (info) => {
    const { status } = info.file;
    if (status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (status === "done") {
      let { file } = this.state;
      file = {
        url: baseurl + "/" + info.file.response.path,
        path: info.file.response.path,
        name: info.file.name,
        size: info.file.size,
        type: info.file.type,
        uid: new Date().getTime(),
      };
      this.setState({ file });
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  render() {
    const { show_upload_modal } = this.state;
    const columns = [
      {
        title: "Class",
        key: "class",
        dataIndex: "class",
      },
      {
        title: "Section",
        key: "section",
        dataIndex: "section",
      },
      {
        title: "Class Teacher",
        key: "class_teacher_name",
        dataIndex: "class_teacher_name",
      },
      {
        title: "Students",
        key: "students_count",
        dataIndex: "students_count",
      },
      {
        title: "Actions",
        render: (record) => (
          <span>
            <Icon
              type="edit"
              className="action-icon"
              onClick={() => {
                this.props.history.push("/academics/classes/" + record.id);
              }}
            />
            <Icon
              type="download"
              className="action-icon"
              title="Download Template"
              onClick={this.handleDownloadClick.bind(this, record.id)}
            />
            <Icon
              type="cloud-upload"
              className="action-icon"
              title="Upload Students List"
              style={{ color: "#1890ff" }}
              onClick={() =>
                this.setState({ show_upload_modal: true, class_id: record.id })
              }
            />
            <Popconfirm
              title="Are you sure?"
              onConfirm={this.handleDelete.bind(this, record.id)}
            >
              <Icon type="delete" className="action-icon absent" />
            </Popconfirm>
          </span>
        ),
      },
    ];
    return (
      <Layout.Content>
        <ShowBreadcrumbs
          items={[
            { name: "Home", link: "/" },
            { name: "Academics", link: "/academics/" },
            { name: "Classes", link: "/academics/classes/" },
          ]}
        />
        <Row className="ribbon-row">
          <Col sm={20}>
            <h1>Classes</h1>
          </Col>
          <Col sm={4} style={{ textAlign: "right" }}>
            <Link to="/academics/classes/new">
              <Button type="primary" icon="plus" className="action-button">
                Add Class
              </Button>
            </Link>
          </Col>
        </Row>
        <Spin spinning={this.state.spinning}>
          <Table
            columns={columns}
            dataSource={this.state.classes}
            rowKey="id"
          />
        </Spin>
        {show_upload_modal && (
          <Modal
            title="Import students list"
            visible={true}
            okText="Upload"
            onCancel={this.closeModal}
            onOk={this.handleUploadClick}
          >
            <div style={{ marginBottom: 20 }}>
              <strong>Academic Year ID : </strong>
              <AcademicYearDropdown
                value={this.state.ac_year_id}
                onChange={(val) => this.setState({ ac_year_id: val })}
              />
            </div>
            <Upload.Dragger
              name="file"
              action={baseurl + "/upload/?file_path=documents"}
              headers={{ Authorization: getToken() }}
              accept=".csv"
              onChange={this.handleFileUpload}
            >
              <p className="ant-upload-drag-icon">
                <Icon type="inbox" />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
            </Upload.Dragger>
          </Modal>
        )}
      </Layout.Content>
    );
  }
}

function mapStateToProps(state) {
  return {
    teachers: state.teachers,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    get_classes: (payload) => {
      dispatch({ type: "GET_CLASSES", payload });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClassesList);
