import React, { Component } from "react";
import {
  Layout,
  Table,
  Row,
  Col,
  Form,
  Input,
  Button,
  message,
  Icon,
  Popconfirm
} from "antd";
import { api } from "../../../../config";

class InventoryCategories extends Component {
  state = {
    categories: []
  };

  componentDidMount() {
    this.get_inventory_categories();
  }

  get_inventory_categories = () => {
    api
      .get("/simple/inventory/category/")
      .then(res => {
        if (res.data && res.data.success) {
          this.setState({ categories: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  add_inventory_category = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        api
          .put("/simple/inventory/category/", JSON.stringify(val))
          .then(res => {
            if (res.data && res.data.success) {
              message.success("New Category Added");
              this.get_inventory_categories();
            } else {
              message.error(res.data.error);
            }
          })
          .catch(err => {
            console.log(err);
          });
      }
    });
  };

  render() {
    const columns = [
      { title: "ID", dataIndex: "id" },
      { title: "Name", dataIndex: "name" },
      {
        title: "Actions",
        render: record => (
          <span>
            <Popconfirm title="Are you sure?">
              <Icon type="delete" className="action-icon" />
            </Popconfirm>
          </span>
        )
      }
    ];
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    return (
      <Layout.Content>
        {/* <h1>Inventory Category</h1> */}
        <Row gutter={32}>
          <Col sm={12}>
            <h2>Inventory Categories List</h2>
            <Table
              columns={columns}
              dataSource={this.state.categories}
              rowKey="id"
            />
          </Col>
          <Col sm={8}>
            <h2>Add New Category</h2>
            <div>
              <Form>
                <FormItem label="Inventory Category Name">
                  {getFieldDecorator(`name`, {
                    rules: [
                      { required: true, message: "Please input category name" }
                    ]
                  })(<Input placeholder="Category Name" />)}
                </FormItem>
                <Button type="primary" onClick={this.add_inventory_category}>
                  Add New
                </Button>
              </Form>
            </div>
          </Col>
        </Row>
      </Layout.Content>
    );
  }
}

export default Form.create()(InventoryCategories);
