import React, { Component } from "react";
import {
  Layout,
  Table,
  Row,
  Col,
  DatePicker,
  Button,
  message,
  Form,
  Checkbox,
  Input,
} from "antd";
import moment from "moment";
import {
  ClassSectionDropdown,
  AcademicYearDropdown,
} from "../../../components";
import { api } from "../../../config";

class AttendanceUpdate extends Component {
  state = {
    class_id: null,
    date: moment(),
    students_list: [],
    ac_year_id: null,
  };

  componentDidMount() {}

  classChange = (val) => {
    this.setState({ class_id: val });
  };

  dateChange = (val) => {
    this.setState({ date: val.format("YYYY-MM-DD") });
  };

  handleGo = () => {
    let { class_id, date, ac_year_id } = this.state;
    if (!(class_id && date && ac_year_id)) {
      message.error("Please Select class, section and date");
      return;
    }
    this.getAttendance(class_id, date, ac_year_id);
  };

  getAttendance = (class_id, date, ac_year_id) => {
    let _date = date && date.format("YYYY-MM-DD");
    let url =
      "/attendance/students/" + class_id + "/" + _date + "/" + ac_year_id + "/?list=true";
    api
      .get(url)
      .then((res) => {
        if (res.data && res.data.success) {
          this.setState({ students_list: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleSave = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        val.class_id = this.state.class_id;
        val.date = this.state.date;
        val.ac_year_id = this.state.ac_year_id;
        api
          .put("/attendance/students/", JSON.stringify(val))
          .then((res) => {
            if (res.data && res.data.success) {
              message.success("Attendance Taken");
              this.props.history.push("/attendance/");
            } else {
              message.error(res.data.error);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  render() {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    const columns = [
      {
        title: "Sl. No.",
        render: (v, r, i) => <span>{i + 1}</span>,
      },
      {
        title: "Admn. No.",
        dataIndex: "admission_no",
      },
      {
        title: "Name",
        dataIndex: "fullname",
      },
      {
        title: "Present",
        render: (text, record, index) => (
          <span>
            <FormItem style={{ margin: 0 }}>
              {getFieldDecorator(`attendance[${index}].att_id`, {
                initialValue: record.att_id || null,
              })(<Input type="hidden" />)}
            </FormItem>
            <FormItem style={{ margin: 0 }}>
              {getFieldDecorator(`attendance[${index}].student_id`, {
                initialValue: record.student_id,
              })(<Input type="hidden" />)}
            </FormItem>
            <FormItem style={{ margin: 0 }}>
              {getFieldDecorator(`attendance[${index}].present`, {
                initialValue: record.present || true,
                valuePropName: "checked",
              })(<Checkbox />)}
            </FormItem>
          </span>
        ),
      },
      // {
      //   title: "Remarks",
      //   render: (text, record, index) => (
      //     <span>
      //       <FormItem style={{ margin: 0, textAlign: "center" }}>
      //         {getFieldDecorator(`attendance[${index}].extras.remarks`)(
      //           <Input />
      //         )}
      //       </FormItem>
      //     </span>
      //   )
      // }
    ];
    return (
      <Layout.Content>
        <h1>Update Attendance</h1>
        <Row gutter={32} style={{ marginBottom: 20 }}>
          <Col sm={6}>
            <span>Class : </span>
            <ClassSectionDropdown
              value={this.state.class_id}
              onChange={this.classChange}
            />
          </Col>
          <Col sm={4}>
            <span>AC Year : </span>
            <AcademicYearDropdown
              value={this.state.ac_year_id}
              onChange={(ac_year_id) => {
                this.setState({ ac_year_id });
              }}
            />
          </Col>
          <Col sm={4}>
            <span>Date : </span>
            <DatePicker
              defaultValue={moment()}
              onChange={this.dateChange}
              style={{ width: 160, maxWidth: "100%" }}
            />
          </Col>
          <Col sm={2}>
            <Button onClick={this.handleGo}>Go</Button>
          </Col>
          <Col sm={8} style={{ textAlign: "right" }}>
            <Button type="primary" onClick={this.handleSave}>
              Save Attendance
            </Button>
          </Col>
        </Row>
        <Form>
          <Table
            columns={columns}
            dataSource={this.state.students_list}
            rowKey="student_id"
            pagination={false}
          />
        </Form>
      </Layout.Content>
    );
  }
}

export default Form.create()(AttendanceUpdate);
