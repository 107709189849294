import { Form, Input, Radio, Button } from "antd";
import React, { Component } from "react";

class StudentProfile extends Component {
  state = {};

  handleSubmit = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        console.log(val);
        let { current } = this.props;
        this.props.set_page(current + 1);
      }
    });
  };

  _previous_screen = () => {
    let { current } = this.props;
    this.props.set_page(current - 1);
  };

  render() {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <Form wrapperCol={{ sm: 8 }} labelCol={{ sm: 8 }}>
          <FormItem label="USN/SATS">
            {getFieldDecorator("usn")(<Input />)}
          </FormItem>
          <FormItem label="Aadhar No.">
            {getFieldDecorator("aadhar_no")(<Input />)}
          </FormItem>
          <FormItem label="Religion">
            {getFieldDecorator("religion")(<Input />)}
          </FormItem>
          <FormItem label="Caste">
            {getFieldDecorator("caste")(<Input />)}
          </FormItem>
          <FormItem label="Gender">
            {getFieldDecorator("gender")(
              <Radio.Group>
                <Radio value="Male">Male</Radio>
                <Radio value="Female">Female</Radio>
                <Radio value="Others">Others</Radio>
              </Radio.Group>
            )}
          </FormItem>
          <FormItem label="Blood Group">
            {getFieldDecorator("blood_group")(<Input />)}
          </FormItem>
          <FormItem label="Admission Quota">
            {getFieldDecorator("quota")(<Input />)}
          </FormItem>
          <FormItem wrapperCol={{ offset: 8, sm: 8 }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button type="primary" ghost onClick={this._previous_screen}>
                Prev
              </Button>
              <Button type="primary" onClick={this.handleSubmit}>
                Next
              </Button>
            </div>
          </FormItem>
        </Form>
      </div>
    );
  }
}

export default Form.create()(StudentProfile);
