import React, { Component } from "react";
import {
  Layout,
  Row,
  Col,
  Table,
  Form,
  Button,
  Icon,
  message,
  InputNumber,
} from "antd";
import {
  AcademicYearDropdown,
  ClassSectionDropdown,
  ExamSelectorDropdown,
} from "../../../components";
import { api } from "../../../config";

class AssignExam extends Component {
  state = {
    exams: [],
    loading: false,
  };

  componentDidMount() {
    this.get_exam_assignments();
  }

  get_exam_assignments = () => {
    api
      .get("/exam/assign/")
      .then((res) => {
        if (res.data.success) {
          this.setState({ exams: res.data.data });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleSave = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        this.setState({ loading: true });
        api
          .put("/exam/assign/", JSON.stringify(val))
          .then((res) => {
            if (res.data.success) {
              message.success("Exam Assigned");
              this.get_exam_assignments();
            } else {
              message.error(res.data.error);
            }
            this.setState({ loading: false });
          })
          .catch((err) => {
            console.log(err);
            this.setState({ loading: false });
          });
      }
    });
  };

  deleteExam = () => {};

  render() {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    const columns = [
      { title: "ID", dataIndex: "id" },
      { title: "Exam", dataIndex: "exam" },
      { title: "Academic Year", dataIndex: "ac_year" },
      {
        title: "Class",
        render: (r) => <span>{r.class + " " + r.section}</span>,
      },
      { title: "Max Attemps", dataIndex: "max_attempts" },
      {
        title: "Actions",
        render: (v, r) => (
          <span>
            <Icon type="delete" className="action-icon" />
          </span>
        ),
      },
    ];
    return (
      <Layout.Content>
        <h1>Assign Exam to class</h1>
        <Row gutter={64}>
          <Col sm={12}>
            <Table
              columns={columns}
              dataSource={this.state.exams}
              rowKey="id"
            />
          </Col>
          <Col sm={12}>
            <h3>Assign Exam to Class</h3>
            <Form>
              <Row gutter={24}>
                <Col sm={12}>
                  <FormItem label="Exam">
                    {getFieldDecorator("exam_id", {
                      rules: [{ required: true, message: "Exam is required!" }],
                    })(<ExamSelectorDropdown />)}
                  </FormItem>
                </Col>
                <Col sm={12}>
                  <FormItem label="Academic Year">
                    {getFieldDecorator("ac_year_id", {
                      rules: [
                        {
                          required: true,
                          message: "Academic Year is required!",
                        },
                      ],
                    })(<AcademicYearDropdown />)}
                  </FormItem>
                </Col>
                <Col sm={12}>
                  <FormItem label="Class">
                    {getFieldDecorator("class_id", {
                      rules: [
                        { required: true, message: "Class is required!" },
                      ],
                    })(<ClassSectionDropdown style={{ width: 160 }} />)}
                  </FormItem>
                </Col>
                <Col sm={12}>
                  <FormItem label="Max Attempts">
                    {getFieldDecorator("max_attempts")(<InputNumber />)}
                  </FormItem>
                </Col>
                <Col sm={24}>
                  <FormItem>
                    <Button
                      type="primary"
                      onClick={this.handleSave}
                      loading={this.state.loading}
                    >
                      Add
                    </Button>
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Layout.Content>
    );
  }
}

export default Form.create()(AssignExam);
