import React, { Component } from "react";
import { Form, Input, Button, message } from "antd";
import { api } from "../../config";

class ChangePassword extends Component {
  state = {};

  handleFormSubmit = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        if (val.new_password !== val.confirm_password) {
          this.props.form.setFields({
            confirm_password: {
              value: val.confirm_password,
              errors: [new Error("Passwords do not match")]
            }
          });
          return;
        }
        api
          .post("/user/change-password/", JSON.stringify(val))
          .then(res => {
            if (res.data && res.data.success) {
              message.success("Password Changed");
            } else {
              message.error(res.data.error);
            }
          })
          .catch(err => {
            console.log(err);
          });
      }
    });
  };

  render() {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <Form>
          <FormItem label="Old Password">
            {getFieldDecorator("old_password", {
              rules: [
                { required: true, message: "Old password is required" },
                { min: 8, message: "Password must be at least 8 letters" }
              ],
              validateTrigger: ["onBlur"]
            })(<Input type="password" placeholder="Old Password" />)}
          </FormItem>
          <FormItem label="New Password">
            {getFieldDecorator("new_password", {
              rules: [
                { required: true, message: "New password is required" },
                { min: 8, message: "New password must be at least 8 letters" }
              ],
              validateTrigger: ["onBlur"]
            })(<Input type="password" placeholder="New Password" />)}
          </FormItem>
          <FormItem label="Confirm New Password">
            {getFieldDecorator("confirm_password", {
              rules: [
                { required: true, message: "Confirm new password is required" },
                {
                  min: 8,
                  message: "Confirm password must be at least 8 letters"
                }
              ],
              validateTrigger: ["onBlur"]
            })(<Input type="password" placeholder="Confirm New Password" />)}
          </FormItem>
          <FormItem>
            <Button onClick={this.handleFormSubmit} type="primary">
              Change Password
            </Button>
          </FormItem>
        </Form>
      </div>
    );
  }
}

export default Form.create()(ChangePassword);
