import React, { Component } from "react";
import {
  Layout,
  Form,
  Input,
  Select,
  Row,
  Col,
  TimePicker,
  Icon,
  Button,
  message,
  Modal
} from "antd";
import moment from "moment";
import { api } from "../../../config";

class TimetableTiming extends Component {
  state = {
    items_count: 1,
    timing: []
  };

  componentDidMount() {
    this.get_timing();
  }

  get_timing = () => {
    api
      .get("/timetable/timing/")
      .then(res => {
        if (res.data && res.data.success) {
          this.setState(
            {
              timing: res.data.data,
              items_count: res.data.data.length || 1
            },
            () => {
              this.set_data(res.data.data);
            }
          );
        } else {
          message.error(res.data.error);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  set_data = data => {
    data.map((item, index) => {
      item.start_time = moment(item.start_time);
      item.end_time = moment(item.end_time);
      return item;
    });
    let timing_data = { timing: data };
    this.props.form.setFieldsValue(timing_data);
  };

  addItem = () => {
    let items_count = this.state.items_count;
    items_count++;
    this.setState({ items_count });
  };

  removeItem = () => {
    let items_count = this.state.items_count;
    items_count--;
    this.setState({ items_count });
  };

  handleSave = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        api
          .put("/timetable/timing/", JSON.stringify(val))
          .then(res => {
            if (res.data && res.data.success) {
              message.success("School Timings Updated");
              this.get_timing();
            } else {
              message.error(res.data.error);
            }
          })
          .catch(err => {
            console.log(err);
          });
      }
    });
  };

  showConfirm = () => {
    Modal.confirm({
      title: "Are you sure?",
      content: "Changing timing will delete all timetable data.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: this.handleSave,
      onCancel: () => {
        message.info("Timetable not updated");
      }
    });
  };

  render() {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    const items_count = this.state.items_count;
    return (
      <Layout.Content>
        <Row>
          <Col sm={8}>
            <h1>Timetable Timing</h1>
          </Col>
          <Col sm={12} />
          <Col sm={4}>
            <Button onClick={this.showConfirm} type="primary">
              Save
            </Button>
          </Col>
        </Row>
        <Form>
          <div className="tt-timings">
            <table>
              <thead>
                <tr>
                  <th>Sl. No.</th>
                  <th>Type</th>
                  <th>Name</th>
                  <th>Start Time</th>
                  <th>End Time</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {[...Array(items_count).keys()].map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        <FormItem style={{ margin: 0 }}>
                          {getFieldDecorator(`timing[${index}].period_type`)(
                            <Select style={{ width: 100 }}>
                              <Select.Option value={index + 1}>
                                Class
                              </Select.Option>
                              <Select.Option value={0}>Break</Select.Option>
                            </Select>
                          )}
                        </FormItem>
                      </td>
                      <td>
                        <FormItem style={{ margin: 0 }}>
                          {getFieldDecorator(`timing[${index}].name`)(
                            <Input style={{ width: 150 }} />
                          )}
                        </FormItem>
                      </td>
                      <td>
                        <FormItem style={{ margin: 0 }}>
                          {getFieldDecorator(`timing[${index}].start_time`)(
                            <TimePicker format="HH:mm" minuteStep={5} />
                          )}
                        </FormItem>
                      </td>
                      <td>
                        <FormItem style={{ margin: 0 }}>
                          {getFieldDecorator(`timing[${index}].end_time`)(
                            <TimePicker format="HH:mm" minuteStep={5} />
                          )}
                        </FormItem>
                      </td>
                      <td>
                        <div>
                          {items_count - item === 1 ? (
                            <Icon
                              type="plus-circle"
                              style={{ fontSize: 24 }}
                              onClick={this.addItem}
                            />
                          ) : (
                            <Icon
                              type="minus-circle"
                              style={{ fontSize: 24 }}
                              onClick={this.removeItem}
                            />
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </Form>
      </Layout.Content>
    );
  }
}

export default Form.create()(TimetableTiming);
