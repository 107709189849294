import { Button, Card, Col, Descriptions, Layout, message, Row } from "antd";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import io from "socket.io-client";
import { api, getToken, baseurl } from "../../../config";

class JitsiClassroom extends Component {
  state = {
    id: undefined,
    details: {},
    status: {
      code: null,
      message: "",
    },
    extras: {},
    socket: {},
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      this.setState(
        { id: this.props.match.params.id },
        this.get_classroom_details
      );
    }
  }

  componentWillUnmount() {
    const { socket } = this.state;
    if (socket && socket.disconnect) socket.disconnect();
  }

  get_classroom_details = () => {
    let { id, status } = this.state;
    api
      .get(`/virtual-class/${id}/`)
      .then((res) => {
        if (res.data.success) {
          let details = res.data.data;
          status.code = details.status;
          switch (status.code) {
            case -1:
              status.message = "Class cancelled";
              break;
            case 0:
              status.message = "Class not yet started";
              break;
            case 1:
              status.message = "Class started";
              break;
            case 2:
              status.message = "Class ended";
              break;
            case 3:
              status.message = "Streaming Available";
              break;
            default:
              status.message = "Not your class";
          }
          let { extras } = details;
          if (typeof extras === "string") {
            extras = JSON.parse(extras);
          }
          this.setState({ details, status, extras }, this.enable_socket);
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  enable_socket = () => {
    let { details } = this.state;
    let data = {
      token: getToken(),
      vc_class: details,
      user: this.props.user,
    };
    let socket = io(baseurl);
    socket.on("connect", () => {
      socket.emit("vc_room", { type: "authenticate", data });
    });
    socket.on("vc_room_session", (data) => {
      let { details } = this.state;
      try {
        if (data.type === "session" && details.id === data.data.vc_class.id) {
          if (data.data.status === "START") {
            this.setState({
              status: { state: "START", message: "Class Started", code: 1 },
            });
          } else if (data.data.status === "END") {
            this.setState({
              status: { state: "END", message: "Class Ended", code: 2 },
            });
          } else if (data.data.status === "CANCEL") {
            this.setState({
              status: {
                state: "CANCEL",
                message: "Class Cancelled",
                code: -1,
              },
            });
          } else if (data.data.status === "RESTART") {
            this.join_class();
          }
        }
      } catch (err) {
        console.log(err);
      }
    });

    this.setState({ socket });
  };

  startJitSiClass = () => {
    const { details, extras } = this.state;
    const { jitsi_meeting_id } = extras;
    const jitsi = document.getElementById("jitsi-vc-container");
    const { user, teacher } = this.props;
    if (jitsi) {
      jitsi.style.zIndex = 1024;
      jitsi.style.display = "block";
    }
    const domain = "meet.jit.si";
    const options = {
      roomName: jitsi_meeting_id,
      height: "100%",
      parentNode: document.getElementById("jitsi-vc-container"),
      interfaceConfigOverwrite: {
        SHOW_JITSI_WATERMARK: false,
        APP_NAME: details.name,
        HIDE_INVITE_MORE_HEADER: true,
        NATIVE_APP_NAME: "Eduga Virtual Classroom",
        JITSI_WATERMARK_LINK: false,
      },
      configOverwrite: {
        disableSimulcast: false,
        disableInviteFunctions: true,
        localRecording: {
          enabled: true,
          format: "flac",
        },
        requireDisplayName: false,
      },
      userInfo: {
        email: user.email,
        displayName: teacher.fullname,
      },
    };
    try {
      const app = new window.JitsiMeetExternalAPI(domain, options);
      app.addEventListener("videoConferenceJoined", (e) => {
        app.executeCommand("displayName", teacher.name);
        this.start_virtual_classroom();
      });
      app.addEventListener("videoConferenceLeft", (e) => {
        jitsi.style.display = "none";
        jitsi.innerHTML = "";
      });
    } catch (err) {
      console.log(err);
    }
  };

  start_virtual_classroom = () => {
    this.state.socket.emit("vc_room", {
      type: "session",
      data: { status: "START", vc_class: this.state.details },
    });
  };

  end_virtual_class = () => {
    this.state.socket.emit("vc_room", {
      type: "session",
      data: { status: "END", vc_class: this.state.details },
    });
  };

  restart_virtual_class = () => {
    this.startJitSiClass();
  };

  render() {
    const Item = Descriptions.Item;
    const { details, status } = this.state;
    const { user, teacher } = this.props;
    return (
      <Layout.Content>
        <Row gutter={16}>
          <Col sm={12}>
            <h1>Virtual Classroom</h1>
          </Col>
          <Col sm={12} className="action-buttons">
            {user.role === 2 && details.teacher_id === teacher.id && (
              <>
                {[0, 1].includes(status.code) && (
                  <Button
                    type="primary"
                    onClick={this.startJitSiClass}
                    style={{ marginRight: 8 }}
                  >
                    {status.code === 1 ? "Continue" : "Start"}
                  </Button>
                )}
                {status.code === 1 && (
                  <Button type="danger" onClick={this.end_virtual_class}>
                    End Class
                  </Button>
                )}
                <Button
                  onClick={() => {
                    this.props.history.push({
                      pathname:
                        "/virtual-classroom/" + details.id + "/questions/",
                      state: { student: {} },
                    });
                  }}
                >
                  Questions
                </Button>
              </>
            )}
          </Col>
        </Row>
        <Card>
          <Descriptions>
            <Item label="Class Name">{details.name}</Item>
            <Item label="Class">{details.class + " " + details.section}</Item>
            <Item label="Description">{details.description}</Item>
            <Item label="Subject">{details.subject}</Item>
            <Item label="Teachers">{details.teacher}</Item>
            <Item label="Class Created On">{details.timestamp}</Item>
            <Item label="Start Time">
              {moment
                .utc(details.start_time)
                .local()
                .format("YYYY-MM-DD HH:mm A")}
            </Item>
            <Item label="End Time">
              {moment
                .utc(details.end_time)
                .local()
                .format("YYYY-MM-DD HH:mm A")}
            </Item>
            <Item />
            <Item label="Actual Start Time">
              {details.actual_start_time
                ? moment
                    .utc(details.actual_start_time)
                    .local()
                    .format("YYYY-MM-DD HH:mm A")
                : "-"}
            </Item>
            <Item label="Actual End Time">
              {details.actual_end_time
                ? moment
                    .utc(details.actual_end_time)
                    .local()
                    .format("YYYY-MM-DD HH:mm A")
                : "-"}
            </Item>
            <Item />
            <Item label="Status">
              <p style={{ margin: 0, fontWeight: "bold", color: "#1890ff" }}>
                {status.message}
              </p>
            </Item>
          </Descriptions>
        </Card>
        <div id="jitsi-vc-container"></div>
      </Layout.Content>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    teacher: state.teacher,
  };
}

export default connect(mapStateToProps, null)(JitsiClassroom);
