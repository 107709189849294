import React from "react";
import { Route, Switch } from "react-router";
import EmployeeSalary from "./EmployeeSalary";
import EmployeeSalaryForm from "./EmployeeSalaryForm";
import EmployeesList from "./EmployeesList";
import PayrollPaymentForm from "./PayrollPaymentForm";
import PayrollPaymentList from "./PayrollPaymentList";
import PayrollViewer from "./PayrollViewer";
import PayrollDashboard from "./SimplePayrollDashboard";

const AdminSimplePayrollRouter = (props) => (
  <Switch>
    <Route exact path="/payroll/" component={PayrollDashboard} />
    <Route exact path="/payroll/employees/" component={EmployeesList} />
    <Route exact path="/payroll/list" component={PayrollPaymentList} />
    <Route exact path="/payroll/new" component={PayrollPaymentForm} />
    <Route exact path="/payroll/view/:id" component={PayrollViewer} />
    <Route exact path="/payroll/salary" component={EmployeeSalaryForm} />
    <Route
      exact
      path="/payroll/employee/:id/salary"
      component={EmployeeSalary}
    />
    <Route exact path="/payroll/:id" component={PayrollPaymentList} />
  </Switch>
);

export { AdminSimplePayrollRouter };
