import { Descriptions, Layout, message, Table } from "antd";
import React, { Component } from "react";
import { Currency } from "../../../../components";
import { api } from "../../../../config";
import moment from "moment";
import { Link } from "react-router-dom";

class StudentsList extends Component {
  state = {
    students: [],
    fee_name: "",
  };

  componentDidMount() {
    if (this.props.location.search) {
      let s = this.props.location.search;
      let p = new URLSearchParams(s);
      let name = p.get("name");
      this.setState({ fee_name: name });
      this.__get_students_list(s);
    }
  }

  __get_students_list = (name) => {
    api
      .get(`/fees/create/${name}`)
      .then((res) => {
        if (res.data.success) {
          this.setState({ students: res.data.data });
          console.info(res.data.data);
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const { Item } = Descriptions;
    const { students, fee_name } = this.state;
    const columns = [
      { title: "ID", dataIndex: "id" },
      {
        title: "Student Name",
        dataIndex: "student_name",
        render: (v, r) => <Link to={`/fees/records/${r.student_id}`}>{v}</Link>,
      },
      { title: "Class", dataIndex: "class" },
      {
        title: "Total Amount",
        dataIndex: "total_amount",
        align: "right",
        render: (v) => <Currency value={v} />,
      },
      {
        title: "Paid Amount",
        dataIndex: "paid_amount",
        align: "right",
        render: (v) => <Currency value={v} />,
      },
      {
        title: "Timestamp",
        dataIndex: "timestamp",
        render: (v) => (
          <span>{moment.utc(v).local().format("YYYY-MM-DD hh:mm A")}</span>
        ),
      },
    ];
    return (
      <Layout.Content>
        <h1>Students List</h1>
        <Descriptions>
          <Item label="Fee Name">{fee_name}</Item>
        </Descriptions>
        <Table
          columns={columns}
          dataSource={students}
          rowKey="id"
          pagination={{ pageSize: 50 }}
        />
      </Layout.Content>
    );
  }
}

export default StudentsList;
