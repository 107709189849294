import React, { useState } from "react";
import { Layout, Row, Col, message, Table } from "antd";
import { Link } from "react-router-dom";
import StudentSelector from "../../../../components/StudentSelector";
import { api } from "../../../../config";
import moment from "moment";

export default function SimpleTestsList(props) {
  const [student, setStudent] = useState(undefined);
  const [tests, setTests] = useState([]);

  const _get_tests_list = (student_id) => {
    setStudent(student_id);
    let url = `/simple/tests/${student_id}/`;
    api
      .get(url)
      .then((res) => {
        if (res.data.success) {
          setTests(res.data.data);
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const columns = [
    { title: "ID", dataIndex: "id" },
    {
      title: "Test Name",
      dataIndex: "test_name",
      render: (v, r) => (
        <Link
          to={{
            pathname: `/simple-test/${r.id}`,
            state: { student_id: student, test: r },
          }}
        >
          {v}
        </Link>
      ),
    },
    { title: "Subject", dataIndex: "subject" },
    {
      title: "Duration",
      dataIndex: "duration",
      render: (v) => <span>{v} min</span>,
    },
    {
      title: "Timestamp",
      dataIndex: "timestamp",
      render: (v) => (
        <span>{moment.utc(v).local().format("YYYY-MM-DD hh:mm A")}</span>
      ),
    },
  ];

  return (
    <Layout.Content>
      <Row gutter={16}>
        <Col sm={8}>
          <h1>Tests List</h1>
        </Col>
        <Col sm={16}>
          <span>
            <strong>Student : </strong>
            <StudentSelector
              value={student}
              onChange={(val) => {
                _get_tests_list(val);
              }}
            />
          </span>
        </Col>
      </Row>
      <Table columns={columns} dataSource={tests} rowKey="id" />
    </Layout.Content>
  );
}
