import React, { Component } from "react";
import { Layout, message, Table } from "antd";
import { api } from "../../../config";

class Participants extends Component {
  state = {
    id: null,
    participants: [],
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      this.get_participants_list(this.props.match.params.id);
      this.setState({ id: this.props.match.params.id });
    }
  }

  get_participants_list = (id) => {
    api
      .get("/meeting/" + id + "/participants/")
      .then((res) => {
        if (res.data.success) {
          this.setState({ participants: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const columns = [
      { title: "ID", dataIndex: "id" },
      { title: "Name", dataIndex: "name" },
      {
        title: "Email/Username",
        render: (r) => <span>{r.email || r.username}</span>,
      },
      {
        title: "Role",
        dataIndex: "role",
        render: (v) => {
          switch (v) {
            case 1:
              return <span>Parent</span>;
            case 2:
              return <span>Teacher</span>;
            case 3:
            case 4:
              return <span>Admin</span>;
            default:
              return <span>Others</span>;
          }
        },
      },
      {
        title: "Status",
        dataIndex: "accepted",
        render: (v) => {
          switch (v) {
            case 0:
              return <span>Unknown</span>;
            case 1:
              return <span>Attending</span>;
            case -1:
              return <span>Not Attending</span>;
            default:
              return <span>Unknown</span>;
          }
        },
      },
    ];
    return (
      <Layout.Content>
        <h1>Meeting Participants</h1>
        <Table
          columns={columns}
          dataSource={this.state.participants}
          rowKey="id"
        />
      </Layout.Content>
    );
  }
}

export default Participants;
