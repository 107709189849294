import React, { Component } from "react";
import { Layout, Spin, Form, Input, DatePicker, Button, message } from "antd";
import { api } from "../../../../config";
import {
  HRUserSelectorDropdown,
  HRDepartmentDropdown,
} from "../../../../components";
import moment from "moment";

class EmployeesForm extends Component {
  state = {
    spinning: false,
    loading: false,
    id: undefined,
    employee: {},
  };

  componentDidMount() {
    if (this.props.location.state && this.props.match.params) {
      let { employee } = this.props.location.state;
      let { id } = this.props.match.params;
      if (id && employee) {
        this.__set_form_values(employee);
        this.setState({ employee, id });
      }
    }
  }

  __set_form_values = (employee) => {
    employee.dob = moment(employee.dob);
    employee.hire_date = moment(employee.hire_date);
    employee.join_date = moment(employee.join_date);
    this.props.form.setFieldsValue(employee);
  };

  add_employee = (data) => {
    this.setState({ loading: true });
    api
      .put("/hr/employees/", JSON.stringify(data))
      .then((res) => {
        if (res.data.success) {
          message.success("New employee added");
          // this.props.history.push("/hr/employees/");
          this.props.history.goBack();
        } else {
          message.error(res.data.error);
        }
        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);
      });
  };

  update_employee = (id, data) => {
    this.setState({ loading: true });
    api
      .post(`/hr/employee/${id}/`, JSON.stringify(data))
      .then((res) => {
        if (res.data.success) {
          message.success("Updated employee details!");
          this.props.history.goBack();
        } else {
          message.error(res.data.error);
        }
        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);
      });
  };

  handleSubmit = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        let { id } = this.state;
        if (id) {
          this.update_employee(id, val);
        } else {
          this.add_employee(val);
        }
      }
    });
  };

  render() {
    const { id, loading } = this.state;
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    return (
      <Layout.Content>
        <h1 className="align-center">{id ? "Update " : "Add "}Employee</h1>
        <Spin spinning={false}>
          <Form labelCol={{ sm: 8 }} wrapperCol={{ sm: 8 }}>
            <FormItem label="Employee Name">
              {getFieldDecorator("name", {
                rules: [
                  { required: true, message: "Employee Name is required!" },
                ],
              })(<Input />)}
            </FormItem>
            <FormItem label="Select User (for login)">
              {getFieldDecorator("user_id")(
                <HRUserSelectorDropdown all={true} />
              )}
            </FormItem>
            <FormItem label="Job Title">
              {getFieldDecorator("job_title", {
                rules: [{ required: true, message: "Job Title is required!" }],
              })(<Input />)}
            </FormItem>
            <FormItem label="Date of Birth">
              {getFieldDecorator("dob", {
                rules: [{ required: true, message: "DoB is required!" }],
              })(<DatePicker />)}
            </FormItem>
            <FormItem label="Hire Date">
              {getFieldDecorator("hire_date", {
                rules: [{ required: true, message: "Hire Date is required!" }],
              })(<DatePicker />)}
            </FormItem>
            <FormItem label="Join Date">
              {getFieldDecorator("join_date", {
                rules: [{ required: true, message: "Join Date is required!" }],
              })(<DatePicker />)}
            </FormItem>
            <FormItem label="Department">
              {getFieldDecorator("hr_department_id", {
                rules: [{ required: true, message: "Department is required!" }],
              })(<HRDepartmentDropdown />)}
            </FormItem>
            <FormItem wrapperCol={{ offset: 8 }}>
              <Button
                type="primary"
                onClick={this.handleSubmit}
                loading={loading}
              >
                {id ? "Update " : "Add "}Employee
              </Button>
            </FormItem>
          </Form>
        </Spin>
      </Layout.Content>
    );
  }
}

export default Form.create()(EmployeesForm);
