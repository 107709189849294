import React, { Component } from "react";
import {
  Layout,
  message,
  Table,
  Icon,
  Popconfirm,
  Row,
  Col,
  Button,
  Input
} from "antd";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { api } from "../../../config";
import IssueBook from "./IssueBook";
import ReturnBook from "./ReturnBook";

class BooksList extends Component {
  state = {
    books: [],
    book: {},
    issueModal: false,
    returnModal: false,
    spinning: false,
    book_code: null
  };

  componentDidMount() {
    this.get_books_list();
  }

  closeModal = () => {
    this.setState({ issueModal: false, returnModal: false });
    this.get_books_list();
  };

  get_books_list = () => {
    this.setState({ spinning: true });
    api
      .get("/library/books/")
      .then(res => {
        if (res.data.success) {
          this.setState({ books: res.data.data });
        } else {
          message.error(res.data.error);
        }
        this.setState({ spinning: false });
      })
      .catch(err => {
        console.log(err);
        this.setState({ spinning: false });
      });
  };

  handleBookSearch = () => {
    let { book_code } = this.state;
    if (!book_code) {
      message.error("Book Code is required!");
      return;
    }
    let url = "/library/book/search/" + book_code + "/";
    this.setState({ spinning: true });
    api
      .get(url)
      .then(res => {
        if (res.data.success) {
          this.setState({ books: [res.data.data] });
        } else {
          message.error(res.data.error);
        }
        this.setState({ spinning: false });
      })
      .catch(err => {
        console.log(err);
        this.setState({ spinning: false });
      });
  };

  render() {
    const role = this.props.user.role;
    const columns = [
      { title: "Book Code", dataIndex: "code" },
      { title: "Title", dataIndex: "title" },
      { title: "Author", dataIndex: "author" },
      { title: "Publisher", dataIndex: "publisher" },
      {
        title: "Action",
        render: record => (
          <span>
            {role === 7 && (
              <span>
                {record.status === 2 && (
                  <Icon
                    type="play-circle"
                    className="action-icon"
                    title="Issue"
                    onClick={() => {
                      this.setState({ issueModal: true, book: record });
                    }}
                  />
                )}
                {record.status === 1 && (
                  <Icon
                    type="close-circle"
                    className="action-icon"
                    title="Issue"
                    onClick={() => {
                      this.setState({ returnModal: true, book: record });
                    }}
                  />
                )}
              </span>
            )}
            <Icon
              type="menu"
              className="action-icon"
              title="Issues History"
              onClick={() => {
                this.props.history.push(
                  "/library/book/history/" + record.id + "/"
                );
              }}
            />
            <Icon
              type="edit"
              className="action-icon"
              onClick={() => {
                this.props.history.push("/library/book/" + record.id + "/");
              }}
              title="Edit"
            />
            <Popconfirm title="Are you sure?">
              <Icon
                type="delete"
                className="action-icon absent"
                title="Delete"
              />
            </Popconfirm>
          </span>
        )
      }
    ];
    return (
      <Layout.Content>
        <Row>
          <Col sm={8}>
            <h1>Books List</h1>
          </Col>
          <Col sm={8} style={{ textAlign: "center" }}>
            <Input
              placeholder="Book Code"
              style={{ marginRight: 10, width: 200, maxWidth: "100%" }}
              onChange={e => {
                this.setState({ book_code: e.currentTarget.value });
              }}
            />
            <Button onClick={this.handleBookSearch}>Go</Button>
          </Col>
          <Col sm={8} style={{ textAlign: "right" }}>
            <Link to="/library/book/new/">
              <Button type="primary" icon="plus">
                Add Book
              </Button>
            </Link>
          </Col>
        </Row>
        <Table
          columns={columns}
          dataSource={this.state.books}
          rowKey="id"
          loading={this.state.spinning}
        />
        {this.state.issueModal && (
          <IssueBook
            visible={this.state.issueModal}
            closeModal={this.closeModal}
            book={this.state.book}
          />
        )}
        {this.state.returnModal && (
          <ReturnBook
            visible={this.state.returnModal}
            closeModal={this.closeModal}
            book={this.state.book}
          />
        )}
      </Layout.Content>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user
  };
}

export default connect(mapStateToProps, null)(BooksList);
