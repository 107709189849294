import React, { Component } from "react";
import {
  Layout,
  Form,
  Input,
  InputNumber,
  Select,
  DatePicker,
  Button,
  message
} from "antd";
import {
  ClassSectionDropdown,
  SubjectSelectionDropdown,
  TeacherSelectionDropdown
} from "../../../../components";
import { api } from "../../../../config";
var moment = require("moment");

class TestDetailsForm extends Component {
  state = {
    test: {},
    loading: false
  };

  componentDidMount() {
    let test = this.props.location.state.test;
    test.start_time = moment(test.start_time);
    test.end_time = moment(test.end_time);
    this.props.form.setFieldsValue(test);
    this.setState({ test });
  }

  handleUpdate = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        this.setState({ loading: true });
        api
          .post("/class/test/" + val.id + "/", JSON.stringify(val))
          .then(res => {
            if (res.data && res.data.success) {
              message.success("Test Subject Updated");
              this.props.history.push("/academics/test/list/" + val.test_id);
            } else {
              message.error(res.data.error);
            }
            this.setState({ loading: false });
          })
          .catch(err => {
            console.log(err);
            this.setState({ loading: false });
          });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const FormItem = Form.Item;
    return (
      <Layout.Content>
        <h1 style={{ textAlign: "center" }}>Test Details Form</h1>
        <Form labelCol={{ sm: 8 }} wrapperCol={{ sm: 8 }}>
          <FormItem style={{ margin: 0 }}>
            {getFieldDecorator("id")(<Input type="hidden" />)}
          </FormItem>
          <FormItem style={{ margin: 0 }}>
            {getFieldDecorator("test_id")(<Input type="hidden" />)}
          </FormItem>
          <FormItem label="Test">
            {getFieldDecorator("test")(<Input disabled />)}
          </FormItem>
          <FormItem label="Class">
            {getFieldDecorator("class_id")(<ClassSectionDropdown disabled />)}
          </FormItem>
          <FormItem label="Subject">
            {getFieldDecorator("subject_id")(
              <SubjectSelectionDropdown
                disabled
                class={this.state.test.class_id}
              />
            )}
          </FormItem>
          <FormItem label="Marks Type">
            {getFieldDecorator("marks_type", {
              rules: [{ required: true, message: "Marks type is required!" }]
            })(
              <Select style={{ width: "100%" }}>
                <Select.Option value={1}>Marks</Select.Option>
                <Select.Option value={2}>Grades</Select.Option>
              </Select>
            )}
          </FormItem>
          <FormItem label="Min Marks">
            {getFieldDecorator("min_marks", {
              rules: [{ required: true, message: "Min marks is required!" }]
            })(<InputNumber />)}
          </FormItem>
          <FormItem label="Max Marks">
            {getFieldDecorator("max_marks", {
              rules: [{ required: true, message: "Max marks is required!" }]
            })(<InputNumber />)}
          </FormItem>
          <FormItem label="Start Time">
            {getFieldDecorator("start_time", {
              rules: [{ required: true, message: "Start time is required!" }]
            })(
              <DatePicker
                showTime={{ format: "hh:mm", minuteStep: 10 }}
                format="YYYY-MM-DD hh:mm"
                style={{ width: 175 }}
              />
            )}
          </FormItem>
          <FormItem label="End Time">
            {getFieldDecorator("end_time", {
              rules: [{ required: true, message: "End time is required!" }]
            })(
              <DatePicker
                showTime={{ format: "hh:mm", minuteStep: 10 }}
                format="YYYY-MM-DD hh:mm"
                style={{ width: 175 }}
              />
            )}
          </FormItem>
          <FormItem label="Invigilator">
            {getFieldDecorator("invigilator")(<TeacherSelectionDropdown />)}
          </FormItem>
          <FormItem wrapperCol={{ offset: 8 }}>
            <Button
              type="primary"
              onClick={this.handleUpdate}
              loading={this.state.loading}
            >
              Update
            </Button>
          </FormItem>
        </Form>
      </Layout.Content>
    );
  }
}

export default Form.create()(TestDetailsForm);
