import { Icon, Layout, message, Table } from "antd";
import React, { Component } from "react";
import { api } from "../../../config";
import moment from "moment";
import { Currency } from "../../../components";
import PrintPaySlip from "../../admin/simple/payroll/PrintPaySlip";

class PayrollList extends Component {
  state = {
    payrolls: [],
    payslip: [],
    total: 0.0,
    payroll: {},
  };

  componentDidMount() {
    this.__get_payroll_list();
  }

  __get_payroll_list = () => {
    api
      .get("/employee/payroll/")
      .then((res) => {
        if (res.data.success) {
          this.setState({ payrolls: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  __print_record = (r) => {
    let { payslip } = JSON.parse(r.extras);
    let total = 0.0;
    payslip.forEach((item) => {
      total += item.type === 0 ? item.value : -1 * item.value;
    });
    this.setState({ payslip, payroll: r, total }, () => {
      setTimeout(() => {
        window.print();
      }, 100);
    });
  };

  render() {
    const { payrolls, payroll, payslip, total } = this.state;
    const columns = [
      { title: "ID", dataIndex: "id" },
      {
        title: "Month",
        dataIndex: "month_year",
        render: (v) => <span>{moment(v).format("MMM, YYYY")}</span>,
      },
      {
        title: "Present/Total Days",
        render: (r) => <span>{`${r.present_days}/${r.total_days}`}</span>,
      },
      {
        title: "Salary Amount",
        dataIndex: "salary_amount",
        render: (v) => <Currency value={v} />,
      },
      {
        title: "Amount Paid",
        dataIndex: "amount_paid",
        render: (v) => <Currency value={v} />,
      },
      {
        title: "Payment Date",
        dataIndex: "payment_date",
        render: (v) => <span>{moment(v).format("YYYY-MM-DD")}</span>,
      },
      {
        title: "Actions",
        render: (r) => (
          <span>
            <Icon
              type="printer"
              className="action-icon"
              onClick={this.__print_record.bind(this, r)}
            />
          </span>
        ),
      },
    ];
    return (
      <Layout.Content>
        <h1>Payroll List</h1>
        <Table columns={columns} dataSource={payrolls} />
        <PrintPaySlip
          payroll={payroll}
          payslip_items={payslip}
          payslip_total={total}
        />
      </Layout.Content>
    );
  }
}

export default PayrollList;
