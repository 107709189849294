import { Modal, Form, Input, DatePicker, message } from "antd";
import React, { Component } from "react";
import {
  AcademicYearDropdown,
  StudentNameSearchDropdown,
} from "../../../components";
import { api } from "../../../config";

class StudentHostelForm extends Component {
  state = {
    loading: false,
  };

  __handleSubmit = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        this.setState({ loading: true });
        api
          .put("/simple-hostel/students/", JSON.stringify(val))
          .then((res) => {
            if (res.data.success) {
              message.success("Student Added to Hostel");
              this.props.close();
            } else {
              message.error(res.data.error);
            }
            this.setState({ loading: false });
          })
          .catch((err) => {
            console.log(err);
            this.setState({ loading: true });
          });
      }
    });
  };

  render() {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    const { loading } = this.state;
    return (
      <Modal
        title="Add Student To Hostel"
        visible={true}
        onOk={this.__handleSubmit}
        onCancel={this.props.close.bind(this)}
        confirmLoading={loading}
      >
        <Form>
          <FormItem label="Academic Year">
            {getFieldDecorator("ac_year_id", {
              rules: [
                { required: true, message: "Academic Year is required!" },
              ],
            })(<AcademicYearDropdown />)}
          </FormItem>
          <FormItem label="Student">
            {getFieldDecorator("student_id", {
              rules: [{ required: true, message: "Student is required!" }],
            })(<StudentNameSearchDropdown />)}
          </FormItem>
          <FormItem label="Hostel Building/Block">
            {getFieldDecorator("hostel_name", {
              rules: [{ required: true, message: "Required!" }],
            })(<Input />)}
          </FormItem>
          <FormItem label="Hostel Room">
            {getFieldDecorator("hostel_room", {
              rules: [{ required: true, message: "Required!" }],
            })(<Input />)}
          </FormItem>
          <FormItem label="Join Date">
            {getFieldDecorator("join_date")(<DatePicker />)}
          </FormItem>
          <FormItem label="End Date">
            {getFieldDecorator("end_date")(<DatePicker />)}
          </FormItem>
        </Form>
      </Modal>
    );
  }
}

export default Form.create()(StudentHostelForm);
