import React, { Component } from "react";
import {
  Layout,
  Row,
  Col,
  Button,
  Table,
  message,
  Icon,
  Popconfirm,
} from "antd";
import { Link } from "react-router-dom";
import { api } from "../../../config";

class QuestionsList extends Component {
  state = {
    id: null,
    questions: [],
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      this.setState({ id: this.props.match.params.id });
      this.get_questions_list(this.props.match.params.id);
    }
  }

  get_questions_list = (id) => {
    api
      .get("/exam/" + id + "/questions/")
      .then((res) => {
        if (res.data.success) {
          this.setState({ questions: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  delete_question = (question_id) => {
    let { id } = this.state;
    api
      .delete("/exam/" + id + "/question/" + question_id + "/")
      .then((res) => {
        if (res.data.success) {
          message.success("Question Deleted");
          this.get_questions_list(id);
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const { id, questions } = this.state;
    const columns = [
      { title: "ID", dataIndex: "id" },
      { title: "Question", dataIndex: "question" },
      {
        title: "Question Type",
        dataIndex: "question_type",
        render: (v, r, i) => {
          switch (v) {
            case 1:
              return <span>MCQ</span>;
            default:
              return <span>Unknown</span>;
          }
        },
      },
      {
        title: "Actions",
        render: (r) => (
          <span>
            <Icon
              type="menu"
              className="action-icon"
              onClick={() => {
                this.props.history.push({
                  pathname: "/exam/question/" + id + "/" + r.id,
                  state: { ...r },
                });
              }}
            />
            <Popconfirm
              title="Are you sure?"
              onConfirm={this.delete_question.bind(this, r.id)}
            >
              <Icon type="delete" className="action-icon absent" />
            </Popconfirm>
          </span>
        ),
      },
    ];
    return (
      <Layout.Content>
        <Row>
          <Col sm={12}>
            <h1>Questions</h1>
          </Col>
          <Col sm={12} style={{ textAlign: "right" }}>
            <Link to={"/exam/question/" + id + "/new"}>
              <Button type="primary" icon="plus">
                New Question
              </Button>
            </Link>
          </Col>
        </Row>
        <Table columns={columns} dataSource={questions} rowKey="id" />
      </Layout.Content>
    );
  }
}

export default QuestionsList;
