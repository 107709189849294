import React, { Component } from "react";
import { Select } from "antd";
import { api } from "../config";

class STRouteListDropdown extends Component {
  state = {
    bus_routes: []
  };
  componentDidMount() {
    this.get_bus_routes_list();
  }

  get_bus_routes_list = () => {
    api.get("/simple/transportation/routes/").then(res => {
      if (res.data && res.data.success) {
        this.setState({ bus_routes: res.data.data });
      }
    });
  };

  render() {
    const Option = Select.Option;
    const style = { minWidth: 160, maxWidth: "100%" };
    return (
      <Select
        placeholder="Bus Routes"
        showSearch
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        onChange={this.props.onChange}
        value={this.props.value}
        style={this.props.style || style}
        allowClear={this.props.allowClear || true}
        {...this.props}
      >
        {this.state.bus_routes.map((item, index) => {
          return (
            <Option value={item.id} key={index}>
              {item.route_name}
            </Option>
          );
        })}
      </Select>
    );
  }
}

export default STRouteListDropdown;
