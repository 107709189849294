import React, { Component } from "react";
import { Select, message } from "antd";
import { api } from "../config";

class TestSelectionDropdown extends Component {
  state = {
    tests_list: [],
    ac_year_id: null
  };

  componentDidMount() {
    if (this.props.ac_year_id) {
      this.getTests(this.props.ac_year_id);
    } else {
      this.getTests();
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.ac_year_id !== prevState.ac_year_id) {
      return { ac_year_id: nextProps.ac_year_id };
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.ac_year_id !== this.props.ac_year_id) {
      this.getTests(this.props.ac_year_id);
    }
  }

  getTests = ac_year_id => {
    let url = ac_year_id ? "/tests/ac_year/" + ac_year_id + "/" : "/tests/";
    api
      .get(url)
      .then(res => {
        if (res.data && res.data.success) {
          this.setState({ tests_list: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  render() {
    const Option = Select.Option;
    return (
      <Select
        style={this.props.style}
        onChange={this.props.onChange}
        value={this.props.value}
        placeholder="Select Test"
      >
        {this.state.tests_list.map((item, index) => {
          return (
            <Option value={item.id} key={index}>
              {item.name}
            </Option>
          );
        })}
      </Select>
    );
  }
}

export default TestSelectionDropdown;
