import {
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Select,
} from "antd";
import React, { Component } from "react";
import { api } from "../../../../config";

class AddPocketMoneyTransaction extends Component {
  state = {
    loading: false,
  };

  __handleSubmit = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        val.student_id = this.props.student_id;
        this.setState({ loading: true });
        api
          .put("/pocket-money/", JSON.stringify(val))
          .then((res) => {
            if (res.data.success) {
              message.success("Transaction added!");
              this.props.close();
            } else {
              message.error(res.data.error || "Failed to add!");
            }
            this.setState({ loading: false });
          })
          .catch((err) => {
            this.setState({ loading: false });
            message.error("Failed to add!");
            console.log(err);
          });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const FormItem = Form.Item;
    const { Option } = Select;
    const { loading } = this.state;
    return (
      <Modal
        title="Pocket Money Transaction"
        visible={true}
        onCancel={this.props.close.bind(this)}
        onOk={this.__handleSubmit}
        confirmLoading={loading}
      >
        <Form>
          <FormItem label="Amount">
            {getFieldDecorator("amount", {
              rules: [{ required: true, message: "Amount is required!" }],
            })(<InputNumber />)}
          </FormItem>
          <FormItem label="Transaction Type">
            {getFieldDecorator("txn_type", {
              rules: [
                { required: true, message: "Transaction type is required!" },
              ],
            })(
              <Select>
                <Option value={0}>Credit</Option>
                <Option value={1}>Debit</Option>
              </Select>
            )}
          </FormItem>
          <FormItem label="Date">
            {getFieldDecorator("date", {
              rules: [{ required: true, message: "Date is required!" }],
            })(<DatePicker />)}
          </FormItem>
          <FormItem label="Details">
            {getFieldDecorator("details", {
              rules: [{ required: true, message: "Details is required!" }],
            })(<Input />)}
          </FormItem>
        </Form>
      </Modal>
    );
  }
}

export default Form.create()(AddPocketMoneyTransaction);
