import React from "react";
import { Switch, Route } from "react-router-dom";
import ClassRoomList from "./ClassRoomList";
import NewClassRoom from "./NewClassRoom";
import VirtualClassRoom from "./VirtualClassRoom";
import VCRoomQuestion from "../../student/virtual_classroom/VCRoomQuestion";
import VCRoomQuestions from "../../student/virtual_classroom/VCRoomQuestions";
import JitsiClassroom from "./JitsiClassroom";

const TeacherVirtualClassroomRouter = (props) => (
  <Switch>
    <Route exact path="/virtual-classroom/" component={ClassRoomList} />
    <Route exact path="/virtual-classroom/new" component={NewClassRoom} />
    <Route exact path="/virtual-classroom/:id" component={VirtualClassRoom} />
    <Route
      exact
      path="/virtual-classroom/:id/jitsi"
      component={JitsiClassroom}
    />
    <Route
      exact
      path="/virtual-classroom/:vc_class_id/questions/"
      component={VCRoomQuestions}
    />
    <Route
      exact
      path="/virtual-classroom/:vc_class_id/question/:id"
      component={VCRoomQuestion}
    />
  </Switch>
);

export { TeacherVirtualClassroomRouter };
