import React from "react";
import { Switch, Route } from "react-router";
import MarksEditor from "./MarksEditor";
import MarksViewer from "./MarksViewer";

const AdminMarksRouter = props => (
  <Switch>
    <Route exact path="/marks/" component={MarksViewer} />
    <Route exact path="/marks/update" component={MarksEditor} />
    <Route exact path="/marks/:id" />
  </Switch>
);

export { AdminMarksRouter };
