import React, { Component } from "react";
import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";

class ShowBreadcrumbs extends Component {
  state = {
    items: []
  };
  componentDidMount() {
    if (this.props.items) {
      this.setState({ items: this.props.items });
    }
  }
  render() {
    const items = this.props.items || [];
    return (
      <Breadcrumb style={{ margin: "16px 0" }}>
        {items.map((item, index) => {
          return (
            <Breadcrumb.Item key={index}>
              {item.link ? (
                <Link to={item.link || "/"}>{item.name}</Link>
              ) : (
                item.name
              )}
            </Breadcrumb.Item>
          );
        })}
      </Breadcrumb>
    );
  }
}

export default ShowBreadcrumbs;
