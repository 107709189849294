import React, { Component } from "react";
import { Form, Input, message, Button } from "antd";
import { connect } from "react-redux";
import { api } from "../../../config";

class NewStudentProfile extends Component {
  state = {
    details: {},
    loading: false
  };

  componentDidMount() {
    if (!this.props.data) {
      message.error("Invalid Visit");
      this.props.history.push("/students/");
      return;
    }
    this.setState({ details: this.props.data });
  }

  redirect_user = student_id => {
    let { modules } = this.props;

    modules.forEach(item => {
      if (item.title === "Fees") {
        if (item.enabled) {
          this.props.history.push("/fees/pay/" + student_id);
        } else {
          this.props.history.push("/students/");
        }
      }
    });
  };

  handleSubmit = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        let formData = Object.assign({}, this.state.details, val);
        this.setState({ loading: true });
        api
          .put("/students/", JSON.stringify(formData))
          .then(res => {
            if (res.data && res.data.success) {
              message.success("New Student Added");
              this.redirect_user(res.data.data.student_id);
            } else {
              message.error(res.data.error);
            }
            this.setState({ loading: false });
          })
          .catch(err => {
            console.log(err);
            this.setState({ loading: false });
          });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const FormItem = Form.Item;
    return (
      <div>
        <Form labelCol={{ sm: 8 }} wrapperCol={{ sm: 16 }}>
          <FormItem label="Father's Name">
            {getFieldDecorator("extras.father_name")(
              <Input placeholder="Father's Name" />
            )}
          </FormItem>
          <FormItem label="Address">
            {getFieldDecorator("extras.address")(
              <Input.TextArea placeholder="Address" rows={4} />
            )}
          </FormItem>
          <FormItem label="Blood Group">
            {getFieldDecorator("extras.blood_group")(
              <Input placeholder="Blood Group" />
            )}
          </FormItem>
          <FormItem wrapperCol={{ sm: 16, offset: 8 }}>
            <Button onClick={this.handleSubmit} loading={this.state.loading}>
              Add Student
            </Button>
          </FormItem>
        </Form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    modules: state.about_school.modules
  };
}

export default connect(mapStateToProps, null)(Form.create()(NewStudentProfile));
