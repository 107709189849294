import React, { Component } from "react";
import { Layout, Row, Col, Form, Input, Button, Icon } from "antd";

class ContactSupport extends Component {
  state = {};
  render() {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    return (
      <Layout.Content>
        <Row>
          <Col sm={8} offset={8}>
            <div>
              <h1 style={{ textAlign: "center" }}>Contact Support</h1>
              <p>
                <Icon type="mail" />
                <a href="mailto:contact@eduga.co.in"> contact@eduga.co.in</a>
              </p>
              <p>
                <Icon type="phone" />
                <a href="tel:+919353675928"> +91 935 367 5928</a> /
                <a href="tel:+919353647042"> +91 935 364 7042</a>
              </p>
              <p>
                <Icon type="message" />
                <a
                  href="https://wa.me/919353675928?text=Eduga+Support"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  +91 935 367 5928
                </a>{" "}
                /
                <a
                  href="https://wa.me/919353647042?text=Eduga+Support"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  +91 935 364 7042
                </a>
              </p>
            </div>
            <Form>
              <FormItem label="Name">
                {getFieldDecorator("name")(<Input />)}
              </FormItem>
              <FormItem label="Subject">
                {getFieldDecorator("subject")(<Input />)}
              </FormItem>
              <FormItem label="Message">
                {getFieldDecorator("message")(<Input.TextArea rows={4} />)}
              </FormItem>
              <FormItem>
                <Button type="primary">Send</Button>
              </FormItem>
            </Form>
          </Col>
        </Row>
      </Layout.Content>
    );
  }
}

export default Form.create()(ContactSupport);
