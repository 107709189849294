import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Layout,
  message,
  Radio,
  Row,
  Select,
  Table,
} from "antd";
import React, { Component } from "react";
import { Currency } from "../../../../components";
import { api } from "../../../../config";
import moment from "moment";
import SimpleFinanceReportPrint from "./SimpleFinanceReportPrint";

class SimpleFinanceDashboard extends Component {
  state = {
    loading: true,
    card: {},
    card_data: {},
    report_filter: {
      field: "all",
      interval: "all",
      date_range: [],
    },
    reports: [],
    sum: {
      credit: 0.0,
      debit: 0.0,
    },
  };

  componentDidMount() {
    this._get_dashboard_card_data();
  }

  _get_dashboard_card_data = () => {
    api
      .get("/simple-finance/dashboard/")
      .then((res) => {
        if (res.data.success) {
          this.setState({ card_data: res.data.data });
          this._rearrange_card_data(res.data.data);
          this.setState({ loading: false });
        } else {
          message.error(res.data.error);
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  _rearrange_card_data = (data) => {
    let card_data = {};
    Object.keys(data).forEach((item) => {
      let total_credit = (data[item].fees || 0) + (data[item].credit || 0);
      let expense = (data[item].debit || 0) + (data[item].expense || 0);
      let total_debit = expense;
      let net_total = total_credit - total_debit;
      let cd = [
        {
          name: "Fees Collected",
          value: <Currency value={data[item].fees || 0} />,
        },
        {
          name: "Other Credit",
          value: <Currency value={data[item].credit || 0} />,
        },
        {
          name: <h4>Total Credit</h4>,
          value: (
            <h4>
              <Currency value={total_credit} />
            </h4>
          ),
        },
        { name: "Expenses", value: <Currency value={expense || 0} /> },
        { name: "Salary", value: <Currency value={data[item].payroll || 0} /> },
        {
          name: <h4>Total Debit</h4>,
          value: (
            <h4>
              <Currency value={expense} />
            </h4>
          ),
        },
        {
          name: <h3>Net Total</h3>,
          value: (
            <h3>
              <Currency value={net_total} />
            </h3>
          ),
        },
      ];
      card_data[item] = cd;
    });
    this.setState({ card_data: card_data });
  };

  _handleFilterView = () => {
    const { report_filter } = this.state;
    const { field, interval, date_range } = report_filter;
    if (!(field && interval && date_range.length === 2)) {
      message.error("Please select report category, interval and period");
      return;
    }
    let start_date = date_range[0].format("YYYY-MM-DD");
    let end_date = date_range[1].format("YYYY-MM-DD");
    let url = `/simple-finance/reports/?field=${field}&interval=${interval}&start_date=${start_date}&end_date=${end_date}`;
    url = encodeURI(url);
    api
      .get(url)
      .then((res) => {
        if (res.data.success) {
          this.setState({ reports: res.data.data });
          this._calculate_total(res.data.data);
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  _calculate_total = (data) => {
    let credit = 0.0,
      debit = 0.0;
    data.forEach((item) => {
      if (item.txn_type === 0) {
        credit += item.amount;
      } else {
        debit += item.amount;
      }
    });
    this.setState({ sum: { credit, debit } });
  };

  _handleCSVDownload = () => {
    let { reports, report_filter } = this.state;
    let interval = report_filter.interval;
    var csvContent = "data:text/csv;charset=utf-8,";
    let header = "Sl. No., Date, Category, Debit, Credit\r\n";
    csvContent += header;
    reports.forEach((item, index) => {
      let line = `${index + 1}, ${
        interval === "weekly" ? "Week" + item.data : item.date
      }, ${item.name}, ${item.type === "debit" ? item.amount : " "}, ${
        item.type === "credit" ? item.amount : ""
      }\r\n`;
      csvContent += line;
    });
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `finance_report.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  _handleDownload = () => {
    window.print();
  };

  render() {
    const { loading, card_data, report_filter, reports, sum } = this.state;
    const { today, week, month, total } = card_data;
    const { Option } = Select;
    const columns = [
      { title: "Sl. No.", render: (v, r, i) => <span>{i + 1}</span> },
      {
        title: "Date",
        dataIndex: "date",
        render: (v, r) => {
          switch (report_filter.interval) {
            case "all":
              return moment.utc(v).local().format("YYYY-MM-DD");
            case "daily":
              return moment.utc(v).local().format("YYYY-MM-DD");
            case "weekly":
              return `Week ${v}`;
            case "monthly":
              return moment.utc(v).local().format("YYYY-MM");
            case "annual":
              return moment.utc(v).local().format("YYYY");
            default:
              return "";
          }
        },
      },
      { title: "Category", dataIndex: "name" },
      {
        title: "Debit",
        align: "right",
        render: (r) => (
          <span>{r.txn_type === 1 ? <Currency value={r.amount} /> : ""}</span>
        ),
      },
      {
        title: "Credit",
        align: "right",
        render: (r) => (
          <span>{r.txn_type === 0 ? <Currency value={r.amount} /> : ""}</span>
        ),
      },
    ];
    return (
      <Layout.Content className="sf-dashboard">
        <Row gutter={16}>
          <Col sm={12}>
            <h1>Finance Dashboard</h1>
          </Col>
          <Col sm={12} className="action-buttons">
            {/* <Button type="primary" ghost style={{ marginRight: 10 }}>
              Balance Sheet
            </Button>
            <Button type="primary">Finance Reports</Button> */}
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={12} md={12} lg={8} xl={6}>
            <Card title="Today" loading={loading}>
              <CardTable data={today} />
            </Card>
          </Col>
          <Col xs={24} sm={12} md={12} lg={8} xl={6}>
            <Card title="This Week" loading={loading}>
              <CardTable data={week} />
            </Card>
          </Col>
          <Col xs={24} sm={12} md={12} lg={8} xl={6}>
            <Card title="This Month" loading={loading}>
              <CardTable data={month} />
            </Card>
          </Col>
          <Col xs={24} sm={12} md={12} lg={8} xl={6}>
            <Card title="Total" loading={loading}>
              <CardTable data={total} />
            </Card>
          </Col>
        </Row>
        <Card
          title="Reports"
          style={{ margin: "16px 0" }}
          extra={[
            <Button
              type="primary"
              style={{ marginRight: 10 }}
              onClick={this._handleFilterView}
              size="small"
              ghost
            >
              View
            </Button>,
            <Button
              type="primary"
              disabled={reports.length === 0}
              icon="download"
              size="small"
              onClick={this._handleDownload}
            >
              Download
            </Button>,
          ]}
        >
          <Row gutter={16}>
            <Col sm={4}>
              <Form.Item label="Report Category">
                <Select
                  placeholder="Field"
                  style={{ minWidth: 150 }}
                  value={report_filter.field}
                  onChange={(val) => {
                    report_filter.field = val;
                    this.setState({ report_filter });
                  }}
                  disabled={true}
                >
                  <Option value="fees">Fees</Option>
                  <Option value="accounts">Accounts</Option>
                  <Option value="salary">Salary</Option>
                  <Option value="all">All</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col sm={12}>
              <Form.Item label="Interval">
                <Radio.Group
                  defaultValue="all"
                  value={report_filter.interval}
                  onChange={(e) => {
                    report_filter.interval = e.target.value;
                    this.setState({ report_filter });
                  }}
                >
                  <Radio value="all">All Entries</Radio>
                  <Radio value="daily">Daily</Radio>
                  <Radio value="weekly">Weekly</Radio>
                  <Radio value="monthly">Monthly</Radio>
                  <Radio value="annual">Annual</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col sm={8}>
              <Form.Item label="Period">
                <DatePicker.RangePicker
                  value={report_filter.date_range}
                  onChange={(val) => {
                    report_filter.date_range = val;
                    this.setState({ report_filter });
                  }}
                />
              </Form.Item>
            </Col>
            <Col sm={24}>
              <Table
                columns={columns}
                dataSource={reports}
                pagination={{ pageSize: 50 }}
                footer={(data) => {
                  let credit = 0.0,
                    debit = 0.0;
                  data.forEach((item) => {
                    if (item.txn_type === 0) {
                      credit += item.amount;
                    } else {
                      debit += item.amount;
                    }
                  });
                  return (
                    <div className="table-footer">
                      <div className="sum-data">
                        <div className="sum-data-label">This Page</div>
                        <div>
                          Debit : <Currency value={debit} />
                        </div>
                        <div>
                          Credit : <Currency value={credit} />
                        </div>
                        <div>
                          Balance : <Currency value={credit - debit} />
                        </div>
                      </div>
                      <div className="sum-data">
                        <div className="sum-data-label">Total</div>
                        <div>
                          Debit : <Currency value={sum.debit} />
                        </div>
                        <div>
                          Credit : <Currency value={sum.credit} />
                        </div>
                        <div>
                          Balance : <Currency value={sum.credit - sum.debit} />
                        </div>
                      </div>
                    </div>
                  );
                }}
              />
            </Col>
          </Row>
        </Card>
        <SimpleFinanceReportPrint
          reports={reports}
          type={report_filter.interval}
          total={sum}
        />
      </Layout.Content>
    );
  }
}

export default SimpleFinanceDashboard;

const CardTable = (props) => (
  <div>
    <table style={{ width: "100%" }}>
      <tbody>
        {props.data &&
          props.data.map((item, index) => {
            return (
              <tr key={index}>
                <td style={{ width: "80%" }}>{item.name}</td>
                <td style={{ width: "20%", textAlign: "right" }}>
                  {item.value}
                </td>
              </tr>
            );
          })}
      </tbody>
    </table>
  </div>
);
