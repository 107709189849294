import React, { Component } from "react";
import {
  Layout,
  Form,
  Input,
  Row,
  Col,
  Upload,
  Button,
  Icon,
  message,
  Divider,
  Select,
  DatePicker
} from "antd";
import Axios from "axios";

class NewApplicationForm extends Component {
  state = {
    imageUrl: null,
    loading: false,
    base_url: null,
    settings: {},
    school: [],
    params: {}
  };

  componentDidMount() {
    let params = this.props.match.params;
    if (!(params.sch_code || params.sch_token)) {
      message.error("Invalid URL");
      return;
    }
    this.setState({ params: this.props.match.params }, () => {
      this.get_schools_list();
    });
  }

  get_schools_list = () => {
    Axios.get(
      "https://ambegaalu.in/eduga/schools_list.json?nocache=" +
        new Date().getTime()
    )
      .then(res => {
        let schools = res.data.schools;
        let school = schools.find(item => {
          return item.code === this.state.params.sch_code;
        });
        if (!school) {
          message.error("This is invalid URL");
          return;
        }
        this.setState(
          { school, base_url: school.api },
          this.get_admissions_settings
        );
      })
      .catch(err => {
        console.log(err);
      });
  };

  get_admissions_settings = () => {
    let url = this.state.base_url + "/settings/admissions/";
    Axios.get(url)
      .then(res => {
        if (res.data.success) {
          let data = res.data.data;
          if (data.adm_application_token === this.state.params.sch_token) {
            this.setState({ settings: res.data.data });
          } else if (data.adm_classes_list.length === 0) {
            message.error("Admissions not yet open");
          } else {
            message.error("Invalid URL");
          }
        } else {
          message.error("Invalid URL");
        }
      })
      .catch(err => {});
  };

  getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  handleChange = info => {
    if (info.file.status === "uploading") {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      this.getBase64(info.file.originFileObj, imageUrl =>
        this.setState({
          imageUrl,
          loading: false
        })
      );
    }
  };

  beforeUpload = file => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  handleSubmit = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        console.log(val);
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const FormItem = Form.Item;
    const { imageUrl, settings } = this.state;
    const { Option } = Select;
    return (
      <Layout.Content>
        <h1 style={{ textAlign: "center" }}>Apply for Admission</h1>
        <Form>
          <Row>
            <Col sm={16} offset={4}>
              <Row gutter={24}>
                <Col sm={24}>
                  <h2>Student Details</h2>
                </Col>
                <Col sm={12}>
                  <FormItem label="Student Name">
                    {getFieldDecorator("name")(<Input />)}
                  </FormItem>
                  <Row gutter={16}>
                    <Col sm={8}>
                      <FormItem label="Class applying to">
                        {getFieldDecorator("application_class")(
                          <Select placeholder="Select Class">
                            {settings.adm_classes_list &&
                              settings.adm_classes_list.map((item, index) => {
                                return (
                                  <Option key={index} value={item}>
                                    {item}
                                  </Option>
                                );
                              })}
                          </Select>
                        )}
                      </FormItem>
                    </Col>
                    <Col sm={8}>
                      <FormItem label="Gender">
                        {getFieldDecorator("details.student.gender")(
                          <Select placeholder="Gender">
                            <Select.Option value="m">Male</Select.Option>
                            <Select.Option value="f">Female</Select.Option>
                            <Select.Option value="o">Others</Select.Option>
                          </Select>
                        )}
                      </FormItem>
                    </Col>
                    <Col sm={8}>
                      <FormItem label="Date of Birth">
                        {getFieldDecorator("details.student.dob")(
                          <DatePicker />
                        )}
                      </FormItem>
                    </Col>
                  </Row>
                </Col>
                <Col sm={12} style={{ textAlign: "center" }}>
                  <FormItem label="Student Photo">
                    {getFieldDecorator("student_photo")(
                      <Upload
                        className="avatar-uploader"
                        beforeUpload={this.beforeUpload}
                        onChange={this.handleChange}
                        name="avatar"
                        listType="picture-card"
                      >
                        {imageUrl ? (
                          <img
                            src={imageUrl}
                            alt="avatar"
                            style={{ width: "100%" }}
                          />
                        ) : (
                          <div>
                            <Icon
                              type={this.state.loading ? "loading" : "plus"}
                            />
                            <div className="ant-upload-text">Upload</div>
                          </div>
                        )}
                      </Upload>
                    )}
                  </FormItem>
                </Col>

                <Divider />

                <Col sm={24}>
                  <h2>Parent/Guardian Details (Person of Contact)</h2>
                </Col>
                <Col sm={12}>
                  <FormItem label="Parent/Guardian Name">
                    {getFieldDecorator("parent_name")(<Input />)}
                  </FormItem>
                  <FormItem label="Parent/Guardian Email">
                    {getFieldDecorator("email")(<Input />)}
                  </FormItem>
                </Col>

                <Col sm={12}>
                  <FormItem label="Parent/Guardian Mobile">
                    {getFieldDecorator("mobile")(<Input />)}
                  </FormItem>
                  <FormItem label="Parent/Guardian Address">
                    {getFieldDecorator("mobile")(<Input.TextArea />)}
                  </FormItem>
                </Col>

                <Divider />

                <Col sm={24}>
                  <h2>Father's Details</h2>
                </Col>
                <Col sm={12}>
                  <FormItem label="Father's Name">
                    {getFieldDecorator("details.father.name")(<Input />)}
                  </FormItem>
                  <FormItem label="Father's Mobile">
                    {getFieldDecorator("details.father.mobile")(<Input />)}
                  </FormItem>
                </Col>
                <Col sm={12}>
                  <FormItem label="Father's Profession">
                    {getFieldDecorator("details.father.profession")(<Input />)}
                  </FormItem>
                  <FormItem label="Father's Annual Income">
                    {getFieldDecorator("details.father.salary")(<Input />)}
                  </FormItem>
                </Col>

                <Divider />

                <Col sm={24}>
                  <h2>Mothers's Details</h2>
                </Col>
                <Col sm={12}>
                  <FormItem label="Mother's Name">
                    {getFieldDecorator("details.mother.name")(<Input />)}
                  </FormItem>
                  <FormItem label="Mother's Mobile">
                    {getFieldDecorator("details.mother.mobile")(<Input />)}
                  </FormItem>
                </Col>
                <Col sm={12}>
                  <FormItem label="Mother's Profession">
                    {getFieldDecorator("details.mother.profession")(<Input />)}
                  </FormItem>
                  <FormItem label="Mother's Annual Income">
                    {getFieldDecorator("details.mother.salary")(<Input />)}
                  </FormItem>
                </Col>

                <Divider />

                <Col sm={24}>
                  <h2>Previous School Details</h2>
                </Col>
                <Col sm={12}>
                  <FormItem label="Previous School's Name">
                    {getFieldDecorator("details.prev_school.name")(<Input />)}
                  </FormItem>
                </Col>
                <Col sm={12}>
                  <FormItem label="Percentage/CGPA in final exam of previous year">
                    {getFieldDecorator("details.prev_school.marks")(<Input />)}
                  </FormItem>
                </Col>
                <Col sm={12}>
                  <FormItem label="City/Town/Village of Previous School">
                    {getFieldDecorator("details.prev_school.city")(<Input />)}
                  </FormItem>
                </Col>
                <Col sm={12}>
                  <FormItem label="Upload Previous Year Marks Card (Ignore if not available)">
                    {getFieldDecorator("details.prev_school.marks_card")(
                      <Upload>
                        <Button icon="upload">Upload Marks Card</Button>
                      </Upload>
                    )}
                  </FormItem>
                </Col>
              </Row>
              <FormItem>
                <Button onClick={this.handleSubmit} type="primary">
                  Submit
                </Button>
              </FormItem>
            </Col>
          </Row>
        </Form>
      </Layout.Content>
    );
  }
}

export default Form.create()(NewApplicationForm);
