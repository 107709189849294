import { Col, Layout, Row, Button, message, Table } from "antd";
import React, { Component } from "react";
import { api } from "../../../../config";
import moment from "moment";
import { Currency } from "../../../../components";
import AddPocketMoneyTransaction from "./AddPocketMoneyTransaction";

class StudentPocketMoney extends Component {
  state = {
    id: undefined,
    transactions: [],
    txnModal: false,
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      this.setState(
        { id: this.props.match.params.id },
        this.__get_student_pocket_money
      );
    }
  }

  __get_student_pocket_money = () => {
    let { id } = this.state;
    api
      .get(`/pocket-money/${id}/`)
      .then((res) => {
        if (res.data.success) {
          this.setState({ transactions: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  __closeModal = () => {
    this.setState({ txnModal: false });
    this.__get_student_pocket_money();
  };

  render() {
    const { transactions, txnModal, id } = this.state;
    const columns = [
      { title: "ID", dataIndex: "id" },
      { title: "Details", dataIndex: "details", width: "50%" },
      {
        title: "Date",
        dataIndex: "date",
        render: (v, r) => (
          <span>{moment.utc(v).local().format("YYYY-MM-DD")}</span>
        ),
      },
      {
        title: "Credit",
        render: (r) => (
          <span>{r.txn_type === 0 ? <Currency value={r.amount} /> : ""}</span>
        ),
      },
      {
        title: "Debit",
        render: (r) => (
          <span>{r.txn_type === 1 ? <Currency value={r.amount} /> : ""}</span>
        ),
      },
    ];
    return (
      <Layout.Content>
        <Row gutter={16}>
          <Col sm={12}>
            <h1>Student Pocket Money</h1>
          </Col>
          <Col sm={12} className="action-buttons">
            <Button
              type="primary"
              icon="plus"
              onClick={() => this.setState({ txnModal: true })}
            >
              Add Transaction
            </Button>
          </Col>
        </Row>
        <Table columns={columns} dataSource={transactions} rowKey="id" />
        {txnModal && (
          <AddPocketMoneyTransaction
            student_id={id}
            close={this.__closeModal}
          />
        )}
      </Layout.Content>
    );
  }
}

export default StudentPocketMoney;
