import { DatePicker, Form, Input, message, Modal, Select } from "antd";
import React, { Component } from "react";
import moment from "moment";
import { api } from "../../../../config";

class LeadContactForm extends Component {
  state = {
    loading: false,
  };

  componentDidMount() {
    if (!this.props.adm_lead_id) {
      message.error("Error");
      this.props.close();
    }
  }

  _handleSubmit = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        this.setState({ loading: true });
        val.adm_lead_id = this.props.adm_lead_id;
        api
          .put(`/admissions/leads/contact/`, JSON.stringify(val))
          .then((res) => {
            if (res.data.success) {
              message.success("New lead contact entry added!");
              this.props.close();
            } else {
              message.error(res.data.error);
            }
            this.setState({ loading: false });
          })
          .catch((err) => {
            console.log(err);
            this.setState({ loading: false });
          });
      }
    });
  };

  render() {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    const { Option } = Select;
    const { loading } = this.state;
    return (
      <Modal
        visible={true}
        title="Lead Contact Entry"
        onCancel={this.props.close.bind(this)}
        confirmLoading={loading}
        onOk={this._handleSubmit}
      >
        <Form>
          <FormItem label="Summary">
            {getFieldDecorator("summary", {
              rules: [{ required: true, message: "Summary is required!" }],
            })(<Input />)}
          </FormItem>
          <FormItem label="Details">
            {getFieldDecorator("details")(<Input.TextArea />)}
          </FormItem>
          <FormItem label="Contact Mode">
            {getFieldDecorator("mode", {
              initialValue: "call",
              rules: [{ required: true, message: "Contact mode is required!" }],
            })(
              <Select>
                <Option value="call">Call</Option>
                <Option value="message">Message</Option>
                <Option value="email">Email</Option>
              </Select>
            )}
          </FormItem>
          <FormItem label="Contact Time">
            {getFieldDecorator("contact_time", {
              rules: [{ required: true, message: "Contact time is required!" }],
              initialValue: moment(),
            })(
              <DatePicker
                showTime={{ format: "HH:mm" }}
                format="YYYY-MM-DD HH:mm"
              />
            )}
          </FormItem>
        </Form>
      </Modal>
    );
  }
}

export default Form.create()(LeadContactForm);
