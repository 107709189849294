import React, { Component } from "react";
import {
  Layout,
  Spin,
  Form,
  Input,
  Select,
  Button,
  InputNumber,
  message
} from "antd";
import { api } from "../../../../config";

class RoutesForm extends Component {
  state = {
    id: null,
    route: {}
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      this.get_route_details(this.props.match.params.id);
      this.setState({ id: this.props.match.params.id });
    }
  }

  get_route_details = id => {
    api
      .get("/simple/transportation/route/" + id + "/")
      .then(res => {
        if (res.data.success) {
          this.setState({ route: res.data.data });
          this.props.form.setFieldsValue(res.data.data);
        } else {
          message.error(res.data.error);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  add_new_route = data => {
    api
      .put("/simple/transportation/routes/", JSON.stringify(data))
      .then(res => {
        if (res.data.success) {
          message.success("New Route Added");
        } else {
          message.error(res.data.error);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  update_route = (id, data) => {
    api
      .post("/simple/transportation/route/" + id + "/", JSON.stringify(data))
      .then(res => {
        if (res.data.success) {
          message.success("Route Updated");
        } else {
          message.error(res.data.error);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  handleSubmit = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        if (this.state.id) {
          this.update_route(this.state.id, val);
        } else {
          this.add_new_route(val);
        }
      }
    });
  };

  render() {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    const { id } = this.state;
    return (
      <Layout.Content>
        <h1 className="align-center">{id ? "Update " : "Add "} Route</h1>
        <Spin spinning={false}>
          <Form labelCol={{ sm: 8 }} wrapperCol={{ sm: 8 }}>
            <FormItem label="Route Name">
              {getFieldDecorator("route_name", {
                rules: [{ required: true, message: "Route name is required!" }]
              })(<Input placeholder="Route Name" />)}
            </FormItem>
            <FormItem label="Source">
              {getFieldDecorator("source", {
                rules: [{ required: true, message: "Source is required!" }]
              })(<Input placeholder="Source" />)}
            </FormItem>
            <FormItem label="Destination">
              {getFieldDecorator("destination", {
                rules: [{ required: true, message: "Destination is required!" }]
              })(<Input placeholder="Destination" />)}
            </FormItem>
            <FormItem label="Stops">
              {getFieldDecorator("stops_list")(
                <Select mode="tags" placeholder="Stops List" />
              )}
            </FormItem>
            <FormItem label="GPS Device IMEI">
              {getFieldDecorator("device.imei")(
                <Input placeholder="GPS Device IMEI" />
              )}
            </FormItem>
            <FormItem label="Driver's Name">
              {getFieldDecorator("details.driver.name", {
                rules: [
                  { required: true, message: "Driver's name is required!" }
                ]
              })(<Input placeholder="Driver's Name" />)}
            </FormItem>
            <FormItem label="Driver's Age">
              {getFieldDecorator("details.driver.age", {
                rules: [
                  { required: true, message: "Driver's age is required!" }
                ]
              })(<InputNumber placeholder="Driver's Age" />)}
            </FormItem>
            <FormItem label="Driver Phone">
              {getFieldDecorator("details.driver.phone", {
                rules: [
                  { required: true, message: "Driver's phone is required!" }
                ]
              })(<Input placeholder="Driver's Phone Number" />)}
            </FormItem>
            <FormItem label="Vehicle Number">
              {getFieldDecorator("details.vehicle.number", {
                rules: [
                  { required: true, message: "Vehicle's number is required!" }
                ]
              })(<Input placeholder="Vehicle's Number" />)}
            </FormItem>
            <FormItem label="Vehicle Make/Model">
              {getFieldDecorator("details.vehicle.model", {
                rules: [
                  { required: true, message: "Vehicle's model is required!" }
                ]
              })(<Input placeholder="Vehicle's Make & Model" />)}
            </FormItem>
            <FormItem label="Vehicle's Age">
              {getFieldDecorator("details.vehicle.age", {
                rules: [
                  { required: true, message: "Vehicle's age is required!" }
                ]
              })(<InputNumber placeholder="Vehicles's Age" />)}
            </FormItem>
            <FormItem wrapperCol={{ offset: 8 }}>
              <Button type="primary" onClick={this.handleSubmit}>
                {id ? "Update " : "Add "} Route
              </Button>
            </FormItem>
          </Form>
        </Spin>
      </Layout.Content>
    );
  }
}

export default Form.create()(RoutesForm);
