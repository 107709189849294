import React from "react";
import { Switch, Route } from "react-router-dom";
import MeetingsList from "./MeetingsList";
import MeetingForm from "../../admin/meetings/MeetingForm";
// import MeetingRoom from "./MeetingRoom";
import JitsiMeeting from "../../admin/meetings/JitsiMeeting";

const TeacherMeetingRouter = (props) => (
  <Switch>
    <Route exact path="/meeting/" component={MeetingsList} />
    <Route exact path="/meeting/new" component={MeetingForm} />
    <Route exact path="/meeting/room/:id" component={JitsiMeeting} />
  </Switch>
);

export { TeacherMeetingRouter };
