import { Button, Col, DatePicker, Layout, message, Row, Table } from "antd";
import moment from "moment";
import React, { Component } from "react";
import {
  AcademicYearDropdown,
  ClassSectionDropdown,
  Currency,
  FeeNameDropdown,
} from "../../../../components";
import { api } from "../../../../config";

export default class FeeInstallmentReport extends Component {
  state = {
    class_id: undefined,
    ac_year_id: undefined,
    fee_name: undefined,
    date_range: [],
    data: [],
  };

  handleClassChange = (class_id) => {
    this.setState({ class_id });
  };

  handleACYearChange = (ac_year_id) => {
    this.setState({ ac_year_id });
  };

  handleFeeNameChange = (fee_name) => {
    this.setState({ fee_name });
  };

  handleGo = () => {
    const { class_id, ac_year_id, fee_name, date_range } = this.state;
    let [start_date, end_date] = date_range;
    if (!start_date || !end_date) {
      message.error("Start and end dates are required!");
      return;
    }
    start_date = start_date.format("YYYY-MM-DD");
    end_date = end_date.format("YYYY-MM-DD");
    let url = `/fees/list/${class_id}/${ac_year_id}/?fee_name=${fee_name || ""
      }&start_date=${start_date}&end_date=${end_date}`;
    api
      .get(url)
      .then((res) => {
        if (res.data.success) {
          this.setState({ data: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  exportFeesRecords = () => {
    let { data, class_id } = this.state;

    var csvContent = "data:text/csv;charset=utf-8,";
    let header =
      "Admission No,Student Name,Installment ID,Fee ID,Fee Name,Installment Amount,Payment Mode,Installment Date,Total Fee Amount,Paid Amount,Discount\r\n";
    csvContent += header;
    data.forEach((item) => {
      let line = `${item.admission_no},${item.fullname},${item.id},${item.payment_id},${item.fee_name},${item.paid_amount},${item.mode},${item.date},${item.total_amount},${item.total_paid},${item.discount_amount}\r\n`;
      csvContent += line;
    });
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `installment_history_class_${class_id}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  columns = [
    { title: "Admn. No.", dataIndex: "admission_no" },
    { title: "Student Name", dataIndex: "fullname" },
    { title: "Installment ID", dataIndex: "id" },
    { title: "Fee ID", dataIndex: "payment_id" },
    {
      title: "Installment Amount",
      dataIndex: "paid_amount",
      render: (v) => <Currency value={v} />,
    },
    {
      title: "Installment Date",
      dataIndex: "date",
      render: (v) => <span>{moment.utc(v).local().format("YYYY-MM-DD")}</span>,
    },
    {
      title: "Total Fee Amount",
      dataIndex: "total_amount",
      render: (v) => <Currency value={v} />,
    },
    {
      title: "Paid Amount",
      dataIndex: "total_paid",
      render: (v) => <Currency value={v} />,
    },
    {
      title: 'Payment Mode', dataIndex: 'mode'
    }
  ];

  render() {
    const { class_id, ac_year_id, fee_name, data } = this.state;

    return (
      <Layout.Content>
        <h1>Fee Installment Report</h1>
        <Row className="ribbon-row">
          <Col md={16}>
            <div style={{ display: "flex", alignItems: "end" }}>
              <div>
                <strong>Class : </strong>
                <ClassSectionDropdown
                  onChange={this.handleClassChange}
                  value={class_id}
                />
              </div>
              <div style={{ marginLeft: 8 }}>
                <strong>AC Year : </strong>
                <AcademicYearDropdown
                  onChange={this.handleACYearChange}
                  value={ac_year_id}
                />
              </div>
              <div style={{ marginLeft: 8 }}>
                <strong>Fee Name : </strong>
                <FeeNameDropdown
                  onChange={this.handleFeeNameChange}
                  value={fee_name}
                  class_id={class_id}
                  ac_year_id={ac_year_id}
                />
              </div>
              <div style={{ marginLeft: 8 }}>
                <strong>Fee Name : </strong>
                <DatePicker.RangePicker
                  onChange={(dr) => this.setState({ date_range: dr })}
                />
              </div>
              <div style={{ marginLeft: 12 }}>
                <Button onClick={this.handleGo}>Go</Button>
              </div>
            </div>
          </Col>
          <Col md={8} className="action-buttons">
            <Button
              icon="export"
              disabled={data.length === 0}
              onClick={this.exportFeesRecords}
            >
              Export
            </Button>
          </Col>
        </Row>
        <Table columns={this.columns} dataSource={this.state.data} />
      </Layout.Content>
    );
  }
}
