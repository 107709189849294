import React, { Component } from "react";
import {
  Layout,
  Table,
  message,
  Icon,
  Row,
  Col,
  Button,
  Tag,
  Spin,
  Modal,
} from "antd";
import { api } from "../../../../config";
import { ShowBreadcrumbs } from "../../../../components";

var moment = require("moment");

class ACYearList extends Component {
  state = {
    acyears_list: [],
    spinning: false,
  };

  componentDidMount() {
    this.get_academic_years();
  }

  get_academic_years = () => {
    this.setState({ spinning: true });
    api
      .get("/acyears/")
      .then((res) => {
        if (res.data && res.data.success) {
          this.setState({ acyears_list: res.data.data });
        } else {
          message.error(res.data.error || "Something went wrong");
        }
        this.setState({ spinning: false });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ spinning: false });
      });
  };

  changeStatus = (id, action) => {
    let content = "",
      that = this;
    switch (action) {
      case "start":
        content = "This will be set as default year for all actions";
        break;
      case "stop":
        content =
          "This will stop the current academic year and move old data to archive";
        break;
      case "delete":
        content = "The academic year will be deleted";
        break;
      default:
        content = "";
    }
    Modal.confirm({
      title: "Are you sure?",
      content: content,
      onOk() {
        api
          .patch("/acyear/" + id + "/", JSON.stringify({ action }))
          .then((res) => {
            if (res.data && res.data.success) {
              message.success("Action Completed");
              that.get_academic_years();
            } else {
              message.error(res.data.error);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
      onCancel() {
        message.info("Action Cancelled");
      },
    });
  };

  render() {
    const columns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Syllabus",
        dataIndex: "syllabus",
        key: "syllabus",
      },
      {
        title: "Start Date",
        render: (record) => (
          <span>{moment(record.start_date).format("DD MMM, YYYY")}</span>
        ),
      },
      {
        title: "End Date",
        render: (record) => (
          <span>{moment(record.end_date).format("DD MMM, YYYY")}</span>
        ),
      },
      {
        title: "Status",
        render: (record) => (
          <Tag
            color={
              record.status === 0
                ? "RED"
                : record.status === 1
                ? "GREEN"
                : record.status === 2
                ? "GREY"
                : ""
            }
          >
            {record.status === 0
              ? "UPCOMING YEAR"
              : record.status === 1
              ? "ENABLED"
              : record.status === 2
              ? "PREVIOUS YEAR"
              : ""}
          </Tag>
        ),
      },
      {
        title: "Actions",
        render: (record) => (
          <span>
            <Icon
              type="edit"
              className="action-icon"
              title="Edit"
              onClick={() => {
                this.props.history.push("/academics/acyear/" + record.id + "/");
              }}
            />
            <Icon
              type="play-circle"
              className="action-icon"
              title="Start"
              onClick={this.changeStatus.bind(this, record.id, "start")}
            />
            <Icon
              type="stop"
              className="action-icon"
              title="End"
              onClick={this.changeStatus.bind(this, record.id, "stop")}
            />
            <Icon
              type="delete"
              className="action-icon"
              title="Delete"
              onClick={this.changeStatus.bind(this, record.id, "delete")}
            />
          </span>
        ),
      },
    ];
    return (
      <Layout.Content>
        <ShowBreadcrumbs
          items={[
            { name: "Home", link: "/" },
            { name: "Academics", link: "/academics/" },
            { name: "Academic Years", link: "/academics/acyears/" },
          ]}
        />
        <Row className="ribbon-row">
          <Col sm={8}>
            <h1>Academic Years</h1>
          </Col>
          <Col sm={5} offset={11}>
            <Button
              icon="plus"
              type="primary"
              onClick={() => {
                this.props.history.push("/academics/acyear/new");
              }}
              className="action-button"
            >
              Add New Academic Year
            </Button>
          </Col>
        </Row>

        <Spin spinning={this.state.spinning}>
          <Table
            columns={columns}
            dataSource={this.state.acyears_list}
            rowKey="id"
          />
        </Spin>
      </Layout.Content>
    );
  }
}

export default ACYearList;
