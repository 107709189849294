import React from "react";
import { Switch, Route } from "react-router-dom";
import ViewFees from "./ViewFees";
import { FeeReceipt } from "../../admin/fees/pay";
import PayInstallment from "./PayInstallment";

const StudentFeesRouter = (props) => (
  <Switch>
    <Route exact path="/fees/" component={ViewFees} />
    <Route exact path="/fees/records/:id" component={ViewFees} />
    <Route exact path="/fees/receipt/:id" component={FeeReceipt} />
    <Route exact path="/fees/pay/installment/:id/" component={PayInstallment} />
  </Switch>
);

export { StudentFeesRouter };
