import React from "react";
import { Switch, Route } from "react-router";

import StudentsList from "./StudentsList";
import AddStudent from "./AddStudent";
import EditStudent from "./EditStudent";
import StudentLinkUser from "./LinkUser";
import StudentDetailsEditor from "./StudentDetailsEditor";
import StudentDetails from "./StudentDetails";
import AddNewStudent from "./AddNewStudent";
import AddStudentPage from "./add_student/AddStudentPage";
import StudentPocketMoney from "./pocket_money/StudentPocketMoney";

const AdminStudentsRouter = () => (
  <Switch>
    <Route exact path="/students/" component={StudentsList} />
    <Route exact path="/students/new/" component={AddNewStudent} />
    <Route exact path="/students/link/:id" component={StudentLinkUser} />
    <Route exact path="/students/add" component={AddStudent} />
    <Route exact path="/students/edit/:id" component={StudentDetailsEditor} />
    <Route exact path="/students/:id" component={EditStudent} />
    <Route exact path="/students/details/:id" component={StudentDetails} />
    <Route exact path="/students/add/new" component={AddStudentPage} />
    <Route
      exact
      path="/students/:id/pocket-money/"
      component={StudentPocketMoney}
    />
  </Switch>
);

export { AdminStudentsRouter };
