import React, { Component } from "react";
import { api } from "../../../config";
import { message, Radio, Button, Icon } from "antd";

class OnlineExamPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      student_id: null,
      questions: [],
      timer: 0,
      active_question: {},
      exam: {},
      question: {},
      submitting: false,
    };
    this.timer_interval = null;
    this.duration = 0;
  }

  componentDidMount() {
    let { id, student_id, exam } = this.props;
    if (id && student_id && exam) {
      this.setState(
        { id, student_id, exam, timer: exam.max_duration },
        this.get_questions_list
      );
    }
  }

  componentWillUnmount() {
    if (this.timer_interval) {
      clearInterval(this.timer_interval);
    }
  }

  get_questions_list = () => {
    let { id, student_id } = this.state;
    api
      .get("/exam/questions/" + id + "/" + student_id + "/")
      .then((res) => {
        if (res.data.success) {
          let data = res.data.data;
          data = data.map((item, index) => {
            return {
              ...item,
              answer: null,
              index: index + 1,
              state: index === 0 ? 0 : 1,
            };
          });
          this.setState(
            { questions: data, question: { ...data[0], state: 0 } },
            this.start_timer
          );
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  start_timer = () => {
    let { timer } = this.state;
    const timer_ele = document.getElementById("timer");
    this.timer_interval = setInterval(() => {
      let time = Math.floor(timer / 60) + "m " + Math.floor(timer % 60) + "s";
      timer--;
      this.duration++;
      if (timer === 0) {
        clearInterval(this.timer_interval);
        this.submit_test();
      }
      timer_ele.innerText = time;
    }, 1000);
  };

  answer_question = (item, e) => {
    let { question, questions } = this.state;
    let ans = e.target.value;
    question.answer = ans;
    questions[item.index - 1].answer = ans;
    questions[item.index - 1].state = 2;
    this.setState({ question, questions });
  };

  prev_button = (question) => {
    if (question.index === 1) {
      return;
    }
    let { questions } = this.state;
    questions = questions.map((it, ind) => {
      if (it.state === 0) {
        it.state = 1;
      }
      if (it.state === 1 && ind === question.index - 2) {
        it.state = 0;
      }
      return it;
    });
    this.setState({ questions, question: questions[question.index - 2] });
  };

  next_button = (question) => {
    let { questions } = this.state;
    if (question.index === questions.length) {
      return;
    }
    questions = questions.map((it, ind) => {
      if (it.state === 0) {
        it.state = 1;
      }
      if (it.state === 1 && ind === question.index) {
        it.state = 0;
      }
      return it;
    });
    this.setState({ questions, question: questions[question.index] });
  };

  clear_button = (question) => {
    let { questions } = this.state;
    questions[question.index - 1].answer = undefined;
    questions[question.index - 1].state = 0;
    this.setState({ question, questions });
  };

  exit_test = () => {
    clearInterval(this.timer_interval);
    this.props.finish_online_exam({ status: "EXIT", data: {} });
  };

  submit_test = () => {
    let { questions, exam, student_id } = this.state;
    let data = {
      exam_assign_id: exam.exam_assign_id,
      student_id,
      questions,
      duration: this.duration,
    };
    this.setState({ submitting: true });
    api
      .post("/exam/solutions/", JSON.stringify(data))
      .then((res) => {
        if (res.data.success) {
          clearInterval(this.timer_interval);
          this.props.finish_online_exam({
            status: "SUCCESS",
            data: res.data.data,
          });
        } else {
          message.error(res.data.error);
        }
        this.setState({ submitting: false });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ submitting: false });
      });
  };

  render() {
    let { questions, question, submitting } = this.state;
    return (
      <div className="online-exam">
        <div className="top-bar">
          <div className="exam-name">
            <h1>Exam Name</h1>
          </div>
          <div className="exam-timer">
            <h1 id="timer">&nbsp;</h1>
          </div>
          <div className="exam-buttons">
            <Button
              type="danger"
              style={{ marginRight: 10 }}
              onClick={this.exit_test}
            >
              Exit
            </Button>
            <Button
              type="primary"
              onClick={this.submit_test}
              loading={submitting}
            >
              Submit
            </Button>
          </div>
        </div>
        {question.id && (
          <div className="questions">
            <div className="question-box">
              <h2>
                Question {question.index} : {question.question}
              </h2>
              <div>
                <Radio.Group
                  value={question.answer}
                  onChange={this.answer_question.bind(this, question)}
                >
                  {question.options.map((item, index) => {
                    return (
                      <Radio value={index} key={index} className="radio">
                        {item}
                      </Radio>
                    );
                  })}
                </Radio.Group>
              </div>
              <div className="exam-footer">
                <div className="icons-set">
                  <div className="icons-left">
                    <Button
                      icon="left-circle"
                      onClick={this.prev_button.bind(this, question)}
                      disabled={question.index === 1}
                    >
                      Previous
                    </Button>
                  </div>
                  <div className="icons-right">
                    <Button
                      icon="stop"
                      style={{ marginRight: 10 }}
                      onClick={this.clear_button.bind(this, question)}
                    >
                      Clear
                    </Button>
                    <Button
                      disabled={questions.length === question.index}
                      onClick={this.next_button.bind(this, question)}
                    >
                      Next <Icon type="right-circle" />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="questions-list">
              <h1>Questions List</h1>
              <div className="questions-grid">
                {questions.map((item, index) => {
                  let color = "";
                  // 0 - active, 1 - unanswered, 2 - answered, 3 - skipped
                  switch (item.state) {
                    case 0:
                      color = "#22B6FF";
                      break;
                    case 1:
                      color = "white";
                      break;
                    case 2:
                      color = "#93db58";
                      break;
                    case 3:
                      color = "#999999";
                      break;
                    default:
                      color = "white";
                  }
                  return (
                    <div
                      className="question-number"
                      key={index}
                      onClick={() => {
                        let { questions } = this.state;
                        questions = questions.map((it, ind) => {
                          if (it.state === 0) {
                            it.state = 1;
                          }
                          if (it.state === 1 && ind === index) {
                            it.state = 0;
                          }
                          return it;
                        });
                        this.setState({ questions, question: item });
                      }}
                      style={{ backgroundColor: color }}
                    >
                      {index + 1}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default OnlineExamPage;
