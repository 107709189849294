import { Col, DatePicker, Layout, Row } from "antd";
import React, { Component } from "react";

class ExpensesByCategory extends Component {
  state = {
    expense_id: undefined,
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      this.setState({ expense_id: this.props.match.params.id });
    }
  }

  _get_expense_by_category = () => {};

  render() {
    return (
      <Layout.Content>
        <h1>Expense Report by Category</h1>
        <Row gutter={16}>
          <Col>
            <span>
              <strong>Date : </strong>
              <DatePicker.RangePicker />
            </span>
          </Col>
        </Row>
      </Layout.Content>
    );
  }
}

export default ExpensesByCategory;
