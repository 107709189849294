import React, { Component } from "react";
import {
  Layout,
  Form,
  Input,
  InputNumber,
  Button,
  DatePicker,
  message,
  Upload,
} from "antd";
import {
  ClassSectionDropdown,
  AcademicYearDropdown,
  SubjectSelectionDropdown,
  TeacherSelectionDropdown,
} from "../../../../components";
import { api, baseurl, getToken } from "../../../../config";

class NewSimpleTest extends Component {
  state = {
    class_id: undefined,
  };

  handleSubmit = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        val.files =
          val.files &&
          val.files.map((item) => {
            return {
              name: item.name,
              size: item.size,
              type: item.type,
              status: "done",
              path: item.response.path,
              url: baseurl + "/" + item.response.path,
              uid: new Date().getTime(),
            };
          });
        api
          .put("/simple/tests/", JSON.stringify(val))
          .then((res) => {
            if (res.data.success) {
              message.success("New Test Added");
              this.props.history.push("/simple-test/");
            } else {
              message.error(res.data.error);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  render() {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    const uploadProps = {
      name: "file",
      action: baseurl + "/upload/",
      headers: { Authorization: getToken() },
      accept: ".jpg,.jpeg,.png,.gif,.pdf,.docx,.doc,.odt",
      listType: "picture",
      multiple: true,
    };
    return (
      <Layout.Content>
        <h1 className="align-center">New Test</h1>
        <Form labelCol={{ sm: 8 }} wrapperCol={{ sm: 8 }}>
          <FormItem label="Test Name">
            {getFieldDecorator("test_name", {
              rules: [{ required: true, message: "Test Name is required!" }],
            })(<Input />)}
          </FormItem>
          <FormItem label="Academic Year">
            {getFieldDecorator("ac_year_id", {
              rules: [
                { required: true, message: "Academic Year is required!" },
              ],
            })(<AcademicYearDropdown />)}
          </FormItem>
          <FormItem label="Class">
            {getFieldDecorator("class_id", {
              rules: [{ required: true, message: "Class is required!" }],
            })(
              <ClassSectionDropdown
                onChange={(class_id) => this.setState({ class_id })}
              />
            )}
          </FormItem>
          <FormItem label="Subject">
            {getFieldDecorator("subject_id", {
              rules: [{ required: true, message: "Subject is required!" }],
            })(<SubjectSelectionDropdown class={this.state.class_id} />)}
          </FormItem>
          <FormItem label="Teacher (Evaluator)">
            {getFieldDecorator("evaluator", {
              rules: [{ required: true, message: "Evaluator is required!" }],
            })(<TeacherSelectionDropdown />)}
          </FormItem>
          <FormItem label="Description">
            {getFieldDecorator("description")(<Input.TextArea />)}
          </FormItem>
          <FormItem label="Files">
            {getFieldDecorator("files", {
              rules: [{ required: true, message: "Files is required!" }],
              valuePropName: "fileList",
              getValueFromEvent: this.normFile,
            })(
              <Upload {...uploadProps}>
                <Button icon="upload">Click to upload</Button>
              </Upload>
            )}
          </FormItem>
          <FormItem label="Max Marks">
            {getFieldDecorator("max_marks", {
              rules: [{ required: true, message: "Max marks is required!" }],
            })(<InputNumber />)}
          </FormItem>
          <FormItem label="Pass Marks">
            {getFieldDecorator("pass_marks")(<InputNumber />)}
          </FormItem>
          <FormItem label="Duration">
            {getFieldDecorator("duration", {
              rules: [{ required: true, message: "Duration is required!" }],
            })(<InputNumber />)}
          </FormItem>
          <FormItem label="Start Time">
            {getFieldDecorator("start_time")(
              <DatePicker
                showTime={{ format: "hh:mm", minuteStep: 10 }}
                format="YYYY-MM-DD hh:mm"
              />
            )}
          </FormItem>
          <FormItem wrapperCol={{ offset: 8 }}>
            <Button type="primary" onClick={this.handleSubmit}>
              Save
            </Button>
          </FormItem>
        </Form>
      </Layout.Content>
    );
  }
}

export default Form.create()(NewSimpleTest);
