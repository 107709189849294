import React from "react";
import { Switch, Route } from "react-router-dom";
import OnlineTestList from "./OnlineTestList";
import Questions from "./Questions";
import QuestionForm from "./QuestionForm";
import OnlineTestForm from "./OnlineTestForm";
import OnlineTestQuestions from "./OnlineTestQuestions";
import OnlineTestAddQuestions from "./OnlineTestAddQuestions";
import OnlineTestAssignments from "./OnlineTestAssignments";
import OnlineTestAttempts from "./OnlineTestAttempts";
import OnlineTestAnswers from "./OnlineTestAnswers";
import QuestionViewer from "./QuestionViewer";

const AdminOnlineTestRouter = (props) => (
  <Switch>
    <Route exact path="/online-test/" component={OnlineTestList} />
    <Route exact path="/online-test/new" component={OnlineTestForm} />
    <Route
      exact
      path="/online-test/:test_id/questions/"
      component={OnlineTestQuestions}
    />
    <Route
      exact
      path="/online-test/:test_id/questions/add"
      component={OnlineTestAddQuestions}
    />
    <Route
      exact
      path="/online-test/:test_id/assign/"
      component={OnlineTestAssignments}
    />
    <Route
      exact
      path="/online-test/:test_id/attempts/:test_assign_id/"
      component={OnlineTestAttempts}
    />
    <Route
      exact
      path="/online-test/:test_id/attempt/:test_attempt_id/answers"
      component={OnlineTestAnswers}
    />
    <Route exact path="/online-test/questions/" component={Questions} />
    <Route exact path="/online-test/question/new" component={QuestionForm} />
    <Route exact path="/online-test/question/:id" component={QuestionViewer} />
  </Switch>
);

export { AdminOnlineTestRouter };
