import React from "react";
import { Switch, Route } from "react-router-dom";
import OnlineTestsList from "./OnlineTestsList";
import OnlineTestDetails from "./OnlineTestDetails";
import OnlineTestPage from "./OnlineTestPage";

const StudentOnlineTestRouter = (props) => (
  <Switch>
    <Route exact path="/online-test/" component={OnlineTestsList} />
    <Route exact path="/online-test/test/" component={OnlineTestPage} />
    <Route exact path="/online-test/:test_id" component={OnlineTestDetails} />
  </Switch>
);

export { StudentOnlineTestRouter };
