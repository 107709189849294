import React, { Component } from "react";
import { Layout, Tabs } from "antd";
import SmsSettings from "../adminactions/sms/SmsSettings";
// import PaymentsSettings from "./PaymentsSettings";
import AdmissionsSettings from "./AdmissionsSettings";
import LibrarySettings from "./LibrarySettings";
import FeesSettings from "./FeesSettings";

class AdminSettings extends Component {
  state = {};
  render() {
    const { TabPane } = Tabs;
    return (
      <Layout.Content>
        <h1>Settings</h1>
        <Tabs>
          <TabPane tab="SMS Settings" key="1">
            <SmsSettings />
          </TabPane>
          {/* <TabPane tab="Payments Settings" key="2">
            <PaymentsSettings />
          </TabPane> */}
          <TabPane tab="Admissions Settings" key="3">
            <AdmissionsSettings />
          </TabPane>
          <TabPane tab="Library Settings" key="4">
            <LibrarySettings />
          </TabPane>
          {/* <TabPane tab="Modules List" key="5">
            <ModulesList />
          </TabPane> */}
          <TabPane tab="Fees Settings" key="6">
            <FeesSettings />
          </TabPane>
        </Tabs>
      </Layout.Content>
    );
  }
}

export default AdminSettings;
