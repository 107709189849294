import React, { Component } from "react";
import { Layout, Row, Col, Table, message } from "antd";
import { api } from "../../../config";
import { StudentSearch, TeacherSelectionDropdown } from "../../../components";
import moment from "moment";

class StudentBooksHistory extends Component {
  state = {
    spinning: false,
    student_id: null,
    teacher_id: null,
    book_issues: []
  };

  booksIssueHistory = (type, id) => {
    let url = "/library/issues/" + type + "/" + id + "/";
    this.setState({ spinning: true });
    api
      .get(url)
      .then(res => {
        if (res.data && res.data.success) {
          this.setState({ book_issues: res.data.data });
        } else {
          message.error(res.data.error);
        }
        this.setState({ spinning: false });
      })
      .catch(err => {
        console.log(err);
        this.setState({ spinning: false });
      });
  };

  render() {
    const columns = [
      { title: "ID", dataIndex: "id" },
      { title: "Book Code", dataIndex: "code" },
      { title: "Title", dataIndex: "title" },
      {
        title: "Issued",
        render: r => <span>{moment(r.issue_date).format("YYYY-MM-DD")}</span>
      },
      {
        title: "Returned",
        render: r => (
          <span>
            {r.return_date ? moment(r.return_date).format("YYYY-MM-DD") : "-"}
          </span>
        )
      },
      {
        title: "Status",
        dataIndex: "status",
        render: val => (
          <span>
            {val === 2 && "Returned"}
            {val === 1 && "Not yet returned"}
          </span>
        )
      }
    ];
    return (
      <Layout.Content>
        <Row>
          <Col sm={8}>
            <h1>Student Books Issue History</h1>
          </Col>
          <Col sm={12}>
            <span>Student : </span>
            <StudentSearch
              style={{ width: 160 }}
              value={this.state.student_id}
              onChange={val => {
                this.setState({ student_id: val });
                this.booksIssueHistory("student", val);
              }}
            />
            <span>&nbsp;or Teacher : </span>
            <TeacherSelectionDropdown
              style={{ width: 160 }}
              value={this.state.teacher_id}
              onChange={val => {
                this.setState({ teacher_id: val });
                this.booksIssueHistory("teacher", val);
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={24}>
            <Table
              columns={columns}
              dataSource={this.state.book_issues}
              rowKey="id"
              loading={this.state.spinning}
            />
          </Col>
        </Row>
      </Layout.Content>
    );
  }
}

export default StudentBooksHistory;
