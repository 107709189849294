import React, { Component } from "react";
import { Layout, Form, Input, Upload, Button, message, Spin } from "antd";
import { api, baseurl, getToken } from "../../../../config";

class AboutSchool extends Component {
  state = {
    about_school: {},
    spinning: false,
    loading: false
  };

  componentDidMount() {
    this.get_school_details();
  }

  get_school_details = () => {
    this.setState({ spinning: true });
    api
      .get("/about/school/")
      .then(res => {
        if (res.data && res.data.success) {
          let data = { ...res.data.data };
          data.school_logo = null;
          this.props.form.setFieldsValue(data);
          this.setState({ about_school: res.data.data });
        } else {
          message.error(res.data.error);
        }
        this.setState({ spinning: false });
      })
      .catch(err => {
        console.log(err);
        this.setState({ spinning: false });
      });
  };

  handleSave = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        // console.log(val);
        let data = val;
        try {
          data.school_logo = val.school_logo[0].response.path;
        } catch (err) {
          //   message.error("Please check logo file");
          data.school_logo = this.state.about_school.school_logo || null;
        }
        this.setState({ loading: true });
        api
          .post("/about/school/", JSON.stringify(data))
          .then(res => {
            if (res.data && res.data.success) {
              message.success("Updated School Details");
            } else {
              message.error(res.data.error);
            }
            this.setState({ loading: false });
          })
          .catch(err => {
            console.log(err);
            this.setState({ loading: false });
          });
      }
    });
  };

  normFile = e => {
    console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const FormItem = Form.Item;
    const { about_school } = this.state;
    return (
      <Layout.Content>
        <h1 style={{ textAlign: "center" }}>About School</h1>
        <Spin spinning={this.state.spinning}>
          <Form labelCol={{ sm: 8 }} wrapperCol={{ sm: 8 }}>
            <FormItem label="School Name">
              {getFieldDecorator("school_name", {
                rules: [{ required: true, message: "Please enter School Name" }]
              })(<Input />)}
            </FormItem>
            <FormItem label="School Short Name (for SMS)">
              {getFieldDecorator("school_short_name", {
                rules: [
                  { required: true, message: "Please enter school short name!" }
                ]
              })(<Input />)}
            </FormItem>
            <FormItem label="School Phone Number">
              {getFieldDecorator("phone_number", {
                rules: [
                  { required: true, message: "Please enter phone number" },
                  {
                    pattern: "^[0-9]+$",
                    message: "Please enter valid phone number"
                  }
                ]
              })(<Input />)}
            </FormItem>
            <FormItem label="School Address">
              {getFieldDecorator("school_address", {
                rules: [
                  { required: true, message: "Please enter School Address" }
                ]
              })(<Input.TextArea />)}
            </FormItem>
            {about_school.school_logo && (
              <FormItem label="Logo">
                <img
                  src={baseurl + "/" + about_school.school_logo}
                  alt="School Logo"
                  style={{ width: 100 }}
                />
              </FormItem>
            )}
            <FormItem label="School Logo">
              {getFieldDecorator("school_logo", {
                //   rules: [{ required: true, message: "Logo file is required" }],
                valuePropName: "fileList",
                getValueFromEvent: this.normFile
              })(
                <Upload
                  name="file"
                  action={baseurl + "/upload/"}
                  headers={{ Authorization: getToken() }}
                  listType="picture"
                  accept=".jpg,.jpeg,.png,.gif"
                >
                  <Button icon="upload">Upload</Button>
                </Upload>
              )}
            </FormItem>
            <FormItem wrapperCol={{ offset: 8 }}>
              <Button
                type="primary"
                onClick={this.handleSave}
                loading={this.state.loading}
              >
                Save
              </Button>
            </FormItem>
          </Form>
        </Spin>
      </Layout.Content>
    );
  }
}

export default Form.create()(AboutSchool);
