import React, { Component } from "react";
import { Layout, Form, Row, Col, Select, message, Input, Button } from "antd";
// import { connect } from "react-redux";
import { api } from "../../../config";
import { TeacherSelectionDropdown } from "../../../components";

class TimetableEditor extends Component {
  state = {
    timing: [],
    timetable: {
      monday: [],
      tuesday: [],
      wednesday: [],
      thursday: [],
      friday: [],
      saturday: []
    },
    class_id: null,
    day: null,
    subjects: []
  };

  componentDidMount() {
    let class_id = this.props.location.state.class_id;
    this.setState({ class_id });
    this.get_subjects(class_id);
    this.get_timing();
    this.get_timetable(class_id);
  }

  get_subjects = class_id => {
    api
      .get("/subjects/" + class_id + "/")
      .then(res => {
        if (res.data && res.data.success) {
          this.setState({ subjects: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  get_timing = () => {
    api
      .get("/timetable/timing/")
      .then(res => {
        if (res.data && res.data.success) {
          this.setState({ timing: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  get_timetable = class_id => {
    api
      .get("/timetable/" + class_id + "/")
      .then(res => {
        if (res.data && res.data.success) {
          // if (Object.keys(res.data.data).length > 0) {
          this.setState({ timetable: res.data.data });
          // }
        } else {
          message.error(res.data.error);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  handleSave = () => {
    let timetable = this.state.timetable;
    this.props.form.validateFields((err, val) => {
      if (!err) {
        timetable = { ...timetable, ...val };
        console.log(timetable);
        this.setState({ timetable });
      }
    });
  };

  handleUpdate = () => {
    let { class_id, timetable } = this.state;
    let data = { timetable };
    api
      .put("/timetable/" + class_id + "/", JSON.stringify(data))
      .then(res => {
        if (res.data && res.data.success) {
          message.success("Timetable Updated");
        } else {
          message.error(res.data.error);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  handleSubjectChange = (index, id) => {
    const { day, subjects } = this.state;
    if (!day) {
      message.error("Please select day first");
      return;
    }
    const { setFieldsValue, getFieldValue } = this.props.form;
    let data = getFieldValue(day);
    if (!id) {
      data[index] = { teacher_id: null };
      setFieldsValue({ [day]: data });
      return;
    }
    let sub = subjects.find(item => {
      if (item.id === id) {
        return item;
      }
      return false;
    });
    const period_id = data[index].period_id;
    data[index] = { teacher_id: sub.subject_teacher_id };
    setFieldsValue({ [day]: data });
    this.handleTeacherChange(period_id, sub.subject_teacher_id, index);
  };

  handleTeacherChange = (period_id, teacher_id, index) => {
    if (!teacher_id) return;
    let day = this.state.day;
    const data = { day, period_id, teacher_id };
    api
      .post("/timetable/teacher/availability/", JSON.stringify(data))
      .then(res => {
        if (res.data && res.data.success) {
          let data = res.data.data;
          if (data.length > 0) {
            let val = { [day]: [] };
            val[day][index] = {
              teacher_id: {
                value: teacher_id,
                errors: [new Error("This teacher has another class")]
              }
            };
            this.props.form.setFields(val);
          }
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  render() {
    const days = [
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday"
    ];
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    const Option = Select.Option;
    const { day } = this.state;
    return (
      <Layout.Content>
        <h1>Timetable Editor</h1>
        <Row>
          <Col sm={8}>
            <Select
              style={{ width: 200 }}
              placeholder="Select Day"
              onChange={day => {
                this.setState({ day }, () => {
                  this.props.form.setFieldsValue(this.state.timetable);
                });
              }}
            >
              {days.map((item, index) => {
                return (
                  <Option value={item} key={index}>
                    {item.charAt(0).toUpperCase() + item.slice(1)}
                  </Option>
                );
              })}
            </Select>
          </Col>
          <Col sm={11} />
          <Col sm={2} style={{ textAlign: "right" }}>
            <Button onClick={this.handleSave} type="primary">
              Save
            </Button>
          </Col>
          <Col sm={3} style={{ textAlign: "right" }}>
            <Button onClick={this.handleUpdate} type="primary">
              Update
            </Button>
          </Col>
        </Row>
        <Form>
          <div className="timetable-editor-table">
            <table>
              <thead>
                <tr>
                  {/* <th>Sl. No.</th> */}
                  <th>Period</th>
                  <th>Subject</th>
                  <th>Teacher</th>
                  <th>Classroom</th>
                </tr>
              </thead>
              <tbody>
                {this.state.timing.map((item, index) => {
                  if (item.type === 0) return null;
                  if (!day) return null;
                  const period_type = item.period_type;
                  return (
                    <tr key={index}>
                      <td
                        colSpan={period_type === 0 ? 4 : 1}
                        style={{
                          textAlign: "center",
                          background: "rgba(0, 0, 0, 0.25)"
                        }}
                      >
                        {item.name}
                      </td>
                      <td className={period_type === 0 ? "hidden" : ""}>
                        <FormItem style={{ margin: 0 }}>
                          {getFieldDecorator(`${day}[${index}].period_id`, {
                            initialValue: item.id
                          })(<Input type="hidden" />)}
                        </FormItem>
                        <FormItem style={{ margin: 0 }}>
                          {getFieldDecorator(`${day}[${index}].subject_id`)(
                            <Select
                              placeholder="Subject"
                              style={{ width: 140 }}
                              onChange={this.handleSubjectChange.bind(
                                this,
                                index
                              )}
                            >
                              <Option value={null}>No Class</Option>
                              {this.state.subjects.map((sub, ind) => {
                                return (
                                  <Option value={sub.id} key={ind}>
                                    {sub.name}
                                  </Option>
                                );
                              })}
                            </Select>
                          )}
                        </FormItem>
                      </td>
                      <td className={period_type === 0 ? "hidden" : ""}>
                        <FormItem style={{ margin: 0 }}>
                          {getFieldDecorator(`${day}[${index}].teacher_id`, {
                            initialValue: null
                          })(
                            <TeacherSelectionDropdown
                              style={{ width: 160 }}
                              onChange={this.handleTeacherChange.bind(
                                this,
                                item.id,
                                index
                              )}
                            />
                          )}
                        </FormItem>
                      </td>
                      <td className={period_type === 0 ? "hidden" : ""}>
                        <FormItem style={{ margin: 0 }}>
                          {getFieldDecorator(`${day}[${index}].room`, {
                            initialValue: null
                          })(<Input style={{ width: 120 }} />)}
                        </FormItem>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </Form>
      </Layout.Content>
    );
  }
}

// function mapStateToProps(state) {
//   return {
//     timing: state.timetable_timing
//   };
// }

export default Form.create()(TimetableEditor);
