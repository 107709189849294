import React, { useState, useEffect } from "react";
import {
  Layout,
  Row,
  Col,
  Button,
  Table,
  message,
  Icon,
  Popconfirm,
} from "antd";
import { api } from "../../../../config";
import {
  AcademicYearDropdown,
  ClassSectionDropdown,
} from "../../../../components";
import { Link } from "react-router-dom";
import moment from "moment";

let state = {
  ac_year: undefined,
  class_id: undefined,
};

export default function SimpleTestList(props) {
  const [tests, setTests] = useState([]);
  const [ac_year, set_ac_year] = useState(state.ac_year);
  const [class_id, set_class] = useState(state.class_id);

  useEffect(() => {
    ac_year && class_id && _get_tests_list(ac_year, class_id);
    return () => {
      state = { ac_year, class_id };
    };
  }, [ac_year, class_id]);

  const _get_tests_list = (ac_yr, cls) => {
    api
      .get(`/simple/tests/${ac_yr}/${cls}/`)
      .then((res) => {
        if (res.data.success) {
          setTests(res.data.data);
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const _delete_simple_test = (id) => {
    api
      .delete(`/simple/test/${id}/`)
      .then((res) => {
        if (res.data.success) {
          message.success("Test Deleted");
          _get_tests_list(ac_year, class_id);
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const _columns = [
    { title: "ID", dataIndex: "id" },
    {
      title: "Test Name",
      dataIndex: "test_name",
      render: (v, r) => (
        <Link to={{ pathname: `/simple-test/${r.id}`, state: { test: r } }}>
          {v}
        </Link>
      ),
    },
    { title: "Class", dataIndex: "class" },
    { title: "Subject", dataIndex: "subject" },
    {
      title: "Duration",
      dataIndex: "duration",
      render: (v) => <span>{v} min</span>,
    },
    {
      title: "Start Time",
      dataIndex: "start_time",
      render: (v) => (
        <span>
          {v ? moment.utc(v).local().format("YYYY-MM-DD hh:mm A") : "-"}
        </span>
      ),
    },
    {
      title: "Actions",
      render: (r) => (
        <span>
          <Popconfirm
            title="Are you sure?"
            onConfirm={_delete_simple_test.bind(this, r.id)}
          >
            <Icon type="delete" className="action-icon absent" />
          </Popconfirm>
        </span>
      ),
    },
  ];

  return (
    <Layout.Content>
      <Row>
        <Col sm={6}>
          <h1>Tests List</h1>
        </Col>
        <Col sm={12}>
          <span>
            <strong>Ac Year : </strong>
          </span>
          <AcademicYearDropdown
            value={ac_year}
            onChange={(ac_year) => set_ac_year(ac_year)}
          />
          <span>
            <strong> Class : </strong>
          </span>
          <ClassSectionDropdown
            value={class_id}
            onChange={(class_id) => set_class(class_id)}
          />
        </Col>
        <Col sm={6} className="action-buttons">
          <Button
            type="primary"
            icon="plus"
            className="action-button"
            onClick={() => props.history.push("/simple-test/new")}
          >
            New Test
          </Button>
        </Col>
      </Row>
      <Table columns={_columns} dataSource={tests} rowKey="id" />
    </Layout.Content>
  );
}
