import React, { Component } from "react";
import {
  Layout,
  Row,
  Col,
  message,
  Card,
  Divider,
  Button,
  Modal,
  List,
  Empty,
  Progress,
  Statistic,
  Icon,
  Table,
} from "antd";
import { Link } from "react-router-dom";
import { Bar } from "react-chartjs-2";
import { api } from "../../../config";
import FeeCollections from "./FeeCollections";
import { Currency } from "../../../components";

var moment = require("moment");

class AdminDashboard extends Component {
  state = {
    fees_data: {},
    students_data: {},
    disabled: false,
    card_data: {},
    latest_leads: [],
    latest_admission_applications: [],
    loading: false,
    admissions: {},
    ac_year_id: null,
  };

  componentDidMount() {
    this.get_dashboard_data();
  }

  get_dashboard_data = () => {
    const { ac_year_id } = this.state;
    this.setState({ loading: true });
    api
      .get(`/dashboard/admin/?ac_year_id=${ac_year_id}`)
      .then((res) => {
        if (res.data && res.data.success) {
          try {
            this.prepare_students_data(res.data.data.students_graph);
            this.check_sms_count(res.data.data.cards.sms);
            this.setState({
              card_data: res.data.data.cards,
              latest_leads: res.data.data.latest_leads,
              latest_admission_applications:
                res.data.data.latest_admission_applications,
              fees_data: res.data.data.fees,
              admissions: res.data.data.admissions,
            });
          } catch (err) {
            message.error(err);
          }
        } else {
          message.error(res.data.error);
        }
        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  check_sms_count = (sms_data) => {
    if (sms_data.sms_balance && sms_data.sms_balance < 50) {
      Modal.warning({
        title: "SMS Balance is Low",
        content: "Your SMS balance is low. Please recharge your account.",
      });
    }
  };

  prepare_students_data = (students_data) => {
    let labels = [],
      datasets = [],
      students_count = [],
      students_present = [],
      data = {};

    students_data.forEach((item) => {
      labels.push(item.class + " " + item.section);
      students_count.push(item.students);
      students_present.push(item.present);
    });
    datasets.push({
      data: students_count,
      backgroundColor: "rgba(50, 136, 189, 0.6)",
      label: "Total Students",
      borderColor: "rgba(50, 136, 189, 1)",
    });
    datasets.push({
      data: students_present,
      backgroundColor: "rgba(102, 189, 99, 0.6)",
      label: "Students Present",
      borderColor: "rgba(102, 189, 99, 1)",
    });
    data = {
      labels,
      datasets,
    };
    this.setState({ students_data: data });
  };

  send_fee_due_sms = () => {
    this.setState({ disabled: true });
    let that = this;
    Modal.confirm({
      title: "Do you want to send fees due SMS?",
      onOk() {
        api
          .post("/fees/due/alert/")
          .then((res) => {
            if (res.data && res.data.success) {
              message.success("SMS Sent");
            } else {
              message.error(res.data.error);
              that.setState({ disabled: false });
            }
          })
          .catch((err) => {
            console.log(err);
            that.setState({ disabled: false });
          });
      },
      onCancel() {
        that.setState({ disabled: false });
      },
    });
  };

  admissions_table = [
    { title: "ID", dataIndex: "id" },
    {
      title: "Name",
      dataIndex: "fullname",
      render: (v, r) => <Link to={`/students/details/${r.id}`}>{v}</Link>,
    },
    { title: "Admission No", dataIndex: "admission_no" },
    { title: "Roll No", dataIndex: "roll_no" },
    {
      title: "Admission Date",
      dataIndex: "admission_date",
      render: (v) => <span>{moment.utc(v).local().format("YYYY-MM-DD")}</span>,
    },
    { title: "Class", render: (r) => <span>{`${r.class} ${r.section}`}</span> },
  ];

  handleACYearChange = (ac_year_id) => {
    this.setState({ ac_year_id }, this.get_dashboard_data);
  };

  render() {
    const { students, expenses, fees, fees_due_count, sms, storage } =
      this.state.card_data;
    const { loading, latest_leads, latest_admission_applications, admissions } =
      this.state;
    return (
      <Layout.Content className="dashboard">
        <Row gutter={16}>
          <Col xl={12} sm={12} xs={24}>
            <h1>Admin Dashboard</h1>
          </Col>
          <Col xl={12} sm={12} xs={24} style={{ textAlign: "right" }}>
            {/* <AcademicYearDropdown
              onChange={this.handleACYearChange}
              value={this.state.ac_year_id}
            /> */}
          </Col>
        </Row>
        <Row gutter={16} type="flex">
          <Col xl={6} sm={12} xs={24} className="card-row">
            <Card
              title="Students"
              className="admin-card card-elevation"
              loading={loading}
            >
              <CardTable
                data={[
                  {
                    name: "Total Students",
                    value: (students && students.total_students) || 0,
                  },
                  {
                    name: "Students Present",
                    value: (students && students.present_students) || 0,
                  },
                  {
                    name: "App Users",
                    value: (students && students.app_users) || 0,
                  },
                ]}
              />
            </Card>
          </Col>
          <Col xl={6} sm={12} xs={24} className="card-row">
            <Card
              title="Fees"
              className="admin-card card-elevation"
              loading={loading}
            >
              <CardTable
                data={[
                  {
                    name: "Total Fees Amount",
                    value: (
                      <Currency value={(fees && fees.total_amount) || 0} />
                    ),
                  },
                  {
                    name: "Total Fee Collected",
                    value: <Currency value={(fees && fees.paid_amount) || 0} />,
                  },
                  {
                    name: "Total Due Amount",
                    value: (
                      <Currency
                        value={
                          (fees && fees.total_amount - fees.paid_amount) || 0
                        }
                      />
                    ),
                  },
                  {
                    name:
                      "Send Fees Due SMS (" +
                      (fees_due_count || 0) +
                      " Students)",
                    value: (
                      <Button
                        onClick={this.send_fee_due_sms}
                        disabled={this.state.disabled}
                        size="small"
                      >
                        Send SMS
                      </Button>
                    ),
                  },
                ]}
              />
            </Card>
          </Col>
          <Col xl={6} sm={12} xs={24} className="card-row">
            {storage ? (
              <Card
                title="Storage"
                className="admin-card card-elevation"
                loading={loading}
              >
                {storage && (
                  <>
                    <CardTable
                      data={[
                        {
                          name: "Used Storage",
                          value:
                            (storage &&
                              parseFloat(storage.used_storage).toFixed(2) +
                                storage.storage_unit) ||
                            "NA",
                        },
                        {
                          name: "Max Storage",
                          value:
                            (storage &&
                              parseFloat(storage.max_storage).toFixed(2) +
                                storage.storage_unit) ||
                            "NA",
                        },
                      ]}
                    />
                    <Progress
                      percent={parseFloat(
                        (parseFloat(storage.used_storage) * 100.0) /
                          parseFloat(storage.max_storage)
                      ).toFixed(2)}
                      style={{ marginTop: 10 }}
                    />
                  </>
                )}
              </Card>
            ) : (
              <Card
                title="Expenses"
                className="admin-card card-elevation"
                loading={loading}
              >
                <CardTable
                  data={[
                    {
                      name: "Total Expenses",
                      value: "₹" + ((expenses && expenses.total_amount) || 0),
                    },
                  ]}
                />
              </Card>
            )}
          </Col>
          <Col xl={6} sm={12} xs={24} className="card-row">
            <Card
              title="SMS"
              className="admin-card card-elevation"
              loading={loading}
            >
              <CardTable
                data={[
                  { name: "SMS Sent", value: (sms && sms.sms_sent) || 0 },
                  {
                    name: "SMS Balance",
                    value: (sms && sms.sms_balance) || 0,
                  },
                ]}
              />
            </Card>
          </Col>
        </Row>

        {admissions && (
          <Card title="Admissions">
            <Row gutter={16}>
              <Col lg={6} sm={24}>
                <Row gutter={16}>
                  <Col sm={24} style={{ marginBottom: 16 }}>
                    <Card>
                      <Statistic
                        title="Today"
                        value={admissions.count && admissions.count.today}
                        valueStyle={{
                          color:
                            admissions.count && admissions.count.today > 0
                              ? "#3f8600"
                              : "",
                        }}
                        prefix={
                          admissions.count && admissions.count.today > 0 ? (
                            <Icon type="arrow-up" />
                          ) : (
                            ""
                          )
                        }
                      />
                    </Card>
                  </Col>
                  <Col sm={24} style={{ marginBottom: 16 }}>
                    <Card>
                      <Statistic
                        title="This Week"
                        value={admissions.count && admissions.count.week}
                        valueStyle={{
                          color:
                            admissions.count && admissions.count.week > 0
                              ? "#3f8600"
                              : "",
                        }}
                        prefix={
                          admissions.count && admissions.count.week > 0 ? (
                            <Icon type="arrow-up" />
                          ) : (
                            ""
                          )
                        }
                      />
                    </Card>
                  </Col>
                  <Col sm={24} style={{ marginBottom: 16 }}>
                    <Card>
                      <Statistic
                        title="This Month"
                        value={admissions.count && admissions.count.month}
                        valueStyle={{
                          color:
                            admissions.count && admissions.count.month > 0
                              ? "#3f8600"
                              : "",
                        }}
                        prefix={
                          admissions.count && admissions.count.month > 0 ? (
                            <Icon type="arrow-up" />
                          ) : (
                            ""
                          )
                        }
                      />
                    </Card>
                  </Col>
                  <Col sm={24} style={{ marginBottom: 16 }}>
                    <Card>
                      <Statistic
                        title="Total Students"
                        value={students && students.total_students}
                      />
                    </Card>
                  </Col>
                </Row>
              </Col>
              <Col lg={18} sm={24}>
                <h3>Last 10 admissions</h3>
                <Table
                  columns={this.admissions_table}
                  dataSource={admissions.list || []}
                  rowKey="id"
                  pagination={false}
                  size="small"
                />
              </Col>
            </Row>
          </Card>
        )}

        <Divider />

        <Row type="flex">
          <Col sm={24} style={{ marginBottom: 20 }}>
            <FeeCollections fees_data={this.state.fees_data} />
          </Col>
          <Col sm={24}>
            <Card title="Students Count" className="card-elevation">
              <Bar
                data={this.state.students_data}
                options={{ maintainAspectRatio: false }}
                height={300}
              />
            </Card>
          </Col>
        </Row>

        <Divider />

        <Row gutter={32} type="flex">
          <Col xs={24} sm={24} lg={8} className="card-row">
            <Card
              title="Latest Leads"
              extra={<Link to="/admissions/leads/">more</Link>}
              className="admin-card card-elevation"
            >
              {latest_leads && latest_leads.length > 0 ? (
                <List>
                  {latest_leads.map((item, index) => {
                    return (
                      <List.Item key={index}>
                        <Link
                          to={{
                            pathname: "/admissions/lead/" + item.id,
                            state: item,
                          }}
                        >
                          {"Lead #" + item.id + " "}
                        </Link>
                        {item.student_name} c/o {item.parent_name} applied{" "}
                        {moment.utc(item.created).fromNow()} and the status is{" "}
                        {item.status}
                      </List.Item>
                    );
                  })}
                </List>
              ) : (
                <Empty />
              )}
            </Card>
          </Col>
          <Col xs={24} sm={24} lg={8} className="card-row">
            <Card
              title="Latest Applications"
              extra={<Link to="/admissions/applications/">more</Link>}
              className="admin-card card-elevation"
            >
              {latest_admission_applications &&
              latest_admission_applications.length > 0 ? (
                <List>
                  {latest_admission_applications.map((item, index) => {
                    return (
                      <List.Item key={index}>
                        <Link
                          to={{
                            pathname: "/admissions/application/" + item.id,
                          }}
                        >
                          {"Application #" + item.id + " "}
                        </Link>{" "}
                        {item.student_name} c/o {item.parent_name} applied to{" "}
                        {item.application_class}{" "}
                        {moment.utc(item.timestamp).local().fromNow()}
                      </List.Item>
                    );
                  })}
                </List>
              ) : (
                <Empty />
              )}
            </Card>
          </Col>
          <Col xs={24} sm={24} lg={8} className="card-row">
            <Card
              title="Reminders (Tasks)"
              className="admin-card card-elevation"
            >
              {/* <p>Display's latest 5 tasks/reminders</p> */}
              <Empty />
            </Card>
          </Col>
        </Row>
      </Layout.Content>
    );
  }
}

const CardTable = (props) => (
  <div>
    <table style={{ width: "100%" }}>
      <tbody>
        {props.data &&
          props.data.map((item, index) => {
            return (
              <tr key={index}>
                <td style={{ width: "80%" }}>{item.name}</td>
                <td style={{ width: "20%", textAlign: "right" }}>
                  {item.value}
                </td>
              </tr>
            );
          })}
      </tbody>
    </table>
  </div>
);

export default AdminDashboard;
