import {
  Button,
  Col,
  DatePicker,
  Layout,
  Row,
  Table,
  Tag,
  message,
} from "antd";
import moment from "moment";
import React, { Component } from "react";
import { api } from "../../../config";

export default class EmployeeAttendanceList extends Component {
  state = {
    employees: [],
    date: moment(),
    loading: true,
  };

  componentDidMount() {}

  getEmployeesList = () => {
    let { date } = this.state;
    if (!date) {
      message.error("Date is requried!");
      return;
    }
    this.setState({ loading: true });
    api
      .get(`/employee/attendance?date=${date.format("YYYY-MM-DD")}`)
      .then((res) => {
        if (res.data.success) {
          this.setState({ employees: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {})
      .finally(() => this.setState({ loading: false }));
  };

  render() {
    const columns = [
      { title: "Employee ID", dataIndex: "emp_id" },
      { title: "Employee Name", dataIndex: "name" },
      {
        title: "Present",
        dataIndex: "present",
        render: (v) => {
          if (v === null) {
            return " - ";
          }
          return (
            <Tag color={v === 1 ? "green" : "red"}>
              {v === 1 ? "Present" : "Absent"}
            </Tag>
          );
        },
      },
      {
        title: "Leave Type",
        dataIndex: "leave_type",
        render: (v, r) => {
          if (r.present === null || r.present === 1) {
            return " - ";
          }
          switch (v) {
            case -1:
              return "Loss of Pay";
            case 0:
              return "Unknown";
            case 1:
              return "Casual Leave";
            case 2:
              return "Sick Leave";
            default:
              return "Others";
          }
        },
      },
      { title: "Actions", render: () => <span></span> },
    ];
    return (
      <Layout.Content>
        <Row className="ribbon-row">
          <Col md={12}>
            <h1>Employee Attendance</h1>
          </Col>
          <Col md={12} className="action-buttons">
            <Button
              type="primary"
              onClick={() =>
                this.props.history.push("/employee/attendance/update")
              }
            >
              Update Attendance
            </Button>
          </Col>
        </Row>

        <div style={{ display: "flex", gap: "8px", alignItems: "flex-end" }}>
          <div>
            <p style={{ marginBottom: 0 }}>Date : </p>
            <DatePicker
              value={this.state.date}
              onChange={(d) => this.setState({ date: d })}
            />
          </div>
          <Button onClick={this.getEmployeesList}>Go</Button>
        </div>

        <Table
          columns={columns}
          dataSource={this.state.employees}
          rowKey={"emp_id"}
        />
      </Layout.Content>
    );
  }
}
