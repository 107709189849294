import {
  Button,
  Card,
  Descriptions,
  Icon,
  Layout,
  message,
  Table,
  Tag,
  Tooltip,
} from "antd";
import React, { Component } from "react";
import moment from "moment";
import { api } from "../../../../config";
import { Link } from "react-router-dom";
import LeadContactForm from "./LeadContactForm";

class LeadDetails extends Component {
  state = {
    id: undefined,
    lead: {},
    contacts: [],
    modal: false,
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      this.setState({ id: this.props.match.params.id }, () => {
        this._get_lead_details();
        this._get_lead_contacts();
      });
    }
  }

  _get_lead_details = () => {
    let { id } = this.state;
    api
      .get(`/admissions/lead/${id}/`)
      .then((res) => {
        if (res.data.success) {
          this.setState({ lead: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  _get_lead_contacts = () => {
    let { id } = this.state;
    api
      .get(`/admissions/leads/${id}/contact/`)
      .then((res) => {
        if (res.data.success) {
          this.setState({ contacts: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  get_lead_status_text = (status) => {
    switch (status) {
      case -3:
        return { text: "Not Interested", color: "red" };
      case -2:
        return { text: "Rejected (by parent)", color: "red" };
      case -1:
        return { text: "Rejected (by school)", color: "red" };
      case 0:
        return { text: "Unknown", color: "" };
      case 1:
        return { text: "Follow up (positive)", color: "lime" };
      case 2:
        return { text: "Follow up (negative)", color: "volcano" };
      case 3:
        return { text: "Pending (by parent)", color: "blue" };
      case 4:
        return { text: "Pending (by school)", color: "blue" };
      case 5:
        return { text: "Confirmed", color: "green" };
      case 6:
        return { text: "Qualified", color: "green" };
      default:
        return { text: "Unknown", color: "" };
    }
  };

  closeModal = () => {
    this.setState({ modal: false });
    this._get_lead_contacts();
  };

  render() {
    const { lead, contacts, modal, id } = this.state;
    const { Item } = Descriptions;
    const status = this.get_lead_status_text(lead.status);
    const columns = [
      { title: "ID", dataIndex: "id" },
      { title: "Contacted By", dataIndex: "name" },
      { title: "Summary", dataIndex: "summary" },
      {
        title: "Mode",
        dataIndex: "mode",
        render: (v) => <span style={{ textTransform: "capitalize" }}>{v}</span>,
      },
      {
        title: "Recording",
        dataIndex: "call_recording",
        render: (v) => {
          let j = JSON.parse(v);
          return <>{j.url ? <Link>Play</Link> : <span>-</span>}</>;
        },
      },
      {
        title: "Contact Time",
        dataIndex: "contact_time",
        render: (v) => (
          <Tooltip title={moment.utc(v).local().toString()}>
            {moment.utc(v).local().fromNow()}
          </Tooltip>
        ),
      },
      {
        title: "Actions",
        render: (r) => (
          <span>
            <Icon type="edit" className="action-icon" />
            <Icon type="delete" className="action-icon absent" />
          </span>
        ),
      },
    ];
    return (
      <Layout.Content>
        <Card
          title="Lead Details"
          style={{ marginBottom: 16 }}
          extra={[
            <Button
              icon="edit"
              size="small"
              type="primary"
              ghost
              onClick={() =>
                this.props.history.push({
                  pathname: `/admissions/lead/${id}/edit`,
                  state: lead,
                })
              }
            >
              edit
            </Button>,
          ]}
        >
          <Descriptions>
            <Item label="Parent Name">{lead.parent_name}</Item>
            <Item label="Student Name">{lead.student_name}</Item>
            <Item label="Class">{lead.class}</Item>
            <Item label="Mobile">{lead.mobile}</Item>
            <Item label="Email">{lead.email}</Item>
            <Item></Item>
            <Item label="Created">{lead.created}</Item>
            <Item label="Updated">{lead.updated}</Item>
            <Item label="Status">
              <Tag color={status.color}>{status.text}</Tag>
            </Item>
            {lead.extras &&
              Object.keys(lead.extras).map((item, index) => {
                return (
                  <Item
                    key={index}
                    label={
                      <span style={{ textTransform: "capitalize" }}>
                        {item}
                      </span>
                    }
                  >
                    {lead.extras[item]}
                  </Item>
                );
              })}
          </Descriptions>
        </Card>
        <Card
          title="Contact Log"
          extra={[
            <Button
              icon="plus"
              size="small"
              type="primary"
              ghost
              onClick={() => this.setState({ modal: true })}
            >
              Add
            </Button>,
          ]}
        >
          <Table
            columns={columns}
            dataSource={contacts}
            expandedRowRender={(r) => <span>{r.details}</span>}
            rowKey="id"
            footer={() => <div>Download Eduga Admin App to record calls.</div>}
          />
        </Card>
        {modal && <LeadContactForm close={this.closeModal} adm_lead_id={id} />}
      </Layout.Content>
    );
  }
}

export default LeadDetails;
