import { Card, Col, Descriptions, Layout, Row } from "antd";
import React, { Component } from "react";
import { baseurl } from "../../../config";

const { Item } = Descriptions;

export default class VisitorInfo extends Component {
  state = {
    info: {},
  };

  componentDidMount() {
    let info = this.props.location.state;
    if (info.photo) {
      info.photo = JSON.parse(info.photo);
    }
    if (info.extras) {
      info.extras = JSON.parse(info.extras);
    }
    console.log(info);
    this.setState({ info });
  }

  render() {
    const { info } = this.state;
    return (
      <Layout.Content>
        <Row gutter={12}>
          <Col md={18}>
            <Card title="Visitor Info">
              <Descriptions>
                <Item label="Visitor Name">{info.name}</Item>
                <Item label="Student Name">{info.student_name}</Item>
                <Item label="Mobile">{info.mobile}</Item>
                <Item label="Address">{info.address}</Item>
                <Item label="Person Visiting">
                  {info.person_visiting || " - "}
                </Item>
                <Item label="Reason Visiting">{info.reason_visiting}</Item>
                <Item label="Entry Timestamp">{info.entry_timestamp}</Item>
                <Item label="Exit Timestamp">
                  {info.exit_timestamp || " - "}
                </Item>
              </Descriptions>
            </Card>
          </Col>
          <Col md={6}>
            <Card>
              {info.photo && (
                <img
                  src={`${baseurl}/${info.photo.path}`}
                  alt="Visitor's pic"
                  style={{ maxWidth: "100%" }}
                />
              )}
            </Card>
          </Col>
        </Row>
      </Layout.Content>
    );
  }
}
