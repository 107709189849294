import {
  Button,
  Col,
  Icon,
  Layout,
  message,
  Popconfirm,
  Row,
  Table,
} from "antd";
import React, { Component } from "react";
import { AcademicYearDropdown, Currency } from "../../../components";
import { api } from "../../../config";
import StudentHostelForm from "./StudentHostelForm";
import moment from "moment";

class HostelStudents extends Component {
  state = {
    modalForm: false,
    students: [],
    ac_year_id: undefined,
    spinning: true,
  };

  componentDidMount() {
    this.setState({ spinning: true });
  }

  __get_students_list = (ac_year_id) => {
    api
      .get(`/simple-hostel/students/${ac_year_id}/`)
      .then((res) => {
        if (res.data.success) {
          this.setState({ students: res.data.data, spinning: false });
        } else {
          message.error(res.data.error);
          this.setState({ spinning: false });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({ spinning: false });
      });
  };

  __show_form_modal = () => {
    this.setState({ modalForm: true });
  };

  __closeModal = () => {
    this.setState({ modalForm: false });
    this.__get_students_list(this.state.ac_year_id);
  };

  __change_ac_year = (val) => {
    this.setState({ ac_year_id: val });
    this.__get_students_list(val);
  };

  render() {
    const { modalForm, students, ac_year_id, spinning } = this.state;
    const columns = [
      { title: "Sl. No.", render: (v, r, i) => <span>{i + 1}</span> },
      { title: "Name", dataIndex: "fullname" },
      { title: "Hostel Name", dataIndex: "hostel_name" },
      { title: "Hostel Room", dataIndex: "hostel_room" },
      {
        title: "Join Date",
        dataIndex: "join_date",
        render: (v) => (
          <span>{moment.utc(v).local().format("YYYY-MM-DD")}</span>
        ),
      },
      {
        title: "Pocket Money",
        dataIndex: "pocket_money",
        render: (v) => <Currency value={v} />,
      },
      {
        title: "Actions",
        render: (r) => (
          <span>
            <Icon
              type="calculator"
              title="Pocket Money"
              className="action-icon"
              onClick={() =>
                this.props.history.push(
                  `/students/${r.student_id}/pocket-money/`
                )
              }
            />
            <Popconfirm title="Are you sure?">
              <Icon
                type="delete"
                title="Remove student from hostel"
                className="action-icon absent"
              />
            </Popconfirm>
          </span>
        ),
      },
    ];

    return (
      <Layout.Content>
        <Row gutter={16}>
          <Col sm={6}>
            <h1>Hostel Students</h1>
          </Col>
          <Col sm={6}>
            <AcademicYearDropdown
              value={ac_year_id}
              onChange={this.__change_ac_year}
            />
          </Col>
          <Col sm={12} className="action-buttons">
            <Button icon="plus" type="primary" onClick={this.__show_form_modal}>
              Add Student
            </Button>
          </Col>
        </Row>
        <Table columns={columns} dataSource={students} loading={spinning} />
        {modalForm && <StudentHostelForm close={this.__closeModal} />}
      </Layout.Content>
    );
  }
}

export default HostelStudents;
