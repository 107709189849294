import React, { Component } from "react";
import { Select } from "antd";
import { api } from "../config";

class InventoryCategoryDropdown extends Component {
  state = {
    categories: []
  };

  componentDidMount() {
    this.get_inventory_categories_list();
  }

  get_inventory_categories_list = () => {
    api.get("/finance/inventory/categories/").then(res => {
      if (res.data && res.data.success) {
        this.setState({ categories: res.data.data });
      }
    });
  };

  render() {
    const Option = Select.Option;
    return (
      <Select
        placeholder="Inventory Category"
        showSearch
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        onChange={this.props.onChange}
        value={this.props.value}
        style={this.props.style}
        allowClear={this.props.allowClear || true}
      >
        {this.state.categories.map((item, index) => {
          return (
            <Option value={item.id} key={index}>
              {item.name}
            </Option>
          );
        })}
      </Select>
    );
  }
}

export default InventoryCategoryDropdown;
