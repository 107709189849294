import React, { Component } from "react";
import { Select } from "antd";
import { api } from "../config";

class ClassSectionDropdown extends Component {
  state = {
    classes: [],
    new_classes: [],
  };

  componentDidMount() {
    this.get_classes_list();
  }

  process_class_list = (classes) => {
    let new_classes = {};
    classes.forEach((item) => {
      if (item.branch_id) {
        if (item.branch_code in new_classes) {
          new_classes[item.branch_code].push(item);
        } else {
          new_classes[item.branch_code] = [item];
        }
      } else {
        if ("No Branch" in new_classes) {
          new_classes["No Branch"].push(item);
        } else {
          new_classes["No Branch"] = [item];
        }
      }
    });
    this.setState({ new_classes });
  };

  get_classes_list = () => {
    api
      .get("/classes-list/")
      .then((res) => {
        if (res.data.success) {
          this.process_class_list(res.data.data);
          this.setState({ classes: res.data.data });
        }
      })
      .catch((err) => {});
  };

  render() {
    const { Option, OptGroup } = Select;
    const style = { minWidth: 160, maxWidth: "100%", marginRight: 10 };
    const { new_classes } = this.state;
    return (
      <Select
        placeholder="Class"
        style={this.props.style || style}
        onChange={this.props.onChange}
        value={this.props.value || undefined}
        disabled={this.props.disabled || false}
        allowClear
        {...this.props}
      >
        {Object.keys(new_classes).map((branch, i) => {
          return (
            <OptGroup key={i} label={branch}>
              {new_classes[branch].map((item, index) => {
                return (
                  <Option value={item.id} key={index}>
                    {item.class + " " + item.section}
                  </Option>
                );
              })}
            </OptGroup>
          );
        })}
      </Select>
    );
  }
}

export default ClassSectionDropdown;
