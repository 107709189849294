import React from "react";
import { Route, Switch } from "react-router";
import ChatsList from "../../admin/chat/ChatsList";

const TeacherChatRouter = (props) => (
  <Switch>
    <Route exact path="/chat/" component={ChatsList} />
  </Switch>
);

export { TeacherChatRouter };
