import React, { Component } from "react";
import {
  Layout,
  Form,
  Input,
  InputNumber,
  DatePicker,
  Button,
  message
} from "antd";
import { api } from "../../../../config";

class SimpleExpensesForm extends Component {
  state = {
    id: null
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      this.setState({ id: this.props.match.params.id });
    }
  }

  handleSubmit = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        if (this.state.id) {
          this.update_simple_expense(this.state.id, val);
        } else {
          this.add_new_simple_expense(val);
        }
      }
    });
  };

  update_simple_expense = (id, data) => {
    console.log(id, data);
  };

  add_new_simple_expense = data => {
    api
      .put("/simple/expenses/", JSON.stringify(data))
      .then(res => {
        if (res.data && res.data.success) {
          message.success("New expense added");
          this.props.history.push("/expenses/");
        } else {
          message.error(res.data.error);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const FormItem = Form.Item;
    return (
      <Layout.Content>
        <h1 className="align-center">Expenses Form</h1>
        <Form labelCol={{ sm: 8 }} wrapperCol={{ sm: 8 }}>
          <FormItem label="Expense Name">
            {getFieldDecorator("name", {
              rules: [{ required: true, message: "Name is required!" }]
            })(<Input />)}
          </FormItem>
          <FormItem label="Amount">
            {getFieldDecorator("amount", {
              rules: [{ required: true, message: "Amount is required!" }]
            })(<InputNumber />)}
          </FormItem>
          <FormItem label="Date">
            {getFieldDecorator("date", {
              rules: [{ required: true, message: "Date is required!" }]
            })(<DatePicker />)}
          </FormItem>
          <FormItem wrapperCol={{ offset: 8 }}>
            <Button onClick={this.handleSubmit}>Save</Button>
          </FormItem>
        </Form>
      </Layout.Content>
    );
  }
}

export default Form.create()(SimpleExpensesForm);
