import React, { Component } from "react";
import { Layout, Row, Calendar, message, Col } from "antd";
import { api } from "../../../config";
import StudentSelector from "../../../components/StudentSelector";

const PRESENT = <span className="present">Present</span>;
const ABSENT = <span className="absent">Absent</span>;

class StudentAttendance extends Component {
  state = {
    attendance: [],
    student_id: null,
  };

  componentDidMount() {}

  get_attendance = (student_id) => {
    api
      .get("/attendance/students/" + student_id + "/")
      .then((res) => {
        if (res.data && res.data.success) {
          this.setState({ attendance: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleStudentChange = (student_id) => {
    this.setState({ student_id });
    this.get_attendance(student_id);
  };

  dateCellRender = (date) => {
    let attendance = this.state.attendance;
    let res = attendance.filter((item) => {
      if (item.date === date.format("YYYY-MM-DD")) {
        return item;
      }
      return null;
    });
    if (res.length === 1) {
      return res[0].present === 1 ? PRESENT : ABSENT;
    }
  };

  render() {
    return (
      <Layout.Content>
        <h1>Student Attendance</h1>
        <Row>
          <Col>
            <StudentSelector
              value={this.state.student_id}
              onChange={this.handleStudentChange}
              style={{ width: 200 }}
            />
          </Col>
        </Row>
        {this.state.attendance.length > 0 ? (
          <Calendar dateCellRender={this.dateCellRender} />
        ) : null}
      </Layout.Content>
    );
  }
}

export default StudentAttendance;
