import React from "react";
import { Switch, Route } from "react-router-dom";

import AttendanceList from "./AttendanceList";
import AttendanceUpdate from "./AttendanceUpdate";

const TeacherAttendanceRouter = props => (
  <Switch>
    <Route exact path="/attendance/" component={AttendanceList} />
    <Route exact path="/attendance/update" component={AttendanceUpdate} />
  </Switch>
);

export { TeacherAttendanceRouter };
