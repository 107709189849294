import React from "react";
import { Switch, Route } from "react-router-dom";
import StudentsList from "../../admin/students/StudentsList";
import StudentDetailsEditor from "../../admin/students/StudentDetailsEditor";

const TeacherStudentsRouter = props => (
  <Switch>
    <Route exact path="/students" component={StudentsList} />
    <Route exact path="/students/edit/:id" component={StudentDetailsEditor} />
  </Switch>
);

export { TeacherStudentsRouter };
