import React, { Component } from "react";
import { Layout, Form, Input, Button, Select, message, Icon, Spin } from "antd";
import {
  ShowBreadcrumbs,
  ClassSectionDropdown,
  TeacherSelectionDropdown
} from "../../../../components";
import { api } from "../../../../config";

class SubjectsForm extends Component {
  state = {
    update: undefined,
    elective: false,
    count: 1,
    loading: false,
    spinning: false
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      this.getSubject(this.props.match.params.id);
      this.setState({ update: this.props.match.params.id });
    }
  }

  getSubject = id => {
    this.setState({ spinning: true });
    api
      .get("/subject/" + id + "/")
      .then(res => {
        if (res.data && res.data.success) {
          let data = res.data.data;
          if (data.subject_type === 3) {
            let count = data.extras.subjects.length;
            this.setState({ elective: true, count }, () => {
              this.props.form.setFieldsValue(data);
            });
          } else {
            this.props.form.setFieldsValue(data);
          }
        } else {
          message.error(res.data.error);
        }
        this.setState({ spinning: false });
      })
      .catch(err => {
        console.log(err);
        this.setState({ spinning: false });
      });
  };

  handleSubmit = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        val.class_id = val.class_id.id || val.class_id;
        if (this.state.update) {
          this.updateSubject(val);
        } else {
          this.addNewSubject(val);
        }
      }
    });
  };

  addNewSubject = val => {
    this.setState({ loading: true });
    api
      .put("/subjects/", JSON.stringify(val))
      .then(res => {
        if (res.data && res.data.success) {
          message.success("New Subject Added");
          this.props.history.push("/academics/subjects/");
        } else {
          message.error(res.data.error);
        }
        this.setState({ loading: true });
      })
      .catch(err => {
        console.log(err);
        this.setState({ loading: true });
      });
  };

  updateSubject = val => {
    this.setState({ loading: true });
    let url = "/subject/" + this.state.update + "/";
    api
      .post(url, JSON.stringify(val))
      .then(res => {
        if (res.data && res.data.success) {
          message.success("Subject Updated");
          this.props.history.push("/academics/subjects/");
        } else {
          message.error(res.data.error);
        }
        this.setState({ loading: true });
      })
      .catch(err => {
        console.log(err);
        this.setState({ loading: true });
      });
  };

  handleSubjetTypeChange = val => {
    if (val === 3) {
      this.setState({ elective: true });
    } else {
      this.setState({ elective: false });
    }
  };

  addItem = () => {
    let count = this.state.count;
    count++;
    this.setState({ count });
  };

  removeItem = () => {
    let count = this.state.count;
    count--;
    this.setState({ count });
  };

  render() {
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      }
    };
    const { getFieldDecorator } = this.props.form;
    const FormItem = Form.Item;
    const { elective, count } = this.state;
    return (
      <Layout.Content>
        <ShowBreadcrumbs
          items={[
            { name: "Home", link: "/" },
            { name: "Academics", link: "/" },
            { name: "Subjects", link: "/academics/subjects/" },
            { name: "Add", link: "/academics/subjects/new" }
          ]}
        />
        <h1 style={{ textAlign: "center" }}>
          {this.state.update ? "Update " : "Add "} Subject
        </h1>
        <Spin spinning={this.state.spinning}>
          <Form {...formItemLayout}>
            <FormItem label="Class">
              {getFieldDecorator("class_id", {
                rules: [
                  { required: true, message: "Please select class and section" }
                ]
              })(<ClassSectionDropdown />)}
            </FormItem>
            <FormItem label="Subject Name">
              {getFieldDecorator("name", {
                rules: [
                  { required: true, message: "Please enter subject name" }
                ]
              })(<Input />)}
            </FormItem>
            <FormItem label="Subject Code">
              {getFieldDecorator("code", {
                rules: [
                  { required: true, message: "Please enter subject code" }
                ]
              })(<Input />)}
            </FormItem>
            <FormItem label="Subject Type">
              {getFieldDecorator("subject_type")(
                <Select onChange={this.handleSubjetTypeChange}>
                  <Select.Option value={1}>Primary Subject</Select.Option>
                  <Select.Option value={2}>Secondary Subject</Select.Option>
                  <Select.Option value={3}>Elective Subject</Select.Option>
                </Select>
              )}
            </FormItem>
            <FormItem label="Marks Type">
              {getFieldDecorator("marks_type")(
                <Select>
                  <Select.Option value={1}>Marks</Select.Option>
                  <Select.Option value={2}>Grades</Select.Option>
                </Select>
              )}
            </FormItem>
            {!elective && (
              <FormItem label="Subject Teacher">
                {getFieldDecorator("subject_teacher_id")(
                  <TeacherSelectionDropdown />
                )}
              </FormItem>
            )}
            {elective && (
              <div className="elective-subject-form-table">
                <table>
                  <thead>
                    <tr>
                      <th>Subject Name</th>
                      <th>Subject Code</th>
                      <th>Subject Teacher</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {[...Array(count).keys()].map(item => {
                      return (
                        <tr key={item}>
                          <td>
                            <FormItem wrapperCol={{ sm: 24 }}>
                              {getFieldDecorator(
                                `extras.subjects[${item}].subject_name`,
                                {
                                  rules: [
                                    {
                                      required: true,
                                      message: "Subject Name is required"
                                    }
                                  ]
                                }
                              )(
                                <Input
                                  placeholder="Elective Subject Name"
                                  style={{ width: 180 }}
                                />
                              )}
                            </FormItem>
                          </td>
                          <td>
                            <FormItem wrapperCol={{ sm: 24 }}>
                              {getFieldDecorator(
                                `extras.subjects[${item}].subject_code`
                              )(
                                <Input
                                  placeholder="Elective Subject Code"
                                  style={{ width: 180 }}
                                />
                              )}
                            </FormItem>
                          </td>
                          <td>
                            <FormItem wrapperCol={{ sm: 24 }}>
                              {getFieldDecorator(
                                `extras.subjects[${item}].subject_teacher`
                              )(
                                <TeacherSelectionDropdown
                                  style={{ width: 180 }}
                                />
                              )}
                            </FormItem>
                          </td>
                          <td>
                            <div style={{ marginTop: -20 }}>
                              {count - item === 1 ? (
                                <Icon
                                  type="plus-circle"
                                  style={{ fontSize: 24 }}
                                  onClick={this.addItem}
                                />
                              ) : (
                                <Icon
                                  type="minus-circle"
                                  style={{ fontSize: 24 }}
                                  onClick={this.removeItem}
                                />
                              )}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
            <FormItem wrapperCol={{ offset: 8 }}>
              <Button
                type="primary"
                onClick={this.handleSubmit}
                loading={this.state.loading}
              >
                {this.state.update ? "Update" : "Add"}
              </Button>
            </FormItem>
          </Form>
        </Spin>
      </Layout.Content>
    );
  }
}

export default Form.create()(SubjectsForm);
