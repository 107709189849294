import { Button, Card, Col, Layout, Row, Table, message } from "antd";
import React, { Component } from "react";
import { api } from "../../../../config";
import { Bar } from "react-chartjs-2";
import { Link } from "react-router-dom";
import moment from "moment";
import { Currency } from "../../../../components";

class PayrollDashboard extends Component {
  state = {
    employees: [],
    payroll_graph_data: [],
    card: {},
  };

  componentDidMount() {
    this.get_employees_list();
    this.get_payroll_dashboard_data();
  }

  get_employees_list = () => {
    api
      .get("/simple/payroll/employees/")
      .then((res) => {
        if (res.data.success) {
          this.setState({ employees: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => console.log(err));
  };

  get_payroll_dashboard_data = () => {
    api
      .get("/simple/payroll/dashboard/")
      .then((res) => {
        if (res.data.success) {
          let data = res.data.data;
          this.arrange_payroll_graph_data(data.payroll);
          this.setState({ card: data.card });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  arrange_payroll_graph_data = (payroll) => {
    let labels = [],
      datasets = [],
      salary = [],
      paid = [],
      data = {};

    payroll.forEach((item) => {
      labels.push(moment.utc(item.month_year).format("MMM YY"));
      salary.push(item.salary_amount);
      paid.push(item.amount_paid);
    });

    datasets.push({
      data: salary,
      label: "Salary",
      backgroundColor: "rgba(75, 192, 192, 0.4)",
      borderColor: "rgba(75, 192, 192, 1)",
    });
    datasets.push({
      data: paid,
      label: "Amount Paid",
      backgroundColor: "rgba(255, 99, 132, 0.4)",
      borderColor: "rgba(255, 99, 132, 1)",
    });

    data = { labels, datasets };
    this.setState({ payroll_graph_data: data });
  };

  render() {
    const { employees, payroll_graph_data, card } = this.state;
    const columns = [
      { title: "ID", dataIndex: "id" },
      {
        title: "Name",
        dataIndex: "name",
        render: (v, r) => <Link to={`/payroll/${r.id}/`}>{v}</Link>,
      },
      { title: "Job Title", dataIndex: "job_title" },
      {
        title: "Total Salary",
        dataIndex: "salary_amount",
        align: "right",
        render: (v) => <Currency value={v} />,
      },
      {
        title: "Amount Paid",
        dataIndex: "amount_paid",
        align: "right",
        render: (v) => <Currency value={v} />,
      },
      {
        title: "Balance Salary",
        align: "right",
        render: (r) => <Currency value={r.salary_amount - r.amount_paid} />,
      },
    ];
    return (
      <Layout.Content>
        <Row gutter={16}>
          <Col sm={8}>
            <h1>Payroll Dashboard</h1>
          </Col>
          <Col sm={16} className="action-buttons">
            <Button
              type="primary"
              style={{ marginLeft: 10 }}
              onClick={() => this.props.history.push("/payroll/employees/")}
            >
              Employees
            </Button>
            <Button
              icon="plus"
              type="primary"
              style={{ marginLeft: 10 }}
              onClick={() => this.props.history.push("/payroll/new")}
            >
              New Salary
            </Button>
          </Col>
        </Row>
        <Row gutter={16} type="flex" style={{ marginBottom: 16 }}>
          <Col sm={8} className="card-row">
            <Card title="Employees" className="admin-card card-elevation">
              <CardTable
                data={[
                  { name: "Total Employees", value: card.employees || "-" },
                  { name: " ", value: <span>&nbsp;</span> },
                ]}
              />
            </Card>
          </Col>
          <Col sm={8} className="card-row">
            <Card
              title="Salary This Month"
              className="admin-card card-elevation"
            >
              <CardTable
                data={[
                  {
                    name: "Salary Amount",
                    value: card.month && (
                      <Currency value={card.month.salary_amount || 0} />
                    ),
                  },
                  {
                    name: "Amount Paid",
                    value: card.month && (
                      <Currency value={card.month.amount_paid || 0} />
                    ),
                  },
                ]}
              />
            </Card>
          </Col>
          <Col sm={8} className="card-row">
            <Card title="Total Salary" className="admin-card card-elevation">
              <CardTable
                data={[
                  {
                    name: "Salary Amount",
                    value: card.total && (
                      <Currency value={card.total.salary_amount || 0} />
                    ),
                  },
                  {
                    name: "Amount Paid",
                    value: card.total && (
                      <Currency value={card.total.amount_paid || 0} />
                    ),
                  },
                ]}
              />
            </Card>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col sm={24}>
            <Card
              title="Monthly Salary"
              style={{ marginBottom: 16 }}
              className="admin-card card-elevation"
            >
              <Bar
                data={payroll_graph_data || {}}
                options={{
                  maintainAspectRatio: false,
                  scales: { yAxes: [{ ticks: { beginAtZero: true } }] },
                }}
                height={400}
              />
            </Card>
          </Col>
          <Col sm={24}>
            <Card title="Employees">
              <Table columns={columns} dataSource={employees} rowKey="id" />
            </Card>
          </Col>
        </Row>
      </Layout.Content>
    );
  }
}

export default PayrollDashboard;

const CardTable = (props) => (
  <div>
    <table style={{ width: "100%" }}>
      <tbody>
        {props.data &&
          props.data.map((item, index) => {
            return (
              <tr key={index}>
                <td style={{ width: "80%" }}>{item.name}</td>
                <td style={{ width: "20%", textAlign: "right" }}>
                  {item.value}
                </td>
              </tr>
            );
          })}
      </tbody>
    </table>
  </div>
);
