import React, { Component } from "react";
import { Select } from "antd";
import { api } from "../config";

class StudentNameSearchDropdown extends Component {
  state = {
    students: [],
  };

  get_students_list = (val) => {
    api
      .get("/search/student/" + val + "/")
      .then((res) => {
        if (res.data && res.data.success) {
          this.setState({ students: res.data.data });
        }
      })
      .catch((err) => {});
  };

  handleChange = (e) => {
    console.log(e);
  };

  render() {
    const Option = Select.Option;
    return (
      <Select
        placeholder="Student Search"
        showSearch
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        onSearch={this.get_students_list}
        onChange={this.props.onChange}
        value={this.props.value}
        style={this.props.style}
        allowClear={this.props.allowClear || true}
      >
        {this.state.students.map((item, index) => {
          return (
            <Option value={item.id} key={index}>
              {`${item.fullname} - ${item.class} (${item.admission_no})`}
            </Option>
          );
        })}
      </Select>
    );
  }
}

export default StudentNameSearchDropdown;
