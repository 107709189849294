import React from "react";
import { Switch, Route } from "react-router";

import TeachersList from "./TeachersList";
import TeachersForm from "./TeachersForm";

const AdminTeachersRouter = () => (
  <Switch>
    <Route exact path="/teachers/" component={TeachersList} />
    <Route exact path="/teachers/new" component={TeachersForm} />
    <Route exact path="/teachers/:id" component={TeachersForm} />
  </Switch>
);

export { AdminTeachersRouter };
