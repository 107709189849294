import React from "react";
import { Switch, Route } from "react-router-dom";
import { LeadsList, LeadForm } from "./leads";
import NewApplicationForm from "./applications/NewApplicationForm";
import ApplicationsList from "./applications/ApplicationsList";
import ApplicationViewer from "./applications/ApplicationViewer";
import LeadDetails from "./leads/LeadDetails";

const AdminAdmissionsRouter = (props) => (
  <Switch>
    <Route exact path="/admissions/leads/" component={LeadsList} />
    <Route exact path="/admissions/lead/new/" component={LeadForm} />
    <Route exact path="/admissions/lead/:id" component={LeadDetails} />
    <Route exact path="/admissions/lead/:id/edit" component={LeadForm} />
    <Route exact path="/admissions/apply/" component={NewApplicationForm} />
    <Route
      exact
      path="/admissions/applications/"
      component={ApplicationsList}
    />
    <Route
      exact
      path="/admissions/application/:id"
      component={ApplicationViewer}
    />
  </Switch>
);

export { AdminAdmissionsRouter };
