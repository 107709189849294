import React, { Component } from "react";
import { Modal, Form, Input, DatePicker, Row, Col, message } from "antd";
import { StudentSearch, TeacherSelectionDropdown } from "../../../components";
import { api } from "../../../config";

class IssueBook extends Component {
  state = {
    loading: false
  };

  handleSubmit = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        this.setState({ loading: true });
        api
          .put("/library/issue/", JSON.stringify(val))
          .then(res => {
            if (res.data.success) {
              message.success("Book Issued");
              this.props.closeModal();
            } else {
              message.error(res.data.error);
            }
            this.setState({ loading: false });
          })
          .catch(err => {
            console.log(err);
            this.setState({ loading: false });
          });
      }
    });
  };

  render() {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    const book = this.props.book;
    return (
      <Modal
        title="Issue Book"
        visible={this.props.visible}
        onCancel={this.props.closeModal}
        okText="Issue"
        onOk={this.handleSubmit}
        confirmLoading={this.state.loading}
      >
        <p>Code : {book.code}</p>
        <p>Title : {book.title}</p>
        <p>Author : {book.author}</p>
        <p>Publisher : {book.publisher}</p>
        <Form>
          <FormItem>
            {getFieldDecorator("book_id", { initialValue: book.id })(
              <Input hidden />
            )}
          </FormItem>
          <FormItem label="Book Code">
            {getFieldDecorator("book_code", {
              initialValue: book.code
            })(<Input disabled />)}
          </FormItem>
          <Row gutter={24}>
            <Col sm={12}>
              <FormItem label="Student admission no">
                {getFieldDecorator("student_id")(<StudentSearch />)}
              </FormItem>
            </Col>
            <Col sm={12}>
              <FormItem label="Teacher">
                {getFieldDecorator("teacher_id")(<TeacherSelectionDropdown />)}
              </FormItem>
            </Col>
          </Row>
          <FormItem label="Return Date">
            {getFieldDecorator("last_date", {
              rules: [{ required: true, message: "Return date is required!" }]
            })(<DatePicker />)}
          </FormItem>
        </Form>
      </Modal>
    );
  }
}

export default Form.create()(IssueBook);
