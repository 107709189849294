import React, { Component } from "react";
import { Select } from "antd";
import { api } from "../config";

class HRDepartmentDropdown extends Component {
  state = {
    departments: [],
  };

  componentDidMount() {
    this.get_departments_list();
  }

  get_departments_list = () => {
    api
      .get("/hr/departments/")
      .then((res) => {
        if (res.data.success) {
          this.setState({ departments: res.data.data });
        }
      })
      .catch((err) => {});
  };

  render() {
    const { Option } = Select;
    let { departments } = this.state;
    const style = { minWidth: 160, maxWidth: "100%" };
    return (
      <Select
        placeholder="HR Department"
        showSearch
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        onChange={this.props.onChange}
        value={this.props.value}
        style={this.props.style || style}
        allowClear={this.props.allowClear || true}
      >
        {departments.map((item, index) => {
          return (
            <Option key={index} value={item.id}>
              {item.name}
            </Option>
          );
        })}
      </Select>
    );
  }
}

export default HRDepartmentDropdown;
