import React from "react";
import { Switch, Route } from "react-router-dom";
import SimpleExpensesList from "./SimpleExpensesList";
import SimpleExpensesForm from "./SimpleExpensesForm";

const AdminSimpleExpensesRouter = props => (
  <Switch>
    <Route exact path="/expenses/" component={SimpleExpensesList} />
    <Route exact path="/expenses/new" component={SimpleExpensesForm} />
  </Switch>
);

export { AdminSimpleExpensesRouter };
