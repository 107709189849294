import React, { Component } from "react";
import { Icon } from "antd";
// import CanvasDraw from "react-canvas-draw";

class HomeworkCorrector extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.pos = { x: 0, y: 0 };
    this.canvasHistory = [];
    this.canvasIndex = -1;
    this.canvasPath = [];
  }

  componentDidMount() {
    this.canvas = document.getElementById("canvas");
    this.canvas.width = window.innerWidth;
    this.canvas.height = window.innerHeight;
    this.context = this.canvas.getContext("2d");
    this.canvasDrawEvents();
    this.loadImage();
  }

  canvasDrawEvents = () => {
    this.canvas.addEventListener("mouseenter", this.set_position);
    this.canvas.addEventListener("mousedown", this.set_position);
    this.canvas.addEventListener("mousemove", this.draw_canvas);
    this.canvas.addEventListener("touchstart", (e) => {
      var touch = e.touches[0];
      this.pos.x = touch.clientX;
      this.pos.y = touch.clientY;
    });
    this.canvas.addEventListener("touchend", (e) => {
      this.pos = { x: undefined, y: undefined };
    });
    this.canvas.addEventListener(
      "touchmove",
      (e) => {
        e.preventDefault();
        e.stopPropagation();
        var touch = e.touches[0];
        var mouseEvent = new MouseEvent("mousemove", {
          clientX: touch.clientX,
          clientY: touch.clientY,
          buttons: 1,
        });
        this.canvas.dispatchEvent(mouseEvent);
      },
      false
    );
  };

  draw_canvas = (e) => {
    if (e.buttons !== 1) return;
    this.context.beginPath();
    this.context.lineWidth = 5;
    this.context.lineCap = "round";
    this.context.strokeStyle = "#d00";
    this.context.moveTo(this.pos.x, this.pos.y);
    this.set_position(e);
    this.context.lineTo(this.pos.x, this.pos.y);
    this.context.stroke();
    // this.canvasPath.push({ x: e.clientX, y: e.clientY });
  };

  set_position = (e) => {
    this.pos.x = e.clientX;
    this.pos.y = e.clientY;
  };

  loadImage = () => {
    var image = new Image();
    image.crossOrigin = "anonymous";
    image.onload = () => {
      let { width, height } = this.canvas;
      let hRatio = width / image.width;
      let vRatio = height / image.height;
      var ratio = Math.min(hRatio, vRatio);
      var cx = (width - image.width * ratio) / 2;
      var cy = (height - image.height * ratio) / 2;
      this.context.clearRect(0, 0, width, height);
      this.context.fillStyle = "#d3d3d3";
      this.context.fillRect(0, 0, width, height);
      this.context.drawImage(
        image,
        0,
        0,
        image.width,
        image.height,
        cx,
        cy,
        image.width * ratio,
        image.height * ratio
      );
      this.context.stroke();
    };
    image.src = this.props.imageSrc;
  };

  handleUndo = () => {
    this.loadImage();
  };

  handleSave = async () => {
    this.canvas.toBlob((blob) => {
      let img = this.canvas.toDataURL();
      this.props.submitImage(blob, img);
    });
  };

  render() {
    // const { canvas } = this.state;
    return (
      <div className="hw-corrector">
        <div className="hw-icons">
          <div className="hw-icon">
            <Icon type="stop" title="Clear" onClick={this.handleUndo} />
          </div>
          <div className="hw-icon send-icon">
            <Icon
              type="check-circle"
              title="Submit"
              onClick={this.handleSave}
            />
          </div>
          <div className="hw-icon close-icon">
            <Icon
              type="close-square"
              title="Close"
              onClick={() => {
                this.props.closeModal();
              }}
            />
          </div>
        </div>
        <div>
          <canvas id="canvas"></canvas>
        </div>
      </div>
    );
  }
}

export default HomeworkCorrector;
