import React, { Component } from "react";
import { Layout, Form, Input, message, DatePicker, Button, Spin } from "antd";
import { api } from "../../../config";
import { ClassSectionDropdown } from "../../../components";
var moment = require("moment");

class EditStudent extends Component {
  state = {
    student_id: null,
    student: {},
    spinning: false,
    loading: false,
  };
  componentDidMount() {
    if (this.props.match.params.id) {
      this.setState({ student_id: this.props.match.params.id });
      this.getStudent(this.props.match.params.id);
    }
  }

  getStudent = (id) => {
    this.setState({ spinning: true });
    api
      .get("/student/" + id + "/")
      .then((res) => {
        if (res.data && res.data.success) {
          let data = res.data.data;
          data.admission_date = data.admission_date
            ? moment(data.admission_date)
            : "";
          data.dob = data.dob ? moment(data.dob) : "";
          this.props.form.setFieldsValue(data);
          this.setState({ student: data });
        } else {
          message.error(res.data.error);
        }
        this.setState({ spinning: false });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ spinning: false });
      });
  };

  handleUpdate = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        console.log(val);
        let url = "/student/" + this.state.student_id + "/";
        this.setState({ loading: true });
        api
          .post(url, JSON.stringify(val))
          .then((res) => {
            if (res.data && res.data.success) {
              message.success("Student Data Updated");
              this.props.history.push("/students/");
            } else {
              message.error(res.data.error);
            }
            this.setState({ loading: false });
          })
          .catch((err) => {
            console.log(err);
            this.setState({ loading: false });
          });
      }
    });
  };

  render() {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    return (
      <Layout.Content>
        <h1 style={{ textAlign: "center" }}>Edit Student</h1>
        <Spin spinning={this.state.spinning}>
          <Form labelCol={{ sm: 8 }} wrapperCol={{ sm: 8 }}>
            <FormItem label="Student Name">
              {getFieldDecorator("fullname")(<Input />)}
            </FormItem>
            <FormItem label="Admission No.">
              {getFieldDecorator("admission_no")(<Input disabled />)}
            </FormItem>
            <FormItem label="Admission Date">
              {getFieldDecorator("admission_date")(<DatePicker disabled />)}
            </FormItem>
            <FormItem label="Date of Birth">
              {getFieldDecorator("dob")(<DatePicker />)}
            </FormItem>
            <FormItem label="Mobile Number">
              {getFieldDecorator("mobile")(<Input />)}
            </FormItem>
            <FormItem label="Class">
              {getFieldDecorator("class_id")(<ClassSectionDropdown />)}
            </FormItem>
            <FormItem label="Roll No.">
              {getFieldDecorator("roll_no")(<Input />)}
            </FormItem>
            <FormItem label="Father's Name">
              {getFieldDecorator("extras.father_name")(<Input />)}
            </FormItem>
            <FormItem label="Address">
              {getFieldDecorator("extras.address")(<Input.TextArea />)}
            </FormItem>
            <FormItem label="Blood Group">
              {getFieldDecorator("extras.blood_group")(<Input />)}
            </FormItem>
            <FormItem wrapperCol={{ offset: 8 }}>
              <Button
                type="primary"
                onClick={this.handleUpdate}
                loading={this.state.loading}
              >
                Update Student
              </Button>
            </FormItem>
          </Form>
        </Spin>
      </Layout.Content>
    );
  }
}

export default Form.create()(EditStudent);
