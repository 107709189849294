import React, { Component } from "react";
import {
  Layout,
  Row,
  Col,
  Button,
  Table,
  message,
  Tag,
  Icon,
  Popconfirm,
} from "antd";
import { Link } from "react-router-dom";
import { api } from "../../../config";
import moment from "moment";
import { connect } from "react-redux";

class MeetingsList extends Component {
  state = {
    meetings: {
      organised: [],
      invitation: [],
    },
    spinning: false,
  };

  componentDidMount() {
    this.get_meetings_list();
  }

  get_meetings_list = () => {
    this.setState({ spinning: true });
    api
      .get("/meetings/user/")
      .then((res) => {
        if (res.data.success) {
          this.setState({ meetings: res.data.data });
        } else {
          message.error(res.data.error);
        }
        this.setState({ spinning: false });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ spinning: false });
      });
  };

  handle_invitation = (id, status) => {
    api
      .patch("/meeting/" + id + "/invitation/" + status + "/")
      .then((res) => {
        if (res.data.success) {
          message.success(
            "Invitation " + (status === 0 ? "Rejected" : "Accepted")
          );
          this.get_meetings_list();
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handle_cancel = (id) => {
    api
      .delete("/meeting/" + id + "/")
      .then((res) => {
        if (res.data.success) {
          message.success("Meeting canceled");
          this.get_meetings_list();
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handle_status_update = (id, status) => {
    api
      .patch("/meeting/" + id + "/status/" + status + "/")
      .then((res) => {
        if (res.data.success) {
          if (status === 1) {
            this.props.history.push("/meeting/room/" + id + "/");
          } else if (status === 2) {
            message.success("Meeting Ended");
            this.get_meetings_list();
          }
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const organiserColumns = [
      { title: "ID", dataIndex: "id" },
      {
        title: "Title",
        dataIndex: "title",
        render: (v, r) => {
          if (r.status === 1) {
            return (
              <span>{<Link to={"/meeting/room/" + r.id + "/"}>{v}</Link>}</span>
            );
          } else {
            return <span>{v}</span>;
          }
        },
      },
      { title: "Organiser", dataIndex: "name" },
      {
        title: "Start Time",
        dataIndex: "start_time",
        sorter: (a, b) =>
          moment(a.start_time).format("X") - moment(b.start_time).format("X"),
        render: (v) => (
          <span>{moment.utc(v).local().format("YYYY-MM-DD HH:mm A")}</span>
        ),
      },
      {
        title: "Status",
        dataIndex: "status",
        sorter: (a, b) => a.status - b.status,
        filters: [
          { text: "Cancelled", value: -1 },
          { text: "Upcoming", value: 0 },
          { text: "Ongoing", value: 1 },
          { text: "Ended", value: 2 },
        ],
        onFilter: (v, r) => v === r.status,
        render: (v, r, i) => {
          switch (v) {
            case -1:
              return <Tag color="red">Cancelled</Tag>;
            case 0:
              return <Tag color="cyan">Upcoming</Tag>;
            case 1:
              return <Tag color="green">Ongoing</Tag>;
            case 2:
              return <Tag color="volcano">Ended</Tag>;
            default:
              return <Tag>Unknown</Tag>;
          }
        },
      },
      {
        title: "Action",
        render: (r) => (
          <span>
            <Icon
              type="ordered-list"
              className="action-icon"
              title="Participants"
              onClick={() => {
                this.props.history.push("/meeting/" + r.id + "/participants/");
              }}
            />
            {r.status === 0 && (
              <Icon
                type="play-circle"
                className="action-icon"
                title="Start"
                onClick={this.handle_status_update.bind(this, r.id, 1)}
              />
            )}
            {r.status === 1 && (
              <Popconfirm
                title="Are you sure to end this meeting?"
                onConfirm={this.handle_status_update.bind(this, r.id, 2)}
              >
                <Icon
                  type="close-circle"
                  className="action-icon absent"
                  title="End Meeting"
                />
              </Popconfirm>
            )}
            {r.status === 0 && (
              <Popconfirm
                title="Are you sure to cancel meeting?"
                onConfirm={this.handle_cancel.bind(this, r.id)}
              >
                <Icon
                  type="stop"
                  className="action-icon absent"
                  title="Cancel Meeting"
                />
              </Popconfirm>
            )}
          </span>
        ),
      },
    ];
    const invitationColumns = [
      { title: "ID", dataIndex: "id" },
      {
        title: "Title",
        dataIndex: "title",
        render: (v, r) => (
          <span>{<Link to={"/meeting/room/" + r.id + "/"}>{v}</Link>}</span>
        ),
      },
      { title: "Organiser", dataIndex: "name" },
      {
        title: "Start Time",
        dataIndex: "start_time",
        sorter: (a, b) =>
          moment(a.start_time).format("X") - moment(b.start_time).format("X"),
        render: (v) => (
          <span>{moment.utc(v).local().format("YYYY-MM-DD HH:mm A")}</span>
        ),
      },
      {
        title: "Meeting Status",
        dataIndex: "status",
        sorter: (a, b) => a.status - b.status,
        filters: [
          { text: "Cancelled", value: -1 },
          { text: "Upcoming", value: 0 },
          { text: "Ongoing", value: 1 },
          { text: "Ended", value: 2 },
        ],
        onFilter: (v, r) => v === r.status,
        render: (v, r, i) => {
          switch (v) {
            case -1:
              return <Tag color="red">Cancelled</Tag>;
            case 0:
              return <Tag color="cyan">Upcoming</Tag>;
            case 1:
              return <Tag color="green">Ongoing</Tag>;
            case 2:
              return <Tag color="volcano">Ended</Tag>;
            default:
              return <Tag>Unknown</Tag>;
          }
        },
      },
      {
        title: "Your Status",
        dataIndex: "accepted",
        render: (v) => {
          switch (v) {
            case -1:
              return "Not Attending";
            case 0:
              return "Unknown";
            case 1:
              return "Attending";
            default:
              return "Unknown";
          }
        },
      },
      {
        title: "Actions",
        render: (r) => (
          <span>
            {r.status === 0 && (
              <>
                {r.accepted === 1 ? (
                  <Icon
                    type="close-square"
                    className="action-icon"
                    onClick={this.handle_invitation.bind(this, r.id, -1)}
                    title="Reject Invitation"
                  />
                ) : (
                  <Icon
                    type="check-square"
                    className="action-icon"
                    onClick={this.handle_invitation.bind(this, r.id, 1)}
                    title="Accept Invitation"
                  />
                )}
              </>
            )}
          </span>
        ),
      },
    ];
    const { meetings } = this.state;
    const { user } = this.props;
    return (
      <Layout.Content>
        <Row>
          <Col sm={12}>
            <h1>Meetings List</h1>
          </Col>
          <Col sm={12} style={{ textAlign: "right" }}>
            {[2, 3, 4].includes(user.role) && (
              <Link to="/meeting/new">
                <Button type="primary" icon="plus">
                  New Meeting
                </Button>
              </Link>
            )}
          </Col>
        </Row>
        {[2, 3, 4].includes(user.role) && (
          <>
            <h3 style={{ marginTop: 20 }}>Organised by You</h3>
            <Table
              columns={organiserColumns}
              dataSource={meetings.organised}
              rowKey="id"
              loading={this.state.spinning}
            />
          </>
        )}
        <h3 style={{ marginTop: 20 }}>Invitations</h3>
        <Table
          columns={invitationColumns}
          dataSource={meetings.invitation}
          rowKey="id"
          loading={this.state.spinning}
        />
      </Layout.Content>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps, null)(MeetingsList);
