import React, { Component } from "react";
import {
  Layout,
  message,
  Avatar,
  Comment,
  Tooltip,
  Card,
  Input,
  Button,
} from "antd";
import moment from "moment";
import { api } from "../../../config";
import { ShowBreadcrumbs } from "../../../components";

class VCRoomQuestion extends Component {
  state = {
    vc_class_id: null,
    student: {},
    id: null,
    question: {},
    answers: [],
    reply_id: null,
    reply_text: "",
    answer_text: "",
  };

  componentDidMount() {
    if (
      this.props.match.params.vc_class_id &&
      this.props.location.state.student
    ) {
      this.setState(
        {
          vc_class_id: this.props.match.params.vc_class_id,
          id: this.props.match.params.id,
          student: this.props.location.state.student,
        },
        this.get_question
      );
    }
  }

  get_question = () => {
    const { vc_class_id, id } = this.state;
    const url = "/virtual-class/" + vc_class_id + "/question/" + id + "/";
    api
      .get(url)
      .then((res) => {
        if (res.data.success) {
          let { question, answers } = res.data.data;
          this.setState({ question, answers });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleAnswer = () => {
    const { answer_text, student, question } = this.state;
    let data = {
      answer: answer_text,
      question_id: question.id,
      student_id: student.id,
      parent_answer_id: null,
    };
    api
      .post("/virtual-class/question/reply/", JSON.stringify(data))
      .then((res) => {
        if (res.data.success) {
          message.success("Question Answered");
          this.setState({ answer_text: "" });
          this.get_question();
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleReply = () => {
    const { reply_id, reply_text, student, question } = this.state;
    if (!reply_text || reply_text.length === 0) {
      message.error("Reply is empty");
      return;
    }
    let data = {
      answer: reply_text,
      question_id: question.id,
      student_id: student.id,
      parent_answer_id: reply_id,
    };
    api
      .post("/virtual-class/question/reply/", JSON.stringify(data))
      .then((res) => {
        if (res.data.success) {
          message.success("Answer Replied");
          this.setState({ reply_id: null, reply_text: "" });
          this.get_question();
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const { question, answers, reply_id, student, vc_class_id } = this.state;
    return (
      <Layout.Content>
        <ShowBreadcrumbs
          items={[
            { name: "Home", link: "/" },
            { name: "Virtual Classroom List", link: "/virtual-classroom/" },
            {
              name: "Virtual Classroom",
              link: {
                pathname: "/virtual-classroom/" + vc_class_id,
                state: { student },
              },
            },
            {
              name: "Questions List",
              link: {
                pathname: "/virtual-classroom/" + vc_class_id + "/questions/",
                state: { student },
              },
            },
            { name: "Question" },
          ]}
        />
        <Card title="Question">
          <h2>
            Question {question.id} : {question.question}
          </h2>
          <p>{question.description}</p>
          {answers.map((answer, index) => {
            return (
              <Comment
                key={index}
                actions={[
                  <span
                    key="comment-basic-reply-to"
                    onClick={() => {
                      this.setState({ reply_id: answer.id, reply_text: "" });
                    }}
                  >
                    Reply to
                  </span>,
                ]}
                author={answer.student_name || answer.teacher_name}
                avatar={
                  <Avatar
                    src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
                    alt="Han Solo"
                  />
                }
                content={<p>{answer.answer}</p>}
                datetime={
                  <Tooltip title={moment().format("YYYY-MM-DD HH:mm:ss")}>
                    <span>{moment().fromNow()}</span>
                  </Tooltip>
                }
              >
                {answer.replies &&
                  answer.replies.map((reply, ind) => {
                    return (
                      <Comment
                        key={ind}
                        author={reply.student_name || reply.teacher_name}
                        avatar={
                          <Avatar
                            src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
                            alt="Han Solo"
                          />
                        }
                        content={<p>{reply.answer}</p>}
                        datetime={
                          <Tooltip
                            title={moment().format("YYYY-MM-DD HH:mm:ss")}
                          >
                            <span>{moment().fromNow()}</span>
                          </Tooltip>
                        }
                      />
                    );
                  })}
                {reply_id === answer.id && (
                  <div>
                    <Input.TextArea
                      style={{ marginBottom: 10 }}
                      value={this.state.reply_text}
                      onChange={(e) => {
                        this.setState({ reply_text: e.target.value });
                      }}
                    />
                    <Button
                      type="primary"
                      style={{ marginBottom: 10 }}
                      onClick={this.handleReply}
                    >
                      Reply
                    </Button>
                  </div>
                )}
              </Comment>
            );
          })}
          <Input.TextArea
            style={{ marginBottom: 10 }}
            value={this.state.answer_text}
            onChange={(e) => {
              this.setState({ answer_text: e.target.value });
            }}
          />
          <Button type="primary" onClick={this.handleAnswer}>
            Answer
          </Button>
        </Card>
      </Layout.Content>
    );
  }
}

export default VCRoomQuestion;
