import React, { Component } from "react";
import { Table, message, Icon, Modal } from "antd";
import { api } from "../config";

class GetAnnouncements extends Component {
  state = {
    announcements: [],
    show_announcement: false,
    announcement: {}
  };

  componentDidMount() {
    this.getAnnouncements();
  }

  getAnnouncements = () => {
    api
      .get("/announcements/")
      .then(res => {
        if (res.data && res.data.success) {
          this.setState({ announcements: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  closeModal = () => {
    this.setState({ show_announcement: false });
  };

  render() {
    const columns = [
      { title: "From", dataIndex: "from_name" },
      { title: "Title", dataIndex: "title" },
      { title: "Time", dataIndex: "timestamp" },
      {
        title: "Actions",
        render: record => (
          <span>
            <Icon
              type="read"
              className="action-icon"
              onClick={() => {
                this.setState({
                  announcement: record,
                  show_announcement: true
                });
              }}
            />
          </span>
        )
      }
    ];
    const { show_announcement, announcement } = this.state;
    return (
      <div>
        <Table
          columns={columns}
          dataSource={this.state.announcements}
          rowKey="id"
        />
        {show_announcement && (
          <Modal
            visible={show_announcement}
            onCancel={this.closeModal}
            cancelText="Close"
            title="Announcement"
          >
            <h2>{announcement.title}</h2>
            {/* <h5>From: {announcement.from_name}</h5> */}
            {/* <h5>Date: {announcement.timestamp}</h5> */}
            <p>{announcement.content}</p>
          </Modal>
        )}
      </div>
    );
  }
}

export default GetAnnouncements;
