import React from "react";
import { Switch, Route } from "react-router-dom";
import AppUsers from "./AppUsers";
import SMSList from "./SMSList";
import MultiClassReport from "../students/MultiClassReport";
import MultiClassFeesReport from "../fees/reports/MultiClassFeesReport";

const AdminReportsRouter = (props) => (
  <Switch>
    <Route exact path="/reports/sms/" component={SMSList} />
    <Route exact path="/reports/app-users/" component={AppUsers} />
    <Route exact path="/reports/multi-class/" component={MultiClassReport} />
    <Route
      exact
      path="/reports/multi-class/fees"
      component={MultiClassFeesReport}
    />
  </Switch>
);

export { AdminReportsRouter };
