import React, { Component } from "react";
import {
  Layout,
  Row,
  Col,
  Button,
  Table,
  message,
  Icon,
  Popconfirm,
  Input,
} from "antd";
import { Link } from "react-router-dom";
import { api } from "../../../../config";
import { Currency, PrintTable, ShowBreadcrumbs } from "../../../../components";
import Highlighter from "react-highlight-words";

class InventoryList extends Component {
  state = {
    spinning: false,
    inventories: [],
    searchText: undefined,
    searchColumn: undefined,
  };

  componentDidMount() {
    this.get_inventory_list();
  }

  get_inventory_list = () => {
    this.setState({ spinning: true });
    api
      .get("/finance/inventory/")
      .then((res) => {
        if (res.data && res.data.success) {
          this.setState({ inventories: res.data.data });
        } else {
          message.error(res.data.error);
        }
        this.setState({ spinning: false });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ spinning: false });
      });
  };

  delete_inventory_item = (id) => {
    api
      .delete("/finance/inventory/" + id + "/")
      .then((res) => {
        if (res.data && res.data.success) {
          message.success("Inventory item deleted");
          this.get_inventory_list();
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search by name`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text, r) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        <span>{text}</span>
      ),
  });

  exportInventoryReports = () => {
    let { inventories } = this.state;

    var csvContent = "data:text/csv;charset=utf-8,";
    let header = "ID,Name,Quantity,Amount\r\n";
    csvContent += header;
    inventories.forEach((item) => {
      let line = `${item.id},${item.name},${item.quantity},${item.total_amount}\r\n`;
      csvContent += line;
    });
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute(
      "download",
      `inventory_reports_${new Date().getTime()}.csv`
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  render() {
    const columns = [
      { title: "ID", dataIndex: "id", print: true },
      {
        title: "Name",
        dataIndex: "name",
        print: true,
        ...this.getColumnSearchProps("name"),
      },
      {
        title: "Quantity",
        print: true,
        render: (r) => (
          <span>
            {r.quantity} {r.unit || ""}
          </span>
        ),
      },
      {
        title: "Price",
        dataIndex: "total_amount",
        align: "right",
        print: true,
        render: (v) => <Currency value={v} />,
      },
      {
        title: "Actions",
        print: false,
        render: (record) => (
          <span>
            <Icon
              type="edit"
              className="action-icon"
              onClick={() => {
                this.props.history.push(
                  "/finance/inventory/" + record.id + "/"
                );
              }}
            />
            <Popconfirm
              title="Are you sure?"
              onConfirm={this.delete_inventory_item.bind(this, record.id)}
            >
              <Icon type="delete" className="action-icon absent" />
            </Popconfirm>
          </span>
        ),
      },
    ];

    return (
      <Layout.Content>
        <ShowBreadcrumbs
          items={[
            { name: "Home", link: "/" },
            { name: "Finance", link: "/finance/" },
            { name: "Inventory", link: "/finance/inventory/" },
          ]}
        />
        <Row>
          <Col sm={8}>
            <h1>Inventory List</h1>
          </Col>
          <Col sm={16} style={{ textAlign: "right" }}>
            <Button
              icon="export"
              type="primary"
              ghost
              style={{ marginRight: 8 }}
              onClick={this.exportInventoryReports}
            >
              CSV
            </Button>
            <Button
              type="primary"
              icon="file-pdf"
              style={{ marginRight: 8 }}
              onClick={() => window.print()}
            >
              PDF
            </Button>
            <Link to="/finance/inventory/category">
              <Button icon="plus" type="primary">
                Inventory Categories
              </Button>
            </Link>
            <Link to="/finance/inventory/new">
              <Button icon="plus" type="primary" style={{ marginLeft: 8 }}>
                Add new item
              </Button>
            </Link>
          </Col>
        </Row>
        <Table
          columns={columns}
          dataSource={this.state.inventories}
          rowKey="id"
          loading={this.state.spinning}
        />
        <PrintTable
          columns={columns}
          data={this.state.inventories}
          type="Inventory List"
        />
      </Layout.Content>
    );
  }
}

export default InventoryList;
