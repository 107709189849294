import React, { Component } from "react";
import {
  Layout,
  Row,
  Col,
  message,
  Button,
  Form,
  InputNumber,
  Input,
  Table,
  Spin,
} from "antd";
import {
  ClassSectionDropdown,
  SubjectSelectionDropdown,
  TestSelectionDropdown,
  AcademicYearDropdown,
} from "../../../components";
import { api } from "../../../config";

class MarksEditor extends Component {
  state = {
    tests: [],
    class_id: null,
    test_id: null,
    subject_id: null,
    students: [],
    test_subject: {},
    loading: false,
    spinning: false,
    ac_year_id: null,
  };

  componentDidMount() {}

  handleGo = () => {
    let { class_id, test_id, subject_id, ac_year_id } = this.state;
    if (!(class_id && test_id && subject_id && ac_year_id)) {
      message.error("Select Class, Section, Academic Year, Test & Subject");
      return;
    }
    this.getTestSubjectId(test_id, class_id, subject_id);
  };

  getTestSubjectId = (test_id, class_id, subject_id) => {
    let url =
      "/class/test/" + test_id + "/" + class_id + "/" + subject_id + "/";
    api
      .get(url)
      .then((res) => {
        if (res.data && res.data.success) {
          if (!res.data.data) {
            message.error("No test taken");
          }
          this.getStudentsList(res.data.data.id, class_id);
          this.setState({ test_subject: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getStudentsList = (test_subject_id, class_id) => {
    let { ac_year_id } = this.state;
    let url = "/update/marks/" + test_subject_id + "/" + class_id + "/";
    url = ac_year_id ? url + ac_year_id + "/" : url;
    this.setState({ spinning: true });
    api
      .get(url)
      .then((res) => {
        if (res.data && res.data.success) {
          this.setState({ students: res.data.data });
        } else {
          message.error(res.data.error);
        }
        this.setState({ spinning: false });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ spinning: false });
      });
  };

  handleSave = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        let { test_subject } = this.state;
        if (!test_subject.id) {
          message.error("Select Class, Section, Test & Subject");
          return;
        }
        val.test_subject_id = test_subject.id;
        this.setState({ loading: true });
        api
          .put("/update/marks/", JSON.stringify(val))
          .then((res) => {
            if (res.data && res.data.success) {
              message.success("Marks Updated");
              this.props.history.push("/marks/");
            } else {
              message.error(res.data.error);
            }
            this.setState({ loading: false });
          })
          .catch((err) => {
            console.log(err);
            this.setState({ loading: false });
          });
      }
    });
  };

  validate_marks = (rule, value, callback) => {
    if (value > this.state.test_subject.max_marks) {
      callback(true);
    }
    callback();
  };

  render() {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    const max_marks = this.state.test_subject.max_marks;
    const columns = [
      {
        title: "Admn. No.",
        dataIndex: "admission_no",
      },
      {
        title: "Student Name",
        dataIndex: "fullname",
        sorter: (a, b) =>
          a.fullname.toLowerCase() < b.fullname.toLowerCase() ? -1 : 1,
      },
      {
        title: "Marks (max marks " + (max_marks || "") + ")",
        render: (text, record, index) => (
          <span>
            <FormItem>
              {getFieldDecorator(`marks[${index}].marks_id`, {
                initialValue: record.marks_id,
              })(<Input type="hidden" />)}
            </FormItem>
            <FormItem>
              {getFieldDecorator(`marks[${index}].student_id`, {
                initialValue: record.id,
              })(<Input type="hidden" />)}
            </FormItem>
            <FormItem>
              {getFieldDecorator(`marks[${index}].marks`, {
                initialValue: record.marks_obtained,
                rules: [
                  {
                    validator: this.validate_marks,
                    message: "Marks obtained must be less than max marks",
                  },
                ],
              })(<InputNumber />)}
            </FormItem>
          </span>
        ),
      },
      {
        title: "Grade",
        render: (text, record, index) => (
          <span>
            <FormItem>
              {getFieldDecorator(`marks[${index}].grade`, {
                initialValue: record.grade,
              })(<Input style={{ width: 80 }} />)}
            </FormItem>
          </span>
        ),
      },
    ];
    return (
      <Layout.Content>
        <h1>Marks Editor</h1>
        <Row gutter={32}>
          <Col sm={6}>
            <h3>Class &amp; Section</h3>
            <ClassSectionDropdown
              value={this.state.class_id}
              onChange={(class_id) => {
                this.setState({ class_id });
              }}
            />
          </Col>
          <Col sm={4}>
            <h3>AC Year</h3>
            <AcademicYearDropdown
              style={{ width: 200, maxWidth: "100%" }}
              onChange={(ac_year_id) => {
                this.setState({ ac_year_id });
              }}
              value={this.state.ac_year_id}
            />
          </Col>
          <Col sm={4}>
            <h3>Test</h3>
            <TestSelectionDropdown
              style={{ width: 200, maxWidth: "100%" }}
              onChange={(test_id) => {
                this.setState({ test_id });
              }}
              value={this.state.test_id}
              ac_year_id={this.state.ac_year_id}
            />
          </Col>
          <Col sm={4}>
            <h3>Subject</h3>
            <SubjectSelectionDropdown
              class={this.state.class_id}
              style={{ width: 180, maxWidth: "100%" }}
              value={this.state.subject_id}
              onChange={(subject_id) => {
                this.setState({ subject_id });
              }}
            />
          </Col>
          <Col sm={2}>
            <Button style={{ marginTop: 33.5 }} onClick={this.handleGo}>
              Go
            </Button>
          </Col>
          <Col sm={4} style={{ textAlign: "right" }}>
            <Button
              type="primary"
              style={{ marginTop: 33.5 }}
              onClick={this.handleSave}
              loading={this.state.loading}
            >
              Save
            </Button>
          </Col>
        </Row>
        <Form>
          <Spin spinning={this.state.spinning}>
            <Table
              columns={columns}
              dataSource={this.state.students}
              rowKey="id"
              className="marks-table"
              pagination={false}
            />
          </Spin>
        </Form>
      </Layout.Content>
    );
  }
}

export default Form.create()(MarksEditor);
