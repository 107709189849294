import axios from "axios";

var api_base_url = null;

function getToken() {
  var token =
    window.localStorage.getItem("token") ||
    window.sessionStorage.getItem("token");
  if (!token) {
    return false;
  }
  return token;
}

function getBaseUrl() {
  var url =
    window.localStorage.getItem("api_base_url") ||
    window.sessionStorage.getItem("api_base_url");
  api_base_url = url;
  if (!url) {
    return null;
  }
  return url;
}

var api = axios.create({
  baseURL: getBaseUrl(),
  headers: {
    Authorization: getToken(),
    "Content-Type": "application/json"
  }
});

export { api, api_base_url as baseurl, getToken };
