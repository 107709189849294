import React, { Component } from "react";
import { Layout, Collapse } from "antd";

class FAQs extends Component {
  state = {};
  render() {
    const { Panel } = Collapse;
    return (
      <Layout.Content>
        <h1>Eduga FAQs</h1>
        <Collapse accordion>
          <Panel header="Question 1" key={1}>
            <p>This is answer to question one.</p>
          </Panel>
        </Collapse>
      </Layout.Content>
    );
  }
}

export default FAQs;
