import React, { Component } from "react";
import {
  Layout,
  message,
  Spin,
  Input,
  Radio,
  Button,
  Result,
  Card,
} from "antd";
import { Link } from "react-router-dom";
import { api, baseurl } from "../../../config";
import TermsOfService from "./TermsOfService";
import { connect } from "react-redux";

const styles = {
  payButton: {
    width: "100%",
  },
};

class PayInstallment extends Component {
  state = {
    fee_id: null,
    fees: {},
    spinning: true,
    payment: { amount_paid: 0, mode: undefined },
    loading: false,
    settings: {},
    txn_status: {},
    payment_data: {},
    show_tos_modal: false,
    easebuzz_loaded: false,
  };

  componentDidMount() {
    this.get_fee_settings();
    if (this.props.match.params.id) {
      this.get_fees_details(this.props.match.params.id);
      this.setState({ fee_id: this.props.match.params.id });
    }
  }

  load_easebuzz_gateway = () => {
    const script = document.createElement("script");
    script.src =
      "https://ebz-static.s3.ap-south-1.amazonaws.com/easecheckout/easebuzz-checkout.js";
    script.async = true;
    script.onload = () => {
      this.setState({ easebuzz_loaded: true });
    };
    document.body.appendChild(script);
  };

  get_fee_settings = () => {
    api
      .get("/settings/fees/")
      .then((res) => {
        if (res.data.success) {
          let data = res.data.data;
          if (
            !(
              data.online_fee_payment_enabled &&
              data.online_fee_payment_enabled === "1"
            )
          ) {
            message.warning("Online fee payment not enabled", 5);
          }
          this.setState({ settings: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  get_fees_details = (fee_id) => {
    api
      .get("/fees/receipt/" + fee_id + "/")
      .then((res) => {
        if (res.data && res.data.success) {
          this.setState({ fees: res.data.data, spinning: false });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handlePay = () => {
    const { amount_paid, mode } = this.state.payment;
    const { payment } = this.state.fees;
    if (!(amount_paid && mode)) {
      if (!amount_paid || amount_paid === 0) {
        message.error("Amount must be entered");
      } else if (!mode) {
        message.error("Please select payment mode");
      }
      return;
    }
    if (amount_paid > payment.total_amount - payment.paid_amount) {
      message.error("Amount Paying cannot be more than Amount Pending");
      return;
    }
    const fee_id = this.state.fee_id;
    const data = { amount: amount_paid, payment_mode: mode, fee_id };
    this.setState({ loading: true });
    api
      .put("/fees/online/pay/", JSON.stringify(data))
      .then((res) => {
        if (res.data && res.data.success) {
          this.setState({ payment_data: res.data.data });
          switch (res.data.data.payment_mode) {
            case "paytm":
              this.initiate_paytm_payment(res.data.data);
              break;
            case "razorpay":
              this.initiate_razorpay_payment(res.data.data);
              break;
            case "easebuzz":
              this.initiate_easebuzz_payment(res.data.data);
              break;
            default:
              message.error("Payment gateway not found");
          }
        } else {
          message.error(res.data.error);
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  initiate_easebuzz_payment = (data) => {
    const script = document.createElement("script");
    script.src =
      "https://ebz-static.s3.ap-south-1.amazonaws.com/easecheckout/easebuzz-checkout.js";
    script.async = true;
    script.onload = () => {
      var easebuzzCheckout = new window.EasebuzzCheckout(
        data.key,
        data.environment
      );
      let options = {
        access_key: data.access_key,
        onResponse: (response) => {
          console.log(response);
          let formData = {
            id: data.id,
            fee_id: this.state.fee_id,
            mode: "easebuzz",
            order_id: response.txnid,
            result: response,
          };
          api
            .post("/fees/online/pay/", JSON.stringify(formData))
            .then((res) => {
              if (res.data.success) {
                this.setState({
                  txn_status: {
                    STATUS: "TXN_SUCCESS",
                    ORDERID: data.order_id,
                    TXNAMOUNT: data.amount,
                  },
                });
              } else {
                message.error(res.data.error);
                this.setState({
                  txn_status: {
                    STATUS: "TXN_FAILED",
                    ORDERID: data.order_id,
                    TXNAMOUNT: data.amount,
                  },
                });
              }
              this.setState({ loading: false });
            })
            .catch((err) => {
              console.log(err);
              this.setState({ loading: false });
            });
        },
      };
      easebuzzCheckout.initiatePayment(options);
    };
    document.body.appendChild(script);
  };

  initiate_razorpay_payment = (data) => {
    let school = this.props.about_school;
    let options = {
      key: data.razorpay_key_id,
      amount: data.total_amount,
      currency: "INR",
      name: school.school_name,
      description: `Student fee payment (#${data.fee_id})`,
      image: baseurl + "/" + school.school_logo,
      order_id: data.order_id,
      handler: (response) => {
        console.debug(response);
        let formData = {
          id: data.id,
          fee_id: this.state.fee_id,
          mode: "razorpay",
          order_id: data.order_id,
          result: response,
        };
        api
          .post("/fees/online/pay/", JSON.stringify(formData))
          .then((res) => {
            if (res.data.success) {
              this.setState({
                txn_status: {
                  STATUS: "TXN_SUCCESS",
                  ORDERID: data.order_id,
                  TXNAMOUNT: data.amount,
                },
              });
            } else {
              message.error(res.data.error);
              this.setState({
                txn_status: {
                  STATUS: "TXN_FAILED",
                  ORDERID: data.order_id,
                  TXNAMOUNT: data.amount,
                },
              });
            }
            this.setState({ loading: false });
          })
          .catch((err) => {
            console.log(err);
            this.setState({ loading: false });
          });
      },
      prefill: {
        name: data.student_name,
        email: data.email,
        contact: data.mobile,
      },
      modal: {
        onDismiss: () => {
          message.error("Fee payment cancelled by user!");
          this.setState({ loading: false });
        },
      },
    };
    let rzpay = new window.Razorpay(options);
    rzpay.open();
  };

  initiate_paytm_payment = (data) => {
    let config = {
      root: "",
      flow: "DEFAULT",
      data: data.data,
      merchant: {
        redirect: false,
      },
      handler: {
        notifyMerchant: this.notify_merchant,
        transactionStatus: this.transaction_status,
      },
      payMode: {
        order: ["UPI", "CARD", "BALANCE"],
      },
    };

    let that = this;

    if (window.Paytm && window.Paytm.CheckoutJS) {
      window.Paytm.CheckoutJS.init(config)
        .then(function onSuccess() {
          // after successfully updating configuration, invoke Blink Checkout
          window.Paytm.CheckoutJS.invoke();
        })
        .catch(function onError(error) {
          console.log("error => ", error);
          that.setState({ loading: false });
        });
    }
  };

  notify_merchant = (eventName, data) => {
    if (eventName === "APP_CLOSED") {
      message.info(data.message);
      this.setState({ loading: false });
    }
  };

  transaction_status = (data) => {
    this.setState({ loading: false, txn_status: data });
    window.Paytm.CheckoutJS.close();
    let formData = {
      fee_id: this.state.fee_id,
      order_id: this.state.payment_data.id,
      mode: this.state.payment.mode,
      result: data,
    };
    api
      .post("/fees/online/pay/", JSON.stringify(formData))
      .then((res) => {
        if (res.data.success) {
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  closeModal = () => {
    this.setState({ show_tos_modal: false });
  };

  render() {
    const { payment, installments, student } = this.state.fees;
    const { settings, txn_status, show_tos_modal } = this.state;
    return (
      <Layout.Content>
        <h1 className="align-center">Pay Fees Installment</h1>
        <Spin spinning={this.state.spinning}>
          {!this.state.spinning && (
            <div className="installment-table">
              {!txn_status.STATUS ? (
                <table>
                  <tbody>
                    <tr>
                      <td>Student Name : </td>
                      <td>{student.fullname}</td>
                    </tr>
                    <tr>
                      <td>Student Admission No. : </td>
                      <td>{student.admission_no}</td>
                    </tr>
                    <tr>
                      <td>Total Fees Amount : </td>
                      <td>₹ {payment.total_amount}</td>
                    </tr>
                    <tr>
                      <td>Amount Paid : </td>
                      <td>₹ {payment.paid_amount}</td>
                    </tr>
                    {installments.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            Installment {index + 1}{" "}
                            {item.paid_amount < 0 ? "(Refund) " : " "} :
                          </td>
                          <td>₹ {item.paid_amount}</td>
                        </tr>
                      );
                    })}
                    <tr>
                      <td>
                        <b>Amount Pending : </b>
                      </td>
                      <td>
                        <b>₹ {payment.total_amount - payment.paid_amount}</b>
                      </td>
                    </tr>
                    <tr>
                      <td>Installment Amount : </td>
                      <td>
                        <Input
                          type="number"
                          placeholder="Amount Paying"
                          onChange={(e) => {
                            let payment = this.state.payment;
                            payment.amount_paid = parseInt(e.target.value, 10);
                            this.setState({ payment });
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Payment Mode : </td>
                      <td>
                        {settings.payment_gateway_list && (
                          <Radio.Group
                            defaultValue="cash"
                            onChange={(e) => {
                              let payment = this.state.payment;
                              payment.mode = e.target.value;
                              this.setState({ payment });
                            }}
                            disabled={
                              settings.online_fee_payment_enabled === "0"
                            }
                          >
                            {settings.payment_gateway_list.map(
                              (item, index) => {
                                return (
                                  <Radio
                                    value={item.value}
                                    key={index}
                                    disabled={!item.enabled}
                                  >
                                    {item.name}
                                  </Radio>
                                );
                              }
                            )}
                          </Radio.Group>
                        )}
                      </td>
                    </tr>
                    {this.state.payment.mode === "razorpay" && (
                      <tr>
                        <td></td>
                        <td>
                          Note :{" "}
                          {settings.razorpay_gateway_details &&
                            settings.razorpay_gateway_details.tdr_rate}
                          % + taxes will be charged extra.
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td />
                      <td>
                        <Button
                          type="primary"
                          style={styles.payButton}
                          onClick={this.handlePay}
                          loading={this.state.loading}
                        >
                          Pay
                        </Button>
                        {this.state.payment.mode === "paytm" ? (
                          <p style={{ marginTop: 10, marginBottom: 10 }}>
                            By clicking pay you agree to the following{" "}
                            <span
                              className="link"
                              onClick={() => {
                                this.setState({ show_tos_modal: true });
                              }}
                            >
                              Terms of Service
                            </span>
                          </p>
                        ) : (
                          ""
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              ) : (
                <div>
                  {txn_status.STATUS === "TXN_SUCCESS" ? (
                    <Card>
                      <Result
                        status="success"
                        title={`Fee Installment Paid`}
                        subTitle={`Order id ${txn_status.ORDERID} completed, Paid ₹${txn_status.TXNAMOUNT}`}
                        extra={[
                          <Link to="/fees/">
                            <Button type="primary" key="back">
                              Go Back
                            </Button>
                          </Link>,
                        ]}
                      />
                    </Card>
                  ) : (
                    <Card>
                      <Result
                        status="error"
                        title="Payment Failed"
                        subTitle={`Order id ${txn_status.ORDERID} status is ${txn_status.STATUS}, Order amount is ₹${txn_status.TXNAMOUNT}`}
                        extra={[
                          <Link to="/fees/">
                            <Button type="primary" key="back">
                              Go Back
                            </Button>
                          </Link>,
                        ]}
                      />
                    </Card>
                  )}
                </div>
              )}
            </div>
          )}
        </Spin>
        {show_tos_modal && <TermsOfService closeModal={this.closeModal} />}
      </Layout.Content>
    );
  }
}

function mapStateToProps(state) {
  return {
    about_school: state.about_school,
  };
}

export default connect(mapStateToProps, null)(PayInstallment);
