import React from "react";
import { Switch, Route } from "react-router-dom";
import {
  TestsList,
  TestsForm,
  TestsDetailsList,
  TestDetailsAddForm,
  TestDetailsEditForm
} from "../../admin/academics/tests";

const TeacherTestsRouter = props => (
  <Switch>
    <Route exact path="/academics/tests/" component={TestsList} />
    <Route exact path="/academics/tests/new" component={TestsForm} />
    <Route exact path="/academics/tests/:id" component={TestsForm} />
    <Route exact path="/academics/test/list/:id" component={TestsDetailsList} />
    <Route
      exact
      path="/academics/test/details/:testid"
      component={TestDetailsAddForm}
    />
    <Route
      exact
      path="/academics/test/details/edit/:id"
      component={TestDetailsEditForm}
    />
  </Switch>
);

export { TeacherTestsRouter };
