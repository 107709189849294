import { Form, Input, message, Modal } from "antd";
import React, { Component } from "react";
import { api } from "../../../../config";

class StudentUsernameReset extends Component {
  state = {
    username: undefined,
    loading: false,
  };

  handleSubmit = () => {
    let { username } = this.state;
    if (!username || username.length === 0) {
      message.error("Username can't be empty");
      return;
    }
    let data = {
      username,
      student_id: this.props.student.id,
    };
    this.setState({ loading: true });
    api
      .post(`/user/update/username/?type=username`, JSON.stringify(data))
      .then((res) => {
        if (res.data.success) {
          message.success("Username updated");
          this.props.closeModal();
        } else {
          message.error(res.data.error);
        }
        this.setState({ loading: false });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  render() {
    const { username, loading } = this.state;
    return (
      <Modal
        visible={true}
        title="Change Username of Student"
        onCancel={this.props.closeModal.bind(this)}
        onOk={this.handleSubmit}
        confirmLoading={loading}
      >
        <Form.Item label="New username">
          <Input
            value={username}
            onChange={(e) => this.setState({ username: e.target.value })}
          />
        </Form.Item>
      </Modal>
    );
  }
}

export default StudentUsernameReset;
