import React from "react";
import { Switch, Route } from "react-router-dom";
import HomeworkList from "../../teacher/homework/HomeworkList";
import HomeworkForm from "../../teacher/homework/HomeworkForm";
import HomeworkViewer from "../../teacher/homework/HomeworkViewer";
import HomeworkDetails from "../../teacher/homework/HomeworkDetails";

const AdminHomeworkRouter = (props) => (
  <Switch>
    <Route exact path="/homework/" component={HomeworkList} />
    <Route exact path="/homework/new/" component={HomeworkForm} />
    <Route exact path="/homework/:id/" component={HomeworkViewer} />
    <Route exact path="/homework/:id/solutions" component={HomeworkDetails} />
  </Switch>
);

export { AdminHomeworkRouter };
