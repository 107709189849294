import { Layout, Table, Icon, message, Row, Col, Button } from "antd";
import React, { Component } from "react";
import { Currency } from "../../../components";
import moment from "moment";
import { api } from "../../../config";
import { Link } from "react-router-dom";
import BuyCredits from "./BuyCredits";

class BillingList extends Component {
  state = {
    bills: [],
    buy_modal: false,
    buy: undefined,
  };

  componentDidMount() {
    this.__get_billing_list();
  }

  __get_billing_list = () => {
    api
      .get(`/eduga/billing/`)
      .then((res) => {
        if (res.data.success) {
          this.setState({ bills: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  __close_modal = (refresh = false) => {
    this.setState({ buy_modal: false, buy: undefined });
    if (refresh) {
      this.__get_billing_list();
    }
  };

  render() {
    const { bills, buy_modal, buy } = this.state;
    const columns = [
      { title: "Sl. No.", render: (v, r, i) => <span>{i + 1}</span> },
      {
        title: "Name",
        dataIndex: "name",
        render: (v, r) => <Link to={`/billing/${r.id}/`}>{v}</Link>,
      },
      {
        title: "Amount",
        dataIndex: "amount",
        align: "right",
        render: (v) => <Currency value={v} />,
      },
      {
        title: "Paid",
        dataIndex: "paid",
        align: "right",
        render: (v) => <Currency value={v} />,
      },
      {
        title: "Discount",
        dataIndex: "discount",
        align: "right",
        render: (v) => <Currency value={v} />,
      },
      {
        title: "Date",
        dataIndex: "timestamp",
        render: (v) => (
          <span>{moment.utc(v).local().format("YYYY-MM-DD")}</span>
        ),
      },
      {
        title: "Due Date",
        dataIndex: "due_date",
        render: (v) => (
          <span>{moment.utc(v).local().format("YYYY-MM-DD")}</span>
        ),
      },
      {
        title: "Actions",
        render: (r) => (
          <span>
            <Icon type="dollar" title="Pay Bill" className="action-icon" />
          </span>
        ),
      },
    ];
    return (
      <Layout.Content>
        <Row gutter={16}>
          <Col sm={12}>
            <h1>Eduga Billing</h1>
          </Col>
          <Col sm={12} className="action-buttons">
            <Button
              type="primary"
              ghost
              style={{ marginRight: 8 }}
              onClick={() => this.setState({ buy_modal: true, buy: "SMS" })}
            >
              Buy SMS Credit
            </Button>
            <Button
              type="primary"
              ghost
              onClick={() => this.setState({ buy_modal: true, buy: "Storage" })}
            >
              Buy Storage
            </Button>
          </Col>
        </Row>
        <Table
          columns={columns}
          dataSource={bills}
          expandedRowRender={(r, i) => <span>{r.description}</span>}
          rowKey="id"
        />
        {buy_modal && <BuyCredits buy={buy} close={this.__close_modal} />}
      </Layout.Content>
    );
  }
}

export default BillingList;
