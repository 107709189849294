import React, { Component } from "react";
import { Button, Col, Layout, message, Row, Table } from "antd";
import { api } from "../../../config";
import moment from "moment";
import { Link } from "react-router-dom";

class VisitorsList extends Component {
  state = {
    visitors: [],
    spinning: false,
  };

  componentDidMount() {
    this.get_visitors_list();
  }

  get_visitors_list = () => {
    api
      .get("/visitors/")
      .then((res) => {
        if (res.data.success) {
          this.setState({ visitors: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  export_visitors_list = () => {
    let { visitors } = this.state;
    var csvContent = "data:text/csv;charset=utf-8,";
    let header =
      "ID,Visitor Name,Mobile,Student Name,Address,Reason Visiting,Person Visiting,Entry Timestamp,Exit Timestamp\r\n";
    csvContent += header;
    visitors.forEach((item) => {
      let line = `${item.id},${item.name},${item.mobile},${item.student_name},${item.address},"${item.reason_visiting}","${item.person_visiting}",${item.entry_timestamp},${item.exit_timestamp}\r\n`;
      csvContent += line;
    });
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "visitors_list.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  render() {
    const columns = [
      { title: "ID", dataIndex: "id" },
      {
        title: "Name",
        dataIndex: "name",
        render: (v, r) => (
          <Link to={{ pathname: "/visitors/info", state: r }}>
            <span>{v}</span>
          </Link>
        ),
      },
      { title: "Mobile", dataIndex: "mobile" },
      {
        title: "Entry",
        dataIndex: "entry_timestamp",
        render: (v) => (
          <span>{moment.utc(v).local().format("DD MMM, YYYY hh:mm A")}</span>
        ),
      },
      {
        title: "Exit",
        dataIndex: "exit_timestamp",
        render: (v) => (
          <span>
            {v ? moment.utc(v).local().format("DD MMM, YYYY hh:mm A") : " - "}
          </span>
        ),
      },
    ];
    return (
      <Layout.Content>
        <Row>
          <Col md={12}>
            <h1>Visitors List</h1>
          </Col>
          <Col md={12} className="action-buttons">
            <Button
              icon="export"
              type="primary"
              onClick={this.export_visitors_list}
              style={{ marginRight: 8 }}
            >
              CSV
            </Button>
            <Button
              icon="plus"
              type="primary"
              className="action-button"
              onClick={() => this.props.history.push("/visitors/new/")}
            >
              New Visitor
            </Button>
          </Col>
        </Row>
        <Table
          columns={columns}
          dataSource={this.state.visitors}
          rowKey="id"
          loading={this.state.spinning}
        />
      </Layout.Content>
    );
  }
}

export default VisitorsList;
