import React from "react";
import { Switch, Route } from "react-router-dom";
import ClassRoomList from "../../teacher/virtual_classroom/ClassRoomList";
import VirtualClassRoom from "./VirtualClassRoom";
import VCRoomQuestions from "./VCRoomQuestions";
import VCRoomQuestion from "./VCRoomQuestion";
import JitsiClassroom from "./JitsiClassroom";

const StudentVirtualClassroomRouter = (props) => (
  <Switch>
    <Route exact path="/virtual-classroom/" component={ClassRoomList} />
    <Route exact path="/virtual-classroom/:id" component={VirtualClassRoom} />
    <Route
      exact
      path="/virtual-classroom/:id/jitsi"
      component={JitsiClassroom}
    />
    <Route
      exact
      path="/virtual-classroom/:vc_class_id/questions/"
      component={VCRoomQuestions}
    />
    <Route
      exact
      path="/virtual-classroom/:vc_class_id/question/:id"
      component={VCRoomQuestion}
    />
  </Switch>
);

export { StudentVirtualClassroomRouter };
