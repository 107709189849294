import React from "react";
import { Switch, Route } from "react-router-dom";
import OnlineExams from "./OnlineExams";
import OnlineExamDetails from "./OnlineExamDetails";

const StudentExamRouter = (props) => (
  <Switch>
    <Route exact path="/exam/" component={OnlineExams} />
    <Route exact path="/exam/details/:id" component={OnlineExamDetails} />
  </Switch>
);

export { StudentExamRouter };
