import React, { Component } from "react";
import {
  Layout,
  Row,
  Col,
  Button,
  message,
  Table,
  Icon,
  Popconfirm,
} from "antd";
import { api } from "../../../config";
import AssignOnlineTest from "./AssignOnlineTest";

class OnlineTestQuestions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questions: [],
      class_id: undefined,
      subject_id: undefined,
      topic: undefined,
      test_id: undefined,
      assign_test: false,
    };
  }

  componentDidMount() {
    if (this.props.match.params.test_id) {
      this.setState(
        { test_id: this.props.match.params.test_id },
        this.get_test_questions
      );
    }
  }

  get_test_questions = () => {
    let { test_id } = this.state;
    api
      .get(`/ot/test/${test_id}/questions/`)
      .then((res) => {
        if (res.data.success) {
          this.setState({ questions: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  closeModal = (submitted) => {
    if (submitted) {
      let { test_id } = this.state;
      this.props.history.push(`/online-test/${test_id}/assign/`);
    }
    this.setState({ assign_test: false });
  };

  render() {
    const { test_id, questions, assign_test } = this.state;
    const columns = [
      { title: "ID", dataIndex: "id" },
      { title: "Question", dataIndex: "question" },
      { title: "Topic", dataIndex: "topic" },
      {
        title: "Question Type",
        dataIndex: "type",
        render: (v) => {
          switch (v) {
            case 0:
              return <span>MCQ Single Correct</span>;
            case 1:
              return <span>MCQ Multiple Correct</span>;
            case 2:
              return <span>Subjective</span>;
            case 3:
              return <span>Media Upload</span>;
            default:
              return <span>-</span>;
          }
        },
      },
      { title: "Max Marks", dataIndex: "correct_marks" },
      {
        title: "Actions",
        render: (r) => (
          <span>
            <Popconfirm title="Are you sure?">
              <Icon
                type="delete"
                className="action-icon absent"
                title="Delete question from test"
              />
            </Popconfirm>
          </span>
        ),
      },
    ];
    return (
      <Layout.Content>
        <Row>
          <Col sm={12}>
            <h1>Test Questions</h1>
          </Col>
          <Col sm={12} className="action-buttons">
            <Button
              type="primary"
              icon="plus"
              onClick={() =>
                this.props.history.push(`/online-test/${test_id}/questions/add`)
              }
              style={{ marginRight: 10 }}
            >
              Add Questions
            </Button>
            <Button
              type="primary"
              icon="play-circle"
              onClick={() => this.setState({ assign_test: true })}
            >
              Assign Test to Class
            </Button>
          </Col>
        </Row>
        <Table columns={columns} dataSource={questions} rowKey="id" />
        {assign_test && (
          <AssignOnlineTest closeModal={this.closeModal} test_id={test_id} />
        )}
      </Layout.Content>
    );
  }
}

export default OnlineTestQuestions;
