import React, { Component } from "react";
import { Card, Radio, Empty } from "antd";
import { Line } from "react-chartjs-2";
import moment from "moment";

class FeeCollections extends Component {
  state = {
    month_data: {},
    day_data: {},
    fees_data: {},
    type: null,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.fees_data.months) {
      return { fees_data: nextProps.fees_data };
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.fees_data.months && this.state.type === null) {
      this.prepare_fees_data(this.state.fees_data.months, "month");
      this.prepare_fees_data(this.state.fees_data.days, "day");
      this.setState({ type: "month" });
    }
  }

  prepare_fees_data = (fees_data, type) => {
    let labels = [],
      datasets = [],
      total_amount = [],
      paid_amount = [],
      discount_amount = [];

    fees_data.forEach((item) => {
      let date = null;
      if (type === "month") {
        date = moment(item.date, "YYYY-MM").format("MMM, YY");
      } else if (type === "day") {
        date = moment(item.date, "YYYY-MM-DD").format("DD MMM, YY");
      }
      labels.push(date);
      total_amount.push(item.total_amount);
      paid_amount.push(item.paid_amount);
      discount_amount.push(item.discount_amount);
    });

    datasets.push({
      label: "Total Fees Collection",
      backgroundColor: "rgba(0, 0, 0, 0)",
      data: total_amount,
      borderColor: "rgba(127, 59, 8, 0.6)",
    });
    datasets.push({
      label: "Total Amount Received",
      backgroundColor: "rgba(0, 0, 0, 0)",
      data: paid_amount,
      borderColor: "rgba(1, 102, 94, 0.6)",
    });
    datasets.push({
      label: "Discount Amount",
      backgroundColor: "rgba(0, 0, 0, 0)",
      data: discount_amount,
      borderColor: "rgba(84, 39, 136, 0.6)",
    });

    if (type === "day") {
      this.setState({
        day_data: { labels, datasets },
      });
    } else if (type === "month") {
      this.setState({
        month_data: { labels, datasets },
      });
    }
  };

  render() {
    const { type, month_data, day_data } = this.state;
    return (
      <Card title="Fees Collection" className="card-elevation">
        {type !== null ? (
          <>
            <div style={{ position: "absolute", top: 16, right: 16 }}>
              <Radio.Group
                onChange={(e) => {
                  this.setState({ type: e.target.value });
                }}
                defaultValue={type}
                size="small"
              >
                <Radio.Button value="month">Month</Radio.Button>
                <Radio.Button value="day">Day</Radio.Button>
              </Radio.Group>
            </div>
            <Line
              data={type === "month" ? month_data : day_data}
              options={{
                elements: { line: { tension: 0.2 } },
                maintainAspectRatio: false,
              }}
              height={300}
              redraw={true}
            />
          </>
        ) : (
          <Empty />
        )}
      </Card>
    );
  }
}

export default FeeCollections;
