import React, { Component } from "react";
import { Layout, message, Spin, Input, Radio, Button, DatePicker } from "antd";
import { api } from "../../../../config";
import { AccountsDropdown } from "../../../../components";
import moment from "moment";

const styles = {
  payButton: {
    width: "100%",
    backgroundColor: "#882222",
    borderColor: "#882222",
  },
};

class PayFeesInstallment extends Component {
  state = {
    fee_id: null,
    fees: {},
    spinning: true,
    payment: {
      amount_paid: 0,
      mode: "cash",
      account: undefined,
      date: moment(),
    },
    loading: false,
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      this.get_fees_details(this.props.match.params.id);
      this.setState({ fee_id: this.props.match.params.id });
    }
  }

  get_fees_details = (fee_id) => {
    api
      .get("/fees/receipt/" + fee_id + "/")
      .then((res) => {
        if (res.data && res.data.success) {
          this.setState({ fees: res.data.data, spinning: false });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handlePay = () => {
    const { amount_paid, mode, account, date } = this.state.payment;
    const { payment } = this.state.fees;
    if (!(amount_paid && mode)) {
      if (!amount_paid || amount_paid === 0) {
        message.error("Amount must be entered");
      } else if (!mode) {
        message.error("Please select payment mode");
      }
      return;
    }
    if (amount_paid > payment.total_amount - payment.paid_amount) {
      message.error("Amount Paying cannot be more than Amount Pending");
      return;
    }
    const fee_id = this.state.fee_id;
    const data = { amount: amount_paid, mode: mode, account, date };
    this.setState({ loading: true });
    api
      .put("/fees/pay/installment/" + fee_id + "/", JSON.stringify(data))
      .then((res) => {
        if (res.data && res.data.success) {
          message.success("Fee Installment Paid");
          this.props.history.push("/fees/receipt/" + payment.id + "/");
        } else {
          message.error(res.data.error);
        }
        this.setState({ loading: false });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  render() {
    const { payment, installments, student } = this.state.fees;
    return (
      <Layout.Content>
        <h1>Pay Fees Installment</h1>
        <Spin spinning={this.state.spinning}>
          {!this.state.spinning && (
            <div className="installment-table">
              <table>
                <tbody>
                  <tr>
                    <td>Student Name : </td>
                    <td>{student.fullname}</td>
                  </tr>
                  <tr>
                    <td>Student Admission No. : </td>
                    <td>{student.admission_no}</td>
                  </tr>
                  <tr>
                    <td>Total Fees Amount : </td>
                    <td>₹ {payment.total_amount}</td>
                  </tr>
                  <tr>
                    <td>Amount Paid : </td>
                    <td>₹ {payment.paid_amount}</td>
                  </tr>
                  {installments.map((item, index) => {
                    return (
                      <tr>
                        <td>Installment {index + 1} : </td>
                        <td>₹ {item.paid_amount}</td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td>
                      <b>Amount Pending : </b>
                    </td>
                    <td>
                      <b>₹ {payment.total_amount - payment.paid_amount}</b>
                    </td>
                  </tr>
                  <tr>
                    <td>Installment Amount : </td>
                    <td>
                      <Input
                        type="number"
                        placeholder="Amount Paying"
                        onChange={(e) => {
                          let payment = this.state.payment;
                          payment.amount_paid = parseInt(e.target.value, 10);
                          this.setState({ payment });
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Payment Date</td>
                    <td>
                      <DatePicker
                        value={this.state.payment.date}
                        onChange={(val) => {
                          let payment = this.state.payment;
                          payment.date = val;
                          this.setState({ payment });
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Credit to account : </td>
                    <td>
                      <AccountsDropdown
                        style={{ width: "100%" }}
                        value={this.state.payment.account}
                        onChange={(val) => {
                          let payment = this.state.payment;
                          payment.account = val;
                          this.setState({ payment });
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Payment Mode : </td>
                    <td>
                      <Radio.Group
                        defaultValue="cash"
                        onChange={(e) => {
                          let payment = this.state.payment;
                          payment.mode = e.target.value;
                          this.setState({ payment });
                        }}
                      >
                        <Radio value="cash">Cash</Radio>
                        <Radio value="card">Card</Radio>
                        <Radio value="wallet">Wallet/UPI</Radio>
                        <Radio value="transfer">Transfer</Radio>
                      </Radio.Group>
                    </td>
                  </tr>
                  <tr>
                    <td />
                    <td>
                      <Button
                        type="primary"
                        style={styles.payButton}
                        onClick={this.handlePay}
                        loading={this.state.loading}
                      >
                        Pay
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </Spin>
      </Layout.Content>
    );
  }
}

export default PayFeesInstallment;
