import React from "react";
import { Switch, Route } from "react-router-dom";
import AddBook from "./AddBook";
import BooksList from "./BooksList";
import BookIssueHistory from "./BookIssueHistory";
import StudentBooksHistory from "./StudentBooksHistory";

const AdminLibraryRouter = props => (
  <Switch>
    <Route exact path="/library/books/" component={BooksList} />
    <Route exact path="/library/book/new" component={AddBook} />
    <Route exact path="/library/book/:id" component={AddBook} />
    <Route
      exact
      path="/library/book/history/:id"
      component={BookIssueHistory}
    />
    <Route exact path="/library/issued/" component={StudentBooksHistory} />
  </Switch>
);

export { AdminLibraryRouter };
