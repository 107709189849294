import React, { useEffect } from "react";
import { connect } from "react-redux";

function PrintComponent(props) {
  var pp = null;

  useEffect(() => {
    const beforeprint = (e) => {
      document.getElementById("root").style.display = "none";
      pp = document.getElementById("print-page");
      document.body.appendChild(pp);
    };
    window.addEventListener("beforeprint", beforeprint);
    return () => {
      window.removeEventListener("beforeprint", beforeprint);
    };
  }, []);

  useEffect(() => {
    const afterprint = (e) => {
      if (pp) {
        document.body.removeChild(pp);
        document.getElementById("print-parent").appendChild(pp);
      }
      document.getElementById("root").style.display = "block";
    };
    window.addEventListener("afterprint", afterprint);
    return () => {
      window.removeEventListener("afterprint", afterprint);
    };
  }, []);

  return (
    <div id="print-parent">
      <div>{props.children}</div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    about_school: state.about_school,
  };
}

export default connect(mapStateToProps, null)(PrintComponent);
