import React, { Component } from "react";
import { Select } from "antd";
import { api } from "../config";

class ExamSelectorDropdown extends Component {
  state = {
    exams: [],
  };

  componentDidMount() {
    this.get_exams_list();
  }

  get_exams_list = () => {
    api
      .get("/exams/")
      .then((res) => {
        if (res.data.success) {
          this.setState({ exams: res.data.data });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const { Option } = Select;
    const style = { width: 160 };
    return (
      <Select
        placeholder="Exams"
        showSearch
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        onChange={this.props.onChange}
        value={this.props.value}
        style={this.props.style || style}
        allowClear={this.props.allowClear || true}
      >
        {this.state.exams.map((item, index) => {
          return (
            <Option value={item.id} key={index}>
              {item.name}
            </Option>
          );
        })}
      </Select>
    );
  }
}

export default ExamSelectorDropdown;
