import { Button, Col, DatePicker, Layout, message, Row, Table } from "antd";
import React, { Component } from "react";
import moment from "moment";
import { Currency, PrintTable } from "../../../../components";
import { api } from "../../../../config";

class AccountEntries extends Component {
  state = {
    id: undefined,
    date: [moment().startOf("month"), moment()],
    transactions: [],
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      this.setState(
        { id: this.props.match.params.id },
        this._get_account_transactions
      );
    }
  }

  _get_account_transactions = () => {
    const { id, date } = this.state;
    const [start_date, end_date] = date;
    let url = "";
    if (start_date && end_date) {
      url = `/finance/account/${id}/transactions/?start_date=${date[0].format(
        "YYYY-MM-DD"
      )}&end_date=${date[1].format("YYYY-MM-DD")}`;
    } else {
      url = `/finance/account/${id}/transactions/`;
    }
    api
      .get(url)
      .then((res) => {
        if (res.data.success) {
          this.setState({ transactions: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  exportAccountStatement = () => {
    let { transactions, id } = this.state;

    var csvContent = "data:text/csv;charset=utf-8,";
    let header = "ID,Name,Amount,Type,Date\r\n";
    csvContent += header;
    transactions.forEach((item) => {
      let line = `${item.id},${item.name},${item.amount},${
        item.txn_type === 0 ? "Credit" : "Debit"
      },${item.date}\r\n`;
      csvContent += line;
    });
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute(
      "download",
      `account_statement_${id}_${new Date().getTime()}.csv`
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  render() {
    const { date, transactions } = this.state;
    const columns = [
      {
        title: "Sl. No.",
        print: true,
        render: (v, r, i) => <span>{i + 1}</span>,
      },
      {
        title: "Date",
        dataIndex: "date",
        print: true,
        render: (v) => (
          <span>{moment.utc(v).local().format("YYYY-MM-DD")}</span>
        ),
      },
      { title: "Title", dataIndex: "name", print: true },
      {
        title: "Debit",
        align: "right",
        print: true,
        render: (r) => (
          <span>{r.txn_type === 1 ? <Currency value={r.amount} /> : ""}</span>
        ),
      },
      {
        title: "Credit",
        align: "right",
        print: true,
        render: (r) => (
          <span>{r.txn_type === 0 ? <Currency value={r.amount} /> : ""}</span>
        ),
      },
    ];
    return (
      <Layout.Content>
        <Row gutter={16}>
          <Col sm={12}>
            <h1>Account Entries</h1>
          </Col>
          <Col sm={12} className="action-buttons">
            <Button
              type="primary"
              ghost
              icon="export"
              onClick={this.exportAccountStatement}
            >
              CSV
            </Button>
            <Button
              icon="file-pdf"
              type="primary"
              onClick={() => window.print()}
              style={{ marginLeft: 8 }}
            >
              PDF
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <span>
              <strong>Date Range : </strong>
              <DatePicker.RangePicker
                value={date}
                onChange={(v) => this.setState({ date: v })}
              />
            </span>
            <Button
              type="primary"
              ghost
              style={{ marginLeft: 8 }}
              onClick={this._get_account_transactions}
            >
              View
            </Button>
          </Col>
        </Row>
        <Table columns={columns} dataSource={transactions} rowKey="id" />
        <PrintTable
          columns={columns}
          data={transactions}
          type="Account Transactions"
        />
      </Layout.Content>
    );
  }
}

export default AccountEntries;
