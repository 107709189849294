import React, { Component } from "react";
import { Layout, Form, Input, DatePicker, Button, message, Spin } from "antd";
import { api } from "../../../../config";
import { ShowBreadcrumbs } from "../../../../components";

var moment = require("moment");

class ACYearForm extends Component {
  state = {
    ac_year_id: null,
    loading: false,
    spinning: false
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      this.setState({ ac_year_id: this.props.match.params.id });
      this.get_acyear_item(this.props.match.params.id);
    }
  }

  get_acyear_item = id => {
    this.setState({ spinning: true });
    api
      .get("/acyear/" + id + "/")
      .then(res => {
        if (res.data && res.data.success) {
          let data = res.data.data;
          data.start_date =
            (data.start_date && moment(data.start_date)) || null;
          data.end_date = (data.end_date && moment(data.end_date)) || null;
          this.props.form.setFieldsValue(data);
        } else {
          message.error(res.data.error);
        }
        this.setState({ spinning: false });
      })
      .catch(err => {
        console.log(err);
        this.setState({ spinning: false });
      });
  };

  handleClick = () => {
    this.setState({ loading: true });
    this.props.form.validateFields((err, val) => {
      if (!err) {
        if (this.state.ac_year_id) {
          this.updateACYear(this.state.ac_year_id, val);
        } else {
          this.addNewACYear(val);
        }
      }
    });
  };

  addNewACYear = val => {
    // console.log(val);
    api
      .put("/acyears/", JSON.stringify(val))
      .then(res => {
        if (res.data && res.data.success) {
          message.success("New AC Year Added");
          this.props.history.push("/academics/acyears/");
        } else {
          message.error(res.data.error || "Something went wrong");
        }
        this.setState({ loading: false });
      })
      .catch(err => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  updateACYear = (id, val) => {
    // console.log(id, val);
    // message.info("Updating new academic year is disabled");
    api
      .post("/acyear/" + id + "/", JSON.stringify(val))
      .then(res => {
        if (res.data && res.data.success) {
          message.success("Academic Year Updated");
          this.props.history.push("/academics/acyears/");
        } else {
          message.error(res.data.error || "Something went wrong");
        }
        this.setState({ loading: false });
      })
      .catch(err => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  render() {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    const ac_year_id = this.state.ac_year_id;
    return (
      <Layout.Content>
        <ShowBreadcrumbs
          items={[
            { name: "Home", link: "/" },
            { name: "Academics", link: "/academics/" },
            { name: "Academic Years", link: "/academics/acyears/" }
          ]}
        />
        <h1 className="align-center">Academic Year Form</h1>
        <Spin spinning={this.state.spinning}>
          <Form labelCol={{ sm: 8 }} wrapperCol={{ sm: 8 }}>
            <FormItem label="Name">
              {getFieldDecorator("name", {
                rules: [{ required: true, message: "Name is required" }]
              })(<Input />)}
            </FormItem>
            <FormItem label="Syllabus">
              {getFieldDecorator("syllabus", {
                rules: [{ required: true, message: "Syllabus is required" }]
              })(<Input />)}
            </FormItem>
            <FormItem label="Start Date">
              {getFieldDecorator("start_date", {
                rules: [{ required: true, message: "Start Date is required" }]
              })(<DatePicker />)}
            </FormItem>
            <FormItem label="End Date">
              {getFieldDecorator("end_date", {
                rules: [{ required: true, message: "End Date is required" }]
              })(<DatePicker />)}
            </FormItem>
            <FormItem wrapperCol={{ offset: 8 }}>
              <Button
                type="primary"
                onClick={this.handleClick}
                loading={this.state.loading}
              >
                {ac_year_id ? "Update" : "Add"}
              </Button>
            </FormItem>
          </Form>
        </Spin>
      </Layout.Content>
    );
  }
}

export default Form.create()(ACYearForm);
