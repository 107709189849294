import React, { Component } from "react";
import {
  Layout,
  Row,
  Col,
  Button,
  Table,
  message,
  Icon,
  Popconfirm,
} from "antd";
import AddStudentModal from "./AddStudentModal";
import { api } from "../../../../config";

class StudentsList extends Component {
  state = {
    route_id: null,
    addModal: false,
    students: [],
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      this.get_students_list(this.props.match.params.id);
      this.setState({ route_id: this.props.match.params.id });
    }
  }

  get_students_list = (route_id) => {
    let url = "/simple/transportation/students/" + route_id + "/";
    api
      .get(url)
      .then((res) => {
        if (res.data.success) {
          this.setState({ students: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  closeModal = () => {
    this.setState({ addModal: false });
    this.get_students_list(this.state.route_id);
  };

  handleRemoveStudent = (id) => {
    api
      .delete("/simple/transportation/student/" + id + "/")
      .then((res) => {
        if (res.data.success) {
          message.success("Student removed from route");
          this.get_students_list(this.state.route_id);
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const columns = [
      {
        title: "Sl.",
        dataIndex: "id",
        render: (v, r, i) => <span>{i + 1}</span>,
      },
      { title: "Name", dataIndex: "student_name" },
      { title: "Stop", dataIndex: "stop_name" },
      {
        title: "Actions",
        render: (r) => (
          <span>
            <Icon
              type="dollar"
              title="Fees"
              className="action-icon"
              onClick={() =>
                this.props.history.push(`/fees/records/${r.student_id}/`)
              }
            />
            <Popconfirm
              title="Are you sure?"
              onConfirm={this.handleRemoveStudent.bind(this, r.id)}
            >
              <Icon type="delete" className="action-icon absent" />
            </Popconfirm>
          </span>
        ),
      },
    ];
    const { addModal } = this.state;
    return (
      <Layout.Content>
        <Row>
          <Col sm={12}>
            <h1>Students List</h1>
          </Col>
          <Col sm={12} style={{ textAlign: "right" }}>
            <Button
              icon="plus"
              type="primary"
              onClick={() => {
                this.setState({ addModal: true });
              }}
            >
              Add Student
            </Button>
          </Col>
        </Row>
        <Table columns={columns} dataSource={this.state.students} rowKey="id" />
        {addModal && (
          <AddStudentModal visible={addModal} closeModal={this.closeModal} />
        )}
      </Layout.Content>
    );
  }
}

export default StudentsList;
