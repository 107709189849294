import React, { Component } from "react";
import { Select } from "antd";
import { api } from "../config";

class StudentSearch extends Component {
  state = {
    students: [],
  };

  admission_no_search = (admission_no) => {
    if (!admission_no) {
      return;
    }
    api
      .get("/search/student/" + admission_no + "/")
      .then((res) => {
        if (res.data && res.data.success) {
          this.setState({ students: res.data.data });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleSearch = (val) => {
    this.admission_no_search(val);
  };

  render() {
    const Option = Select.Option;
    return (
      <Select
        showSearch
        onSearch={this.handleSearch}
        value={this.props.value}
        onChange={this.props.onChange}
        style={this.props.style}
        filterOption={(input, option) => {
          return true;
        }}
        allowClear
        {...this.props}
      >
        {this.state.students.map((item, index) => {
          return (
            <Option value={item.id} key={index}>
              {item.admission_no + " - " + item.fullname}
            </Option>
          );
        })}
      </Select>
    );
  }
}

export default StudentSearch;
