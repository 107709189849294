import React, { Component } from "react";
import { Layout } from "antd";

class SettingsScreen extends Component {
  state = {};
  render() {
    return (
      <Layout.Content>
        <h1>Settings</h1>
      </Layout.Content>
    );
  }
}

export default SettingsScreen;
