import React, { Component } from "react";
import {
  Layout,
  Row,
  Col,
  Button,
  Table,
  message,
  Icon,
  Popconfirm
} from "antd";
import { Link } from "react-router-dom";
import { api } from "../../../../config";

class BusStopsList extends Component {
  state = {
    stops: []
  };

  componentDidMount() {
    this.get_bus_stops_list();
  }

  get_bus_stops_list = () => {
    api
      .get("/bus/stops/")
      .then(res => {
        if (res.data && res.data.success) {
          this.setState({ stops: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  render() {
    const columns = [
      { title: "ID", dataIndex: "id" },
      { title: "Name", dataIndex: "name" },
      { title: "Latitude", dataIndex: "lat" },
      { title: "Longitude", dataIndex: "lon" },
      { title: "Address", dataIndex: "extras.address" },
      { title: "Landmark", dataIndex: "extras.landmark" },
      {
        title: "Actions",
        render: records => (
          <span>
            <Icon
              type="edit"
              className="action-icon"
              onClick={() => {
                this.props.history.push(
                  "/transportation/stop/" + records.id + "/"
                );
              }}
            />
            <Popconfirm title="Are you sure?">
              <Icon type="delete" className="action-icon absent" />
            </Popconfirm>
          </span>
        )
      }
    ];
    return (
      <Layout.Content>
        <Row>
          <Col sm={12}>
            <h1>Bus Stops</h1>
          </Col>
          <Col sm={12} style={{ textAlign: "right" }}>
            <Link to="/transportation/stops/new">
              <Button icon="plus" type="primary">
                Add Bus Stop
              </Button>
            </Link>
          </Col>
        </Row>
        <Table columns={columns} dataSource={this.state.stops} rowKey="id" />
      </Layout.Content>
    );
  }
}

export default BusStopsList;
