import React, { Component } from "react";
import {
  Layout,
  Form,
  Input,
  Row,
  Col,
  Upload,
  Button,
  Icon,
  message,
  Divider,
  Select,
  DatePicker,
} from "antd";
import moment from "moment";
// import Axios from "axios";

let state = {
  imageUrl: null,
  loading: false,
  base_url: null,
  settings: {},
  school: [],
  params: {},
  sch_details: {},
  token: null,
  photo: {},
  formData: {},
};

class NewApplicationForm extends Component {
  constructor(props) {
    super(props);
    this.state = state;
  }

  componentDidMount() {
    if (!this.props.location.state) {
      message.error("Invalid URL");
      return;
    }
    let {
      params,
      base_url,
      settings,
      sch_details,
      token,
    } = this.props.location.state;
    // let params = this.props.match.params;
    if (!(params.sch_code || params.sch_token)) {
      message.error("Invalid URL");
      return;
    }
    if (this.props.history.action === "POP") {
      let val = this.state.formData;
      if (!val.details) {
        message.error("No Data");
        return;
      }
      val.details.student.dob = moment(val.details.student.dob);
      this.props.form.setFieldsValue(val);
    }
    this.setState({ params, base_url, settings, sch_details, token });
  }

  componentWillUnmount() {
    state = this.state;
  }

  getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  handleChange = (info) => {
    if (info.file.status === "uploading") {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      let photo = {
        name: info.file.name,
        path: info.file.response.path,
        status: "done",
        uid: info.file.uid,
        url: this.state.base_url + "/" + info.file.response.path,
      };
      this.setState({ photo });
      this.getBase64(info.file.originFileObj, (imageUrl) =>
        this.setState({
          imageUrl: photo.url,
          loading: false,
        })
      );
    }
  };

  beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 < 200;
    if (!isLt2M) {
      message.error("Image must smaller than 200kB!");
    }
    return isJpgOrPng && isLt2M;
  };

  handleSubmit = () => {
    this.props.form.validateFieldsAndScroll((err, val) => {
      if (!err) {
        let dob = val.details.student.dob;
        val.student_photo = this.state.photo;
        val.details.student.dob = dob && dob.toJSON();
        val.details.prev_school.marks_card = val.details.prev_school.marks_card
          ? val.details.prev_school.marks_card.map((item) => {
              if (item.response) {
                return {
                  path: item.response.path,
                  name: item.name,
                  url: this.state.base_url + "/" + item.response.path,
                  uid: item.uid,
                  size: item.size,
                };
              }
              return item;
            })
          : [];
        this.setState({ formData: val }, () => {
          this.props.history.push({
            pathname: "/admissions/apply/preview/",
            state: { state: { ...this.state }, formData: val },
          });
        });
      }
    });
  };

  normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  guardianNameUpadte = (e) => {
    let { setFieldsValue } = this.props.form;
    setFieldsValue({ "details.father.name": e.target.value });
  };

  guardianPhoneUpdate = (e) => {
    let { setFieldsValue } = this.props.form;
    setFieldsValue({ "details.father.mobile": e.target.value });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const FormItem = Form.Item;
    const { imageUrl, settings } = this.state;
    const { Option } = Select;
    const { base_url, token } = this.state;
    return (
      <Layout.Content className="container">
        <h1 style={{ textAlign: "center" }}>Apply for Admission</h1>
        <Form>
          <Row>
            <Col sm={24}>
              <Row gutter={24}>
                <Col sm={24}>
                  <h2>Student Details</h2>
                </Col>
                <Col sm={12}>
                  <FormItem label="Student Name">
                    {getFieldDecorator("student_name", {
                      rules: [
                        {
                          required: true,
                          message: "Student name is required!",
                        },
                      ],
                    })(<Input />)}
                  </FormItem>
                  <Row gutter={16}>
                    <Col sm={8}>
                      <FormItem label="Class applying to">
                        {getFieldDecorator("application_class", {
                          rules: [
                            { required: true, message: "Class is required!" },
                          ],
                        })(
                          <Select placeholder="Select Class">
                            {settings.adm_classes_list &&
                              settings.adm_classes_list.map((item, index) => {
                                return (
                                  <Option key={index} value={item}>
                                    {item}
                                  </Option>
                                );
                              })}
                          </Select>
                        )}
                      </FormItem>
                    </Col>
                    <Col sm={8}>
                      <FormItem label="Gender">
                        {getFieldDecorator("details.student.gender")(
                          <Select placeholder="Gender">
                            <Select.Option value="m">Male</Select.Option>
                            <Select.Option value="f">Female</Select.Option>
                            <Select.Option value="o">Others</Select.Option>
                          </Select>
                        )}
                      </FormItem>
                    </Col>
                    <Col sm={8}>
                      <FormItem label="Date of Birth">
                        {getFieldDecorator("details.student.dob")(
                          <DatePicker />
                        )}
                      </FormItem>
                    </Col>
                  </Row>
                </Col>
                <Col sm={12} style={{ textAlign: "center" }}>
                  <FormItem label="Student Photo">
                    {getFieldDecorator("student_photo")(
                      <Upload
                        className="avatar-uploader"
                        beforeUpload={this.beforeUpload}
                        onChange={this.handleChange}
                        name="file"
                        listType="picture-card"
                        action={base_url + "/upload/?file_path=admission"}
                        accept=".jpg,.jpeg,.png"
                        headers={{ Authorization: token }}
                      >
                        {imageUrl ? (
                          <img
                            src={imageUrl}
                            alt="avatar"
                            style={{ width: "100%" }}
                          />
                        ) : (
                          <div>
                            <Icon
                              type={this.state.loading ? "loading" : "plus"}
                            />
                            <div className="ant-upload-text">Upload</div>
                            <p>(Max 200kB)</p>
                          </div>
                        )}
                      </Upload>
                    )}
                  </FormItem>
                </Col>

                <Divider />

                <Col sm={24}>
                  <h2>Parent/Guardian Details (Person of Contact)</h2>
                </Col>
                <Col sm={12}>
                  <FormItem label="Parent/Guardian Name">
                    {getFieldDecorator("parent_name", {
                      rules: [
                        {
                          required: true,
                          message: "Parent/Guardian name is required!",
                        },
                      ],
                    })(<Input onChange={this.guardianNameUpadte} />)}
                  </FormItem>
                  <FormItem label="Parent/Guardian Email">
                    {getFieldDecorator("email", {
                      rules: [
                        { required: true, message: "Email is required!" },
                        {
                          type: "email",
                          message: "Enter valid email address!",
                        },
                      ],
                    })(<Input />)}
                  </FormItem>
                </Col>

                <Col sm={12}>
                  <FormItem label="Parent/Guardian Mobile">
                    {getFieldDecorator("mobile", {
                      rules: [
                        { required: true, message: "Mobile is required!" },
                        {
                          pattern: "^[+0-9 ]{10,16}$",
                          message: "Enter valid mobile number!",
                        },
                      ],
                    })(<Input onChange={this.guardianPhoneUpdate} />)}
                  </FormItem>
                  <FormItem label="Parent/Guardian Address">
                    {getFieldDecorator("details.guardian.address")(
                      <Input.TextArea />
                    )}
                  </FormItem>
                </Col>

                <Divider />

                <Col sm={24}>
                  <h2>Father's Details</h2>
                </Col>
                <Col sm={12}>
                  <FormItem label="Father's Name">
                    {getFieldDecorator("details.father.name")(<Input />)}
                  </FormItem>
                  <FormItem label="Father's Mobile">
                    {getFieldDecorator("details.father.mobile")(<Input />)}
                  </FormItem>
                </Col>
                <Col sm={12}>
                  <FormItem label="Father's Profession">
                    {getFieldDecorator("details.father.profession")(<Input />)}
                  </FormItem>
                  <FormItem label="Father's Annual Income">
                    {getFieldDecorator("details.father.salary")(<Input />)}
                  </FormItem>
                </Col>

                <Divider />

                <Col sm={24}>
                  <h2>Mothers's Details</h2>
                </Col>
                <Col sm={12}>
                  <FormItem label="Mother's Name">
                    {getFieldDecorator("details.mother.name")(<Input />)}
                  </FormItem>
                  <FormItem label="Mother's Mobile">
                    {getFieldDecorator("details.mother.mobile")(<Input />)}
                  </FormItem>
                </Col>
                <Col sm={12}>
                  <FormItem label="Mother's Profession">
                    {getFieldDecorator("details.mother.profession")(<Input />)}
                  </FormItem>
                  <FormItem label="Mother's Annual Income">
                    {getFieldDecorator("details.mother.salary")(<Input />)}
                  </FormItem>
                </Col>

                <Divider />

                <Col sm={24}>
                  <h2>Previous School/College Details (If Applicable)</h2>
                </Col>
                <Col sm={12}>
                  <FormItem label="Previous School/College Name">
                    {getFieldDecorator("details.prev_school.name")(<Input />)}
                  </FormItem>
                </Col>
                <Col sm={12}>
                  <FormItem label="Percentage/CGPA in final exam of previous year">
                    {getFieldDecorator("details.prev_school.marks")(<Input />)}
                  </FormItem>
                </Col>
                <Col sm={12}>
                  <FormItem label="City/Town/Village of Previous School/College">
                    {getFieldDecorator("details.prev_school.city")(<Input />)}
                  </FormItem>
                </Col>
                <Col sm={12}>
                  <FormItem label="Upload Previous Year Marks Card (Ignore if not available)">
                    {getFieldDecorator("details.prev_school.marks_card", {
                      valuePropName: "fileList",
                      getValueFromEvent: this.normFile,
                    })(
                      <Upload
                        name="file"
                        action={base_url + "/upload/?file_path=admission"}
                        accept=".jpg,.jpeg,.png,.pdf"
                        headers={{ Authorization: token }}
                      >
                        <Button icon="upload">Upload Marks Card</Button>
                      </Upload>
                    )}
                  </FormItem>
                </Col>
              </Row>
              <FormItem>
                <Button onClick={this.handleSubmit} type="primary">
                  Submit
                </Button>
              </FormItem>
            </Col>
          </Row>
        </Form>
      </Layout.Content>
    );
  }
}

export default Form.create()(NewApplicationForm);
