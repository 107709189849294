import React from "react";
import { Switch, Route } from "react-router-dom";
import { TableOfContent, GettingStarted } from "./usage";
import { ContactSupport } from "./contact";
import { FAQs } from "./faqs";

const AdminHelpRouter = props => (
  <Switch>
    <Route exact path="/help/usage" component={TableOfContent} />
    <Route
      exact
      path="/help/usage/getting-started/"
      component={GettingStarted}
    />
    <Route exact path="/help/contact/" component={ContactSupport} />
    <Route exact path="/help/faqs/" component={FAQs} />
  </Switch>
);

export { AdminHelpRouter };
