import React, { Component } from "react";
import { Layout, Form, Input, Button, InputNumber, message, Spin } from "antd";
import {
  InventoryCategoryDropdown,
  ExpensesDropdown,
  ShowBreadcrumbs,
} from "../../../../components";
import { api } from "../../../../config";

const styles = {
  calc_text: {
    display: "inline-block",
    width: "50%",
    margin: "0 0 0 30px",
    color: "rgba(0, 0, 0, 0.85)",
    fontSize: 14,
    textAlign: "right",
  },
};

class InventoryForm extends Component {
  state = {
    quantity: null,
    tax_amount: null,
    total_before_taxes: null,
    loading: false,
    id: null,
    spinning: false,
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      this.setState({ id: this.props.match.params.id });
      this.get_inventory(this.props.match.params.id);
    }
  }

  get_inventory = (id) => {
    this.setState({ spinning: true });
    api
      .get("/finance/inventory/" + id + "/")
      .then((res) => {
        if (res.data.success) {
          let data = res.data.data;
          this.props.form.setFieldsValue(data);
          this.setState({
            quantity: data.quantity,
            total_before_taxes: data.amount,
            tax_amount: data.tax,
          });
        } else {
          message.error(res.data.error);
        }
        this.setState({ spinning: false });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ spinning: false });
      });
  };

  add_inventory_item = (val) => {
    this.setState({ loading: true });
    api
      .put("/finance/inventory/", JSON.stringify(val))
      .then((res) => {
        if (res.data && res.data.success) {
          message.success("Inventory Item Added");
          this.props.history.push("/finance/inventory/");
        } else {
          message.error(res.data.error);
        }
        this.setState({ loading: false });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  update_inventory_item = (id, val) => {
    this.setState({ loading: true });
    api
      .post("/finance/inventory/" + id + "/", JSON.stringify(val))
      .then((res) => {
        if (res.data.success) {
          message.success("Inventory item updated");
        } else {
          message.error(res.data.error);
        }
        this.setState({ loading: false });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  handleSubmit = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        val.total_amount =
          this.state.tax_amount + this.state.total_before_taxes;
        if (this.state.id) {
          this.update_inventory_item(this.state.id, val);
        } else {
          this.add_inventory_item(val);
        }
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const FormItem = Form.Item;
    const { id } = this.state;
    return (
      <Layout.Content>
        <ShowBreadcrumbs
          items={[
            { name: "Home", link: "/" },
            { name: "Finance", link: "/finance/" },
            { name: "Inventory", link: "/finance/inventory/" },
            {
              name: (id ? "Edit " : "New ") + "Inventory",
              link: "/finance/inventory/" + (id ? id : "new"),
            },
          ]}
        />
        <h1 className="align-center">Inventory Form</h1>
        <Spin spinning={this.state.spinning}>
          <Form labelCol={{ sm: 8 }} wrapperCol={{ sm: 8 }}>
            <FormItem label="Name">
              {getFieldDecorator("name", {
                rules: [
                  { required: true, message: "Inventory name is required" },
                ],
              })(<Input />)}
            </FormItem>
            <FormItem label="Inventory Category">
              {getFieldDecorator("inv_cat_id")(<InventoryCategoryDropdown />)}
            </FormItem>
            <FormItem label="Expense for item">
              {getFieldDecorator("expense_id")(<ExpensesDropdown />)}
            </FormItem>
            <FormItem label="Quantity">
              {getFieldDecorator("quantity", {
                rules: [{ required: true, message: "Quantity is required" }],
              })(
                <InputNumber
                  onChange={(val) => {
                    this.setState({ quantity: val });
                  }}
                />
              )}
            </FormItem>
            <FormItem label="Unit">
              {getFieldDecorator("unit")(<Input placeholder="Unit of quantity like Nos/Kgs etc." />)}
            </FormItem>
            <FormItem label="Amount before taxes">
              {getFieldDecorator("amount")(
                <InputNumber
                  onChange={(val) => {
                    this.setState({ total_before_taxes: val });
                  }}
                />
              )}
              <span style={styles.calc_text}>
                Price/unit (before taxes) : &nbsp;
                <InputNumber
                  disabled
                  value={(
                    this.state.total_before_taxes / this.state.quantity
                  ).toFixed(2)}
                />
              </span>
            </FormItem>
            <FormItem label="Total tax amount">
              {getFieldDecorator("tax")(
                <InputNumber
                  onChange={(val) => {
                    this.setState({ tax_amount: val });
                  }}
                />
              )}
              <span style={styles.calc_text}>
                Tax in percentage : &nbsp;
                <InputNumber
                  disabled
                  value={(
                    (this.state.tax_amount / this.state.total_before_taxes) *
                    100
                  ).toFixed(2)}
                />
              </span>
            </FormItem>
            <FormItem label="Total Amount">
              <InputNumber
                disabled
                value={this.state.tax_amount + this.state.total_before_taxes}
              />
            </FormItem>
            <FormItem label="Details">
              {getFieldDecorator("details")(<Input.TextArea />)}
            </FormItem>
            <FormItem wrapperCol={{ offset: 8 }}>
              <Button loading={this.state.loading} onClick={this.handleSubmit}>
                Save
              </Button>
            </FormItem>
          </Form>
        </Spin>
      </Layout.Content>
    );
  }
}

export default Form.create()(InventoryForm);
