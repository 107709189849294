import { Button, Card, message, Upload } from "antd";
import React, { Component } from "react";
import { api, baseurl, getToken } from "../../../../config";

export default class StudentPicture extends Component {
  state = {
    picture: null,
    loading: false,
  };

  beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 < 256;
    if (!isLt2M) {
      message.error("Image must smaller than 256 kB!");
    }
    return isJpgOrPng && isLt2M;
  };

  updateProfile = async (path) => {
    let { student_id } = this.props;
    try {
      let data = { student_id, name: "profile_photo", value: path };
      let res = await api.post(`/student/profile/item/`, JSON.stringify(data));
      if (res.data.success) {
        message.success("Student Pic Updated!");
      } else {
        message.error(res.data.error);
      }
    } catch (err) {
      message.error(err);
    }
  };

  handleChange = (info) => {
    if (info.file.status === "uploading") {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      this.updateProfile(info.file.response.path);
      this.setState({ loading: false, picture: info.file.response.path });
    }
  };

  render() {
    const { loading, picture } = this.state;
    let pic = picture || this.props.picture;
    return (
      <Card
        title="Student Pic"
        style={{ marginBottom: 20 }}
        className="admin-card card-elevation"
      >
        <div className="student-avatar-div">
          {pic ? (
            <img
              src={`${baseurl}/${pic}`}
              alt="Avatar"
              className="student-avatar"
            />
          ) : (
            <div className="student-no-avatar">
              <p>No Picture</p>
            </div>
          )}
        </div>
        <div className="student-avatar-upload">
          <Upload
            name="file"
            action={`${baseurl}/upload/?file_path=profile`}
            headers={{ Authorization: getToken() }}
            listType="text"
            accept=".jpg,.jpeg"
            beforeUpload={this.beforeUpload}
            onChange={this.handleChange}
          >
            <Button loading={loading} icon="upload">
              Upload
            </Button>
          </Upload>
        </div>
      </Card>
    );
  }
}
