import React, { Component } from "react";
import { Layout, Table, message } from "antd";
import { api } from "../../../../config";

class TripsList extends Component {
  state = {
    trips: [],
    spinning: false
  };

  componentDidMount() {
    this.get_trips_list();
  }

  get_trips_list = () => {
    api
      .get("/bus/trips/")
      .then(res => {
        if (res.data.success) {
          this.setState({ trips: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  render() {
    const columns = [
      { title: "ID", dataIndex: "id" },
      { title: "Route", dataIndex: "route_name" },
      { title: "Date", dataIndex: "trip_date" },
      { title: "Start Time", dataIndex: "start_time" },
      { title: "End Time", dataIndex: "end_time" }
    ];
    return (
      <Layout.Content>
        <h1>Bus Trips</h1>
        <Table
          columns={columns}
          dataSource={this.state.trips}
          rowKey="id"
          loading={this.state.spinning}
        />
      </Layout.Content>
    );
  }
}

export default TripsList;
