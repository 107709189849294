import { Layout, message, Table } from "antd";
import React, { Component } from "react";
import { api } from "../../../config";

class UsersList extends Component {
  state = {
    users: [],
    spinning: false,
  };

  componentDidMount() {
    this._get_users_list();
  }

  _get_users_list = () => {
    this.setState({ spinning: true });
    api
      .get("/users/")
      .then((res) => {
        if (res.data.success) {
          this.setState({ users: res.data.data });
        } else {
          message.error(res.data.error);
        }
        this.setState({ spinning: false });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ spinning: false });
      });
  };

  render() {
    const { users, spinning } = this.state;
    const columns = [
      { title: "ID", dataIndex: "id" },
      { title: "Name", dataIndex: "name" },
      { title: "Username", dataIndex: "username" },
      { title: "Email", dataIndex: "email" },
      { title: "Registered on", dataIndex: "registration" },
    ];

    return (
      <Layout.Content>
        <h1>Users List</h1>
        <Table
          columns={columns}
          dataSource={users}
          rowKey="id"
          loading={spinning}
          pagination={{ pageSize: 50 }}
        />
      </Layout.Content>
    );
  }
}

export default UsersList;
