import { Form, Input, Modal } from "antd";
import React, { Component } from "react";
import { HRUserSelectorDropdown } from "../../../components";

class NewChat extends Component {
  state = {};

  _handle_submit = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        this.props.sendMessage(val);
      }
    });
  };

  render() {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    return (
      <Modal
        visible={true}
        title="Start New Chat"
        onCancel={this.props.closeModal.bind(this)}
        onOk={this._handle_submit}
      >
        <Form>
          <FormItem label="Select User">
            {getFieldDecorator("to_user_id", {
              rules: [{ required: true, message: "User is required!" }],
            })(<HRUserSelectorDropdown all={true} />)}
          </FormItem>
          <FormItem label="Message">
            {getFieldDecorator("message", {
              rules: [{ required: true, message: "Message is required!" }],
            })(<Input />)}
          </FormItem>
        </Form>
      </Modal>
    );
  }
}

export default Form.create()(NewChat);
