import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Icon,
  Input,
  InputNumber,
  Layout,
  message,
  Row,
  Select,
  Upload,
} from "antd";
import React, { Component } from "react";
import {
  AccountsDropdown,
  EmployeeDropdown,
  ExpensesCategoryDropdown,
  PaymentsModeDropdown,
} from "../../../../components";
import { api, baseurl, getToken } from "../../../../config";
import moment from "moment";

class PayrollPaymentForm extends Component {
  state = {
    id: undefined,
    salary_amount: 0.0,
    absent_days: 0,
    total_days: undefined,
    count: 1,
    salary: {},
    ctc: undefined,
  };

  _handleSubmit = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        val.files =
          val.files &&
          val.files.map((item) => {
            if (item.response) {
              return {
                path: item.response.path,
                name: item.name,
                url: baseurl + "/" + item.response.path,
                size: item.size,
                type: item.type,
                uid: new Date().getTime(),
              };
            }
            return item;
          });
        val.month_year = val.month_year.utc().startOf("month");
        val.present_days = val.total_days - val.absent_days;
        api
          .put("/simple/payroll/", JSON.stringify(val))
          .then((res) => {
            if (res.data.success) {
              message.success("New payroll entry is added");
              this.props.history.goBack();
            } else {
              message.error(res.data.error);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  normFile = (e) => {
    console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  _updateSalary = () => {
    let { absent_days, total_days, ctc } = this.state;
    let { setFieldsValue, getFieldValue } = this.props.form;
    if (!(total_days && ctc)) {
      return;
    }
    let salary_amount =
      ((parseInt(total_days) - parseInt(absent_days)) / parseInt(total_days)) *
      ctc;
    salary_amount = Math.ceil(salary_amount);

    let salary = getFieldValue("extras.payslip");
    var index = undefined;
    salary.forEach((item, ind) => {
      if (item.key === "lop") index = ind;
    });

    if (absent_days === 0) {
      setFieldsValue({ salary_amount });
      if (index) this._cancel_payroll_item(index);
      return;
    }

    let item_name =
      (absent_days > 0 ? "Loss of Pay x" : "Overtime x") +
      Math.abs(absent_days).toString();
    let type = absent_days > 0 ? 1 : 0;
    let data = { item_name, type, value: ctc - salary_amount, key: "lop" };

    if (index) {
      salary[index] = { ...data };
    } else {
      salary.push({ ...data });
    }
    this.setState({ count: salary.length }, () => {
      setFieldsValue({ salary_amount, "extras.payslip": salary });
    });
  };

  _cancel_payroll_item = (index) => {
    let { form } = this.props;
    let { count } = this.state;
    let val = form.getFieldValue("extras.payslip");
    val.splice(index, 1);
    form.setFieldsValue({ "extras.payslip": val });
    this.setState({ count: count - 1 });
  };

  _update_salary = () => {
    let { form } = this.props;
    let val = form.getFieldValue("extras.payslip");
    let total = 0.0;
    val.forEach((item) => {
      total +=
        item.type === 0 ? parseFloat(item.value) : -1 * parseFloat(item.value);
    });
    form.setFieldsValue({ amount_paid: total });
    this.setState({ amount_paid: total }, this._updateSalary);
  };

  _get_employee_salary = (employee_id) => {
    api
      .get(`/simple/payroll/employee/${employee_id}/salary/`)
      .then((res) => {
        if (res.data.success) {
          let { salary, ctc } = res.data.data;
          if (!salary) {
            message.info("Employee salary is not set");
            this.props.form.resetFields([
              "extras.payslip",
              "salary_amount",
              "amount_paid",
              "absent_days",
            ]);
            this.setState({ count: 1, ctc: undefined });
            this._updateSalary();
          } else {
            salary = JSON.parse(salary);
            this.setState({ count: salary.length, salary: res.data.data, ctc });
            this.props.form.setFieldsValue({
              "extras.payslip": salary,
            });
            this._updateSalary();
          }
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const uploadProps = {
      name: "file",
      action: baseurl + "/upload/?file_path=finances",
      headers: { Authorization: getToken() },
      accept: ".jpg,.jpeg,.png,.pdf,.doc,.docx",
      multiple: true,
    };
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    const { count, absent_days, salary } = this.state;
    return (
      <Layout.Content>
        <Row gutter={24}>
          <Col sm={12}>
            <h1>New Payroll Entry</h1>
          </Col>
          <Col sm={12} className="action-buttons">
            <Button type="primary" onClick={this._handleSubmit}>
              Save
            </Button>
          </Col>
        </Row>
        <Form>
          <Row gutter={24}>
            <Col sm={12}>
              <Card title="Payroll Form">
                <FormItem label="Employee">
                  {getFieldDecorator("employee_id", {
                    rules: [{ required: true, message: "Name is requried!" }],
                  })(<EmployeeDropdown onChange={this._get_employee_salary} />)}
                </FormItem>
                <Row gutter={16}>
                  <Col sm={12}>
                    <FormItem label="CTC">
                      <Input value={salary.ctc} disabled />
                    </FormItem>
                  </Col>
                  <Col sm={12}>
                    {salary.total_salary - salary.total_paid >= 0 ? (
                      <FormItem label="Salary Due">
                        <Input
                          value={salary.total_salary - salary.total_paid || " "}
                          disabled
                        />
                      </FormItem>
                    ) : (
                      <FormItem label="Excess Salary">
                        <Input
                          value={salary.total_paid - salary.total_salary || 0}
                          disabled
                        />
                      </FormItem>
                    )}
                  </Col>
                  <Col sm={8}>
                    <FormItem label="Absent Days">
                      {getFieldDecorator("absent_days", {
                        rules: [
                          {
                            required: true,
                            message: "Absent Days is required!",
                          },
                        ],
                        initialValue: absent_days,
                      })(
                        <InputNumber
                          onChange={(v) =>
                            this.setState(
                              { absent_days: v },
                              this._updateSalary
                            )
                          }
                        />
                      )}
                    </FormItem>
                  </Col>
                  <Col sm={8}>
                    <FormItem label="Total Days">
                      {getFieldDecorator("total_days", {
                        rules: [
                          {
                            required: true,
                            message: "Total Days is required!",
                          },
                        ],
                      })(
                        <InputNumber
                          onChange={(v) =>
                            this.setState({ total_days: v }, this._updateSalary)
                          }
                        />
                      )}
                    </FormItem>
                  </Col>
                  <Col sm={8}>
                    <FormItem label="Month &amp; Year">
                      {getFieldDecorator("month_year", {
                        rules: [
                          {
                            required: true,
                            message: "Month and Year is required!",
                          },
                        ],
                        initialValue: moment(),
                      })(<DatePicker.MonthPicker />)}
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col sm={8}>
                    <FormItem label="Salary">
                      {getFieldDecorator("salary_amount", {
                        rules: [
                          {
                            required: true,
                            message: "Salary amount is required!",
                          },
                        ],
                      })(
                        <InputNumber
                          onChange={(val) =>
                            this.setState(
                              { salary_amount: val },
                              this._updateSalary
                            )
                          }
                        />
                      )}
                    </FormItem>
                  </Col>
                  <Col sm={8}>
                    <FormItem label="Salary Paid">
                      {getFieldDecorator("amount_paid", {
                        rules: [
                          {
                            required: true,
                            message: "Amount Paid is required!",
                          },
                        ],
                      })(<InputNumber />)}
                    </FormItem>
                  </Col>
                  <Col sm={8}>
                    <FormItem label="Payment Date">
                      {getFieldDecorator("payment_date", {
                        rules: [
                          {
                            required: true,
                            message: "Payment Date is required!",
                          },
                        ],
                        initialValue: moment(),
                      })(<DatePicker />)}
                    </FormItem>
                  </Col>
                  <Divider />
                  <h3>Expense</h3>
                  <Col sm={12}>
                    <FormItem label="Debit Account">
                      {getFieldDecorator("exp_debit_account")(
                        <AccountsDropdown />
                      )}
                    </FormItem>
                  </Col>
                  <Col sm={12}>
                    <FormItem label="Expense Payment Mode">
                      {getFieldDecorator("exp_payment_mode")(
                        <PaymentsModeDropdown />
                      )}
                    </FormItem>
                  </Col>
                  <Col sm={12}>
                    <FormItem label="Expense Category">
                      {getFieldDecorator("exp_category")(
                        <ExpensesCategoryDropdown />
                      )}
                    </FormItem>
                  </Col>
                  <Col sm={12}>
                    <FormItem label="Payment Details">
                      {getFieldDecorator("payment_mode_details")(
                        <Input placeholder="Cheque No or Txn. ID" />
                      )}
                    </FormItem>
                  </Col>
                </Row>
                <FormItem label="Payslip">
                  {getFieldDecorator("payslip", {
                    valuePropName: "fileList",
                    getValueFromEvent: this.normFile,
                  })(
                    <Upload {...uploadProps}>
                      <Button icon="upload">Upload</Button>
                    </Upload>
                  )}
                </FormItem>
              </Card>
            </Col>
            <Col sm={12}>
              <Card title="Payslip Generator">
                {[...Array(count).keys()].map((item, index) => {
                  return (
                    <Row gutter={12} key={index} style={{ marginBottom: 0 }}>
                      <Col sm={12}>
                        <FormItem>
                          {getFieldDecorator(`extras.payslip[${index}].key`)(
                            <Input type="hidden" />
                          )}
                          {getFieldDecorator(
                            `extras.payslip[${index}].item_name`,
                            {
                              rules: [
                                {
                                  required: true,
                                  message: "Item name is required",
                                },
                              ],
                            }
                          )(<Input placeholder="Item Name" />)}
                        </FormItem>
                      </Col>
                      <Col sm={6}>
                        <FormItem>
                          {getFieldDecorator(`extras.payslip[${index}].type`, {
                            initialValue: 0,
                            rules: [
                              { required: true, message: "Type is required!" },
                            ],
                          })(
                            <Select onBlur={this._update_salary}>
                              <Select.Option value={0}>Credit</Select.Option>
                              <Select.Option value={1}>Debit</Select.Option>
                            </Select>
                          )}
                        </FormItem>
                      </Col>
                      <Col sm={5}>
                        <FormItem>
                          {getFieldDecorator(`extras.payslip[${index}].value`, {
                            rules: [
                              { required: true, message: "Value is requried!" },
                            ],
                          })(<InputNumber onBlur={this._update_salary} />)}
                        </FormItem>
                      </Col>
                      <Col sm={1}>
                        <Icon
                          type="close-circle"
                          style={{ fontSize: 18, marginTop: 8 }}
                          onClick={this._cancel_payroll_item.bind(this, index)}
                        />
                      </Col>
                    </Row>
                  );
                })}
                <Button
                  icon="plus"
                  type="dashed"
                  block
                  onClick={() => this.setState({ count: count + 1 })}
                >
                  Add New Item
                </Button>
              </Card>
            </Col>
          </Row>
        </Form>
      </Layout.Content>
    );
  }
}

export default Form.create()(PayrollPaymentForm);
