import React, { Component } from "react";
import { Layout, Table, Row, Col, Icon, message } from "antd";
import StudentSelector from "../../../components/StudentSelector";
import { api } from "../../../config";
import moment from "moment";

class OnlineExams extends Component {
  state = {
    exams: [],
    student_id: null,
  };

  componentDidMount() {}

  get_online_exams_list = (student_id) => {
    api
      .get("/exam/list/" + student_id + "/")
      .then((res) => {
        if (res.data.success) {
          this.setState({ exams: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const { student_id } = this.state;
    const columns = [
      { title: "ID", dataIndex: "id" },
      { title: "Name", dataIndex: "name" },
      {
        title: "Duration",
        dataIndex: "max_duration",
        render: (v) => <span>{v / 60 + " mins"}</span>,
      },
      {
        title: "Start Time",
        dataIndex: "start_time",
        render: (v, r) => (
          <span>
            {v ? moment.utc(v).local().format("YYYY-MM-DD HH:mm A") : " - "}
          </span>
        ),
      },
      {
        title: "Actions",
        render: (r) => (
          <span>
            <Icon
              type="play-circle"
              className="action-icon"
              onClick={() => {
                this.props.history.push({
                  pathname: "/exam/details/" + r.id + "/",
                  state: { exam: r, student_id: student_id },
                });
              }}
            />
          </span>
        ),
      },
    ];
    return (
      <Layout.Content>
        <Row>
          <Col sm={8}>
            <h1>Online Exams</h1>
          </Col>
          <Col sm={8}>
            <span>
              <strong>Student : &nbsp;</strong>
            </span>
            <StudentSelector
              value={this.state.student_id}
              style={{ minWidth: 150 }}
              onChange={(val) => {
                this.get_online_exams_list(val);
                this.setState({ student_id: val });
              }}
            />
          </Col>
        </Row>
        <Table columns={columns} dataSource={this.state.exams} rowKey="id" />
      </Layout.Content>
    );
  }
}

export default OnlineExams;
