import React, { useState, useEffect } from "react";
import { Layout, message, Table, Descriptions, Card, Button } from "antd";
import { api } from "../../../../config";
import moment from "moment";

export default function SimpleTestDetails(props) {
  const [id, setId] = useState();
  const [student_id, setStudentId] = useState({});
  const [attempts, setAttempts] = useState([]);
  const [test, setTest] = useState({});

  useEffect(() => {
    let test_id = props.match.params.id;
    let state = props.location.state;
    if (!(state.student_id && state.test)) {
      props.history.push("/simple-test/");
      return;
    }
    setId(test_id);
    setStudentId(state.student_id);
    setTest(state.test);
  }, []);

  useEffect(() => {
    id && student_id && _get_simple_test_details();
  }, [id, student_id]);

  const _get_simple_test_details = () => {
    api
      .get(`/simple/test/${id}/details/${student_id}/`)
      .then((res) => {
        if (res.data.success) {
          setAttempts(res.data.data);
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const _start_test = () => {
    if (attempts.length === 2) {
      message.error("You have already attempted twice");
      return;
    }
    if (test.start_time) {
      let time = moment(test.start_time);
      let now = moment();
      console.log(time, now);
      if (now < time) {
        message.error("Test not yet started!");
        return;
      } else if (time.endOf("day") < now) {
        message.error("You are late, you can't take the test now!");
        return;
      }
    }
    props.history.push({
      pathname: "/simple-test/test/",
      state: { student_id, test },
    });
  };

  const columns = [
    { title: "ID", dataIndex: "id" },
    {
      title: "Time",
      dataIndex: "start_time",
      render: (v) => (
        <span>{moment.utc(v).local().format("YYYY-MM-DD hh:mm A")}</span>
      ),
    },
    {
      title: "End Time",
      dataIndex: "end_time",
      render: (v) => (
        <span>{moment.utc(v).local().format("YYYY-MM-DD hh:mm A")}</span>
      ),
    },
    { title: "Marks", dataIndex: "marks_obtained" },
  ];

  const { Item } = Descriptions;

  return (
    <Layout.Content>
      <Card
        title="Test Details"
        style={{ marginBottom: 20 }}
        extra={[
          <Button
            size="small"
            icon="play-circle"
            type="primary"
            onClick={_start_test.bind(this)}
          >
            Start
          </Button>,
        ]}
      >
        <Descriptions>
          <Item label="Test Name">{test.test_name}</Item>
          <Item label="Subject">{test.subject}</Item>
          <Item label="Duration">{test.duration} mins</Item>
          <Item label="Start Time">
            {test.start_time
              ? moment.utc(test.start_time).local().format("YYYY-MM-DD hh:mm A")
              : " - "}
          </Item>
        </Descriptions>
      </Card>
      <Card title="Attempts">
        <Table columns={columns} dataSource={attempts} rowKey="id" />
      </Card>
    </Layout.Content>
  );
}
