import React, { Component } from "react";
import { Select } from "antd";
import { api } from "../config";

class UserSelectorDropdown extends Component {
  state = {
    emails: [],
    options: [],
  };

  componentDidMount() {
    this.get_users_list();
  }

  get_users_list = () => {
    api
      .get("/search/email/8/")
      .then((res) => {
        if (res.data.success) {
          let data = res.data.data;
          let options = data.map((item, index) => {
            return (
              <Select.Option key={index} value={item.id}>
                {item.email || item.username}
              </Select.Option>
            );
          });
          this.setState({ emails: data, options });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const { options } = this.state;
    return (
      <Select
        mode="multiple"
        placeholder="Select User"
        onChange={this.props.onChange}
        value={this.props.value}
        {...this.props}
      >
        {options}
      </Select>
    );
  }
}

export default UserSelectorDropdown;
