import React, { Component } from "react";
import { Row, Col, Input, Select, Form, Button, message } from "antd";
import { api } from "../../../config";

class AddUserForm extends Component {
  state = {
    emails: [],
    selected_id: null
  };

  componentDidMount() {
    api
      .get("/search/email/")
      .then(res => {
        if (res.data && res.data.success) {
          this.setState({ emails: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  handleGo = () => {
    this.props.nextScreen(this.state.selected_id);
  };

  render() {
    const Option = Select.Option;
    const FormItem = Form.Item;
    return (
      <div>
        <Row>
          <Col sm={12}>
            <h2 style={{ textAlign: "center" }}>Select Existing User</h2>
            <div style={{ padding: "40px 80px", margin: "auto" }}>
              <FormItem>
                <Select
                  showSearch
                  showArrow={false}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  style={{ width: "100%" }}
                  placeholder="Add Existing User"
                  onChange={v => {
                    this.setState({ selected_id: v });
                  }}
                >
                  {this.state.emails.map((item, index) => {
                    return (
                      <Option value={item.id} key={index}>
                        {item.email}
                      </Option>
                    );
                  })}
                </Select>
              </FormItem>
              <FormItem style={{ textAlign: "center" }}>
                <Button onClick={this.handleGo}>Go</Button>
              </FormItem>
            </div>
          </Col>
          <Col sm={12} style={{ borderLeft: "1px solid rgba(0, 0, 0, 0.25)" }}>
            <h2 style={{ textAlign: "center" }}>Add New User</h2>
            <NewUserForm nextScreen={this.props.nextScreen} />
          </Col>
          {!this.props.link_user && (
            <Col sm={24} style={{ textAlign: "center", padding: 10 }}>
              <Button type="primary" onClick={this.handleGo}>
                Skip
              </Button>
            </Col>
          )}
        </Row>
      </div>
    );
  }
}

class UserForm extends Component {
  state = {
    loading: false
  };
  componentDidMount() {
    this.changePassword();
  }
  changePassword = () => {
    let password = Math.random()
      .toString(36)
      .slice(-8);
    this.props.form.setFieldsValue({ password });
  };

  handleSubmit = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        this.addNewUser(val);
      }
    });
  };

  addNewUser = val => {
    val.role = 1;
    this.setState({ loading: true });
    api
      .put("/user/", JSON.stringify(val))
      .then(res => {
        if (res.data && res.data.success) {
          this.props.nextScreen(res.data.data.user_id);
        } else {
          message.error(res.data.error);
        }
        this.setState({ loading: false });
      })
      .catch(err => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const FormItem = Form.Item;
    return (
      <Form>
        <FormItem label="Name" labelCol={{ sm: 6 }} wrapperCol={{ sm: 18 }}>
          {getFieldDecorator("name", {
            rules: [{ required: true, message: "Please enter name" }]
          })(<Input placeholder="Name" />)}
        </FormItem>
        <FormItem label="Email" labelCol={{ sm: 6 }} wrapperCol={{ sm: 18 }}>
          {getFieldDecorator("email", {
            rules: [
              { required: true, message: "Please enter email" },
              { type: "email", message: "Invalid email address" }
            ]
          })(<Input placeholder="Email" type="email" />)}
        </FormItem>
        <FormItem label="Password" labelCol={{ sm: 6 }} wrapperCol={{ sm: 18 }}>
          {getFieldDecorator("password", {
            rules: [{ required: true, message: "Please enter password" }]
          })(
            <Input
              placeholder="Password"
              style={{ width: "86%", marginRight: 10 }}
            />
          )}
          <Button icon="reload" onClick={this.changePassword} />
        </FormItem>
        <FormItem wrapperCol={{ sm: 18, offset: 6 }}>
          <Button onClick={this.handleSubmit} loading={this.state.loading}>
            Add User
          </Button>
        </FormItem>
      </Form>
    );
  }
}

const NewUserForm = Form.create()(UserForm);

export default AddUserForm;
