import React, { Component } from "react";
import {
  Layout,
  Row,
  Col,
  Switch,
  Tooltip,
  Icon,
  Button,
  message,
  Spin
} from "antd";
import { api } from "../../../../config";

const styles = {
  rowStyle: {
    marginBottom: 15
  }
};

class SmsSettings extends Component {
  state = {
    settings: {
      sms: "0",
      smart_sms: "0",
      announcements_sms: "0",
      messages_sms: "0",
      fee_payment_sms: "0",
      attendance_sms: "0"
    },
    spinning: false,
    loading: false
  };

  componentDidMount() {
    this.getSettings();
  }

  getSettings = () => {
    this.setState({ spinning: true });
    api
      .get("/settings/sms/")
      .then(res => {
        if (res.data && res.data.success) {
          let data = res.data.data;
          if (Object.keys(data).length > 0) this.setState({ settings: data });
        } else {
          message.error(res.data.error);
        }
        this.setState({ spinning: false });
      })
      .catch(err => {
        console.log(err);
        this.setState({ spinning: false });
      });
  };

  handleStateChange = (name, val) => {
    let { settings } = this.state;
    settings[name] = val === true ? "1" : "0";
    this.setState({ settings });
  };

  updateSettings = () => {
    const data = this.state.settings;
    this.setState({ loading: true });
    api
      .post("/settings/sms/", JSON.stringify(data))
      .then(res => {
        if (res.data && res.data.success) {
          message.success("SMS Settings Updated");
        } else {
          message.error(res.data.error || "Failed to Update SMS Settings");
        }
        this.setState({ loading: false });
      })
      .catch(err => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  render() {
    const { settings } = this.state;
    return (
      <Layout.Content>
        <Row style={styles.rowStyle}>
          <Col sm={8}>
            <h1>SMS Settings</h1>
          </Col>
          <Col sm={4} offset={12}>
            <Button
              type="primary"
              onClick={this.updateSettings}
              loading={this.state.loading}
            >
              Save
            </Button>
          </Col>
        </Row>

        <Spin spinning={this.state.spinning}>
          <Row style={styles.rowStyle}>
            <Col sm={16}>
              <h3>Enable SMS</h3>
            </Col>
            <Col sm={8}>
              <Switch
                checked={settings.sms === "1" ? true : false}
                onChange={this.handleStateChange.bind(this, "sms")}
              />
            </Col>
          </Row>
          <Spin indicator={<span />} spinning={settings.sms === "0"}>
            <Row style={styles.rowStyle}>
              <Col sm={16}>
                <h3>
                  Enable Smart SMS{" "}
                  <Tooltip
                    title="Will send app notifications to people with android 
                phone app and text message to others"
                  >
                    <sup>
                      <Icon type="info-circle" />
                    </sup>
                  </Tooltip>
                </h3>
              </Col>
              <Col sm={8}>
                <Switch
                  onChange={this.handleStateChange.bind(this, "smart_sms")}
                  checked={settings.smart_sms === "1" ? true : false}
                />
              </Col>
            </Row>

            <Row style={styles.rowStyle}>
              <Col sm={16}>
                <h3>SMS Sent This Academic Year</h3>
              </Col>
              <Col sm={8}>
                <h3>{settings.sms_sent || 0}</h3>
              </Col>
            </Row>

            <Row style={styles.rowStyle}>
              <Col sm={16}>
                <h3>SMS Balance</h3>
              </Col>
              <Col sm={8}>
                <h3>{settings.sms_balance || 0}</h3>
              </Col>
            </Row>

            <Row style={styles.rowStyle}>
              <Col sm={16}>
                <h3>Send Announcements as SMS</h3>
              </Col>
              <Col>
                <Switch
                  onChange={this.handleStateChange.bind(
                    this,
                    "announcements_sms"
                  )}
                  checked={settings.announcements_sms === "1" ? true : false}
                />
              </Col>
            </Row>

            <Row style={styles.rowStyle}>
              <Col sm={16}>
                <h3>Send Messages as SMS</h3>
              </Col>
              <Col>
                <Switch
                  onChange={this.handleStateChange.bind(this, "messages_sms")}
                  checked={settings.messages_sms === "1" ? true : false}
                />
              </Col>
            </Row>

            <Row style={styles.rowStyle}>
              <Col sm={16}>
                <h3>Send SMS when fees is paid</h3>
              </Col>
              <Col>
                <Switch
                  onChange={this.handleStateChange.bind(
                    this,
                    "fee_payment_sms"
                  )}
                  checked={settings.fee_payment_sms === "1" ? true : false}
                />
              </Col>
            </Row>

            <Row style={styles.rowStyle}>
              <Col sm={16}>
                <h3>Send SMS when student is absent.</h3>
              </Col>
              <Col>
                <Switch
                  onChange={this.handleStateChange.bind(this, "attendance_sms")}
                  checked={settings.attendance_sms === "1" ? true : false}
                />
              </Col>
            </Row>
          </Spin>
        </Spin>
      </Layout.Content>
    );
  }
}

export default SmsSettings;
