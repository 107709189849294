import { Layout, message, Table, Icon, Popconfirm } from "antd";
import moment from "moment";
import React, { Component } from "react";
import { Currency } from "../../../../components";
import { api } from "../../../../config";

class AccountTransfer extends Component {
  state = {
    transactions: [],
    spinning: false,
  };

  componentDidMount() {
    this._get_account_transfers();
  }

  _get_account_transfers = () => {
    this.setState({ spinning: true });
    api
      .get("/finance/account/transfers/")
      .then((res) => {
        if (res.data.success) {
          this.setState({ transactions: res.data.data });
        } else {
          message.error(res.data.error);
        }
        this.setState({ spinning: false });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ spinning: true });
      });
  };

  _delete_entry = (r) => {
    if (r.type === "credit") {
      this._delete_income(r);
    } else {
      this._delete_expense(r);
    }
  };

  _delete_expense = (r) => {
    this.setState({ spinning: true });
    api
      .delete(`/finance/expense/${r.id}/`)
      .then((res) => {
        if (res.data && res.data.success) {
          message.success(
            "Expense deleted, please remove this from deposit as well"
          );
          this._get_account_transfers();
        } else {
          message.error(res.data.error);
        }
        this.setState({ spinning: false });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ spinning: false });
      });
  };

  _delete_income = (r) => {
    this.setState({ spinning: true });
    api
      .delete(`/finance/deposit/${r.id}/`)
      .then((res) => {
        if (res.data.success) {
          message.success(
            "Deposit deleted, please remove this from expenses as well"
          );
          this._get_account_transfers();
        } else {
          message.error(res.data.error || "Something went wrong!");
        }
        this.setState({ spinning: false });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ spinning: false });
      });
  };

  render() {
    const { transactions, spinning } = this.state;
    const columns = [
      { title: "Sl. No.", render: (v, r, i) => <span>{i + 1}</span> },
      { title: "Name", dataIndex: "name" },
      { title: "Account", dataIndex: "account" },
      {
        title: "Amount",
        dataIndex: "amount",
        align: "right",
        render: (v) => <Currency value={v} />,
      },
      { title: "Txn. Type", dataIndex: "type" },
      {
        title: "Date",
        dataIndex: "date",
        render: (v) => (
          <span>{moment.utc(v).local().format("YYYY-MM-DD hh:mm A")}</span>
        ),
      },
      {
        title: "Actions",
        render: (r) => (
          <span>
            <Popconfirm
              title="Are you sure?"
              onConfirm={this._delete_entry.bind(this, r)}
            >
              <Icon type="delete" className="action-icon absent" />
            </Popconfirm>
          </span>
        ),
      },
    ];
    return (
      <Layout.Content>
        <h1>Internal Account Transfers</h1>
        <Table columns={columns} dataSource={transactions} loading={spinning} />
      </Layout.Content>
    );
  }
}

export default AccountTransfer;
