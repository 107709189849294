import React, { Component } from "react";
import {
  Layout,
  Form,
  Input,
  Radio,
  message,
  Select,
  Button,
  Upload,
} from "antd";
import { connect } from "react-redux";
import { api, baseurl, getToken } from "../../../config";

class NewAnnouncements extends Component {
  state = {
    send_to: 0,
    classes_list: [],
    message_length: 0,
    loading: false,
  };

  componentDidMount() {
    this.getClassList();
  }

  getClassList = () => {
    api
      .get("/class/")
      .then((res) => {
        if (res.data && res.data.success) {
          this.setState({ classes_list: res.data.data });
        } else {
          message.error("Failed to fetch classes list");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleClick = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        val.files =
          val.files &&
          val.files.map((item) => {
            if (item.response) {
              return {
                path: item.response.path,
                name: item.name,
                url: baseurl + "/" + item.response.path,
              };
            }
            return item;
          });
        this.setState({ loading: true });
        api
          .put("/announcements/", JSON.stringify(val))
          .then((res) => {
            if (res.data && res.data.success) {
              message.success("New Announcement Made");
              const role = this.props.user.role;
              if (role === 2) {
                this.props.history.push("/announcements/");
              } else if (role === 3 || role === 4) {
                this.props.history.push("/announcements/");
              }
            } else {
              message.error(res.data.error);
            }
            this.setState({ loading: false });
          })
          .catch((err) => {
            console.log(err);
            this.setState({ loading: false });
          });
      }
    });
  };

  handleMessageChange = (e) => {
    let msg = e.target.value;
    this.setState({ message_length: msg.length });
  };

  normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  render() {
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 },
      },
    };
    const FormItem = Form.Item;
    const Option = Select.Option;
    const { getFieldDecorator } = this.props.form;
    const { message_length } = this.state;
    return (
      <Layout.Content>
        <h1 style={{ textAlign: "center" }}>Make New Announcement</h1>
        <Form {...formItemLayout}>
          <FormItem label="To">
            {getFieldDecorator("to", {
              rules: [{ required: true, message: "To is required" }],
            })(
              <Radio.Group
                onChange={(e) => {
                  this.setState({ send_to: e.target.value });
                }}
              >
                <Radio value="0">Everyone</Radio>
                <Radio value="1">All Students</Radio>
                <Radio value="2">Teachers</Radio>
                <Radio value="5">Class</Radio>
              </Radio.Group>
            )}
          </FormItem>
          {this.state.send_to === "5" && (
            <FormItem label="Class">
              {getFieldDecorator("class_ids", {
                rules: [
                  {
                    required: true,
                    message: "Please select atleast one class",
                  },
                ],
              })(
                <Select mode="multiple">
                  {this.state.classes_list.map((item, index) => {
                    return (
                      <Option value={item.id} key={index}>
                        {item.class + item.section}
                      </Option>
                    );
                  })}
                </Select>
              )}
            </FormItem>
          )}
          <FormItem label="Title">
            {getFieldDecorator("title", {
              rules: [{ required: true, message: "Title is required" }],
            })(<Input />)}
          </FormItem>
          <FormItem label="Message">
            {getFieldDecorator("message", {
              rules: [{ required: true, message: "Message is required" }],
            })(<Input.TextArea onChange={this.handleMessageChange} rows={6} />)}
            <span style={{ color: message_length > 159 ? "red" : "black" }}>
              Characters: {message_length}({Math.ceil(message_length / 160)}{" "}
              messages)
            </span>
          </FormItem>

          <FormItem label="Attachments">
            {getFieldDecorator("files", {
              valuePropName: "fileList",
              getValueFromEvent: this.normFile,
            })(
              <Upload
                name="file"
                action={baseurl + "/upload/"}
                headers={{ Authorization: getToken() }}
                accept=".jpg,.jpeg,.png,.pdf,.doc,.docx,.ppt,.pptx,.odt,.odp,.txt,.rtf"
              >
                <Button icon="upload">Upload</Button>
              </Upload>
            )}
          </FormItem>
          <FormItem wrapperCol={{ offset: 6 }}>
            <Button
              type="primary"
              onClick={this.handleClick}
              loading={this.state.loading}
            >
              Send
            </Button>
          </FormItem>
        </Form>
      </Layout.Content>
    );
  }
}

function mapStatesToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStatesToProps, null)(Form.create()(NewAnnouncements));
