import { Button, Icon, Layout, Select, Table, Typography, message } from "antd";
import React, { Component } from "react";
import { AcademicYearDropdown, Currency } from "../../../../components";
import { api } from "../../../../config";

const { Option } = Select;

export default class MultiClassFeesReport extends Component {
  state = {
    ac_year_id: undefined,
    classes_list: [],
    loading: false,
    students: [],
  };

  componentDidMount() {
    this.getClassList();
  }

  getClassList = () => {
    api
      .get("/class/")
      .then((res) => {
        if (res.data && res.data.success) {
          this.setState({ classes_list: res.data.data });
        } else {
          message.error("Failed to fetch classes list");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  fetchFeesInfo = async () => {
    try {
      const { ac_year_id, selected_classes } = this.state;
      if (selected_classes.length === 0) {
        message.error("Atlease one class must be selected!");
        return;
      }
      this.setState({ loading: true });
      let students = [];
      for (let i = 0; i < selected_classes.length; i++) {
        let { data } = await api.get(
          `/reports/fees/${selected_classes[i]}/${ac_year_id}/`
        );
        if (data && data.success) {
          students = students.concat(data.data);
        } else {
          message.error(
            `Failed to fetch student fees from class_id ${selected_classes[i]}`
          );
        }
      }
      this.setState({ students });
    } catch (err) {
    } finally {
      this.setState({ loading: false });
    }
  };

  exportFeesRecords = () => {
    let { students, class_id } = this.state;

    var csvContent = "data:text/csv;charset=utf-8,";
    let header =
      "ID,Student Name,Class ID,Admission No,Paid Amount,Total Amount,Due Amount\r\n";
    csvContent += header;
    students.forEach((item) => {
      let line = `${item.id},${item.fullname},${item.class_id || " - "},${
        item.admission_no
      }, ${item.paid_amount}, ${item.total_amount}, ${
        item.total_amount - item.paid_amount
      }\r\n`;
      csvContent += line;
    });
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `fees_records_class_${class_id}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  render() {
    const columns = [
      { title: "Admn. No", dataIndex: "admission_no" },
      { title: "Name", dataIndex: "fullname" },
      {
        title: "Total Amount",
        dataIndex: "total_amount",
        align: "right",
        render: (v) => <Currency value={v} />,
      },
      {
        title: "Amount Paid",
        dataIndex: "paid_amount",
        align: "right",
        render: (v) => <Currency value={v} />,
      },
      {
        title: "Due Amount",
        align: "right",
        render: (record) => (
          <span>
            <Currency value={record.total_amount - record.paid_amount} />
          </span>
        ),
        sorter: (a, b) =>
          a.total_amount - a.paid_amount - (b.total_amount - b.paid_amount),
      },
      {
        title: "Actions",
        render: (record) => (
          <span>
            <Icon
              type="dollar"
              className="action-icon"
              onClick={() => {
                this.props.history.push(
                  "/fees/records/" + (record.student_id || record.id)
                );
              }}
            />
          </span>
        ),
      },
    ];
    return (
      <Layout.Content>
        <Typography.Title level={3}>Multi Class Fees Reports</Typography.Title>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex", gap: 8 }}>
            <div>
              <strong>AC Year : </strong>
              <AcademicYearDropdown
                style={{ width: 150 }}
                onChange={(val) => this.setState({ ac_year_id: val })}
                value={this.state.ac_year_id}
              />
            </div>
            <div>
              <span>Classes List : </span>
              <Select
                mode="multiple"
                onChange={(val) => {
                  this.setState({ selected_classes: val });
                }}
                style={{ minWidth: 240 }}
              >
                {this.state.classes_list.map((item, index) => {
                  return (
                    <Option value={item.id} key={index}>
                      {item.class + item.section}
                    </Option>
                  );
                })}
              </Select>
              <Button onClick={this.fetchFeesInfo} style={{ marginLeft: 12 }}>
                Get
              </Button>
            </div>
          </div>
          <Button icon="export" type="primary" onClick={this.exportFeesRecords}>
            CSV
          </Button>
        </div>

        <Table
          columns={columns}
          dataSource={this.state.students}
          rowKey={"id"}
        />
      </Layout.Content>
    );
  }
}
