import React, { Component } from "react";
import {
  Layout,
  Table,
  Row,
  Col,
  Button,
  message,
  Icon,
  Spin,
  Popconfirm,
} from "antd";
import { Link } from "react-router-dom";
import { ShowBreadcrumbs, ClassSectionDropdown } from "../../../../components";
import { api } from "../../../../config";

let state = {
  class_id: null,
  subjects: [],
  spinning: false,
};

class SubjectsList extends Component {
  state = state;

  componentWillUnmount() {
    state = this.state;
  }

  componentDidMount() {
    if (this.state.class_id) {
      this.getSubjects(this.state.class_id);
    }
  }

  handleClassChange = (id) => {
    this.setState({ class_id: id });
    this.getSubjects(id);
  };

  getSubjects = (id) => {
    this.setState({ spinning: true });
    api
      .get("/subjects/" + id + "/")
      .then((res) => {
        if (res.data && res.data.success) {
          this.setState({ subjects: res.data.data });
        } else {
          message.error(res.data.error);
        }
        this.setState({ spinning: false });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ spinning: false });
      });
  };

  handleDelete = (id) => {
    api
      .delete("/subject/" + id + "/")
      .then((res) => {
        if (res.data.success) {
          message.success("Subject Deleted");
          this.getSubjects(this.state.class_id);
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const columns = [
      {
        title: "Class",
        dataIndex: "class",
        key: "class",
      },
      {
        title: "Section",
        dataIndex: "section",
        key: "section",
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Code",
        dataIndex: "code",
        key: "code",
      },
      {
        title: "Teacher",
        dataIndex: "teacher_name",
        key: "teacher_name",
      },
      {
        title: "Actions",
        render: (record) => (
          <span>
            <Icon
              type="edit"
              className="action-icon"
              onClick={() => {
                this.props.history.push("/academics/subjects/" + record.id);
              }}
            />
            <Popconfirm
              title="Are you sure?"
              onConfirm={this.handleDelete.bind(this, record.id)}
            >
              <Icon type="delete" className="action-icon absent" />
            </Popconfirm>
          </span>
        ),
      },
    ];
    return (
      <Layout.Content>
        <ShowBreadcrumbs
          items={[
            { name: "Home", link: "/" },
            { name: "Academics", link: "/academics/" },
            { name: "Subjects", link: "/academics/subjects/" },
          ]}
        />
        <Row className="ribbon-row">
          <Col sm={8}>
            <h1>Subjects</h1>
          </Col>
          <Col sm={8}>
            <span>
              <strong>Class : </strong>
            </span>
            <ClassSectionDropdown
              value={this.state.class_id}
              onChange={this.handleClassChange}
            />
          </Col>
          <Col sm={8} style={{ textAlign: "right" }}>
            <Link to="/academics/subjects/new">
              <Button type="primary" icon="plus" className="action-button">
                Add Subject
              </Button>
            </Link>
          </Col>
        </Row>
        <Spin spinning={this.state.spinning}>
          <Table
            columns={columns}
            dataSource={this.state.subjects}
            rowKey="id"
          />
        </Spin>
      </Layout.Content>
    );
  }
}

export default SubjectsList;
