import React, { Component } from "react";
import { Layout, Row, Col, Button, message, Table, Icon } from "antd";
import {
  ClassSectionDropdown,
  SubjectSelectionDropdown,
  // OnlineTestQuestionTopicDropdown,
} from "../../../components";
import { api } from "../../../config";

let state = {
  questions: [],
  class_id: undefined,
  subject_id: undefined,
  topic: undefined,
};

class Questions extends Component {
  state = state;

  componentWillUnmount() {
    state = this.state;
  }

  componentDidMount() {
    this.get_questions_list();
  }

  get_questions_list = () => {
    let { class_id, subject_id } = this.state;
    if (!(class_id && subject_id)) {
      return;
    }
    let url = "/ot/questions/" + class_id + "/" + subject_id + "/";
    api
      .get(url)
      .then((res) => {
        if (res.data.success) {
          this.setState({ questions: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {});
  };

  render() {
    const { class_id, subject_id, topic, questions } = this.state;
    const columns = [
      { title: "ID", dataIndex: "id" },
      { title: "Topic", dataIndex: "topic" },
      { title: "Question", dataIndex: "question" },
      {
        title: "Type",
        dataIndex: "type",
        render: (v, r) => {
          switch (v) {
            case 0:
              return <span>MCQ (Single Correct)</span>;
            case 1:
              return <span>MCQ (Multiple correct)</span>;
            case 2:
              return <span>Subjective</span>;
            case 3:
              return <span>Media</span>;
            default:
              return <span>Unknown</span>;
          }
        },
      },
      {
        title: "Actions",
        render: (r) => (
          <span>
            <Icon
              type="profile"
              className="action-icon"
              onClick={() =>
                this.props.history.push(`/online-test/question/${r.id}/`)
              }
            />
          </span>
        ),
      },
    ];
    return (
      <Layout.Content>
        <Row gutter={16}>
          <Col sm={4}>
            <h1>Questions</h1>
          </Col>
          <Col sm={16}>
            <span>
              <strong>Class : </strong>
              <ClassSectionDropdown
                value={class_id}
                onChange={(class_id) => this.setState({ class_id })}
              />
            </span>
            <span style={{ marginRight: 10 }}>
              <strong>Subject : </strong>
              <SubjectSelectionDropdown
                class={class_id}
                value={subject_id}
                onChange={(subject_id) =>
                  this.setState({ subject_id }, this.get_questions_list)
                }
              />
            </span>
            {/* <span style={{ marginRight: 10 }}>
              <strong>Topic : </strong>
              <OnlineTestQuestionTopicDropdown
                value={topic}
                onChange={(val) => this.setState({ topic: val })}
              />
            </span> */}
          </Col>
          <Col sm={4} style={{ textAlign: "right" }}>
            <Button
              type="primary"
              icon="plus"
              onClick={() => {
                this.props.history.push({
                  pathname: "/online-test/question/new",
                  state: { class_id, subject_id, topic },
                });
              }}
            >
              Add New Question
            </Button>
          </Col>
        </Row>
        <Table columns={columns} dataSource={questions} rowKey="id" />
      </Layout.Content>
    );
  }
}

export default Questions;
