import React, { Component } from "react";
import { Layout, Row, Col, Button, Table, message } from "antd";
import { api } from "../../../../config";
import { AcademicYearDropdown, Currency } from "../../../../components";

class FeesList extends Component {
  state = {
    fees_list: [],
    ac_year_id: undefined,
  };

  componentDidMount() {}

  get_fees_list = (ac_year_id) => {
    api
      .get(`/fees/create/${ac_year_id}/`)
      .then((res) => {
        if (res.data.success) {
          this.setState({ fees_list: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  __handle_ac_year_id_change = (val) => {
    this.setState({ ac_year_id: val });
    this.get_fees_list(val);
  };

  render() {
    const { ac_year_id } = this.state;
    const columns = [
      {
        title: "Fee Name",
        dataIndex: "name",
        render: (v, r) => (
          <Button
            type="link"
            onClick={() => {
              const params = new URLSearchParams();
              params.append("name", v);
              this.props.history.push({
                pathname: "/fees/create/reports/",
                search: params.toString(),
              });
            }}
          >
            {v || "Unknown/Others"}
          </Button>
        ),
      },
      { title: "Count", dataIndex: "count" },
      {
        title: "Total Amount",
        dataIndex: "total",
        align: "right",
        render: (v) => <Currency value={v} />,
      },
      {
        title: "Amount Received",
        dataIndex: "paid",
        align: "right",
        render: (v) => <Currency value={v} />,
      },
    ];
    return (
      <Layout.Content>
        <Row>
          <Col md={8}>
            <h1>Fees List</h1>
          </Col>
          <Col md={8}>
            <span>
              <strong>AC Year : </strong>
              <AcademicYearDropdown
                value={ac_year_id}
                onChange={this.__handle_ac_year_id_change}
              />
            </span>
          </Col>
          <Col md={8} style={{ textAlign: "right" }}>
            <Button
              type="primary"
              icon="plus"
              onClick={() => {
                this.props.history.push("/fees/create/new/");
              }}
            >
              Create Fees
            </Button>
          </Col>
        </Row>
        <Table
          columns={columns}
          dataSource={this.state.fees_list}
          pagination={{ pageSize: 50 }}
        />
      </Layout.Content>
    );
  }
}

export default FeesList;
