import React from "react";
import { Switch, Route } from "react-router-dom";
import StudentMarks from "./StudentMarks";

const StudentMarksRouter = props => (
  <Switch>
    <Route exact path="/marks" component={StudentMarks} />
  </Switch>
);

export { StudentMarksRouter };
