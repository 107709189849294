import React, { Component } from "react";
import {
  Layout,
  Table,
  Row,
  Col,
  Button,
  Modal,
  Form,
  Input,
  message,
  Icon,
  Popconfirm,
} from "antd";
import moment from "moment";
import { api } from "../../../config";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

class OnlineTestList extends Component {
  state = {
    newTestModalVisible: false,
    tests: [],
  };

  componentDidMount() {
    this.get_tests_list();
  }

  get_tests_list = () => {
    api
      .get("/ot/tests/")
      .then((res) => {
        if (res.data.success) {
          this.setState({ tests: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  add_new_test = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        api
          .put("/ot/tests/", JSON.stringify(val))
          .then((res) => {
            if (res.data.success) {
              message.success("New Test Created");
              this.setState({ newTestModalVisible: false });
              this.get_tests_list();
            } else {
              message.error(res.data.error);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  render() {
    const columns = [
      { title: "ID", dataIndex: "id" },
      {
        title: "Name",
        dataIndex: "name",
        render: (v, r) => (
          <span>
            <Link to={`/online-test/${r.id}/questions/`}>{v}</Link>
          </span>
        ),
      },
      { title: "Category", dataIndex: "category" },
      {
        title: "Created",
        dataIndex: "timestamp",
        render: (v) => (
          <span>{moment.utc(v).local().format("YYYY-MM-DD hh:mm A")}</span>
        ),
      },
      {
        title: "Action",
        render: (r) => (
          <span>
            <Icon
              type="ordered-list"
              className="action-icon"
              title="Test Assignment"
              onClick={() =>
                this.props.history.push(`/online-test/${r.id}/assign/`)
              }
            />
            <Popconfirm title="Are you sure?">
              <Icon
                type="delete"
                className="action-icon absent"
                title="Delete"
              />
            </Popconfirm>
          </span>
        ),
      },
    ];
    const { newTestModalVisible, tests } = this.state;
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    const { user } = this.props;
    return (
      <Layout.Content>
        <Row gutter={16}>
          <Col sm={12}>
            <h1>Online Tests</h1>
          </Col>
          <Col sm={12} className="action-buttons">
            {[3, 4].includes(user.role) && (
              <Button
                className="action-button"
                type="primary"
                icon="plus"
                onClick={() => this.setState({ newTestModalVisible: true })}
              >
                New Test
              </Button>
            )}
          </Col>
        </Row>
        <Table columns={columns} dataSource={tests} rowKey="id" />
        {newTestModalVisible && (
          <Modal
            title="New Online Test"
            visible={true}
            onCancel={() => this.setState({ newTestModalVisible: false })}
            onOk={this.add_new_test}
          >
            <Form>
              <FormItem label="Online Test Name">
                {getFieldDecorator("name", {
                  rules: [
                    { required: true, message: "Test name is requried!" },
                  ],
                })(<Input />)}
              </FormItem>
              <FormItem label="Test Category">
                {getFieldDecorator("category")(<Input />)}
              </FormItem>
            </Form>
          </Modal>
        )}
      </Layout.Content>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps, null)(Form.create()(OnlineTestList));
