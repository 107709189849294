import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Layout,
  Row,
  Select,
  Table,
  message,
} from "antd";
import React, { Component } from "react";
import { api } from "../../../config";
import moment from "moment";

const { Option } = Select;

export default class UpdateEmployeeAttendance extends Component {
  state = {
    employees: [],
    loading: false,
    date: moment(),
    submitting: false,
  };

  componentDidMount() {}

  setInitData = (data) => {
    let _data = data.map((item) => {
      return {
        ...item,
        present: item.present === null ? true : item.present,
      };
    });
    this.setState({ employees: _data });
  };

  getEmployeesList = () => {
    const { date } = this.state;
    if (!date) {
      message.error("Date is requried!");
      return;
    }
    this.setState({ loading: true });
    api
      .get(`/employee/attendance?date=${date.format("YYYY-MM-DD")}`)
      .then((res) => {
        if (res.data.success) {
          this.setInitData(res.data.data);
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {})
      .finally(() => this.setState({ loading: false }));
  };

  handlePresentToggle = (i, e) => {
    let { employees } = this.state;
    employees[i].present = e.target.checked;
    if (e.target.checked === true) {
      employees[i].leave_type = undefined;
    }
    this.setState({ employees });
  };

  handleLeaveUpdate = (i, val) => {
    let { employees } = this.state;
    employees[i].leave_type = val;
    this.setState({ employees });
  };

  handleUpdateAttendance = () => {
    let { employees, date } = this.state;
    let body = { date, data: employees };
    this.setState({ submitting: true });
    api
      .put("/employee/attendance", JSON.stringify(body))
      .then((res) => {
        if (res.data.success) {
          message.success("Employee attendance updated!");
          this.props.history.goBack();
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => message.error("Something went wrong!"))
      .finally(() => this.setState({ submitting: false }));
  };

  render() {
    const columns = [
      { title: "Employee ID", dataIndex: "emp_id" },
      { title: "Employee Name", dataIndex: "name" },
      {
        title: "Present",
        render: (v, r, i) => (
          <Checkbox
            checked={r.present}
            onChange={this.handlePresentToggle.bind(this, i)}
            disabled={r.id}
          />
        ),
      },
      {
        title: "Leave Type",
        render: (v, r, i) => (
          <Select
            style={{ minWidth: 200 }}
            onChange={this.handleLeaveUpdate.bind(this, i)}
            disabled={r.present || r.id}
          >
            <Option value={1}>Casual Leave</Option>
            <Option value={2}>Sick Leave</Option>
            <Option value={-1}>Loss of Pay</Option>
            <Option value={4}>Others (no loss of pay)</Option>
          </Select>
        ),
      },
    ];
    return (
      <Layout.Content>
        <Row>
          <Col md={12}>
            <h1>Update Employee Attendance</h1>
          </Col>
          <Col md={12} className="action-buttons">
            <Button type="primary" onClick={this.handleUpdateAttendance}>
              Update Attendance
            </Button>
          </Col>
        </Row>

        <div style={{ display: "flex", alignItems: "flex-end", gap: "8px" }}>
          <div>
            <p style={{ marginBottom: 0 }}>Date : </p>
            <DatePicker
              value={this.state.date}
              onChange={(v) => this.setState({ date: v })}
            />
          </div>
          <Button onClick={this.getEmployeesList}>Go</Button>
        </div>

        <Table
          columns={columns}
          dataSource={this.state.employees}
          rowKey={"emp_id"}
          pagination={false}
        />
      </Layout.Content>
    );
  }
}
