import React, { Component } from "react";
import { Select, Modal, message } from "antd";
import { api } from "../../../config";

/*
    Update delete endpoint, if TC then just remove student, 
    if deleted then remove student, attendance record, marks record etc.,
*/

class DeleteStudent extends Component {
  state = {
    loading: false,
    reason: 1,
  };

  deleteStudent = () => {
    let id = this.props.student.id;
    this.setState({ loading: true });
    api
      .delete("/student/" + id + "/" + this.state.reason + "/")
      .then((res) => {
        if (res.data && res.data.success) {
          message.success("Student Deleted");
          this.props.closeModal();
        } else {
          message.error(res.data.error);
        }
        this.setState({ loading: false });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  render() {
    const Option = Select.Option;
    return (
      <Modal
        visible={this.props.visible}
        title="Delete Student"
        okText="Delete"
        onOk={this.deleteStudent}
        onCancel={this.props.closeModal}
        confirmLoading={this.state.loading}
      >
        <h3>Reason:</h3>
        <Select
          style={{ width: "100%" }}
          defaultValue={1}
          onChange={(val) => {
            this.setState({ reason: val });
          }}
        >
          <Option value={1}>Duplicate/Incorrect Entry</Option>
          <Option value={2}>TC</Option>
        </Select>
      </Modal>
    );
  }
}

export default DeleteStudent;
