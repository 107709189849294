import React, { Component } from "react";
import {
  Card,
  Descriptions,
  Icon,
  message,
  Button,
  Row,
  Col,
  Table,
} from "antd";
import { api } from "../../../../config";
import moment from "moment";
import StudentPasswordReset from "./StudentPasswordReset";
import StudentUsernameReset from "./StudentUsernameReset";
import PrintStudentDetails from "../PrintStudentDetails";
import StudentAdmissionTypeUpdate from "./StudentAdmissionTypeUpdate";
import EditStudentProfileItem from "./EditStudentProfileItem";
import StudentPicture from "./StudentPicture";

class StudentProfileCard extends Component {
  state = {
    student_id: null,
    student: {},
    loading: false,
    password_reset_modal: false,
    username_reset_modal: false,
    academic_years: undefined,
    admission_type_modal: false,
    edit_profile_item: undefined,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.student_id !== prevState.id) {
      return { ...prevState, ...nextProps };
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.student_id !== this.state.student_id) {
      this.get_student_profile(this.state.student_id);
    }
  }

  get_student_profile = (id) => {
    this.setState({ loading: true });
    api
      .get("/student/" + id + "/")
      .then((res) => {
        if (res.data.success) {
          this.setState({
            student: res.data.data,
            academic_years: res.data.data.academic_years,
          });
        } else {
          message.error(res.data.error);
        }
        this.setState({ loading: false });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  editStudentDetails = (id) => {
    this.props.history.push("/students/" + id);
  };

  closeModal = () => {
    this.setState({
      password_reset_modal: false,
      username_reset_modal: false,
      admission_type_modal: false,
      edit_profile_item: undefined,
    });
    this.get_student_profile(this.state.student_id);
  };

  change_admission_type = () => {
    this.setState({ admission_type_modal: true });
  };

  capitalize = (text) => {
    return text[0].toLocaleUpperCase() + text.slice(1);
  };

  render() {
    const { Item } = Descriptions;
    const {
      student,
      password_reset_modal,
      username_reset_modal,
      academic_years,
      admission_type_modal,
      edit_profile_item,
    } = this.state;
    const columns = [
      { title: "ID", dataIndex: "id" },
      { title: "AC Year", dataIndex: "ac_year" },
      {
        title: "Class",
        render: (r) => <span>{`${r.class} ${r.section}`}</span>,
      },
      { title: "Adm. Type", dataIndex: "admission_type" },
    ];
    return (
      <Row gutter={16} type="flex">
        <Col xl={6} md={24}>
          <StudentPicture
            picture={student.profile_photo}
            student_id={student.id}
          />
        </Col>
        <Col xl={12} md={24} className="card-row">
          <Card
            title="Student Info"
            style={{ marginBottom: 20 }}
            extra={[
              <Icon
                type="printer"
                onClick={() => window.print()}
                style={{ marginRight: 8 }}
              />,
              <Icon
                type="edit"
                onClick={this.editStudentDetails.bind(this, student.id)}
              />,
            ]}
            loading={this.state.loading}
            className="admin-card card-elevation"
          >
            <Descriptions
              column={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 2, xxl: 3 }}
            >
              <Item label="Student Name">{student.fullname}</Item>
              <Item label="Admission No">{student.admission_no}</Item>
              <Item label="Admission Date">
                {moment(student.admission_date).format("YYYY-MM-DD")}
              </Item>
              <Item label="Date of Birth">
                {moment(student.dob).format("YYYY-MM-DD")}
              </Item>
              <Item label="Mobile">{student.mobile}</Item>
              <Item label="Class">{student.class + " " + student.section}</Item>
              <Item label="Roll No">{student.roll_no}</Item>
              <Item label="Username (for login)">
                {student.username || "-"}{" "}
                <Icon
                  type="edit"
                  title="Change username"
                  onClick={() => this.setState({ username_reset_modal: true })}
                />
              </Item>
              <Item label="Email">
                {student.email || "-"}{" "}
                <Icon
                  type="edit"
                  title="Change email"
                  style={{ cursor: "not-allowed" }}
                />
              </Item>
              <Item label="SATS/USN">{student.usn || "-"}</Item>
              {["religion", "caste", "gender", "blood_group", "quota"].map(
                (item) => {
                  return (
                    <Item label={this.capitalize(item)}>
                      {student[item] || " - "}{" "}
                      <Icon
                        type="edit"
                        onClick={() =>
                          this.setState({
                            edit_profile_item: {
                              name: item,
                              value: student[item],
                            },
                          })
                        }
                      />
                    </Item>
                  );
                }
              )}
              <Item label="Aadhar No">{student.aadhar_no || "-"}</Item>
              {student.extras &&
                Object.keys(student.extras).map((item, index) => {
                  let label = item
                    .split("_")
                    .map((item) => {
                      return item[0].toLocaleUpperCase() + item.slice(1);
                    })
                    .join(" ");
                  return (
                    <Item label={label} key={index}>
                      {student.extras[item]}
                    </Item>
                  );
                })}
              <Item label="Password">
                <Button
                  size="small"
                  type="danger"
                  ghost
                  onClick={() => {
                    this.setState({ password_reset_modal: true });
                  }}
                >
                  Reset Password
                </Button>
              </Item>
              {academic_years &&
                Array.isArray(academic_years) &&
                academic_years.length > 0 && (
                  <Item label="Admission Type">
                    {academic_years[academic_years.length - 1].admission_type ||
                      " - "}
                    <Icon
                      size="small"
                      type="edit"
                      onClick={this.change_admission_type}
                    />
                  </Item>
                )}
            </Descriptions>
          </Card>
        </Col>
        <Col xl={6} md={24} className="card-row">
          <Card className="admin-card card-elevation">
            {academic_years && (
              <>
                <h4>Academic Years</h4>
                <Table
                  columns={columns}
                  dataSource={academic_years}
                  size="small"
                  rowKey="aym_id"
                  pagination={false}
                />
              </>
            )}
          </Card>
        </Col>

        {password_reset_modal && (
          <StudentPasswordReset
            student={student}
            closeModal={this.closeModal}
          />
        )}
        {username_reset_modal && (
          <StudentUsernameReset
            student={student}
            closeModal={this.closeModal}
          />
        )}
        {admission_type_modal && (
          <StudentAdmissionTypeUpdate
            visible={admission_type_modal}
            closeModal={this.closeModal}
            aym_id={academic_years[academic_years.length - 1].aym_id}
          />
        )}
        {edit_profile_item && (
          <EditStudentProfileItem
            close={this.closeModal}
            student_id={student.id}
            item={edit_profile_item}
          />
        )}
        <PrintStudentDetails student={student} />
      </Row>
    );
  }
}

export default StudentProfileCard;
