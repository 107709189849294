import {
  Avatar,
  Button,
  Icon,
  Layout,
  Select,
  Table,
  Tag,
  message,
} from "antd";
import React, { Component } from "react";
import { api, baseurl } from "../../../config";
import { AcademicYearDropdown } from "../../../components";
import { Link } from "react-router-dom";
import moment from "moment";
import { connect } from "react-redux";

const { Option } = Select;

class MultiClassReport extends Component {
  state = {
    classes_list: [],
    selected_classes: [],
    students: [],
    ac_year_id: undefined,
    loading: false,
    filters: { status: ["1"] },
  };

  componentDidMount() {
    this.getClassList();
  }

  getClassList = () => {
    api
      .get("/class/")
      .then((res) => {
        if (res.data && res.data.success) {
          this.setState({ classes_list: res.data.data });
        } else {
          message.error("Failed to fetch classes list");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  fetchStudentsList = async () => {
    try {
      const { ac_year_id, selected_classes } = this.state;
      if (selected_classes.length === 0) {
        message.error("Atlease one class must be selected!");
        return;
      }
      this.setState({ loading: true, students: [] });
      let students = [];
      for (let i = 0; i < selected_classes.length; i++) {
        let { data } = await api.get(
          `/students-list/${selected_classes[i]}/${ac_year_id}/`
        );
        if (data && data.success) {
          students = students.concat(data.data);
        } else {
          message.error(
            `Failed to fetch students from class_id ${selected_classes[i]}`
          );
        }
      }
      this.setState({ students });
    } catch (err) {
    } finally {
      this.setState({ loading: false });
    }
  };

  onTableChange = (pagination, filters, sorters) => {
    this.setState({ filters });
  };

  get_status = (r) => {
    if (r.status === 2) {
      return <Tag color="volcano">TC</Tag>;
    }
    if (!r.acy_status && r.status === 3) {
      return <Tag color="geekblue">Graduated</Tag>;
    } else if (!r.acy_status && r.status === 0) {
      return <Tag color="orange">Year Break</Tag>;
    } else if (!r.acy_status && r.status === 1) {
      return <Tag color="green">Active</Tag>;
    }
    switch (r.acy_status) {
      case -1:
        return <Tag color="red">Detained</Tag>;
      case 0:
        return <Tag color="orange">Year Break</Tag>;
      case 1:
        return <Tag color="green">Current</Tag>;
      case 2:
        return <Tag color="cyan">Promoted</Tag>;
      case 3:
        return <Tag color="geekblue">Graduated</Tag>;
      case 4:
        return <Tag color="purple">Shuffled</Tag>;
      default:
        return <Tag>Unknown</Tag>;
    }
  };

  exportStudentsList = () => {
    let { students } = this.state;
    var csvContent = "data:text/csv;charset=utf-8,";
    let header =
      "ID,Student Name,ClassID,DoB,Mobile,Admission No,Admission Date,Roll No,Admission Type,Quota,Religion,Caste,Address\r\n";
    csvContent += header;
    students.forEach((item) => {
      if (item.acy_status !== 1) {
        return;
      }
      let address = item.extras.address || " - ";
      address = address.replace("#", "");
      let line = `"${item.id}","${item.fullname}","${item.class_id}","${moment
        .utc(item.dob)
        .local()
        .format("YYYY-MM-DD")}","${item.mobile}","${
        item.admission_no
      }","${moment.utc(item.admission_date).local().format("YYYY-MM-DD")}","${
        item.roll_no
      }","${item.admission_type}","${item.quota}","${item.religion}","${
        item.caste
      }","${address}"\r\n`;
      csvContent += line;
    });
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `students_list.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  render() {
    let filters = this.state.filters || {};

    const columns = [
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
        render: (v, r, i) => <span>{i + 1}</span>,
      },
      {
        title: "Avatar",
        dataIndex: "profile_photo",
        render: (v) =>
          v ? <Avatar src={`${baseurl}/${v}`} /> : <Avatar icon="user" />,
      },
      {
        title: "Name",
        dataIndex: "fullname",
        key: "fullname",
        sorter: (a, b) =>
          a.fullname.toLowerCase() < b.fullname.toLowerCase() ? -1 : 1,
        render: (v, r) => (
          <span>
            <Link to={"/students/details/" + r.id}>
              {v} {r.class && `(${r.class}${r.section})`}
            </Link>
          </span>
        ),
      },
      {
        title: "Mobile",
        dataIndex: "mobile",
        key: "mobile",
      },
      {
        title: "Admission No",
        dataIndex: "admission_no",
        key: "admission_no",
      },
      {
        title: "Admission Date",
        dataIndex: "admission_date",
        render: (v) => (
          <span>{moment.utc(v).local().format("YYYY-MM-DD")}</span>
        ),
      },
      {
        title: "Roll No",
        dataIndex: "roll_no",
        key: "roll_no",
      },
      {
        title: "SATS/USN",
        dataIndex: "usn",
      },
      {
        title: "Status",
        dataIndex: "status",
        filters: [
          { text: "Current", value: "1" },
          { text: "Promoted", value: "2" },
          { text: "Graduated", value: "3" },
          { text: "Shuffled", value: "4" },
        ],
        onFilter: (v, r) => {
          return r.acy_status === parseInt(v, 10);
        },
        filteredValue: filters.status,
        // filtered: true,
        render: (v, r) => {
          let status = this.get_status(r);
          let at = r.admission_type;
          return (
            <span>
              {status}
              {at && <Tag color={at === "NEW" ? "red" : "blue"}>{at}</Tag>}
              {r.quota && <Tag color="volcano">{r.quota}</Tag>}
            </span>
          );
        },
      },
      {
        title: "Actions",
        render: (record) => (
          <span>
            {/* <Icon
                type="edit"
                title="Edit Student Attendance"
                className="action-icon"
                onClick={() => {
                  let url = "";
                  if (this.props.user.role === 2) {
                    url = "/students/edit/" + record.id + "/";
                  } else {
                    url = "/students/edit/" + record.id + "/";
                  }
                  this.props.history.push(url);
                }}
              /> */}
            {[3, 4].includes(this.props.user.role) && (
              <Icon
                type="form"
                title="Edit Student Profile"
                className="action-icon"
                onClick={() => {
                  let url = "/students/" + record.id + "/";
                  this.props.history.push(url);
                }}
              />
            )}
            {[3, 4].includes(this.props.user.role) && !record.user_id && (
              <Icon
                type="link"
                title="Link User to Student"
                className="action-icon"
                onClick={() => {
                  let url = "/students/link/" + record.id + "/";
                  this.props.history.push(url);
                }}
              />
            )}
            <Icon
              type="dollar"
              className="action-icon"
              onClick={() => {
                this.props.history.push("/fees/records/" + record.id + "/");
              }}
            />
            <Icon
              type="message"
              className="action-icon"
              onClick={() => {
                this.setState({ messageModal: true, student: record });
              }}
            />
            {[3, 4].includes(this.props.user.role) && (
              <Icon
                type="delete"
                className="action-icon absent"
                onClick={() => {
                  this.setState({ deleteModal: true, student: record });
                }}
              />
            )}
          </span>
        ),
      },
    ];
    return (
      <Layout.Content>
        <h1>Multi Class Reports</h1>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex", gap: 8 }}>
            <div>
              <strong>AC Year : </strong>
              <AcademicYearDropdown
                style={{ width: 150 }}
                onChange={(val) => this.setState({ ac_year_id: val })}
                value={this.state.ac_year_id}
              />
            </div>
            <div>
              <span>Classes List : </span>
              <Select
                mode="multiple"
                onChange={(val) => {
                  this.setState({ selected_classes: val });
                }}
                style={{ minWidth: 240 }}
              >
                {this.state.classes_list.map((item, index) => {
                  return (
                    <Option value={item.id} key={index}>
                      {item.class + item.section}
                    </Option>
                  );
                })}
              </Select>
              <Button
                onClick={this.fetchStudentsList}
                style={{ marginLeft: 12 }}
              >
                Get
              </Button>
            </div>
          </div>
          <Button
            icon="export"
            type="primary"
            onClick={this.exportStudentsList}
          >
            CSV
          </Button>
        </div>

        <Table
          columns={columns}
          dataSource={this.state.students}
          rowKey={"id"}
          pagination={{ defaultPageSize: 50 }}
          onChange={this.onTableChange}
        />
      </Layout.Content>
    );
  }
}

function mapStateToProps(state) {
  return {
    class: state.class,
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    get_classes: (payload) => {
      dispatch({ type: "GET_CLASSES", payload });
    },
    get_students: (payload) => {
      dispatch({ type: "GET_STUDENTS", payload });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MultiClassReport);
