import React, { Component } from "react";
import { Layout, Row, Col, message } from "antd";
import AddUserForm from "./AddUser";
import { api } from "../../../config";

class StudentLinkUser extends Component {
  state = {
    student_id: null
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      this.setState({ student_id: this.props.match.params.id });
    }
  }

  handleStudentLinking = user_id => {
    let data = { user_id, student_id: this.state.student_id };
    api
      .post("/user/link/student/", JSON.stringify(data))
      .then(res => {
        if (res.data && res.data.success) {
          message.success("User Linked to Student");
          this.props.history.push("/students/");
        } else {
          message.error(res.data.error);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  render() {
    return (
      <Layout.Content>
        <Row>
          <Col sm={20} offset={2} style={{ marginTop: 40 }}>
            <AddUserForm
              {...this.props}
              link_user={true}
              nextScreen={this.handleStudentLinking}
            />
          </Col>
        </Row>
      </Layout.Content>
    );
  }
}

export default StudentLinkUser;
