import {
  Button,
  Card,
  Col,
  DatePicker,
  Descriptions,
  Form,
  Input,
  InputNumber,
  Layout,
  message,
  Row,
  Table,
  Icon,
} from "antd";
import React, { Component } from "react";
import moment from "moment";
import { Currency } from "../../../../components";
import { api } from "../../../../config";
import {
  AccountsDropdown,
  PaymentsModeDropdown,
  ExpensesCategoryDropdown,
} from "../../../../components";
import PrintPaySlip from "./PrintPaySlip";

class PayrollViewer extends Component {
  state = {
    id: undefined,
    payroll: {},
    payslip_items: [],
    installments: [],
    loading: false,
    emp_salary: {},
    payslip_total: 0.0,
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      this.setState({ id: this.props.match.params.id });
      this._get_payroll_entry(this.props.match.params.id);
    }
  }

  _get_payroll_entry = (id) => {
    api
      .get(`/simple/payroll/${id}/`)
      .then((res) => {
        if (res.data.success) {
          let data = res.data.data;
          let extras = JSON.parse(data.extras);
          let payslip = extras.payslip || [];
          let total = 0.0;
          payslip.forEach((item) => {
            total += item.type === 0 ? item.value : -1 * item.value;
          });
          this.setState({
            payroll: data,
            payslip_items: payslip,
            installments: data.installments,
            payslip_total: total,
          });
          this._get_employee_salary(data.employee_id);
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  _get_employee_salary = (emp_id) => {
    api
      .get(`/simple/payroll/employee/${emp_id}/salary/`)
      .then((res) => {
        if (res.data.success) {
          this.setState({ emp_salary: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  _handleInstallmentPayment = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        let { payroll, id } = this.state;
        if (val.amount_paid > payroll.salary_amount - payroll.amount_paid) {
          message.error("Amount paying cannot be greater than pending amount!");
          return;
        }
        this.setState({ loading: true });
        api
          .put("/simple/payroll/installment/", JSON.stringify(val))
          .then((res) => {
            if (res.data.success) {
              message.success("Installment Paid");
              this._get_payroll_entry(id);
            } else {
              message.error(res.data.error);
            }
            this.setState({ loading: false });
          })
          .catch((err) => {
            console.log(err);
            this.setState({ loading: false });
          });
      }
    });
  };

  render() {
    const { Item } = Descriptions;
    const {
      payroll,
      payslip_items,
      installments,
      id,
      loading,
      payslip_total,
      emp_salary,
    } = this.state;
    const columns = [
      { title: "ID", dataIndex: "id" },
      {
        title: "Amount",
        dataIndex: "amount",
        render: (v) => <Currency value={v} />,
      },
      { title: "Mode", dataIndex: "payment_mode" },
      {
        title: "Date",
        dataIndex: "date",
        render: (v) => (
          <span>{moment.utc(v).local().format("YYYY-MM-DD")}</span>
        ),
      },
    ];
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    return (
      <Layout.Content>
        <h1>Employee Payroll</h1>
        <Card title="Details" style={{ marginBottom: 16 }}>
          <Descriptions>
            <Item label="Employee Name">{payroll.emp_name}</Item>
            <Item label="Total Days">{payroll.total_days}</Item>
            <Item label="Absent Days">
              {payroll.total_days - payroll.present_days}
            </Item>
            <Item label="Month and Year">
              {moment.utc(payroll.month_year).format("MMM YYYY")}
            </Item>
            <Item label="Payment Date">
              {moment
                .utc(payroll.payment_date)
                .local()
                .format("YYYY-MM-DD hh:mm A")}
            </Item>
            <Item></Item>
            <Item label="CTC">
              <Currency value={emp_salary.ctc} />
            </Item>
            <Item label="Salary Amount">
              <Currency value={payslip_total} />
            </Item>
            <Item label="Amount Paid">
              <Currency value={payroll.amount_paid} />
            </Item>
            <Item label="Amount Due">
              <Currency value={payslip_total - payroll.amount_paid} />
            </Item>
            <Item label="Total Balance">
              <Currency
                value={emp_salary.total_salary - emp_salary.total_paid}
              />
            </Item>
          </Descriptions>
        </Card>
        <Row gutter={16}>
          <Col sm={8}>
            {payslip_items && (
              <Card
                title="Payslip"
                className="payroll-table-parent"
                extra={<Icon type="printer" onClick={() => window.print()} />}
              >
                <div className="payroll-table">
                  <table>
                    <thead>
                      <tr>
                        {/* <th>Sl. No.</th> */}
                        <th style={{ maxWidth: "100%", width: 240 }}>Item</th>
                        <th>Debit</th>
                        <th>Credit</th>
                      </tr>
                    </thead>
                    <tbody>
                      {payslip_items.map((item, index) => {
                        return (
                          <tr key={index}>
                            {/* <td>{index + 1}</td> */}
                            <td>{item.item_name}</td>
                            <td>
                              {item.type === 0 ? (
                                ""
                              ) : (
                                <Currency value={item.value} />
                              )}
                            </td>
                            <td>
                              {item.type === 1 ? (
                                ""
                              ) : (
                                <Currency value={item.value} />
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                    <tfoot>
                      <tr>
                        <th colSpan={1}>Total</th>
                        <th colSpan={2}>
                          <Currency value={payslip_total} />
                        </th>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </Card>
            )}
            {installments.length > 0 && (
              <Card title="Installments" style={{ marginTop: 8 }}>
                <Table
                  columns={columns}
                  dataSource={installments}
                  rowKey="id"
                  pagination={false}
                  size="small"
                />
              </Card>
            )}
          </Col>
          <Col sm={16}>
            {payslip_total - payroll.amount_paid > 1 && (
              <Card title="Pay Installment">
                <Form>
                  <div>
                    {getFieldDecorator("payment_id", { initialValue: id })(
                      <Input type="hidden" />
                    )}
                  </div>
                  <Row gutter={8}>
                    <Col sm={12}>
                      <FormItem label="Amount">
                        {getFieldDecorator("amount_paid", {
                          rules: [
                            { required: true, message: "Amount is requried!" },
                          ],
                        })(<InputNumber />)}
                      </FormItem>
                    </Col>
                    <Col sm={12}>
                      <FormItem label="Payment Date">
                        {getFieldDecorator("payment_date", {
                          rules: [
                            {
                              required: true,
                              message: "Payment date is required!",
                            },
                          ],
                        })(<DatePicker />)}
                      </FormItem>
                    </Col>
                    <Col sm={12}>
                      <FormItem label="Debit Account">
                        {getFieldDecorator("exp_debit_account")(
                          <AccountsDropdown />
                        )}
                      </FormItem>
                    </Col>
                    <Col sm={12}>
                      <FormItem label="Expense Payment Mode">
                        {getFieldDecorator("exp_payment_mode")(
                          <PaymentsModeDropdown />
                        )}
                      </FormItem>
                    </Col>
                    <Col sm={12}>
                      <FormItem label="Expense Category">
                        {getFieldDecorator("exp_category")(
                          <ExpensesCategoryDropdown />
                        )}
                      </FormItem>
                    </Col>
                    <Col sm={12}>
                      <FormItem label="Payment Details">
                        {getFieldDecorator("payment_mode_details")(<Input />)}
                      </FormItem>
                    </Col>
                  </Row>
                  <FormItem>
                    <Button
                      icon="dollar"
                      type="primary"
                      block
                      onClick={this._handleInstallmentPayment}
                      loading={loading}
                    >
                      Pay
                    </Button>
                  </FormItem>
                </Form>
              </Card>
            )}
          </Col>
        </Row>

        <PrintPaySlip
          payroll={payroll}
          payslip_items={payslip_items}
          payslip_total={payslip_total}
        />
      </Layout.Content>
    );
  }
}

export default Form.create()(PayrollViewer);
