import React, { Component } from "react";
import {
  Layout,
  Form,
  Input,
  InputNumber,
  DatePicker,
  Button,
  message,
} from "antd";
import moment from "moment";
import { api } from "../../../config";

class ExamForm extends Component {
  state = {
    id: null,
  };

  handleSubmit = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        api
          .put("/exams/", JSON.stringify(val))
          .then((res) => {
            if (res.data.success) {
              message.success("Exam created");
              this.props.history.push("/exams/");
            } else {
              message.error(res.data.error);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const FormItem = Form.Item;
    return (
      <Layout.Content>
        <h1 className="align-center">Add Exam</h1>
        <Form labelCol={{ sm: 8 }} wrapperCol={{ sm: 8 }}>
          <FormItem label="Exam Name">
            {getFieldDecorator("name", {
              rules: [{ required: true, message: "Name is required!" }],
            })(<Input />)}
          </FormItem>
          <FormItem label="Description">
            {getFieldDecorator("description")(<Input.TextArea />)}
          </FormItem>
          <FormItem label="Duration in minutes">
            {getFieldDecorator("max_duration", {
              rules: [{ required: true, message: "Duration is required" }],
            })(<InputNumber />)}
          </FormItem>
          <FormItem label="Start Time">
            {getFieldDecorator("start_time")(
              <DatePicker
                disabledDate={(date) => {
                  return (
                    moment().subtract(1, "day").valueOf() >= date.valueOf()
                  );
                }}
                showTime={{ format: "HH:mm", minuteStep: 5 }}
                format="YYYY-MM-DD HH:mm"
              />
            )}
          </FormItem>
          <FormItem wrapperCol={{ offset: 8 }}>
            <Button type="primary" onClick={this.handleSubmit}>
              Save
            </Button>
          </FormItem>
        </Form>
      </Layout.Content>
    );
  }
}

export default Form.create()(ExamForm);
