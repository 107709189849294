import React, { Component } from "react";
import { Modal, Form, Checkbox, message, InputNumber } from "antd";
import {
  StudentNameSearchDropdown,
  AcademicYearDropdown,
  BusRouteStopDropdown,
} from "../../../../components";
import { api } from "../../../../config";

class StudentsListForm extends Component {
  state = {
    route: {},
    pickup: {},
    drop: {},
  };

  componentDidMount() {
    if (this.props.route) {
      this.setState({ route: this.props.route });
    }
  }

  handleSubmit = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        api
          .put("/bus/students/", JSON.stringify(val))
          .then((res) => {
            if (res.data && res.data.success) {
              message.success("Student Added to Bus");
              this.props.closeModal();
            } else {
              message.error(res.data.error);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  __set_amount = () => {
    let { setFieldsValue } = this.props.form;
    let { pickup, drop } = this.state;
    let amount =
      ((pickup && pickup.amount) || 0) + ((drop && drop.amount) || 0);
    setFieldsValue({ amount });
  };

  __get_pickup_stop = (item) => {
    this.setState({ pickup: item }, this.__set_amount);
  };

  __get_drop_stop = (item) => {
    this.setState({ drop: item }, this.__set_amount);
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const FormItem = Form.Item;
    const { route } = this.state;
    return (
      <Modal
        visible={this.props.visible}
        onCancel={this.props.closeModal}
        title="Add Student to Bus"
        okText="Add"
        onOk={this.handleSubmit}
      >
        <Form>
          <FormItem label="Student">
            {getFieldDecorator("student_id", {
              rules: [{ required: true, message: "Student is required!" }],
            })(<StudentNameSearchDropdown />)}
          </FormItem>
          <FormItem label="Academic Year">
            {getFieldDecorator("ac_year_id", {
              rules: [
                { required: true, message: "Academic year is required!" },
              ],
            })(<AcademicYearDropdown />)}
          </FormItem>
          <FormItem label="Pickup Stop">
            {getFieldDecorator("pickup_route_stop_id", {
              initialValue: null,
            })(
              <BusRouteStopDropdown
                getItem={this.__get_pickup_stop}
                route_id={route.id}
                direction={1}
              />
            )}
          </FormItem>
          <FormItem label="Drop Stop">
            {getFieldDecorator("drop_route_stop_id", {
              initialValue: null,
            })(
              <BusRouteStopDropdown
                getItem={this.__get_drop_stop}
                route_id={route.id}
                direction={2}
              />
            )}
          </FormItem>
          <FormItem label="Fees">
            {getFieldDecorator("fee", {
              initialValue: true,
              valuePropName: "checked",
            })(<Checkbox>Transportation fee included in Main Fees?</Checkbox>)}
          </FormItem>
          <FormItem label="Fee Amount">
            {getFieldDecorator("amount", {
              initialValue: "0",
            })(<InputNumber />)}
          </FormItem>
        </Form>
      </Modal>
    );
  }
}

export default Form.create()(StudentsListForm);
