import React, { Component } from "react";
import { Select, message } from "antd";
import { api } from "../config";

const style = {
  width: 160,
  maxWidth: "100%"
};

class SubjectSelectionDropdown extends Component {
  state = { subjects: [], value: undefined };

  static getDerivedStateFromProps(nextProps, prevState) {
    if ("class" in nextProps) {
      return {
        ...(nextProps.class || null)
      };
    }
    if ("value" in nextProps) {
      return {
        ...(nextProps.value || null)
      };
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.class !== this.props.class) {
      this.getSubjects(this.props.class);
    }
    if (prevProps.value !== this.props.value) {
      this.setState({ value: this.props.value });
    }
  }

  componentDidMount() {
    if (this.props.class) {
      this.getSubjects(this.props.class);
    } else {
      this.getSubjects();
    }
  }

  getSubjects = id => {
    if (!id) {
      return;
    }
    let url = "/subjects/" + id + "/";
    api
      .get(url)
      .then(res => {
        if (res.data && res.data.success) {
          this.setState({ subjects: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  render() {
    return (
      <Select
        showSearch
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        onChange={this.props.onChange}
        value={this.props.value}
        style={this.props.style || style}
        disabled={this.props.disabled || false}
        placeholder={this.props.placeholder || "Subject"}
        allowClear={this.props.allowClear || true}
      >
        {this.state.subjects.map((item, index) => {
          return (
            <Select.Option value={item.id} key={index}>
              {item.name}
            </Select.Option>
          );
        })}
      </Select>
    );
  }
}

export default SubjectSelectionDropdown;
