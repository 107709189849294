import {
  Button,
  Col,
  Form,
  Input,
  Layout,
  message,
  Radio,
  Row,
  Select,
} from "antd";
import React, { Component } from "react";
import { api } from "../../../config";

const FormItem = Form.Item;
const { Option } = Select;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 },
  },
};

class SMS extends Component {
  state = {
    templates: {},
    send_to: 0,
    classes_list: [],
    message_length: 0,
    loading: false,
  };

  componentDidMount() {
    this.getClassList();
    this.get_sms_templates_list();
  }

  getClassList = () => {
    api
      .get("/class/")
      .then((res) => {
        if (res.data && res.data.success) {
          this.setState({ classes_list: res.data.data });
        } else {
          message.error("Failed to fetch classes list");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  get_sms_templates_list = () => {
    api
      .get("/sms/templates/")
      .then((res) => {
        if (res.data.success) {
          this.setState({ templates: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => console.log(err));
  };

  handleClick = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        this.setState({ loading: true });
        api
          .put("/sms/send/", JSON.stringify(val))
          .then((res) => {
            if (res.data && res.data.success) {
              message.success("Messages Sent");
            } else {
              message.error(res.data.error);
            }
            this.setState({ loading: false });
          })
          .catch((err) => {
            console.log(err);
            this.setState({ loading: false });
          });
      }
    });
  };

  selected_temaplte = (template, name) => {
    this.props.form.setFieldsValue({
      message: template.dlt_template,
      title: name,
      template_id: template.dlt_template_id,
    });
    this.setState({ message_length: template.dlt_template.length });
  };

  handleMessageChange = (e) => {
    let msg = e.target.value;
    this.setState({ message_length: msg.length });
  };

  render() {
    const { templates } = this.state;
    const { getFieldDecorator } = this.props.form;
    const { message_length } = this.state;
    return (
      <Layout.Content>
        <h1>Send SMS</h1>
        <Row>
          <Col lg={16}>
            <Form {...formItemLayout}>
              <FormItem label="To">
                {getFieldDecorator("to", {
                  rules: [{ required: true, message: "To is required" }],
                })(
                  <Radio.Group
                    onChange={(e) => {
                      this.setState({ send_to: e.target.value });
                    }}
                  >
                    <Radio value="0">Everyone</Radio>
                    <Radio value="1">All Students</Radio>
                    <Radio value="2">Teachers</Radio>
                    <Radio value="5">Class</Radio>
                  </Radio.Group>
                )}
              </FormItem>
              {this.state.send_to === "5" && (
                <FormItem label="Class">
                  {getFieldDecorator("class_ids", {
                    rules: [
                      {
                        required: true,
                        message: "Please select atleast one class",
                      },
                    ],
                  })(
                    <Select mode="multiple">
                      {this.state.classes_list.map((item, index) => {
                        return (
                          <Option value={item.id} key={index}>
                            {item.class + item.section}
                          </Option>
                        );
                      })}
                    </Select>
                  )}
                </FormItem>
              )}
              <FormItem label="Title">
                {getFieldDecorator("title", {
                  rules: [{ required: true, message: "Title is required" }],
                })(<Input disabled />)}
              </FormItem>
              <FormItem label="Template ID">
                {getFieldDecorator("template_id", {
                  rules: [
                    { required: true, message: "Template ID is required!" },
                  ],
                })(<Input disabled />)}
              </FormItem>
              <FormItem label="Message">
                {getFieldDecorator("message", {
                  rules: [{ required: true, message: "Message is required" }],
                })(
                  <Input.TextArea
                    onChange={this.handleMessageChange}
                    rows={6}
                  />
                )}
                <span style={{ color: message_length > 159 ? "red" : "black" }}>
                  Characters: {message_length}({Math.ceil(message_length / 160)}{" "}
                  messages)
                </span>
              </FormItem>

              <FormItem wrapperCol={{ offset: 6 }}>
                <Button
                  type="primary"
                  onClick={this.handleClick}
                  loading={this.state.loading}
                >
                  Send
                </Button>
              </FormItem>
            </Form>
          </Col>
          <Col lg={8}>
            <h3 style={{ textAlign: "center" }}>Templates</h3>
            {Object.keys(templates).map((item) => {
              let _template = templates[item];
              return (
                <div
                  key={item}
                  style={{ cursor: "pointer" }}
                  onClick={this.selected_temaplte.bind(this, _template, item)}
                >
                  <h4>{item}</h4>
                  <p>{_template.dlt_template}</p>
                </div>
              );
            })}
          </Col>
        </Row>
      </Layout.Content>
    );
  }
}

export default Form.create()(SMS);
