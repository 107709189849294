import React, { Component } from "react";
import {
  Layout,
  Table,
  message,
  Row,
  Col,
  Button,
  Icon,
  Popconfirm
} from "antd";
import { api } from "../../../../config";

class InventoryList extends Component {
  state = {
    inventory_items: []
  };

  componentDidMount() {
    this.get_inventory_items();
  }

  get_inventory_items = () => {
    api
      .get("/simple/inventory/")
      .then(res => {
        if (res.data && res.data.success) {
          this.setState({ inventory_items: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  handleDelete = record => {
    api
      .delete("/simple/inventory/" + record.id + "/")
      .then(res => {
        if (res.data && res.data.success) {
          message.success(record.item_name + " is deleted");
          this.get_inventory_items();
        } else {
          message.error(res.data.error);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  render() {
    const columns = [
      { title: "Item Name", dataIndex: "item_name" },
      { title: "Category", dataIndex: "category" },
      { title: "Quantity", dataIndex: "quantity" },
      {
        title: "Actions",
        render: record => (
          <span>
            <Icon
              type="edit"
              className="action-icon"
              onClick={() => {
                this.props.history.push("/inventory/edit/" + record.id + "/");
              }}
            />
            <Popconfirm
              title="Are you sure?"
              onConfirm={this.handleDelete.bind(this, record)}
            >
              <Icon type="delete" className="action-icon" />
            </Popconfirm>
          </span>
        )
      }
    ];
    return (
      <Layout.Content>
        <Row>
          <Col sm={16}>
            <h1>Inventory List</h1>
          </Col>
          <Col sm={8} style={{ textAlign: "right" }}>
            <Button
              icon="plus"
              type="primary"
              style={{ marginRight: 10 }}
              onClick={() => {
                this.props.history.push("/inventory/category");
              }}
            >
              Category
            </Button>
            <Button
              type="primary"
              icon="plus"
              onClick={() => {
                this.props.history.push("/inventory/new");
              }}
            >
              Add New Item
            </Button>
          </Col>
        </Row>
        <Table
          columns={columns}
          dataSource={this.state.inventory_items}
          rowKey="id"
        />
      </Layout.Content>
    );
  }
}

export default InventoryList;
