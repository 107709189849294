import React from "react";
import { Switch, Route } from "react-router-dom";
import MessagesList from "./MessagesList";
import SMS from "./SMS";

const AdminMessagesRouter = (props) => (
  <Switch>
    <Route exact path="/messages" component={MessagesList} />
    <Route exact path="/messages/new" />
    <Route exact path="/messages/sms" component={SMS} />
  </Switch>
);

export { AdminMessagesRouter };
