import React, { Component } from "react";
import { Form, Input, DatePicker } from "antd";
import { connect } from "react-redux";
import { ClassSectionDropdown } from "../../../components";

var moment = require("moment");

class StudentProfile extends Component {
  state = {
    students: [],
    user: {}
  };
  componentDidMount() {
    this.setState({ user: this.props.user, students: this.props.students });
  }

  render() {
    const { students, user } = this.state;
    const FormItem = Form.Item;
    return (
      <div>
        <Form labelCol={{ sm: 8 }} wrapperCol={{ sm: 8 }}>
          <FormItem label="Name">
            <Input value={user.name} disabled />
          </FormItem>
          <FormItem label="Email">
            <Input value={user.email} disabled />
          </FormItem>
          {students.map((item, index) => {
            return (
              <span key={index}>
                <h3 style={{ textAlign: "center" }}>Student {index + 1}</h3>
                <FormItem label="Name">
                  <Input value={item.fullname} disabled />
                </FormItem>
                <FormItem label="Admission No.">
                  <Input value={item.admission_no} disabled />
                </FormItem>
                <FormItem label="Mobile Number">
                  <Input value={item.mobile} disabled />
                </FormItem>
                <FormItem label="Class">
                  <ClassSectionDropdown value={item.class_id} disabled />
                </FormItem>
                <FormItem label="Roll No.">
                  <Input value={item.roll_no} disabled />
                </FormItem>
                <FormItem label="Date of Birth">
                  <DatePicker
                    format="DD-MM-YYYY"
                    value={moment(item.dob)}
                    disabled
                  />
                </FormItem>
                <FormItem label="Admission Date">
                  <DatePicker
                    format="DD-MM-YYYY"
                    value={moment(item.admission_date)}
                    disabled
                  />
                </FormItem>
              </span>
            );
          })}
        </Form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    students: state.students
  };
}

export default connect(
  mapStateToProps,
  null
)(StudentProfile);
