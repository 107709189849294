import React, { Component } from "react";
import { Layout, Row, Col, Button, Table, message, Icon, Spin } from "antd";
import { Link } from "react-router-dom";
import moment from "moment";
import { ClassSectionDropdown } from "../../../../components";
import { api } from "../../../../config";

let state = {
  test_id: null,
  class_id: null,
  tests_list: [],
  spinning: false,
};

class TestsDetailsList extends Component {
  state = state;

  componentWillUnmount() {
    state = this.state;
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.setState({ test_id: this.props.match.params.id });
    }
    if (this.state.class_id && this.state.test_id) {
      this.getTestDetails();
    }
  }

  getTestDetails = () => {
    let { test_id, class_id } = this.state;
    if (!(test_id && class_id)) {
      message.error("Data is missing");
      return;
    }
    this.setState({ spinning: true });
    let url = "/class/test/" + test_id + "/" + class_id + "/";
    api
      .get(url)
      .then((res) => {
        if (res.data && res.data.success) {
          this.setState({ tests_list: res.data.data });
        } else {
          message.error(res.data.error);
        }
        this.setState({ spinning: false });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ spinning: false });
      });
  };

  handleClassChange = (class_id) => {
    this.setState({ class_id }, this.getTestDetails);
  };

  render() {
    const columns = [
      {
        title: "Test",
        dataIndex: "test",
        key: "test",
      },
      {
        title: "Class",
        dataIndex: "class",
        key: "class",
      },
      {
        title: "Section",
        dataIndex: "section",
        key: "section",
      },
      {
        title: "Subject",
        dataIndex: "subject",
        key: "subject",
      },
      {
        title: "Start Date",
        render: (record) => (
          <span>{moment(record.start_time).format("DD MMM, YYYY h:mm a")}</span>
        ),
      },
      {
        title: "End Date",
        render: (record) => (
          <span>{moment(record.end_time).format("DD MMM, YYYY h:mm a")}</span>
        ),
      },
      {
        title: "Actions",
        render: (record) => (
          <span>
            <Icon
              type="edit"
              className="action-icon"
              onClick={() => {
                this.props.history.push({
                  pathname: "/academics/test/details/edit/" + record.id,
                  state: { test: record },
                });
              }}
            />
            <Icon type="delete" className="action-icon" />
          </span>
        ),
      },
    ];
    return (
      <Layout.Content>
        <Row gutter={32} className="ribbon-row">
          <Col sm={8}>
            <h1>Tests Details</h1>
          </Col>
          <Col sm={8}>
            <span>
              <strong>Class : </strong>
            </span>
            <ClassSectionDropdown
              value={this.state.class_id}
              onChange={this.handleClassChange}
            />
          </Col>
          <Col sm={8} className="action-buttons">
            <Link to={"/academics/test/details/" + this.state.test_id}>
              <Button icon="plus" type="primary" className="action-button">
                Add New
              </Button>
            </Link>
          </Col>
        </Row>
        <Spin spinning={this.state.spinning}>
          <Table
            columns={columns}
            dataSource={this.state.tests_list}
            rowKey="id"
          />
        </Spin>
      </Layout.Content>
    );
  }
}

export default TestsDetailsList;
