import React, { Component } from "react";
import {
  Layout,
  Form,
  DatePicker,
  InputNumber,
  Select,
  Icon,
  Button,
  message,
} from "antd";
import {
  ClassSectionDropdown,
  TeacherSelectionDropdown,
  SubjectSelectionDropdown,
} from "../../../../components";
import { api } from "../../../../config";

class TestDetailsAddForm extends Component {
  state = {
    items_count: 1,
    class_id: null,
    test_id: null,
    loading: false,
  };

  componentDidMount() {
    if (this.props.match.params.testid) {
      this.setState({ test_id: this.props.match.params.testid });
    }
  }

  addItem = () => {
    let items_count = this.state.items_count;
    items_count++;
    this.setState({ items_count });
  };

  removeItem = () => {
    let items_count = this.state.items_count;
    items_count--;
    this.setState({ items_count });
  };

  handleSubmit = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        val.test_id = this.state.test_id;
        this.setState({ loading: true });
        api
          .put("/class/tests/", JSON.stringify(val))
          .then((res) => {
            if (res.data && res.data.success) {
              message.success("New Test Item Added");
              this.props.history.push("/academics/test/list/" + val.test_id);
            } else {
              message.error(res.data.error);
            }
            this.setState({ loading: false });
          })
          .catch((err) => {
            console.log(err);
            this.setState({ loading: false });
          });
      }
    });
  };

  render() {
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
    };
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    const items_count = this.state.items_count;

    return (
      <Layout.Content>
        <h1 style={{ textAlign: "center" }}>Add Test Details</h1>
        <Form>
          <FormItem label="Class" {...formItemLayout}>
            {getFieldDecorator("class_id", {
              rules: [
                { required: true, message: "Please select class and section" },
              ],
            })(
              <ClassSectionDropdown
                onChange={(class_id) => {
                  this.setState({ class_id });
                }}
              />
            )}
          </FormItem>
          <div className="test-details-table">
            <table className="test-details-table">
              <thead>
                <tr>
                  <th className="table-subject">Subject</th>
                  <th className="table-min-marks">Min Marks</th>
                  <th className="table-max-marks">Max Marks</th>
                  <th className="table-result-type">Result Type</th>
                  <th className="table-start-time">Start Time</th>
                  <th className="table-end-time">End Time</th>
                  <th className="table-invigilator">Invigilator</th>
                  <th> </th>
                </tr>
              </thead>
              <tbody>
                {[...Array(items_count).keys()].map((item) => {
                  return (
                    <tr key={item}>
                      <td>
                        <FormItem>
                          {getFieldDecorator(`subjects[${item}].subject_id`, {
                            rules: [
                              {
                                required: true,
                                message: "Subject is required",
                              },
                            ],
                          })(
                            <SubjectSelectionDropdown
                              class={this.state.class_id}
                              style={{ width: 150 }}
                            />
                          )}
                        </FormItem>
                      </td>
                      <td>
                        <FormItem>
                          {getFieldDecorator(`subjects[${item}].min_marks`, {
                            rules: [
                              {
                                required: true,
                                message: "Min Marks is required",
                              },
                            ],
                          })(<InputNumber />)}
                        </FormItem>
                      </td>
                      <td>
                        <FormItem>
                          {getFieldDecorator(`subjects[${item}].max_marks`, {
                            rules: [
                              {
                                required: true,
                                message: "Max Marks is required",
                              },
                            ],
                          })(<InputNumber />)}
                        </FormItem>
                      </td>
                      <td>
                        <FormItem>
                          {getFieldDecorator(`subjects[${item}].marks_type`, {
                            rules: [
                              {
                                required: true,
                                message: "Marks Type is required",
                              },
                            ],
                          })(
                            <Select style={{ width: 100 }}>
                              <Select.Option value="1">Marks</Select.Option>
                              <Select.Option value="2">Grades</Select.Option>
                            </Select>
                          )}
                        </FormItem>
                      </td>
                      <td>
                        <FormItem>
                          {getFieldDecorator(`subjects[${item}].start_time`)(
                            <DatePicker
                              showTime={{ format: "hh:mm", minuteStep: 10 }}
                              format="YYYY-MM-DD hh:mm"
                              style={{ width: 175 }}
                            />
                          )}
                        </FormItem>
                      </td>
                      <td>
                        <FormItem>
                          {getFieldDecorator(`subjects[${item}].end_time`)(
                            <DatePicker
                              showTime={{ format: "hh:mm", minuteStep: 10 }}
                              format="YYYY-MM-DD hh:mm"
                              style={{ width: 175 }}
                            />
                          )}
                        </FormItem>
                      </td>
                      <td>
                        <FormItem>
                          {getFieldDecorator(`subjects[${item}].invigilator`)(
                            <TeacherSelectionDropdown style={{ width: 150 }} />
                          )}
                        </FormItem>
                      </td>
                      <td>
                        <div style={{ marginTop: -20 }}>
                          {items_count - item === 1 ? (
                            <Icon
                              type="plus-circle"
                              style={{ fontSize: 24 }}
                              onClick={this.addItem}
                            />
                          ) : (
                            <Icon
                              type="minus-circle"
                              style={{ fontSize: 24 }}
                              onClick={this.removeItem}
                            />
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <FormItem style={{ textAlign: "center" }} wrapperCol={{ sm: 24 }}>
            <Button
              type="primary"
              onClick={this.handleSubmit}
              loading={this.state.loading}
            >
              Add Test Details
            </Button>
          </FormItem>
        </Form>
      </Layout.Content>
    );
  }
}

export default Form.create()(TestDetailsAddForm);
