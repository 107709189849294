import React from "react";
import { Switch, Route } from "react-router-dom";
import BooksList from "../../admin/library/BooksList";
import AddBook from "../../admin/library/AddBook";
import BookIssueHistory from "../../admin/library/BookIssueHistory";

const LibrarianLibraryRouter = props => (
  <Switch>
    <Route exact path="/library/books/" component={BooksList} />
    <Route exact path="/library/book/new" component={AddBook} />
    <Route exact path="/library/book/:id" component={BookIssueHistory} />
    <Route
      exact
      path="/library/book/history/:id"
      component={BookIssueHistory}
    />
  </Switch>
);

export { LibrarianLibraryRouter };
