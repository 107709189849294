import React from "react";
import { Switch, Route } from "react-router-dom";
import ClassRoomList from "../../teacher/virtual_classroom/ClassRoomList";
import NewClassRoom from "../../teacher/virtual_classroom/NewClassRoom";
import VirtualClassRoom from "../../teacher/virtual_classroom/VirtualClassRoom";
import VirtualClassroomAccess from "./VirtualClassroomAccess";

const AdminVirtualClassroomRouter = (props) => (
  <Switch>
    <Route exact path="/virtual-classroom/" component={ClassRoomList} />
    <Route
      exact
      path="/virtual-classroom/access/"
      component={VirtualClassroomAccess}
    />
    <Route exact path="/virtual-classroom/new" component={NewClassRoom} />
    <Route exact path="/virtual-classroom/:id" component={VirtualClassRoom} />
  </Switch>
);

export { AdminVirtualClassroomRouter };
