import React from "react";
import { connect } from "react-redux";
import { Modal } from "antd";

const TermsOfService = (props) => {
  console.log(props);
  return (
    <Modal
      title="Terms and Conditions for Online Payment"
      visible={true}
      onCancel={props.closeModal}
      onOk={props.closeModal}
      width={window.innerWidth > 1200 ? 1200 : "96%"}
    >
      <div>
        <p>
          The Terms and Conditions contained herein shall apply to any person
          (“User”) using the services of COMPANY NAME herein after called
          “COMPANY NAME” for making Fee payments through an online payment
          gateway service (“Service”) and Payment Gateway Service provider,
          through COMPANY NAME’ website i.e. http://nhvps.com. Each User is
          therefore deemed to have read and accepted these Terms and Conditions.
        </p>
        <ol>
          <li>
            <h4>Privacy Policy</h4>
            <p>
              COMPANY NAME respects and protects the privacy of the individuals
              that access the information and use the services provided through
              them. Individually identifiable information about the User is not
              wilfully disclosed to any third party without first receiving the
              User’s permission, as covered in this Privacy Policy. This Privacy
              Policy describes COMPANY NAME’ treatment of personally
              identifiable information that COMPANY NAME collects when User is
              on the COMPANY NAME’ website. COMPANY NAME does not collect any
              unique information about the User (such as User’s name, email
              address, age, gender etc.) except when the User specifically and
              knowingly provide such information on the Website. Like any
              business interested in offering the highest quality of service to
              clients, COMPANY NAME may, from time to time, send  email and
              other communication to the User, tell them about the various
              services, features, functionality and content offered by COMPANY
              NAME website or seek voluntary information from you. Please be
              aware, however, that COMPANY NAME will release specific personal
              information about the User if required to do so in the following
              circumstances: In order to comply with any valid legal process
              such as a search warrant, or court order, or any other judicial
              scrutiny. If any of User’s actions on ‘COMPANY NAME’ website
              violate the Terms of Service or any of COMPANY NAME’ guidelines
              for specific services, or To protect or defend ‘COMPANY NAME’
              legal rights or property, the COMPANY NAME site, or COMPANY NAME
              Users; or To investigate, prevent, or take action regarding
              illegal activities, suspected fraud, situations involving
              potential threats to the security, integrity of COMPANY NAME
              website/offerings.
            </p>
          </li>
          <li>
            <h4>General Terms and Conditions For Online-Payments</h4>
            <ol>
              <li>
                Once a User has accepted these Terms and Conditions, he/ she may
                register and avail the Services. A User may either register on
                Eduga’s website or alternatively enter his/ her Roll number and
                pay their dues/ fees in any other manner as may be specified by
                COMPANY NAME from time to time.
              </li>
              <li>
                COMPANY NAME’ rights, obligations, undertakings shall be subject
                to the laws in force in India, as well as any directives/
                procedures of Government of India, and nothing contained in
                these Terms and Conditions shall be in derogation of COMPANY
                NAME’ right to comply with any law enforcement agencies request
                or requirements relating to any User’s use of the website or
                information provided to or gathered by COMPANY NAME with respect
                to such use. Each User accepts and agrees that the provision of
                details of his/ her use of the Website to regulators or police
                or to any other third party in order to resolve disputes or
                complaints which relate to the Website shall be at the absolute
                discretion of COMPANY NAME.
              </li>
              <li>
                If any part of these Terms and Conditions are determined to be
                invalid or unenforceable pursuant to applicable law including,
                but not limited to, the warranty disclaimers and liability
                limitations set forth herein, then the invalid or unenforceable
                provision will be deemed superseded by a valid, enforceable
                provision that most closely matches the intent of the original
                provision and the remainder of these Terms and Conditions shall
                continue in effect.
              </li>
              <li>
                These Terms and Conditions constitute the entire agreement
                between the User and COMPANY NAME. These Terms and Conditions
                supersede all prior or contemporaneous communications and
                proposals, whether electronic, oral, or written, between the
                User and COMPANY NAME. A printed version of these Terms and
                Conditions and of any notice given in electronic form shall be
                admissible in judicial or administrative proceedings based upon
                or relating to these Terms and Conditions to the same extent and
                subject to the same conditions as other business documents and
                records originally generated and maintained in printed form.
              </li>
              <li>
                The entries in the books of COMPANY NAME and/or the Payment
                Service Providers kept in the ordinary course of business of
                COMPANY NAME and/or the Payment Service Providers with regard to
                transactions covered under these Terms and Conditions and
                matters therein appearing shall be binding on the User and shall
                be conclusive proof of the genuineness and accuracy of the
                transaction.
              </li>
              <li>
                Refund For Charge Back Transaction: In the event there is any
                claim for/ of charge back  by the User for any reason
                whatsoever, such User shall immediately approach COMPANY NAME
                with his/ her claim details and claim refund from COMPANY NAME
                alone. Such refund (if any) shall be effected only by COMPANY
                NAME by means as COMPANY NAME deems appropriate. No claims for
                refund/ charge back shall be made by any User to the Payment
                Service Provider(s) and in the event such claim is made it shall
                not be entertained.
              </li>
              <li>
                In these Terms and Conditions, the term “Charge Back” shall
                mean, approved and settled credit card or net banking purchase
                transaction(s) which are at any time refused, debited or charged
                back to merchant account (and shall also include similar debits
                to Payment Service Provider’s accounts, if any) by the acquiring
                bank or credit card company for any reason whatsoever, together
                with the bank fees, penalties and other charges incidental
                thereto.
              </li>
              <li>
                Refund for fraudulent/duplicate transaction(s):  The User shall
                directly contact COMPANY NAME for any fraudulent transaction(s)
                on account of misuse of Card/ Bank details by a fraudulent
                individual/party and such issues shall be suitably addressed by
                COMPANY NAME alone in line with their policies and  rules.
              </li>
              <li>
                Server Slow Down/Session Timeout: In case the Website or Payment
                Service Provider’s webpage, that is linked to the Website, is
                experiencing any server related issues like ‘slow down’ or
                ‘failure’ or ‘session timeout’, the User shall, before
                initiating the second payment, check whether his/her Bank
                Account has been debited or not and accordingly resort to one of
                the following options:
              </li>
              <ol>
                <li>
                  In case the Bank Account appears to be debited, ensure that
                  he/ she does not make the payment twice and immediately
                  thereafter contact COMPANY NAME via e-mail or any other mode
                  of contact as provided by COMPANY NAME to confirm payment.
                </li>
                <li>
                  In case the Bank Account is not debited, the User may initiate
                  a fresh transaction to make payment.
                </li>
              </ol>
            </ol>
            <p>
              However, the User agrees that under no circumstances the Payment
              Gateway Service Provider shall be held responsible for such
              fraudulent/duplicate transactions and hence no claims should be
              raised to Payment Gateway Service Provider.  No communication
              received by the Payment Service Provider(s) in this regards  shall
              be entertained by the Payment Service Provider(s).
            </p>
          </li>
          <li>
            <h4>Limitation of Liability</h4>
            <ol>
              <li>
                 COMPANY NAME has made this Service available to the User as a
                matter of convenience. COMPANY NAME expressly   disclaims any
                claim or liability arising out of the provision of this Service.
                The User agrees and acknowledges that he/ she shall be solely
                responsible for his/ her conduct and that COMPANY NAME reserves
                the right to terminate the rights to use of the Service
                immediately without giving any prior notice thereof.
              </li>
              <li>
                COMPANY NAME and/or the Payment Service Providers shall not be
                liable for any inaccuracy, error or delay in, or omission of (a)
                any data, information or message, or (b) the transmission or
                delivery of any such data, information or message; or (c) any
                loss or damage arising from or occasioned by any such
                inaccuracy, error, delay or omission, non-performance or
                interruption in any such data, information or message. Under no
                circumstances shall the COMPANY NAME and/or the Payment Service
                Providers, statutory and non-statutory officers, employees and
                its third party agents  involved in processing, delivering or
                managing the Services, be liable for any direct, indirect,
                incidental, special or consequential damages, or any damages
                whatsoever, including punitive or exemplary  arising out of or
                in any way connected with the provision of or any inadequacy or
                deficiency in the provision of the Services or resulting from
                unauthorized access or alteration of transmissions of data or
                arising from suspension or termination of the Services.
              </li>
              <li>
                COMPANY NAME and the Payment Service Provider(s) assume no
                liability whatsoever for any monetary or other damage suffered
                by the User on account of:
                <ol>
                  <li>
                    the delay, failure, interruption, or corruption of any data
                    or other information transmitted in connection with use of
                    the Payment Gateway or Services in connection thereto; and/
                    or
                  </li>
                  <li>
                    Any interruption or errors in the operation of the Payment
                    Gateway.
                  </li>
                </ol>
              </li>
              <li>
                The User shall indemnify and hold harmless the Payment Service
                Provider(s) and COMPANY NAME and their respective officers,
                directors, agents, and employees, from any claim or demand, or
                actions arising out of or in connection with the utilization of
                the Services.
              </li>
              <li>
                The User agrees that COMPANY NAME or any of its employees will
                not be held liable by the User for any loss or damages arising
                from your use of, or reliance upon the information contained on
                the Website, or any failure to comply with these Terms and
                Conditions where such failure is due to circumstance beyond
                COMPANY NAME’ reasonable control.
              </li>
            </ol>
          </li>
          <li>
            <h4>Miscellaneous Conditions</h4>
            <ol>
              <li>
                 Any waiver of any rights available to COMPANY NAME under these
                Terms and Conditions shall not mean that those rights are
                automatically waived.
              </li>
              <li>
                The User agrees, understands and confirms that his/ her personal
                data including without limitation details relating to debit
                card/ credit card transmitted over the Internet may be
                susceptible to misuse, hacking, theft and/ or fraud and that
                COMPANY NAME or the Payment Service Provider(s) have no control
                over such matters.
              </li>
              <li>
                Although all reasonable care has been taken towards guarding
                against unauthorized use of any information transmitted by the
                User, COMPANY NAME does not represent or guarantee that the use
                of the Services provided by/ through it will not result in theft
                and/or unauthorized use of data over the Internet.
              </li>
              <li>
                COMPANY NAME, the Payment Service Provider(s) and its affiliates
                and associates shall not be liable, at any time, for any failure
                of performance, error, omission, interruption, deletion, defect,
                delay in operation or transmission, computer virus,
                communications line failure, theft or destruction or
                unauthorized access to, alteration of, or use of information
                contained on the Website.
              </li>
              <li>
                The User may be required to create his/ her own User ID and
                Password in order to register and/ or use the Services provided
                by COMPANY NAME on the Website. By accepting these Terms and
                Conditions the User agrees that his/ her User ID and Password
                are very important pieces of information and it shall be the
                User’s own responsibility to keep them secure and confidential.
                In furtherance hereof, the User agrees to;
                <ol>
                  <li>
                    Choose a new password, whenever required for security
                    reasons.
                  </li>
                  <li>
                    Keep his/ her User ID & Password strictly confidential.
                  </li>
                  <li>
                    Be responsible for any transactions made by User under such
                    User ID and Password.
                  </li>
                </ol>
              </li>
            </ol>
            <p>
              The User is hereby informed that COMPANY NAME will never ask the
              User for the User’s password in an unsolicited phone call or in an
              unsolicited email. The User is hereby required to sign out of his/
              her COMPANY NAME account on the Website and close the web browser
              window when the transaction(s) have been completed. This is to
              ensure that others cannot access the User’s personal information
              and correspondence when the User happens to share a computer with
              someone else or is using a computer in a public place like a
              library or Internet café.
            </p>
          </li>
          <li>
            <h4>Debit/Credit Card, Bank Account Details</h4>
            <ol>
              <li>
                The User agrees that the debit/credit card details provided by
                him/ her for use of the aforesaid Service(s) must be correct and
                accurate and that the User shall not use a debit/ credit card,
                that is not lawfully owned by him/ her or the use of which is
                not authorized by the lawful owner thereof. The User further
                agrees and undertakes to provide correct and valid debit/credit
                card details.
              </li>
              <li>
                The User may pay his/ her fees to COMPANY NAME by using a
                debit/credit card or through online banking account. The User
                warrants, agrees and confirms that when he/ she initiates a
                payment transaction and/or issues an online payment instruction
                and provides his/ her card / bank details: <br />
                The User is fully and lawfully entitled to use such credit /
                debit card, bank account for such transactions; <br />
                The User is responsible to ensure that the card/ bank account
                details provided by him/ her are accurate; <br /> The User is
                authorizing debit of the nominated card/ bank account for the
                payment of fees selected by such User along with the applicable
                Fees.
                <br /> The User is responsible to ensure sufficient credit is
                available on the nominated card/ bank account at the time of
                making the payment to permit the payment of the dues payable or
                the bill(s) selected by the User inclusive of the applicable
                Fee.
              </li>
            </ol>
          </li>
          <li>
            <h4>Personal Information</h4>
            <ol>
              <li>
                The User agrees that, to the extent required or permitted by
                law, COMPANY NAME and/ or the Payment Service Provider(s) may
                also collect, use and disclose personal information in
                connection with security related or law enforcement
                investigations or in the course of cooperating with authorities
                or complying with legal requirements.
              </li>
              <li>
                The User agrees that any communication sent by the User vide
                e-mail, shall imply release of information therein/ therewith to
                COMPANY NAME. The User agrees to be contacted via e-mail on such
                mails initiated by him/ her.
              </li>
              <li>
                In addition to the information already in the possession of
                COMPANY NAME and/ or the Payment Service Provider(s), COMPANY
                NAME may have collected similar information from the User in the
                past. By entering the Website the User consents to the terms of
                COMPANY NAME’ information privacy policy and to COMPANY NAME’
                continued use of previously collected information. By submitting
                the User’s personal information to COMPANY NAME, the User will
                be treated as having given his/her permission for the processing
                of the User’s personal data as set out herein.
              </li>
              <li>
                The User acknowledges and agrees that his/ her information will
                be managed in accordance with the laws for the time in force.
              </li>
            </ol>
          </li>
          <li>
            <h4>Payment Gateway Disclaimer</h4>
            <p>
              The Service is provided in order to facilitate access to view and
              pay Student Fees online. COMPANY NAME or the Payment Service
              Provider(s) do not make any representation of any kind, express or
              implied, as to the operation of the Payment Gateway other than
              what is specified in the Website for this purpose. By accepting/
              agreeing to these Terms and Conditions, the User expressly agrees
              that his/ her use of the aforesaid online payment Service is
              entirely at own risk and responsibility of the User.
            </p>
          </li>
        </ol>
      </div>
    </Modal>
  );
};

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps, null)(TermsOfService);
