import React, { Component } from "react";
import { Layout } from "antd";
import { ShowBreadcrumbs } from "../../../../components";

class GettingStarted extends Component {
  state = {};
  render() {
    return (
      <Layout.Content>
        <ShowBreadcrumbs
          items={[
            { name: "Home", link: "/" },
            { name: "Help", link: "/help/usage/" },
            { name: "Getting Started" }
          ]}
        />
        <h1>Getting Started</h1>
        <p>When logged in for first time, please do the following</p>
        <h2>1. Setup Institution Details</h2>
        <p>
          Goto <strong>Admin Actions -> About School</strong> and fill in school
          details.
        </p>
        <div className="tutorials-image">
          <img
            src="https://eduga.co.in/tutorials/images/Eduga-about_school.png"
            alt="About School"
            className="img-responsive"
          />
        </div>
        <h2>2. Create new Academic Year</h2>
        <p>
          Goto <strong>Academics -> Academic Year</strong> and click{" "}
          <strong>Add New Academic Year</strong>.
        </p>
        <div className="tutorials-image">
          <img
            src="https://eduga.co.in/tutorials/images/Eduga-academic_year.png"
            alt="Create Academic Year"
            className="img-responsive"
          />
        </div>
        <p>
          Enter a name for Academic Year, Syllabus (CBSE/ICSE/State Syllabus
          etc.,), Start Date and End Date.
        </p>
        <div className="tutorials-image">
          <img
            src="https://eduga.co.in/tutorials/images/Eduga-academic_year.png"
            alt="Create Academic Year"
            className="img-responsive"
          />
        </div>
      </Layout.Content>
    );
  }
}

export default GettingStarted;
