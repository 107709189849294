import React, { Component } from "react";
import { Layout } from "antd";
import GetAnnouncements from "../../../components/GetAnnouncements";
import { connect } from "react-redux";

class TeacherDashboard extends Component {
  state = {};
  render() {
    return (
      <Layout.Content>
        <h1>Hello {this.props.user.name},</h1>
        <h3>Announcements</h3>
        <GetAnnouncements />
      </Layout.Content>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps, null)(TeacherDashboard);
