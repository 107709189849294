import React, { Component } from "react";
import {
  Layout,
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  Button,
  message,
} from "antd";
import {
  AcademicYearDropdown,
  ClassSectionDropdown,
  FeeItemDropdown,
} from "../../../../components";
import { api } from "../../../../config";

class CreateFees extends Component {
  state = {
    loading: false,
  };

  handleSubmit = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        this.setState({ loading: true });
        api
          .put("/fees/create/", JSON.stringify(val))
          .then((res) => {
            if (res.data.success) {
              message.success("New Fee Created");
              this.setState({ loading: false });
              this.props.history.push("/fees/create/");
            } else {
              message.error(res.data.error);
              this.setState({ loading: false });
            }
          })
          .catch((err) => {
            console.log(err);
            this.setState({ loading: false });
          });
      }
    });
  };

  render() {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    return (
      <Layout.Content>
        <Row>
          <Col sm={24}>
            <h1>Create Fees</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form labelCol={{ sm: 8 }} wrapperCol={{ sm: 8 }}>
              <FormItem label="Fee Title">
                {getFieldDecorator("name", {
                  rules: [{ required: true, message: "Fee Title is required" }],
                })(<Input />)}
              </FormItem>
              <FormItem label="Academic Year">
                {getFieldDecorator("ac_year_id", {
                  rules: [
                    { required: true, message: "Academic Year is required" },
                  ],
                })(<AcademicYearDropdown />)}
              </FormItem>
              <FormItem label="Class">
                {getFieldDecorator("class_id", {
                  rules: [{ required: true, message: "Class is required" }],
                })(<ClassSectionDropdown />)}
              </FormItem>
              <FormItem label="Select Fee Item">
                {getFieldDecorator("fee_item_id")(
                  <FeeItemDropdown
                    getItem={(item) => {
                      let { setFieldsValue } = this.props.form;
                      item
                        ? setFieldsValue({
                            amount_total: item.amount,
                          })
                        : setFieldsValue({ amount_total: undefined });
                    }}
                  />
                )}
              </FormItem>
              <FormItem label="Fee Amount">
                {getFieldDecorator("amount_total", {
                  rules: [
                    { required: true, message: "Fee Amount is required" },
                  ],
                })(<InputNumber />)}
              </FormItem>
              <FormItem wrapperCol={{ offset: 8 }}>
                <Button
                  onClick={this.handleSubmit}
                  loading={this.state.loading}
                >
                  Create Fee Item
                </Button>
              </FormItem>
            </Form>
          </Col>
        </Row>
      </Layout.Content>
    );
  }
}

export default Form.create()(CreateFees);
