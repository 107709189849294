import { Button, Col, Layout, message, Row, Table } from "antd";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { api } from "../../../config";

export default class AppUsers extends Component {
  state = {
    app_users: [],
  };

  componentDidMount() {
    this._get_app_users_list();
  }

  _get_app_users_list = () => {
    api
      .get("/reports/app-users/")
      .then((res) => {
        if (res.data.success) {
          this.setState({ app_users: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  columns = [
    { title: "ID", dataIndex: "id" },
    {
      title: "Student Name",
      dataIndex: "fullname",
      render: (v, r) => <Link to={`/students/details/${r.id}`}>{v}</Link>,
    },
    { title: "Class", render: (r) => <span>{`${r.class} ${r.section}`}</span> },
    {
      title: "App User",
      dataIndex: "app_user",
      render: (v) => <span>{v === 1 ? "Yes" : "No"}</span>,
    },
    { title: "Registered On", dataIndex: "created_on" },
  ];

  export_csv = () => {
    let { app_users } = this.state;
    var csvContent = "data:text/csv;charset=utf-8,";
    let header = "ID,Student Name,Class,Section,App User,Registered On\r\n";
    csvContent += header;
    app_users.forEach((item) => {
      let line = `${item.id},${item.fullname},${item.class},${item.section},${
        item.app_user === 1 ? "Yes" : "No"
      },${item.created_on}\r\n`;
      csvContent += line;
    });

    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `app_users.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  render() {
    return (
      <Layout.Content>
        <Row>
          <Col md={12}>
            <h1>App Users</h1>
          </Col>
          <Col md={12} className="action-buttons">
            <Button icon="export" type="primary" onClick={this.export_csv}>
              Export
            </Button>
          </Col>
        </Row>

        <Table
          columns={this.columns}
          dataSource={this.state.app_users}
          rowKey="id"
        />
      </Layout.Content>
    );
  }
}
