import React, { Component } from "react";
import { Layout, Card, Result, Button } from "antd";
import { Link } from "react-router-dom";

class PaymentStatus extends Component {
  state = {
    application_id: null,
    order_details: {},
    status: null,
    title: null,
    subTitle: null,
    result_status: null,
    params: {},
  };

  componentDidMount() {
    let {
      application_id,
      order_details,
      status,
      params,
    } = this.props.location.state;
    this.set_status_and_texts(application_id, order_details, status, params);
    this.setState({ application_id, order_details, status, params });
  }

  set_status_and_texts = (application_id, order_details, status, params) => {
    let subTitle =
      "Application id : " +
      params.sch_code +
      application_id +
      ", Order id : " +
      order_details.id +
      ", Amount : ₹" +
      parseInt(order_details.amount) / 100;
    switch (status) {
      case -2:
        this.setState({
          result_status: "error",
          title: "Payment Failed",
          subTitle,
        });
        return;
      case -1:
        this.setState({
          result_status: "error",
          title: "Payment Failed",
          subTitle,
        });
        return;
      case 0:
        this.setState({
          result_status: "info",
          title: "Payment Pending",
          subTitle,
        });
        return;
      case 1:
        this.setState({
          result_status: "success",
          title:
            order_details.amount > 0
              ? "Payment Successfull"
              : "Application Submitted",
          subTitle,
        });
        return;
      default:
        this.setState({
          result_status: "info",
          title: "Unknown",
          subTitle: "-",
        });
    }
  };

  render() {
    const { title, subTitle, params } = this.state;
    return (
      <Layout.Content>
        <h1 className="align-center">Order Status</h1>
        <Card>
          <Result
            status={this.state.result_status}
            title={title}
            subTitle={subTitle}
            extra={
              <Button type="primary">
                <Link
                  to={
                    "/admissions/apply/" +
                    params.sch_code +
                    "/" +
                    params.sch_token +
                    "/"
                  }
                  replace
                >
                  Home
                </Link>
              </Button>
            }
          />
        </Card>
      </Layout.Content>
    );
  }
}

export default PaymentStatus;
