import React, { Component } from "react";
import {
  Layout,
  Table,
  Row,
  Col,
  Button,
  message,
  Icon,
  Spin,
  Popconfirm,
} from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { ShowBreadcrumbs } from "../../../../components";
import { api } from "../../../../config";

var moment = require("moment");

class TestsList extends Component {
  state = {
    tests: [],
    spinning: false,
  };

  componentDidMount() {
    this.getTests();
  }

  getTests = () => {
    this.setState({ spinning: true });
    api
      .get("/tests/")
      .then((res) => {
        if (res.data && res.data.data) {
          this.setState({ tests: res.data.data });
        } else {
          message.error(res.data.error);
        }
        this.setState({ spinning: false });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ spinning: false });
      });
  };

  deleteTest = (id) => {
    api
      .delete("/test/" + id + "/")
      .then((res) => {
        if (res.data.success) {
          message.success("Test Deleted");
          this.getTests();
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const columns = [
      {
        title: "Test Name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Start Date",
        render: (record) => (
          <span>{moment(record.start_date).format("DD-MM-YYYY")}</span>
        ),
      },
      {
        title: "End Date",
        render: (record) => (
          <span>{moment(record.end_date).format("DD-MM-YYYY")}</span>
        ),
      },
      {
        title: "Test Type",
        render: (record) => (
          <span>
            {record.test_type === 1
              ? "Exam"
              : record.test_type === 2
              ? "Class Test"
              : "Unknown"}
          </span>
        ),
      },
      {
        title: "Actions",
        render: (record) => (
          <span>
            <Icon type="edit" className="action-icon" />
            <Icon
              type="form"
              className="action-icon"
              onClick={() => {
                let url = "/academics/test/list/" + record.id;
                this.props.history.push(url);
              }}
            />
            <Popconfirm
              title="Are you sure?"
              onConfirm={this.deleteTest.bind(this, record.id)}
            >
              <Icon type="delete" className="action-icon absent" />
            </Popconfirm>
          </span>
        ),
      },
    ];
    return (
      <Layout.Content>
        <ShowBreadcrumbs
          items={[
            { name: "Home", link: "/" },
            { name: "Academics", link: "/academics/" },
            { name: "Tests", link: "/academics/tests/" },
          ]}
        />
        <Row className="ribbon-row">
          <Col sm={20}>
            <h1>Tests</h1>
          </Col>
          <Col sm={4}>
            <Link to="/academics/tests/add">
              <Button type="primary" icon="plus" className="action-button">
                Add Test
              </Button>
            </Link>
          </Col>
        </Row>
        <Spin spinning={this.state.spinning}>
          <Table columns={columns} dataSource={this.state.tests} rowKey="id" />
        </Spin>
      </Layout.Content>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps, null)(TestsList);
