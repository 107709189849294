import React, { Component } from "react";
import { Layout, message, Icon, Breadcrumb, Card, Empty } from "antd";
import { api, baseurl } from "../../../config";

class FilesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      type: undefined,
      folder: undefined,
      loading: false,
    };
  }

  componentDidMount() {
    this.get_files_list();
  }

  get_files_list = (path = "root") => {
    this.setState({ loading: true });
    api
      .get(`/files/${path}/`)
      .then((res) => {
        if (res.data.success) {
          let data = res.data.data;
          if (path === "root") {
            this.setState({ folder: undefined });
          }
          this.setState({ type: data.type, files: data.list });
        } else {
          message.error(res.data.error);
        }
        this.setState({ loading: false });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  handleClick = (item) => {
    if (this.state.folder) {
      let url = `${baseurl}/files/${this.state.folder}/${item}`;
      let a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = item;
      a.target = "_blank";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } else {
      this.setState({ folder: item });
      this.get_files_list(item);
    }
  };

  render() {
    const { files, type, loading } = this.state;
    return (
      <Layout.Content>
        <Card
          title="Files"
          extra={
            <Breadcrumb className="files-breadcrumb">
              <Breadcrumb.Item onClick={this.get_files_list.bind(this, "root")}>
                <Icon type="hdd" />
                <span>files</span>
              </Breadcrumb.Item>
              {this.state.folder && (
                <Breadcrumb.Item>{this.state.folder}</Breadcrumb.Item>
              )}
            </Breadcrumb>
          }
          loading={loading}
        >
          {files.length > 1 ? (
            <div className="files-grid">
              {files.map((item, index) => {
                if (item === "index.html") {
                  return null;
                }
                return (
                  <div
                    key={index}
                    className="file-item"
                    onClick={this.handleClick.bind(this, item)}
                  >
                    <div className="file-icon">
                      {type === "dir" && (
                        <Icon
                          type="folder-open"
                          theme="filled"
                          style={{ fontSize: 36, color: "#0093ff" }}
                        />
                      )}
                      {type === "files" && (
                        <Icon
                          type="file"
                          theme="filled"
                          style={{ fontSize: 36, color: "#0093ff" }}
                        />
                      )}
                    </div>
                    <div className="file-label">
                      <h3>{item}</h3>
                      <p>File type</p>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <Empty />
          )}
        </Card>
      </Layout.Content>
    );
  }
}

export default FilesList;
