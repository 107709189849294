import React from "react";
import { Switch, Route } from "react-router-dom";
import HostelStudents from "./HostelStudents";
import StudentHostelForm from "./StudentHostelForm";

const AdminHostelRouter = (props) => (
  <Switch>
    <Route exact path="/hostel/" component={HostelStudents} />
    <Route exact path="/hostel/student/new" component={StudentHostelForm} />
  </Switch>
);

export { AdminHostelRouter };
