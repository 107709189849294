import React from "react";
import { Icon } from "antd";

const MicOnSvg = () => (
  <svg
    aria-hidden="true"
    focusable="false"
    width="1em"
    height="1em"
    // style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);"
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 24 24"
    // fill="currentColor"
  >
    <path
      d="M12 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3m7 9c0 3.53-2.61 6.44-6 6.93V21h-2v-3.07c-3.39-.49-6-3.4-6-6.93h2a5 5 0 0 0 5 5a5 5 0 0 0 5-5h2z"
      fill="currentColor"
    />
  </svg>
);

const MicOffSvg = () => (
  <svg
    aria-hidden="true"
    focusable="false"
    width="1em"
    height="1em"
    // style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);"
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 24 24"
  >
    <path
      d="M19 11c0 1.19-.34 2.3-.9 3.28l-1.23-1.23c.27-.62.43-1.31.43-2.05H19m-4 .16L9 5.18V5a3 3 0 0 1 3-3a3 3 0 0 1 3 3v6.16M4.27 3L21 19.73L19.73 21l-4.19-4.19c-.77.46-1.63.77-2.54.91V21h-2v-3.28c-3.28-.49-6-3.31-6-6.72h1.7c0 3 2.54 5.1 5.3 5.1c.81 0 1.6-.19 2.31-.52l-1.66-1.66L12 14a3 3 0 0 1-3-3v-.72L3 4.27L4.27 3z"
      fill="currentColor"
    />
  </svg>
);

const MicOnIcon = props => <Icon component={MicOnSvg} {...props} />;

const MicOffIcon = props => <Icon component={MicOffSvg} {...props} />;

export { MicOffIcon, MicOnIcon };
