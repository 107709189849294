import React, { Component } from "react";
import {
  Layout,
  Button,
  Card,
  Descriptions,
  List,
  Result,
  message,
  Table,
} from "antd";
import moment from "moment";
import OnlineExamPage from "./OnlineExamPage";
import { api } from "../../../config";

class OnlineExamDetails extends Component {
  state = {
    exam: {},
    id: null,
    started: false,
    student_id: null,
    results: {},
    attempts: [],
  };

  componentDidMount() {
    if (this.props.location.state) {
      this.setState(
        {
          exam: this.props.location.state.exam,
          id: this.props.match.params.id,
          student_id: this.props.location.state.student_id,
        },
        this.previous_attempts
      );
    }
  }

  start_exam = () => {
    let exam_id = document.getElementById("online-exam");
    exam_id.requestFullscreen();
    document.onfullscreenchange = (e) => {
      if (document.fullscreenElement === null) {
        if (!this.state.results.status) {
          this.finish_online_exam({ status: "EXIT", data: {} });
        }
        this.setState({ started: false });
      }
    };
    this.setState({ started: true });
  };

  finish_online_exam = (results) => {
    let exam_id = document.getElementById("online-exam");
    if (
      document.fullscreenElement !== null &&
      exam_id === document.fullscreenElement
    ) {
      document.exitFullscreen();
    }
    if (results.status === "EXIT") {
      this.exam_forfeited();
    }
    this.setState({ results });
  };

  previous_attempts = () => {
    const { exam, student_id } = this.state;
    const url =
      "/exam/attempts/" + exam.exam_assign_id + "/" + student_id + "/";
    api
      .get(url)
      .then((res) => {
        if (res.data.success) {
          this.setState({ attempts: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  exam_forfeited = () => {
    const { exam, student_id } = this.state;
    const url =
      "/exam/attempts/" + exam.exam_assign_id + "/" + student_id + "/";
    api
      .patch(url)
      .then((res) => {
        if (!res.data.success) {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const { exam, started, id, student_id, results, attempts } = this.state;
    const { Item } = Descriptions;
    const columns = [
      { title: "ID", dataIndex: "id" },
      {
        title: "Attempt Date Time",
        dataIndex: "timestamp",
        render: (v) => (
          <span>{moment.utc(v).local().format("YYYY-MM-DD hh:mm A")}</span>
        ),
      },
      { title: "Marks Obtained", dataIndex: "marks_obtained" },
      {
        title: "Time taken",
        dataIndex: "duration",
        render: (v) => (
          <span>
            {!v ? "-" : Math.floor(v / 60) + " mins " + (v % 60) + " secs"}
          </span>
        ),
      },
      {
        title: "Status",
        dataIndex: "status",
        render: (v) => {
          switch (v) {
            case 1:
              return <span>Completed</span>;
            case -1:
              return <span>Did not complete</span>;
            default:
              return <span>Unknown</span>;
          }
        },
      },
    ];
    return (
      <Layout.Content>
        <Card title="Online Exam Details" style={{ marginBottom: 20 }}>
          <Descriptions>
            <Item label="Exam Name">{exam.name}</Item>
            <Item label="Description">{exam.description}</Item>
            <Item label="Duration">{exam.max_duration / 60} mins</Item>
            {exam.start_time && (
              <Item label="Start Time">{exam.start_time}</Item>
            )}
          </Descriptions>
        </Card>
        {!results.status && (
          <div>
            {attempts.length > 0 && (
              <Card title="Previous Attempts" style={{ marginBottom: 20 }}>
                <Table columns={columns} dataSource={attempts} rowKey="id" />
              </Card>
            )}
            <Card title="Notes for Online Tests" style={{ marginBottom: 20 }}>
              <List>
                <List.Item>
                  Use latest version of Firefox/Chrome for online tests
                </List.Item>
                <List.Item>
                  Once you click start, the browser goes into fullscreen mode
                  and the test starts.
                </List.Item>
                <List.Item>
                  Exiting from fullscreen manually will stop the test and
                  disqualfy you from test (avoid hitting Esc key).
                </List.Item>
                <List.Item>
                  The timer for the test is shown in top right corner of the
                  screen.
                </List.Item>
                <List.Item>
                  Once you finish the test you can click submit button (in top
                  right corner of screen)
                </List.Item>
                <List.Item>
                  The test results will be automatically submitted if the timer
                  counter hits 0
                </List.Item>
              </List>
            </Card>
            <Button
              type="primary"
              onClick={this.start_exam}
              disabled={attempts.length >= exam.max_attempts}
            >
              Start
            </Button>
            <div id="online-exam">
              {started && (
                <OnlineExamPage
                  id={id}
                  student_id={student_id}
                  exam={exam}
                  finish_online_exam={this.finish_online_exam}
                />
              )}
            </div>
          </div>
        )}
        {results.status === "EXIT" && (
          <Card>
            <Result status="warning" title="You exited the test" />
          </Card>
        )}
        {results.status === "SUCCESS" && (
          <Card>
            <Result
              status="success"
              title="You submitted your test"
              subTitle={"You scored " + results.data.marks_obtained + " marks"}
            >
              <p>
                <strong>
                  Total Questions :{" "}
                  {results.data.results.correct +
                    results.data.results.incorrect +
                    results.data.results.no_answer}
                </strong>
              </p>
              <p>
                Attempted Questions :{" "}
                {results.data.results.correct + results.data.results.incorrect}
              </p>
              <p>Correct Answers : {results.data.results.correct}</p>
              <p>Incorrect Answer : {results.data.results.incorrect}</p>
              <p>
                <strong>You Scored {results.data.marks_obtained} marks</strong>
              </p>
            </Result>
          </Card>
        )}
      </Layout.Content>
    );
  }
}

export default OnlineExamDetails;
