import React from "react";
import { Switch, Route } from "react-router-dom";
import QuestionPapers from "./QuestionPapers";
import NewQuestionPaper from "./NewQuestionPaper";

const TeacherQuestionPaperRouter = props => (
  <Switch>
    <Route exact path="/question-paper/" component={QuestionPapers} />
    <Route exact path="/question-paper/new" component={NewQuestionPaper} />
    <Route exact path="/question-paper/:id" component={NewQuestionPaper} />
  </Switch>
);

export { TeacherQuestionPaperRouter };
