import {
  Button,
  Card,
  Col,
  Descriptions,
  Layout,
  message,
  Row,
  Table,
  Tag,
} from "antd";
import React, { Component } from "react";
import { Currency } from "../../../components";
import { api } from "../../../config";
import moment from "moment";

class BillDetails extends Component {
  state = {
    id: undefined,
    bill: {},
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      this.setState(
        { id: this.props.match.params.id },
        this.__get_bill_details
      );
    }
  }

  __get_bill_details = () => {
    let { id } = this.state;
    api
      .get(`/eduga/bill/${id}/`)
      .then((res) => {
        if (res.data.success) {
          this.setState({ bill: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  __get_status = (status) => {
    switch (status) {
      case -1:
        return { text: "Failed", color: "red" };
      case 0:
        return { text: "Pending", color: "blue" };
      case 1:
        return { text: "Success", color: "green" };
      default:
        return { text: "Unknown", color: "" };
    }
  };

  render() {
    const { bill } = this.state;
    const { Item } = Descriptions;
    const columns = [
      { title: "ID", dataIndex: "id" },
      {
        title: "Amount",
        dataIndex: "amount",
        align: "right",
        render: (v) => <Currency value={v} />,
      },
      {
        title: "Payment Mode",
        dataIndex: "payment_mode",
        render: (v) => <span style={{ textTransform: "capitalize" }}>{v}</span>,
      },
      { title: "Description", dataIndex: "description" },
      {
        title: "Date",
        dataIndex: "timestamp",
        render: (v) => (
          <span>{moment.utc(v).local().format("YYYY-MM-HH hh:mm A")}</span>
        ),
      },
      {
        title: "Status",
        dataIndex: "status",
        render: (v) => {
          let status = this.__get_status(v);
          return <Tag color={status.color}>{status.text}</Tag>;
        },
      },
    ];
    return (
      <Layout.Content>
        <Row gutter={16}>
          <Col sm={12}>
            <h1>Bill Details</h1>
          </Col>
          <Col sm={12} className="action-buttons">
            <Button type="primary" icon="dollar">
              Pay Online
            </Button>
          </Col>
        </Row>
        <Card style={{ marginBottom: 16 }}>
          <Descriptions title="Bill Info">
            <Item label="Bill Title">{bill.name}</Item>
            <Item label="Description">{bill.description}</Item>
            <Item label="Amount">{<Currency value={bill.amount} />}</Item>
            <Item label="Paid">{<Currency value={bill.paid} />}</Item>
            <Item label="Discount">{<Currency value={bill.discount} />}</Item>
            <Item label="Balance">
              {<Currency value={bill.amount - bill.paid} />}
            </Item>
          </Descriptions>
        </Card>
        <Card title="Payment History">
          <Table
            size="small"
            columns={columns}
            expandedRowRender={(r, i) => (
              <span>
                {r.rzp_order_id ? (
                  <span>Razorpay Order ID : {r.rzp_order_id}</span>
                ) : (
                  <span> - </span>
                )}
              </span>
            )}
            dataSource={bill.payments || []}
            rowKey="id"
          />
        </Card>
      </Layout.Content>
    );
  }
}

export default BillDetails;
