import React, { Component } from "react";
import {
  Layout,
  Row,
  Col,
  Button,
  Spin,
  Switch,
  Select,
  InputNumber,
  message,
  Modal,
  Input,
} from "antd";
import { api } from "../../../config";

const styles = {
  rowStyle: {
    marginBottom: 15,
  },
};

class AdmissionsSettings extends Component {
  state = {
    settings: {
      adm_classes_list: [],
      adm_application_fees: null,
      adm_enabled: "0",
      adm_application_token: null,
      school_code: null,
      adm_short_url: null,
    },
    fees_message: "",
    spinning: false,
    loading: false,
  };

  componentDidMount() {
    this.get_admissions_settings();
  }

  get_admissions_settings = () => {
    this.setState({ spinning: true });
    api
      .get("/settings/admissions/")
      .then((res) => {
        if (res.data.success) {
          let data = res.data.data;
          let val = data.adm_application_fees;
          let fees_message = "";
          if (val < 10) {
            fees_message = "Application fees must be atleast ₹10";
          } else {
            // fees_message =
            //   "₹ " +
            //   (val - val * 0.05 - 10) +
            //   " + ₹" +
            //   (val * 0.05 + 10) +
            //   " (gateway charges)";
            fees_message = "";
          }
          this.setState({ settings: res.data.data, fees_message });
        } else {
          message.error(res.data.error);
        }
        this.setState({ spinning: false });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ spinning: false });
      });
  };

  update_admissions_settings = () => {
    let data = this.state.settings;
    this.setState({ loading: true });
    api
      .post("/settings/admissions/", JSON.stringify(data))
      .then((res) => {
        if (res.data.success) {
          message.success("Admissions Settings Updated");
        } else {
          message.error(res.data.error);
        }
        this.setState({ loading: false });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  handleTokenRefresh = () => {
    Modal.confirm({
      title: "Are you sure?",
      content:
        "Old URL will become obsolete and you need to update old url to new url and also update short URL",
      onOk: () => {
        api
          .put("/settings/admissions/")
          .then((res) => {
            if (res.data.success) {
              let settings = this.state.settings;
              settings.adm_application_token =
                res.data.data.adm_application_token;
              this.setState({ settings: settings });
              message.success("URL Updated");
            } else {
              message.error(res.data.error);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
      onCancel: () => {},
    });
  };

  render() {
    const { settings } = this.state;
    return (
      <Layout.Content>
        <Row style={styles.rowStyle}>
          <Col sm={8}>
            <h1>Admissions Settings</h1>
          </Col>
          <Col sm={4} offset={12}>
            <Button
              type="primary"
              loading={this.state.loading}
              onClick={this.update_admissions_settings}
            >
              Save
            </Button>
          </Col>
        </Row>
        <Spin spinning={this.state.spinning}>
          <Row style={styles.rowStyle}>
            <Col sm={16}>
              <h3>Enable Admissions Application</h3>
            </Col>
            <Col sm={8}>
              <Switch
                checked={settings.adm_enabled === "1" ? true : false}
                onChange={(val) => {
                  let { settings } = this.state;
                  settings.adm_enabled = val === true ? "1" : "0";
                  this.setState({ settings });
                }}
              />
            </Col>
          </Row>
          <Spin
            indicator={<span />}
            spinning={settings.adm_enabled === "0" ? true : false}
          >
            <Row style={styles.rowStyle}>
              <Col sm={16}>
                <h3>Admission Classes List</h3>
              </Col>
              <Col sm={8}>
                <Select
                  mode="tags"
                  style={{ width: "100%" }}
                  value={settings.adm_classes_list}
                  onChange={(val) => {
                    let { settings } = this.state;
                    settings.adm_classes_list = val;
                    this.setState({ settings });
                  }}
                ></Select>
              </Col>
            </Row>
            <Row style={styles.rowStyle}>
              <Col sm={16}>
                <h3>Application Fees</h3>
              </Col>
              <Col sm={8}>
                <InputNumber
                  value={settings.adm_application_fees}
                  onChange={(val) => {
                    let { settings } = this.state;
                    settings.adm_application_fees = val;
                    let fees_message = "";
                    if (val < 10) {
                      fees_message =
                        "Application fees must be atleast ₹10 or Empty for none";
                    } else {
                      // fees_message =
                      //   "₹ " +
                      //   (val - val * 0.05 - 10) +
                      //   " + ₹" +
                      //   (val * 0.05 + 10) +
                      //   " (gateway charges)";
                      fees_message = "";
                    }
                    this.setState({ settings, fees_message });
                  }}
                />
                {settings.adm_application_fees > 0 && (
                  <p style={{ display: "inline-block", marginLeft: 10 }}>
                    {this.state.fees_message}
                  </p>
                )}
              </Col>
            </Row>
            <Row style={styles.rowStyle}>
              <Col sm={16}>
                <h3>Application URL</h3>
              </Col>
              <Col sm={8}>
                <p className="application-url">
                  {"https://app.eduga.co.in/admissions/apply/" +
                    settings.school_code +
                    "/" +
                    settings.adm_application_token +
                    "/"}
                </p>
                <Button
                  icon="retweet"
                  onClick={this.handleTokenRefresh}
                ></Button>
              </Col>
            </Row>
            <Row style={styles.rowStyle}>
              <Col sm={16}>
                <h3>Application Short URL</h3>
              </Col>
              <Col sm={8}>
                <Input
                  placeholder="Short URL"
                  value={settings.adm_short_url}
                  onChange={(e) => {
                    let { settings } = this.state;
                    settings.adm_short_url = e.target.value;
                    this.setState({ settings });
                  }}
                />
              </Col>
            </Row>
          </Spin>
        </Spin>
      </Layout.Content>
    );
  }
}

export default AdmissionsSettings;
