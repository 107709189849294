import React, { Component } from "react";
import {
  Layout,
  Form,
  Input,
  InputNumber,
  DatePicker,
  Button,
  message,
  Spin,
  Upload
} from "antd";
import { api, getToken, baseurl } from "../../../config";
import moment from "moment";

class AddBook extends Component {
  state = {
    loading: false,
    book: {},
    id: null,
    spinning: false,
    photo: {}
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      this.get_book_details(this.props.match.params.id);
      this.setState({ id: this.props.match.params.id });
    }
  }

  get_book_details = id => {
    this.setState({ spinning: true });
    api
      .get("/library/book/" + id + "/")
      .then(res => {
        if (res.data.success) {
          let data = res.data.data;
          data.print_date = moment(data.print_date);
          data.purchase_date = moment(data.purchase_date);
          data.timestamp = moment(data.timestamp);
          this.setState({ book: data });
          this.props.form.setFieldsValue(data);
        } else {
          message.error(res.data.error);
        }
        this.setState({ spinning: false });
      })
      .catch(err => {
        console.log(err);
        this.setState({ spinning: false });
      });
  };

  handlePhotoUpload = info => {
    if (info.file.status === "done") {
      let photo = {
        name: info.file.name,
        path: info.file.response.path,
        uid: info.file.uid,
        url: baseurl + "/" + info.file.response.path
      };
      console.log(photo);
      this.setState({ photo });
    }
  };

  beforeUpload = file => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 < 200;
    if (!isLt2M) {
      message.error("Image must smaller than 200kB!");
    }
    return isJpgOrPng && isLt2M;
  };

  handleSubmit = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        val.image = this.state.photo;
        if (this.state.id) {
          this.update_book(this.state.id, val);
        } else {
          this.add_new_book(val);
        }
      }
    });
  };

  add_new_book = data => {
    this.setState({ loading: true });
    api
      .put("/library/books/", JSON.stringify(data))
      .then(res => {
        if (res.data.success) {
          message.success("New Book Added");
          this.props.history.push("/library/books/");
        } else {
          message.error(res.data.error);
        }
        this.setState({ loading: false });
      })
      .catch(err => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  update_book = (id, data) => {
    api
      .post("/library/book/" + id + "/", JSON.stringify(data))
      .then(res => {
        if (res.data.success) {
          message.success("Book Updated");
        } else {
          message.error(res.data.error);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  render() {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    const { id } = this.state;
    return (
      <Layout.Content>
        <h1 style={{ textAlign: "center" }}>
          {id ? "Update " : "Add New "} Book
        </h1>
        <Spin spinning={this.state.spinning}>
          <Form wrapperCol={{ sm: 8 }} labelCol={{ sm: 8 }}>
            <FormItem label="Book Code">
              {getFieldDecorator("code", {
                rules: [{ required: true, message: "Code is required!" }]
              })(<Input />)}
            </FormItem>
            <FormItem label="Title">
              {getFieldDecorator("title", {
                rules: [{ required: true, message: "Book title is required!" }]
              })(<Input />)}
            </FormItem>
            <FormItem label="Author">
              {getFieldDecorator("author", {
                rules: [{ required: true, message: "Book author is required!" }]
              })(<Input />)}
            </FormItem>
            <FormItem label="Publisher">
              {getFieldDecorator("publisher", {
                rules: [
                  { required: true, message: "Book publisher is required!" }
                ]
              })(<Input />)}
            </FormItem>
            <FormItem label="ISBN 10">
              {getFieldDecorator("isbn_10")(<Input />)}
            </FormItem>
            <FormItem label="ISBN 13">
              {getFieldDecorator("isbn_13")(<Input />)}
            </FormItem>
            <FormItem label="Edition">
              {getFieldDecorator("edition")(<Input />)}
            </FormItem>
            <FormItem label="Price">
              {getFieldDecorator("price")(<InputNumber />)}
            </FormItem>
            <FormItem label="Book category">
              {getFieldDecorator("category")(<Input />)}
            </FormItem>
            <FormItem label="Pages">
              {getFieldDecorator("pages")(<InputNumber />)}
            </FormItem>
            <FormItem label="Print Date">
              {getFieldDecorator("print_date")(<DatePicker />)}
            </FormItem>
            <FormItem label="Purchase Date">
              {getFieldDecorator("purchase_date")(<DatePicker />)}
            </FormItem>
            <FormItem label="Book Shelf No.">
              {getFieldDecorator("shelf_no")(<Input />)}
            </FormItem>
            <FormItem label="Book Photo">
              {getFieldDecorator("image")(
                <Upload
                  name="file"
                  action={baseurl + "/upload/?file_path=library"}
                  headers={{ Authorization: getToken() }}
                  accept=".jpg,.jpeg,.png"
                  beforeUpload={this.beforeUpload}
                  onChange={this.handlePhotoUpload}
                >
                  <Button>Upload</Button>
                </Upload>
              )}
            </FormItem>
            <FormItem wrapperCol={{ offset: 8 }}>
              <Button loading={this.state.loading} onClick={this.handleSubmit}>
                {id ? "Update " : "Add New "} Book
              </Button>
            </FormItem>
          </Form>
        </Spin>
      </Layout.Content>
    );
  }
}

export default Form.create()(AddBook);
