import React, { Component } from "react";
import {
  Layout,
  Row,
  Col,
  Button,
  Table,
  message,
  Spin,
  Modal,
  Icon,
  Upload,
} from "antd";
import { connect } from "react-redux";
import {
  ClassSectionDropdown,
  TestSelectionDropdown,
  AcademicYearDropdown,
} from "../../../components";
import { api, baseurl, getToken } from "../../../config";

let state = {
  class_id: null,
  ac_year_id: null,
  test_id: null,
  spinning: false,
  rows: [],
  columns: [],
  disabled: false,
  modalVisible: false,
};

const styles = {
  label: {
    display: "inline-block",
    minWidth: 80,
  },
};

class MarksViewer extends Component {
  state = state;

  componentWillUnmount() {
    state = this.state;
  }

  handleClassSelection = (class_id) => {
    this.setState({ class_id });
  };

  handleACYearChange = (ac_year_id) => {
    this.setState({ ac_year_id });
  };

  handleGo = () => {
    let { class_id, test_id, ac_year_id } = this.state;
    if (!(class_id && test_id && ac_year_id)) {
      message.error("Select Class, Section, Academic Year & Test");
      return;
    }
    this.getStudentsList(test_id, class_id, ac_year_id);
  };

  handleExport = () => {
    let { class_id, test_id, ac_year_id } = this.state;
    if (!(class_id && test_id && ac_year_id)) {
      message.error("Select Class, Section, Academic Year & Test");
      return;
    }
    let url = class_id + "/" + ac_year_id + "/" + test_id + "/";
    api
      .get("/export/empty_marks_list/" + url)
      .then((res) => {
        const link = document.createElement("a");
        link.href = baseurl + "/export/empty_marks_list/" + url;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getStudentsList = (test_id, class_id, ac_year_id) => {
    this.setState({ spinning: true, disabled: false });
    let url = "/marks/" + test_id + "/" + class_id + "/" + ac_year_id + "/";
    api
      .get(url)
      .then((res) => {
        if (res.data && res.data.success) {
          this.rearrange_data(res.data.data);
        } else {
          message.error(res.data.error);
        }
        this.setState({ spinning: false });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ spinning: false });
      });
  };

  rearrange_data = (data) => {
    let columns = [
      { title: "Admn. No.", dataIndex: "admission_no" },
      {
        title: "Student Name",
        dataIndex: "fullname",
        sorter: (a, b) =>
          a.fullname.toLowerCase() < b.fullname.toLowerCase() ? -1 : 1,
      },
    ];
    let rows = [];
    let subjects = [];
    let students = [];
    data.forEach((item) => {
      if (subjects.indexOf(item.subject) === -1) {
        subjects.push(item.subject);
        columns.push({
          title: item.subject,
          dataIndex: item.subject,
          width: "10%",
        });
      }
      if (students.indexOf(item.admission_no) === -1) {
        students.push(item.admission_no);
      }
    });
    data.forEach((item, index) => {
      let id = rows.findIndex((row) => {
        if (item.admission_no === row.admission_no) {
          row.key = index;
          row[item.subject] = item.marks_obtained;
          return true;
        }
        return false;
      });
      if (id === -1) {
        rows.push({
          admission_no: item.admission_no,
          key: index,
          [item.subject]: item.marks_obtained,
          fullname: item.fullname,
        });
      }
    });
    this.setState({ rows, columns });
  };

  approve_marks = () => {
    let { class_id, test_id } = this.state;
    if (!(class_id && test_id)) {
      message.error("Select Class, Section & Test");
      return;
    }
    Modal.confirm({
      title: "Approve marks?",
      content:
        "By approving marks SMS will be sent to parents with these marks if sms is enabled in settings",
      onOk: () => {
        this.setState({ disabled: true });
        api
          .get("/marks/approve/" + test_id + "/" + class_id + "/")
          .then((res) => {
            if (res.data && res.data.success) {
              message.success("Marks approved and messages sent");
            } else {
              message.error(res.data.error);
              this.setState({ disabled: false });
            }
          })
          .catch((err) => {
            console.log(err);
            this.setState({ disabled: false });
          });
      },
    });
  };

  normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  closeModal = () => {
    this.setState({ modalVisible: false });
  };

  handleFileUpload = (info) => {
    const { status } = info.file;
    if (status === "done") {
      let data = { csv_file_path: info.file.response.path };
      api
        .post("/import/marks/", JSON.stringify(data))
        .then((res) => {
          if (res.data && res.data.success) {
            message.success("Marks Updated");
            this.handleGo();
            this.closeModal();
          } else {
            message.error(res.data.error);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (status === "error") {
      message.error("Error uploading file, please check file format and size.");
    }
  };

  render() {
    const { role } = this.props.user;
    return (
      <Layout.Content>
        <h1>Marks Viewer</h1>
        <Row gutter={32} className="ribbon-row">
          <Col md={16}>
            <Row>
              <Col md={8} xs={24}>
                <span style={styles.label}>Class :</span>
                <ClassSectionDropdown
                  value={this.state.class_id}
                  onChange={this.handleClassSelection}
                />
              </Col>
              <Col md={8} xs={24}>
                <span style={styles.label}>AC Year :</span>
                <AcademicYearDropdown
                  value={this.state.ac_year_id}
                  onChange={this.handleACYearChange}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Col md={24} xs={24}>
                <span style={styles.label}>Test :</span>
                <TestSelectionDropdown
                  style={{ width: 150, maxWidth: "100%" }}
                  onChange={(test_id) => {
                    this.setState({ test_id });
                  }}
                  value={this.state.test_id}
                  ac_year_id={this.state.ac_year_id}
                />
                <Button onClick={this.handleGo} style={{ marginLeft: 10 }}>
                  Go
                </Button>
              </Col>
            </Row>
          </Col>
          <Col md={8} className="action-buttons">
            <Row>
              <Col style={{ marginBottom: 12 }}>
                <Button.Group>
                  <Button
                    type="primary"
                    onClick={() => {
                      this.props.history.push("/marks/update/");
                    }}
                  >
                    <Icon type="plus" />
                    Add Marks
                  </Button>
                  {(role === 3 || role === 4) && (
                    <Button
                      type="primary"
                      onClick={this.approve_marks}
                      disabled={this.state.disabled}
                    >
                      Approve Marks
                      <Icon type="check" />
                    </Button>
                  )}
                </Button.Group>
              </Col>
              <Col>
                <Button.Group>
                  <Button onClick={this.handleExport} type="primary">
                    <Icon type="download" />
                    Export
                  </Button>
                  <Button
                    type="primary"
                    onClick={() => {
                      this.setState({ modalVisible: true });
                    }}
                  >
                    Import
                    <Icon type="upload" />
                  </Button>
                </Button.Group>
              </Col>
            </Row>
          </Col>
        </Row>
        <Spin spinning={this.state.spinning}>
          <Table
            columns={this.state.columns}
            dataSource={this.state.rows}
            rowKey="key"
          />
        </Spin>
        <Modal
          visible={this.state.modalVisible}
          onCancel={this.closeModal}
          title="Upload Marks File"
        >
          <Upload.Dragger
            name="file"
            action={baseurl + "/upload/"}
            headers={{ Authorization: getToken() }}
            listType="csv"
            accept=".csv"
            onChange={this.handleFileUpload}
          >
            <p className="ant-upload-drag-icon">
              <Icon type="inbox" />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
          </Upload.Dragger>
        </Modal>
      </Layout.Content>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps, null)(MarksViewer);
