import React, { Component } from "react";
import { Button, Col, Icon, Layout, message, Modal, Row, Table } from "antd";
import { api } from "../../../config";

class ActiveVisitors extends Component {
  state = {
    visitors: [],
    spinning: false,
  };

  componentDidMount() {
    this.get_active_visitors();
  }

  get_active_visitors = () => {
    api
      .get("/visitors/active/")
      .then((res) => {
        if (res.data.success) {
          this.setState({ visitors: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  checkout_visitor = (r) => {
    Modal.confirm({
      title: "Mark visitor exit",
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        api
          .post(`/visitor/${r.id}/`, JSON.stringify({ exit_gate: null }))
          .then((res) => {
            if (res.data && res.data.success) {
              message.success("Visitor Exited!");
              this.get_active_visitors();
            } else {
              message.error(res.data.error);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
      onCancel: () => {},
    });
  };

  render() {
    const columns = [
      { title: "ID", dataIndex: "id" },
      { title: "Name", dataIndex: "name" },
      { title: "Mobile", dataIndex: "mobile" },
      { title: "Entry", dataIndex: "entry_timestamp" },
      { title: "Exit", dataIndex: "exit_timestamp" },
      {
        title: "Actions",
        render: (r) => (
          <span>
            <Icon
              type="clock-circle"
              className="action-icon"
              title="Checkout"
              onClick={this.checkout_visitor.bind(this, r)}
            />
          </span>
        ),
      },
    ];
    return (
      <Layout.Content>
        <Row>
          <Col md={12}>
            <h1>Active Visitors</h1>
          </Col>
          <Col md={12} className="action-buttons">
            <Button
              onClick={() => this.props.history.push("/visitors/list/")}
              type="primary"
            >
              Visitors History
            </Button>
            <Button
              type="primary"
              style={{ marginLeft: 8 }}
              icon="plus"
              onClick={() => this.props.history.push("/visitors/new/")}
            >
              New Visitor
            </Button>
          </Col>
        </Row>
        <Table
          columns={columns}
          dataSource={this.state.visitors}
          rowKey="id"
          loading={this.state.spinning}
        />
      </Layout.Content>
    );
  }
}

export default ActiveVisitors;
