import React from "react";
import { Switch, Route } from "react-router-dom";
import MeetingsList from "../../teacher/meetings/MeetingsList";
import JitsiMeeting from "../../admin/meetings/JitsiMeeting";

const StudentMeetingRouter = (props) => (
  <Switch>
    <Route exact path="/meeting/" component={MeetingsList} />
    <Route exact path="/meeting/room/:id" component={JitsiMeeting} />
  </Switch>
);

export { StudentMeetingRouter };
