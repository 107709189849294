import React, { Component } from "react";
import { Modal, Form, Input, Checkbox, message } from "antd";
import { api } from "../../../../config";

class ContactsFormModal extends Component {
  state = {
    student_id: null,
    confirmLoading: false,
    id: null,
  };

  componentDidMount() {
    let id = null;
    if (this.props.contact_id) {
      id = this.props.contact_id;
      this.get_contact_details(id);
    }
    this.setState({ student_id: this.props.student_id, id });
  }

  get_contact_details = (id) => {
    this.props.form.setFieldsValue(this.props.contact);
  };

  update_contact = (id, data) => {
    this.setState({ confirmLoading: true });
    api
      .post("/student/contact/" + id + "/", JSON.stringify(data))
      .then((res) => {
        if (res.data.success) {
          message.success("Contact details updated");
          this.props.closeModal();
        } else {
          message.error(res.data.error);
        }
        this.setState({ confirmLoading: false });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ confirmLoading: false });
      });
  };

  add_new_contact = (data) => {
    this.setState({ confirmLoading: true });
    api
      .put("/student/contacts/", JSON.stringify(data))
      .then((res) => {
        if (res.data.success) {
          message.success("Contact Added");
          this.props.closeModal();
        } else {
          message.error(res.data.error);
        }
        this.setState({ confirmLoading: false });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ confirmLoading: false });
      });
  };

  handleSubmit = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        let { student_id, id } = this.state;
        val.student_id = student_id;
        if (id) {
          this.update_contact(id, val);
        } else {
          this.add_new_contact(val);
        }
      }
    });
  };

  render() {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    const { id } = this.state;
    return (
      <Modal
        visible={true}
        title={id ? "Update Contact" : "Add New Contact"}
        okText="Save"
        onOk={this.handleSubmit}
        onCancel={this.props.closeModal}
      >
        <Form>
          <FormItem label="Contact Name">
            {getFieldDecorator("contact_name", {
              rules: [{ required: true, message: "Contact name is required!" }],
            })(<Input placeholder="Contact Name" />)}
          </FormItem>
          <FormItem label="Mobile">
            {getFieldDecorator("mobile", {
              rules: [{ required: true, message: "Movile is requried!" }],
            })(<Input placeholder="Mobile" />)}
          </FormItem>
          <FormItem label="Email">
            {getFieldDecorator("email")(<Input placeholder="Email" />)}
          </FormItem>
          <FormItem label="Address">
            {getFieldDecorator("address")(<Input placeholder="Address" />)}
          </FormItem>
          <FormItem label="Student Relation">
            {getFieldDecorator("student_relation")(
              <Input placeholder="Parent/Neighbour" />
            )}
          </FormItem>
          <FormItem>
            {getFieldDecorator("enable_sms", { valuePropName: "checked" })(
              <Checkbox>
                Enable SMS <small>(You can enable SMS in 2 contacts max)</small>
              </Checkbox>
            )}
          </FormItem>
          <FormItem>
            {getFieldDecorator("is_emergency_contact", {
              valuePropName: "checked",
            })(<Checkbox>Is Emergency Contact</Checkbox>)}
          </FormItem>
          <FormItem label="Aadhar No.">
            {getFieldDecorator("extras.aadhar_no")(<Input />)}
          </FormItem>
          <FormItem label="PAN Card No.">
            {getFieldDecorator("extras.pan_no")(<Input />)}
          </FormItem>
        </Form>
      </Modal>
    );
  }
}

export default Form.create()(ContactsFormModal);
