import React, { Component } from "react";
import {
  Layout,
  Descriptions,
  Card,
  Button,
  Row,
  Col,
  message,
  Icon,
  notification,
  Radio,
  Progress,
  Upload,
  Modal,
} from "antd";
import { invokeSaveAsDialog, RecordRTCPromisesHandler } from "recordrtc";
import { connect } from "react-redux";
import io from "socket.io-client";
import { baseurl, getToken, api } from "../../../config";
import WhiteBoard from "./WhiteBoard";
import SimplePeer from "simple-peer";
import { MicOffIcon, MicOnIcon } from "../../../icons/mic";
import moment from "moment";
import axios from "axios";

class VirtualClassRoom extends Component {
  state = {
    peers: {},
    details: {},
    whiteboard: false,
    stream: null,
    status: {
      code: null,
      message: "",
    },
    localMute: false,
    remoteMute: true,
    buttons: {
      start: false,
      started: false,
      stop: false,
      upload: false,
      download: false,
      pause: false,
    },
    recorder: null,
    students: {},
    active_users: 0,
    questions: [],
    video_source: null,
    show_users: false,
    show_upload_progress: false,
    upload_progress: 0,
    video_details: null,
    video_playing: false,
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      this.get_class_details(this.props.match.params.id);
    }
  }

  get_class_details = (id) => {
    api
      .get("/virtual-class/" + id + "/")
      .then((res) => {
        if (res.data.success) {
          let { status } = this.state;
          let details = res.data.data;
          let video_details = details.public_url;
          status.code = details.status;
          switch (status.code) {
            case -1:
              status.message = "Class cancelled";
              break;
            case 0:
              status.message = "Class not yet started";
              break;
            case 1:
              status.message = "Class started";
              break;
            case 2:
              status.message = "Class ended";
              break;
            case 3:
              status.message = "Streaming Available";
              break;
            default:
              status.message = "Not your class";
          }
          if (video_details) {
            video_details = JSON.parse(video_details);
          }
          this.setState({ details, status, video_details }, () => {
            if ([0, 1].includes(status.code) && this.props.user.role === 2) {
              this.enable_socket();
            }
          });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {});
  };

  enable_socket = () => {
    let { details, status } = this.state;

    let data = {
      token: getToken(),
      vc_class: details,
      user: this.props.user,
    };
    let socket = io(baseurl);
    socket.on("connect", () => {
      socket.emit("vc_room", { type: "authenticate", data });
      if (status.code === 1) {
        this.start_session();
      }
    });
    socket.on("vc_room", (data) => {
      if ("success" in data && data.success === false) {
        if (data.error === "Invalid Teacher") {
          let { status } = this.state;
          status.code = -2;
          status.message = "Not your class";
          this.setState({ status });
        }
        message.error(data.error);
      }
    });

    socket.on("vc_room_join", (data) => {
      this.add_student(data);
      this.add_peer(data);
    });

    socket.on("vc_ask_question", (data) => {
      let { questions, students } = this.state;
      if (data.sid in students) {
        notification.open({
          message: "Question from " + students[data.sid].name,
          description: data.data,
        });
        questions.push({
          student_id: students[data.sid].id,
          name: students[data.sid].name,
          question: data.data,
        });
        this.setState({ questions });
      }
    });

    socket.on("vc_room_answer", (data) => {
      this.handleStudentStateChange(data);
    });

    this.setState({ socket });
  };

  handleStudentStateChange = (data) => {
    let { peers } = this.state;
    let p = peers[data.sid];
    p.signal(JSON.parse(data.data));
    p._onIceStateChange = () => {
      let { students, active_users } = this.state;
      try {
        if (p._pc.iceConnectionState === "connected") {
          students[data.sid].status = 1;
        } else if (p._pc.iceConnectionState === "disconnected") {
          students[data.sid].status = -1;
        }
        let counter = 0;
        for (let key in students) {
          if (students[key].status === 1) {
            counter++;
          }
        }
        active_users = counter;
      } catch (err) {
        console.log(err);
      }

      this.setState({ students, active_users });
    };
  };

  add_student = (data) => {
    let { students } = this.state;
    if (data.student) {
      let s = Object.keys(students).find((item) => {
        return item.id === data.student.id;
      });
      s ||
        (students[data.sid] = {
          id: data.student.id,
          name: data.student.fullname,
          admission_no: data.student.admission_no,
          class_id: data.student.class_id,
          sid: data.sid,
          status: 0,
        });
    }
    this.setState({ students });
  };

  add_peer = (sock_data) => {
    let { peers, stream, students } = this.state;
    let p = new SimplePeer({
      initiator: true,
      stream: stream,
      trickle: false,
    });

    p.on("signal", (data) => {
      if (data && data.type && data.type === "offer") {
        let req = {
          type: "offer",
          data: {
            sid: sock_data.sid,
            data: JSON.stringify(data),
          },
        };
        this.state.socket.emit("vc_room", req);
      }
    });
    p.on("data", (data) => {
      let str = new TextDecoder("utf-8").decode(data);
      let j = JSON.parse(str);
      if (j.type === "image") {
        students[sock_data.sid].imageURI = j.uri;
      } else {
        console.log(data);
      }
      this.setState({ students });
    });
    p.on("error", (err) => {
      console.log(err);
    });
    p.on("connect", () => {
      console.log("Connected");
    });
    p.on("stream", (stream) => {
      let audio_player = document.getElementById("audio-players");
      let audio = document.createElement("audio");
      audio.setAttribute("controls", "");
      audio_player.appendChild(audio);
      if ("srcObject" in audio) {
        audio.srcObject = stream;
      } else {
        console.log("No Src object");
        audio.src = window.URL.createObjectURL(stream);
      }
    });
    p.on("close", () => {
      console.log("Closed");
    });
    peers[sock_data.sid] = p;
    this.setState({ peers });
  };

  get_permissions = async () => {
    try {
      let {
        socket,
        details,
        status,
        buttons,
        video_source,
        stream,
      } = this.state;
      let audio, screen;
      stream &&
        stream.getTracks().forEach((track) => {
          track.stop();
        });
      if (video_source === null) {
        message.error("Select Video Source");
        return;
      } else if (video_source === "screen") {
        audio = await navigator.mediaDevices.getUserMedia({ audio: true });
        screen = await navigator.mediaDevices.getDisplayMedia({
          video: true,
          audio: false,
        });
      } else if (video_source === "camera") {
        audio = await navigator.mediaDevices.getUserMedia({ audio: true });
        screen = await navigator.mediaDevices.getUserMedia({
          video: {
            width: { ideal: 1280 },
            height: { ideal: 720 },
            facingMode: "environment",
          },
        });
      }

      if (details.status === 1) {
        socket.emit("vc_room", {
          type: "session",
          data: { status: "RESTART", vc_class: details },
        });
      } else {
        socket.emit("vc_room", {
          type: "session",
          data: { status: "START", vc_class: details },
        });
        details.status = 1;
      }

      stream = new MediaStream();
      screen.getTracks().forEach((track) => {
        stream.addTrack(track);
      });
      audio.getTracks().forEach((track) => {
        stream.addTrack(track);
      });
      if (video_source === "camera") {
        var video = document.getElementById("video");
        if ("srcObject" in video) {
          video.srcObject = stream;
        } else {
          video.src = window.URL.createObjectURL(stream); // for older browsers
        }
        video.play();
      }
      status.code = 1;
      status.message = "Class started";
      buttons.start = true;
      this.setState({ stream: stream, status, buttons, details });
    } catch (err) {
      if (err.name && err.name === "NotAllowedError") {
        message.error("Please allow Microphone and Screen Access");
      } else if (err.name && err.name === "NotFoundError") {
        message.error("Microphone not found");
        console.log(err);
      } else {
        console.log(err);
        message.error(err.message);
      }
    }
  };

  start_session = () => {
    this.get_permissions();
  };

  end_session = () => {
    let { socket, details, status, stream, buttons, recorder } = this.state;
    socket.emit("vc_room", {
      type: "session",
      data: { status: "END", vc_class: details },
    });
    stream &&
      stream.getTracks().forEach((track) => {
        track.stop();
      });
    let audios = document.querySelectorAll("audio");
    audios.forEach((audio) => {
      audio.remove();
    });
    status.code = 2;
    status.message = "Class ended";
    buttons.start = false;
    recorder && this.handleStopRecording();
    this.setState({ status, students: {}, buttons });
  };

  closeBoard = () => {
    this.setState({ whiteboard: false });
  };

  handleLocalMute = () => {
    let { localMute, stream } = this.state;
    stream &&
      stream.getAudioTracks().forEach((track) => {
        track.enabled = localMute;
      });
    this.setState({ localMute: !localMute });
  };

  handleRemoteMute = () => {
    let { remoteMute } = this.state;
    let audios = document.querySelectorAll("audio");
    audios.forEach((audio) => {
      if (audio.paused) {
        audio.play();
      } else {
        audio.muted = !remoteMute;
      }
    });
    this.setState({ remoteMute: !remoteMute });
  };

  handleStartRecording = () => {
    let { stream, buttons } = this.state;
    if (stream) {
      let recorder = new RecordRTCPromisesHandler(stream);
      recorder.startRecording();
      buttons.start = false;
      buttons.started = true;
      buttons.stop = true;
      this.setState({ buttons, recorder });
    }
  };

  handlePauseRecording = () => {
    let { recorder, buttons } = this.state;
    buttons.pause = true;
    recorder.pauseRecording();
    this.setState({ buttons });
  };

  handleResumeRecording = () => {
    let { recorder, buttons } = this.state;
    buttons.pause = false;
    recorder.resumeRecording();
    this.setState({ buttons });
  };

  handleStopRecording = () => {
    let { recorder, buttons } = this.state;
    buttons.download = true;
    buttons.upload = true;
    buttons.stop = false;
    buttons.started = false;
    recorder.stopRecording();
    this.setState({ buttons });
  };

  handleUploadRecording = async () => {
    let { recorder, details } = this.state;
    let blob = await recorder.getBlob();
    let data = {
      name: details.name,
      content_type: blob.type,
      size: blob.size,
    };
    this.check_upload_size(data, blob);
  };

  check_upload_size = (data, blob) => {
    if (blob.size > 500 * 1024 * 1024) {
      Modal.confirm({
        title: "File size too big, sure to continue?",
        content: (
          <p>
            The file size is larger than 500MB, it'll consume too much data
            while streaming.
            <br />
            It is recommended to upload 720p video below 500MB.
          </p>
        ),
        onOk: () => {
          this.getUploadURL(data, blob);
        },
        onCancel: () => {
          message.info("File upload cancelled");
        },
      });
    } else {
      this.getUploadURL(data, blob);
    }
  };

  getUploadURL = (data, blob) => {
    api
      .put("/upload/", JSON.stringify(data))
      .then((res) => {
        if (res.data.success) {
          this.uploadVideoRecording(res.data.data, blob);
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log("Old Error ", err);
      });
  };

  updateFileUploadStatus = (data) => {
    let { details } = this.state;
    delete data["url"];
    api
      .post(
        "/virtual-class/" + details.id + "/",
        JSON.stringify({ data, type: "upload" })
      )
      .then((res) => {
        if (res.data.success) {
          message.success("Video Uploaded");
          this.setState({ video_details: data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  uploadVideoRecording = (data, blob) => {
    this.setState({ show_upload_progress: true });
    axios
      .put(data.url, blob, {
        onUploadProgress: (e) => {
          let progress = Math.round((e.loaded * 100) / e.total);
          this.setState({
            upload_progress: progress,
          });
        },
        headers: {
          "Content-Type": blob.type,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          this.updateFileUploadStatus(data);
        }
      })
      .catch((err) => {
        console.log("Upload Failed ", err);
        message.error("Upload Failed !");
      });
  };

  handleDownloadRecording = async () => {
    let { recorder, details } = this.state;
    let blob = await recorder.getBlob();
    invokeSaveAsDialog(blob, details.name);
  };

  handlePlayClick = () => {
    let { video_details, status } = this.state;
    if ([2, 3].includes(status.code) && video_details) {
      let video = document.getElementById("video-player");
      if (!video.src) {
        this.get_video_link();
      }
    }
  };

  get_video_link = (type) => {
    let { details } = this.state;
    api
      .get("/virtual-class/video/" + details.id + "/")
      .then((res) => {
        if (res.data.success) {
          let url = res.data.data.url;
          if (type && type === "download") {
            this.downloadFile(res.data.data);
          } else {
            this.play_video(url);
          }
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
        message.error("Network Error");
      });
  };

  downloadFile = (video) => {
    let { details } = this.state;
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = video.url;
    a.download = details.name;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  play_video = (url) => {
    let video = document.getElementById("video-player");
    if (!video.src) {
      video.src = url;
    }
    video.play();
    this.setState({ video_playing: true });
  };

  allow_streaming = () => {
    let { details } = this.state;
    api
      .post(
        "/virtual-class/" + details.id + "/",
        JSON.stringify({ type: "approve" })
      )
      .then((res) => {
        if (res.data.success) {
          message.success("Now students can stream this class");
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  manualFileUpload = (e) => {
    let { video_details } = this.state;
    if (video_details) {
      Modal.confirm({
        title: "Are you sure?",
        content: "Old video will be removed?",
        onOk: () => {
          let data = {
            name: e.name,
            content_type: e.type,
            size: e.size,
          };
          this.check_upload_size(data, e);
        },
        okType: "danger",
      });
    } else {
      let data = {
        name: e.name,
        content_type: e.type,
        size: e.size,
      };
      this.check_upload_size(data, e);
    }
    return false;
  };

  render() {
    const Item = Descriptions.Item;
    const {
      details,
      status,
      localMute,
      remoteMute,
      buttons,
      students,
      active_users,
      questions,
      video_source,
      show_users,
      show_upload_progress,
      upload_progress,
      video_details,
      video_playing,
    } = this.state;
    const { user } = this.props;
    return (
      <Layout.Content className="teacher-virtual-classroom">
        <Row>
          <Col sm={12}>
            <h1>Class Room</h1>
          </Col>
          <Col sm={12} style={{ textAlign: "right" }}>
            <Button
              style={{ marginRight: 16 }}
              onClick={() => {
                this.props.history.push({
                  pathname: "/virtual-classroom/" + details.id + "/questions/",
                  state: { student: {} },
                });
              }}
            >
              Questions
            </Button>
            {[2, 3].includes(status.code) && (
              <>
                {status.code === 2 && (
                  <Upload
                    className="vc_class_upload"
                    beforeUpload={this.manualFileUpload}
                    accept=".mp4,.webm,.mkv,.avi"
                    multiple={false}
                  >
                    <Button
                      icon="upload"
                      style={{ marginRight: 16 }}
                      loading={
                        show_upload_progress && !(upload_progress === 100)
                      }
                    >
                      Upload
                    </Button>
                  </Upload>
                )}
                <Button
                  icon="download"
                  style={{ marginRight: 16 }}
                  onClick={() => {
                    this.get_video_link("download");
                  }}
                >
                  Download
                </Button>
              </>
            )}
            {[3, 4].includes(user.role) &&
              status.code === 2 &&
              video_details && (
                <Button onClick={this.allow_streaming}>Allow Streaming</Button>
              )}
          </Col>
        </Row>
        <p>
          Note 1 : To use this feature please use Firefox 66+/Chrome 72+/Edge
          79+
        </p>
        <p>
          Note 2 : To use record feature make sure you have atleast 2 GB Ram
          free (need 1GB storage for 1hr 1080p video)
        </p>
        <Card>
          <Descriptions>
            <Item label="Class Name">{details.name}</Item>
            <Item label="Class">{details.class + " " + details.section}</Item>
            <Item label="Description">{details.description}</Item>
            <Item label="Subject">{details.subject}</Item>
            <Item label="Teachers">{details.teacher}</Item>
            <Item label="Class Created On">{details.timestamp}</Item>
            <Item label="Start Time">
              {moment
                .utc(details.start_time)
                .local()
                .format("YYYY-MM-DD HH:mm A")}
            </Item>
            <Item label="End Time">
              {moment
                .utc(details.end_time)
                .local()
                .format("YYYY-MM-DD HH:mm A")}
            </Item>
            <Item />
            <Item label="Actual Start Time">
              {details.actual_start_time
                ? moment
                    .utc(details.actual_start_time)
                    .local()
                    .format("YYYY-MM-DD HH:mm A")
                : "-"}
            </Item>
            <Item label="Actual End Time">
              {details.actual_end_time
                ? moment
                    .utc(details.actual_end_time)
                    .local()
                    .format("YYYY-MM-DD HH:mm A")
                : "-"}
            </Item>
            <Item />
            <Item label="Status">
              <p style={{ margin: 0, fontWeight: "bold", color: "#1890ff" }}>
                {status.message}
              </p>
            </Item>
            <Item label="Active Students">{active_users}</Item>
            <Item label="Video Source">
              <Radio.Group
                disabled={user.role !== 2}
                onChange={(e) => {
                  this.setState({ video_source: e.target.value }, () => {
                    if (status.code === 1) {
                      this.start_session();
                    }
                  });
                }}
                value={video_source}
              >
                <Radio value="screen">Screen</Radio>
                <Radio value="camera">Camera</Radio>
              </Radio.Group>
            </Item>
          </Descriptions>
        </Card>
        <Row style={{ marginTop: 20 }}>
          {user.role === 2 && (
            <Col sm={24}>
              <Button.Group style={{ marginBottom: 16, marginRight: 8 }}>
                <Button
                  type="primary"
                  onClick={this.start_session}
                  disabled={status.code !== 0}
                >
                  Start
                </Button>
                <Button
                  type="danger"
                  onClick={this.end_session}
                  disabled={status.code !== 1}
                >
                  End
                </Button>
              </Button.Group>
              <Button.Group style={{ marginRight: 8, marginBottom: 16 }}>
                <Button onClick={this.handleLocalMute}>
                  {!localMute ? (
                    <span>
                      <MicOffIcon style={{ marginRight: 6 }} />
                      Mute Local Audio
                    </span>
                  ) : (
                    <span>
                      <MicOnIcon style={{ marginRight: 6 }} />
                      Unmute Local Audio
                    </span>
                  )}
                </Button>
                <Button onClick={this.handleRemoteMute}>
                  {!remoteMute ? (
                    <span>
                      Mute Remote Audio
                      <MicOffIcon style={{ marginLeft: 6 }} />
                    </span>
                  ) : (
                    <span>
                      Unmute Remote Audio
                      <MicOnIcon style={{ marginLeft: 6 }} />
                    </span>
                  )}
                </Button>
              </Button.Group>
              <Button.Group style={{ marginBottom: 16, marginRight: 8 }}>
                {!buttons.started && (
                  <Button
                    disabled={!buttons.start}
                    onClick={this.handleStartRecording}
                  >
                    <Icon type="play-circle" /> Start Recording
                  </Button>
                )}
                {buttons.started && (
                  <>
                    {!buttons.pause ? (
                      <Button onClick={this.handlePauseRecording}>
                        <Icon type="pause" /> Pause Recording
                      </Button>
                    ) : (
                      <Button onClick={this.handleResumeRecording}>
                        <Icon type="play-circle" /> Resume Recording
                      </Button>
                    )}
                  </>
                )}
                <Button
                  disabled={!buttons.stop}
                  onClick={this.handleStopRecording}
                >
                  Stop Recording
                  <Icon type="stop" />
                </Button>
                <Button
                  disabled={!buttons.upload}
                  onClick={this.handleUploadRecording}
                  loading={show_upload_progress && !(upload_progress === 100)}
                >
                  <Icon type="cloud-upload" />
                  Upload
                </Button>
                <Button
                  disabled={!buttons.download}
                  onClick={this.handleDownloadRecording}
                >
                  Download
                  <Icon type="download" />
                </Button>
              </Button.Group>
              <Button
                style={{ marginBottom: 16, marginRight: 8 }}
                onClick={() => {
                  this.setState({ show_users: true });
                }}
              >
                Show Students
              </Button>
              <Button
                type="primary"
                style={{ marginBottom: 16 }}
                onClick={() => {
                  this.setState({ whiteboard: true });
                }}
              >
                White Board
              </Button>
            </Col>
          )}
        </Row>
        {show_upload_progress && (
          <div style={{ marginBottom: 16 }}>
            <Progress percent={upload_progress} />
          </div>
        )}
        {video_source === "camera" && (
          <div className="teacher-video">
            <video id="video" controls muted={true} />
          </div>
        )}
        {[2, 3].includes(status.code) && video_details && (
          <div className="teacher-video">
            {!video_playing && (
              <Icon
                type="play-circle"
                className="play-icon"
                onClick={this.handlePlayClick}
              />
            )}
            <video id="video-player" controls onPlay={this.handlePlayClick} />
          </div>
        )}
        <Row gutter={24}>
          <Col sm={6}>
            <Card title="Online Students List">
              <div className="vc_room_list">
                <ul>
                  {Object.keys(students).map((item, index) => {
                    if (students[item].status === 1) {
                      return (
                        <li key={index} className="present">
                          {students[item].name}
                        </li>
                      );
                    } else if (students[item].status === -1) {
                      return (
                        <li key={index} className="absent">
                          {students[item].name}
                        </li>
                      );
                    } else {
                      return <li key={index}>{students[item].name}</li>;
                    }
                  })}
                </ul>
              </div>
            </Card>
          </Col>
          <Col sm={18}>
            <Card title="Questions">
              <div className="vc_room_list">
                <ul>
                  {questions.map((item, index) => {
                    return (
                      <li key={index}>
                        {item.question + " - by " + item.name}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </Card>
          </Col>
        </Row>
        <div id="audio-players" style={{ visibility: "hidden" }} />
        {this.state.whiteboard && <WhiteBoard close={this.closeBoard} />}
        <div className="show-users" style={{ zIndex: show_users ? 3 : -1 }}>
          <Icon
            type="close-square"
            className="close-users-list"
            onClick={() => {
              this.setState({ show_users: false });
            }}
          />
          <Row gutter={4}>
            {Object.keys(students).map((item, index) => {
              return (
                <Col sm={4} key={index}>
                  <div className="user-thumbnail">
                    <p>{students[item].name}</p>
                    <img src={students[item].imageURI} alt="Student Avatar" />
                  </div>
                </Col>
              );
            })}
          </Row>
        </div>
      </Layout.Content>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps, null)(VirtualClassRoom);
