import React from "react";
import { Switch, Route } from "react-router-dom";

import AnnouncementsList from "./AnnouncementsList";
import NewAnnouncements from "./NewAnnouncements";
import WebAnnouncements from "./WebAnnouncements";

const AdminAnnouncementRouter = (props) => (
  <Switch>
    <Route exact path="/announcements" component={AnnouncementsList} />
    <Route exact path="/announcements/new" component={NewAnnouncements} />
    <Route exact path="/announcements/web" component={WebAnnouncements} />
  </Switch>
);

export { AdminAnnouncementRouter };
