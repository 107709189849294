import React, { Component } from "react";

class Logout extends Component {
  state = {};
  componentDidMount() {
    window.localStorage.clear();
    window.sessionStorage.clear();
    window.location.replace("/");
  }
  render() {
    return (
      <div style={{ position: "fixed", width: "100%", height: "100%" }}>
        <div
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)"
          }}
        >
          <img
            src={"/public/images/loading.gif"}
            alt="Loading"
            style={{ transform: "scale(0.66)" }}
          />
        </div>
      </div>
    );
  }
}

export default Logout;
