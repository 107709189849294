import { Form, Input, message, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { api } from "../../../../config";

const capitalize = (text) => {
  return text[0].toLocaleUpperCase() + text.slice(1);
};

function EditStudentProfileItem({ close, student_id, item = {} }) {
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    setLoading(true);
    let data = {
      student_id,
      name: item.name,
      value: value,
    };
    api
      .post(`/student/profile/item/`, JSON.stringify(data))
      .then((res) => {
        if (res.data.success) {
          message.success("Student profile item updated");
          close();
        } else {
          message.error(res.data.error);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (item.value) {
      setValue(item.value);
    }
  }, [item]);

  return (
    <Modal
      visible={true}
      title="Update Student Profile Item"
      onOk={handleSubmit}
      onCancel={close}
      confirmLoading={loading}
    >
      <Form.Item label={capitalize(item.name)}>
        <Input value={value} onChange={(e) => setValue(e.target.value)} />
      </Form.Item>
    </Modal>
  );
}

export default EditStudentProfileItem;
