import React, { Component } from "react";
import { Layout, Form, Input, Button, message, Select } from "antd";
import { api } from "../../../../config";

class LeadForm extends Component {
  state = {
    loading: false,
    id: null,
    lead: {},
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      this.setState({ id: this.props.match.params.id });
    }

    if (this.props.location.state && this.props.location.state.id) {
      this.setState({ lead: this.props.location.state }, () => {
        this.props.form.setFieldsValue(this.state.lead);
      });
    }
  }

  add_new_lead = (data) => {
    api
      .put("/admissions/leads/", JSON.stringify(data))
      .then((res) => {
        if (res.data.success) {
          message.success("New Lead added");
          this.props.history.push("/admissions/leads/");
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  update_lead = (id, data) => {
    api
      .post("/admissions/lead/" + id + "/", JSON.stringify(data))
      .then((res) => {
        if (res.data.success) {
          message.success("Lead Updated");
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleSubmit = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        if (this.state.id) {
          this.update_lead(this.state.id, val);
        } else {
          this.add_new_lead(val);
        }
      }
    });
  };

  render() {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    const { Option } = Select;
    const { id, lead } = this.state;
    let extras_keys = ["dob", "gender", "reason", "referrer"];
    return (
      <Layout.Content>
        <h1 style={{ textAlign: "center" }}>
          {id ? "Update Lead" : "Add Lead"}
        </h1>
        <Form labelCol={{ sm: 8 }} wrapperCol={{ sm: 8 }}>
          <FormItem label="Parent/Guardians Name">
            {getFieldDecorator("parent_name", {
              rules: [{ required: true, message: "Name is required!" }],
            })(<Input />)}
          </FormItem>
          <FormItem label="Mobile">
            {getFieldDecorator("mobile", {
              rules: [{ required: true, message: "Mobile is required!" }],
            })(<Input />)}
          </FormItem>
          <FormItem label="Email">
            {getFieldDecorator("email")(<Input type="email" />)}
          </FormItem>
          <FormItem label="Student Name">
            {getFieldDecorator("student_name")(<Input />)}
          </FormItem>
          <FormItem label="Class">
            {getFieldDecorator("class")(<Input />)}
          </FormItem>
          <FormItem label="Status">
            {getFieldDecorator("status", {
              initialValue: 0,
              rules: [{ required: true, message: "Status is required!" }],
            })(
              <Select>
                <Option value={-3}>Not interested</Option>
                <Option value={-2}>Rejected (by parent)</Option>
                <Option value={-1}>Rejected (by school)</Option>
                <Option value={0}>Unknown (no contact yet)</Option>
                <Option value={1}>Follow up (positive)</Option>
                <Option value={2}>Follow up (negative)</Option>
                <Option value={3}>Decision Pending (by parent)</Option>
                <Option value={4}>Decision Pending (by school)</Option>
                <Option value={5}>Confirmed (but admission pending)</Option>
                <Option value={6}>Qualified (admission done)</Option>
              </Select>
            )}
          </FormItem>
          <FormItem label="Date of Birth">
            {getFieldDecorator("extras.dob")(<Input />)}
          </FormItem>
          <FormItem label="Gender">
            {getFieldDecorator("extras.gender")(<Input />)}
          </FormItem>
          <FormItem label="Comments">
            {getFieldDecorator("extras.reason")(<Input />)}
          </FormItem>
          <FormItem label="Referrer">
            {getFieldDecorator("extras.referrer")(<Input />)}
          </FormItem>
          {lead &&
            lead.extras &&
            Object.keys(lead.extras).map((item, index) => {
              if (extras_keys.includes(item)) {
                return <></>;
              } else {
                return (
                  <FormItem
                    key={index}
                    label={
                      <span style={{ textTransform: "capitalize" }}>
                        {item}
                      </span>
                    }
                  >
                    {getFieldDecorator(`extras[${item}]`, {
                      initialValue: lead.extras[item],
                    })(<Input />)}
                  </FormItem>
                );
              }
            })}
          <FormItem wrapperCol={{ offset: 8 }}>
            <Button onClick={this.handleSubmit}>
              {id ? "Update Lead" : "Add Lead"}
            </Button>
          </FormItem>
        </Form>
      </Layout.Content>
    );
  }
}

export default Form.create()(LeadForm);
