import React, { Component } from "react";
import { Layout, Menu, Dropdown, Avatar, Badge, Popover, Empty } from "antd";
import { Link } from "react-router-dom";
import { api } from "../../config";
import moment from "moment";

const { Header, Footer } = Layout;

const GuestHeaderMenu = (props) => (
  <Header className="guest-header-menu" style={{ display: "block" }}>
    <h1 className="title align-center">{props.school_name}</h1>
  </Header>
);

const menu = () => (
  <Menu>
    <Menu.Item key="1">
      <Link to="/">Home</Link>
    </Menu.Item>
    <Menu.Item key="2">
      <Link to="/profile">Profile</Link>
    </Menu.Item>
    <Menu.Item key="3">
      <Link to="/settings">Settings</Link>
    </Menu.Item>
    <Menu.Item key="4">
      <Link to="/logout">Logout</Link>
    </Menu.Item>
  </Menu>
);

class Notifications extends Component {
  state = {
    notifications: [],
  };

  componentDidMount() {
    this.get_notifications();
  }

  get_notifications = () => {
    api
      .get("/notifications/")
      .then((res) => {
        if (res.data.success) {
          this.setState({ notifications: res.data.data });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const { notifications } = this.state;
    return (
      <div className="notifications">
        {notifications.map((item, index) => {
          return (
            <div className="notification-item">
              <div className="notification-title">
                <h1>{item.title}</h1>
                <h3>{moment.utc(item.timestamp).local().fromNow()}</h3>
              </div>
              <p>{item.body}</p>
            </div>
          );
        })}
        {notifications.length === 0 && <Empty />}
      </div>
    );
  }
}

const UserHeaderMenu = (props) => (
  <Header className="user-header-menu">
    <h1 className="title">Eduga</h1>
    <Popover
      title="Notifications"
      content={<Notifications />}
      placement="bottomRight"
      trigger={["click"]}
    >
      <Badge dot={true} className="user-badge">
        <Avatar
          icon="bell"
          shape="square"
          style={{ backgroundColor: "#f56a00", cursor: "pointer" }}
        />
      </Badge>
    </Popover>

    <a href="/chat" target="_blank">
      <Badge dot={true} className="user-badge">
        <Avatar
          icon="message"
          shape="square"
          style={{
            backgroundColor: "rgb(8, 107, 179)",
            cursor: "pointer",
            marginLeft: 15,
          }}
        />
      </Badge>
    </a>

    <Dropdown overlay={menu} placement="bottomRight" trigger={["click"]}>
      <Avatar
        icon="user"
        shape="square"
        style={{
          alignSelf: "center",
          backgroundColor: "#87d068",
          marginLeft: 15,
          cursor: "pointer",
        }}
      />
    </Dropdown>
  </Header>
);

const PageFooter = () => (
  <Footer className="site-footer">&copy; Eduga (YuvaTech Labs) 2020-21</Footer>
);

export { GuestHeaderMenu, UserHeaderMenu, PageFooter };
