import React, { Component } from "react";
import { Layout, Row, Col, Input, Button } from "antd";

class TransferCertificate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      student_id: null,
      student: {}
    };
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.setState({ student_id: this.props.match.params.id });
    }
  }

  render() {
    return (
      <Layout.Content>
        <Row>
          <Col sm={8}>
            <h1>Transfer Certificate</h1>
          </Col>
          <Col sm={4} offset={10}>
            <Input type="number" placeholder="Admission Number" />
          </Col>
          <Col sm={2}>
            <Button style={{ marginLeft: 10 }}>Go</Button>
          </Col>
        </Row>
      </Layout.Content>
    );
  }
}

export default TransferCertificate;
