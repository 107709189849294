import { Button, Card, Col, Layout, message, Row } from "antd";
import React, { Component } from "react";
import { Currency } from "../../../../components";
import { api } from "../../../../config";

class EmployeeSalary extends Component {
  state = {
    id: undefined,
    salary: {},
    payslip_items: [],
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      this.setState({ id: this.props.match.params.id });
      this._get_employee_salary(this.props.match.params.id);
    }
  }

  _get_employee_salary = (id) => {
    api
      .get(`/simple/payroll/employee/${id}/salary/`)
      .then((res) => {
        if (res.data.success) {
          let data = res.data.data;
          let payroll = JSON.parse(data.salary);
          this.setState({ salary: data, payslip_items: payroll });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const { salary, payslip_items } = this.state;
    return (
      <Layout.Content>
        <Row>
          <Col sm={12}>
            <h1>Employee Salary</h1>
          </Col>
          <Col sm={12} className="action-buttons">
            <Button
              type="primary"
              onClick={() => this.props.history.push("/payroll/salary/")}
            >
              Edit Salary
            </Button>
          </Col>
        </Row>
        {payslip_items && (
          <Card title="Payslip" className="payroll-table-parent">
            <div className="payroll-table">
              <table>
                <thead>
                  <tr>
                    <th style={{ maxWidth: "100%", width: 240 }}>Item</th>
                    <th>Debit</th>
                    <th>Credit</th>
                  </tr>
                </thead>
                <tbody>
                  {payslip_items.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item.item_name}</td>
                        <td>
                          {item.type === 0 ? (
                            ""
                          ) : (
                            <Currency value={item.value} />
                          )}
                        </td>
                        <td>
                          {item.type === 1 ? (
                            ""
                          ) : (
                            <Currency value={item.value} />
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
                <tfoot>
                  <tr>
                    <th colSpan={1}>Total</th>
                    <th colSpan={2}>
                      <Currency value={salary.ctc} />
                    </th>
                  </tr>
                </tfoot>
              </table>
            </div>
          </Card>
        )}
      </Layout.Content>
    );
  }
}

export default EmployeeSalary;
