import React, { Component } from "react";
import { Layout, Form, Input, Button, message, Spin } from "antd";
import { ShowBreadcrumbs } from "../../../components";
import { api } from "../../../config";

class TeachersForm extends Component {
  state = {
    update: null,
    spinning: false,
    loading: false
  };

  componentDidMount() {
    this.changePassword();
    if (this.props.match.params.id) {
      this.getTeacher(this.props.match.params.id);
      this.setState({ update: this.props.match.params.id });
    }
  }

  getTeacher = id => {
    this.setState({ spinning: true });
    api
      .get("/teacher/" + id + "/")
      .then(res => {
        if (res.data && res.data.success) {
          this.props.form.setFieldsValue(res.data.data);
        } else {
          message.error(res.data.error);
        }
        this.setState({ spinning: false });
      })
      .catch(err => {
        console.log(err);
        this.setState({ spinning: false });
      });
  };

  changePassword = () => {
    let password = Math.random()
      .toString(36)
      .slice(-8);
    this.props.form.setFieldsValue({ password });
  };

  updateTeacher = (id, val) => {
    this.setState({ loading: true });
    api
      .post("/teacher/" + id + "/", JSON.stringify(val))
      .then(res => {
        if (res.data && res.data.success) {
          message.success("Teacher Data Updated");
          this.props.history.push("/teachers/");
        } else {
          message.error(res.data.error);
        }
        this.setState({ loading: false });
      })
      .catch(err => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  addNewTeacher = val => {
    this.setState({ loading: true });
    api
      .put("/teachers/", JSON.stringify(val))
      .then(res => {
        if (res.data && res.data.success) {
          message.success("New Teacher Added");
          this.props.history.push("/teachers/");
        } else {
          message.error(res.data.error);
        }
        this.setState({ loading: false });
      })
      .catch(err => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  handleClick = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        if (this.state.update) {
          this.updateTeacher(this.state.update, val);
        } else {
          this.addNewTeacher(val);
        }
      }
    });
  };

  render() {
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      }
    };
    const { getFieldDecorator } = this.props.form;
    const FormItem = Form.Item;
    return (
      <Layout.Content>
        <ShowBreadcrumbs
          items={[
            { name: "Home", link: "/" },
            { name: "Teachers", link: "/teachers/" },
            { name: "Add", link: "/teachers/new" }
          ]}
        />
        <h1 style={{ textAlign: "center" }}>
          {this.state.update ? "Update " : "Add "}Teacher
        </h1>
        <Spin spinning={this.state.spinning}>
          <Form {...formItemLayout}>
            <FormItem label="Email">
              {getFieldDecorator("email", {
                rules: [
                  { required: true, message: "Please enter email" },
                  { type: "email", message: "Invalid email address" }
                ]
              })(
                <Input
                  type="email"
                  disabled={this.state.update ? true : false}
                />
              )}
            </FormItem>
            {!this.state.update && (
              <span>
                <FormItem label="Password">
                  {getFieldDecorator("password")(
                    <Input style={{ width: "88%", marginRight: 10 }} />
                  )}
                  <Button icon="reload" onClick={this.changePassword} />
                </FormItem>
              </span>
            )}
            <FormItem label="Name">
              {getFieldDecorator("fullname", {
                rules: [{ required: true, message: "Please enter fullname" }]
              })(<Input />)}
            </FormItem>
            <FormItem label="Mobile">
              {getFieldDecorator("mobile", {
                rules: [
                  {
                    required: true,
                    message: "Please enter valid mobile number!"
                  }
                ]
              })(<Input type="number" pattern={"[0-9]{10}"} len={10} />)}
            </FormItem>
            <FormItem label="Address">
              {getFieldDecorator("extras.address")(<Input.TextArea rows={4} />)}
            </FormItem>
            <FormItem label="Qualification">
              {getFieldDecorator("extras.qualification")(<Input />)}
            </FormItem>
            <FormItem wrapperCol={{ offset: 8 }}>
              <Button
                type="primary"
                onClick={this.handleClick}
                loading={this.state.loading}
              >
                {this.state.update ? "Update " : "Add "} Teacher
              </Button>
            </FormItem>
          </Form>
        </Spin>
      </Layout.Content>
    );
  }
}

export default Form.create()(TeachersForm);
