import { Form, Input, Checkbox, Button } from "antd";
import React, { Component } from "react";

class ParentDetails extends Component {
  state = {};

  handleSubmit = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        val.student_relation = this.props.relation;
        console.log(val);
      }
    });
  };

  _previous_screen = () => {
    let { current } = this.props;
    this.props.set_page(current - 1);
  };

  _next_screen = () => {
    let { current } = this.props;
    this.props.set_page(current + 1);
  };

  render() {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    const { relation } = this.props;
    return (
      <div>
        <Form labelCol={{ sm: 8 }} wrapperCol={{ sm: 8 }}>
          <FormItem label="Full Name">
            {getFieldDecorator("contact_name", {
              rules: [{ required: true, message: "Contact name is required!" }],
            })(<Input placeholder="Contact Name" />)}
          </FormItem>
          <FormItem label="Mobile">
            {getFieldDecorator("mobile", {
              rules: [{ required: true, message: "Mobile is requried!" }],
            })(<Input placeholder="Mobile" />)}
          </FormItem>
          <FormItem label="Email">
            {getFieldDecorator("email")(<Input placeholder="Email" />)}
          </FormItem>
          <FormItem label="Address">
            {getFieldDecorator("address")(
              <Input.TextArea placeholder="Address" />
            )}
          </FormItem>
          <FormItem wrapperCol={{ offset: 8 }}>
            {getFieldDecorator("enable_sms", { valuePropName: "checked" })(
              <Checkbox>
                Enable SMS <small>(You can enable SMS in 2 contacts max)</small>
              </Checkbox>
            )}
          </FormItem>
          <FormItem label="Aadhar No.">
            {getFieldDecorator("extras.aadhar_no")(<Input />)}
          </FormItem>
          <FormItem label="PAN Card No.">
            {getFieldDecorator("extras.pan_no")(<Input />)}
          </FormItem>
          <FormItem wrapperCol={{ offset: 8, sm: 8 }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button type="primary" ghost onClick={this._previous_screen}>
                Prev
              </Button>
              <div>
                {relation && (
                  <Button
                    style={{ marginRight: 8 }}
                    onClick={this._next_screen}
                  >
                    Skip
                  </Button>
                )}
                <Button type="primary" onClick={this.handleSubmit}>
                  Next
                </Button>
              </div>
            </div>
          </FormItem>
        </Form>
      </div>
    );
  }
}

export default Form.create()(ParentDetails);
