import React, { Component } from "react";
import { Layout, Form, Input, Button, message } from "antd";
import { api } from "../../../../config";
import { ShowBreadcrumbs } from "../../../../components";

class BranchesForm extends Component {
  state = {
    id: null,
    branch: {}
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      this.get_branch(this.props.match.params.id);
      this.setState({ id: this.props.match.params.id });
    }
  }

  get_branch = id => {
    api
      .get("/branch/" + id + "/")
      .then(res => {
        if (res.data.success) {
          this.props.form.setFieldsValue(res.data.data);
          this.setState({ branch: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  add_new_branch = data => {
    api
      .put("/branches/", JSON.stringify(data))
      .then(res => {
        if (res.data.success) {
          message.success("New Branch Added");
          this.props.history.push("/academics/branches/");
        } else {
          message.error(res.data.error);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  update_branch = (id, data) => {
    api
      .post("/branch/" + id + "/", JSON.stringify(data))
      .then(res => {
        if (res.data.success) {
          message.success("Branch Updated");
        } else {
          message.error(res.data.error);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  handleSubmit = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        if (this.state.id) {
          this.update_branch(this.state.id, val);
        } else {
          this.add_new_branch(val);
        }
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const FormItem = Form.Item;
    const { id } = this.state;
    return (
      <Layout.Content>
        <ShowBreadcrumbs
          items={[
            { name: "Home", link: "/" },
            { name: "Academics", link: "/academics/" },
            { name: "Branches", link: "/academics/branches/" }
          ]}
        />
        <h1 className="align-center">{id ? "Update " : "Add "} Branch</h1>
        <Form labelCol={{ sm: 8 }} wrapperCol={{ sm: 8 }}>
          <FormItem label="Branch Name">
            {getFieldDecorator("name", {
              rules: [{ required: true, message: "Branch name is required!" }]
            })(<Input />)}
          </FormItem>
          <FormItem label="Branch Code">
            {getFieldDecorator("code", {
              rules: [{ required: true, message: "Branch code is required!" }]
            })(<Input />)}
          </FormItem>
          <FormItem wrapperCol={{ offset: 8 }}>
            <Button type="primary" onClick={this.handleSubmit}>
              {id ? "Update " : "Add "} Branch
            </Button>
          </FormItem>
        </Form>
      </Layout.Content>
    );
  }
}

export default Form.create()(BranchesForm);
