import React, { useState, useEffect } from "react";
import { Layout, Table, message, Row, Col, Button, Icon } from "antd";
import { api } from "../../../../config";
import NewHoliday from "./NewHoliday";
import moment from "moment";

export default function HolidaysList(props) {
  const [holidays, setHolidays] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    get_holidays_list();
  }, []);

  const get_holidays_list = () => {
    api
      .get("/hr/holidays/")
      .then((res) => {
        if (res.data.success) {
          setHolidays(res.data.data);
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => console.log(err));
  };

  const closeModal = () => {
    setShowModal(false);
    get_holidays_list();
  };

  const columns = [
    { title: "ID", dataIndex: "id" },
    {
      title: "Date",
      dataIndex: "date",
      render: (v) => <span>{moment(v).format("DD MMM, YYYY")}</span>,
    },
    { title: "Reason", dataIndex: "reason" },
    {
      title: "Actions",
      render: (r) => (
        <span>
          <Icon type="delete" className="action-icon absent" />
        </span>
      ),
    },
  ];
  return (
    <Layout.Content>
      <Row>
        <Col sm={12}>
          <h1>Holidays List</h1>
        </Col>
        <Col sm={12} className="action-buttons">
          <Button type="primary" icon="plus" onClick={() => setShowModal(true)}>
            New Holiday
          </Button>
        </Col>
      </Row>
      <Table columns={columns} dataSource={holidays} rowKey="id" />
      {showModal && <NewHoliday closeModal={closeModal} />}
    </Layout.Content>
  );
}
