import React, { Component } from "react";
import { Layout } from "antd";
import { Link } from "react-router-dom";

class TableOfContent extends Component {
  state = {};
  render() {
    return (
      <Layout.Content>
        <h1>Usage Guide</h1>
        <h3>Getting Started</h3>
        <ul>
          <li>
            <Link to="/help/usage/getting-started/">Adding School Details</Link>
          </li>
          <li>
            <Link to="/help/usage/">Create Academic Year</Link>
          </li>
          <li>
            <Link to="/help/usage/">Add Teachers</Link>
          </li>
          <li>
            <Link to="/help/usage/">Create Classes</Link>
          </li>
          <li>
            <Link to="/help/usage/">Add Subjects</Link>
          </li>
          <li>
            <Link to="/help/usage/">Create Fee Item</Link>
          </li>
          <li>
            <Link to="/help/usage/">Add Students</Link>
          </li>
          <li>
            <Link to="/help/usage/">Pay Fees</Link>
          </li>
          <li>
            <Link to="/help/usage/">Add Students</Link>
          </li>
        </ul>
        <h3>Communication</h3>
        <ul>
          <li>
            <Link to="/help/usage/">Send Messages</Link>
          </li>
          <li>
            <Link to="/help/usage/">Make announcements</Link>
          </li>
          <li>
            <Link to="/help/usage/">Add Homeworks</Link>
          </li>
          <li>
            <Link to="/help/usage/">Add Notes</Link>
          </li>
        </ul>
        <h3>Leads and Admissions Management</h3>
        <ul>
          <li>
            <Link to="/help/usage/">Lead Management</Link>
          </li>
          <li>
            <Link to="/help/usage/">Admission Management</Link>
          </li>
        </ul>
        <h3>Marks and Attendance</h3>
        <ul>
          <li>
            <Link to="/help/usage/">Adding Student Marks</Link>
          </li>
          <li>
            <Link to="/help/usage/">Updating Student Attendance</Link>
          </li>
        </ul>
      </Layout.Content>
    );
  }
}

export default TableOfContent;
