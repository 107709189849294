import React, { Component } from "react";
import { Layout, Table, message, Icon, Tag, Popconfirm } from "antd";
import { api } from "../../../../config";
import moment from "moment";

class ApplicationsList extends Component {
  state = {
    applications: [],
    status_list: [],
  };

  componentDidMount() {
    let status_list = [
      { text: "Withdrawn (by candidate)", color: "red", value: -2 },
      { text: "Rejected (by institute)", color: "red", value: -1 },
      { text: "Unknown", color: "", value: 0 },
      { text: "Waitlist (by candidate)", color: "blue", value: 1 },
      { text: "Waitlist (by institute)", color: "blue", value: 2 },
      { text: "Selected", color: "green", value: 3 },
    ];
    this.setState({ status_list });
    this.get_applications_list();
  }

  get_applications_list = () => {
    api
      .get("/admissions/applications/")
      .then((res) => {
        if (res.data.success) {
          this.setState({ applications: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  delete_application = (id) => {
    api
      .delete("/admissions/application/" + id + "/")
      .then((res) => {
        if (res.data.success) {
          message.success("Application Deleted");
          this.get_applications_list();
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const columns = [
      { title: "ID", dataIndex: "id" },
      { title: "Student Name", dataIndex: "student_name" },
      { title: "Application Class", dataIndex: "application_class" },
      { title: "Parent Name", dataIndex: "parent_name" },
      { title: "Mobile", dataIndex: "mobile" },
      {
        title: "Timestamp",
        dataIndex: "timestamp",
        sorter: (a, b) =>
          moment(a.timestamp).format("X") - moment(b.timestamp).format("X"),
        render: (v) => (
          <span>{moment.utc(v).local().format("YYYY-MM-DD hh:mm A")}</span>
        ),
      },
      {
        title: "Status",
        dataIndex: "status",
        sorter: (a, b) => a.status - b.status,
        filters: this.state.status_list,
        onFilter: (value, record) => value === record.status,
        render: (v, r) => {
          switch (v) {
            case -2:
              return <Tag color="red">Withdrawn (by candidate)</Tag>;
            case -1:
              return <Tag color="red">Rejected (by institute)</Tag>;
            case 0:
              return <Tag>Unknown</Tag>;
            case 1:
              return <Tag color="blue">Waitlisted (by candidate)</Tag>;
            case 2:
              return <Tag color="blue">Waitlisted (by institute)</Tag>;
            case 3:
              return <Tag color="green">Selected</Tag>;
            default:
              return <Tag>Unknown</Tag>;
          }
        },
      },
      {
        title: "Actions",
        render: (r) => (
          <span>
            <Icon
              type="profile"
              className="action-icon"
              onClick={() => {
                this.props.history.push({
                  pathname: "/admissions/application/" + r.id,
                  state: r,
                });
              }}
            />
            <Popconfirm
              title="Are you sure?"
              onConfirm={this.delete_application.bind(this, r.id)}
            >
              <Icon
                type="delete"
                className="action-icon absent"
                title="Delete"
              />
            </Popconfirm>
          </span>
        ),
      },
    ];
    return (
      <Layout.Content>
        <h1>Applications List</h1>
        <Table
          columns={columns}
          dataSource={this.state.applications}
          rowKey="id"
        />
      </Layout.Content>
    );
  }
}

export default ApplicationsList;
