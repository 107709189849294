import React, { Component } from "react";
import {
  Layout,
  Row,
  Col,
  Button,
  Table,
  message,
  Icon,
  Popconfirm,
} from "antd";
import { Link } from "react-router-dom";
import { api } from "../../../../config";
import { Currency, ShowBreadcrumbs } from "../../../../components";

class AccountsList extends Component {
  state = {
    spinning: false,
    accounts: [],
  };

  componentDidMount() {
    this.get_accounts_list();
  }

  get_accounts_list = () => {
    this.setState({ spinning: true });
    api
      .get("/finance/accounts/")
      .then((res) => {
        if (res.data && res.data.success) {
          this.setState({ accounts: res.data.data });
        } else {
          message.error(res.data.error);
        }
        this.setState({ spinning: false });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ spinning: false });
      });
  };

  handleDelete = (id) => {
    api
      .delete("/finance/account/" + id + "/")
      .then((res) => {
        if (res.data && res.data.success) {
          message.success("Account deleted");
          this.get_accounts_list();
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const columns = [
      { title: "ID", dataIndex: "id" },
      {
        title: "Name",
        dataIndex: "name",
        render: (v, r) => <Link to={`/finance/account/${r.id}/`}>{v}</Link>,
      },
      { title: "Type", dataIndex: "type" },
      { title: "Bank", dataIndex: "extras.bank_name" },
      {
        title: "Credit",
        dataIndex: "credit",
        align: "right",
        render: (v) => <Currency value={v} />,
      },
      {
        title: "Debit",
        dataIndex: "debit",
        align: "right",
        render: (v) => <Currency value={v} />,
      },
      {
        title: "Balance",
        align: "right",
        render: (r) => <Currency value={r.credit - r.debit} />,
      },
      {
        title: "Actions",
        align: "right",
        render: (record) => (
          <span>
            <Icon
              type="edit"
              className="action-icon"
              onClick={() => {
                this.props.history.push("/finance/accounts/" + record.id + "/");
              }}
            />
            <Popconfirm
              title="Are you sure?"
              onConfirm={() => {
                this.handleDelete(record.id);
              }}
            >
              <Icon type="delete" className="action-icon absent" />
            </Popconfirm>
          </span>
        ),
      },
    ];
    return (
      <Layout.Content>
        <ShowBreadcrumbs
          items={[
            { name: "Home", link: "/" },
            { name: "Finance", link: "/finance/" },
            { name: "Accounts", link: "/finance/accounts/" },
          ]}
        />
        <Row>
          <Col sm={6}>
            <h1>Accounts List</h1>
          </Col>
          <Col sm={18} style={{ textAlign: "right" }}>
            <Button
              type="primary"
              ghost
              icon="swap"
              onClick={() => this.props.history.push("/finance/transfer/")}
              style={{ marginRight: 12 }}
            >
              Account Transfers
            </Button>
            <Link to="/finance/accounts/new">
              <Button icon="plus" type="primary">
                Add new account
              </Button>
            </Link>
          </Col>
        </Row>
        <Table
          loading={this.state.spinning}
          columns={columns}
          dataSource={this.state.accounts}
          rowKey="id"
        />
      </Layout.Content>
    );
  }
}

export default AccountsList;
