import React, { Component } from "react";
import { Layout, message, Row, Col } from "antd";
import { api } from "../../../config";
import StudentAttendanceEditor from "./StudentAttendanceEditor";

class StudentDetailsEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      student: {},
      student_id: null
    };
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.get_student(this.props.match.params.id);
      this.setState({ student_id: this.props.match.params.id });
    }
  }

  get_student = id => {
    api
      .get("/student/" + id + "/")
      .then(res => {
        if (res.data && res.data.success) {
          this.setState({ student: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  render() {
    return (
      <Layout.Content>
        <h1>Student Details Editor</h1>
        <Row>
          <Col sm={8}>
            <StudentAttendanceEditor student={this.state.student} />
          </Col>
        </Row>
      </Layout.Content>
    );
  }
}

export default StudentDetailsEditor;
