import React from "react";
import { message } from "antd";
import { connect } from "react-redux";
import { api } from "../../../config";

class JitsiMeeting extends React.Component {
  state = {
    loading: true,
    id: undefined,
    meeting: {},
    extras: {},
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      this.get_meeting(this.props.match.params.id);
      this.setState({ id: this.props.match.params.id });
    }
  }

  get_meeting = (id) => {
    api
      .get("/meeting/" + id + "/")
      .then((res) => {
        if (res.data.success) {
          let data = res.data.data;
          let extras = JSON.parse(data.extras);
          if (extras.jitsi_room_name) {
            this.start_meeting(extras.jitsi_room_name);
          }
          this.setState({ meeting: data, extras });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  start_meeting = (room_name) => {
    if (window.JitsiMeetExternalAPI) this.startConference(room_name);
    else {
      message.error("Failed to load Meeting Scripts");
    }
  };

  startConference = (room_name) => {
    const domain = "meet.jit.si";
    const options = {
      roomName: room_name,
      height: "100%",
      parentNode: document.getElementById("jitsi-container"),
      interfaceConfigOverwrite: {
        filmStripOnly: false,
        SHOW_JITSI_WATERMARK: false,
      },
      configOverwrite: {
        disableSimulcast: false,
      },
    };

    try {
      const api = new window.JitsiMeetExternalAPI(domain, options);
      api.addEventListener("videoConferenceJoined", () => {
        console.log("Local User Joined");
        this.setState({ loading: false });
        api.executeCommand("displayName", this.props.user.name);
      });
      api.addEventListener("videoConferenceLeft", () => {
        console.clear();
        this.props.history.replace("/meeting/");
      });
    } catch (error) {
      console.log("Failed to load Jitsi", error);
    }
  };

  render() {
    // const { loading } = this.state;
    return (
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 5,
          background: "#f8f8f8",
        }}
      >
        <div
          style={{ width: "100%", height: "100vh" }}
          id="jitsi-container"
        ></div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps, null)(JitsiMeeting);
