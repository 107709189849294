import React from "react";
import { Switch, Route } from "react-router-dom";
import MeetingsList from "../../teacher/meetings/MeetingsList";
import MeetingForm from "./MeetingForm";
import Participants from "./Participants";
// import MeetingRoom from "../../teacher/meetings/MeetingRoom";
import JitsiMeeting from "./JitsiMeeting";

const AdminMeetingRouter = (props) => (
  <Switch>
    <Route exact path="/meeting/" component={MeetingsList} />
    <Route exact path="/meeting/new" component={MeetingForm} />
    <Route exact path="/meeting/:id/participants/" component={Participants} />
    <Route exact path="/meeting/room/:id" component={JitsiMeeting} />
  </Switch>
);

export { AdminMeetingRouter };
