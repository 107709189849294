import React, { Component } from "react";
import {
  Layout,
  Row,
  Col,
  message,
  Card,
  Calendar,
  Badge,
  Empty,
  Button,
} from "antd";
import { AcademicYearDropdown } from "../../../components";
import { api } from "../../../config";
import { Bar } from "react-chartjs-2";
import StudentProfileCard from "./student_details/StudentProfileCard";
import StudentContactCard from "./student_details/StudentContactCard";
import { Link } from "react-router-dom";

class StudentDetails extends Component {
  state = {
    id: null,
    ac_year_id: null,
    marks: [],
    attendance: [],
    monthly_attendance: [],
    spinning: false,
    marks_data: {},
    tests: [],
    subjects: [],
    graph_data: [],
    graph_options: {},
  };

  componentDidMount() {
    // get student marks, attendance data and month wise attendance count data
    // Display marks in table (same as dispayed in parent login), display attendance in calendar view
    // and attendance count in graphical view
    // update calendar to dateFullCellRender
    this.setState({ id: this.props.match.params.id });
  }

  handleACYearChange = (ac_year_id) => {
    if (ac_year_id && this.state.id) {
      this.setState({ ac_year_id }, () => {
        this.get_student_details();
      });
    }
  };

  get_student_details = () => {
    let { id, ac_year_id } = this.state;
    this.setState({ spinning: true });
    api
      .get("/student/details/" + id + "/" + ac_year_id + "/")
      .then((res) => {
        if (res.data.success) {
          let data = res.data.data;
          this.rearrangeMarksData(data.marks);
          this.generateGraphData(data.monthly_attendance);
          this.setState({
            marks: data.marks,
            attendance: data.attendance,
            monthly_attendance: data.monthly_attendance,
          });
        } else {
          message.error(res.data.error);
        }
        this.setState({ spinning: false });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ spinning: false });
      });
  };

  dateCellRender = (date) => {
    const PRESENT = <Badge status="success" />;
    const ABSENT = <Badge status="error" />;
    let attendance = this.state.attendance;
    let res = attendance.filter((item) => {
      if (item.date === date.format("YYYY-MM-DD")) {
        return item;
      }
      return null;
    });
    if (res.length === 1) {
      return res[0].present === 1 ? PRESENT : ABSENT;
    }
  };

  DateCell = (props) => (
    <div className="calendar-date">
      <div
        className={
          props.present === 1
            ? "calendar-present"
            : props.present === 0
            ? "calendar-absent"
            : ""
        }
      >
        {props.date.format("DD")}
      </div>
    </div>
  );

  dateFullCellRender = (date) => {
    let attendance = this.state.attendance;
    let res = attendance.filter((item) => {
      if (item.data === date.format("YYYY-MM-DD")) {
        return item;
      }
      return null;
    });
    if (res.length === 1) {
      return date.format("DD");
    }
    return <this.DateCell date={date} />;
  };

  rearrangeMarksData = (data) => {
    let res = {},
      tests = [],
      subjects = [];
    data.forEach((item) => {
      if (Object.keys(res).indexOf(item.test_name) === -1) {
        res[item.test_name] = {};
        tests.push(item.test_name);
      }
      if (subjects.indexOf(item.subject_name) === -1) {
        subjects.push(item.subject_name);
      }
      res[item.test_name][item.subject_name] = {};
      res[item.test_name][item.subject_name]["marks"] = item.marks_obtained;
      res[item.test_name][item.subject_name]["grade"] = item.grade;
    });
    this.setState({ marks_data: res, tests, subjects });
  };

  generateGraphData = (data) => {
    let present = [],
      total = [],
      xAxis = [],
      absent = [];
    data.forEach((item) => {
      xAxis.push(item.month);
      present.push(item.present);
      total.push(item.total);
      absent.push(item.total - item.present);
    });
    let graph_data = {
      labels: xAxis,
      datasets: [
        {
          data: present,
          backgroundColor: "rgba(68, 136, 68, 0.8)",
          label: "Present",
        },
        {
          data: absent,
          backgroundColor: "rgba(136, 68, 68, 0.8)",
          label: "Absent",
        },
      ],
    };
    this.setState({ graph_data });
  };

  render() {
    const { subjects, tests, marks_data, id } = this.state;
    return (
      <Layout.Content>
        <Row>
          <Col sm={8}>
            <h1>Student Details</h1>
          </Col>
          <Col sm={8}>
            <span>Academic Year : </span>
            <AcademicYearDropdown
              value={this.state.ac_year_id}
              onChange={this.handleACYearChange}
            />
          </Col>
          <Col sm={8} style={{ textAlign: "right" }}>
            <Link to={"/fees/pay/" + id}>
              <Button type="primary" icon="dollar">
                Pay Fees
              </Button>
            </Link>
          </Col>
        </Row>
        <Row gutter={32}>
          <Col sm={24}>
            <StudentProfileCard {...this.props} student_id={this.state.id} />
          </Col>
          <Col sm={24}>
            <StudentContactCard student_id={this.state.id} />
          </Col>
          <Col sm={12}>
            <Card title="Monthly Attendance">
              {this.state.attendance.length > 0 ? (
                <Calendar
                  fullscreen={false}
                  dateCellRender={this.dateCellRender}
                  // dateFullCellRender={this.dateFullCellRender}
                />
              ) : (
                <Empty />
              )}
            </Card>
          </Col>
          <Col sm={12}>
            <Card title="Marks View">
              {this.state.marks.length > 0 ? (
                <div className="marks-table">
                  <table>
                    <thead>
                      <tr>
                        <th>Test/Subject</th>
                        {subjects.map((item, index) => {
                          return <th key={index}>{item}</th>;
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {tests.map((test, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <b>{test}</b>
                            </td>
                            {subjects.map((item, ind) => {
                              return (
                                <td key={ind}>
                                  {(marks_data[test][item] &&
                                    marks_data[test][item]["marks"]) ||
                                    "NA"}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              ) : (
                <Empty />
              )}
            </Card>
          </Col>
        </Row>
        <Row gutter={32} style={{ marginTop: 24 }}>
          <Col sm={12}>
            <Card title="Attendance Graph">
              {this.state.monthly_attendance.length > 0 ? (
                <Bar
                  data={this.state.graph_data}
                  options={{
                    scales: {
                      xAxes: [{ stacked: true }],
                      yAxes: [{ stacked: true }],
                    },
                  }}
                />
              ) : (
                <Empty />
              )}
            </Card>
          </Col>
        </Row>
      </Layout.Content>
    );
  }
}

export default StudentDetails;
