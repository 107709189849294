import React, { Component } from "react";
import { Layout, Row, Col, Card, message, List } from "antd";
import { Pie } from "react-chartjs-2";
import { api } from "../../../../config";
import moment from "moment";
import { ShowBreadcrumbs } from "../../../../components";

const colors = [
  "#7cb5ec",
  "#434348",
  "#90ed7d",
  "#f7a35c",
  "#8085e9",
  "#f15c80",
  "#e4d354",
  "#2b908f",
  "#f45b5b",
  "#91e8e1",
];

class FinanceDashboard extends Component {
  state = {
    expenses: {},
    fee_collection: {},
    last_5_expense: [],
    expense_pie_chart_data: {},
    loading: false,
    accounts: [],
  };

  componentDidMount() {
    this.get_expense_data();
  }

  get_expense_data = () => {
    this.setState({ loading: true });
    api
      .get("/finance/reports/finance-dashboard/")
      .then((res) => {
        if (res.data.success) {
          let data = res.data.data;
          this.expense_pie_chart_data(data.expenses);
          this.setState({
            expenses: data.expenses,
            fee_collection: data.fee_collection,
            last_5_expense: data.last_5_expense,
            accounts: data.accounts,
          });
          this.setState({ loading: false });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  expense_pie_chart_data = (data) => {
    let exp_data = data.expense_by_category;
    let labels = [],
      dataset = [],
      len = Object.keys(exp_data).length;
    Object.keys(exp_data).forEach((item) => {
      labels.push(item);
      dataset.push(exp_data[item]);
    });
    let pie_chart_data = {
      labels: labels,
      datasets: [
        {
          data: dataset,
          backgroundColor: colors.slice(0, len),
          hoverBackgroundColor: colors.slice(0, len),
        },
      ],
    };
    this.setState({ expense_pie_chart_data: pie_chart_data });
  };

  render() {
    const {
      expenses,
      fee_collection,
      last_5_expense,
      loading,
      accounts,
    } = this.state;
    return (
      <Layout.Content>
        <ShowBreadcrumbs
          items={[
            { name: "Home", link: "/" },
            { name: "Finance", link: "/finance/" },
          ]}
        />
        <h1>Finance Dashboard</h1>
        <Row gutter={32}>
          <Col sm={8}>
            <Card title="Expenses" loading={loading}>
              <table className="card-table">
                <tbody>
                  <tr>
                    <td className="card-table-name">Expenses this month</td>
                    <td className="card-table-amount">
                      ₹{expenses.expense_month || 0}
                    </td>
                  </tr>
                  <tr>
                    <td className="card-table-name">Expenses this year</td>
                    <td className="card-table-amount">
                      ₹{expenses.expense_year || 0}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Card>
          </Col>
          <Col sm={8}>
            <Card title="Fee collection this year" loading={loading}>
              {fee_collection.fees_this_year && (
                <table className="card-table">
                  <tbody>
                    <tr>
                      <td className="card-table-name">Total Amount</td>
                      <td className="card-table-amount">
                        ₹{fee_collection.fees_this_year.total_amount || 0}
                      </td>
                    </tr>
                    <tr>
                      <td className="card-table-name">Due Amount</td>
                      <td className="card-table-amount">
                        ₹
                        {(fee_collection.fees_this_year.total_amount || 0) -
                          (fee_collection.fees_this_year.paid_amount || 0)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}
            </Card>
          </Col>
          <Col sm={8}>
            <Card title="Fee collection next year" loading={loading}>
              {fee_collection.fees_next_year && (
                <table className="card-table">
                  <tbody>
                    <tr>
                      <td className="card-table-name">Total Amount</td>
                      <td className="card-table-amount">
                        ₹{fee_collection.fees_next_year.total_amount || 0}
                      </td>
                    </tr>
                    <tr>
                      <td className="card-table-name">Due Amount</td>
                      <td className="card-table-amount">
                        ₹
                        {(fee_collection.fees_next_year.total_amount || 0) -
                          (fee_collection.fees_next_year.paid_amount || 0)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}
            </Card>
          </Col>
          {/* <Col sm={6}>
            <Card title="Account Spends" loading={loading}>
              <p>Lists accounts with money spent</p>
            </Card>
          </Col> */}
        </Row>
        <Row gutter={32} style={{ marginTop: 40 }}>
          <Col sm={8}>
            <Card title="Expenses by Category" loading={loading}>
              <Pie
                data={this.state.expense_pie_chart_data}
                options={{
                  legend: { position: "right" },
                }}
              />
            </Card>
          </Col>
          <Col sm={8}>
            <Card title="Last 5 Expenses" loading={loading}>
              <List>
                {last_5_expense.map((item, index) => {
                  return (
                    <List.Item key={index}>
                      {"Spent ₹" +
                        item.total_amount +
                        " for " +
                        item.name +
                        " on " +
                        moment(item.date).format("DD MMM, YYYY")}
                    </List.Item>
                  );
                })}
              </List>
            </Card>
          </Col>
          <Col sm={8}>
            <Card title="Account Spends" loading={loading}>
              <table className="card-table">
                <tbody>
                  {accounts.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td className="card-table-name">
                          {item.name || "Unknown/Others"}
                        </td>
                        <td className="card-table-amount">
                          ₹{item.total_amount}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </Card>
          </Col>
        </Row>
      </Layout.Content>
    );
  }
}

export default FinanceDashboard;
