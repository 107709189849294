import React, { Component } from "react";
import { Layout, Row, Col, message } from "antd";
import StudentSelector from "../../../components/StudentSelector";
import { api } from "../../../config";

class StudentMarks extends Component {
  state = {
    student_id: null,
    marks_data: [],
    tests: [],
    subjects: [],
    data: [],
  };

  handleStudentChanges = (student_id) => {
    this.setState({ student_id });
    this.get_student_marks(student_id);
  };

  get_student_marks = (student_id) => {
    api
      .get("/marks/" + student_id + "/")
      .then((res) => {
        if (res.data && res.data.success) {
          this.rearrangeData(res.data.data);
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  rearrangeData = (data) => {
    let res = {},
      tests = [],
      subjects = [];
    data.forEach((item) => {
      if (Object.keys(res).indexOf(item.test_name) === -1) {
        res[item.test_name] = {};
        tests.push(item.test_name);
      }
      if (subjects.indexOf(item.subject_name) === -1) {
        subjects.push(item.subject_name);
      }
      res[item.test_name][item.subject_name] = {};
      res[item.test_name][item.subject_name]["marks"] = item.marks_obtained;
      res[item.test_name][item.subject_name]["grade"] = item.grade;
      res[item.test_name][item.subject_name]["min_marks"] = item.min_marks;
      res[item.test_name][item.subject_name]["max_marks"] = item.max_marks;
      res[item.test_name][item.subject_name]["marks_type"] = item.marks_type;
    });
    this.setState({ data: res, tests, subjects });
  };

  render() {
    const { subjects, tests, data } = this.state;
    return (
      <Layout.Content>
        <h1>Student Marks</h1>
        <Row>
          <Col>
            <StudentSelector
              value={this.state.student_id}
              onChange={this.handleStudentChanges}
              style={{ width: 160 }}
            />
          </Col>
        </Row>
        {this.state.student_id && (
          <div className="marks-table">
            <table>
              <thead>
                <tr>
                  <th>Test/Subject</th>
                  {subjects.map((item, index) => {
                    return <th key={index}>{item}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                {tests.map((test, index) => {
                  return (
                    <tr key={index}>
                      <td>{test}</td>
                      {subjects.map((item, ind) => {
                        let marks = data[test][item];
                        if (marks) {
                          if (marks.marks_type === 1) {
                            return (
                              <td
                                key={ind}
                                className={
                                  marks.marks < marks.min_marks ? "absent" : ""
                                }
                              >
                                {marks.marks}/{marks.max_marks} (
                                {marks.marks < marks.min_marks
                                  ? "Fail"
                                  : "Pass"}
                                )
                              </td>
                            );
                          } else {
                            return <td key={ind}>{marks.grade}</td>;
                          }
                        } else {
                          return (
                            <td key={ind}>
                              <small>NA</small>
                            </td>
                          );
                        }
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </Layout.Content>
    );
  }
}

export default StudentMarks;
