import React from "react";
import { Route, Switch } from "react-router";
import UsersList from "./UsersList";

const AdminUsersRouter = (props) => (
  <Switch>
    <Route exact path="/users/" component={UsersList} />
  </Switch>
);

export { AdminUsersRouter };
