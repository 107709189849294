import React from "react";
import { Switch, Route } from "react-router-dom";
import StudentAttendance from "./StudentAttendance";

const StudentAttendanceRouter = props => (
  <Switch>
    <Route exact path="/attendance" component={StudentAttendance} />
  </Switch>
);

export { StudentAttendanceRouter };
