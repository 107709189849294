import React, { Component } from "react";
import {
  Layout,
  Row,
  Col,
  Button,
  Table,
  message,
  Icon,
  Popconfirm
} from "antd";
import { Link } from "react-router-dom";
import { api } from "../../../../config";

class VehiclesList extends Component {
  state = {
    vehicles: []
  };

  componentDidMount() {
    this.get_vehicles_list();
  }

  get_vehicles_list = () => {
    api
      .get("/bus/vehicles/")
      .then(res => {
        if (res.data && res.data.success) {
          this.setState({ vehicles: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  render() {
    const columns = [
      { title: "ID", dataIndex: "id" },
      { title: "Name", dataIndex: "name" },
      { title: "Registration No", dataIndex: "rto_reg_no" },
      { title: "Vehicle", dataIndex: "vehicle_name" },
      { title: "Seats", dataIndex: "total_seats" },
      {
        title: "Actions",
        render: record => (
          <span>
            <Icon
              type="edit"
              className="action-icon"
              onClick={() => {
                this.props.history.push(
                  "/transportation/vehicle/" + record.id + "/"
                );
              }}
            />
            <Popconfirm title="Are you sure?">
              <Icon type="delete" className="action-icon absent" />
            </Popconfirm>
          </span>
        )
      }
    ];
    return (
      <Layout.Content>
        <Row>
          <Col sm={6}>
            <h1>Vehicles List</h1>
          </Col>
          <Col sm={4} offset={14}>
            <Link to="/transportation/vehicles/new">
              <Button icon="plus" type="primary">
                Add New Vehicle
              </Button>
            </Link>
          </Col>
        </Row>
        <Table columns={columns} dataSource={this.state.vehicles} rowKey="id" />
      </Layout.Content>
    );
  }
}

export default VehiclesList;
