import React, { Component } from "react";
import { Modal, Input, Form, message, Button } from "antd";
import { api } from "../../../config";

class ChangePasswordModal extends Component {
  state = {
    password: "",
    teacher: {},
    loading: false,
  };

  componentDidMount() {
    this.setState({ teacher: this.props.teacher });
    this.changePassword();
  }

  changePassword = () => {
    let password = Math.random().toString(36).slice(-8);
    this.setState({ password });
  };

  handleUpdatePassword = () => {
    this.setState({ loading: true });
    let { password, teacher } = this.state;
    if (!teacher.user_id) {
      message.error("The teacher has no registered user");
      return;
    }
    let data = {
      password,
      user_id: teacher.user_id,
    };
    api
      .patch("/user/change-password/", JSON.stringify(data))
      .then((res) => {
        if (res.data.success) {
          message.success("Password reset");
          this.props.closeModal();
        } else {
          message.error(res.data.error);
        }
        this.setState({ loading: false });
      })
      .catch((err) => {
        console.log(err);
        message.info("Please check network connection");
        this.setState({ loading: false });
      });
  };

  render() {
    const { password, loading } = this.state;
    return (
      <Modal
        visible={true}
        title="Reset Teacher Password"
        okText="Update Password"
        onOk={this.handleUpdatePassword}
        onCancel={this.props.closeModal}
        confirmLoading={loading}
      >
        <Form.Item label="New Password">
          <Input
            value={password}
            onChange={(e) => {
              this.setState({ password: e.target.value });
            }}
            style={{ width: "90%", marginRight: 5 }}
          />
          <Button icon="reload" onClick={this.changePassword} />
        </Form.Item>
      </Modal>
    );
  }
}

export default ChangePasswordModal;
