import React from "react";
import { Route, Switch } from "react-router";
import PayrollList from "./PayrollList";

const TeacherPayrollRouter = (props) => (
  <Switch>
    <Route exact path="/payroll" component={PayrollList} />
  </Switch>
);

export { TeacherPayrollRouter };
