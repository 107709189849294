import React, { Component } from "react";
import { Layout, Row, Col, Table, Button, message, Icon } from "antd";
import StudentsListForm from "./StudentsListForm";
import { api } from "../../../../config";
import { AcademicYearDropdown, Currency } from "../../../../components";

class StudentsList extends Component {
  state = {
    id: undefined,
    show_modal: false,
    route: {},
    students_list: [],
    ac_year_id: undefined,
    columns: [],
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      let columns = [
        { title: "ID", dataIndex: "id" },
        { title: "Student Name", dataIndex: "fullname" },
        { title: "Stop Name", dataIndex: "bus_stop_name" },
        {
          title: "Fee",
          dataIndex: "fee",
          align: "right",
          render: (v) => <Currency value={v} />,
        },
        {
          title: "Actions",
          render: (r) => (
            <span>
              <Icon
                type="dollar"
                className="action-icon"
                title="Transportation Fees"
                onClick={() =>
                  this.props.history.push(`/fees/records/${r.student_id}/`)
                }
              />
            </span>
          ),
        },
      ];
      this.setState({ id: this.props.match.params.id, columns });
    } else {
      let columns = [
        { title: "ID", dataIndex: "id" },
        { title: "Student Name", dataIndex: "fullname" },
        { title: "Pickup Stop", dataIndex: "pickup" },
        { title: "Drop Stop", dataIndex: "drop" },
        {
          title: "Fee",
          dataIndex: "fee",
          align: "right",
          render: (v) => <Currency value={v} />,
        },
        {
          title: "Actions",
          render: (r) => (
            <span>
              <Icon
                type="dollar"
                className="action-icon"
                title="Transportation Fees"
                onClick={() =>
                  this.props.history.push(`/fees/records/${r.student_id}/`)
                }
              />
            </span>
          ),
        },
      ];
      this.setState({ columns });
    }
  }

  get_route = (id) => {
    api
      .get("/bus/route/" + id + "/")
      .then((res) => {
        if (res.data && res.data.success) {
          this.setState({ route: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  get_bus_students_list = (ac_year_id, route_id) => {
    let url = `/bus/students/${ac_year_id}/`;
    url = route_id ? url + route_id + "/" : url;
    api
      .get(url)
      .then((res) => {
        if (res.data && res.data.success) {
          this.setState({ students_list: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  closeModal = () => {
    this.setState({ show_modal: false });
    this.get_bus_students_list(this.state.ac_year_id, this.state.id);
  };

  __handleACYearChange = (val) => {
    this.setState({ ac_year_id: val });
    this.get_bus_students_list(val, this.state.id);
  };

  render() {
    let { ac_year_id, columns } = this.state;
    return (
      <Layout.Content>
        <Row>
          <Col md={8}>
            <h1>Students List</h1>
          </Col>
          <Col md={8}>
            <span>
              <strong>AC Year : </strong>
              <AcademicYearDropdown
                value={ac_year_id}
                onChange={this.__handleACYearChange}
              />
            </span>
          </Col>
          <Col md={8} style={{ textAlign: "right" }}>
            {!this.state.id && (
              <Button
                icon="plus"
                type="primary"
                onClick={() => {
                  this.setState({ show_modal: true });
                }}
              >
                Add Student
              </Button>
            )}
          </Col>
        </Row>
        <Table
          columns={columns}
          dataSource={this.state.students_list}
          rowKey="id"
          pagination={{ pageSize: 50 }}
        />
        {this.state.show_modal && (
          <StudentsListForm
            closeModal={this.closeModal}
            visible={this.state.show_modal}
            route={this.state.route}
            route_id={this.state.id}
          />
        )}
      </Layout.Content>
    );
  }
}

export default StudentsList;
