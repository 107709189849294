import React, { Component } from "react";
import { Layout, Form, Input, Button, Select, Spin, message } from "antd";
import { api } from "../../../../config";
import { ShowBreadcrumbs } from "../../../../components";

class AccountsForm extends Component {
  state = {
    spinning: false,
    loading: false,
    account: {},
    id: null
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      this.setState({ id: this.props.match.params.id });
      this.get_account(this.props.match.params.id);
    }
  }

  get_account = id => {
    this.setState({ spinning: true });
    api
      .get("/finance/account/" + id + "/")
      .then(res => {
        if (res.data && res.data.success) {
          this.setState({ account: res.data.data });
          this.props.form.setFieldsValue(res.data.data);
        } else {
          message.error(res.data.error);
        }
        this.setState({ spinning: false });
      })
      .catch(err => {
        console.log(err);
        this.setState({ spinning: false });
      });
  };

  handleSave = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        if (this.state.id) {
          this.update_account(this.state.id, val);
        } else {
          this.add_new_account(val);
        }
      }
    });
  };

  add_new_account = data => {
    this.setState({ loading: true });
    api
      .put("/finance/accounts/", JSON.stringify(data))
      .then(res => {
        if (res.data && res.data.success) {
          message.success("New account added");
          this.props.history.push("/finance/accounts/");
        } else {
          message.error(res.data.error);
        }
        this.setState({ loading: false });
      })
      .catch(err => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  update_account = (id, data) => {
    this.setState({ loading: true });
    api
      .post("/finance/account/" + id + "/", JSON.stringify(data))
      .then(res => {
        if (res.data && res.data.success) {
          message.success("Account updated");
          this.props.history.push("/finance/accounts/");
        } else {
          message.error(res.data.error);
        }
        this.setState({ loading: false });
      })
      .catch(err => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const FormItem = Form.Item;
    const Option = Select.Option;
    const { id } = this.state;
    return (
      <Layout.Content>
        <ShowBreadcrumbs
          items={[
            { name: "Home", link: "/" },
            { name: "Finance", link: "/finance/" },
            { name: "Accounts", link: "/finance/accounts/" },
            {
              name: (id ? "Edit " : "New ") + "Account",
              link: "/finance/accounts/" + (id ? id : "new")
            }
          ]}
        />
        <h1 className="align-center">Accounts Form</h1>
        <Spin spinning={this.state.spinning}>
          <Form labelCol={{ sm: 8 }} wrapperCol={{ sm: 8 }}>
            <FormItem label="Name">
              {getFieldDecorator("name", {
                rules: [{ required: true, message: "Name is required" }]
              })(<Input />)}
            </FormItem>
            <FormItem label="Type">
              {getFieldDecorator("type", {
                rules: [{ required: true, message: "Type is required" }]
              })(
                <Select>
                  <Option value="bank">Bank Account</Option>
                  <Option value="book">Book Account</Option>
                </Select>
              )}
            </FormItem>
            <FormItem label="Bank Name">
              {getFieldDecorator("extras.bank_name")(<Input />)}
            </FormItem>
            <FormItem label="Branch">
              {getFieldDecorator("extras.bank_branch")(<Input />)}
            </FormItem>
            <FormItem label="A/C No.">
              {getFieldDecorator("extras.account_number")(<Input />)}
            </FormItem>
            <FormItem label="IFSC Code">
              {getFieldDecorator("extras.ifsc_code")(<Input />)}
            </FormItem>
            <FormItem wrapperCol={{ offset: 8 }}>
              <Button loading={this.state.loading} onClick={this.handleSave}>
                Save
              </Button>
            </FormItem>
          </Form>
        </Spin>
      </Layout.Content>
    );
  }
}

export default Form.create()(AccountsForm);
