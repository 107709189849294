import React, { Component } from "react";
import { Layout, Card, message, List, Button } from "antd";
import { api } from "../../../config";
import { Link } from "react-router-dom";
import { ShowBreadcrumbs } from "../../../components";
import NewVCRoomQuestion from "./NewVCRoomQuestion";

class VCRoomQuestions extends Component {
  state = {
    vc_class_id: null,
    student: {},
    questions: [],
    show_new_question_modal: false,
  };

  componentDidMount() {
    if (
      this.props.match.params.vc_class_id &&
      this.props.location.state.student
    ) {
      this.setState(
        {
          vc_class_id: this.props.match.params.vc_class_id,
          student: this.props.location.state.student,
        },
        this.get_vc_room_questions_list
      );
    }
  }

  get_vc_room_questions_list = () => {
    const url = "/virtual-class/" + this.state.vc_class_id + "/questions/";
    api
      .get(url)
      .then((res) => {
        if (res.data.success) {
          this.setState({ questions: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  closeModal = () => {
    this.setState({ show_new_question_modal: false });
    this.get_vc_room_questions_list();
  };

  render() {
    const {
      questions,
      vc_class_id,
      student,
      show_new_question_modal,
    } = this.state;
    return (
      <Layout.Content>
        <ShowBreadcrumbs
          items={[
            { name: "Home", link: "/" },
            { name: "Virtual Classroom List", link: "/virtual-classroom/" },
            {
              name: "Virtual Classroom",
              link: {
                pathname: "/virtual-classroom/" + vc_class_id,
                state: { student },
              },
            },
            { name: "Questions List" },
          ]}
        />
        <Card
          title="Questions"
          extra={
            student.id && (
              <Button
                type="link"
                onClick={() => {
                  this.setState({ show_new_question_modal: true });
                }}
              >
                Ask new question
              </Button>
            )
          }
        >
          <List>
            {questions.map((item, index) => {
              return (
                <List.Item key={index} style={{ display: "block" }}>
                  <h4>
                    <Link
                      to={{
                        pathname:
                          "/virtual-classroom/" +
                          vc_class_id +
                          "/question/" +
                          item.id,
                        state: { student },
                      }}
                    >
                      {index + 1 + ")  " + item.question}
                    </Link>
                  </h4>
                  <p>{item.description}</p>
                </List.Item>
              );
            })}
          </List>
        </Card>
        {show_new_question_modal && (
          <NewVCRoomQuestion
            student={student}
            vc_class_id={vc_class_id}
            closeModal={this.closeModal}
          />
        )}
      </Layout.Content>
    );
  }
}

export default VCRoomQuestions;
