import { Descriptions, Layout, message, Table } from "antd";
import React, { Component } from "react";
import { Currency } from "../../../../components";
import { api } from "../../../../config";
import moment from "moment";

class FeeItemStudentsReports extends Component {
  state = {
    fee_report: {},
    class_id: undefined,
    ac_year_id: undefined,
    reports: [],
  };

  componentDidMount() {
    let { fee_report, class_id, ac_year_id } = this.props.location.state;
    if (fee_report && class_id && ac_year_id) {
      this.setState(
        { fee_report, class_id, ac_year_id },
        this._get_fee_item_reports
      );
    }
  }

  _get_fee_item_reports = () => {
    let { fee_report, class_id, ac_year_id } = this.state;
    let params = fee_report.id
      ? `item_id=${fee_report.id}`
      : `item_name=${fee_report.fee_item_name}`;
    let url = `/reports/fees/${class_id}/${ac_year_id}/?${params}`;
    api
      .get(url)
      .then((res) => {
        if (res.data.success) {
          this.setState({ reports: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const { reports, fee_report } = this.state;
    const columns = [
      { title: "Fee ID", dataIndex: "id" },
      { title: "Student Name", dataIndex: "fullname" },
      {
        title: "Amount",
        dataIndex: "amount",
        align: "right",
        render: (v) => <Currency value={v} />,
      },
      {
        title: "Fee Amount",
        dataIndex: "total_amount",
        align: "right",
        render: (v) => <Currency value={v} />,
      },
      {
        title: "Paid Amount",
        dataIndex: "paid_amount",
        align: "right",
        render: (v) => <Currency value={v} />,
      },
      {
        title: "Date",
        dataIndex: "timestamp",
        render: (v) => (
          <span>{moment.utc(v).local().format("YYYY-MM-DD")}</span>
        ),
      },
    ];
    const { Item } = Descriptions;
    return (
      <Layout.Content>
        <h1>Fee Item Students Report</h1>
        <Descriptions>
          <Item label="Fee Item Name">{fee_report.fee_item_name}</Item>
          <Item label="Quantity">{fee_report.quantity}</Item>
          <Item label="Count">{fee_report.count}</Item>
          <Item label="Item Amount">
            <Currency value={fee_report.amount} />
          </Item>
          <Item label="Total Collection">
            <Currency value={fee_report.total_amount} />
          </Item>
        </Descriptions>
        <Table columns={columns} dataSource={reports} rowKey="id" />
      </Layout.Content>
    );
  }
}

export default FeeItemStudentsReports;
