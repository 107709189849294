import React, { Component } from "react";
import {
  Layout,
  Row,
  Col,
  Button,
  Table,
  message,
  Icon,
  Popconfirm
} from "antd";
import { Link } from "react-router-dom";
import { api } from "../../../../config";

var moment = require("moment");

class DriversList extends Component {
  state = {
    drivers: []
  };

  componentDidMount() {
    this.get_drivers_list();
  }

  get_drivers_list = () => {
    api
      .get("/bus/drivers/")
      .then(res => {
        if (res.data && res.data.success) {
          this.setState({ drivers: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  render() {
    const columns = [
      { title: "ID", dataIndex: "id" },
      { title: "Name", dataIndex: "name" },
      {
        title: "Age",
        render: record => <span>{moment(record.dob).fromNow(true)}</span>
      },
      { title: "DL No.", dataIndex: "dl_no" },
      { title: "Mobile", dataIndex: "mobile_no" },
      {
        title: "Actions",
        render: record => (
          <span>
            <Icon
              type="edit"
              className="action-icon"
              onClick={() => {
                this.props.history.push(
                  "/transportation/driver/" + record.id + "/"
                );
              }}
            />
            <Popconfirm title="Are you sure?">
              <Icon type="delete" className="action-icon absent" />
            </Popconfirm>
          </span>
        )
      }
    ];
    return (
      <Layout.Content>
        <Row>
          <Col sm={6}>
            <h1>Drivers List</h1>
          </Col>
          <Col sm={4} offset={14} style={{ textAlign: "right" }}>
            <Link to="/transportation/drivers/new">
              <Button icon="plus" type="primary">
                Add new driver
              </Button>
            </Link>
          </Col>
        </Row>
        <Table columns={columns} dataSource={this.state.drivers} rowKey="id" />
      </Layout.Content>
    );
  }
}

export default DriversList;
