import React, { Component } from "react";
import { Layout, Form, Input, DatePicker, Upload, Button, message } from "antd";
import {
  ClassSectionDropdown,
  SubjectSelectionDropdown,
} from "../../../components";
import { baseurl, getToken, api } from "../../../config";

class HomeworkForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      class_id: null,
      loading: false,
    };
  }

  handleSubmit = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        val.files =
          val.files &&
          val.files.map((item) => {
            return {
              name: item.name,
              size: item.size,
              type: item.type,
              status: "done",
              path: item.response.path,
              url: baseurl + "/" + item.response.path,
              uid: new Date().getTime(),
            };
          });
        this.setState({ loading: true });
        api
          .put("/homework/", JSON.stringify(val))
          .then((res) => {
            if (res.data && res.data.success) {
              message.success("Homework Added");
              this.props.history.push("/homework/");
            } else {
              message.error(res.data.error);
            }
            this.setState({ loading: false });
          })
          .catch((err) => {
            console.log(err);
            this.setState({ loading: false });
          });
      }
    });
  };

  normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const FormItem = Form.Item;
    return (
      <Layout.Content>
        <h1 className="align-center">Home Work Form</h1>
        <Form wrapperCol={{ sm: 8 }} labelCol={{ sm: 8 }}>
          <FormItem label="Home work title">
            {getFieldDecorator("title", {
              rules: [{ required: true, message: "Title is required" }],
            })(<Input />)}
          </FormItem>
          <FormItem label="Class">
            {getFieldDecorator("class_id", {
              rules: [{ required: true, message: "Class is required" }],
            })(
              <ClassSectionDropdown
                onChange={(class_id) => {
                  this.setState({ class_id });
                }}
              />
            )}
          </FormItem>
          <FormItem label="Subject">
            {getFieldDecorator("subject_id")(
              <SubjectSelectionDropdown class={this.state.class_id} />
            )}
          </FormItem>
          <FormItem label="Description">
            {getFieldDecorator("description", {
              rules: [{ required: true, message: "Description is required" }],
            })(<Input.TextArea />)}
          </FormItem>
          <FormItem label="Submission Date">
            {getFieldDecorator("submission_date", {
              rules: [
                { required: true, message: "Submission date is required" },
              ],
            })(<DatePicker />)}
          </FormItem>
          <FormItem label="File Upload">
            {getFieldDecorator("files", {
              valuePropName: "fileList",
              getValueFromEvent: this.normFile,
            })(
              <Upload
                name="file"
                action={baseurl + "/upload/"}
                headers={{ Authorization: getToken() }}
                listType="picture"
                accept=".jpg,.jpeg,.png,.pdf,.doc,.docx,.ppt,.pptx,.odt,.odp,.txt,.rtf,.mp4,.webm"
              >
                <Button icon="upload">Upload</Button>
              </Upload>
            )}
          </FormItem>
          <FormItem wrapperCol={{ offset: 8 }}>
            <Button onClick={this.handleSubmit} loading={this.state.loading}>
              Add home work
            </Button>
          </FormItem>
        </Form>
      </Layout.Content>
    );
  }
}

export default Form.create()(HomeworkForm);
