import React, { Component } from "react";
import {
  Layout,
  Row,
  Col,
  Table,
  Input,
  Form,
  Button,
  Icon,
  message,
  Popconfirm,
} from "antd";
import { api } from "../../../../config";

class Departments extends Component {
  state = {
    departments: [],
  };

  componentDidMount() {
    this.get_departments_list();
  }

  get_departments_list = () => {
    api
      .get("/hr/departments/")
      .then((res) => {
        if (res.data.success) {
          this.setState({ departments: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  add_department = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        api
          .put("/hr/departments/", JSON.stringify(val))
          .then((res) => {
            if (res.data.success) {
              message.success("New Department Added");
              this.get_departments_list();
            } else {
              message.error(res.data.error);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  delete_department = (id) => {
    api
      .delete("/hr/department/" + id + "/")
      .then((res) => {
        if (res.data.success) {
          message.success("Department Deleted");
          this.get_departments_list();
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    const columns = [
      { title: "ID", dataIndex: "id" },
      { title: "Name", dataIndex: "name" },
      {
        title: "Actions",
        render: (r) => (
          <span>
            <Popconfirm
              title="Are you sure?"
              onConfirm={this.delete_department.bind(this, r.id)}
            >
              <Icon
                type="delete"
                className="action-icon absent"
                title="Delete"
              />
            </Popconfirm>
          </span>
        ),
      },
    ];
    return (
      <Layout.Content>
        <h1>Departments</h1>
        <Row gutter={24}>
          <Col sm={12}>
            <Table
              columns={columns}
              dataSource={this.state.departments}
              rowKey="id"
            />
          </Col>
          <Col sm={12}>
            <h2>Add Department</h2>
            <Form>
              <FormItem label="Department Name">
                {getFieldDecorator("name", {
                  rules: [
                    { required: true, message: "Department Name is requried!" },
                  ],
                })(<Input />)}
              </FormItem>
              <FormItem>
                <Button onClick={this.add_department}>Add Department</Button>
              </FormItem>
            </Form>
          </Col>
        </Row>
      </Layout.Content>
    );
  }
}

export default Form.create()(Departments);
