import { message, Modal, Select } from "antd";
import React, { useState } from "react";
import { api } from "../../../../config";

const { Option } = Select;

function StudentAdmissionTypeUpdate({ visible, closeModal, aym_id }) {
  const [admission_type, setAdmissionType] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const __update_admission_type = () => {
    let data = {
      admission_type: admission_type || null,
      aym_id,
    };
    api
      .post(`/student/admission-type/`, JSON.stringify(data))
      .then((res) => {
        if (res.data.success) {
          message.success("Admission type updated!");
          closeModal(true);
        } else {
          message.error(res.data.error);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <Modal
      title="Update Admission Type"
      visible={visible}
      onCancel={closeModal}
      onOk={__update_admission_type}
      confirmLoading={loading}
    >
      <Select
        value={admission_type}
        onChange={setAdmissionType}
        placeholder="Admission Type"
        allowClear
        style={{ width: "100%" }}
      >
        <Option value="NEW">New</Option>
        <Option value="RE ADM.">Re Adm.</Option>
      </Select>
    </Modal>
  );
}

export default StudentAdmissionTypeUpdate;
