import React from "react";
import {
  Layout,
  message,
  Upload,
  Icon,
  Input,
  Button,
  Modal,
  Card,
  Result,
} from "antd";
import { api, baseurl, getToken } from "../../../../config";

class SimpleTestPage extends React.Component {
  state = {
    student_id: undefined,
    test: {},
    test_attempt_id: undefined,
    start_time: undefined,
    files: [],
    submit_test: false,
    student_files: [],
    student_remarks: undefined,
    finish_test: false,
  };

  componentDidMount() {
    let s = this.props.location.state;
    if (s.student_id && s.test) {
      this.setState(
        {
          student_id: s.student_id,
          test: s.test,
          files: JSON.parse(s.test.files),
        },
        this.start_test
      );
    } else {
      this.props.history.push("/online-test/");
    }
  }

  start_test = () => {
    let test_status = window.localStorage.getItem("simple-test");
    if (!test_status) {
      this._get_test_questions();
    } else {
      test_status = JSON.parse(test_status);
      if (test_status.test_id === this.state.test.id) {
        this.setState(
          {
            test_attempt_id: test_status.test_attempt_id,
            start_time: test_status.start_time,
          },
          this.start_timer
        );
      } else {
        window.localStorage.removeItem("simple-test");
        this._get_test_questions();
      }
    }
  };

  _get_test_questions = () => {
    let { student_id, test } = this.state;
    let data = { student_id, test_id: test.id };
    api
      .put("/simple/test/attempts/", JSON.stringify(data))
      .then((res) => {
        if (res.data.success) {
          this.setState({ test_attempt_id: res.data.data.st_test_solution_id });
          window.localStorage.setItem(
            "simple-test",
            JSON.stringify({
              test_attempt_id: res.data.data.st_test_solution_id,
              start_time: new Date(),
              test_id: test.id,
            })
          );
          this.start_timer();
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  start_timer = () => {
    let { test, start_time } = this.state;
    let duration = parseInt(test.duration, 10) * 60;
    let timer = document.getElementById("test-timer");
    if (start_time) {
      duration =
        duration -
        parseInt(
          (new Date().getTime() - new Date(start_time).getTime()) / 1000,
          10
        );
      if (duration < 0) {
        this.complete_test();
        return;
      }
    }
    this.interval = setInterval(() => {
      duration--;
      if (duration === 0) {
        this.complete_test();
      }
      let time =
        Math.floor(duration / 60) + "m " + Math.floor(duration % 60) + "s";
      timer.innerText = time;
    }, 1000);
  };

  start_upload_timer = () => {
    this.duration = 10 * 60;
    let timer = document.getElementById("test-timer");
    timer.style.color = "red";
    this.interval = setInterval(() => {
      this.duration--;
      if (this.duration === 0) {
        clearInterval(this.interval);
        this.submit_test_solutions();
      }
      let time =
        Math.floor(this.duration / 60) +
        "m " +
        Math.floor(this.duration % 60) +
        "s";
      timer.innerText = time;
    }, 1000);
  };

  complete_test = () => {
    clearInterval(this.interval);
    this.start_upload_timer();
    this.setState({ submit_test: true });
  };

  submit_test_solutions = () => {
    let { student_files } = this.state;
    if (student_files.length > 0) {
      this.post_solution();
    } else {
      this.duration === 0
        ? this.post_solution()
        : Modal.confirm({
            title: "Are you sure?",
            content: "No files are attached.",
            okType: "danger",
            onOk: () => {
              this.post_solution();
            },
          });
    }
  };

  post_solution = () => {
    const {
      test_attempt_id,
      student_files,
      student_remarks,
      student_id,
    } = this.state;
    let data = { test_attempt_id, student_files, student_remarks, student_id };
    api
      .post("/simple/test/attempts/", JSON.stringify(data))
      .then((res) => {
        if (res.data.success) {
          this.setState({ finish_test: true });
          window.localStorage.removeItem("simple-test");
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const { files, submit_test, finish_test } = this.state;
    const uploadProps = {
      name: "file",
      action: baseurl + "/upload/",
      headers: { Authorization: getToken() },
      accept: ".jpeg,.png,.jpg,.mp4",
      multiple: true,
      onChange: (e) => {
        if (e.file.status === "done") {
          let { student_files } = this.state;
          student_files = e.fileList.map((file) => {
            return {
              url: baseurl + "/" + file.response.path,
              path: file.response.path,
              name: file.name,
              size: file.size,
              type: file.type,
              uid: new Date().getTime(),
            };
          });
          this.setState({ student_files });
        }
      },
    };
    return (
      <Layout.Content>
        {!finish_test ? (
          <div>
            <div style={{ display: "flex" }}>
              <div style={{ flex: 1 }}>
                <h1>Online Test</h1>
              </div>
              <div>
                <h1 id="test-timer"> </h1>
              </div>
            </div>
            {!submit_test ? (
              <div>
                {files.map((item, index) => {
                  return (
                    <div key={index}>
                      {item.type.includes("image/") ? (
                        <div className="simple-test-attachment-container">
                          <a
                            href={item.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={item.url}
                              className="simple-test-attachment"
                              alt="Question Paper Attachment"
                            />
                          </a>
                        </div>
                      ) : (
                        <a
                          href={item.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {item.name}
                        </a>
                      )}
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Button type="primary" onClick={this.complete_test}>
                          Upload Solutions
                        </Button>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div>
                <h3>Upload Solution</h3>
                <Upload.Dragger {...uploadProps}>
                  <p className="ant-upload-drag-icon">
                    <Icon type="inbox" />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                  <p className="ant-upload-hint">
                    Support for a single or bulk upload.
                  </p>
                </Upload.Dragger>
                <Input.TextArea
                  style={{ marginTop: 10, marginBottom: 10 }}
                  placeholder="Text..."
                  onChange={(e) =>
                    this.setState({ student_remarks: e.target.value })
                  }
                />
                <Button type="primary" onClick={this.submit_test_solutions}>
                  Submit Solution
                </Button>
              </div>
            )}
          </div>
        ) : (
          <div>
            <Card>
              <Result
                status="success"
                title="Solutions Uploaded"
                extra={[
                  <Button
                    type="primary"
                    onClick={() => this.props.history.push("/simple-test/")}
                  >
                    Go Back
                  </Button>,
                ]}
              />
            </Card>
          </div>
        )}
      </Layout.Content>
    );
  }
}

export default SimpleTestPage;
