import React, { Component } from "react";
import {
  Layout,
  Form,
  Input,
  InputNumber,
  Row,
  Col,
  Upload,
  Button,
  Checkbox,
  Icon,
  message,
  Collapse,
  Card,
  Radio,
} from "antd";
import {
  ClassSectionDropdown,
  SubjectSelectionDropdown,
} from "../../../components";
import { baseurl, getToken, api } from "../../../config";
import { connect } from "react-redux";

class QuestionForm extends Component {
  state = {
    question_type: undefined,
    count: 4,
    loading: false,
    class_id: undefined,
  };

  componentDidMount() {
    let st = this.props.location.state;
    if (st) {
      // this.props.form.setFieldsValue(st);
    }
  }

  increment = (event) => {
    event.stopPropagation();
    const { count, question_type } = this.state;
    if ([0, 1].includes(question_type)) {
      this.setState({ count: count + 1 });
      return;
    }
    message.warning("This doesn't support multiple options");
  };

  decrement = (index, event) => {
    event.stopPropagation();
    const { count } = this.state;
    let options = this.props.form.getFieldsValue()["options"];
    options.splice(index, 1);
    this.props.form.setFieldsValue({ options });
    this.setState({ count: count - 1 });
  };

  normalizeForm = (val) => {
    if ([0, 1].includes(val.type)) {
      let count = 0;
      val.options.forEach((item) => {
        if (item.is_correct) {
          count++;
        }
      });
      if (val.type === 0 && count > 1) {
        message.error(
          "Only one option can be correct, for multiple correct answers change question type."
        );
        return;
      }
      if (count === 0) {
        message.error("At least one option must be correct!");
        return;
      }
    }
    val.files =
      val.files &&
      val.files.map((item, index) => {
        return {
          name: item.name,
          size: item.size,
          uid: new Date().getTime(),
          url: baseurl + "/" + item.response.path,
          path: item.response.path,
        };
      });
    val.options =
      val.options &&
      val.options.map((option, index) => {
        option.files =
          option.files &&
          option.files.map((item) => {
            return {
              name: item.name,
              size: item.size,
              uid: new Date().getTime(),
              url: baseurl + "/" + item.response.path,
              path: item.response.path,
            };
          });
        return option;
      });
    return val;
  };

  handleSave = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        val = this.normalizeForm(val);
        let { teacher } = this.props;
        if (teacher) {
          val.teacher_id = teacher.id;
        }
        api
          .put("/ot/questions/", JSON.stringify(val))
          .then((res) => {
            if (res.data.success) {
              message.success("New Question Added");
              this.props.history.goBack();
            } else {
              message.error(res.data.error);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const FormItem = Form.Item;
    const { Panel } = Collapse;
    const { count, question_type } = this.state;
    const upload_props = {
      action: baseurl + "/upload/",
      listType: "picture-card",
      headers: {
        Authorization: getToken(),
      },
    };
    return (
      <Layout.Content>
        <Card
          title="New Question"
          extra={
            <Button type="primary" onClick={this.handleSave}>
              Save
            </Button>
          }
        >
          <Form>
            <Row gutter={16}>
              <Col sm={12}>
                <h3 className="align-center">Question</h3>
                <Row gutter={16}>
                  <Col sm={8}>
                    <FormItem label="Class">
                      {getFieldDecorator("class_id", {
                        rules: [
                          { required: true, message: "Class is required!" },
                        ],
                      })(
                        <ClassSectionDropdown
                          onChange={(class_id) => this.setState({ class_id })}
                        />
                      )}
                    </FormItem>
                  </Col>
                  <Col sm={8}>
                    <FormItem label="Subject">
                      {getFieldDecorator("subject_id", {
                        rules: [
                          { required: true, message: "Subject is required!" },
                        ],
                      })(
                        <SubjectSelectionDropdown class={this.state.class_id} />
                      )}
                    </FormItem>
                  </Col>
                  <Col sm={8}>
                    <FormItem label="Question Topic/Chapter">
                      {getFieldDecorator("topic")(<Input />)}
                    </FormItem>
                  </Col>
                </Row>
                <FormItem label="Question Type">
                  {getFieldDecorator("type", {
                    rules: [
                      { required: true, message: "Question Type is required!" },
                    ],
                  })(
                    <Radio.Group
                      onChange={(e) =>
                        this.setState({ question_type: e.target.value })
                      }
                    >
                      <Radio value={0}>
                        Multiple Choice (Sigle Correct Answer)
                      </Radio>
                      <Radio value={1}>
                        Multiple Choice (Multiple Correct Answer)
                      </Radio>
                      <Radio value={2}>Subjective (Text Entry)</Radio>
                      <Radio value={3}>
                        Media (Student uploads Image/Audio/Video)
                      </Radio>
                    </Radio.Group>
                  )}
                </FormItem>
                <FormItem label="Question">
                  {getFieldDecorator("question", {
                    rules: [
                      { required: true, message: "Question is required!" },
                    ],
                  })(<Input />)}
                </FormItem>
                <FormItem label="Description">
                  {getFieldDecorator("description")(<Input.TextArea />)}
                </FormItem>
                <FormItem label="Attachments">
                  {getFieldDecorator("files", {
                    valuePropName: "fileList",
                    getValueFromEvent: this.normFile,
                  })(
                    <Upload {...upload_props}>
                      <div>
                        <Icon type="plus" />
                        <div className="ant-upload-text">Upload</div>
                      </div>
                    </Upload>
                  )}
                </FormItem>
              </Col>
              <Col sm={12}>
                <h3 className="align-center">Marks</h3>
                <Row gutter={16} style={{ textAlign: "center" }}>
                  <Col sm={8}>
                    <FormItem label="Marks for correct answer">
                      {getFieldDecorator("correct_marks", {
                        rules: [
                          {
                            required: true,
                            message: "This field is required!",
                          },
                        ],
                      })(<InputNumber />)}
                    </FormItem>
                  </Col>
                  <Col sm={8}>
                    <FormItem label="Marks for incorrect answer">
                      {getFieldDecorator("incorrect_marks", {
                        initialValue: 0,
                        rules: [
                          { required: true, message: "This is required!" },
                        ],
                      })(<InputNumber />)}
                    </FormItem>
                  </Col>
                  <Col sm={8}>
                    <FormItem label="Marks for no answer">
                      {getFieldDecorator("no_answer_marks", {
                        initialValue: 0,
                        rules: [
                          { required: true, message: "This is required!" },
                        ],
                      })(<InputNumber />)}
                    </FormItem>
                  </Col>
                </Row>
                {[0, 1].includes(question_type) && (
                  <>
                    <h3 className="align-center">Answers/Options</h3>
                    <Collapse>
                      {[...Array(count)].map((item, index) => {
                        return (
                          <Panel
                            key={index}
                            header={`Option ${index + 1}`}
                            extra={
                              index + 1 === count ? (
                                <Icon
                                  type="plus-circle"
                                  onClick={this.increment}
                                />
                              ) : (
                                <Icon
                                  type="minus-circle"
                                  onClick={this.decrement.bind(this, index)}
                                />
                              )
                            }
                          >
                            <Row gutter={16}>
                              <Col sm={12}>
                                <FormItem label="Option">
                                  {getFieldDecorator(`options[${index}].text`, {
                                    rules: [
                                      {
                                        required: true,
                                        message: "This is required!",
                                      },
                                    ],
                                  })(<Input />)}
                                </FormItem>
                                <FormItem label="Explanation">
                                  {getFieldDecorator(
                                    `options[${index}].explanation`
                                  )(<Input.TextArea />)}
                                </FormItem>
                              </Col>
                              <Col sm={12}>
                                <FormItem label="Is correct answer">
                                  {getFieldDecorator(
                                    `options[${index}].is_correct`,
                                    {
                                      initialValue: false,
                                      rules: [
                                        {
                                          required: true,
                                          message: "This is required!",
                                        },
                                      ],
                                    }
                                  )(<Checkbox>Correct Answer</Checkbox>)}
                                </FormItem>
                                <FormItem label="Attachments">
                                  {getFieldDecorator(
                                    `options[${index}].files`,
                                    {
                                      valuePropName: "fileList",
                                      getValueFromEvent: this.normFile,
                                    }
                                  )(
                                    <Upload {...upload_props}>
                                      <div>
                                        <Icon type="plus" />
                                        <div className="ant-upload-text">
                                          Upload
                                        </div>
                                      </div>
                                    </Upload>
                                  )}
                                </FormItem>
                              </Col>
                            </Row>
                          </Panel>
                        );
                      })}
                    </Collapse>
                  </>
                )}
              </Col>
            </Row>
          </Form>
        </Card>
      </Layout.Content>
    );
  }
}

function mapStateToProps(state) {
  return {
    teacher: state.teacher,
    teacher_settings: state.teacher_settings,
  };
}

export default connect(mapStateToProps, null)(Form.create()(QuestionForm));
