import React, { Component } from "react";
import { Layout, Form, Input, Button, message, Select, Spin } from "antd";
import {
  BusDriversDropdown,
  BusVehiclesDropdown,
  BusStopsDropdown
} from "../../../../components";
import { api } from "../../../../config";

class BusRouteListForm extends Component {
  state = {
    id: null,
    spinning: false,
    loading: false
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      this.setState({ id: this.props.match.params.id });
      this.get_bus_route_details(this.props.match.params.id);
    }
  }

  get_bus_route_details = id => {
    this.setState({ spinning: true });
    api
      .get("/bus/route/" + id + "/")
      .then(res => {
        if (res.data.success) {
          this.props.form.setFieldsValue(res.data.data);
        } else {
          message.error(res.data.error);
        }
        this.setState({ spinning: false });
      })
      .catch(err => {
        console.log(err);
        this.setState({ spinning: false });
      });
  };

  handleSubmit = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        if (!this.state.id) {
          this.add_new_bus_route_item(val);
        } else {
          this.update_bus_route_item(this.state.id, val);
        }
      }
    });
  };

  add_new_bus_route_item = val => {
    this.setState({ loading: true });
    api
      .put("/bus/routes/", JSON.stringify(val))
      .then(res => {
        if (res.data && res.data.success) {
          message.success("New Bus Route Added");
          this.props.history.push("/transportation/routes/");
        } else {
          message.error(res.data.error);
        }
        this.setState({ loading: false });
      })
      .catch(err => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  update_bus_route_item = (id, val) => {
    this.setState({ loading: true });
    api
      .post("/bus/route/" + id + "/", JSON.stringify(val))
      .then(res => {
        if (res.data.success) {
          message.success("Bus Route Updated");
        } else {
          message.error(res.data.error);
        }
        this.setState({ loading: false });
      })
      .catch(err => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  render() {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    const Option = Select.Option;
    return (
      <Layout.Content>
        <h1 style={{ textAlign: "center" }}>Bus Route Form</h1>
        <Spin spinning={this.state.spinning}>
          <Form labelCol={{ sm: 8 }} wrapperCol={{ sm: 8 }}>
            <FormItem label="Route Name">
              {getFieldDecorator("name", {
                rules: [{ required: true, message: "Route Name is required" }]
              })(<Input />)}
            </FormItem>
            <FormItem label="Source">
              {getFieldDecorator("source", {
                rules: [{ required: true, message: "Source is required" }]
              })(<BusStopsDropdown />)}
            </FormItem>
            <FormItem label="Destination">
              {getFieldDecorator("destination", {
                rules: [{ required: true, message: "Destination is required" }]
              })(<BusStopsDropdown />)}
            </FormItem>
            <FormItem label="Driver's Name">
              {getFieldDecorator("driver_id", {
                rules: [{ required: true, message: "Driver is required" }]
              })(<BusDriversDropdown />)}
            </FormItem>
            <FormItem label="Vehicle">
              {getFieldDecorator("vehicle_id", {
                rules: [{ required: true, message: "Vehicle is required" }]
              })(<BusVehiclesDropdown />)}
            </FormItem>
            <FormItem label="Direction">
              {getFieldDecorator("direction", {
                rules: [{ required: true, message: "Direction is required" }]
              })(
                <Select placeholder="Direction">
                  <Option value={1}>Towards School</Option>
                  <Option value={2}>Away from School</Option>
                </Select>
              )}
            </FormItem>
            <FormItem wrapperCol={{ offset: 8 }}>
              <Button onClick={this.handleSubmit} loading={this.state.loading}>
                {this.state.id ? "Update " : "Add "}Bus Route
              </Button>
            </FormItem>
          </Form>
        </Spin>
      </Layout.Content>
    );
  }
}

export default Form.create()(BusRouteListForm);
