import React, { Component } from "react";
import { Layout, Row, Col, Table, message, Button } from "antd";
import { Link } from "react-router-dom";
import moment from "moment";
import { api } from "../../../../config";

class BusRouteStopsList extends Component {
  state = {
    route_stops: [],
    route_id: null
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      this.setState({ route_id: this.props.match.params.id });
      this.get_bus_route_stops(this.props.match.params.id);
    }
  }

  get_bus_route_stops = id => {
    api
      .get("/bus/routes/stops/" + id + "/")
      .then(res => {
        if (res.data && res.data.success) {
          this.setState({ route_stops: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  render() {
    const columns = [
      { title: "ID", dataIndex: "id" },
      { title: "Route", dataIndex: "route" },
      { title: "Bus Stop", dataIndex: "bus_stop" },
      {
        title: "Arrival Time",
        render: record => (
          <span>{moment(record.arr_time).format("hh:mm A")}</span>
        )
      },
      { title: "Amount", dataIndex: "amount" }
    ];
    return (
      <Layout.Content>
        <Row>
          <Col sm={20}>
            <h1>Bus Route Stops</h1>
          </Col>
          <Col sm={4} style={{ textAlign: "right" }}>
            <Link
              to={
                "/transportation/routes/stops/" + this.state.route_id + "/edit/"
              }
            >
              <Button>Edit</Button>
            </Link>
          </Col>
        </Row>
        <Table
          columns={columns}
          dataSource={this.state.route_stops}
          rowKey="id"
          pagination={false}
        />
      </Layout.Content>
    );
  }
}

export default BusRouteStopsList;
