import React from "react";
import { Switch, Route } from "react-router-dom";
import VisitorsList from "./VisitorsList";
import ActiveVisitors from "./ActiveVisitors";
import VisitorForm from "./VisitorForm";
import VisitorInfo from "./VisitorInfo";

const AdminVisitorsRouter = (props) => (
  <Switch>
    <Route exact path="/visitors/" component={ActiveVisitors} />
    <Route exact path="/visitors/list/" component={VisitorsList} />
    <Route exact path="/visitors/new/" component={VisitorForm} />
    <Route exact path="/visitors/info" component={VisitorInfo} />
  </Switch>
);

export { AdminVisitorsRouter };
