import React, { Component } from "react";
import {
  Layout,
  Form,
  DatePicker,
  Button,
  message,
  Input,
  Upload,
  Icon,
  Progress,
  Modal,
  Radio,
} from "antd";
import {
  ClassSectionDropdown,
  SubjectSelectionDropdown,
  TeacherSelectionDropdown,
} from "../../../components";
import moment from "moment";
import axios from "axios";
import { api } from "../../../config";

class NewClassRoom extends Component {
  state = {
    class_id: null,
    upload_progress: 0,
    file_details: {},
    show_upload_progress: false,
    class_type: "upload",
  };

  handleSubmit = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        let { file_details } = this.state;
        if (!((val.teacher_id && val.start_time) || file_details.url)) {
          message.error("Upload file or select teacher and start time");
          return;
        }
        val.public_url = file_details;
        api
          .put("/virtual-classes/", JSON.stringify(val))
          .then((res) => {
            if (res.data.success) {
              message.success("New Class Created");
              this.props.history.push("/virtual-classroom/");
            } else {
              message.error(res.data.error);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  check_upload_size = (data, blob) => {
    if (blob.size > 500 * 1024 * 1024) {
      Modal.confirm({
        title: "File size too big, sure to continue?",
        content: (
          <p>
            The file size is larger than 500MB, it'll consume too much data
            while streaming.
            <br />
            It is recommended to upload 720p video below 500MB.
          </p>
        ),
        onOk: () => {
          this.getUploadURL(data, blob);
        },
        onCancel: () => {
          message.info("File upload cancelled");
        },
      });
    } else {
      this.getUploadURL(data, blob);
    }
  };

  getUploadURL = (data, blob) => {
    api
      .put("/upload/", JSON.stringify(data))
      .then((res) => {
        if (res.data.success) {
          this.uploadVideoRecording(res.data.data, blob);
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log("Old Error ", err);
      });
  };

  uploadVideoRecording = (data, blob) => {
    this.setState({ show_upload_progress: true });
    axios
      .put(data.url, blob, {
        onUploadProgress: (e) => {
          let progress = Math.round((e.loaded * 100) / e.total);
          this.setState({
            upload_progress: progress,
          });
        },
        headers: {
          "Content-Type": blob.type,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          console.log(data);
          this.setState({ file_details: data });
        }
      })
      .catch((err) => {
        console.log("Upload Failed ", err);
        message.error("Upload Failed !");
      });
  };

  manualFileUpload = (e) => {
    let data = {
      name: e.name,
      content_type: e.type,
      size: e.size,
    };
    this.check_upload_size(data, e);
    return false;
  };

  render() {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    const { upload_progress, show_upload_progress, class_type } = this.state;
    return (
      <Layout.Content>
        <h1 className="align-center">New Virtual Classroom</h1>
        <Form wrapperCol={{ sm: 8 }} labelCol={{ sm: 8 }}>
          <FormItem label="Topic">
            {getFieldDecorator("name", {
              rules: [{ required: true, message: "Class name is required!" }],
            })(<Input />)}
          </FormItem>
          <FormItem label="Description">
            {getFieldDecorator("description", {
              rules: [{ required: true, message: "Description is required!" }],
            })(<Input />)}
          </FormItem>
          <FormItem label="Class">
            {getFieldDecorator("class_id", {
              rules: [{ required: true, message: "Class is required!" }],
            })(
              <ClassSectionDropdown
                onChange={(cls) => {
                  this.setState({ class_id: cls });
                }}
              />
            )}
          </FormItem>
          <FormItem label="Subject">
            {getFieldDecorator("subject_id", {
              rules: [{ required: true, message: "Subject is required!" }],
            })(<SubjectSelectionDropdown class={this.state.class_id} />)}
          </FormItem>
          <FormItem label="Teacher">
            {getFieldDecorator("teacher_id")(<TeacherSelectionDropdown />)}
          </FormItem>
          <FormItem label="Online Class Type">
            {getFieldDecorator("extras.class_type", { initialValue: "upload" })(
              <Radio.Group
                onChange={(e) => this.setState({ class_type: e.target.value })}
              >
                <Radio value="upload">Upload Video</Radio>
                <Radio value="eduga-online" disabled>
                  Eduga Online
                </Radio>
                <Radio value="jitsi">JitSi</Radio>
                <Radio value="zoom" disabled>
                  Zoom
                </Radio>
              </Radio.Group>
            )}
          </FormItem>
          {class_type !== "upload" ? (
            <>
              <FormItem label="Start Time">
                {getFieldDecorator("start_time")(
                  <DatePicker
                    disabledDate={(date) => {
                      return (
                        moment().subtract(1, "day").valueOf() >= date.valueOf()
                      );
                    }}
                    showTime={{ format: "HH:mm", minuteStep: 5 }}
                    format="YYYY-MM-DD HH:mm"
                  />
                )}
              </FormItem>
              <FormItem label="End Time">
                {getFieldDecorator("end_time")(
                  <DatePicker
                    disabledDate={(date) => {
                      return (
                        moment().subtract(1, "day").valueOf() >= date.valueOf()
                      );
                    }}
                    showTime={{ format: "HH:mm", minuteStep: 5 }}
                    format="YYYY-MM-DD HH:mm"
                  />
                )}
              </FormItem>
            </>
          ) : (
            <FormItem label="Upload Video">
              <Upload.Dragger
                beforeUpload={this.manualFileUpload}
                accept=".mp4"
                multiple={false}
              >
                <p className="ant-upload-drag-icon">
                  <Icon type="inbox" />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
              </Upload.Dragger>
              {show_upload_progress && (
                <div style={{ marginBottom: 16 }}>
                  <Progress percent={upload_progress} />
                </div>
              )}
            </FormItem>
          )}
          <FormItem wrapperCol={{ offset: 8 }}>
            <Button type="primary" onClick={this.handleSubmit}>
              Submit
            </Button>
          </FormItem>
        </Form>
      </Layout.Content>
    );
  }
}

export default Form.create()(NewClassRoom);
