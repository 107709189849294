import React, { Component } from "react";
import {
  Layout,
  Form,
  Input,
  InputNumber,
  DatePicker,
  Button,
  Upload,
  message,
  Spin,
} from "antd";
import { baseurl, getToken, api } from "../../../../config";
import {
  AccountsDropdown,
  VendorsDropdown,
  ExpensesCategoryDropdown,
  PaymentsModeDropdown,
  ShowBreadcrumbs,
} from "../../../../components";

var moment = require("moment");

class ExpensesForm extends Component {
  state = {
    loading: false,
    spinning: false,
    amount: 0.0,
    tax: 0.0,
    id: null,
    files: [],
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      this.setState({ id: this.props.match.params.id });
      this.get_expense(this.props.match.params.id);
    }
  }

  get_expense = (id) => {
    this.setState({ spinning: true });
    api
      .get("/finance/expense/" + id + "/")
      .then((res) => {
        if (res.data.success) {
          let data = res.data.data;
          data.date = moment(data.date);
          data.timestamp = moment(data.timestamp);
          data.files = data.files.map((item, index) => {
            return {
              uid: index,
              name: item.name,
              status: "done",
              url: item.url,
              path: item.path,
            };
          });
          this.props.form.setFieldsValue(data);
          this.setState({ amount: data.amount, tax: data.tax });
        } else {
          message.error(res.data.error);
        }
        this.setState({ spinning: false });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ spinning: false });
      });
  };

  add_expense = (val) => {
    this.setState({ loading: true });
    api
      .put("/finance/expenses/", JSON.stringify(val))
      .then((res) => {
        if (res.data && res.data.success) {
          message.success("Expense Added");
          this.props.history.push("/finance/expenses/");
        } else {
          message.error(res.data.error);
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  update_expense = (id, val) => {
    this.setState({ loading: true });
    api
      .post("/finance/expense/" + id + "/", JSON.stringify(val))
      .then((res) => {
        if (res.data.success) {
          message.success("Expense updated");
        } else {
          message.error(res.data.error);
        }
        this.setState({ loading: false });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  handleSubmit = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        if (this.state.id) {
          val.files =
            val.files &&
            val.files.map((item) => {
              if (item.response) {
                return {
                  path: item.response.path,
                  name: item.name,
                  url: baseurl + "/" + item.response.path,
                };
              }
              return item;
            });
          val.total_amount = this.state.amount + this.state.tax;
          this.update_expense(this.state.id, val);
        } else {
          val.files =
            val.files &&
            val.files.map((item) => {
              return {
                path: item.response.path,
                name: item.name,
                url: baseurl + "/" + item.response.path,
              };
            });
          val.total_amount = this.state.amount + this.state.tax;
          this.add_expense(val);
        }
      }
    });
  };

  normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const FormItem = Form.Item;
    const id = this.state.id;
    return (
      <Layout.Content>
        <ShowBreadcrumbs
          items={[
            { name: "Home", link: "/" },
            { name: "Finance", link: "/finance/" },
            { name: "Expenses", link: "/finance/expenses/" },
            {
              name: (id ? "Edit " : "New ") + "Expense",
              link: "/finance/" + (id ? "expense/" + id : "expenses/new"),
            },
          ]}
        />
        <h1 className="align-center">{id ? "Update " : "Add "} Expenses</h1>
        <Spin spinning={this.state.spinning}>
          <Form labelCol={{ sm: 8 }} wrapperCol={{ sm: 8 }}>
            <FormItem label="Expense Name">
              {getFieldDecorator("name", {
                rules: [
                  { required: true, message: "Expense name is required!" },
                ],
              })(<Input />)}
            </FormItem>
            <FormItem label="Account">
              {getFieldDecorator("account_id")(<AccountsDropdown />)}
            </FormItem>
            <FormItem label="Vendor">
              {getFieldDecorator("vendor_id")(<VendorsDropdown />)}
            </FormItem>
            <FormItem label="Expense Category">
              {getFieldDecorator("exp_cat_id")(<ExpensesCategoryDropdown />)}
            </FormItem>
            <FormItem label="Amount before taxes (in INR)">
              {getFieldDecorator("amount")(
                <InputNumber
                  style={{ width: 150 }}
                  onChange={(val) => {
                    this.setState({ amount: val });
                  }}
                />
              )}
            </FormItem>
            <FormItem label="Tax">
              {getFieldDecorator("tax", {
                initialValue: 0,
              })(
                <InputNumber
                  style={{ width: 150 }}
                  onChange={(val) => {
                    this.setState({ tax: val });
                  }}
                />
              )}
            </FormItem>
            <FormItem label="Total Amount (inclusive of taxes)">
              <InputNumber
                disabled
                style={{ width: 150 }}
                value={this.state.amount + this.state.tax}
              />
            </FormItem>
            <FormItem label="Payment Amount (installment amount)">
              {getFieldDecorator("installment_amount", {
                rules: [
                  {
                    required: true,
                    message: "Installment amount is required!",
                  },
                ],
              })(<InputNumber />)}
            </FormItem>
            <FormItem label="Payment Date">
              {getFieldDecorator("date", {
                initialValue: moment(),
              })(<DatePicker />)}
            </FormItem>
            <FormItem label="Payment Mode">
              {getFieldDecorator("payment_mode_id")(<PaymentsModeDropdown />)}
            </FormItem>
            <FormItem label="Payment Details">
              {getFieldDecorator("payment_details")(<Input />)}
            </FormItem>
            <FormItem label="Description">
              {getFieldDecorator("description")(<Input.TextArea />)}
            </FormItem>
            <FormItem label="Files (upload)">
              {getFieldDecorator("files", {
                valuePropName: "fileList",
                getValueFromEvent: this.normFile,
              })(
                <Upload
                  name="file"
                  action={baseurl + "/upload/"}
                  headers={{ Authorization: getToken() }}
                  accept=".jpg,.jpeg,.png,.pdf,.doc,.docx,.ppt,.pptx,.odt,.odp,.txt,.rtf"
                >
                  <Button icon="upload">Upload</Button>
                </Upload>
              )}
            </FormItem>
            <FormItem wrapperCol={{ offset: 8 }}>
              <Button loading={this.state.loading} onClick={this.handleSubmit}>
                {id ? "Update " : "Add "} Expense
              </Button>
            </FormItem>
          </Form>
        </Spin>
      </Layout.Content>
    );
  }
}

export default Form.create()(ExpensesForm);
