import {
  Avatar,
  Badge,
  Button,
  Col,
  Icon,
  Input,
  Layout,
  message,
  notification,
  Result,
  Row,
  Tooltip,
} from "antd";
import React, { Component } from "react";
import { api, baseurl, getToken } from "../../../config";
import moment from "moment";
import { connect } from "react-redux";
import io from "socket.io-client";
import NewChat from "./NewChat";

class ChatsList extends Component {
  state = {
    chats_list: [],
    active: undefined,
    messages: [],
    message: "",
    showModal: false,
  };
  socket = undefined;

  componentDidMount() {
    this._get_chats_list();
    this._connect_socket();
    document.body.style.overflow = "hidden";
  }

  _send_new_message = (data) => {
    this.socket.emit("chat", { type: "post", data });
    this.socket.emit("chat", { type: "chats" });
    this.socket.emit("chat", {
      type: "get",
      data: { user_id: data.to_user_id },
    });
    this.setState({ active: data.to_user_id });
    this._closeModal();
  };

  _closeModal = () => {
    this.setState({ showModal: false });
  };

  _connect_socket = () => {
    const socket = io(baseurl);

    socket.on("connect", () => {
      socket.emit("chat", {
        type: "authenticate",
        data: { token: getToken() },
      });
    });
    socket.on("chat_authenticate", (data) => {
      console.info(data);
    });
    socket.on("chat_error", (data) => {
      console.debug(data);
    });
    socket.on("chat_message_sent", (data) => {
      let { active } = this.state;
      if (active)
        socket.emit("chat", { type: "get", data: { user_id: active } });
    });
    socket.on("chat_message_received", (data) => {
      let { active } = this.state;
      socket.emit("chat", { type: "chats" });
      if (active)
        socket.emit("chat", { type: "get", data: { user_id: active } });

      if (document.hasFocus()) {
        notification.open({
          message: `New Message from ${data.name}`,
          description: data.message,
        });
        if (active) socket.emit("chat", { type: "seen", data });
      } else if (Notification.permission === "granted") {
        new Notification(`New Message from ${data.name}`, {
          body: data.message,
          icon: "/favicon.png",
        });
      } else {
        notification.open({
          message: "New Messages",
          description: "You have some unread messages",
          duration: 0,
          onClick: () => {
            this.props.history.push("/chat/");
          },
        });
      }
    });
    socket.on("chat_messages", (data) => {
      this.setState({ messages: data }, this._scroll_to_bottom);
    });
    socket.on("chat_list", (data) => {
      this.setState({ chats_list: data });
    });
    this.socket = socket;
  };

  _get_chats_list = () => {
    api
      .get("/chats/")
      .then((res) => {
        if (res.data.success) {
          this.setState({ chats_list: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  _get_user_role = (role) => {
    switch (role) {
      case 1:
        return "Parent";
      case 2:
        return "Teacher";
      case 3:
        return "Admin";
      case 4:
        return "Super Admin";
      case 5:
        return "Driver";
      case 6:
        return "Watchman";
      case 7:
        return "Librarian";
      default:
        return "Others";
    }
  };

  _set_active_chat = (chat) => {
    this._get_messages(chat.id);
    this.setState({ active: chat.id }, this.enter_press_detector);
  };

  _send_message = () => {
    const { active, message } = this.state;
    if (message.length === 0) return;
    let data = {
      to_user_id: active,
      message,
    };
    this.socket.emit("chat", { type: "post", data });
    this.setState({ message: "" });
  };

  _get_messages = (id) => {
    api
      .get(`/chat/${id}/`)
      .then((res) => {
        if (res.data.success) {
          this.setState({ messages: res.data.data }, this._scroll_to_bottom);
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  _enable_desktop_notifications = () => {
    if (!("Notification" in window)) {
      notification.error({
        message: "Error",
        description: "Desktop notifications not available in your browser",
      });
    } else if (Notification.permission === "denied") {
      notification.error({
        message: "Error",
        description: "Please allow desktop notifications from browser settings",
      });
    } else if (Notification.permission === "default") {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          new Notification("Desktop Notifications Enabled");
        } else if (permission === "denied") {
          notification.error({
            message: "Error",
            description: "You denied permission",
          });
        }
      });
    } else if (Notification.permission === "granted") {
      new Notification("Desktop Notifications Enabled");
    }
  };

  _scroll_to_bottom = () => {
    var div = document.getElementById("messages-list");
    if (!div) {
      return;
    }
    div.scrollTop = div.scrollHeight;
  };

  enter_press_detector = () => {
    let q = document.getElementById("message");
    if (!q) return;
    q.onkeyup = (e) => {
      if (!(e.key === "Enter" && e.shiftKey)) {
        if (e.code === "Enter") {
          e.preventDefault();
          this._send_message();
        }
      }
    };
  };

  render() {
    const { chats_list, active, messages, message, showModal } = this.state;
    const { user } = this.props;
    if (user.role === 1) {
      return (
        <Layout.Content>
          <Result
            status="403"
            title="Unavailable"
            subTitle="Sorry, this is not available/enabled for parents"
            extra={
              <Button
                type="primary"
                onClick={() => this.props.history.push("/")}
              >
                Go to Home
              </Button>
            }
          />
        </Layout.Content>
      );
    } else {
      return (
        <div className="chat-page-parent">
          <div className="chat-page-container">
            <Row gutter={16}>
              <Col sm={12}>
                <h1>Eduga Messenger</h1>
              </Col>
              <Col sm={12} className="action-buttons">
                {Notification.permission !== "granted" && (
                  <Button
                    style={{ marginRight: 10 }}
                    onClick={this._enable_desktop_notifications}
                  >
                    Enable Desktop Notifications
                  </Button>
                )}
                <Button
                  icon="plus"
                  type="primary"
                  onClick={() => this.setState({ showModal: true })}
                >
                  New Chat
                </Button>
              </Col>
            </Row>
            <div className="chat-page">
              <div className="chat-container">
                <div className="chat-users">
                  {chats_list.map((item, index) => {
                    let lc_time = moment.utc(item.timestamp).local();
                    return (
                      <div
                        key={index}
                        className={
                          "chat-user" + (item.id === active ? " active" : "")
                        }
                        onClick={this._set_active_chat.bind(this, item)}
                      >
                        <div className="chat-avatar">
                          <Badge count={item.id === active ? 0 : item.unread}>
                            <Avatar
                              icon="user"
                              style={{ backgroundColor: "rgb(73, 174, 45)" }}
                            />
                          </Badge>
                        </div>
                        <div className="chat-details">
                          <h3>{item.name || "No name"}</h3>
                          <div className="chat-meta-data">
                            <p className="chat-user-role">
                              {this._get_user_role(item.role)}
                            </p>
                            <Tooltip
                              title={lc_time.format("YYYY-MM-DD hh:mm A")}
                            >
                              <span className="chat-timestamp">
                                ({lc_time.fromNow()})
                              </span>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="chat-messages">
                  {active && (
                    <>
                      <div className="message-history">
                        <div className="messages-list" id="messages-list">
                          {messages.map((item, index) => {
                            return (
                              <div key={index} className={"message-item"}>
                                <div
                                  className={
                                    "message-bubble " +
                                    (user.id === item.from_user_id
                                      ? "sent"
                                      : "received")
                                  }
                                >
                                  <p className="message-text">{item.message}</p>
                                  <div className="message-timestamp">
                                    <Tooltip
                                      title={moment
                                        .utc(item.timestamp)
                                        .local()
                                        .format("YYYY-MM-DD hh:mm A")}
                                    >
                                      <span>
                                        <small>
                                          (
                                          {moment
                                            .utc(item.timestamp)
                                            .local()
                                            .fromNow()}
                                          )
                                        </small>
                                      </span>
                                    </Tooltip>
                                    <span className="seen">
                                      {item.status === 2 && (
                                        <Tooltip title="Seen">
                                          <small style={{ paddingLeft: 5 }}>
                                            <Icon type="check" title="Seen" />
                                          </small>
                                        </Tooltip>
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div className="message-textbox">
                        <Input.TextArea
                          className="message-input"
                          id="message"
                          autoFocus={true}
                          //   rows={3}
                          value={message}
                          onChange={(e) => {
                            this.setState({ message: e.target.value });
                          }}
                        />
                        <Button
                          icon="rocket"
                          shape="circle"
                          className={
                            "submit-button" +
                            (message.length === 0 ? " disabled" : "")
                          }
                          disabled={message.length === 0}
                          onClick={this._send_message}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            {showModal && (
              <NewChat
                closeModal={this._closeModal}
                sendMessage={this._send_new_message}
              />
            )}
          </div>
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps, null)(ChatsList);
