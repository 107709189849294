import React, { Component } from "react";
import {
  Layout,
  Table,
  message,
  Icon,
  Row,
  Col,
  DatePicker,
  Button,
} from "antd";
import StudentSelector from "../../../components/StudentSelector";
import { api } from "../../../config";
import { HomeworkViewer } from "../../../components";
import { Link } from "react-router-dom";

var moment = require("moment");

var state = {
  homeworks: [],
  student_id: null,
  spinning: false,
  show_modal: false,
  homework: {},
  hw_filter: [],
  start_date_range: [],
};

class StudentHomework extends Component {
  constructor(props) {
    super(props);
    this.state = state;
  }

  componentDidMount() {
    // console.log(this.state);
  }

  componentWillUnmount() {
    state = this.state;
  }

  get_homework = (student_id) => {
    this.setState({ spinning: true });
    api
      .get("/homework/" + student_id + "/")
      .then((res) => {
        if (res.data && res.data.success) {
          this.setState({ homeworks: res.data.data });
          this._set_subject_hw_filter(res.data.data);
        } else {
          message.error(res.data.error);
        }
        this.setState({ spinning: false });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ spinning: false });
      });
  };

  _set_subject_hw_filter = (data) => {
    let { hw_filter } = this.state;
    let sub_list = [];
    data.forEach((item) => {
      if (item.subject_id && !sub_list.includes(item.subject_id)) {
        sub_list.push(item.subject_id);
        hw_filter.push({ value: item.subject_id, text: item.subject });
      }
    });
    this.setState({ hw_filter });
  };

  handleStudentChange = (student_id) => {
    this.setState({ student_id });
    this.get_homework(student_id);
  };

  closeModal = () => {
    this.setState({ show_modal: false });
  };

  render() {
    const {
      show_modal,
      homework,
      homeworks,
      student_id,
      hw_filter,
      start_date_range,
    } = this.state;
    const columns = [
      { title: "ID", dataIndex: "id" },
      {
        title: "Title",
        dataIndex: "title",
        render: (v, r) => (
          <Link to={{ pathname: "/homework/" + r.id, state: { student_id } }}>
            {v}
          </Link>
        ),
      },
      {
        title: "Subject",
        dataIndex: "subject",
        onFilter: (v, r) => v === r.subject_id,
        filters: hw_filter,
      },
      {
        title: "Date",
        dataIndex: "timestamp",
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
        }) => (
          <div style={{ padding: 0 }}>
            <DatePicker.RangePicker
              autoFocus={true}
              value={selectedKeys}
              onChange={(val) => {
                // this.setState({ start_date_range: val });
                setSelectedKeys(val);
              }}
              style={{ padding: 10 }}
              size="small"
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                borderTop: "1px solid #e8e8e8",
              }}
            >
              <Button
                type="link"
                onClick={() => {
                  confirm();
                  this.setState({ start_date_range: selectedKeys });
                }}
              >
                OK
              </Button>
              <Button
                type="link"
                onClick={() => {
                  clearFilters();
                  this.setState({ start_date_range: [] });
                }}
              >
                Reset
              </Button>
            </div>
          </div>
        ),
        onFilter: (value, record) =>
          moment(start_date_range[0]).startOf("day").format("X") <
            moment(record.timestamp).format("X") &&
          moment(record.timestamp).format("X") <
            moment(start_date_range[1]).endOf("day").format("X"),
        sorter: (a, b) =>
          moment(a.timestamp).format("X") - moment(b.timestamp).format("X"),
        render: (v, r) => (
          <span>{moment.utc(v).local().format("YYYY-MM-DD")}</span>
        ),
      },
      {
        title: "Submission Date",
        render: (record) => (
          <span>
            {moment.utc(record.submission_date).local().format("YYYY-MM-DD")}
          </span>
        ),
      },
      {
        title: "Actions",
        render: (record) => (
          <span>
            <Icon
              type="read"
              className="action-icon"
              onClick={() => {
                this.setState({ show_modal: true, homework: record });
              }}
            />
          </span>
        ),
      },
    ];
    return (
      <Layout.Content>
        <Row>
          <Col sm={8}>
            <h1>Homework</h1>
          </Col>
          <Col sm={8}>
            <span>
              <strong>Student : &nbsp;</strong>
            </span>
            <StudentSelector
              value={this.state.student_id}
              onChange={this.handleStudentChange}
              style={{ width: 200 }}
            />
          </Col>
        </Row>
        <Table columns={columns} dataSource={homeworks} rowKey="id" />
        {show_modal && (
          <HomeworkViewer
            visible={show_modal}
            closeModal={this.closeModal}
            homework={homework}
            student_id={student_id}
          />
        )}
      </Layout.Content>
    );
  }
}

export default StudentHomework;
