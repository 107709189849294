import React, { Component } from "react";
import {
  Layout,
  Row,
  Col,
  Form,
  Input,
  Table,
  Button,
  message,
  Icon,
  Popconfirm
} from "antd";
import { api } from "../../../../config";
import { ShowBreadcrumbs } from "../../../../components";

class PaymentModes extends Component {
  state = {
    payment_modes: [],
    loading: false,
    spinning: false
  };

  componentDidMount() {
    this.get_payment_modes();
  }

  get_payment_modes = () => {
    this.setState({ spinning: true });
    api
      .get("/finance/payment-modes/")
      .then(res => {
        if (res.data && res.data.success) {
          this.setState({ payment_modes: res.data.data });
        } else {
          message.error(res.data.error);
        }
        this.setState({ spinning: false });
      })
      .catch(err => {
        console.log(err);
        this.setState({ spinning: false });
      });
  };

  add_payment_mode = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        this.setState({ loading: true });
        api
          .put("/finance/payment-modes/", JSON.stringify(val))
          .then(res => {
            if (res.data && res.data.success) {
              message.success("New payment mode added");
              this.get_payment_modes();
            } else {
              message.error(res.data.error);
            }
            this.setState({ loading: false });
          })
          .catch(err => {
            console.log(err);
            this.setState({ loading: false });
          });
      }
    });
  };

  delete_payment_mode = id => {
    api
      .delete("/finance/payment-mode/" + id + "/")
      .then(res => {
        if (res.data && res.data.success) {
          message.success("Deleted");
          this.get_payment_modes();
        } else {
          message.error(res.data.error);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  render() {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    const columns = [
      { title: "ID", dataIndex: "id" },
      { title: "Name", dataIndex: "name" },
      {
        title: "Actions",
        render: record => (
          <span>
            <Popconfirm
              title="Are you sure?"
              onConfirm={this.delete_payment_mode.bind(this, record.id)}
            >
              <Icon type="delete" className="action-icon" />
            </Popconfirm>
          </span>
        )
      }
    ];
    return (
      <Layout.Content>
        <ShowBreadcrumbs
          items={[
            { name: "Home", link: "/" },
            { name: "Finance", link: "/finance/" },
            { name: "Expenses", link: "/finance/expenses/" },
            { name: "Payment Modes", link: "/finance/payment-modes/" }
          ]}
        />
        <Row gutter={32}>
          <Col sm={12}>
            <h1>Payment Modes</h1>
            <Table
              columns={columns}
              dataSource={this.state.payment_modes}
              loading={this.state.spinning}
              rowKey="id"
            />
          </Col>
          <Col sm={12}>
            <h3>Add Payment Mode</h3>
            <Form>
              <FormItem label="Name">
                {getFieldDecorator("name", {
                  rules: [{ required: true, message: "Name is required!" }]
                })(<Input />)}
              </FormItem>
              <FormItem>
                <Button
                  loading={this.state.loading}
                  onClick={this.add_payment_mode}
                >
                  Add Payment Mode
                </Button>
              </FormItem>
            </Form>
          </Col>
        </Row>
      </Layout.Content>
    );
  }
}

export default Form.create()(PaymentModes);
