import React, { Component } from "react";
import { Layout, Form, Input, Button, message, Spin } from "antd";
import { connect } from "react-redux";
import {
  ShowBreadcrumbs,
  TeacherSelectionDropdown
} from "../../../../components";
import { api } from "../../../../config";
import BranchSelectionDropdown from "../../../../components/BranchSelectionDropdown";

class ClassesForm extends Component {
  state = {
    class_id: null,
    teachers: [],
    loading: false,
    spinning: false
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      this.setState({ class_id: this.props.match.params.id });
      this.getClass(this.props.match.params.id);
    } else {
      // Add new Class
    }
    this.getTeachers();
  }

  getClass = id => {
    this.setState({ spinning: true });
    api
      .get("/class/" + id + "/")
      .then(res => {
        if (res.data && res.data.success) {
          this.props.form.setFieldsValue(res.data.data);
        } else {
          message.error(res.data.error);
        }
        this.setState({ spinning: false });
      })
      .catch(err => {
        console.log(err);
        this.setState({ spinning: false });
      });
  };

  getTeachers = () => {
    api
      .get("/teachers/")
      .then(res => {
        if (res.data && res.data.success) {
          this.props.get_teachers(res.data.data);
          this.setState({ teachers: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  addNewClass = val => {
    this.setState({ loading: true });
    api
      .put("/class/", JSON.stringify(val))
      .then(res => {
        if (res.data && res.data.success) {
          message.success("New Class Added");
          this.props.history.push("/academics/classes/");
        } else {
          message.error(res.data.error);
        }
        this.setState({ loading: false });
      })
      .catch(err => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  updateClass = (id, val) => {
    this.setState({ loading: true });
    api
      .post("/class/" + id + "/", JSON.stringify(val))
      .then(res => {
        if (res.data && res.data.success) {
          message.success("Class Updated");
          this.props.history.push("/academics/classes/");
        } else {
          message.error(res.data.error);
        }
        this.setState({ loading: false });
      })
      .catch(err => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  handleSubmit = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        if (this.props.match.params.id) {
          this.updateClass(this.props.match.params.id, val);
        } else {
          this.addNewClass(val);
        }
      }
    });
  };

  render() {
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      }
    };
    const { getFieldDecorator } = this.props.form;
    const FormItem = Form.Item;
    const { class_id } = this.state;
    return (
      <Layout.Content>
        <ShowBreadcrumbs
          items={[
            { name: "Home", link: "/" },
            { name: "Academics", link: "/academics/" },
            { name: "Classes", link: "/academics/classes/" },
            { name: "Add", link: "/academics/classes/new" }
          ]}
        />
        <h1 style={{ textAlign: "center" }}>
          {class_id ? "Update" : "Add"} Class
        </h1>
        <Spin spinning={this.state.spinning}>
          <Form {...formItemLayout}>
            <FormItem label="Class">
              {getFieldDecorator("class", {
                rules: [{ required: true, message: "Please input Class" }]
              })(<Input />)}
            </FormItem>
            <FormItem label="Section">
              {getFieldDecorator("section", {
                rules: [{ required: true, message: "Please input Section" }]
              })(<Input />)}
            </FormItem>
            <FormItem label="Branch">
              {getFieldDecorator("branch_id")(<BranchSelectionDropdown />)}
            </FormItem>
            <FormItem label="Class Teacher">
              {getFieldDecorator("class_teacher_id")(
                <TeacherSelectionDropdown />
              )}
            </FormItem>
            <FormItem wrapperCol={{ offset: 8 }}>
              <Button
                type="primary"
                onClick={this.handleSubmit}
                loading={this.state.loading}
              >
                {class_id ? "Update" : "Add"}
              </Button>
            </FormItem>
          </Form>
        </Spin>
      </Layout.Content>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    get_teachers: payload => {
      dispatch({ type: "GET_TEACHERS", payload: payload });
    }
  };
}

function mapStateToProps(state) {
  return {
    user: state.user,
    teachers: state.teachers
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(ClassesForm));
