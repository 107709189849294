import React, { Component } from "react";
import {
  Layout,
  Card,
  Descriptions,
  message,
  Input,
  Button,
  Comment,
  Avatar,
  Upload,
  Tooltip,
} from "antd";
import moment from "moment";
import { api, baseurl, getToken } from "../../../config";
import HomeworkCorrector from "./HomeworkCorrector";

class HomeworkViewer extends Component {
  state = {
    id: null,
    homework: {},
    reply_id: null,
    reply_text: "",
    loading: false,
    solution_files: [],
    show_solution: false,
    solution_image: null,
    annotations: [],
    fileList: [],
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      this.setState({ id: this.props.match.params.id }, this.get_homework);
    }
  }

  get_homework = () => {
    let { id } = this.state;
    api
      .get("/homework/" + id + "/")
      .then((res) => {
        if (res.data.success) {
          this.setState({ homework: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleUpload = (info) => {
    if (info.file.status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === "done") {
      let { solution_files } = this.state;
      solution_files.push({
        uid: new Date().getTime(),
        path: info.file.response.path,
        name: info.file.name,
        size: info.file.size,
        type: info.file.type,
        status: "done",
        url: baseurl + "/" + info.file.response.path,
      });
      this.setState({ solution_files });
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
    this.setState({ fileList: [...info.fileList] });
  };

  reply_answer = () => {
    let data = {
      id: this.state.reply_id,
      homework_id: this.state.homework.id,
      remarks: this.state.reply_text,
      attachment: this.state.solution_files,
    };
    api
      .post("/homework/solution/", JSON.stringify(data))
      .then((res) => {
        if (res.data.success) {
          message.success("Remarks added");
          this.setState({
            reply_id: null,
            reply_text: "",
            solution_files: [],
            fileList: [],
          });
          this.get_homework();
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  closeModal = () => {
    this.setState({ show_solution: false, solution_image: null });
  };

  submitImage = (blob, img) => {
    let { annotations } = this.state;
    annotations.push(blob);
    this.uploadImage(blob);
    this.setState({
      annotations,
      solution_image: null,
      show_solution: false,
    });
  };

  uploadImage = (blob) => {
    let { solution_files, fileList } = this.state;
    let fd = new FormData();
    fd.append("file", blob, "attachment-file.png");
    let file = {
      uid: new Date().getTime(),
      name: "attachment-file.png",
      status: "uploading",
      size: blob.size,
      url: window.URL.createObjectURL(blob),
      type: blob.type,
    };
    api
      .post("/upload/?file_type=uploads", fd, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.status === 200) {
          message.success("File uploaded");
          file.url = baseurl + "/" + res.data.path;
          file.path = res.data.path;
          file.status = "done";
          solution_files.push(file);
          fileList.push(file);
          this.setState({ solution_files, fileList });
        } else {
          message.error(res.data.error);
          file.status = "error";
          solution_files.push(file);
          fileList.push(file);
          this.setState({ solution_files, fileList });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  removeFile = (file) => {
    let { solution_files } = this.state;
    let s = solution_files.filter((item) => {
      return file.uid === item.uid;
    });
    this.setState({ solution_files: s });
  };

  render() {
    const { Item } = Descriptions;
    const {
      id,
      homework,
      reply_id,
      reply_text,
      loading,
      show_solution,
      solution_image,
    } = this.state;
    const files = homework.files ? JSON.parse(homework.files) : [];
    return (
      <Layout.Content>
        <Card title="Homework Viewer">
          <Descriptions bordered={true} style={{ marginBottom: 20 }}>
            <Item label="Name" span={3}>
              {homework.title}
            </Item>
            <Item label="Description" span={3}>
              {homework.description}
            </Item>
            <Item label="Subject">{homework.subject}</Item>
            <Item label="Timestamp">
              {moment
                .utc(homework.timestamp)
                .local()
                .format("YYYY-MM-DD HH:mm A")}
            </Item>
            <Item label="Submission Date">
              {moment
                .utc(homework.submission_date)
                .local()
                .format("YYYY-MM-DD")}
            </Item>
            <Item label="Files/Attachements" span={3}>
              <ul>
                {files.length > 0 &&
                  files.map((item, index) => {
                    return (
                      <li key="index">
                        <a href={item.url}>{item.name}</a>
                      </li>
                    );
                  })}
              </ul>
            </Item>
          </Descriptions>
          <Card
            title="Solutions"
            extra={[
              <Button
                type="primary"
                size="small"
                onClick={() =>
                  this.props.history.push(`/homework/${id}/solutions/`)
                }
              >
                Students List
              </Button>,
            ]}
          >
            {homework.solutions && homework.solutions.length > 0 && (
              <div>
                {homework.solutions.map((item, index) => {
                  let file = JSON.parse(item.file);
                  let attachments = JSON.parse(item.attachment);
                  return (
                    <Comment
                      key={index}
                      className={
                        item.remarks
                          ? "hw_sol_with_remarks"
                          : "hw_sol_wo_remarks"
                      }
                      actions={
                        !item.remarks && [
                          <span
                            key="comment-basic-reply-to"
                            onClick={() => {
                              this.setState({
                                reply_id: item.id,
                                reply_text: "",
                                solution_files: [],
                                fileList: [],
                              });
                            }}
                          >
                            Reply
                          </span>,
                        ]
                      }
                      author={item.student_name}
                      avatar={
                        <Avatar
                          src="https://www.w3schools.com/w3images/avatar6.png"
                          alt="Avatar"
                        />
                      }
                      content={
                        <div>
                          <p>{item.description}</p>
                          {file.length ? (
                            file.map((file, ind) => {
                              return (
                                <p key={ind}>
                                  <a
                                    href={file.url || baseurl + "/" + file.path}
                                  >
                                    {file.name}
                                  </a>
                                  &nbsp; or &nbsp;
                                  {[
                                    "image/jpg",
                                    "image/jpeg",
                                    "image/png",
                                    "image/gif",
                                  ].includes(file.type) && (
                                    <Button
                                      type="dashed"
                                      size="small"
                                      onClick={() => {
                                        this.setState({
                                          show_solution: true,
                                          solution_image:
                                            file.url ||
                                            baseurl + "/" + file.path,
                                          reply_id: item.id,
                                        });
                                      }}
                                    >
                                      Correct : {file.name}
                                    </Button>
                                  )}
                                </p>
                              );
                            })
                          ) : (
                            <p>
                              <a href={file.url || baseurl + "/" + file.path}>
                                {file.name}
                              </a>
                            </p>
                          )}
                        </div>
                      }
                      datetime={
                        <Tooltip
                          title={moment(item.timestamp)
                            .utc()
                            .local()
                            .format("YYYY-MM-DD HH:mm:ss")}
                        >
                          <span>
                            {moment(item.timestamp).utc().local().fromNow()}
                          </span>
                        </Tooltip>
                      }
                    >
                      {item.remarks ? (
                        <Comment
                          avatar={
                            <Avatar
                              src="https://www.w3schools.com/howto/img_avatar2.png"
                              alt="Avatar"
                            />
                          }
                          author={item.teacher_name}
                          content={
                            <div>
                              <p>{item.remarks}</p>
                              {attachments.length > 0 &&
                                attachments.map((item, index) => {
                                  return (
                                    <p key={index}>
                                      <a
                                        href={
                                          item.url || baseurl + "/" + item.path
                                        }
                                      >
                                        {item.name}
                                      </a>
                                    </p>
                                  );
                                })}
                            </div>
                          }
                        />
                      ) : (
                        <>
                          {reply_id === item.id && (
                            <div>
                              <Input.TextArea
                                style={{ marginBottom: 10 }}
                                value={reply_text}
                                onChange={(e) => {
                                  this.setState({
                                    reply_text: e.target.value,
                                  });
                                }}
                              />
                              <div style={{ display: "flex" }}>
                                <div style={{ flex: 1 }}>
                                  <Upload
                                    name="file"
                                    action={
                                      baseurl + "/upload/?file_type=uploads"
                                    }
                                    fileList={this.state.fileList}
                                    headers={{ Authorization: getToken() }}
                                    accept=".jpg,.jpeg,.png,.pdf,.doc,.docx,.ppt,.pptx,.odt,.odp,.txt,.rtf,.mp4,.webm"
                                    onChange={this.handleUpload}
                                    multiple={true}
                                    onRemove={this.removeFile}
                                  >
                                    <Button icon="upload">Attach File</Button>
                                  </Upload>
                                </div>
                                <div style={{ textAlign: "right" }}>
                                  <Button
                                    type="primary"
                                    onClick={this.reply_answer}
                                    loading={loading}
                                  >
                                    Submit
                                  </Button>
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </Comment>
                  );
                })}
              </div>
            )}
          </Card>
        </Card>
        {show_solution && (
          <HomeworkCorrector
            imageSrc={solution_image}
            closeModal={this.closeModal}
            submitImage={this.submitImage}
          />
        )}
      </Layout.Content>
    );
  }
}

export default HomeworkViewer;
