import React, { Component } from "react";
import { Layout, Row, Col, Table, Icon, message } from "antd";
import { Link } from "react-router-dom";
import { api } from "../../../config";
import StudentSelector from "../../../components/StudentSelector";
import moment from "moment";

let state = {
  student_id: null,
  spinning: false,
  fees: [],
};

class ViewFees extends Component {
  state = state;

  componentWillUnmount() {
    state = this.state;
  }

  get_student_fees = () => {
    let { student_id } = this.state;
    api
      .get("/fees/history/" + student_id + "/")
      .then((res) => {
        if (res.data && res.data.success) {
          this.setState({
            fees: res.data.data.fees,
            student: res.data.data.student,
          });
        } else {
          message.error(res.data.error);
        }
        this.setState({ spinning: false });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ spinning: false });
      });
  };

  render() {
    const { student_id } = this.state;
    const { fees } = this.state;
    const expandedRowRender = (record) => {
      const columns = [
        {
          title: "Name",
          render: (item, record, index) => <span>Installment {index + 1}</span>,
        },
        { title: "Amount Paid", dataIndex: "paid_amount" },
        { title: "Payment Mode", dataIndex: "mode" },
        {
          title: "Date",
          render: (record) => (
            <span>{moment(record.timestamp).format("DD-MM-YYYY")}</span>
          ),
        },
      ];
      return (
        <Table
          columns={columns}
          dataSource={record.installments}
          pagination={false}
          rowKey="id"
        />
      );
    };
    const columns = [
      { title: "Acadamic Year", dataIndex: "payment.ac_year_name" },
      { title: "Name", dataIndex: "payment.name" },
      { title: "Total Fee Amount", dataIndex: "payment.total_amount" },
      { title: "Amount Paid", dataIndex: "payment.paid_amount" },
      { title: "Discount Amount", dataIndex: "payment.discount_amount" },
      {
        title: "Amount Due",
        render: (record) => (
          <span>
            {record.payment.total_amount - record.payment.paid_amount}
          </span>
        ),
      },
      {
        title: "Date",
        render: (record) => (
          <span>{moment(record.payment.timestamp).format("DD-MM-YYYY")}</span>
        ),
      },
      {
        title: "Actions",
        render: (record) => (
          <span>
            <Icon
              type="printer"
              className="action-icon"
              onClick={() => {
                this.props.history.push("/fees/receipt/" + record.payment.id);
              }}
            />
            {record.payment.total_amount - record.payment.paid_amount > 0 && (
              <span style={{ display: "inline-block", marginRight: 10 }}>
                <Link to={"/fees/pay/installment/" + record.payment.id + "/"}>
                  Pay
                </Link>
              </span>
            )}
          </span>
        ),
      },
    ];

    return (
      <Layout.Content>
        <Row gutter={24}>
          <Col sm={8}>
            <h1>View Fees</h1>
          </Col>
          <Col sm={8}>
            <span>
              <strong>Student : &nbsp;</strong>
              <StudentSelector
                value={student_id}
                onChange={(val) => {
                  this.setState({ student_id: val }, this.get_student_fees);
                }}
                style={{ width: 150 }}
              />
            </span>
          </Col>
        </Row>
        <Table
          columns={columns}
          dataSource={fees}
          expandedRowRender={expandedRowRender}
          rowKey="payment.id"
          loading={this.state.spinning}
        />
      </Layout.Content>
    );
  }
}

export default ViewFees;
