import React, { Component } from "react";
import {
  Layout,
  Row,
  Col,
  Input,
  Button,
  Icon,
  Table,
  message,
  Spin,
  Tag,
  Dropdown,
  Menu,
  Avatar,
} from "antd";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { api, baseurl } from "../../../config";
import {
  ClassSectionDropdown,
  MessageModal,
  AcademicYearDropdown,
} from "../../../components";
import DeleteStudent from "./DeleteStudent";
import moment from "moment";

const styles = {
  select: { width: 150, maxWidth: "100%" },
  input: { width: 180, maxWidth: "100%", marginLeft: 20 },
  button: { marginLeft: 10 },
};

let state = {
  students: [],
  messageModal: false,
  student: {},
  class_id: null,
  admission_no: null,
  spinning: false,
  ac_year_id: null,
  deleteModal: false,
  selected_student_ids: [],
  filters: { status: ["1"] },
};

class StudentsList extends Component {
  state = state;

  componentWillUnmount() {
    state = this.state;
  }

  componentDidMount() {
    if (this.state.class_id) {
      this.get_students(this.state.class_id);
    }
  }

  closeModal = () => {
    this.get_students(this.state.class_id, this.state.ac_year_id);
    this.setState({ messageModal: false, deleteModal: false });
  };

  handleClassSelection = (class_id) => {
    if (!class_id) {
      message.error("Please Select Both Class and Section");
    }
    if (class_id && this.state.ac_year_id) {
      this.get_students(class_id, this.state.ac_year_id);
    }
    this.setState({ class_id });
  };

  handleACYearChange = (ac_year_id) => {
    if (ac_year_id && this.state.class_id) {
      this.get_students(this.state.class_id, ac_year_id);
    }
    this.setState({ ac_year_id });
  };

  get_students = (class_id, ac_year_id) => {
    this.setState({ spinning: true });
    let url = "/students-list/" + class_id + "/";
    url = ac_year_id ? url + ac_year_id + "/" : url;
    api
      .get(url)
      .then((res) => {
        if (res.data && res.data.success) {
          this.setState({ students: res.data.data });
        } else {
          message.error(res.data.error);
        }
        this.setState({ spinning: false });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ spinning: false });
      });
  };

  handleAdmissionNoSearch = () => {
    let admission_no = this.state.admission_no;
    if (!admission_no) {
      message.error("Enter admission number");
      return;
    }
    this.setState({ spinning: true });
    api
      .get("/search/student/" + admission_no + "/")
      .then((res) => {
        if (res.data && res.data.success) {
          this.setState({ students: res.data.data });
        } else {
          message.error(res.data.error);
        }
        this.setState({ spinning: false });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ spinning: false });
      });
  };

  selectedStudents = (selectedRowKeys, selectedRows) => {
    this.setState({ selected_student_ids: selectedRowKeys });
  };

  exportStudentsList = () => {
    let { students, class_id } = this.state;
    var csvContent = "data:text/csv;charset=utf-8,";
    let header =
      "ID,Student Name,DoB,Mobile,Admission No,Admission Date,Roll No,Admission Type,Quota,Religion,Caste,Address\r\n";
    csvContent += header;
    students.forEach((item) => {
      if (item.acy_status !== 1) {
        return;
      }
      let address = item.extras.address || " - ";
      address = address.replace("#", "");
      let line = `"${item.id}","${item.fullname}","${moment
        .utc(item.dob)
        .local()
        .format("YYYY-MM-DD")}","${item.mobile}","${
        item.admission_no
      }","${moment.utc(item.admission_date).local().format("YYYY-MM-DD")}","${
        item.roll_no
      }","${item.admission_type}","${item.quota}","${item.religion}","${
        item.caste
      }","${address}"\r\n`;
      csvContent += line;
    });
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `students_class_${class_id}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  _search_student = (q) => {
    if (!q) {
      let { ac_year_id, class_id } = this.state;
      this.get_students(class_id, ac_year_id);
      return;
    }
    this.setState({ spinning: true });
    let url = "/search/students/?q=" + q;
    api
      .get(url)
      .then((res) => {
        if (res.data.success) {
          this.setState({ students: res.data.data, spinning: false });
        } else {
          message.error(res.data.error);
          this.setState({ spinning: true });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({ spinning: true });
      });
  };

  downloadAllStudentsList = () => {
    api
      .get("/export/students/")
      .then((res) => {
        if (res.data && res.data.data) {
          let url = `${baseurl}/${res.data.data.path}`;
          var link = document.createElement("a");
          link.setAttribute("href", url);
          link.setAttribute("download", `eduga_students_list.csv`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  get_status = (r) => {
    if (r.status === 2) {
      return <Tag color="volcano">TC</Tag>;
    }
    if (!r.acy_status && r.status === 3) {
      return <Tag color="geekblue">Graduated</Tag>;
    } else if (!r.acy_status && r.status === 0) {
      return <Tag color="orange">Year Break</Tag>;
    } else if (!r.acy_status && r.status === 1) {
      return <Tag color="green">Active</Tag>;
    }
    switch (r.acy_status) {
      case -1:
        return <Tag color="red">Detained</Tag>;
      case 0:
        return <Tag color="orange">Year Break</Tag>;
      case 1:
        return <Tag color="green">Current</Tag>;
      case 2:
        return <Tag color="cyan">Promoted</Tag>;
      case 3:
        return <Tag color="geekblue">Graduated</Tag>;
      case 4:
        return <Tag color="purple">Shuffled</Tag>;
      default:
        return <Tag>Unknown</Tag>;
    }
  };

  onTableChange = (pagination, filters, sorters) => {
    this.setState({ filters });
  };

  render() {
    let filters = this.state.filters || {};
    const columns = [
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
        render: (v, r, i) => <span>{i + 1}</span>,
      },
      {
        title: "Avatar",
        dataIndex: "profile_photo",
        render: (v) =>
          v ? <Avatar src={`${baseurl}/${v}`} /> : <Avatar icon="user" />,
      },
      {
        title: "Name",
        dataIndex: "fullname",
        key: "fullname",
        sorter: (a, b) =>
          a.fullname.toLowerCase() < b.fullname.toLowerCase() ? -1 : 1,
        render: (v, r) => (
          <span>
            <Link to={"/students/details/" + r.id}>
              {v} {r.class && `(${r.class}${r.section})`}
            </Link>
          </span>
        ),
      },
      {
        title: "Mobile",
        dataIndex: "mobile",
        key: "mobile",
      },
      {
        title: "Admission No",
        dataIndex: "admission_no",
        key: "admission_no",
      },
      {
        title: "Admission Date",
        dataIndex: "admission_date",
        render: (v) => (
          <span>{moment.utc(v).local().format("YYYY-MM-DD")}</span>
        ),
      },
      {
        title: "Roll No",
        dataIndex: "roll_no",
        key: "roll_no",
      },
      {
        title: "SATS/USN",
        dataIndex: "usn",
      },
      {
        title: "Status",
        dataIndex: "status",
        filters: [
          { text: "Current", value: "1" },
          { text: "Promoted", value: "2" },
          { text: "Graduated", value: "3" },
          { text: "Shuffled", value: "4" },
        ],
        onFilter: (v, r) => {
          return r.acy_status === parseInt(v, 10);
        },
        filteredValue: filters.status,
        // filtered: true,
        render: (v, r) => {
          let status = this.get_status(r);
          let at = r.admission_type;
          return (
            <span>
              {status}
              {at && <Tag color={at === "NEW" ? "red" : "blue"}>{at}</Tag>}
              {r.quota && <Tag color="volcano">{r.quota}</Tag>}
            </span>
          );
        },
      },
      {
        title: "Actions",
        render: (record) => (
          <span>
            {/* <Icon
              type="edit"
              title="Edit Student Attendance"
              className="action-icon"
              onClick={() => {
                let url = "";
                if (this.props.user.role === 2) {
                  url = "/students/edit/" + record.id + "/";
                } else {
                  url = "/students/edit/" + record.id + "/";
                }
                this.props.history.push(url);
              }}
            /> */}
            {[3, 4].includes(this.props.user.role) && (
              <Icon
                type="form"
                title="Edit Student Profile"
                className="action-icon"
                onClick={() => {
                  let url = "/students/" + record.id + "/";
                  this.props.history.push(url);
                }}
              />
            )}
            {[3, 4].includes(this.props.user.role) && !record.user_id && (
              <Icon
                type="link"
                title="Link User to Student"
                className="action-icon"
                onClick={() => {
                  let url = "/students/link/" + record.id + "/";
                  this.props.history.push(url);
                }}
              />
            )}
            <Icon
              type="dollar"
              className="action-icon"
              onClick={() => {
                this.props.history.push("/fees/records/" + record.id + "/");
              }}
            />
            <Icon
              type="message"
              className="action-icon"
              onClick={() => {
                this.setState({ messageModal: true, student: record });
              }}
            />
            {[3, 4].includes(this.props.user.role) && (
              <Icon
                type="delete"
                className="action-icon absent"
                onClick={() => {
                  this.setState({ deleteModal: true, student: record });
                }}
              />
            )}
          </span>
        ),
      },
    ];
    const rowSelection = {
      onChange: this.selectedStudents,
    };
    const { selected_student_ids, students } = this.state;
    return (
      <Layout.Content>
        <Row gutter={24} className="ribbon-row">
          <Col sm={16} xs={24}>
            <span>
              <strong>Class : </strong>
              <ClassSectionDropdown
                onChange={this.handleClassSelection}
                value={this.state.class_id}
              />
            </span>
            <span>
              <strong>AC Year : </strong>
              <AcademicYearDropdown
                style={{ width: 150 }}
                onChange={this.handleACYearChange}
                value={this.state.ac_year_id}
              />
            </span>
            <Input.Search
              placeholder="Search Student"
              onChange={(e) => this._search_student(e.target.value)}
              onSearch={this._search_student.bind(this)}
              style={styles.input}
            />
          </Col>
          <Col sm={8} xs={24} className="action-buttons">
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item onClick={this.downloadAllStudentsList}>
                    Export All Students
                  </Menu.Item>
                  <Menu.Item
                    onClick={this.exportStudentsList}
                    disabled={students.length === 0}
                  >
                    Export This Class
                  </Menu.Item>
                  <Menu.Item
                    onClick={() =>
                      this.props.history.push("/reports/multi-class")
                    }
                  >
                    Multi Class Export
                  </Menu.Item>
                  <Menu.Item
                    onClick={() =>
                      this.props.history.push("/reports/app-users/")
                    }
                  >
                    App Users
                  </Menu.Item>
                </Menu>
              }
            >
              <Button icon="export" type="primary" ghost>
                Export <Icon type="down" />
              </Button>
            </Dropdown>
            <Button
              icon="message"
              disabled={selected_student_ids.length === 0}
              style={styles.button}
              onClick={() => {
                this.setState({ messageModal: true });
              }}
            >
              Message
            </Button>
            {[3, 4].includes(this.props.user.role) && (
              <Link to="/students/new">
                <Button
                  icon="user-add"
                  type="primary"
                  style={styles.button}
                  className="action-button"
                >
                  Add Student
                </Button>
              </Link>
            )}
          </Col>
        </Row>
        <Spin spinning={this.state.spinning}>
          <Table
            columns={columns}
            dataSource={this.state.students}
            rowSelection={rowSelection}
            rowKey="id"
            pagination={{ defaultPageSize: 50 }}
            onChange={this.onTableChange}
          />
        </Spin>
        {this.state.messageModal && (
          <MessageModal
            visible={this.state.messageModal}
            closeModal={this.closeModal}
            student={this.state.student}
            students_list={selected_student_ids}
          />
        )}
        {this.state.deleteModal && (
          <DeleteStudent
            visible={this.state.deleteModal}
            closeModal={this.closeModal}
            student={this.state.student}
          />
        )}
      </Layout.Content>
    );
  }
}

function mapStateToProps(state) {
  return {
    class: state.class,
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    get_classes: (payload) => {
      dispatch({ type: "GET_CLASSES", payload });
    },
    get_students: (payload) => {
      dispatch({ type: "GET_STUDENTS", payload });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StudentsList);
