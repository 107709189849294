import React from "react";
import { Switch, Route } from "react-router-dom";
import AdminDashboard from "./AdminDashboard";

const AdminDashboardRouter = props => (
  <Switch>
    <Route exact path="/" component={AdminDashboard} />
    <Route exact path="/" component={AdminDashboard} />
  </Switch>
);

export { AdminDashboardRouter };
