import { DatePicker, Form, Input, InputNumber, message, Modal } from "antd";
import React, { Component } from "react";
import { AccountsDropdown, PaymentsModeDropdown } from "../../../../components";
import { api } from "../../../../config";
import moment from "moment";

class PayExpenseInstallment extends Component {
  state = {
    loading: false,
  };

  componentDidMount() {}

  _handleSubmit = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        let { expense } = this.props;
        if (
          val.paid_amount > expense.total_amount - expense.paid_amount ||
          val.paid_amount < 0
        ) {
          message.error(
            "Amount paying must be more than 0 and less than balance"
          );
          return;
        }
        val.expense_id = expense.id;
        this.setState({ loading: true });
        api
          .patch("/finance/expenses/", JSON.stringify(val))
          .then((res) => {
            if (res.data.success) {
              message.success("Installment Paid");
              this.props.closeModal();
            } else {
              message.error(res.data.error);
            }
            this.setState({ loading: false });
          })
          .catch((err) => {
            console.log(err);
            this.setState({ loading: false });
          });
      }
    });
  };

  render() {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    const { loading } = this.state;
    return (
      <Modal
        visible={true}
        title="Expense Intallment Form"
        onCancel={this.props.closeModal.bind(this)}
        onOk={this._handleSubmit}
        confirmLoading={loading}
      >
        <Form>
          <FormItem label="Amount Paid">
            {getFieldDecorator("paid_amount", {
              rules: [{ required: true, message: "Amount is required!" }],
            })(<InputNumber />)}
          </FormItem>
          <FormItem label="Account">
            {getFieldDecorator("account_id")(<AccountsDropdown />)}
          </FormItem>
          <FormItem label="Payment Mode">
            {getFieldDecorator("payment_mode_id")(<PaymentsModeDropdown />)}
          </FormItem>
          <FormItem label="Payment Details">
            {getFieldDecorator("payment_details")(<Input />)}
          </FormItem>
          <FormItem label="Payment Date">
            {getFieldDecorator("payment_date", {
              initialValue: moment(),
            })(<DatePicker />)}
          </FormItem>
        </Form>
      </Modal>
    );
  }
}

export default Form.create()(PayExpenseInstallment);
