import React, { Component } from "react";
import { Layout, Descriptions, Card } from "antd";

class ViewQuestion extends Component {
  state = {
    question: {},
  };

  componentDidMount() {
    if (this.props.location.state) {
      this.setState({ question: this.props.location.state });
    }
  }

  render() {
    const { question } = this.state;
    const { Item } = Descriptions;
    return (
      <Layout.Content>
        <Card title="Question">
          <Descriptions bordered>
            <Item label="Question Type" span={3}>
              {question.question_type}
            </Item>
            <Item label="Question" span={3}>
              {question.question}
            </Item>
            <Item label="Options" span={3}>
              <ol>
                {question.options &&
                  question.options.map((item, index) => {
                    return <li key={index}>{item}</li>;
                  })}
              </ol>
            </Item>
            <Item label="Answer">{parseInt(question.answer, 10) + 1}</Item>
            <Item label="Explanation" span={2}>
              {question.explanation}
            </Item>
            <Item label="Correct Marks">{question.correct_marks}</Item>
            <Item label="Incorrect Marks">{question.incorrect_marks}</Item>
            <Item label="No Answer Marks">{question.no_answer_marks}</Item>
          </Descriptions>
        </Card>
      </Layout.Content>
    );
  }
}

export default ViewQuestion;
