import React from "react";
import { Switch, Route } from "react-router-dom";
import NotesList from "./NotesList";
import AddNote from "./AddNote";

const TeacherNotesRouter = props => (
  <Switch>
    <Route exact path="/notes/" component={NotesList} />
    <Route exact path="/notes/new" component={AddNote} />
  </Switch>
);

export { TeacherNotesRouter };
