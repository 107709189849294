import React, { Component } from "react";
import { Layout, Table, Row, Col, message, Button, Modal } from "antd";
import {
  ClassSectionDropdown,
  AcademicYearDropdown,
} from "../../../../components";
import { api } from "../../../../config";

class PromoteStudents extends Component {
  state = {
    pre_year: {
      class_id: null,
      ac_year_id: null,
    },
    post_year: {
      class_id: null,
      ac_year_id: null,
    },
    spinning: false,
    students: [],
    selected_student_ids: [],
  };

  pre_year_class_id_change = (class_id) => {
    let { pre_year } = this.state;
    pre_year.class_id = class_id;
    if (pre_year.class_id && pre_year.ac_year_id) {
      this.get_student_list(pre_year.class_id, pre_year.ac_year_id);
    }
    this.setState({ pre_year });
  };

  pre_year_ac_year_change = (ac_year_id) => {
    let { pre_year } = this.state;
    pre_year.ac_year_id = ac_year_id;
    if (pre_year.class_id && pre_year.ac_year_id) {
      this.get_student_list(pre_year.class_id, pre_year.ac_year_id);
    }
    this.setState({ pre_year });
  };

  post_year_class_id_change = (class_id) => {
    let { post_year } = this.state;
    post_year.class_id = class_id;
    this.setState({ post_year });
  };

  post_year_ac_year_change = (ac_year_id) => {
    let { post_year } = this.state;
    post_year.ac_year_id = ac_year_id;
    this.setState({ post_year });
  };

  get_student_list = (class_id, ac_year_id) => {
    this.setState({ spinning: true });
    let url = "/students-list/" + class_id + "/";
    url = ac_year_id ? url + ac_year_id + "/" : url;
    api
      .get(url)
      .then((res) => {
        if (res.data && res.data.success) {
          let { selected_student_ids } = this.state;
          selected_student_ids.length = 0;
          this.setState({
            students: res.data.data,
            selected_student_ids: selected_student_ids,
          });
        } else {
          message.error(res.data.error);
        }
        this.setState({ spinning: false });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ spinning: false });
      });
  };

  selectedStudents = (selectedRowKeys, selectedRows) => {
    this.setState({ selected_student_ids: selectedRowKeys });
  };

  handlePromote = () => {
    let { pre_year, post_year, selected_student_ids } = this.state;
    let that = this;
    if (
      pre_year.class_id &&
      pre_year.ac_year_id &&
      post_year.class_id &&
      post_year.ac_year_id
    ) {
      if (pre_year.ac_year_id === post_year.ac_year_id) {
        message.error("Academic Year can't be same");
        return;
      }
      if (selected_student_ids.length === 0) {
        message.error("Please select students to be promoted");
        return;
      }
      if (pre_year.class_id === post_year.class_id) {
        // message.error("Class can't be same");
        // This means student has failed
      }
      const content = (
        <div>
          <p>Are you sure you want to promote the selected students?</p>
          <p>
            <strong>Note : </strong>Unmarked students will remain as it is.
            <br />
            To detain students select From class and To class same.
          </p>
        </div>
      );
      Modal.confirm({
        title: "Promote Students",
        content: content,
        // "Are you sure you want to promote these student?\nNote: Promoting will change class and section of these students.",
        onOk() {
          let data = {
            from_class: pre_year.class_id,
            from_ac_year: pre_year.ac_year_id,
            to_class: post_year.class_id,
            to_ac_year: post_year.ac_year_id,
            students: selected_student_ids,
          };
          api
            .post("/promote/students/", JSON.stringify(data))
            .then((res) => {
              if (res.data && res.data.success) {
                selected_student_ids.length = 0;
                message.success("Students promoted");
                that.get_student_list(pre_year.class_id, pre_year.ac_year_id);
                that.setState({ selected_student_ids });
              } else {
                message.error(res.data.error);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        },
        onCancel() {
          message.info("Cancelled promotion");
        },
      });
    } else {
      message.error(
        "Select From class, academic year and To class, academic year"
      );
      return;
    }
  };

  handleGraduate = () => {
    let { pre_year, selected_student_ids } = this.state;
    let that = this;
    if (pre_year.class_id && pre_year.ac_year_id) {
      if (selected_student_ids.length === 0) {
        message.error("Please select students to be graduated");
        return;
      }
      const content = (
        <div>
          <p>
            Are you sure you want to mark the selected students as graduate?
          </p>
          <p>
            <strong>Note : </strong>Unmarked students will remain as it is.
            <br />
            To detain students select From class and To class same.
          </p>
        </div>
      );
      Modal.confirm({
        title: "Graduate Students",
        content: content,
        onOk() {
          let data = {
            from_class: pre_year.class_id,
            from_ac_year: pre_year.ac_year_id,
            students: selected_student_ids,
          };
          api
            .put("/promote/students/", JSON.stringify(data))
            .then((res) => {
              if (res.data && res.data.success) {
                selected_student_ids.length = 0;
                message.success("Students marked as Graduates");
                that.get_student_list(pre_year.class_id, pre_year.ac_year_id);
                that.setState({ selected_student_ids });
              } else {
                message.error(res.data.error);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        },
        onCancel() {
          message.info("Cancelled Graduation");
        },
      });
    } else {
      message.error("Select From class and academic year");
    }
  };

  handleYearBreak = () => {
    let { pre_year, selected_student_ids } = this.state;
    let that = this;
    if (pre_year.class_id && pre_year.ac_year_id) {
      if (selected_student_ids.length === 0) {
        message.error(
          "Please select students to be marked for year back/break"
        );
        return;
      }
      const content = (
        <div>
          <p>
            The selected students will be marked for year back/break. Click ok
            to Proceed.
          </p>
        </div>
      );
      Modal.confirm({
        title: "Year Back/Break",
        content: content,
        onOk() {
          let data = {
            from_class: pre_year.class_id,
            from_ac_year: pre_year.ac_year_id,
            students: selected_student_ids,
          };
          api
            .patch("/promote/students/", JSON.stringify(data))
            .then((res) => {
              if (res.data && res.data.success) {
                selected_student_ids.length = 0;
                message.success("Students marked for Year Back/Break");
                that.get_student_list(pre_year.class_id, pre_year.ac_year_id);
                that.setState({ selected_student_ids });
              } else {
                message.error(res.data.error);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        },
        onCancel() {
          message.info("Year Back/Break Marking Cancelled");
        },
      });
    } else {
      message.error("Select From class and academic year");
    }
  };

  handleShuffle = () => {
    let { pre_year, post_year, selected_student_ids } = this.state;
    let that = this;
    if (
      pre_year.class_id &&
      pre_year.ac_year_id &&
      post_year.class_id &&
      post_year.ac_year_id
    ) {
      if (pre_year.ac_year_id === post_year.ac_year_id) {
        // message.error("Academic Year can't be same");
        // return;
      }
      if (selected_student_ids.length === 0) {
        message.error("Please select students to be promoted");
        return;
      }
      if (pre_year.class_id === post_year.class_id) {
        // message.error("Class can't be same");
        // This means student has failed
      }
      const content = (
        <div>
          <p>Are you sure you want to change classes of selected students?</p>
          <p>
            <strong>Note : </strong>It is recommended to shuffle students at the
            start of the academic year. Shuffling inbetween academic year can be
            disastrous.
          </p>
        </div>
      );
      Modal.confirm({
        title: "Shuffle Students",
        content: content,
        // "Are you sure you want to promote these student?\nNote: Promoting will change class and section of these students.",
        onOk() {
          let data = {
            from_class: pre_year.class_id,
            from_ac_year: pre_year.ac_year_id,
            to_class: post_year.class_id,
            to_ac_year: post_year.ac_year_id,
            students: selected_student_ids,
          };
          api
            .post("/shuffle/students/", JSON.stringify(data))
            .then((res) => {
              if (res.data && res.data.success) {
                selected_student_ids.length = 0;
                message.success("Students shuffled");
                that.get_student_list(pre_year.class_id, pre_year.ac_year_id);
                that.setState({ selected_student_ids });
              } else {
                message.error(res.data.error);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        },
        onCancel() {
          message.info("Shuffling cancelled");
        },
      });
    } else {
      message.error(
        "Select From class, academic year and To class, academic year"
      );
      return;
    }
  };

  render() {
    const columns = [
      { title: "Admn. No.", dataIndex: "admission_no" },
      { title: "Student Name", dataIndex: "fullname" },
      {
        title: "Status",
        render: (r) => {
          switch (r.acy_status) {
            case -1:
              return "Detained";
            case 0:
              return "Year Break";
            case 1:
              return "Current";
            case 2:
              return "Promoted";
            case 3:
              return "Graduated";
            case 4:
              return "Shuffled";
            default:
              return "Unknown";
          }
        },
      },
    ];
    let { pre_year, post_year } = this.state;
    const rowSelection = {
      onChange: this.selectedStudents,
      getCheckboxProps: (r) => ({
        disabled: r.acy_status !== 1,
      }),
    };
    return (
      <Layout.Content>
        <Row className="ribbon-row">
          <Col xl={12} sm={12}>
            <h1>Promote Students</h1>
          </Col>
          <Col xl={12} sm={12} className="action-buttons">
            <Button icon="swap" onClick={this.handleShuffle}>
              Shuffle
            </Button>
            <Button type="danger" icon="pause" onClick={this.handleYearBreak}>
              Year Back/Break
            </Button>
            <Button type="primary" icon="trophy" onClick={this.handleGraduate}>
              Graduate
            </Button>
            <Button
              type="primary"
              icon="up-circle"
              onClick={this.handlePromote}
            >
              Promote
            </Button>
          </Col>
        </Row>
        <Row style={{ marginBottom: 10 }}>
          <Col sm={4} xs={24}>
            <h3>From Class : </h3>
          </Col>
          <Col sm={4} xs={12}>
            <ClassSectionDropdown
              value={pre_year.class_id}
              onChange={this.pre_year_class_id_change}
            />
          </Col>
          <Col sm={4} xs={12}>
            <AcademicYearDropdown
              value={pre_year.ac_year_id}
              onChange={this.pre_year_ac_year_change}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: 10 }}>
          <Col sm={4} xs={24}>
            <h3>To Class : </h3>
          </Col>
          <Col sm={4} xs={12}>
            <ClassSectionDropdown
              value={post_year.class_id}
              onChange={this.post_year_class_id_change}
            />
          </Col>
          <Col sm={4} xs={12}>
            <AcademicYearDropdown
              value={post_year.ac_year_id}
              onChange={this.post_year_ac_year_change}
            />
          </Col>
        </Row>
        <Table
          columns={columns}
          dataSource={this.state.students}
          rowSelection={rowSelection}
          rowKey="id"
          pagination={{ defaultPageSize: 50 }}
        />
      </Layout.Content>
    );
  }
}

export default PromoteStudents;
