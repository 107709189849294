import React, { Component } from "react";
import { Layout, Form, InputNumber, Checkbox, Button, message } from "antd";
import {
  AcademicYearDropdown,
  EmployeeDropdown,
  LeaveTypeDropdown,
} from "../../../../components";
import { api } from "../../../../config";

class EmployeeLeaveForm extends Component {
  state = {
    emp_id: undefined,
    lop: false,
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      this.setState({ emp_id: this.props.match.params.id });
    }
  }

  handleSubmit = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        let { emp_id } = this.state;
        api
          .put(`/hr/emp/${emp_id}/leaves/`, JSON.stringify(val))
          .then((res) => {
            if (res.data.success) {
              message.success("Leave Added");
              this.props.history.push(`/hr/employee/${emp_id}/leaves`);
            } else {
              message.error(res.data.error);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  render() {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    const { lop } = this.state;
    return (
      <Layout.Content>
        <h1 className="align-center">Add Employee Leaves</h1>
        <Form labelCol={{ sm: 8 }} wrapperCol={{ sm: 8 }}>
          <FormItem label="Academic Year">
            {getFieldDecorator("ac_year_id", {
              rules: [
                { required: true, message: "Academic year is required!" },
              ],
            })(<AcademicYearDropdown />)}
          </FormItem>
          <FormItem label="Employee">
            {getFieldDecorator("hr_employee_id", {
              rules: [{ required: true, message: "Employee is required!" }],
            })(<EmployeeDropdown />)}
          </FormItem>
          <FormItem label="Leave Type">
            {getFieldDecorator("hr_leave_type_id", {
              rules: [{ required: true, message: "Leave type is required!" }],
            })(<LeaveTypeDropdown />)}
          </FormItem>
          <FormItem wrapperCol={{ offset: 8 }}>
            {getFieldDecorator("loss_of_pay", {
              initialValue: false,
              rules: [{ required: true, message: "Loss of pay is required!" }],
            })(
              <Checkbox
                onChange={(e) => this.setState({ lop: e.target.checked })}
              >
                Loss of Pay/Overtime
              </Checkbox>
            )}
          </FormItem>
          {!lop ? (
            <FormItem label="Days">
              {getFieldDecorator("value", {
                rules: [{ required: true, message: "Value is required!" }],
              })(<InputNumber />)}
            </FormItem>
          ) : (
            <FormItem label="OT Rate (if OT)">
              {getFieldDecorator("payment_multiplier", {
                initialValue: 1.0,
              })(<InputNumber />)}
            </FormItem>
          )}
          <FormItem wrapperCol={{ offset: 8 }}>
            <Button type="primary" onClick={this.handleSubmit}>
              Save
            </Button>
          </FormItem>
        </Form>
      </Layout.Content>
    );
  }
}

export default Form.create()(EmployeeLeaveForm);
