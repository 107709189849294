import { createStore, combineReducers } from "redux";
import userReducer from "./reducers/userReducer";
import teachersReducer from "./reducers/teachersReducer";
import classReducer from "./reducers/classReducer";
import studentsReducer from "./reducers/studentsReducer";
import aboutSchoolReducer from "./reducers/aboutSchoolReducer";
import notificationReducer from "./reducers/notificationsReducer";
import teacherSettingsReducer from "./reducers/teacherSettingsReducer";
import teacherReducer from "./reducers/teacherReducer";

const rootReducer = combineReducers({
  user: userReducer,
  teachers: teachersReducer,
  class: classReducer,
  students: studentsReducer,
  about_school: aboutSchoolReducer,
  notifications: notificationReducer,
  teacher_settings: teacherSettingsReducer,
  teacher: teacherReducer,
});

const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;
