import React, { Component } from "react";
import { Layout, message, Table, Tag, Icon } from "antd";
import { api } from "../../../config";
import moment from "moment";

class OnlineTestAttempts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      test_id: undefined,
      test_assign_id: undefined,
      attempts: [],
    };
  }

  componentDidMount() {
    let { test_id, test_assign_id } = this.props.match.params;
    if (test_assign_id && test_id) {
      this.setState({ test_id, test_assign_id }, this.get_attempts_list);
    }
  }

  get_attempts_list = () => {
    let { test_assign_id } = this.state;
    let url = `/ot/${test_assign_id}/attempts/`;
    api
      .get(url)
      .then((res) => {
        if (res.data.success) {
          this.setState({ attempts: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => console.log(err));
  };

  render() {
    const { attempts, test_id } = this.state;
    const columns = [
      { title: "ID", dataIndex: "id" },
      { title: "Student Name", dataIndex: "name" },
      {
        title: "Start Time",
        dataIndex: "start_time",
        render: (v) => (
          <span>{moment.utc(v).local().format("YYYY-MM-DD hh:mm A")}</span>
        ),
      },
      {
        title: "End Time",
        dataIndex: "end_time",
        render: (v) => (
          <span>{moment.utc(v).local().format("YYYY-MM-DD hh:mm A")}</span>
        ),
      },
      { title: "Marks Obtained", dataIndex: "marks_obtained" },
      {
        title: "Status",
        dataIndex: "status",
        render: (v, r) => {
          switch (v) {
            case -1:
              return <Tag>Incomplete</Tag>;
            case 0:
              return <Tag>Unknown</Tag>;
            case 1:
              return <Tag color="blue">Ongoing</Tag>;
            case 2:
              return <Tag color="green">Finished</Tag>;
            default:
              return <Tag>Unknown</Tag>;
          }
        },
      },
      {
        title: "Actions",
        render: (r) => (
          <span>
            <Icon
              type="pie-chart"
              className="action-icon"
              title="Check answers"
              onClick={() =>
                this.props.history.push(
                  `/online-test/${test_id}/attempt/${r.id}/answers/`
                )
              }
            />
          </span>
        ),
      },
    ];
    return (
      <Layout.Content>
        <h1>Online Test Attemps</h1>
        <Table columns={columns} dataSource={attempts} rowKey="id" />
      </Layout.Content>
    );
  }
}

export default OnlineTestAttempts;
