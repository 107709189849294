import React, { Component } from "react";
import { Layout, Row, Col, Spin, message, Table, Checkbox, Button } from "antd";
import { api } from "../../../config";

class ModulesList extends Component {
  state = {
    modules: [],
    spinning: false,
    loading: false
  };

  componentDidMount() {
    this.get_modules_list();
  }

  get_modules_list = () => {
    this.setState({ spinning: true });
    api
      .get("/settings/modules/")
      .then(res => {
        if (res.data.success) {
          this.setState({ modules: res.data.data });
        } else {
          message.error(res.data.error);
        }
        this.setState({ spinning: false });
      })
      .catch(err => {
        console.log(err);
        this.setState({ spinning: false });
      });
  };

  expandedRow = (rec, ind) => {
    if (!rec.children) {
      return null;
    }
    const columns = [
      { title: "Sub module", dataIndex: "title" },
      {
        title: "Enabled",
        render: (value, record, index) => (
          <span>
            <Checkbox
              checked={record.enabled}
              onChange={e => {
                let modules = this.state.modules;
                modules[ind].children[index].enabled = e.target.checked;
                this.setState({ modules });
              }}
            />
          </span>
        )
      }
    ];

    return (
      <Table
        columns={columns}
        dataSource={rec.children}
        pagination={false}
        rowKey="link"
      />
    );
  };

  handleModulesSave = () => {
    this.setState({ loading: true });
    api
      .post(
        "/settings/modules/",
        JSON.stringify({ modules: this.state.modules })
      )
      .then(res => {
        if (res.data.success) {
          message.success("Modules list updated");
          setTimeout(() => {
            window.location.reload();
          }, 750);
        } else {
          message.error(res.data.error);
        }
        this.setState({ loading: false });
      })
      .catch(err => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  render() {
    const columns = [
      { title: "Title", dataIndex: "title" },
      {
        title: "Enabled",
        render: (value, record, index) => (
          <span>
            <Checkbox
              checked={record.enabled}
              onChange={e => {
                let modules = this.state.modules;
                modules[index].enabled = e.target.checked;
                this.setState({ modules });
              }}
            />
          </span>
        )
      },
      {
        title: "Hidden",
        render: (value, record, index) => (
          <span>
            <Checkbox
              checked={record.hidden}
              onChange={e => {
                let modules = this.state.modules;
                modules[index].hidden = e.target.checked;
                this.setState({ modules });
              }}
            />
          </span>
        )
      }
    ];
    return (
      <Layout.Content style={{ padding: 30 }}>
        <Row>
          <Col sm={16}>
            <h1>Modules List</h1>
          </Col>
          <Col sm={8} style={{ textAlign: "right" }}>
            <Button
              icon="save"
              type="primary"
              onClick={this.handleModulesSave}
              loading={this.state.loading}
            >
              Save
            </Button>
          </Col>
        </Row>
        <Spin spinning={this.state.spinning}>
          <Table
            columns={columns}
            dataSource={this.state.modules}
            rowKey="link"
            pagination={false}
            childrenColumnName={null}
            expandedRowRender={this.expandedRow}
            rowClassName={(record, index) => {
              if (!record.children) {
                return "modules-list-no-children";
              }
              return "";
            }}
          />
        </Spin>
      </Layout.Content>
    );
  }
}

export default ModulesList;
