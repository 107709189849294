import { Button, Col, Layout, message, Row, Spin, Switch } from "antd";
import React, { Component } from "react";
import { api } from "../../../config";

class FeesSettings extends Component {
  state = {
    settings: {},
    enabled: false,
    online_fee_payment_enabled: false,
    fee_currency: undefined,
    payment_gateway_list: [],
    paytm_gateway_details: {},
    razorpay_gateway_details: {},
  };

  componentDidMount() {
    this.__get_online_fee_settings();
  }

  __get_online_fee_settings = () => {
    api
      .get("/settings/fees/")
      .then((res) => {
        if (res.data.success) {
          this.setState({ settings: res.data.data });
          this.__set_fee_settings_data(res.data.data);
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  __set_fee_settings_data = (data) => {
    if (data.online_fee_payment_enabled === undefined) {
      this.setState({ enabled: false });
      return;
    }
    this.setState({
      online_fee_payment_enabled: data.online_fee_payment_enabled,
    });
    this.setState({ fee_currency: data.fee_currency });
    this.setState({
      payment_gateway_list: data.payment_gateway_list,
    });
    this.setState({
      paytm_gateway_details: data.paytm_gateway_details,
    });
    this.setState({
      razorpay_gateway_details: data.razorpay_gateway_details,
    });
  };

  render() {
    const {
      enabled,
      online_fee_payment_enabled,
      fee_currency,
      payment_gateway_list,
      paytm_gateway_details,
      razorpay_gateway_details,
    } = this.state;
    return (
      <Layout.Content>
        <Row gutter={16}>
          <Col sm={16}>
            <h1>Fees Settings</h1>
          </Col>
          <Col sm={8}>
            <Button type="primary" icon="save">
              Save
            </Button>
          </Col>
        </Row>
        <p>
          (To enable payment gateway or make any changes, please contact our
          support team)
        </p>
        <Spin indicator={<span />} spinning={enabled === "1" ? true : false}>
          <Row gutter={16}>
            <Col sm={16}>
              <h4>Enable Online Fees</h4>
            </Col>
            <Col sm={8}>
              <Switch
                checked={online_fee_payment_enabled === "1" ? true : false}
                onChange={(val) => {
                  this.setState({
                    online_fee_payment_enabled: val ? "1" : "0",
                  });
                }}
                disabled
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col sm={16}>
              <h4>Fee Currency</h4>
            </Col>
            <Col sm={8}>
              <h3>{fee_currency}</h3>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col sm={24}>
              <h2>Payment Gateways List</h2>
            </Col>
            <Col sm={22} offset={2}>
              {payment_gateway_list.map((item, index) => {
                return (
                  <Row gutter={16} key={index}>
                    <Col sm={16}>
                      <h4>{item.name}</h4>
                    </Col>
                    <Col sm={8}>
                      <h4>{item.enabled ? "Enabled" : "Disabled"}</h4>
                    </Col>
                  </Row>
                );
              })}
            </Col>
          </Row>
          <Row gutter={16}>
            <Col sm={24}>
              <h2>PayTM Gateway Details</h2>
            </Col>
            <Col sm={8}>
              <h4>TDR Amount : {paytm_gateway_details.tdr_amount}</h4>
            </Col>
            <Col sm={8}>
              <h4>TDR Rate : {paytm_gateway_details.tdr_rate}%</h4>
            </Col>
            <Col sm={8}>
              <h4>TDR Tax Rate : {paytm_gateway_details.tdr_tax_rate}%</h4>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col sm={24}>
              <h2>Razorpay Gateway Details</h2>
            </Col>
            <Col sm={8}>
              <h4>TDR Amount : {razorpay_gateway_details.tdr_amount}</h4>
            </Col>
            <Col sm={8}>
              <h4>TDR Rate : {razorpay_gateway_details.tdr_rate}%</h4>
            </Col>
            <Col sm={8}>
              <h4>TDR Tax Rate : {razorpay_gateway_details.tdr_tax_rate}%</h4>
            </Col>
          </Row>
        </Spin>
      </Layout.Content>
    );
  }
}

export default FeesSettings;
