import React, { Component } from "react";
import {
  Layout,
  Row,
  Col,
  Input,
  message,
  Form,
  Select,
  Icon,
  Button,
  Table,
  Radio,
  InputNumber,
} from "antd";
import { api } from "../../../../config";
import { AcademicYearDropdown, AccountsDropdown } from "../../../../components";

const styles = {
  iconButton: {
    cursor: "pointer",
    fontSize: "24px",
    position: "relative",
    top: 4,
    left: 14,
  },
  payButton: {
    width: "100%",
    backgroundColor: "#882222",
    borderColor: "#882222",
  },
};

class PayFees extends Component {
  state = {
    fee_items: [],
    fee_particulars: [],
    total: null,
    student_id: null,
    items_count: 1,
    payment: {
      mode: "cash",
      account: undefined,
    },
    ac_year_id: null,
    fee_name: null,
    fee_settings: {},
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      this.setState({ student_id: this.props.match.params.id });
    }
    this.getFeesItem();
    this.getFeeSettings();
  }

  getFeesItem = () => {
    api
      .get("/fees/items/")
      .then((res) => {
        if (res.data && res.data.success) {
          this.setState({ fee_items: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handlePay = () => {
    this.setState({ paying: true });
    let formData = {};
    let payment = this.state.payment;
    let { ac_year_id, fee_name } = this.state;
    if (
      !(
        payment.mode &&
        payment.amount_paid >= 0 &&
        payment.amount_total &&
        ac_year_id
      )
    ) {
      if (!payment.mode) {
        message.error("Please select payment mode");
      } else if (!payment.amount_paid && payment.amount_paid < 0) {
        message.error("Please enter amount paying");
      } else if (!payment.amount_total) {
        message.error("Bill amount not calculated");
      } else if (!ac_year_id) {
        message.error("Please select academic year");
      } else {
        message.error("Enter Amount");
      }
      this.setState({ paying: false });
      return;
    }
    if (payment.amount_paid > payment.amount_total) {
      message.error("Amount Paying can not be greater than Total Amount");
      this.setState({ paying: false });
      return;
    }
    let dataSource = this.state.dataSource;
    // if (dataSource[dataSource.length - 1].name === "Total") dataSource.pop();
    formData.student_id = parseInt(this.props.match.params.id, 10);
    formData.ac_year_id = ac_year_id;
    formData.name = fee_name;
    formData.amount_total = payment.amount_total;
    formData.amount_paid = payment.amount_paid;
    formData.details = dataSource.slice(0, dataSource.length - 1);
    formData.installments = {
      amount: payment.amount_paid,
      mode: payment.mode,
      account: payment.account,
    };
    formData.discounts = payment.discounts;
    api
      .put("/fees/pay/", JSON.stringify(formData))
      .then((res) => {
        this.setState({ paying: false });
        if (res.data && res.data.success) {
          message.success("Fees Paid");
          let url = "/fees/receipt/" + res.data.data.payment_id;
          this.props.history.push(url);
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({ paying: false });
      });
  };

  handlePreviewSubmit = () => {
    this.props.form.validateFields((err, values) => {
      console.log(values);
      let dataSource = [];
      let total = 0.0;
      if (!err) {
        const fee_items = this.state.fee_items;
        if (values.items) {
          values.items.forEach((item) => {
            var matched_obj = fee_items.find((it) => {
              return item.id === it.id;
            });
            matched_obj = { ...matched_obj };
            total += item.amount * item.quantity;
            matched_obj.quantity = item.quantity;
            matched_obj.amount = item.amount * item.quantity;
            dataSource = dataSource.concat(matched_obj);
          });
        }
        dataSource.push({
          name: "Transportation",
          quantity: null,
          amount: parseInt(values.transportation, 10) || 0,
        });
        dataSource.push({
          name: "Miscellaneous",
          quantity: null,
          amount: parseInt(values.others, 10) || 0,
        });
        dataSource.push({
          name: "Discounts",
          quantity: null,
          amount: parseInt(values.discounts, 10) || 0,
        });
        total +=
          (parseInt(values.transportation, 10) || 0) +
          (parseInt(values.others, 10) || 0) -
          (parseInt(values.discounts, 10) || 0);
        dataSource.push({ name: "Total", quantity: null, amount: total });
        let payment = this.state.payment;
        payment.amount_total = total;
        payment.discounts = values.discounts;
        payment.amount_paid = total;
        this.setState({
          dataSource,
          payment,
          ac_year_id: values.ac_year_id,
          fee_name: values.name,
        });
      }
    });
  };

  handleFeeItemSelectionChange = (index, val) => {
    const { fee_items } = this.state;
    let fee_item = fee_items.find((item) => {
      return item.id === val;
    });
    let items = this.props.form.getFieldValue("items");
    items[index].amount = fee_item.amount;
    this.props.form.setFieldsValue({ items });
  };

  getFeeSettings = () => {
    api
      .get(`/fees/settings/`)
      .then((res) => {
        if (res.data.success) {
          this.setState({ fee_settings: res.data.data });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const { payment, fee_settings } = this.state;
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    const Option = Select.Option;
    const formItemLayout = {
      labelCol: { sm: { span: 8 } },
      wrapperCol: { sm: { span: 16 } },
    };
    let fee_fields = [];
    for (let i = 0; i < this.state.items_count; i++) {
      fee_fields.push(
        <FormItem key={i} wrapperCol={{ sm: 24 }}>
          {getFieldDecorator(`items[${i}].id`, {
            rules: [{ required: true, message: "This field is required!" }],
          })(
            <Select
              showSearch
              placeholder="Select Item"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              style={{ width: "60%", marginRight: 5 }}
              onChange={this.handleFeeItemSelectionChange.bind(this, i)}
            >
              {this.state.fee_items.map((item, index) => {
                return (
                  <Option value={item.id} key={index}>
                    {item.name + " - ₹" + item.amount}
                  </Option>
                );
              })}
            </Select>
          )}
          {getFieldDecorator(`items[${i}].amount`, {
            rules: [{ required: true, message: "Amount is required" }],
          })(
            <InputNumber
              style={{ width: "20%", marginRight: 5 }}
              placeholder="Amount"
            />
          )}
          {getFieldDecorator(`items[${i}].quantity`, {
            rules: [{ required: true, message: "Quantity is required" }],
            initialValue: 1,
          })(
            <Select style={{ width: "10%" }}>
              {[1, 2, 3, 4, 5].map((item, index) => {
                return (
                  <Option value={item} key={index}>
                    {item}
                  </Option>
                );
              })}
            </Select>
          )}
          {
            <Icon
              type="minus-circle-o"
              style={styles.iconButton}
              onClick={() => {
                let items_count = this.state.items_count;
                items_count--;
                this.setState({ items_count });
              }}
            />
          }
        </FormItem>
      );
    }
    const columns = [
      {
        title: "Item",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Quantity",
        dataIndex: "quantity",
        key: "quantity",
      },
      {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
      },
    ];
    return (
      <Layout.Content>
        <Row gutter={32}>
          <Col sm={12}>
            <h1 className="align-center">Fee Items</h1>
            <Form {...formItemLayout}>
              <FormItem label="Fee Name">
                {getFieldDecorator("name")(<Input placeholder="Fee Name" />)}
              </FormItem>
              <FormItem label="Academic Year">
                {getFieldDecorator("ac_year_id", {
                  rules: [
                    { required: true, message: "Academic Year is required" },
                  ],
                })(<AcademicYearDropdown />)}
              </FormItem>
              {fee_fields}
              <FormItem wrapperCol={{ sm: 24 }}>
                <Button
                  type="dashed"
                  style={{ width: "100%" }}
                  onClick={() => {
                    let items_count = this.state.items_count;
                    items_count++;
                    this.setState({ items_count });
                  }}
                >
                  <Icon type="plus" /> Add Item
                </Button>
              </FormItem>
              <FormItem label="Transportation">
                {getFieldDecorator(`transportation`, { initialValue: 0 })(
                  <Input type="number" placeholder="Transportation" />
                )}
              </FormItem>
              <FormItem label="Miscellaneous">
                {getFieldDecorator(`others`, { initialValue: 0 })(
                  <Input type="number" placeholder="Miscellaneous" />
                )}
              </FormItem>
              <FormItem label="Discount">
                {getFieldDecorator(`discounts`, { initialValue: 0 })(
                  <Input
                    type="number"
                    placeholder="Discounts"
                    disabled={!fee_settings.fee_discounts_enabled}
                  />
                )}
              </FormItem>
              <FormItem wrapperCol={{ sm: 24 }}>
                <Button
                  type="primary"
                  style={{ width: "100%" }}
                  onClick={this.handlePreviewSubmit}
                >
                  Preview
                </Button>
              </FormItem>
            </Form>
          </Col>
          <Col sm={12}>
            <h1 style={{ textAlign: "center" }}>Bill Preview</h1>
            <div className="bill-screen">
              <Table
                columns={columns}
                dataSource={this.state.dataSource}
                pagination={false}
              />
              <Row style={{ margin: "15px 0px" }}>
                <Col sm={6}>
                  <h3>Payment Mode: </h3>
                </Col>
                <Col sm={18}>
                  <Radio.Group
                    defaultValue="cash"
                    onChange={(e) => {
                      let payment = this.state.payment;
                      payment.mode = e.target.value;
                      this.setState({ payment });
                    }}
                  >
                    <Radio value="cash">Cash</Radio>
                    <Radio value="card">Card</Radio>
                    <Radio value="wallet">Wallet/UPI</Radio>
                    <Radio value="transfer">Transfer</Radio>
                  </Radio.Group>
                </Col>
              </Row>
              <Row style={{ margin: "15px 0px" }}>
                <Col sm={6}>
                  <h3>Credit to account: </h3>
                </Col>
                <Col sm={18}>
                  <AccountsDropdown
                    style={{ width: "100%" }}
                    value={payment.account}
                    onChange={(val) => {
                      payment.account = val;
                      this.setState({ payment });
                    }}
                  />
                </Col>
              </Row>
              <Row style={{ margin: "15px 0px" }}>
                <Col sm={6}>
                  <h3>Amount Paying: </h3>
                </Col>
                <Col sm={18}>
                  <Input
                    placeholder="Amount Paying"
                    value={payment.amount_paid}
                    onChange={(e) => {
                      let payment = this.state.payment;
                      payment.amount_paid = parseInt(e.target.value, 10);
                      this.setState({ payment });
                    }}
                    disabled={!fee_settings.fee_partial_payment_enabled}
                  />
                </Col>
              </Row>
              <Button
                type="primary"
                style={styles.payButton}
                onClick={this.handlePay}
                loading={this.state.paying}
              >
                Pay
              </Button>
            </div>
          </Col>
        </Row>
      </Layout.Content>
    );
  }
}

export default Form.create()(PayFees);
