import React from "react";
import { Switch, Route } from "react-router-dom";
import FilesList from "./FilesList";

const AdminFilesRouter = (props) => (
  <Switch>
    <Route exact path="/files/" component={FilesList} />
    <Route exact path="/files/:folder" component={FilesList} />
  </Switch>
);

export { AdminFilesRouter };
