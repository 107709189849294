import React, { Component } from "react";
import {
  Layout,
  Row,
  Col,
  Button,
  Table,
  message,
  Icon,
  Popconfirm
} from "antd";
import { Link } from "react-router-dom";
import {
  ClassSectionDropdown,
  AcademicYearDropdown,
  SubjectSelectionDropdown
} from "../../../components";
import { api } from "../../../config";

class QuestionPapers extends Component {
  state = {
    class_id: null,
    ac_year_id: null,
    subject_id: null,
    question_papers: []
  };

  get_question_papers_list = () => {
    const { class_id, ac_year_id, subject_id } = this.state;
    if (!(class_id && ac_year_id && subject_id)) {
      message.error("Please select class, ac year and subject");
      return;
    }
    let url =
      "/question-papers-basic/" +
      class_id +
      "/" +
      ac_year_id +
      "/" +
      subject_id +
      "/";
    api
      .get(url)
      .then(res => {
        if (res.data && res.data.success) {
          this.setState({ question_papers: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  delete_question_paper = id => {
    api
      .delete("/question-paper-basic/" + id + "/")
      .then(res => {
        if (res.data.success) {
          message.success("Question Paper Deleted");
          this.get_question_papers_list();
        } else {
          message.error(res.data.error);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  render() {
    const columns = [
      { title: "ID", dataIndex: "id" },
      { title: "Name", dataIndex: "name" },
      { title: "Created", dataIndex: "created" },
      { title: "Updated", dataIndex: "updated" },
      {
        title: "Actions",
        render: record => (
          <span>
            <Icon
              type="edit"
              className="action-icon"
              onClick={() => {
                this.props.history.push("/question-paper/" + record.id + "/");
              }}
            />
            <Popconfirm
              title="Are you sure?"
              onConfirm={this.delete_question_paper.bind(this, record.id)}
            >
              <Icon type="delete" className="action-icon absent" />
            </Popconfirm>
          </span>
        )
      }
    ];
    return (
      <Layout.Content>
        <h1>Question Paper Generator</h1>
        <Row style={{ marginBottom: 20 }}>
          <Col sm={6}>
            <span>Class : </span>
            <ClassSectionDropdown
              value={this.state.class_id}
              onChange={class_id => {
                this.setState({ class_id });
              }}
            />
          </Col>
          <Col sm={4}>
            <span>AC Year : </span>
            <AcademicYearDropdown
              value={this.state.ac_year_id}
              onChange={ac_year_id => {
                this.setState({ ac_year_id });
              }}
            />
          </Col>
          <Col sm={4}>
            <span>Subject : </span>
            <SubjectSelectionDropdown
              value={this.state.subject_id}
              onChange={subject_id => {
                this.setState({ subject_id });
              }}
              class={this.state.class_id}
            />
          </Col>
          <Col sm={2}>
            <Button onClick={this.get_question_papers_list}>Go</Button>
          </Col>
          <Col sm={8} style={{ textAlign: "right" }}>
            <Link to="/question-paper/new">
              <Button type="primary" icon="plus">
                New Question Paper
              </Button>
            </Link>
          </Col>
        </Row>
        <Table
          columns={columns}
          dataSource={this.state.question_papers}
          rowKey="id"
        />
      </Layout.Content>
    );
  }
}

export default QuestionPapers;
