import React, { Component } from "react";
import {
  Layout,
  Table,
  Icon,
  message,
  Row,
  Col,
  Button,
  Popconfirm
} from "antd";
import { api } from "../../../../config";

class RoutesList extends Component {
  state = {
    routes_list: [],
    spinning: false
  };

  componentDidMount() {
    this.get_routes_list();
  }

  get_routes_list = () => {
    api
      .get("/simple/transportation/routes/")
      .then(res => {
        if (res.data.success) {
          this.setState({ routes_list: res.data.data });
          this.props.history.push("/simple-transportation/routes/");
        } else {
          message.error(res.data.error);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  handleDelete = id => {
    api
      .delete("/simple/transportation/route/" + id + "/")
      .then(res => {
        if (res.data.success) {
          message.success("Route deleted");
          this.get_routes_list();
        } else {
          message.error(res.data.error);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  render() {
    const columns = [
      { title: "ID", dataIndex: "id" },
      { title: "Route Name", dataIndex: "route_name" },
      { title: "Source", dataIndex: "source" },
      { title: "Destination", dataIndex: "destination" },
      {
        title: "Actions",
        render: record => (
          <span>
            <Icon
              type="edit"
              className="action-icon"
              onClick={() => {
                this.props.history.push(
                  "/simple-transportation/route/" + record.id
                );
              }}
            />
            <Icon
              type="ordered-list"
              className="action-icon"
              onClick={() => {
                this.props.history.push(
                  "/simple-transportation/students/" + record.id
                );
              }}
            />
            <Popconfirm
              title="Are you sure?"
              onConfirm={this.handleDelete.bind(this, record.id)}
            >
              <Icon type="delete" className="action-icon absent" />
            </Popconfirm>
          </span>
        )
      }
    ];
    return (
      <Layout.Content>
        <Row>
          <Col sm={12}>
            <h1>Routes List</h1>
          </Col>
          <Col sm={12} style={{ textAlign: "right" }}>
            <Button
              type="primary"
              onClick={() => {
                this.props.history.push("/simple-transportation/routes/new");
              }}
              icon="plus"
            >
              New Route
            </Button>
          </Col>
        </Row>
        <Table
          columns={columns}
          dataSource={this.state.routes_list}
          rowKey="id"
          loading={this.state.spinning}
        />
      </Layout.Content>
    );
  }
}

export default RoutesList;
