import React, { Component } from "react";
import {
  Layout,
  Card,
  Descriptions,
  message,
  Input,
  Upload,
  Button,
  Modal,
} from "antd";
import { api, baseurl, getToken } from "../../../config";
import moment from "moment";

class StudentHomeworkViewer extends Component {
  state = {
    id: null,
    student_id: null,
    homework: {},
    solution_files: [],
    description: "",
    loading: false,
  };

  componentDidMount() {
    if (!this.props.match.params.id) {
      message.error("Invalid URL");
      return;
    }
    if (this.props.location.state.student_id) {
      this.setState(
        {
          student_id: this.props.location.state.student_id,
          id: this.props.match.params.id,
        },
        this.get_homework
      );
    }
  }

  get_homework = () => {
    let { student_id, id } = this.state;
    api
      .get("/homework/student/" + student_id + "/" + id + "/")
      .then((res) => {
        if (res.data.success) {
          this.setState({ homework: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleUpload = (info) => {
    if (info.file.status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === "done") {
      let { solution_files } = this.state;
      solution_files.push({
        uid: new Date().getTime(),
        path: info.file.response.path,
        name: info.file.name,
        size: info.file.size,
        type: info.file.type,
        status: "done",
      });
      this.setState({
        solution_files: solution_files,
      });
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  handleSubmit = () => {
    let { homework, description } = this.state;
    if (
      moment.utc(homework.submission_date).local().endOf("day").format("X") <
      moment.utc().local().format("X")
    ) {
      Modal.error({
        title: "Error",
        content: "Last date for submission is over",
      });
      return;
    }
    if (!description) {
      message.error("Solution text is empty!");
      return;
    }
    if (homework.solutions.length > 0) {
      Modal.confirm({
        title: "Are you sure?",
        content: `You have already submitted ${homework.solutions.length} time(s)`,
        okText: "Yes",
        onOk: () => {
          this.submit_new_solution();
        },
        onCancel: () => {},
      });
    } else {
      this.submit_new_solution();
    }
  };

  submit_new_solution = () => {
    let { description, solution_files, student_id, id } = this.state;
    this.setState({ loading: true });
    let data = {
      student_id: student_id,
      homework_id: id,
      description: description,
    };
    data.file = solution_files;
    api
      .put("/homework/solution/", JSON.stringify(data))
      .then((res) => {
        if (res.data.success) {
          message.success("Solution Submitted");
          this.get_homework();
          this.setState({ solution_files: [], description: "" });
        } else {
          message.error(res.data.error);
        }
        this.setState({ loading: false });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  render() {
    const { Item } = Descriptions;
    const { homework, loading, description } = this.state;
    const files = homework.files ? JSON.parse(homework.files) : [];
    return (
      <Layout.Content>
        <Card title="Homework Viewer">
          <Descriptions bordered={true}>
            <Item label="Name" span={3}>
              {homework.title}
            </Item>
            <Item label="Description" span={3}>
              {homework.description}
            </Item>
            <Item label="Subject">{homework.subject}</Item>
            <Item label="Timestamp">
              {moment
                .utc(homework.timestamp)
                .local()
                .format("YYYY-MM-DD HH:mm A")}
            </Item>
            <Item label="Submission Date">
              <span>
                {moment
                  .utc(homework.submission_date)
                  .local()
                  .format("YYYY-MM-DD")}
              </span>
            </Item>
            <Item label="Files/Attachements" span={3}>
              <ul>
                {files.length > 0 &&
                  files.map((item, index) => {
                    return (
                      <li key="index">
                        <a href={item.url}>{item.name}</a>
                      </li>
                    );
                  })}
              </ul>
            </Item>
            <Item label="Solutions" span={3}>
              {homework.solutions && homework.solutions.length > 0 && (
                <div>
                  <h3>Previously Submitted Solutions</h3>
                  <ul>
                    {homework.solutions.map((item, index) => {
                      let file = JSON.parse(item.file);
                      let attachments = JSON.parse(item.attachment);
                      return (
                        <li key={index}>
                          <div>
                            <p>
                              <strong>Answer : </strong>
                              {item.description}
                            </p>
                            {file.length ? (
                              file.map((item, ind) => {
                                let file_link =
                                  item.url || baseurl + "/" + item.path;
                                return (
                                  <p key={ind}>
                                    <a href={file_link}>{item.name}</a>
                                  </p>
                                );
                              })
                            ) : (
                              <p>
                                <a href={item.url || baseurl + "/" + file.path}>
                                  {file.name}
                                </a>
                              </p>
                            )}
                          </div>
                          <div>
                            <p>
                              <strong>Remarks</strong> : {item.remarks}
                            </p>
                            {attachments &&
                              attachments.map((item, index) => {
                                return (
                                  <p key={index}>
                                    <a
                                      href={
                                        item.url || baseurl + "/" + item.path
                                      }
                                    >
                                      {item.name}
                                    </a>
                                  </p>
                                );
                              })}
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}

              <div>
                <h3>Submit Solution</h3>
                <Input.TextArea
                  placeholder="Assignment Solution"
                  style={{ marginBottom: 20 }}
                  value={description}
                  onChange={(e) => {
                    this.setState({ description: e.target.value });
                  }}
                />
                <div style={{ display: "flex" }}>
                  <div style={{ flex: 1 }}>
                    <Upload
                      name="file"
                      action={baseurl + "/upload/?file_type=uploads"}
                      headers={{ Authorization: getToken() }}
                      accept=".jpg,.jpeg,.png,.pdf,.doc,.docx,.ppt,.pptx,.odt,.odp,.txt,.rtf,.mp4,.mp3"
                      onChange={this.handleUpload}
                      multiple={true}
                    >
                      <Button icon="upload">Attach File</Button>
                    </Upload>
                  </div>
                  <div style={{ textAlign: "right" }}>
                    <Button
                      type="primary"
                      onClick={this.handleSubmit}
                      loading={loading}
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </div>
            </Item>
          </Descriptions>
        </Card>
      </Layout.Content>
    );
  }
}

export default StudentHomeworkViewer;
