import { Button, Col, Form, Input, Layout, message, Row } from "antd";
import React, { Component } from "react";
import { ClassSectionDropdown } from "../../../components";
import { api } from "../../../config";

class VisitorForm extends Component {
  state = {
    loading: false,
    cameraOpen: false,
    picture: undefined,
    video: undefined,
  };

  handleSubmit = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        val.photo = this.state.picture;
        this.setState({ loading: true });
        api
          .put("/visitors/", JSON.stringify(val))
          .then((res) => {
            if (res.data && res.data.success) {
              message.success("Visitor Added");
              this.props.history.goBack();
            } else {
              message.error(res.data.error);
            }
            this.setState({ loading: false });
          })
          .catch((err) => {
            console.log(err);
            this.setState({ loading: false });
          });
      }
    });
  };

  openCamera = async () => {
    if (
      !("mediaDevices" in navigator && "getUserMedia" in navigator.mediaDevices)
    ) {
      message.error("Camera not supported!");
    }
    let stream = await navigator.mediaDevices.getUserMedia({
      video: true,
      audio: false,
    });
    document.getElementById("video").srcObject = stream;
    this.setState({ video: stream });
  };

  takePicture = () => {
    let canvas = document.getElementById("canvas");
    canvas
      .getContext("2d")
      .drawImage(document.getElementById("video"), 0, 0, 320, 240);
    let image_data_url = canvas.toDataURL("image/jpeg");
    this.setState({ picture: image_data_url });
  };

  componentWillUnmount() {
    let { video } = this.state;
    if (video) {
      video.getTracks().forEach((track) => {
        track.stop();
      });
    }
  }

  closeCamera = () => {
    let { video } = this.state;
    if (video) {
      video.getTracks().forEach((track) => {
        track.stop();
      });
    }
  };

  render() {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    const { picture, video } = this.state;
    return (
      <Layout.Content>
        <h1 className="align-center">Visitor Form</h1>
        <Row gutter={16}>
          <Col md={12}>
            <Form labelCol={{ sm: 8 }} wrapperCol={{ sm: 16 }}>
              <FormItem label="Visitor Name">
                {getFieldDecorator("name", {
                  rules: [
                    { required: true, message: "Visitor name is required!" },
                  ],
                })(<Input />)}
              </FormItem>
              <FormItem label="Mobile">
                {getFieldDecorator("mobile", {
                  rules: [{ required: true, message: "Mobile is required!" }],
                })(<Input />)}
              </FormItem>
              <FormItem label="Student name">
                {getFieldDecorator("student_name")(<Input />)}
              </FormItem>
              <FormItem label="Class">
                {getFieldDecorator("class")(<ClassSectionDropdown />)}
              </FormItem>
              <FormItem label="Address">
                {getFieldDecorator("address", {
                  rules: [{ required: true, message: "Address is requried!" }],
                })(<Input.TextArea />)}
              </FormItem>
              <FormItem label="Person Visiting">
                {getFieldDecorator("person_visiting")(<Input />)}
              </FormItem>
              <FormItem label="Reason Visiting">
                {getFieldDecorator("reason_visiting", {
                  rules: [{ required: true, message: "Reason is required!" }],
                })(<Input />)}
              </FormItem>
              <FormItem label="Entry Gate">
                {getFieldDecorator("entry_gate", {
                  rules: [
                    { required: true, message: "Entry Gate is required!" },
                  ],
                })(<Input />)}
              </FormItem>
              <FormItem wrapperCol={{ offset: 8 }}>
                <Button
                  onClick={this.handleSubmit}
                  type="primary"
                  loading={this.state.loading}
                >
                  Save
                </Button>
              </FormItem>
            </Form>
          </Col>
          <Col md={12}>
            <div
              style={{
                padding: "10px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <video
                id="video"
                width={320}
                height={240}
                style={{
                  background: "#111",
                  margin: "auto",
                  display: !picture ? "block" : "none",
                }}
                autoPlay
              ></video>
              <canvas
                id="canvas"
                width={320}
                height={240}
                style={{
                  background: "#111",
                  margin: "auto",
                  display: picture ? "block" : "none",
                }}
              ></canvas>
              <div style={{ padding: 16 }}>
                {video ? (
                  <Button onClick={this.closeCamera} style={{ marginRight: 8 }}>
                    Close Camera
                  </Button>
                ) : (
                  <Button onClick={this.openCamera} style={{ marginRight: 8 }}>
                    Open Camera
                  </Button>
                )}
                {video && (
                  <Button
                    onClick={() => {
                      if (picture) {
                        this.setState({ picture: undefined });
                      } else {
                        this.takePicture();
                      }
                    }}
                  >
                    {picture ? "Try Again" : "Take Picture"}
                  </Button>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Layout.Content>
    );
  }
}

export default Form.create()(VisitorForm);
