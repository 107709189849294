import React, { Component } from "react";
import { Layout, Form, Input, Button, message, Spin } from "antd";
import { api } from "../../../../config";
import { ShowBreadcrumbs } from "../../../../components";

class VendorsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      spinning: false,
      vendor: {},
      id: null
    };
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.setState({ id: this.props.match.params.id });
      this.get_vendor(this.props.match.params.id);
    }
  }

  get_vendor = id => {
    this.setState({ spinning: true });
    api
      .get("/finance/vendor/" + id + "/")
      .then(res => {
        if (res.data.success) {
          this.props.form.setFieldsValue(res.data.data);
        } else {
          message.error(res.data.error);
        }
        this.setState({ spinning: false });
      })
      .catch(err => {
        console.log(err);
        this.setState({ spinning: false });
      });
  };

  add_new_vendor = data => {
    this.setState({ loading: true });
    api
      .put("/finance/vendors/", JSON.stringify(data))
      .then(res => {
        if (res.data && res.data.success) {
          message.success("Vendor added");
          this.props.history.push("/finance/vendors/");
        } else {
          message.error(res.data.error);
        }
        this.setState({ loading: false });
      })
      .catch(err => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  update_vendor = (id, data) => {
    this.setState({ loading: true });
    api
      .post("/finance/vendor/" + id + "/", JSON.stringify(data))
      .then(res => {
        if (res.data.success) {
          message.success("Vendor details updated");
        } else {
          message.error(res.data.error);
        }
        this.setState({ loading: false });
      })
      .catch(err => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  handleSubmit = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        if (this.state.id) {
          this.update_vendor(this.state.id, val);
        } else {
          this.add_new_vendor(val);
        }
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const FormItem = Form.Item;
    const { id } = this.state;
    return (
      <Layout.Content>
        <ShowBreadcrumbs
          items={[
            { name: "Home", link: "/" },
            { name: "Finance", link: "/finance/" },
            { name: "Vendors", link: "/finance/vendors/" },
            {
              name: (id ? "Edit " : "New ") + "Vendor",
              link: "/finance/vendor/" + (id ? id : "new")
            }
          ]}
        />
        <h1 className="align-center">Vendors Form</h1>
        <Spin spinning={this.state.spinning}>
          <Form labelCol={{ sm: 8 }} wrapperCol={{ sm: 8 }}>
            <FormItem label="Vendor Name">
              {getFieldDecorator("name", {
                rules: [{ required: true, message: "Vendor name is required!" }]
              })(<Input placeholder="Vendor Name" />)}
            </FormItem>
            <FormItem label="Phone Number">
              {getFieldDecorator("phone")(<Input placeholder="Phone Number" />)}
            </FormItem>
            <FormItem label="Email">
              {getFieldDecorator("email")(<Input placeholder="Email" />)}
            </FormItem>
            <FormItem label="GSTIN">
              {getFieldDecorator("gstin")(<Input placeholder="GSTIN" />)}
            </FormItem>
            <FormItem label="Address">
              {getFieldDecorator("address")(
                <Input.TextArea rows={4} placeholder="Address" />
              )}
            </FormItem>
            <FormItem label="Website">
              {getFieldDecorator("website")(<Input placeholder="Website" />)}
            </FormItem>
            <FormItem label="Notes">
              {getFieldDecorator("notes")(
                <Input.TextArea rows={4} placeholder="Notes" />
              )}
            </FormItem>
            <FormItem wrapperCol={{ offset: 8 }}>
              <Button loading={this.state.loading} onClick={this.handleSubmit}>
                Save
              </Button>
            </FormItem>
          </Form>
        </Spin>
      </Layout.Content>
    );
  }
}

export default Form.create()(VendorsForm);
