import React, { Component } from "react";
import { Layout, Form, Input, DatePicker, Button, message } from "antd";
import {
  ClassSectionDropdown,
  AcademicYearDropdown,
} from "../../../components";
import moment from "moment";
import { api } from "../../../config";

class AddNewStudent extends Component {
  state = {
    loading: false,
    student_id: null,
  };

  componentDidMount() {
    this.getAdmissionNo();
  }

  handleSubmit = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        this.setState({ loading: true });
        api
          .put("/students/", JSON.stringify(val))
          .then((res) => {
            if (res.data.success) {
              let student_id = res.data.data.student_id;
              this.props.history.push("/students/details/" + student_id);
            } else {
              message.error(res.data.error);
            }
            this.setState({ loading: false });
          })
          .catch((err) => {
            console.log(err);
            this.setState({ loading: false });
          });
      }
    });
  };

  getAdmissionNo = () => {
    api
      .get("/next/admission_no/")
      .then((res) => {
        if (res.data && res.data.success) {
          this.props.form.setFieldsValue(res.data.data);
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  showAdmissionNoError = (val, msg) => {
    this.props.form.setFields({
      admission_no: { value: val, errors: [new Error(msg)] },
    });
  };

  handleAdmissionNoChange = (e) => {
    let val = e.target.value;
    if (!val) {
      return;
    }
    if (val < 1) {
      this.showAdmissionNoError(val, "Invalid Admission Number");
      return;
    }
    api
      .get("/validate/admn_no/" + val + "/")
      .then((res) => {
        if (res.data && res.data.success) {
          return;
        } else {
          this.showAdmissionNoError(val, "Admission number already used");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getRollNo = (class_id) => {
    api
      .get("/next/roll_no/" + class_id + "/")
      .then((res) => {
        if (res.data.success) {
          this.props.form.setFieldsValue({ roll_no: res.data.data.roll_no });
        }
      })
      .catch((err) => {});
  };

  render() {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    return (
      <Layout.Content>
        <h1 className="align-center">Add New Student</h1>
        <Form labelCol={{ sm: 8 }} wrapperCol={{ sm: 8 }}>
          <FormItem label="Academic Year">
            {getFieldDecorator("ac_year_id", {
              rules: [
                { required: true, message: "Please select academic year!" },
              ],
            })(<AcademicYearDropdown />)}
          </FormItem>
          <FormItem label="Student Name">
            {getFieldDecorator("fullname", {
              rules: [{ required: true, message: "Please enter student name" }],
            })(<Input placeholder="Student Name" />)}
          </FormItem>
          <FormItem label="Mobile Number">
            {getFieldDecorator("mobile", {
              rules: [
                { required: true, message: "Please enter mobile" },
                {
                  pattern: "^[6-9][0-9]{9}",
                  message: "Enter valid mobile number",
                },
              ],
              validateTrigger: ["onBlur"],
            })(<Input placeholder="Mobile Number" type="tel" />)}
          </FormItem>
          <FormItem label="Date of Birth">
            {getFieldDecorator("dob", {
              rules: [{ required: true, message: "Please select DoB" }],
            })(<DatePicker placeholder="DoB" />)}
          </FormItem>
          <FormItem label="Admission Number">
            {getFieldDecorator("admission_no", {
              rules: [
                { required: true, message: "Please enter admission number" },
              ],
            })(
              <Input
                onChange={this.handleAdmissionNoChange}
                placeholder="Admission Number"
              />
            )}
          </FormItem>
          <FormItem label="Admission Date">
            {getFieldDecorator("admission_date", {
              rules: [
                { required: true, message: "Please select admission date" },
              ],
              initialValue: moment(),
            })(<DatePicker placeholder="Admission Date" />)}
          </FormItem>
          <FormItem label="Class">
            {getFieldDecorator("class_id", {
              rules: [
                {
                  required: true,
                  message: "Please select class and section",
                },
              ],
            })(<ClassSectionDropdown onChange={this.getRollNo} />)}
          </FormItem>
          <FormItem label="Roll Number">
            {getFieldDecorator("roll_no")(<Input placeholder="Roll Number" />)}
          </FormItem>
          <FormItem label="Father's Name">
            {getFieldDecorator("father_name")(<Input />)}
          </FormItem>
          <FormItem label="SATS/USN">
            {getFieldDecorator("usn")(<Input />)}
          </FormItem>
          <FormItem label="Student Aadhar No.">
            {getFieldDecorator("aadhar_no")(<Input />)}
          </FormItem>
          <FormItem wrapperCol={{ sm: 16, offset: 8 }}>
            <Button
              type="primary"
              onClick={this.handleSubmit}
              loading={this.state.loading}
            >
              Add Student
            </Button>
          </FormItem>
        </Form>
      </Layout.Content>
    );
  }
}

export default Form.create()(AddNewStudent);
