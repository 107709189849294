import { Form, InputNumber, message, Modal } from "antd";
import React, { Component } from "react";
import { api } from "../../../../config";

class UpdateFeeDiscount extends Component {
  state = {
    id: undefined,
    fee: {},
    discount: undefined,
    loading: false,
  };

  componentDidMount() {
    let { payment } = this.props.fee;
    this.setState({
      id: payment.id,
      discount: payment.discount_amount,
      fee: this.props.fee,
    });
  }

  __handleSubmit = () => {
    let { discount, id } = this.state;
    if (!discount) {
      message.error("Discount is required!");
      return;
    }
    let data = {
      fee_payment_id: id,
      discount_amount: discount,
    };
    this.setState({ loading: true });
    api
      .patch("/fees/pay/", JSON.stringify(data))
      .then((res) => {
        if (res.data.success) {
          message.success("Fee Discount Updated");
          this.props.close();
        } else {
          message.error("Failed to update discount amount!");
        }
        this.setState({ loading: false });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  render() {
    const { discount, loading } = this.state;
    const FormItem = Form.Item;
    return (
      <Modal
        title="Update Discount Amount"
        visible={true}
        onCancel={this.props.close.bind(this)}
        onOk={this.__handleSubmit}
        confirmLoading={loading}
      >
        <FormItem label="Discount Amount">
          <InputNumber
            placeholder="Discount Amount"
            style={{ width: "100%" }}
            value={discount}
            onChange={(val) => this.setState({ discount: val })}
          />
        </FormItem>
      </Modal>
    );
  }
}

export default UpdateFeeDiscount;
