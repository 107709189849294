import React, { Component } from "react";
import {
  Layout,
  Row,
  Col,
  Button,
  Table,
  message,
  Modal,
  Icon,
  Spin,
} from "antd";
import { connect } from "react-redux";
import moment from "moment";
import { api } from "../../../config";

class AnnouncementsList extends Component {
  state = {
    announcements: [],
    show_modal: false,
    announcement: {},
    spinning: true,
  };

  componentDidMount() {
    this.getAnnouncements();
  }

  closeModal = () => {
    this.setState({ show_modal: false });
  };

  getAnnouncements = () => {
    this.setState({ spinning: true });
    api
      .get("/announcements/")
      .then((res) => {
        if (res.data && res.data.success) {
          this.setState({ announcements: res.data.data });
        } else {
          message.error(res.data.error);
        }
        this.setState({ spinning: false });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ spinning: false });
      });
  };

  render() {
    const columns = [
      {
        title: "Title",
        dataIndex: "title",
      },
      {
        title: "Classes",
        render: (record) => (
          <span>
            {record.to_role === 0 || record.to_role === 1
              ? "All Classes"
              : record.class_id
              ? record.class + record.section
              : " - "}
          </span>
        ),
      },
      {
        title: "To",
        render: (record) => (
          <span>
            {record.to_role === 0
              ? "Everyone"
              : record.to_role === 1
              ? "All Students"
              : record.to_role === 2
              ? "Teachers"
              : record.to_role === 5
              ? "Selected Classes"
              : " "}
          </span>
        ),
      },
      {
        title: "Date",
        render: (record) => (
          <span>{moment(record.timestamp).format("DD MMM, YYYY")}</span>
        ),
      },
      {
        title: "Actions",
        render: (record) => (
          <span>
            <Icon
              type="read"
              className="action-icon"
              onClick={() => {
                this.setState({ announcement: record, show_modal: true });
              }}
            />
          </span>
        ),
      },
    ];
    const { show_modal, announcement } = this.state;
    return (
      <Layout.Content>
        <Row>
          <Col sm={18}>
            <h1>Announcements</h1>
          </Col>
          <Col sm={6} className="action-buttons">
            <Button
              icon="edit"
              style={{ marginRight: 10 }}
              onClick={() => this.props.history.push("/announcements/web/")}
            >
              Web Announcements
            </Button>
            <Button
              type="primary"
              icon="plus"
              onClick={() => {
                const role = this.props.user.role;
                if (role === 2) {
                  this.props.history.push("/announcements/new");
                } else if (role === 3 || role === 4) {
                  this.props.history.push("/announcements/new");
                }
              }}
            >
              New Announcement
            </Button>
          </Col>
        </Row>
        <Spin spinning={this.state.spinning}>
          <Table
            columns={columns}
            dataSource={this.state.announcements}
            rowKey="id"
          />
        </Spin>
        {show_modal && (
          <Modal
            visible={show_modal}
            title="Announcement"
            onCancel={this.closeModal}
            onOk={this.closeModal}
          >
            <h3>{announcement.title}</h3>
            <p>{announcement.content}</p>
          </Modal>
        )}
      </Layout.Content>
    );
  }
}

function mapStatesToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStatesToProps, null)(AnnouncementsList);
