import React, { Component } from "react";
import {
  Modal,
  Form,
  InputNumber,
  DatePicker,
  Input,
  Spin,
  message
} from "antd";
import moment from "moment";
import { api } from "../../../config";

class ReturnBook extends Component {
  state = {
    spinning: false,
    loading: false,
    issue_details: {}
  };

  componentDidMount() {
    if (this.props.book) {
      this.get_book_issue_state(this.props.book.id);
    }
  }

  get_book_issue_state = book_id => {
    api
      .get("/library/book/issue/details/" + book_id + "/")
      .then(res => {
        if (res.data.success) {
          let data = res.data.data;
          if (data.status === 1) {
            this.setState({ issue_details: data });
          } else {
            message.error("This book is not currently issued");
            this.props.closeModal();
          }
        } else {
          message.error(res.data.error);
          this.props.closeModal();
        }
      })
      .catch(err => {
        console.log(err);
        this.props.closeModal();
      });
  };

  update_book_return = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        this.setState({ loading: true });
        api
          .post("/library/book/return/" + val.id + "/", JSON.stringify(val))
          .then(res => {
            if (res.data.success) {
              message.success("Book Returned");
              this.props.closeModal();
            } else {
              message.error(res.data.error);
            }
            this.setState({ loading: false });
          })
          .catch(err => {
            console.log(err);
            this.setState({ loading: false });
          });
      }
    });
  };

  render() {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    const book = this.props.book;
    const details = this.state.issue_details;
    let late = (moment() - moment(details.last_date)) / (1000 * 60 * 60 * 24);
    late = late.toFixed(0);
    return (
      <Modal
        visible={this.props.visible}
        title="Return Book"
        onCancel={this.props.closeModal}
        confirmLoading={this.state.loading}
        onOk={this.update_book_return}
        okText="Return"
      >
        <p>Code : {book.code}</p>
        <p>Title : {book.title}</p>
        <p>Author : {book.author}</p>
        <p>Publisher : {book.publisher}</p>
        <Spin spinning={this.state.spinning}>
          <div>
            <p>
              Issued To:{" "}
              {details.student_id ? details.student : details.teacher}
            </p>
            <p>Issue Date : {details.issue_date}</p>
            <p>Last Date : {details.last_date}</p>
            {late > 0 && <p>Late By : {late} days</p>}
          </div>
          <Form>
            <FormItem>
              {getFieldDecorator("id", { initialValue: details.id })(
                <Input hidden />
              )}
            </FormItem>
            <FormItem>
              {getFieldDecorator("book_id", { initialValue: book.id })(
                <Input hidden />
              )}
            </FormItem>
            <FormItem label="Fine Amount">
              {getFieldDecorator("fine_amount", {
                initialValue: late > 0 ? late : 0
              })(<InputNumber />)}
            </FormItem>
            <FormItem label="Book Returning Date">
              {getFieldDecorator("return_date", {
                initialValue: moment()
              })(<DatePicker />)}
            </FormItem>
          </Form>
        </Spin>
      </Modal>
    );
  }
}

export default Form.create()(ReturnBook);
