import React, { Component } from "react";
import { Layout, Row, Col, Button, message, Table, Icon } from "antd";
import { api } from "../../../../config";

export default class EmployeeLeaves extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: undefined,
      leaves: [],
    };
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.setState(
        { id: this.props.match.params.id },
        this.get_employee_leaves
      );
    }
  }

  get_employee_leaves = () => {
    let { id } = this.state;
    api
      .get(`/hr/emp/${id}/leaves/`)
      .then((res) => {
        if (res.data.success) {
          this.setState({ leaves: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const { id, leaves } = this.state;
    const columns = [
      { title: "ID", dataIndex: "id" },
      { title: "Leave Type", dataIndex: "leave_type" },
      { title: "Count", dataIndex: "value" },
      {
        title: "Actions",
        render: (r) => (
          <span>
            <Icon type="ordered-list" className="action-icon" />
          </span>
        ),
      },
    ];
    return (
      <Layout.Content>
        <Row className="ribbon-row">
          <Col sm={12}>
            <h1>Employee Leaves</h1>
          </Col>
          <Col sm={12} className="action-buttons">
            <Button
              type="primary"
              onClick={() => {
                this.props.history.push("/hr/leaves/type");
              }}
              style={{ marginRight: 10 }}
            >
              Leave Types
            </Button>
            <Button
              type="primary"
              icon="plus"
              onClick={() => {
                this.props.history.push(`/hr/employee/${id}/leave/assign`);
              }}
            >
              New Leave Category
            </Button>
          </Col>
        </Row>
        <Table columns={columns} dataSource={leaves} rowKey="id" />
      </Layout.Content>
    );
  }
}
