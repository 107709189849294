import React, { Component } from "react";
import { Layout, Menu, Icon, Result, Card } from "antd";
import { UserHeaderMenu, PageFooter } from "./HeaderFooter";
import { StudentRouter } from "../../config/Router";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

const width = window.innerWidth;

const { Sider } = Layout;

class StudentLayout extends Component {
  state = {
    collapsed: false,
    selectedKeys: [],
  };

  onCollapse = (collapsed) => {
    this.setState({ collapsed });
  };

  onSelect = ({ item, key, keyPath, selectedKeys, e }) => {
    if (width < 480) {
      this.setState({ collapsed: true, selectedKeys });
    } else {
      this.setState({ selectedKeys });
    }
  };

  render() {
    const { students, user } = this.props;
    if (students && students.length > 0) {
      return (
        <Layout>
          <UserHeaderMenu />
          <Layout style={{ minHeight: "100vh" }}>
            <StudentSiderMenu
              collapsed={this.state.collapsed}
              onCollapse={this.onCollapse}
              onSelect={this.onSelect}
              selectedKeys={this.state.selectedKeys}
            />
            <Layout className="box-container">
              <StudentRouter />
            </Layout>
          </Layout>
          <PageFooter />
        </Layout>
      );
    } else {
      return (
        <Layout>
          <UserHeaderMenu />
          <Layout style={{ minHeight: "60vh" }} className="box-container">
            <h1>Hello {user.name},</h1>
            <Card>
              <Result
                status="info"
                title="Your students are no longer part of this school!"
                subTitle="If you think this is some error, please contact the school."
              />
            </Card>
          </Layout>
          <PageFooter />
        </Layout>
      );
    }
  }
}

const StudentSiderMenu = (props) => (
  <Sider
    width={212}
    breakpoint="md"
    collapsible
    collapsed={props.collapsed}
    onCollapse={props.onCollapse}
    collapsedWidth={width < 480 ? 0 : 80}
    theme="light"
    style={{
      boxShadow: "2px 0px 2px #e8e8e8",
      borderRight: "1px solid #e8e8e8",
    }}
  >
    <div className="sider-fixed-container">
      <Menu
        mode="inline"
        theme="light"
        onSelect={props.onSelect}
        selectedKeys={props.selectedKeys}
      >
        <Menu.Item key="1">
          <Link to="/">
            <Icon type="dashboard" /> <span>Dashboard</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="2">
          <Link to="/attendance">
            <Icon type="calendar" /> <span>Attendance</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="3">
          <Link to="/marks">
            <Icon type="percentage" /> <span>Marks</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="4">
          <Link to="/notes/">
            <Icon type="file-pdf" /> <span>Notes</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="5">
          <Link to="/timetable">
            <Icon type="carry-out" /> <span>Timetable</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="6">
          <Link to="/homework/">
            <Icon type="home" /> <span>Homework</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="7">
          <Link to="/virtual-classroom/">
            <Icon type="solution" /> <span>Virtual Classroom</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="8">
          <Link to="/simple-test/">
            <Icon type="check" /> <span>Simple Online Test</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="11">
          <Link to="/online-test/">
            <Icon type="check" /> <span>Online Tests</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="9">
          <Link to="/fees/">
            <Icon type="dollar" /> <span>Fees</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="10">
          <Link to="/meeting/">
            <Icon type="video-camera" /> <span>Meeting</span>
          </Link>
        </Menu.Item>
      </Menu>
    </div>
  </Sider>
);

function mapStateToProps(state) {
  return {
    user: state.user,
    students: state.students,
  };
}

export default connect(mapStateToProps, null)(StudentLayout);
