import React, { Component } from "react";
import {
  Layout,
  Form,
  Select,
  Input,
  InputNumber,
  Button,
  message
} from "antd";
import { api } from "../../../../config";

class InventoryForm extends Component {
  state = {
    categories: [],
    inventory_id: null
  };

  componentDidMount() {
    this.get_inventory_categories();
    if (this.props.match.params.id) {
      this.get_inventory_details(this.props.match.params.id);
      this.setState({ inventory_id: this.props.match.params.id });
    }
  }

  get_inventory_details = id => {
    api
      .get("/simple/inventory/" + id + "/")
      .then(res => {
        if (res.data && res.data.success) {
          this.props.form.setFieldsValue(res.data.data);
        } else {
          message.error(res.data.error);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  get_inventory_categories = () => {
    api
      .get("/simple/inventory/category/")
      .then(res => {
        if (res.data && res.data.success) {
          this.setState({ categories: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  handleButtonClick = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        // console.log(val);
        if (this.state.inventory_id) {
          this.update_inventory_item(this.state.id, val);
        } else {
          this.add_inventory_item(val);
        }
      }
    });
  };

  add_inventory_item = data => {
    api
      .put("/simple/inventory/", JSON.stringify(data))
      .then(res => {
        if (res.data && res.data.success) {
          message.success("New Item Added");
          this.props.history.push("/inventory/list/");
        } else {
          message.error("Failed to add");
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  update_inventory_item = (id, data) => {
    api
      .post("/simple/inventory/" + id + "/", JSON.stringify(data))
      .then(res => {
        if (res.data && res.data.success) {
          message.success("New Item Added");
          this.props.history.push("/inventory/list/");
        } else {
          message.error("Failed to add");
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  render() {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    const inventory_id = this.state.inventory_id;
    return (
      <Layout.Content>
        <h1>Inventory Form</h1>
        <Form labelCol={{ sm: 8 }} wrapperCol={{ sm: 8 }}>
          <FormItem label="Category">
            {getFieldDecorator("category_id", {
              rules: [{ required: true, message: "Please Select Category" }]
            })(
              <Select disabled={inventory_id ? true : false}>
                {this.state.categories.map((item, index) => {
                  return (
                    <Select.Option value={item.id} key={index}>
                      {item.name}
                    </Select.Option>
                  );
                })}
              </Select>
            )}
          </FormItem>
          <FormItem label="Item Name">
            {getFieldDecorator("item_name", {
              rules: [{ required: true, message: "Please enter name" }]
            })(<Input placeholder="Item Name" />)}
          </FormItem>
          <FormItem label="Quantity">
            {getFieldDecorator("quantity", {
              rules: [{ required: true, message: "Quantity is required" }]
            })(<InputNumber />)}
          </FormItem>
          <FormItem wrapperCol={{ offset: 8 }}>
            <Button type="primary" onClick={this.handleButtonClick}>
              {inventory_id ? "Update" : "Add"}
            </Button>
          </FormItem>
        </Form>
      </Layout.Content>
    );
  }
}

export default Form.create()(InventoryForm);
