import { Card, Form, Layout, Radio } from "antd";
import React, { Component } from "react";

class FinanceReports extends Component {
  state = {};
  render() {
    return (
      <Layout.Content>
        <h1>Finance Reports</h1>
        <Card title="Finance Reports">
          <Form.Item label="Category">
            <Radio.Group>
              <Radio>Expense</Radio>
              <Radio>Payroll</Radio>
              <Radio>Income</Radio>
              <Radio>Fees</Radio>
            </Radio.Group>
          </Form.Item>
        </Card>
      </Layout.Content>
    );
  }
}

export default FinanceReports;
