import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { baseurl } from "../../../../config";
import { Currency } from "../../../../components";

class PrintPaySlip extends Component {
  render() {
    const { about_school, payslip_items, payroll, payslip_total } = this.props;
    return (
      <div className="student-details-print">
        <div className="header">
          <div className="logo">
            <img
              src={`${baseurl}/${about_school.school_logo}`}
              alt="Institute Logo"
            />
          </div>
          <div className="header-info">
            <h2>{about_school.school_name}</h2>
            <p>{about_school.school_address}</p>
            <p>Ph: {about_school.phone_number}</p>
          </div>
        </div>
        <div className="content">
          <div className="details">
            <div className="item">
              <h5>Employee Name</h5>
              <p>{payroll.emp_name}</p>
            </div>
            <div className="item">
              <h5>Payslip for</h5>
              <p>{moment(payroll.month_year).format("MMM, YYYY")}</p>
            </div>
            <div className="item">
              <h5>Present Days</h5>
              <p>{payroll.present_days}</p>
            </div>
            <div className="item">
              <h5>Absent Days</h5>
              <p>{payroll.total_days - payroll.present_days}</p>
            </div>
          </div>
          <div className="table">
            <table>
              <thead>
                <tr>
                  <th>Item</th>
                  <th>Debit</th>
                  <th>Credit</th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(payslip_items) &&
                  payslip_items.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item.item_name}</td>
                        <td>
                          {item.type === 0 ? (
                            ""
                          ) : (
                            <Currency value={item.value} />
                          )}
                        </td>
                        <td>
                          {item.type === 1 ? (
                            ""
                          ) : (
                            <Currency value={item.value} />
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
              <tfoot>
                <tr>
                  <th colSpan={1}>Total</th>
                  <th colSpan={2}>
                    <Currency value={payslip_total} />
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
        <div className="footer">
          <p>Generated by Eduga</p>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    about_school: state.about_school,
  };
}

export default connect(mapStateToProps, null)(PrintPaySlip);
