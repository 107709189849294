import React, { Component } from "react";
import { Layout, message, Button, Result, Card, Modal } from "antd";
import { api } from "../../../config";
import TestQuestion from "./RenderTestQuestion";

class OnlineTestPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      student_id: undefined,
      test: {},
      questions: [],
      attempt_id: undefined,
      answers: {},
      question: {},
      loading: false,
      submitting: false,
      timer: 0,
      success: false,
    };
    this.timer_interval = null;
    this.duration = 0;
  }

  componentDidMount() {
    if (!this.props.location.state) {
      this.props.history.push("/online-test/");
    }
    let { student_id, test } = this.props.location.state;
    if (student_id && test) {
      this.setState(
        { student_id, test, timer: test.duration * 60 },
        this.get_questions_list
      );
    } else {
      this.props.history.push("/online-test/");
    }
  }

  componentWillUnmount() {
    if (this.timer_interval) {
      clearInterval(this.timer_interval);
    }
  }

  get_questions_list = () => {
    let { test, student_id } = this.state;
    let url = `/ot/student/${student_id}/test/${test.id}/attempts/`;
    api
      .put(url)
      .then((res) => {
        if (res.data.success) {
          let data = res.data.data;
          this.arrange_answers(data);
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  fullScreen = () => {
    let { success } = this.state;
    let ot_div = document.getElementById("online-test");
    ot_div
      .requestFullscreen()
      .then(() => {})
      .catch((err) => {
        console.log("Error");
      });
    document.onfullscreenchange = (e) => {
      if (document.fullscreenElement === null && !success) {
        alert("Do not exit fullscreen mode until test is finished");
        // ot_div.requestFullscreen();
      }
    };
  };

  start_timer = () => {
    let { timer } = this.state;
    const timer_ele = document.getElementById("timer");
    this.timer_interval = setInterval(() => {
      let time = Math.floor(timer / 60) + "m " + Math.floor(timer % 60) + "s";
      timer--;
      this.duration++;
      if (timer === 0) {
        clearInterval(this.timer_interval);
        this.submit_test();
      }
      timer_ele.innerText = time;
    }, 1000);
  };

  submit_test = () => {
    let { answers, attempt_id, student_id, test } = this.state;
    let data = { answers, attempt_id, student_id, duration: this.duration };
    let url = `/ot/student/${student_id}/test/${test.id}/attempts/`;
    this.setState({ submitting: true });
    api
      .post(url, JSON.stringify(data))
      .then((res) => {
        if (res.data.success) {
          clearInterval(this.timer_interval);
          this.setState({ success: true });
          this.setState({ submitting: false });
        } else {
          message.error(res.data.error);
          this.setState({ submitting: false });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({ submitting: false });
      });
  };

  arrange_answers = (data) => {
    let { answers, question } = this.state;
    let questions = data.questions.map((item, index) => {
      answers[item.id] = {
        state: index === 0 ? 0 : 1,
        option: undefined,
        answer: undefined,
        files: [],
        index,
        answered: false,
        type: item.type,
      };
      return { ...item, state: 1, index };
    });
    question = questions[0];
    this.setState(
      {
        question,
        answers,
        questions: questions,
        attempt_id: data.attempt_id,
      },
      this.start_timer
    );
  };

  handleQuestionClick = (item) => {
    let { answers } = this.state;
    let n = answers[item.id];
    if ([0, 1].includes(n.state)) {
      n.state = 4;
    }
    this.setState({ answers, question: item });
  };

  setAnswer = (answer) => {
    let { question, answers } = this.state;
    answers[question.id] = { ...answer };
    this.setState({ answers });
    let data = {
      time: new Date(),
      answers: answers,
      test: this.state.test,
      attempt_id: this.state.attempt_id,
    };
    window.localStorage.setItem("online_test", JSON.stringify(data));
  };

  next_question = () => {
    let { question, questions } = this.state;
    if (questions.length - 1 === question.index) {
      return;
    }
    let next_question = questions[question.index + 1];
    this.handleQuestionClick(next_question);
  };

  prev_question = () => {
    let { question, questions } = this.state;
    if (question.index === 0) {
      return;
    }
    question = questions[question.index - 1];
    this.handleQuestionClick(question);
  };

  handleSubmit = () => {
    let { answers } = this.state;
    let unanswered = 0;
    Object.keys(answers).forEach((item) => {
      if (![0, 2, 5].includes(answers[item].state)) unanswered++;
    });
    if (unanswered > 0) {
      Modal.confirm({
        title: "Are you sure?",
        content: `You have ${unanswered} unanswered questions!`,
        okText: "Submit Anyway",
        onOk: this.submit_test,
        onCancel: () => {},
      });
    } else {
      this.submit_test();
    }
  };

  render() {
    let { questions, answers, question, submitting, test } = this.state;
    let { success } = this.state;
    return (
      <Layout.Content>
        {!success ? (
          <div id="online-test">
            <div className="online-exam">
              <div className="top-bar">
                <div className="exam-name">
                  <h1>{test.name}</h1>
                </div>
                <div className="exam-timer">
                  <h1 id="timer">&nbsp;</h1>
                </div>
                <div className="exam-buttons">
                  <Button
                    type="danger"
                    style={{ marginRight: 10 }}
                    // onClick={this.exit_test}
                    disabled={true}
                  >
                    Exit
                  </Button>
                  <Button
                    type="primary"
                    onClick={this.handleSubmit}
                    loading={submitting}
                  >
                    Submit
                  </Button>
                </div>
              </div>
              <div className="questions">
                <div className="question-box">
                  {
                    <TestQuestion
                      questions={questions}
                      question={question}
                      answer={answers[question.id]}
                      answers={answers}
                      next={this.next_question}
                      prev={this.prev_question}
                      setAnswer={this.setAnswer}
                    />
                  }
                </div>
                <div className="questions-list">
                  <h1>Questions List</h1>
                  <div className="questions-grid">
                    {questions.map((item, index) => {
                      let color = "";
                      // 0 - active, 1 - unanswered, 2 - answered, 3 - answer later, 4 - viewed, 5 - answered and review
                      switch (answers[item.id].state) {
                        case 0:
                          // color = "#22B6FF";
                          color = "#dd2222";
                          break;
                        case 1:
                          color = "white";
                          break;
                        case 4:
                          color = "#dd2222";
                          break;
                        case 2:
                          color = "#93db58";
                          break;
                        case 3:
                          color = "#9880cb";
                          break;
                        case 5:
                          color = "#22B6FF";
                          break;
                        default:
                          color = "white";
                          break;
                      }
                      return (
                        <div
                          className="question-number"
                          key={index}
                          onClick={this.handleQuestionClick.bind(this, item)}
                          style={{ backgroundColor: color }}
                        >
                          {index + 1}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Card>
            <Result
              status="success"
              title="Your answers are submitted"
              extra={
                <Button
                  onClick={() => this.props.history.push("/online-test/")}
                >
                  Go Back
                </Button>
              }
            />
          </Card>
        )}
      </Layout.Content>
    );
  }
}

export default OnlineTestPage;
