import React, { Component } from "react";
import {
  Layout,
  Input,
  message,
  Row,
  Col,
  Button,
  Descriptions,
  Card,
} from "antd";
import Axios from "axios";
import moment from "moment";

class CheckApplicationStatus extends Component {
  state = {
    base_url: null,
    settings: {},
    school: [],
    params: {},
    sch_details: {},
    token: null,
    application_id: "",
    mobile_no: "",
    data: {},
  };

  componentDidMount() {
    let state = this.props.location.state;
    if (!state) {
      message.error("Invalid URL");
      return;
    }
    this.setState({ ...state });
  }

  handleSubmit = () => {
    let { application_id, mobile_no, base_url, token, params } = this.state;
    if (!(application_id && mobile_no)) {
      message.error("Enter both Application no and mobile no");
      return;
    }
    if (application_id.length <= 6) {
      message.error("Incorrect Application ID");
      return;
    }
    if (params.sch_code !== application_id.slice(0, 6)) {
      message.error("Invalid Application Code");
      return;
    }
    let url = base_url + "/admissions/application/status/";
    let app_id = application_id.slice(6);
    let data = {
      application_id: app_id,
      mobile_no,
    };
    let headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    Axios.post(url, JSON.stringify(data), { headers })
      .then((res) => {
        if (res.data.success) {
          this.setState({ data: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  get_payment_status = (status) => {
    switch (status) {
      case -1:
        return { text: "Not Yet Received", color: "red" };
      case 0:
        return { text: "Unknown", color: "" };
      case 1:
        return { text: "Paid", color: "green" };
      default:
        return { text: "Unknown", color: "" };
    }
  };

  get_application_status = (status) => {
    switch (status) {
      case -2:
        return { text: "Withdrawn (by candidate)", color: "red" };
      case -1:
        return { text: "Rejected (by institute)", color: "red" };
      case 0:
        return { text: "Unknown", color: "" };
      case 1:
        return { text: "Waitlist (by candidate)", color: "blue" };
      case 2:
        return { text: "Waitlist (by institute)", color: "blue" };
      case 3:
        return { text: "Selected", color: "green" };
      default:
        return { text: "Unknown", color: "" };
    }
  };

  render() {
    let { data } = this.state;
    const { Item } = Descriptions;
    let payment_status = this.get_payment_status(data.payment_status);
    let application_status = this.get_application_status(data.status);
    return (
      <Layout.Content className="admission-details">
        <h1 className="align-center">Check Application Status</h1>
        <div style={{ maxWidth: "100%", margin: "auto", width: 1200 }}>
          <Row gutter={24}>
            <Col md={4} lg={6}></Col>
            <Col md={16} lg={12} style={{ textAlign: "center" }}>
              <Input
                placeholder="Application number"
                onChange={(e) => {
                  this.setState({ application_id: e.target.value });
                }}
                style={{ marginRight: 20, width: "40%" }}
              />
              <Input
                placeholder="Mobile number"
                onChange={(e) => {
                  this.setState({ mobile_no: e.target.value });
                }}
                style={{ marginRight: 20, width: "40%" }}
              />
              <Button type="primary" onClick={this.handleSubmit}>
                Go
              </Button>
            </Col>
            <Col md={4} lg={6}></Col>
          </Row>
          {data.student_photo && (
            <Card style={{ marginTop: 20 }}>
              <Descriptions title="Student Details" bordered>
                <Item label="Student Name">{data.student_name}</Item>
                <Item label="Class Applying To">{data.application_class}</Item>
                <Item label="Student Photo">
                  <img
                    src={data.student_photo.url}
                    alt="Student Pic"
                    style={{
                      width: 120,
                      height: 160,
                      border: "1px dashed grey",
                      //   padding: "0 10px 100%",
                      padding: 10,
                    }}
                  />
                </Item>
                <Item label="Date of Birth">
                  {data.details.student.dob
                    ? moment(data.details.student.dob).format("YYYY-MM-DD")
                    : "-"}
                </Item>
                <Item label="Gender">{data.details.student.gender || "-"}</Item>
              </Descriptions>
              <Descriptions
                title="Parent/Guardian Details"
                bordered
                style={{ marginTop: 20 }}
              >
                <Item label="Name">{data.parent_name}</Item>
                <Item label="Mobile">{data.mobile}</Item>
                <Item label="Email">{data.email}</Item>
                <Item label="Address">
                  {data.details.guardian.address || "-"}
                </Item>
              </Descriptions>
              <Descriptions
                title="Father's Details"
                bordered
                style={{ marginTop: 20 }}
              >
                <Item label="Father's Name">
                  {data.details.father.name || "-"}
                </Item>
                <Item label="Father's Mobile">
                  {data.details.father.mobile || "-"}
                </Item>
                <Item label="Father's Profession">
                  {data.details.father.profession || "-"}
                </Item>
                <Item label="Father's Salary">
                  {data.details.father.salary || "-"}
                </Item>
              </Descriptions>
              <Descriptions
                title="Mother's Details"
                bordered
                style={{ marginTop: 20 }}
              >
                <Item label="Mother's Name">
                  {data.details.mother.name || "-"}
                </Item>
                <Item label="Mother's Mobile">
                  {data.details.mother.mobile || "-"}
                </Item>
                <Item label="Mother's Profession">
                  {data.details.mother.profession || "-"}
                </Item>
                <Item label="Mother's Salary">
                  {data.details.mother.salary || "-"}
                </Item>
              </Descriptions>
              <Descriptions
                title="Previous School/College"
                bordered
                style={{ marginTop: 20 }}
              >
                <Item label="Name">{data.details.prev_school.name || "-"}</Item>
                <Item label="City">{data.details.prev_school.city || "-"}</Item>
                <Item label="Percent/CGPA">
                  {data.details.prev_school.marks || "-"}
                </Item>
                <Item label="Previous Marks Card">
                  {(data.details.prev_school.marks_card &&
                    data.details.prev_school.marks_card.map((item, index) => {
                      return <span>{item.name}, </span>;
                    })) ||
                    "-"}
                </Item>
              </Descriptions>
              <Descriptions title="Status" bordered style={{ marginTop: 20 }}>
                <Item label="Payment Status">
                  <strong>{payment_status.text}</strong>
                </Item>
                <Item label="Application Status">
                  <strong>{application_status.text}</strong>
                </Item>
              </Descriptions>
            </Card>
          )}
        </div>
      </Layout.Content>
    );
  }
}

export default CheckApplicationStatus;
