import {
  Button,
  Card,
  Col,
  Descriptions,
  Layout,
  message,
  Row,
  Table,
} from "antd";
import React, { Component } from "react";
import { Currency } from "../../../../components";
import { api } from "../../../../config";
import moment from "moment";
import PayExpenseInstallment from "./PayExpenseInstallment";

class ExpenseDetails extends Component {
  state = {
    id: undefined,
    expense: {},
    showModal: false,
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      this.setState({ id: this.props.match.params.id });
      this._get_expense_details(this.props.match.params.id);
    }
  }

  _get_expense_details = (id) => {
    api
      .get(`/finance/expense/${id}/`)
      .then((res) => {
        if (res.data.success) {
          this.setState({ expense: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  closeModal = () => {
    this.setState({ showModal: false });
    this._get_expense_details(this.state.id);
  };

  render() {
    const { expense, showModal, id } = this.state;
    const { installments } = expense;
    const { Item } = Descriptions;
    const columns = [
      { title: "ID", dataIndex: "id" },
      { title: "Payment Mode", dataIndex: "payment_mode" },
      {
        title: "Amount",
        dataIndex: "paid_amount",
        align: "right",
        render: (v) => <Currency value={v} />,
      },
      { title: "Payment Details", dataIndex: "payment_details" },
      {
        title: "Payment Date",
        dataIndex: "payment_date",
        render: (v) => (
          <span>{moment.utc(v).local().format("YYYY-MM-DD")}</span>
        ),
      },
    ];
    return (
      <Layout.Content>
        <h1>Expense Details</h1>
        <Card style={{ marginBottom: 16 }}>
          <Descriptions>
            <Item label="Expense Title">{expense.name}</Item>
            <Item label="Category">{expense.category}</Item>
            <Item label="Account">{expense.account}</Item>
            <Item label="Vendor">{expense.vendor}</Item>
            <Item label="Amount">
              <Currency value={expense.amount} />
            </Item>
            <Item label="Tax">
              <Currency value={expense.tax} />
            </Item>
            <Item label="Total Amount">
              <Currency value={expense.total_amount} />
            </Item>
            <Item label="Paid Amount">
              <Currency value={expense.paid_amount} />
            </Item>
            <Item label="Due Amount">
              <Currency value={expense.total_amount - expense.paid_amount} />
            </Item>
          </Descriptions>
        </Card>
        <Row gutter={16}>
          <Col sm={24}>
            <Card
              title="Installments"
              extra={[
                <Button
                  type="primary"
                  size="small"
                  ghost
                  onClick={() => this.setState({ showModal: true })}
                  disabled={expense.total_amount - expense.paid_amount <= 0}
                >
                  Pay Installment
                </Button>,
              ]}
            >
              <Table
                columns={columns}
                dataSource={installments || []}
                rowKey="id"
              />
            </Card>
          </Col>
        </Row>
        {showModal && (
          <PayExpenseInstallment
            closeModal={this.closeModal}
            expense_id={id}
            expense={expense}
          />
        )}
      </Layout.Content>
    );
  }
}

export default ExpenseDetails;
