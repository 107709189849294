import React, { Component } from "react";
import {
  Layout,
  Row,
  Col,
  Button,
  Table,
  Form,
  Input,
  message,
  Icon,
  Popconfirm
} from "antd";
import { api } from "../../../../config";
import { ShowBreadcrumbs } from "../../../../components";

class InventoryCategory extends Component {
  state = {
    loading: false,
    spinning: false,
    categories: []
  };

  componentDidMount() {
    this.getCategoriesList();
  }

  addCategory = () => {
    this.setState({ loading: true });
    this.props.form.validateFields((err, val) => {
      if (!err) {
        api
          .put("/finance/inventory/categories/", JSON.stringify(val))
          .then(res => {
            if (res.data && res.data.success) {
              message.success("Inventory category added");
              this.getCategoriesList();
            } else {
              message.error(res.data.error);
            }
            this.setState({ loading: false });
          })
          .catch(err => {
            console.log(err);
            this.setState({ loading: false });
          });
      }
    });
  };

  getCategoriesList = () => {
    api
      .get("/finance/inventory/categories/")
      .then(res => {
        if (res.data && res.data.success) {
          this.setState({ categories: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  deleteCategory = id => {
    api
      .delete("/finance/inventory/category/" + id + "/")
      .then(res => {
        if (res.data && res.data.success) {
          message.success("Category deleted");
          this.getCategoriesList();
        } else {
          message.error(res.data.error);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  render() {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    const columns = [
      { title: "ID", dataIndex: "id" },
      { title: "Name", dataIndex: "name" },
      {
        title: "Actions",
        render: record => (
          <span>
            <Popconfirm
              title="Are you sure?"
              onConfirm={this.deleteCategory.bind(this, record.id)}
            >
              <Icon type="delete" className="action-icon" />
            </Popconfirm>
          </span>
        )
      }
    ];
    return (
      <Layout.Content>
        <ShowBreadcrumbs
          items={[
            { name: "Home", link: "/" },
            { name: "Finance", link: "/finance/" },
            { name: "Inventory", link: "/finance/inventory/" },
            { name: "Inventory Category", link: "/finance/inventory/category" }
          ]}
        />
        <Row>
          <Col sm={6}>
            <h1>Inventory Categories</h1>
          </Col>
        </Row>
        <Row gutter={32}>
          <Col sm={12}>
            <Table
              loading={this.state.spinning}
              columns={columns}
              dataSource={this.state.categories}
              rowKey="id"
            />
          </Col>
          <Col sm={12}>
            <h2>Add Inventory Category</h2>
            <Form>
              <FormItem label="Name">
                {getFieldDecorator("name", {
                  rules: [
                    { required: true, message: "Category name is required!" }
                  ]
                })(<Input placeholder="Category Name" />)}
              </FormItem>
              <FormItem>
                <Button onClick={this.addCategory} loading={this.state.loading}>
                  Add Category
                </Button>
              </FormItem>
            </Form>
          </Col>
        </Row>
      </Layout.Content>
    );
  }
}

export default Form.create()(InventoryCategory);
