import React, { Component } from "react";
import { Layout, Row, Col, Button, message } from "antd";
import {
  ClassSectionDropdown,
  TeacherSelectionDropdown,
} from "../../../components";
import { api } from "../../../config";
import { connect } from "react-redux";

class TimetableViewer extends Component {
  state = {
    class_id: null,
    timing: [],
    timetable: null,
    teacher: false,
    teacher_id: null,
  };

  componentDidMount() {
    this.get_timing();
  }

  handleEdit = () => {
    if (!this.state.class_id) {
      message.error("Please select class and section");
      return;
    }
    this.props.history.push({
      pathname: "/timetable/edit",
      state: {
        class_id: this.state.class_id,
      },
    });
  };

  get_timing = () => {
    api
      .get("/timetable/timing/")
      .then((res) => {
        if (res.data && res.data.success) {
          this.setState({ timing: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleClassChange = (class_id) => {
    this.get_timetable(class_id);
    this.setState({ class_id });
  };

  get_timetable = (class_id) => {
    api
      .get("/timetable/" + class_id + "/")
      .then((res) => {
        if (res.data && res.data.success) {
          this.setState({ timetable: res.data.data, teacher: false });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleTeacherChange = (val) => {
    this.setState({ teacher_id: val });
    this.get_teacher_timetable(val);
  };

  get_teacher_timetable = (teacher_id) => {
    api
      .get("/timetable/teacher/" + teacher_id + "/")
      .then((res) => {
        if (res.data && res.data.success) {
          this.setState({ timetable: res.data.data, teacher: true });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handlePrint = () => {
    if (!this.state.timetable) {
      message.error("Please select class or teacher");
      return;
    }
    window.print();
  };

  render() {
    const days = [
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
    ];
    const { timetable, timing, teacher, class_id, teacher_id } = this.state;
    const { user } = this.props;
    return (
      <Layout.Content>
        <h1>Timetable</h1>
        <Row gutter={32}>
          <Col sm={8}>
            <h3>Class</h3>
            <ClassSectionDropdown
              value={class_id}
              onChange={this.handleClassChange}
            />
          </Col>
          <Col sm={6} offset={2}>
            {[2, 3, 4].includes(user.role) && (
              <span>
                <h3>Teacher</h3>
                <TeacherSelectionDropdown
                  value={teacher_id}
                  onChange={this.handleTeacherChange}
                  style={{ width: 180 }}
                />
              </span>
            )}
          </Col>
          <Col sm={8} style={{ textAlign: "right", marginTop: 32.5 }}>
            {(this.props.user.role === 3 || this.props.user.role === 4) && (
              <div>
                <Button type="primary" onClick={this.handleEdit}>
                  Edit Timetable
                </Button>
                <Button
                  type="danger"
                  style={{ marginLeft: 15 }}
                  onClick={() => {
                    this.props.history.push("/timetable/timing/");
                  }}
                >
                  Edit Timing
                </Button>
                <Button
                  type="ghost"
                  style={{ marginLeft: 15 }}
                  onClick={this.handlePrint}
                >
                  Print
                </Button>
              </div>
            )}
          </Col>
        </Row>
        <div className="timetable-table">
          {timetable && (
            <table>
              <thead>
                <tr>
                  <th>Day</th>
                  {this.state.timing.map((item, index) => {
                    if (item.type === 0) {
                      return (
                        <th key={index} rowSpan={0}>
                          {item.name}
                        </th>
                      );
                    } else return <th key={index}>{item.name}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                {days.map((day, day_index) => {
                  return (
                    <tr key={day_index}>
                      <td>{day.charAt(0).toUpperCase() + day.slice(1)}</td>
                      {!teacher
                        ? timetable &&
                          timetable[day] &&
                          timetable[day].map((item, index) => {
                            if (!item) return <td key={index}>-</td>;
                            return (
                              <td key={index}>
                                {item.subject || "-"}
                                <br />({item.teacher || "-"})
                              </td>
                            );
                          })
                        : timetable &&
                          timetable[day] &&
                          timing &&
                          timing.map((item, index) => {
                            const d = timetable[day];
                            var r = d.find((dt) => {
                              return dt.period_id === item.id;
                            });
                            return r ? (
                              <td key={index}>
                                {r.subject} <br /> {r.class + r.section}
                              </td>
                            ) : (
                              <td key={index}>-</td>
                            );
                          })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      </Layout.Content>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps, null)(TimetableViewer);
