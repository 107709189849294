import React, { useEffect } from "react";
import moment from "moment";
import { connect } from "react-redux";

function PrintTable({ columns, data, type, ...props }) {
  var pp = null;
  useEffect(() => {
    const beforeprint = (e) => {
      document.getElementById("root").style.display = "none";
      pp = document.getElementById("print-page");
      document.body.appendChild(pp);
    };
    window.addEventListener("beforeprint", beforeprint);
    return () => {
      window.removeEventListener("beforeprint", beforeprint);
    };
  }, []);

  useEffect(() => {
    const afterprint = (e) => {
      if (pp) {
        document.body.removeChild(pp);
        document.getElementById("print-parent").appendChild(pp);
      }
      document.getElementById("root").style.display = "block";
    };
    window.addEventListener("afterprint", afterprint);
    return () => {
      window.removeEventListener("afterprint", afterprint);
    };
  }, []);

  return (
    <div id="print-parent">
      <div className="finance-report-print" id="print-page">
        <div className="letter-head">
          <div className="insitute-details">
            <h1 style={{ marginBottom: 0 }}>
              {props.about_school.school_name}
            </h1>
            <p>{props.about_school.school_address}</p>
          </div>
          <div className="details">
            <span className="item">
              <strong>Report Type : </strong>
              {type}
            </span>
            <span className="item">
              <strong>Generated on : </strong>
              {moment.utc().local().format("DD MMM, YYYY hh:mm A")}
            </span>
          </div>
        </div>
        <div className="table">
          <table>
            <thead>
              <tr>
                {columns.map((c, i) => {
                  if (!c.print) return null;
                  return (
                    <th key={i} style={{ textAlign: c.align || "left" }}>
                      {c.title}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {data.map((v, i) => {
                return (
                  <tr key={i}>
                    {columns.map((c, ind) => {
                      let val = c.dataIndex ? v[c.dataIndex] || " - " : v;
                      let align = c.align || "left";
                      if (!c.print) return null;
                      return (
                        <td key={ind} style={{ textAlign: align }}>
                          {(c.render && c.render(val, v, i)) || val || " "}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
            <tfoot className="table-footer">
              <tr>
                <td colSpan={columns.length}>
                  <p>Report generated using Eduga</p>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    about_school: state.about_school,
  };
}

export default connect(mapStateToProps, null)(PrintTable);
