import React from "react";
import { Switch, Route } from "react-router-dom";
import TransferCertificate from "./TransferCertificate";

const AdminCertificatesRouter = props => (
  <Switch>
    <Route exact path="/certificate/transfer" component={TransferCertificate} />
    <Route
      exact
      path="/certificate/transfer/:id"
      component={TransferCertificate}
    />
  </Switch>
);

export { AdminCertificatesRouter };
