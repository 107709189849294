import React from "react";
import { Switch, Route } from "react-router-dom";
import AdmissionForm from "./AdmissionForm";
import NewApplicationForm from "./NewApplicationForm";
import CheckApplicationStatus from "./CheckApplicationStatus";
import ApplicationPreview from "./ApplicationPreview";
import PaymentStatus from "./PaymentStatus";

const AdmissionFormRouter = props => (
  <Switch>
    <Route
      exact
      path="/admissions/apply/:sch_code/:sch_token/"
      component={AdmissionForm}
    />
    <Route
      exact
      path="/admissions/apply/new-application/"
      component={NewApplicationForm}
    />
    <Route
      exact
      path="/admissions/apply/status/"
      component={CheckApplicationStatus}
    />
    <Route
      exact
      path="/admissions/apply/preview/"
      component={ApplicationPreview}
    />
    <Route
      exact
      path="/admissions/apply/payment-status/"
      component={PaymentStatus}
    />
  </Switch>
);

export { AdmissionFormRouter };
