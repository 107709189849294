import React from "react";
import { Switch, Route } from "react-router-dom";
import AdminsList from "./admins/AdminsList";
import AboutSchool from "./about/AboutSchool";
import ExportsList from "./export/ExportsList";
import SmsSettings from "./sms/SmsSettings";
import AddAdmin from "./admins/AddAdmin";
import AdminModules from "./admins/AdminModules";

const AdminActionsRouter = (props) => (
  <Switch>
    <Route exact path="/admin-actions/admins" component={AdminsList} />
    <Route exact path="/admin-actions/admin/new" component={AddAdmin} />
    <Route
      exact
      path="/admin-actions/admin/:id/modules/access"
      component={AdminModules}
    />
    <Route exact path="/admin-actions/about" component={AboutSchool} />
    <Route exact path="/admin-actions/export" component={ExportsList} />
    <Route exact path="/admin-actions/sms" component={SmsSettings} />
  </Switch>
);

export { AdminActionsRouter };
