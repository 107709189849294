import React from "react";
import { Switch, Route } from "react-router-dom";
import MessagesList from "../../admin/messages/MessagesList";

const TeacherMessagesRouter = props => (
  <Switch>
    <Route exact path="/messages" component={MessagesList} />
    <Route exact path="/messages/new" />
  </Switch>
);

export { TeacherMessagesRouter };
