import React, { Component } from "react";
import { Layout, Spin, Row, Col, Button, message } from "antd";
import Axios from "axios";

class AdmissionForm extends Component {
  state = {
    spinning: false,
    base_url: null,
    settings: {},
    school: [],
    params: {},
    sch_details: {},
  };

  componentDidMount() {
    let params = this.props.match.params;
    if (!(params.sch_code || params.sch_token)) {
      message.error("Invalid URL");
      return;
    }
    window.sessionStorage.clear();
    this.setState({ params: this.props.match.params }, () => {
      this.get_schools_list();
    });
  }

  get_schools_list = () => {
    this.setState({ spinning: true });
    Axios.get(
      "https://ambegaalu.in/eduga/schools_list.json?nocache=" +
        new Date().getTime()
    )
      .then((res) => {
        let schools = res.data.schools;
        let school = schools.find((item) => {
          return item.code === this.state.params.sch_code;
        });
        if (!school) {
          message.error("This is invalid URL");
          return;
        }
        this.setState({ school, base_url: school.api }, () => {
          this.get_school_details();
          this.get_admissions_settings();
        });
        this.setState({ spinning: false });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ spinning: false });
      });
  };

  get_school_details = () => {
    let url = this.state.base_url + "/about/school/";
    this.setState({ spinning: true });
    Axios.get(url)
      .then((res) => {
        if (res.data.success) {
          this.setState({ sch_details: res.data.data });
        }
        this.setState({ spinning: false });
      })
      .catch((err) => {
        this.setState({ spinning: false });
      });
  };

  get_admissions_settings = () => {
    let url = this.state.base_url + "/settings/admissions/";
    this.setState({ spinning: true });
    Axios.get(url)
      .then((res) => {
        if (res.data.success) {
          let data = res.data.data;
          if (data.adm_application_token === this.state.params.sch_token) {
            this.setState({ settings: res.data.data });
          } else if (data.adm_classes_list.length === 0) {
            message.error("Admissions not yet open");
          } else {
            message.error("Invalid URL");
          }
        } else {
          message.error("Invalid URL");
        }
        this.setState({ spinning: false });
      })
      .catch((err) => {
        this.setState({ spinning: false });
      });
  };

  handleApply = (path) => {
    let { base_url, sch_details, settings, params } = this.state;
    Axios.get(base_url + "/guest/generate-token/")
      .then((res) => {
        if (res.data.success) {
          let data = res.data.data;
          if (data.token) {
            this.props.history.push({
              pathname: path,
              state: {
                base_url,
                sch_details,
                settings,
                params,
                token: data.token,
              },
            });
          } else {
            message.error("Something went wrong. Please try again later");
          }
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    let { sch_details, settings, base_url } = this.state;
    return (
      <Layout.Content className="admission-details container">
        <h1 className="align-center">Online Application Form</h1>
        <Spin spinning={this.state.spinning}>
          <Row gutter={32}>
            <Col sm={24} md={24} lg={{ span: 12, offset: 6 }}>
              <table className="admission-table">
                <tbody>
                  <tr>
                    <td colSpan={2}>
                      <img
                        src={base_url + "/" + sch_details.school_logo}
                        alt="School Logo"
                        className="sch-logo"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Institution Name</td>
                    <td>{sch_details.school_name}</td>
                  </tr>
                  <tr>
                    <td>Address</td>
                    <td>{sch_details.school_address}</td>
                  </tr>
                  <tr>
                    <td>Phone no</td>
                    <td>{sch_details.phone_number}</td>
                  </tr>
                  {settings.adm_application_fees > 0 && (
                    <tr>
                      <td>Application Fees</td>
                      <td>{settings.adm_application_fees}</td>
                    </tr>
                  )}
                  <tr>
                    <td>Admission open for classes</td>
                    <td>
                      {settings.adm_classes_list &&
                        settings.adm_classes_list.join(", ")}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="adm-buttons">
                <Button
                  type="primary"
                  onClick={this.handleApply.bind(
                    this,
                    "/admissions/apply/new-application/"
                  )}
                >
                  Apply Now
                </Button>
                <Button
                  type="primary"
                  onClick={this.handleApply.bind(
                    this,
                    "/admissions/apply/status/"
                  )}
                >
                  Check Application Status
                </Button>
              </div>
            </Col>
          </Row>
        </Spin>
      </Layout.Content>
    );
  }
}

export default AdmissionForm;
