import React from "react";
import { Switch, Route } from "react-router-dom";
import TimetableViewer from "../../admin/timetable/TimetableViewer";

const StudentTimetableRouter = props => (
  <Switch>
    <Route exact path="/timetable" component={TimetableViewer} />
  </Switch>
);

export { StudentTimetableRouter };
