import React, { Component } from "react";
import {
  Layout,
  Form,
  Input,
  DatePicker,
  Select,
  Button,
  message,
  Radio,
} from "antd";
import { StudentSearch, UserSelectorDropdown } from "../../../components";
import moment from "moment";
import { api } from "../../../config";

class MeetingForm extends Component {
  state = {
    invites: null,
  };

  add_meeting = (data) => {
    api
      .put("/meetings/", JSON.stringify(data))
      .then((res) => {
        if (res.data.success) {
          message.success("Meeting Scheduled");
          this.props.history.push("/meeting/");
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleSubmit = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        this.add_meeting(val);
      }
    });
  };

  render() {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    const { Option } = Select;
    const { invites } = this.state;

    return (
      <Layout.Content>
        <h1 className="align-center">Create New Meeting</h1>
        <p className="align-center">
          Note : for one way broadcast you can record and upload video file
        </p>
        <Form labelCol={{ sm: 8 }} wrapperCol={{ sm: 8 }}>
          <FormItem label="Title">
            {getFieldDecorator("title", {
              rules: [{ required: true, message: "Title is required!" }],
            })(<Input />)}
          </FormItem>
          <FormItem label="Description">
            {getFieldDecorator("description", {
              rules: [{ required: true, message: "Description is required!" }],
            })(<Input />)}
          </FormItem>
          <FormItem label="Start Time">
            {getFieldDecorator("start_time", {
              rules: [{ required: true, message: "Start Time is required!" }],
            })(
              <DatePicker
                disabledDate={(date) => {
                  return (
                    moment().subtract(1, "day").valueOf() >= date.valueOf()
                  );
                }}
                showTime={{ format: "HH:mm", minuteStep: 5 }}
                format="YYYY-MM-DD HH:mm"
              />
            )}
          </FormItem>
          <FormItem label="Invite">
            {getFieldDecorator("invites", {
              rules: [{ required: true, message: "Invitation is required!" }],
            })(
              <Select onChange={(val) => this.setState({ invites: val })}>
                <Option value={1}>Parents &amp; Staff</Option>
                <Option value={2}>Teachers &amp; Admins</Option>
                <Option value={3}>Admins</Option>
                <Option value={4}>Invitees Only</Option>
                <Option value={5}>All Staff &amp; Invited Students</Option>
              </Select>
            )}
          </FormItem>
          {invites === 4 && (
            <FormItem label="Invities">
              {getFieldDecorator("invited_users", {
                rules: [{ required: true, message: "Users is required!" }],
              })(<UserSelectorDropdown />)}
            </FormItem>
          )}
          {invites === 5 && (
            <FormItem label="Students">
              {getFieldDecorator("invited_users", {
                rules: [{ required: true, message: "Students is required!" }],
              })(<StudentSearch mode="multiple" />)}
            </FormItem>
          )}
          <FormItem label="Meeting Type">
            {getFieldDecorator("meeting_type", { initialValue: "jitsi" })(
              <Radio.Group>
                <Radio value="jitsi">Jitsi</Radio>
                <Radio value="webrtc" disabled>
                  P2P
                </Radio>
              </Radio.Group>
            )}
          </FormItem>
          <FormItem wrapperCol={{ offset: 8 }}>
            <Button type="primary" onClick={this.handleSubmit}>
              Save
            </Button>
          </FormItem>
        </Form>
      </Layout.Content>
    );
  }
}

export default Form.create()(MeetingForm);
