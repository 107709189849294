import React from "react";
import moment from "moment";
import { Currency } from "../../../../components";

export default function SimpleFinanceReportPrint({ reports, type, total }) {
  const _print_date = (v) => {
    switch (type) {
      case "all":
        return moment.utc(v).local().format("YYYY-MM-DD");
      case "daily":
        return moment.utc(v).local().format("YYYY-MM-DD");
      case "weekly":
        return `Week ${v}`;
      case "monthly":
        return moment.utc(v).local().format("YYYY-MM");
      case "annual":
        return moment.utc(v).local().format("YYYY");
      default:
        return "";
    }
  };

  return (
    <div className="finance-report-print">
      <div>
        <h1>Finance Reports</h1>
      </div>
      <div>
        {reports && (
          <div className="table">
            <div className="report-total">
              <div>
                Total Credit : <Currency value={(total && total.credit) || 0} />
              </div>
              <div>
                Total Debit : <Currency value={(total && total.debit) || 0} />
              </div>
              <div>
                Total Balance :{" "}
                <Currency value={(total && total.credit - total.debit) || 0} />
              </div>
            </div>
            <table>
              <thead>
                <tr>
                  <th>Sl. No.</th>
                  <th>Date</th>
                  <th>Title</th>
                  <th>Debit</th>
                  <th>Credit</th>
                </tr>
              </thead>
              <tbody>
                {reports.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className="id">{index + 1}</td>
                      <td className="date">{_print_date(item.date)}</td>
                      <td className="name">{item.name}</td>
                      <td className="currency">
                        {item.txn_type === 1 && (
                          <Currency value={item.amount} />
                        )}
                      </td>
                      <td className="currency">
                        {item.txn_type === 0 && (
                          <Currency value={item.amount} />
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
}
