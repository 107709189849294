import React from "react";
import { Switch, Route } from "react-router-dom";
import TimetableViewer from "./TimetableViewer";
import TimetableTiming from "./TimetableTimings";
import TimetableEditor from "./TimetableEditor";

const AdminTimetableRouter = props => (
  <Switch>
    <Route exact path="/timetable/" component={TimetableViewer} />
    <Route exact path="/timetable/edit" component={TimetableEditor} />
    <Route exact path="/timetable/timing/" component={TimetableTiming} />
  </Switch>
);

export { AdminTimetableRouter };
