import {
  Card,
  Col,
  Form,
  InputNumber,
  Layout,
  Row,
  Select,
  Input,
  Button,
  Icon,
  message,
} from "antd";
import React, { Component } from "react";
import { EmployeeDropdown } from "../../../../components";
import { api } from "../../../../config";

class EmployeeSalaryForm extends Component {
  state = {
    count: 1,
    employee_id: undefined,
  };

  _cancel_payroll_item = (index) => {
    let { form } = this.props;
    let { count } = this.state;
    let val = form.getFieldValue("salary");
    val.splice(index, 1);
    form.setFieldsValue({ salary: val });
    this.setState({ count: count - 1 });
  };

  handleSave = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        api
          .put("/simple/payroll/salary/", JSON.stringify(val))
          .then((res) => {
            if (res.data.success) {
              message.success("Employee Salary Items Added");
              this.props.history.goBack();
            } else {
              message.error(res.data.error);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  _update_salary = () => {
    let { form } = this.props;
    let val = form.getFieldValue("salary");
    let total = 0.0;
    val.forEach((item) => {
      total +=
        item.type === 0 ? parseFloat(item.value) : -1 * parseFloat(item.value);
    });
    form.setFieldsValue({ ctc: total });
    this.setState({ ctc: total });
  };

  _get_employee_salary = (employee_id) => {
    api
      .get(`/simple/payroll/employee/${employee_id}/salary/`)
      .then((res) => {
        if (res.data.success) {
          let { salary, ctc } = res.data.data;
          if (!salary) {
            this.props.form.resetFields(["salary", "ctc"]);
            this.setState({ count: 1 });
          } else {
            salary = JSON.parse(salary);
            this.setState({ count: salary.length });
            this.props.form.setFieldsValue({ salary, ctc });
          }
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const { count } = this.state;
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    return (
      <Layout.Content>
        <Row gutter={24}>
          <Col sm={12}>
            <h1>Set Employee Salary (CTC)</h1>
          </Col>
          <Col sm={12} className="action-buttons">
            <Button icon="save" type="primary" onClick={this.handleSave}>
              Save
            </Button>
          </Col>
        </Row>
        <Form>
          <Row gutter={24}>
            <Col sm={12}>
              <Card title="Employee Salary Details">
                <FormItem label="Employee">
                  {getFieldDecorator("employee_id", {
                    rules: [
                      { required: true, message: "Employee is required!" },
                    ],
                  })(
                    <EmployeeDropdown
                      onChange={(val) => this._get_employee_salary(val)}
                    />
                  )}
                </FormItem>
                <FormItem label="CTC (monthly)">
                  {getFieldDecorator("ctc", {
                    rules: [{ required: true, message: "CTC is required!" }],
                  })(<InputNumber />)}
                </FormItem>
              </Card>
            </Col>
            <Col sm={12}>
              <Card title="Employee Salary Items">
                {[...Array(count).keys()].map((item, index) => {
                  return (
                    <Row gutter={12} key={index} style={{ marginBottom: 0 }}>
                      <Col sm={12}>
                        <FormItem>
                          {getFieldDecorator(`salary[${index}].item_name`, {
                            rules: [
                              {
                                required: true,
                                message: "Item name is required",
                              },
                            ],
                          })(<Input placeholder="Item Name" />)}
                        </FormItem>
                      </Col>
                      <Col sm={6}>
                        <FormItem>
                          {getFieldDecorator(`salary[${index}].type`, {
                            initialValue: 0,
                            rules: [
                              { required: true, message: "Type is required!" },
                            ],
                          })(
                            <Select onBlur={this._update_salary}>
                              <Select.Option value={0}>Credit</Select.Option>
                              <Select.Option value={1}>Debit</Select.Option>
                            </Select>
                          )}
                        </FormItem>
                      </Col>
                      <Col sm={5}>
                        <FormItem>
                          {getFieldDecorator(`salary[${index}].value`, {
                            rules: [
                              { required: true, message: "Value is requried!" },
                            ],
                          })(<InputNumber onBlur={this._update_salary} />)}
                        </FormItem>
                      </Col>
                      <Col sm={1}>
                        <Icon
                          type="close-circle"
                          style={{ fontSize: 18, marginTop: 8 }}
                          onClick={this._cancel_payroll_item.bind(this, index)}
                        />
                      </Col>
                    </Row>
                  );
                })}
                <Button
                  icon="plus"
                  type="dashed"
                  block
                  onClick={() => this.setState({ count: count + 1 })}
                >
                  Add New Item
                </Button>
              </Card>
            </Col>
          </Row>
        </Form>
      </Layout.Content>
    );
  }
}

export default Form.create()(EmployeeSalaryForm);
