import React, { Component } from "react";
import { Layout, message, Card, Row, Col, Empty, Radio, Icon } from "antd";
import { connect } from "react-redux";
import StudentSelector from "../../../components/StudentSelector";
import { api } from "../../../config";
import moment from "moment";
import { Link } from "react-router-dom";

let state = {
  student_id: undefined,
  loading: false,
  data: {
    announcements: [],
    homework: [],
    messages: [],
    vc_rooms: [],
    meetings: [],
  },
  view: "today",
  student: {},
};

class StudentDashboard extends Component {
  state = state;

  componentWillUnmount() {
    state = this.state;
  }

  handleStudentSelect = (student_id) => {
    let student = this.props.students.find((item) => {
      return student_id === item.id;
    });
    this.setState({ student_id, student }, this.get_student_data);
  };

  get_student_data = () => {
    let { student_id, view } = this.state;
    if (!student_id) {
      return;
    }
    this.setState({ loading: true });
    let url = "/students/dashboard/" + student_id + "/?view=" + view;
    api
      .get(url)
      .then((res) => {
        if (res.data.success) {
          this.setState({ data: res.data.data });
          this.setState({ loading: false });
        } else {
          message.error(res.data.error);
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  render() {
    const { loading, view, student, student_id } = this.state;
    const Title = (
      <div style={{ display: "flex" }}>
        <h3 style={{ marginBottom: 0, marginRight: 16 }}>Welcome</h3>
        <StudentSelector
          value={student_id}
          onChange={this.handleStudentSelect}
          size="small"
          style={{ marginRight: 32 }}
        />
      </div>
    );
    const Extras = (
      <div>
        <span>View : </span>
        <Radio.Group
          size="small"
          value={view || "today"}
          onChange={(e) =>
            this.setState({ view: e.target.value }, this.get_student_data)
          }
        >
          <Radio.Button value="today">Today</Radio.Button>
          <Radio.Button value="last-5-entries">Last 5</Radio.Button>
        </Radio.Group>
        <span style={{ display: "inline-block", marginLeft: 16 }}>
          <Icon
            type="retweet"
            style={{ fontSize: 18 }}
            title="Refresh data"
            onClick={this.get_student_data}
          />
        </span>
      </div>
    );
    const { announcements, messages, homework, vc_rooms, meetings } =
      this.state.data;
    return (
      <Layout.Content className="dashboard">
        <Card
          title={Title}
          extra={Extras}
          className="student-dashboard-parent-card"
        >
          <Row gutter={16} type="flex">
            <Col lg={8} md={12} sm={24} xs={24} className="card-row">
              <Card
                title="Announcements"
                loading={loading}
                className="admin-card card-elevation"
              >
                {announcements.length > 0 ? (
                  <div className="student-dashboard-list">
                    {announcements.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="student-dashboard-list-item"
                        >
                          <Link to={"/announcement/" + item.id + "/"}>
                            # {item.id} : {item.title}
                          </Link>
                          &nbsp; from {item.from_name},&nbsp;
                          {moment.utc(item.timestamp).local().fromNow()}
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <Empty />
                )}
              </Card>
            </Col>
            <Col sm={24} md={12} lg={8} xs={24} className="card-row">
              <Card
                title="Messages"
                loading={loading}
                className="admin-card card-elevation"
              >
                {messages.length > 0 ? (
                  <div className="student-dashboard-list">
                    {messages.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="student-dashboard-list-item"
                        >
                          <Link to={"/messages/" + item.id + "/"}>
                            # {item.id} : {item.subject}
                          </Link>
                          &nbsp; from {item.from_name}, &nbsp;
                          {moment.utc(item.timestamp).local().fromNow()}
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <Empty />
                )}
              </Card>
            </Col>
            <Col sm={24} md={12} lg={8} xs={24} className="card-row">
              <Card
                title="Homeworks"
                loading={loading}
                className="admin-card card-elevation"
              >
                {homework.length > 0 ? (
                  <div className="student-dashboard-list">
                    {homework.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="student-dashboard-list-item"
                        >
                          <Link
                            to={{
                              pathname: "/homework/" + item.id,
                              state: { student_id },
                            }}
                          >
                            # {item.id} : {item.title}
                          </Link>
                          &nbsp; in {item.subject_name}, is given&nbsp;
                          {moment.utc(item.timestamp).local().fromNow()} and due
                          date is{" "}
                          {moment
                            .utc(item.timestamp)
                            .local()
                            .format("YYYY-MM-DD")}
                          ({moment.utc(item.submission_date).local().fromNow()})
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <Empty />
                )}
              </Card>
            </Col>
            <Col sm={24} md={12} lg={8} xs={24} className="card-row">
              <Card
                title="Virtual Classroom"
                loading={loading}
                className="admin-card card-elevation"
              >
                {vc_rooms.length > 0 ? (
                  <div className="student-dashboard-list">
                    {vc_rooms.map((item, index) => {
                      let extras = JSON.parse(item.extras);
                      let url = `/virtual-classroom/${item.id}`;
                      if (
                        "class_type" in extras &&
                        extras.class_type === "jitsi"
                      ) {
                        url += "/jitsi";
                      }
                      return (
                        <div
                          key={index}
                          className="student-dashboard-list-item"
                        >
                          <Link
                            to={{
                              pathname: url,
                              state: { student, vc_class: item },
                            }}
                          >
                            # {item.id} : {item.name}
                          </Link>
                          &nbsp; in {item.subject}
                          {moment(item.start_time).isBefore(new Date())
                            ? " was "
                            : " is "}
                          {moment.utc(item.start_time).local().fromNow()}
                          {item.status === 3 && (
                            <span> and is available for streaming</span>
                          )}
                          {item.status === -1 && <span> and is cancelled</span>}
                          {item.status === 1 && <span> and is ongoing</span>}
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <Empty />
                )}
              </Card>
            </Col>
            <Col sm={24} md={12} lg={8} xs={24} className="card-row">
              <Card
                title="Meetings"
                loading={loading}
                className="admin-card card-elevation"
              >
                {meetings.length > 0 ? <div></div> : <Empty />}
              </Card>
            </Col>
          </Row>
        </Card>
      </Layout.Content>
    );
  }
}
function mapStateToProps(state) {
  return {
    user: state.user,
    students: state.students,
  };
}

export default connect(mapStateToProps, null)(StudentDashboard);
