import { Select } from "antd";
import React, { Component } from "react";
import { api } from "../config";

export default class HRUserSelectorDropdown extends Component {
  state = {
    users: {},
  };

  componentDidMount() {
    this._get_users_list();
  }

  _get_users_list = () => {
    let url = "/hr/users/";
    if (this.props.all) {
      url += "?all_users";
    }
    api
      .get(url)
      .then((res) => {
        if (res.data.success) {
          this._arrange_users_list(res.data.data);
        }
      })
      .catch((err) => {});
  };

  _arrange_users_list = (data) => {
    let usrs = {};

    data.forEach((item) => {
      if (![2, 3, 5, 6, 7].includes(item.role)) {
        return;
      }
      let role = this._get_user_role_text(item.role);
      if (role in usrs) {
        usrs[role].push(item);
      } else {
        usrs[role] = [item];
      }
    });
    this.setState({ users: usrs });
  };

  _get_user_role_text = (role) => {
    switch (role) {
      case 2:
        return "Teachers";
      case 3:
        return "Admin";
      case 4:
        return "Admin";
      case 5:
        return "Driver";
      case 6:
        return "Watchman";
      case 7:
        return "Librarian";
      default:
        return "Others";
    }
  };

  render() {
    const { users } = this.state;
    const { Option, OptGroup } = Select;
    return (
      <Select
        placeholder="Select User"
        showSearch
        filterOption={(input, option) =>
          option.props.label
            ? false
            : option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
        }
        value={this.props.value || undefined}
        onChange={this.props.onChange}
        allowClear
        {...this.props}
      >
        {Object.keys(users).map((role, index) => {
          return (
            <OptGroup label={role} key={index}>
              {users[role].map((item, i) => {
                return (
                  <Option value={item.id} key={i}>
                    {item.name || item.email || item.username}
                  </Option>
                );
              })}
            </OptGroup>
          );
        })}
      </Select>
    );
  }
}
