import React, { Component } from "react";
import { Select, message } from "antd";
import { api } from "../config";
import { connect } from "react-redux";

class StudentSelector extends Component {
  state = { students: [], student_id: undefined };

  componentDidMount() {
    if (this.props.user.role === 1) {
      let student_id = this.props.value;
      if (this.props.students.length > 0) {
        student_id = student_id || this.props.students[0].id;
        if (this.props.onChange) {
          this.props.onChange(student_id);
        }
      }
      this.setState({ students: this.props.students, student_id });
    } else {
      this.get_students_list();
    }
  }

  get_students_list = () => {
    api
      .get("/students-list/")
      .then((res) => {
        if (res.data && res.data.success) {
          this.setState({ students: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const Option = Select.Option;
    const style = { width: 160, maxWidth: "100%" };
    const { students, student_id } = this.state;
    return (
      <Select
        {...this.props}
        value={this.props.value || student_id}
        onChange={this.props.onChange}
        style={{ ...style, ...this.props.style }}
        placeholder="Select Student"
      >
        {students.map((item, index) => {
          return (
            <Option value={item.id} key={index}>
              {item.fullname}
            </Option>
          );
        })}
      </Select>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    students: state.students,
  };
}

export default connect(mapStateToProps, null)(StudentSelector);
