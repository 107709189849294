import { Select } from "antd";
import React, { useEffect, useState } from "react";
import { api } from "../config";

const { Option } = Select;

const defaultStyle = {
  width: 150,
};

function FeeNameDropdown({
  class_id,
  ac_year_id,
  value,
  onChange,
  error = undefined,
  style = defaultStyle,
}) {
  const [feeList, setFeeList] = useState([]);

  const __get_fee_name_list = () => {
    api
      .get(`/fees/list/${class_id}/${ac_year_id}/`)
      .then((res) => {
        if (res.data.success) {
          setFeeList(res.data.data);
          error && error(false);
        }
      })
      .catch((err) => {
        console.log(err);
        error && error(true);
      });
  };

  useEffect(() => {
    if (class_id && ac_year_id) {
      __get_fee_name_list();
    }
  }, [class_id, ac_year_id]);

  return (
    <Select
      placeholder="Fee Name"
      style={style}
      value={value}
      onChange={onChange}
      allowClear
    >
      {feeList.map((item, index) => {
        return (
          <Option value={item.name || ""} key={index}>
            {item.name || "No name"}
          </Option>
        );
      })}
    </Select>
  );
}

export default FeeNameDropdown;
