import React, { Component } from "react";
import { Layout, Form, Input, Button, message } from "antd";
import { api } from "../../../../config";

class AddAdmin extends Component {
  state = {
    loading: false
  };

  componentDidMount() {
    this.changePassword();
  }

  changePassword = () => {
    let password = Math.random()
      .toString(36)
      .slice(-8);
    this.props.form.setFieldsValue({ password });
  };

  handleClick = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        this.setState({ loading: true });
        api
          .put("/admins/", JSON.stringify(val))
          .then(res => {
            if (res.data && res.data.success) {
              message.success("New Admin Added");
              this.props.history.push("/admin-actions/admins/");
            } else {
              message.error(res.data.error);
            }
            this.setState({ loading: false });
          })
          .catch(err => {
            console.log(err);
            this.setState({ loading: false });
          });
      }
    });
  };

  render() {
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      }
    };
    const { getFieldDecorator } = this.props.form;
    const FormItem = Form.Item;
    return (
      <Layout.Content>
        <h1>Add New Admin</h1>
        <Form {...formItemLayout}>
          <FormItem label="Admin Name">
            {getFieldDecorator("name", {
              rules: [{ required: true, message: "Admin name is required" }]
            })(<Input placeholder="Admin Name" />)}
          </FormItem>
          <FormItem label="Admin Email">
            {getFieldDecorator("email", {
              rules: [
                { required: true, message: "Admin email is required" },
                { type: "email", message: "Invalid email address" }
              ]
            })(<Input placeholder="Admin Email" type="email" />)}
          </FormItem>
          <FormItem label="Password">
            {getFieldDecorator("password", {
              rules: [{ required: true, message: "Password is required" }]
            })(<Input style={{ width: "88%", marginRight: 10 }} />)}
            <Button icon="reload" onClick={this.changePassword} />
          </FormItem>
          <FormItem wrapperCol={{ offset: 8 }}>
            <Button
              type="primary"
              onClick={this.handleClick}
              loading={this.state.loading}
            >
              Add Admin
            </Button>
          </FormItem>
        </Form>
      </Layout.Content>
    );
  }
}

export default Form.create()(AddAdmin);
