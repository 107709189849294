import React, { Component } from "react";
import { Layout, Form, Input, Button, Spin, InputNumber, message } from "antd";
import { api } from "../../../../config";

class VehiclesForm extends Component {
  state = {
    update: null,
    loading: false,
    spinning: false
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      this.setState({ update: this.props.match.params.id });
      this.getVehicleDetails(this.props.match.params.id);
    }
  }

  getVehicleDetails = id => {
    this.setState({ spinning: true });
    api
      .get("/bus/vehicle/" + id + "/")
      .then(res => {
        if (res.data.success) {
          this.props.form.setFieldsValue(res.data.data);
        } else {
          message.error(res.data.error);
        }
        this.setState({ spinning: false });
      })
      .catch(err => {
        console.log(err);
        this.setState({ spinning: false });
      });
  };

  handleSubmit = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        if (this.state.update) {
          this.updateVehicle(this.state.update, val);
        } else {
          this.addNewVehicle(val);
        }
      }
    });
  };

  updateVehicle = (id, data) => {
    this.setState({ loading: true });
    api
      .post("/bus/vehicle/" + id + "/", JSON.stringify(data))
      .then(res => {
        if (res.data.success) {
          message.success("Vehicle Updated");
        } else {
          message.error(res.data.error);
        }
        this.setState({ loading: false });
      })
      .catch(err => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  addNewVehicle = data => {
    this.setState({ loading: true });
    api
      .put("/bus/vehicles/", JSON.stringify(data))
      .then(res => {
        if (res.data && res.data.success) {
          message.success("New Vehicle Added");
          this.props.history.push("/transportation/vehicles/");
        } else {
          message.error(res.data.error);
        }
        this.setState({ loading: false });
      })
      .catch(err => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  render() {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    return (
      <Layout.Content>
        <h1 className="align-center">
          {this.state.update ? "Update " : "Add "}Vehicle Details
        </h1>
        <Spin spinning={this.state.spinning}>
          <Form labelCol={{ sm: 8 }} wrapperCol={{ sm: 8 }}>
            <FormItem label="Name">
              {getFieldDecorator("name", {
                rules: [{ required: true, message: "Please enter name!" }]
              })(<Input placeholder="Name" />)}
            </FormItem>
            <FormItem label="RTO Registration No.">
              {getFieldDecorator("rto_reg_no", {
                rules: [
                  {
                    required: true,
                    message: "Please enter RTO Registration No."
                  }
                ]
              })(<Input placeholder="KA 00 AA 0000" />)}
            </FormItem>
            <FormItem label="Vehicle Name">
              {getFieldDecorator("vehicle_name", {
                rules: [
                  { required: true, message: "Please enter vehicle name!" }
                ]
              })(<Input />)}
            </FormItem>
            <FormItem label="Vehicle Company">
              {getFieldDecorator("vehicle_company", {
                rules: [
                  { required: true, message: "Please enter vehicle company!" }
                ]
              })(<Input />)}
            </FormItem>
            <FormItem label="Total Seats">
              {getFieldDecorator("total_seats", {
                rules: [{ required: true, message: "Please enter total seats" }]
              })(<InputNumber />)}
            </FormItem>
            <FormItem wrapperCol={{ offset: 8 }}>
              <Button loading={this.state.loading} onClick={this.handleSubmit}>
                {this.state.update ? "Update " : "Add "}Vehicle Details
              </Button>
            </FormItem>
          </Form>
        </Spin>
      </Layout.Content>
    );
  }
}

export default Form.create()(VehiclesForm);
