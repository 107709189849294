import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { baseurl } from "../../../config";

class PrintStudentDetails extends Component {
  state = {};
  render() {
    let { student, about_school } = this.props;
    return (
      <div className="student-details-print">
        <div className="header">
          <div className="logo">
            <img
              src={`${baseurl}/${about_school.school_logo}`}
              alt="Institute Logo"
            />
          </div>
          <div className="header-info">
            <h2>{about_school.school_name}</h2>
            <p>{about_school.school_address}</p>
            <p>Ph: {about_school.phone_number}</p>
          </div>
        </div>
        <div className="content">
          <div className="table">
            <table>
              <tbody>
                {student.profile_photo && (
                  <tr>
                    <td>Student Photo</td>
                    <td>{student.profile_photo}</td>
                  </tr>
                )}
                <tr>
                  <td>Student Name</td>
                  <td>{student.fullname}</td>
                </tr>
                <tr>
                  <td>Admission No</td>
                  <td>{student.admission_no}</td>
                </tr>
                <tr>
                  <td>Admission Date</td>
                  <td>{moment(student.admission_date).format("YYYY-MM-DD")}</td>
                </tr>
                {student.academic_years &&
                  Array.isArray(student.academic_years) &&
                  student.academic_years.length > 0 && (
                    <tr>
                      <td>Admission Type</td>
                      <td>
                        {student.academic_years[
                          student.academic_years.length - 1
                        ].admission_type || " - "}
                      </td>
                    </tr>
                  )}
                <tr>
                  <td>Roll No</td>
                  <td>{student.roll_no}</td>
                </tr>
                <tr>
                  <td>Class</td>
                  <td>
                    {student.class} {student.section}
                  </td>
                </tr>
                <tr>
                  <td>Date of Birth</td>
                  <td>{moment(student.dob).format("YYYY-MM-DD")}</td>
                </tr>
                <tr>
                  <td>Mobile</td>
                  <td>{student.mobile}</td>
                </tr>
                <tr>
                  <td>Aadhaar No.</td>
                  <td>{student.aadhar_no}</td>
                </tr>
                <tr>
                  <td>Religion</td>
                  <td>{student.religion}</td>
                </tr>
                <tr>
                  <td>Caste</td>
                  <td>{student.caste}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="footer">
          <p>Generated by Eduga</p>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    about_school: state.about_school,
  };
}

export default connect(mapStateToProps, null)(PrintStudentDetails);
