import React, { Component } from "react";
import { Modal, Form, Input, Checkbox, message } from "antd";
import { api } from "../config";

class MessageModal extends Component {
  state = {
    to_user: {},
    loading: false,
  };

  total_messages = 1;
  sent_counter = 0;

  componentDidMount() {
    if (this.props.student) {
      this.setState({ to_user: this.props.student });
    }
  }

  handleSend = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        this.setState({ loading: true });
        let { to_user } = this.state;
        let students_list = this.props.students_list;
        if (students_list && students_list.length > 0) {
          this.total_messages = students_list.length;
          students_list.forEach((item) => {
            let data = { ...val, student_id: item };
            this.send_message(data);
          });
        } else {
          val.to_id = to_user.user_id;
          val.student_id = to_user.admission_no ? to_user.id : null;
          this.send_message(val);
        }
      }
    });
  };

  send_message = (data) => {
    api
      .put("/messages/", JSON.stringify(data))
      .then((res) => {
        if (res.data && res.data.success) {
          this.sent_counter++;
          if (this.sent_counter === this.total_messages) {
            message.success("Message Sent");
            this.setState({ loading: false });
            this.props.closeModal();
          }
        } else {
          message.error(res.data.error);
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  render() {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    return (
      <Modal
        visible={this.props.visible}
        title="Message"
        onCancel={this.props.closeModal}
        okText="Send"
        onOk={this.handleSend}
        confirmLoading={this.state.loading}
      >
        <Form>
          <FormItem label="Subject">
            {getFieldDecorator("subject", {
              rules: [{ required: true, message: "Please enter subject!" }],
            })(<Input />)}
          </FormItem>
          <FormItem label="Message">
            {getFieldDecorator("message", {
              rules: [{ required: true, message: "Please enter message!" }],
            })(<Input.TextArea />)}
          </FormItem>
          <FormItem>
            {getFieldDecorator("reply_enabled")(
              <Checkbox>Enable Reply</Checkbox>
            )}
          </FormItem>
        </Form>
      </Modal>
    );
  }
}

export default Form.create()(MessageModal);
