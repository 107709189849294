import React from "react";
import { Switch, Route } from "react-router-dom";
import AccountEntries from "./AccountEntries";
import AccountEntryForm from "./AccountEntryForm";

const AdminSimpleAccountsRouter = (props) => (
  <Switch>
    <Route exact path="/accounts/" component={AccountEntries} />
    <Route exact path="/accounts/new" component={AccountEntryForm} />
    <Route exact path="/accounts/:id" component={AccountEntryForm} />
  </Switch>
);

export { AdminSimpleAccountsRouter };
