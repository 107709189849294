import React, { Component } from "react";
import { Layout, Table, message, Icon, Modal, Button, Input, Spin } from "antd";
import { connect } from "react-redux";
import { api } from "../../../config";

var moment = require("moment");

class MessagesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: [],
      message: {},
      show_message: false,
      reply: "",
      spinning: false,
      loading: false
    };
  }

  componentDidMount() {
    this.get_messages();
  }

  closeModal = () => {
    this.setState({ show_message: false });
    this.get_messages();
  };

  handleChange = e => {
    this.setState({ reply: e.target.value });
  };

  handleSend = () => {
    let data = { reply: this.state.reply };
    let url = "/message/" + this.state.message.id + "/";
    this.setState({ loading: true });
    api
      .post(url, JSON.stringify(data))
      .then(res => {
        if (res.data && res.data.success) {
          message.success("Reply Sent");
          this.closeModal();
        } else {
          message.error(res.data.error);
        }
        this.setState({ loading: false });
      })
      .catch(err => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  get_messages = () => {
    this.setState({ spinning: true });
    api
      .get("/messages/")
      .then(res => {
        if (res.data && res.data.success) {
          this.setState({ messages: res.data.data });
        } else {
          message.error(res.data.error);
        }
        this.setState({ spinning: false });
      })
      .catch(err => {
        console.log(err);
        this.setState({ spinning: false });
      });
  };

  render() {
    const columns = [
      { title: "From", dataIndex: "sent_by" },
      { title: "To", dataIndex: "received_by" },
      { title: "Student", dataIndex: "student_name" },
      { title: "Subject", dataIndex: "subject" },
      {
        title: "Date",
        render: record => <span>{moment(record.timestamp).fromNow()}</span>
      },
      {
        title: "Actions",
        render: record => (
          <span>
            <Icon
              type="read"
              className="action-icon"
              onClick={() => {
                this.setState({
                  message: record,
                  show_message: true
                });
              }}
            />
          </span>
        )
      }
    ];
    const user_id = this.props.user.id;
    const { show_message, message } = this.state;
    return (
      <Layout.Content>
        <h1>Messages List</h1>
        <Spin spinning={this.state.spinning}>
          <Table
            columns={columns}
            dataSource={this.state.messages}
            rowKey="id"
            rowClassName={(record, index) => {
              return record.from === user_id
                ? "message-sent"
                : "message-received";
            }}
          />
        </Spin>
        {show_message && (
          <Modal
            visible={show_message}
            title="Message"
            onCancel={this.closeModal}
            footer={[
              <Button key="cancel" onClick={this.closeModal}>
                Close
              </Button>,
              message.reply_enabled === 1 &&
                message.to === user_id &&
                !message.reply && (
                  <Button
                    key="send"
                    type="primary"
                    onClick={this.handleSend}
                    loading={this.state.loading}
                  >
                    Send
                  </Button>
                )
            ]}
          >
            <h2>{message.subject}</h2>
            {/* <h5>From: {message.from_name}</h5> */}
            {/* <h5>Date: {message.timestamp}</h5> */}
            <p>{message.message}</p>
            {message.reply_enabled === 1 && <h4>Reply : </h4>}
            {message.reply}
            {message.reply_enabled === 1 &&
              message.to === user_id &&
              !message.reply && <Input.TextArea onChange={this.handleChange} />}
          </Modal>
        )}
      </Layout.Content>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user
  };
}

export default connect(
  mapStateToProps,
  null
)(MessagesList);
