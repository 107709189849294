import { Checkbox, DatePicker, Form, Input, Button, message } from "antd";
import React, { Component } from "react";
import {
  AcademicYearDropdown,
  ClassSectionDropdown,
} from "../../../../components";
import { api } from "../../../../config";
import moment from "moment";

class StudentDetails extends Component {
  state = {
    username: false,
  };

  componentDidMount() {
    this.getAdmissionNo();
  }

  handleSubmit = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        let { current } = this.props;
        console.log(val);
        this.props.set_page(current + 1);
      }
    });
  };

  getAdmissionNo = () => {
    api
      .get("/next/admission_no/")
      .then((res) => {
        if (res.data && res.data.success) {
          this.props.form.setFieldsValue(res.data.data);
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  showAdmissionNoError = (val, msg) => {
    this.props.form.setFields({
      admission_no: { value: val, errors: [new Error(msg)] },
    });
  };

  handleAdmissionNoChange = (e) => {
    let val = e.target.value;
    if (!val) {
      return;
    }
    if (val < 1) {
      this.showAdmissionNoError(val, "Invalid Admission Number");
      return;
    }
    api
      .get("/validate/admn_no/" + val + "/")
      .then((res) => {
        if (res.data && res.data.success) {
          return;
        } else {
          this.showAdmissionNoError(val, "Admission number already used");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getRollNo = (class_id) => {
    api
      .get("/next/roll_no/" + class_id + "/")
      .then((res) => {
        if (res.data.success) {
          this.props.form.setFieldsValue({ roll_no: res.data.data.roll_no });
        }
      })
      .catch((err) => {});
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const FormItem = Form.Item;
    const { username } = this.state;
    return (
      <div>
        <Form wrapperCol={{ sm: 8 }} labelCol={{ sm: 8 }}>
          <FormItem label="Academic Year">
            {getFieldDecorator("ac_year_id", {
              rules: [
                { required: true, message: "Academic year is required!" },
              ],
            })(<AcademicYearDropdown />)}
          </FormItem>
          <FormItem label="Student Name">
            {getFieldDecorator("fullname", {
              rules: [{ required: true, message: "Student name is required!" }],
            })(<Input />)}
          </FormItem>
          <FormItem wrapperCol={{ offset: 8 }}>
            <Checkbox
              checked={username}
              onChange={(e) => this.setState({ username: e.target.checked })}
            >
              Use Mobile number as username (for login)
            </Checkbox>
          </FormItem>
          {username && (
            <FormItem label="Username">
              {getFieldDecorator("username", {
                rules: [{ required: true, message: "Username is required!" }],
              })(<Input />)}
            </FormItem>
          )}
          {/* <FormItem label="Email">
            {getFieldDecorator("email")(<Input />)}
          </FormItem> */}
          <FormItem label="Mobile">
            {getFieldDecorator("mobile", {
              rules: [
                { required: true, message: "Mobile is required!" },
                {
                  pattern: "^[6-9][0-9]{9}",
                  message: "Enter valid mobile number",
                },
              ],
            })(<Input />)}
          </FormItem>
          <FormItem label="Date of Birth">
            {getFieldDecorator("dob", {
              rules: [
                { required: true, message: "Date of Birth is requried!" },
              ],
            })(<DatePicker />)}
          </FormItem>
          <FormItem label="Admission No.">
            {getFieldDecorator("admission_no", {
              rules: [{ required: true, message: "Admission no is required!" }],
            })(<Input onChange={this.handleAdmissionNoChange} />)}
          </FormItem>
          <FormItem label="Admission Date">
            {getFieldDecorator("admission_date", {
              rules: [
                { required: true, message: "Admission date is required!" },
              ],
              initialValue: moment(),
            })(<DatePicker />)}
          </FormItem>
          <FormItem label="Class">
            {getFieldDecorator("class_id", {
              rules: [{ required: true, message: "Class is required!" }],
            })(<ClassSectionDropdown onChange={this.getRollNo} />)}
          </FormItem>
          <FormItem label="Roll No">
            {getFieldDecorator("roll_no")(<Input />)}
          </FormItem>
          <FormItem wrapperCol={{ offset: 8, sm: 8 }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button type="primary" ghost disabled={true}>
                Prev
              </Button>
              <Button type="primary" onClick={this.handleSubmit}>
                Next
              </Button>
            </div>
          </FormItem>
        </Form>
      </div>
    );
  }
}

export default Form.create()(StudentDetails);
