import React, { Component } from "react";
import { Layout, Input, Button, Row, Col, Form, message } from "antd";
import Axios from "axios";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
    };
  }

  handleSubmit = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        this.handlePasswordReset(val.school_code, val.email);
      }
    });
  };

  handlePasswordReset = async (sch_code, email) => {
    try {
      let url =
        "https://ambegaalu.in/eduga/schools_list.json?nocache=" +
        new Date().getTime();
      let r1 = await Axios.get(url);
      let schools_list = r1.data.schools;
      let school = schools_list.find((item) => {
        return item.code.toUpperCase() === sch_code.toUpperCase();
      });
      if (!school) {
        message.error("Invalid School Code!");
        return;
      }
      let res = await Axios.post(
        `${school.api}/forgot-password/`,
        JSON.stringify({ email }),
        { headers: { "Content-Type": "application/json" } }
      );
      if (res.data.success) {
        this.setState({ submitted: true });
      } else {
        message.error(res.data.error);
      }
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    return (
      <Layout.Content>
        <Row>
          <Col sm={8} offset={8}>
            {this.state.submitted === false ? (
              <Form>
                <h2>Enter your email to receive password reset link</h2>
                <Row gutter={16}>
                  <Col sm={8}>
                    <FormItem label="School code">
                      {getFieldDecorator("school_code", {
                        rules: [
                          {
                            required: true,
                            message: "School code is required!",
                          },
                        ],
                      })(<Input placeholder="School Code" />)}
                    </FormItem>
                  </Col>
                  <Col sm={16}>
                    <FormItem label="Email">
                      {getFieldDecorator("email", {
                        rules: [
                          { required: true, message: "Email is required!" },
                          { type: "email", message: "Invalid email address" },
                        ],
                        validateTrigger: ["onBlur"],
                      })(<Input type="email" placeholder="Email Address" />)}
                    </FormItem>
                  </Col>
                </Row>
                <FormItem>
                  <Button type="primary" onClick={this.handleSubmit}>
                    Submit
                  </Button>
                </FormItem>
              </Form>
            ) : (
              <div>
                <h3>Please check your email for password reset link</h3>
                <h3>Thank You.</h3>
              </div>
            )}
          </Col>
        </Row>
      </Layout.Content>
    );
  }
}

export default Form.create()(ForgotPassword);
