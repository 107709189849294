import React, { Component } from "react";
import {
  Modal,
  Form,
  InputNumber,
  DatePicker,
  Icon,
  Popover,
  message,
} from "antd";
import {
  ClassSectionDropdown,
  SubjectSelectionDropdown,
  TeacherSelectionDropdown,
} from "../../../components";
import { api } from "../../../config";

class AssignOnlineTest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      class_id: undefined,
    };
  }

  handleSubmit = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        let { test_id } = this.props;
        this.setState({ loading: true });
        api
          .put(`/ot/test/${test_id}/assign/`, JSON.stringify(val))
          .then((res) => {
            if (res.data.success) {
              message.success("Test Assigned");
              this.props.closeModal(true);
            } else {
              message.error(res.data.error);
            }
          })
          .catch((err) => console.log(err));
      }
    });
  };

  render() {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    const { loading, class_id } = this.state;
    return (
      <Modal
        visible={this.props.visible || true}
        title="Assign Online Test"
        onOk={this.handleSubmit}
        onCancel={() => this.props.closeModal(false)}
        confirmLoading={loading}
      >
        <Form>
          <FormItem label="Class">
            {getFieldDecorator("class_id", {
              rules: [{ required: true, message: "Class is required!" }],
            })(
              <ClassSectionDropdown
                onChange={(class_id) => this.setState({ class_id })}
              />
            )}
          </FormItem>
          <FormItem label="Subject">
            {getFieldDecorator("subject_id")(
              <SubjectSelectionDropdown class={class_id} />
            )}
          </FormItem>
          <FormItem label="Pass Marks">
            {getFieldDecorator("pass_marks")(<InputNumber />)}
          </FormItem>
          <FormItem label="Duration in min">
            {getFieldDecorator("duration", {
              rules: [{ required: true, message: "Duration is required!" }],
            })(<InputNumber />)}
          </FormItem>
          <FormItem
            label={
              <span>
                Start Time{" "}
                <sup>
                  <Popover content="Fixed start time for exam (all students must attend at this time)">
                    <Icon type="question-circle" />
                  </Popover>
                </sup>
              </span>
            }
          >
            {getFieldDecorator("start_time")(
              <DatePicker
                showTime={{ format: "hh:mm", minuteStep: 10 }}
                format="YYYY-MM-DD hh:mm"
              />
            )}
          </FormItem>
          <FormItem label="Evaluator">
            {getFieldDecorator("evaluator")(<TeacherSelectionDropdown />)}
          </FormItem>
        </Form>
      </Modal>
    );
  }
}

export default Form.create()(AssignOnlineTest);
