import React, { Component } from "react";
import { Layout, Row, Col, Table, message } from "antd";
import moment from "moment";
import { api } from "../../../config";

class BookIssueHistory extends Component {
  state = {
    id: null,
    issues: []
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      this.setState({ id: this.props.match.params.id });
      this.get_book_issue_history(this.props.match.params.id);
    }
  }

  get_book_issue_history = id => {
    api
      .get("/library/book/issue/" + id + "/")
      .then(res => {
        if (res.data.success) {
          this.setState({ issues: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  render() {
    const columns = [
      { title: "ID", dataIndex: "id" },
      { title: "Book Code", dataIndex: "code" },
      { title: "Title", dataIndex: "title" },
      {
        title: "Issued To",
        render: record => (
          <span>{record.student ? record.student : record.teacher}</span>
        )
      },
      {
        title: "Issue Date",
        render: record => (
          <span>
            {record.issue_date
              ? moment(record.issue_date).format("YYYY-MM-DD")
              : "-"}
          </span>
        )
      },
      {
        title: "Last Date",
        render: record => (
          <span>
            {record.last_date
              ? moment(record.last_date).format("YYYY-MM-DD")
              : "-"}
          </span>
        )
      },
      {
        title: "Return Date",
        render: record => (
          <span>
            {record.return_date
              ? moment(record.last_date).format("YYYY-MM-DD")
              : "-"}
          </span>
        )
      },
      {
        title: "Status",
        dataIndex: "status",
        render: val => (
          <span>
            {val === 2 && "Returned"}
            {val === 1 && "Not yet returned"}
          </span>
        )
      }
    ];
    return (
      <Layout.Content>
        <Row>
          <Col md={12}>
            <h1>Book Issue History</h1>
          </Col>
        </Row>
        <Table columns={columns} dataSource={this.state.issues} rowKey="id" />
      </Layout.Content>
    );
  }
}

export default BookIssueHistory;
