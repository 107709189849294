import React, { Component } from "react";
import { Layout, Row, Col, Button, Input, message } from "antd";
import CKEditor from "@ckeditor/ckeditor5-react";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import {
  ClassSectionDropdown,
  AcademicYearDropdown,
  SubjectSelectionDropdown
} from "../../../components";
import { api } from "../../../config";

class NewQuestionPaper extends Component {
  state = {
    class_id: null,
    ac_year_id: null,
    subject_id: null,
    name: "",
    questions: "",
    id: null,
    editor: null
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      this.get_question_paper(this.props.match.params.id);
      this.setState({ id: this.props.match.params.id });
    }
  }

  get_question_paper = id => {
    api
      .get("/question-paper-basic/" + id + "/")
      .then(res => {
        if (res.data.success) {
          const data = res.data.data;
          this.setState({ ...data });
          const editor = this.state.editor;
          editor.setData(this.state.questions);
        } else {
          message.error(res.data.error);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  handleSave = () => {
    let { class_id, ac_year_id, subject_id, name, id, questions } = this.state;
    if (!(class_id && ac_year_id && subject_id && name.length > 0)) {
      message.error(
        "Please select Class, AC Year, Subject and also enter Name"
      );
      return;
    }
    var data = { class_id, ac_year_id, subject_id, name, questions };
    if (id) {
      this.update_question_paper(id, data);
    } else {
      this.add_new_question_paper(data);
    }
  };

  update_question_paper = (id, data) => {
    api
      .post("/question-paper-basic/" + id + "/", JSON.stringify(data))
      .then(res => {
        if (res.data.success) {
          message.success("Question Paper Updated");
        } else {
          message.error(res.data.error);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  add_new_question_paper = data => {
    api
      .put("/question-papers-basic/", JSON.stringify(data))
      .then(res => {
        if (res.data.success) {
          message.success("New Question Paper Created");
          this.setState({ id: res.data.id });
        } else {
          message.error(res.data.error);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  render() {
    return (
      <Layout.Content>
        <h1>Question Paper Editor</h1>
        <Row style={{ marginBottom: 20 }}>
          <Col sm={10} xxl={6}>
            <span>Class : </span>
            <ClassSectionDropdown
              value={this.state.class_id}
              onChange={class_id => {
                this.setState({ class_id });
              }}
            />
          </Col>
          <Col sm={6} xxl={4}>
            <span>AC Year : </span>
            <AcademicYearDropdown
              value={this.state.ac_year_id}
              onChange={ac_year_id => {
                this.setState({ ac_year_id });
              }}
            />
          </Col>
          <Col sm={6} xxl={4}>
            <span>Subject : </span>
            <SubjectSelectionDropdown
              value={this.state.subject_id}
              onChange={subject_id => {
                this.setState({ subject_id });
              }}
              class={this.state.class_id}
            />
          </Col>
          <Col sm={2} xxl={2}></Col>
          <Col sm={24} xxl={8} style={{ textAlign: "right" }}>
            <Button type="primary" style={{ marginRight: 10 }} icon="printer">
              Print
            </Button>
            <Button type="primary" icon="save" onClick={this.handleSave}>
              Save
            </Button>
          </Col>
        </Row>
        <div className="question-paper-editor-parent">
          <Row style={{ marginBottom: 20 }}>
            <Col
              sm={2}
              offset={8}
              style={{ textAlign: "right", paddingRight: 10, paddingTop: 4 }}
            >
              <span>Name : </span>
            </Col>
            <Col sm={4}>
              <Input
                value={this.state.name}
                onChange={e => {
                  this.setState({ name: e.currentTarget.value });
                }}
              />
            </Col>
          </Row>
          <div className="question-paper-editor">
            <CKEditor
              editor={DecoupledEditor}
              onInit={editor => {
                editor.ui
                  .getEditableElement()
                  .parentElement.insertBefore(
                    editor.ui.view.toolbar.element,
                    editor.ui.getEditableElement()
                  );
                this.setState({ editor });
              }}
              onChange={(event, editor) =>
                this.setState({ questions: editor.getData() })
              }
              data="<h2 style='text-align: center;'>Question Paper Generator</h2>"
            />
          </div>
        </div>
      </Layout.Content>
    );
  }
}

export default NewQuestionPaper;
