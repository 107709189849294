import React, { Component } from "react";
import { Select } from "antd";
import { api } from "../config";

class STRouteStopsDropdown extends Component {
  state = {
    stops: [],
    route_id: null
  };

  componentDidMount() {
    if (this.props.route_id) {
      this.get_route(this.props.route_id);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.route_id !== prevProps.route_id) {
      this.get_route(prevState.route_id);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if ("route_id" in nextProps) {
      if (nextProps.route_id !== prevState.route_id) {
        prevState.route_id = nextProps.route_id;
        return { ...prevState };
      }
    }
    return null;
  }

  get_route = route_id => {
    let url = "/simple/transportation/route/" + route_id + "/";
    api
      .get(url)
      .then(res => {
        if (res.data.success) {
          this.setState({ stops: res.data.data.stops_list });
        }
      })
      .catch(err => {});
  };

  render() {
    const Option = Select.Option;
    const style = { minWidth: 160, maxWidth: "100%" };
    return (
      <Select
        placeholder="Bus Stops"
        showSearch
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        onChange={this.props.onChange}
        value={this.props.value}
        style={this.props.style || style}
        allowClear={this.props.allowClear || true}
        {...this.props}
      >
        {this.state.stops.map((item, index) => {
          return (
            <Option value={item} key={index}>
              {item}
            </Option>
          );
        })}
      </Select>
    );
  }
}

export default STRouteStopsDropdown;
