import React, { Component } from "react";
import {
  Layout,
  Row,
  Col,
  Button,
  Table,
  message,
  Icon,
  Popconfirm,
} from "antd";
import { connect } from "react-redux";
import { api, baseurl } from "../../../config";
import { ClassSectionDropdown } from "../../../components";

var moment = require("moment");

let state = {
  _class: null,
  notes_list: [],
};

class NotesList extends Component {
  state = state;

  componentWillUnmount() {
    state = this.state;
  }

  getNotes = (class_id) => {
    api
      .get("/notes/" + class_id + "/")
      .then((res) => {
        if (res.data && res.data.success) {
          this.setState({ notes_list: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  deleteNote = (id) => {
    api
      .delete("/notes/" + id + "/")
      .then((res) => {
        if (res.data && res.data.success) {
          message.success("Note deleted");
          this.getNotes(this.state._class);
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const { user } = this.props;
    const columns = [
      {
        title: "Subject",
        dataIndex: "subject_name",
      },
      {
        title: "Note Name",
        dataIndex: "name",
      },
      {
        title: "Description",
        dataIndex: "description",
      },
      {
        title: "Uploaded on",
        render: (record) => (
          <span>{moment(record.uploaded_on).format("DD-MM-YYYY")}</span>
        ),
      },
      {
        title: "Actions",
        render: (record) => (
          <span>
            <a href={baseurl + "/" + record.url} download>
              <Icon type="download" title="Download" className="action-icon" />
            </a>
            {[2, 3, 4].includes(user.role) && (
              <Popconfirm
                title="Are you sure?"
                onConfirm={this.deleteNote.bind(this, record.id)}
              >
                <Icon type="delete" className="action-icon absent" />
              </Popconfirm>
            )}
          </span>
        ),
      },
    ];
    return (
      <Layout.Content>
        <h1>Notes List</h1>
        <Row style={{ marginBottom: 20 }}>
          <Col sm={12}>
            <span>Class : </span>
            <ClassSectionDropdown
              value={this.state._class}
              onChange={(_class) => {
                this.getNotes(_class);
                this.setState({ _class: _class });
              }}
            />
          </Col>
          <Col sm={12} style={{ textAlign: "right" }}>
            {this.props.user.role === 2 && (
              <Button
                type="primary"
                icon="plus"
                onClick={() => {
                  this.props.history.push("/notes/new");
                }}
              >
                Add New Notes
              </Button>
            )}
          </Col>
        </Row>
        <Table
          columns={columns}
          dataSource={this.state.notes_list}
          rowKey="id"
        />
      </Layout.Content>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps, null)(NotesList);
