import React, { Component } from "react";
import { connect } from "react-redux";
import { Currency } from "../../../../components";
import { baseurl } from "../../../../config";

class PrintExpenseReport extends Component {
  state = {
    exp_reports: [],
    reports: {
      type: "",
      date: [undefined, undefined],
    },
  };
  componentDidMount() {
    let { exp_reports, reports } = this.props.location.state;
    this.setState({ exp_reports, reports }, () => {
      setTimeout(() => {
        window.print();
        this.props.history.goBack();
      }, 300);
    });
  }

  render() {
    const { about_school } = this.props;
    const { exp_reports, reports } = this.state;
    return (
      <div className="student-details-print expense-report-print">
        <div className="header">
          <div className="logo">
            <img
              src={`${baseurl}/${about_school.school_logo}`}
              alt="Institute Logo"
            />
          </div>
          <div className="header-info">
            <h2>{about_school.school_name}</h2>
            <p>{about_school.school_address}</p>
            <p>Ph: {about_school.phone_number}</p>
          </div>
        </div>
        <div className="content">
          <div className="details">
            <div className="item">
              <h5>Report Type</h5>
              <p>{reports.type}</p>
            </div>
            <div className="item">
              <h5>Start Date</h5>
              <p>{reports.date[0] || " - "}</p>
            </div>
            <div className="item">
              <h5>End Date</h5>
              <p>{reports.date[1] || " - "}</p>
            </div>
          </div>
          <div className="table">
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Title</th>
                  <th>Total Amount</th>
                </tr>
              </thead>
              <tbody>
                {exp_reports.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.id || " - "}</td>
                      <td>{item.title || "Others"}</td>
                      <td>
                        <Currency value={item.total_amount} />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="footer">
          <p>Generated by Eduga</p>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    about_school: state.about_school,
  };
}

export default connect(mapStateToProps)(PrintExpenseReport);
