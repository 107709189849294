import React, { Component } from "react";
import { Card, Icon, Row, Col, message, Popconfirm } from "antd";
import { api } from "../../../../config";
import ContactsFormModal from "./ContactsFormModal";

const style = {
  innerCard: {
    marginBottom: 20,
  },
};

class StudentContactCard extends Component {
  state = {
    student_id: null,
    contacts: [],
    contactsModal: false,
    contact_id: null,
    contact: {},
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.student_id !== prevState.id) {
      return { ...prevState, ...nextProps };
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.student_id !== this.state.student_id) {
      this.get_student_contact_cards(this.state.student_id);
    }
  }

  closeModal = () => {
    this.setState({ contactsModal: false });
    this.get_student_contact_cards(this.state.student_id);
  };

  get_student_contact_cards = (student_id) => {
    api
      .get("/student/contacts/" + student_id + "/")
      .then((res) => {
        if (res.data.success) {
          this.setState({ contacts: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  delete_contact = (id) => {
    api
      .delete("/student/contact/" + id + "/")
      .then((res) => {
        if (res.data.success) {
          message.success("Student Contact Deleted");
          this.get_student_contact_cards(this.state.student_id);
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  CardExtras = (item) => {
    return (
      <>
        <Icon
          type="edit"
          onClick={() => {
            this.setState({
              contact_id: item.id,
              contactsModal: true,
              contact: item,
            });
          }}
        />
        <Popconfirm
          title="Are you sure?"
          onConfirm={this.delete_contact.bind(this, item.id)}
        >
          <Icon type="delete" style={{ marginLeft: 10 }} />
        </Popconfirm>
      </>
    );
  };

  render() {
    const { contacts } = this.state;
    return (
      <>
        <Card
          title="Student Contacts"
          style={{ marginBottom: 20 }}
          extra={
            <Icon
              type="plus-circle"
              onClick={() => {
                this.setState({ contact_id: null, contactsModal: true });
              }}
            />
          }
        >
          <Row gutter={16}>
            {contacts.map((item, index) => {
              return (
                <Col sm={8} key={index}>
                  <Card
                    title={item.student_relation || item.contact_name}
                    style={style.innerCard}
                    extra={<this.CardExtras {...item} />}
                  >
                    <div className="contact-card">
                      <table>
                        <tbody>
                          <tr>
                            <td>Contact Name</td>
                            <td>{item.contact_name}</td>
                          </tr>
                          <tr>
                            <td>Mobile</td>
                            <td>{item.mobile}</td>
                          </tr>
                          <tr>
                            <td>Email</td>
                            <td>{item.email || " - "}</td>
                          </tr>
                          <tr>
                            <td>Address</td>
                            <td>{item.address || " - "}</td>
                          </tr>
                          <tr>
                            <td>Student Relation</td>
                            <td>{item.student_relation || " - "}</td>
                          </tr>
                          <tr>
                            <td>Enable SMS</td>
                            <td>{item.enable_sms ? "Yes" : "No"}</td>
                          </tr>
                          <tr>
                            <td>Is Emergency Contact</td>
                            <td>{item.is_emergency_contact ? "Yes" : "No"}</td>
                          </tr>
                          <tr>
                            <td>Aadhar No.</td>
                            <td>{item.extras.aadhar_no}</td>
                          </tr>
                          <tr>
                            <td>Pan No.</td>
                            <td>{item.extras.pan_no}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </Card>
        {this.state.contactsModal && (
          <ContactsFormModal
            closeModal={this.closeModal}
            student_id={this.state.student_id}
            contact_id={this.state.contact_id}
            contact={this.state.contact}
          />
        )}
      </>
    );
  }
}

export default StudentContactCard;
