import React, { Component } from "react";
import { Layout, Form, Input, Button } from "antd";

class OnlineTestForm extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    return (
      <Layout.Content>
        <h1 className="align-center">New Online Test</h1>
        <Form wrapperCol={{ sm: 8 }} labelCol={{ sm: 8 }}>
          <FormItem label="Online Test Name">
            {getFieldDecorator("name", {
              rules: [{ required: true, message: "Test name is requried!" }],
            })(<Input />)}
          </FormItem>
          <FormItem wrapperCol={{ offset: 8, sm: 8 }}>
            <Button type="primary">Save</Button>
          </FormItem>
        </Form>
      </Layout.Content>
    );
  }
}

export default Form.create()(OnlineTestForm);
