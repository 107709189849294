import React, { Component } from "react";
import {
  Layout,
  Row,
  Col,
  Button,
  Table,
  Icon,
  message,
  Popconfirm,
  Input,
} from "antd";
import { Link } from "react-router-dom";
import { api } from "../../../../config";
import { Currency, ShowBreadcrumbs } from "../../../../components";
import moment from "moment";
import Highlighter from "react-highlight-words";

class ExpensesList extends Component {
  state = {
    expenses: [],
    spinning: false,
    searchText: undefined,
    searchColumn: undefined,
  };

  componentDidMount() {
    this.get_expenses_list();
  }

  get_expenses_list = () => {
    this.setState({ spinning: true });
    api
      .get("/finance/expenses/")
      .then((res) => {
        if (res.data && res.data.success) {
          this.setState({ expenses: res.data.data });
        } else {
          message.error(res.data.error);
        }
        this.setState({ spinning: false });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ spinning: false });
      });
  };

  delete_expense = (id) => {
    api
      .delete("/finance/expense/" + id + "/")
      .then((res) => {
        if (res.data && res.data.success) {
          message.success("Expense Deleted");
          this.get_expenses_list();
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search by name`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text, r) =>
      this.state.searchedColumn === dataIndex ? (
        <Link to={`/finance/expense/${r.id}/details`}>
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        </Link>
      ) : (
        <Link to={`/finance/expense/${r.id}/details`}>{text}</Link>
      ),
  });

  render() {
    const columns = [
      { title: "ID", dataIndex: "id" },
      {
        title: "Name",
        dataIndex: "name",
        ...this.getColumnSearchProps("name"),
      },
      { title: "Vendor", dataIndex: "vendor" },
      { title: "Category", dataIndex: "category" },
      {
        title: "Total Amount",
        dataIndex: "total_amount",
        align: "right",
        render: (v) => <Currency value={v} />,
      },
      {
        title: "Paid Amount",
        dataIndex: "paid_amount",
        align: "right",
        render: (v) => <Currency value={v} />,
      },
      {
        title: "Due Amount",
        align: "right",
        render: (r) => <Currency value={r.total_amount - r.paid_amount} />,
      },
      {
        title: "Date",
        dataIndex: "date",
        render: (v) => (
          <span>{moment.utc(v).local().format("YYYY-MM-DD")}</span>
        ),
      },
      {
        title: "Actions",
        render: (record) => (
          <span>
            <Icon
              type="edit"
              className="action-icon"
              onClick={() => {
                this.props.history.push("/finance/expense/" + record.id + "/");
              }}
            />
            <Popconfirm
              title="Are you sure?"
              onConfirm={this.delete_expense.bind(this, record.id)}
            >
              <Icon type="delete" className="action-icon absent" />
            </Popconfirm>
          </span>
        ),
      },
    ];
    return (
      <Layout.Content>
        <ShowBreadcrumbs
          items={[
            { name: "Home", link: "/" },
            { name: "Finance", link: "/finance/" },
            { name: "Expenses", link: "/finance/expenses/" },
          ]}
        />
        <Row gutter={16}>
          <Col sm={12}>
            <h1>Expenses</h1>
          </Col>
          <Col sm={12} style={{ textAlign: "right" }}>
            <Link to="/finance/expense/reports/">
              <Button icon="pie-chart" type="primary" ghost>
                Reports
              </Button>
            </Link>
            <Link to="/finance/payment-modes/">
              <Button icon="plus" type="primary" style={{ marginLeft: 10 }}>
                Payment Modes
              </Button>
            </Link>
            <Link to="/finance/expenses/category">
              <Button icon="plus" type="primary" style={{ marginLeft: 10 }}>
                Expense Categories
              </Button>
            </Link>
            <Link to="/finance/expenses/new">
              <Button icon="plus" type="primary" style={{ marginLeft: 10 }}>
                Add Expense
              </Button>
            </Link>
          </Col>
        </Row>
        <Table
          columns={columns}
          dataSource={this.state.expenses}
          loading={this.state.spinning}
          rowKey="id"
          expandedRowRender={(r, i) => <span>{r.description || " - "}</span>}
        />
      </Layout.Content>
    );
  }
}

export default ExpensesList;
