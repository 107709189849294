import React, { Component } from "react";
import { Select } from "antd";
import { api } from "../config";

class BusRouteStopDropdown extends Component {
  state = {
    bus_routes: [],
    route_id: null,
  };

  componentDidMount() {
    if (this.props.route_id) {
      this.get_bus_routes_list(this.props.route_id);
    } else if (this.props.direction) {
      this.get_bus_routes_list_by_direction(this.props.direction);
    } else {
      this.get_bus_routes_list();
    }
  }

  get_bus_routes_list = (id) => {
    let url = "/bus/routes/stops/";
    url = id ? url + id + "/" : url;
    api
      .get(url)
      .then((res) => {
        if (res.data && res.data.success) {
          this.setState({ bus_routes: res.data.data });
        }
      })
      .catch((err) => {});
  };

  get_bus_routes_list_by_direction = (direction) => {
    let url = "/bus/route/stops/" + direction + "/";
    api
      .get(url)
      .then((res) => {
        if (res.data && res.data.success) {
          this.setState({ bus_routes: res.data.data });
        }
      })
      .catch((err) => {});
  };

  handleChange = (id) => {
    this.props.onChange && this.props.onChange(id);
    if (this.props.getItem) {
      let item = this.state.bus_routes.find((item) => item.id === id);
      this.props.getItem(item);
    }
  };

  render() {
    const Option = Select.Option;
    return (
      <Select
        placeholder={this.props.placeholder || "Bus Stop"}
        showSearch
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        value={this.props.value}
        onChange={this.handleChange}
        style={this.props.style}
        allowClear={this.props.allowClear || true}
      >
        {this.state.bus_routes.map((item, index) => {
          return (
            <Option value={item.id} key={index}>
              {item.route + " - " + item.bus_stop}
            </Option>
          );
        })}
      </Select>
    );
  }
}

export default BusRouteStopDropdown;
