import React, { Component } from "react";
import {
  Layout,
  Descriptions,
  Card,
  Select,
  Row,
  Col,
  Button,
  message,
  Spin,
} from "antd";
import moment from "moment";
import { api } from "../../../../config";

class ApplicationViewer extends Component {
  state = {
    data: {},
    payment_status: null,
    application_status: null,
    id: null,
    spinning: false,
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      this.get_application_details(this.props.match.params.id);
    }
  }

  get_application_details = (id) => {
    this.setState({ spinning: true });
    api
      .get("/admissions/application/" + id + "/")
      .then((res) => {
        if (res.data.success) {
          let data = res.data.data;
          this.setState({
            data,
            application_status: data.status,
            payment_status: data.payment_status,
          });
        } else {
          message.error(res.data.error);
        }
        this.setState({ spinning: false });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ spinning: false });
      });
  };

  handleSave = () => {
    let { data, payment_status, application_status } = this.state;
    let formData = {
      application_id: data.id,
      payment_status,
      application_status,
    };
    api
      .patch("/admissions/application/status/", JSON.stringify(formData))
      .then((res) => {
        if (res.data.success) {
          message.success("Status Updated");
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const { data, payment_status, application_status } = this.state;
    const { Item } = Descriptions;
    const { Option } = Select;
    return (
      <Layout.Content>
        <Row>
          <Col sm={20}>
            <h1>Admission Application</h1>
          </Col>
          <Col sm={4} style={{ textAlign: "right" }}>
            <Button type="primary" onClick={this.handleSave}>
              Save
            </Button>
          </Col>
        </Row>
        <Spin spinning={this.state.spinning}>
          {data.student_name && (
            <Card>
              <Descriptions title="Status" bordered>
                <Item label="Application ID">{data.id}</Item>
                <Item label="Payment Status">
                  <Select
                    style={{ minWidth: 150 }}
                    placeholder="Payment Status"
                    value={payment_status}
                    disabled={data.fee_payment.id ? true : false}
                    onChange={(val) => {
                      this.setState({ payment_status: val });
                    }}
                  >
                    <Option value={-1}>Not Yet Received</Option>
                    <Option value={0}>Unknown</Option>
                    <Option value={1}>Paid</Option>
                  </Select>
                </Item>
                <Item label="Application Status">
                  <Select
                    style={{ minWidth: 150 }}
                    placeholder="Application Status"
                    value={application_status}
                    onChange={(val) => {
                      this.setState({ application_status: val });
                    }}
                  >
                    <Option value={-2}>Withdrawn (by candidate)</Option>
                    <Option value={-1}>Rejected (by institute)</Option>
                    <Option value={0}>Unknown</Option>
                    <Option value={1}>Waitlist (by candidate)</Option>
                    <Option value={2}>Waitlist (by institute)</Option>
                    <Option value={3}>Selected</Option>
                  </Select>
                </Item>
                <Item label="Applied on">
                  {moment.utc(data.timestamp).local().toString()}
                </Item>
              </Descriptions>
              <Descriptions
                title="Student Details"
                bordered
                style={{ marginTop: 20 }}
              >
                <Item label="Student Name">{data.student_name}</Item>
                <Item label="Class Applying To">{data.application_class}</Item>
                <Item label="Student Photo">
                  <img
                    src={data.student_photo.url}
                    alt="Student Pic"
                    style={{
                      width: 120,
                      height: 160,
                      border: "1px dashed grey",
                      //   padding: "0 10px 100%",
                      padding: 10,
                    }}
                  />
                </Item>
                <Item label="Date of Birth">
                  {data.details.student.dob
                    ? moment(data.details.student.dob).format("YYYY-MM-DD")
                    : "-"}
                </Item>
                <Item label="Gender">{data.details.student.gender || "-"}</Item>
              </Descriptions>
              <Descriptions
                title="Parent/Guardian Details"
                bordered
                style={{ marginTop: 20 }}
              >
                <Item label="Name">{data.parent_name}</Item>
                <Item label="Mobile">{data.mobile}</Item>
                <Item label="Email">{data.email}</Item>
                <Item label="Address">
                  {data.details.guardian.address || "-"}
                </Item>
              </Descriptions>
              <Descriptions
                title="Father's Details"
                bordered
                style={{ marginTop: 20 }}
              >
                <Item label="Father's Name">
                  {data.details.father.name || "-"}
                </Item>
                <Item label="Father's Mobile">
                  {data.details.father.mobile || "-"}
                </Item>
                <Item label="Father's Profession">
                  {data.details.father.profession || "-"}
                </Item>
                <Item label="Father's Salary">
                  {data.details.father.salary || "-"}
                </Item>
              </Descriptions>
              <Descriptions
                title="Mother's Details"
                bordered
                style={{ marginTop: 20 }}
              >
                <Item label="Mother's Name">
                  {data.details.mother.name || "-"}
                </Item>
                <Item label="Mother's Mobile">
                  {data.details.mother.mobile || "-"}
                </Item>
                <Item label="Mother's Profession">
                  {data.details.mother.profession || "-"}
                </Item>
                <Item label="Mother's Salary">
                  {data.details.mother.salary || "-"}
                </Item>
              </Descriptions>
              <Descriptions
                title="Previous School/College"
                bordered
                style={{ marginTop: 20 }}
              >
                <Item label="Name">{data.details.prev_school.name || "-"}</Item>
                <Item label="City">{data.details.prev_school.city || "-"}</Item>
                <Item label="Percent/CGPA">
                  {data.details.prev_school.marks || "-"}
                </Item>
                <Item label="Previous Marks Card">
                  {(data.details.prev_school.marks_card &&
                    data.details.prev_school.marks_card.map((item, index) => {
                      return (
                        <span key={index}>
                          <a
                            href={item.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {item.name}
                          </a>
                          ,{" "}
                        </span>
                      );
                    })) ||
                    "-"}
                </Item>
              </Descriptions>
              {data.fee_payment.id && (
                <Descriptions
                  title="Payment Details"
                  bordered
                  style={{ marginTop: 20 }}
                >
                  <Item label="Payment Mode">
                    {data.fee_payment.payment_mode}
                  </Item>
                  <Item label="Order Id">{data.fee_payment.order_id}</Item>
                  <Item label="Timestamp">{data.fee_payment.timestamp}</Item>
                  <Item label="Total Amount">
                    {data.fee_payment.currency +
                      " " +
                      data.fee_payment.total_amount}
                  </Item>
                  {/* <Item label="Service Charges">
                    {data.fee_payment.currency +
                      " " +
                      data.fee_payment.service_charge}
                  </Item> */}
                </Descriptions>
              )}
            </Card>
          )}
        </Spin>
      </Layout.Content>
    );
  }
}

export default ApplicationViewer;
