import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Layout,
  message,
  Select,
  Upload,
} from "antd";
import React, { Component } from "react";
import { api, baseurl, getToken } from "../../../../config";
import moment from "moment";

class AccountEntryForm extends Component {
  state = {
    id: undefined,
    entry: {},
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      this.setState({ id: this.props.match.params.id });
      this._get_account_entry(this.props.match.params.id);
    }
  }

  _get_account_entry = (id) => {
    api
      .get(`/simple/account/${id}/`)
      .then((res) => {
        if (res.data.success) {
          let data = res.data.data;
          this.setState({ entry: data });
          data.date = moment(data.date);
          data.files = JSON.parse(data.files);
          this.props.form.setFieldsValue(data);
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  _handleSubmit = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        let { id } = this.state;
        if (id) {
          this._update_entry(id, val);
        } else {
          this._add_new_entry(val);
        }
      }
    });
  };

  _add_new_entry = (val) => {
    val.files =
      val.files &&
      val.files.map((item, index) => {
        return {
          name: item.name,
          size: item.size,
          uid: new Date().getTime(),
          url: baseurl + "/" + item.response.path,
          path: item.response.path,
          type: item.type,
        };
      });
    api
      .put("/simple/accounts/", JSON.stringify(val))
      .then((res) => {
        if (res.data.success) {
          message.success("New transaction added");
          this.props.history.push("/accounts/");
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  _update_entry = (id, val) => {
    api
      .post(`/simple/account/${id}/`, JSON.stringify(val))
      .then((res) => {
        if (res.data.success) {
          let data = res.data.data;
          message.success("Entry updated");
          this.props.history.replace(`/accounts/${data.id}/`);
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  render() {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    const upload_props = {
      name: "file",
      action: baseurl + "/upload/",
      headers: {
        Authorization: getToken(),
      },
      multiple: true,
      accept: ".jpg,.jpeg,.png,.pdf,.docx,.doc,.odt",
    };
    return (
      <Layout.Content>
        <h1 className="align-center">New Account Entry</h1>
        <Form wrapperCol={{ sm: 8 }} labelCol={{ sm: 8 }}>
          <FormItem label="Title">
            {getFieldDecorator("title", {
              rules: [{ required: true, message: "Title is required!" }],
            })(<Input />)}
          </FormItem>
          <FormItem label="Description">
            {getFieldDecorator("description")(<Input.TextArea />)}
          </FormItem>
          <FormItem label="Transaction Type">
            {getFieldDecorator("txn_type", {
              rules: [
                { required: true, message: "Transaction type is required!" },
              ],
              initialValue: 0,
            })(
              <Select>
                <Select.Option value={0}>Credit</Select.Option>
                <Select.Option value={1}>Debit</Select.Option>
              </Select>
            )}
          </FormItem>
          <FormItem label="Amount">
            {getFieldDecorator("amount", {
              rules: [{ required: true, message: "Amount is requried!" }],
            })(<InputNumber />)}
          </FormItem>
          <FormItem label="Date">
            {getFieldDecorator("date", {
              rules: [{ required: true, message: "Date is requried!" }],
            })(<DatePicker />)}
          </FormItem>
          <FormItem label="Files">
            {getFieldDecorator("files", {
              valuePropName: "fileList",
              getValueFromEvent: this.normFile,
            })(
              <Upload {...upload_props}>
                <Button icon="upload">Upload</Button>
              </Upload>
            )}
          </FormItem>
          <FormItem wrapperCol={{ offset: 8 }}>
            <Button type="primary" onClick={this._handleSubmit}>
              Save
            </Button>
          </FormItem>
        </Form>
      </Layout.Content>
    );
  }
}

export default Form.create()(AccountEntryForm);
