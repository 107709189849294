import React, { Component } from "react";
import { Select } from "antd";
import { api } from "../config";

class AccountsDropdown extends Component {
  state = {
    accounts: [],
  };

  componentDidMount() {
    this.get_accounts_list();
  }

  get_accounts_list = () => {
    api
      .get("/finance/accounts/")
      .then((res) => {
        if (res.data && res.data.success) {
          this.setState({ accounts: res.data.data });
        }
      })
      .catch((err) => {});
  };

  __onChange = (id) => {
    if (this.props.account) {
      let account = this.state.accounts.find((val, index, array) => {
        return val.id === id;
      });
      this.props.account(account);
    }
    this.props.onChange(id);
  };

  render() {
    const Option = Select.Option;
    return (
      <Select
        placeholder="Account"
        showSearch
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        onChange={this.__onChange}
        value={this.props.value}
        style={this.props.style}
        allowClear={this.props.allowClear || true}
      >
        {this.state.accounts.map((item, index) => {
          return (
            <Option value={item.id} key={index}>
              {item.name}
            </Option>
          );
        })}
      </Select>
    );
  }
}

export default AccountsDropdown;
