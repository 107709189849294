import React, { useState, useEffect } from "react";
import { Select } from "antd";
import { api } from "../config";

export default function LeaveTypeDropdown(props) {
  const [leaveTypes, setLeaveTypes] = useState([]);

  useEffect(() => {
    api
      .get("/hr/leave-types/")
      .then((res) => {
        if (res.data.success) {
          setLeaveTypes(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <Select placeholder="Leave Type" allowClear {...props}>
      {leaveTypes.map((item, index) => {
        return (
          <Select.Option value={item.id} key={index}>
            {item.name}
          </Select.Option>
        );
      })}
    </Select>
  );
}
