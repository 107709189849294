import React, { Component } from "react";
import { Select } from "antd";
import { api } from "../config";

class BranchSelectionDropdown extends Component {
  state = {
    branches: []
  };

  componentDidMount() {
    this.get_branches_list();
  }

  get_branches_list = () => {
    api
      .get("/branches/")
      .then(res => {
        if (res.data.success) {
          this.setState({ branches: res.data.data });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  render() {
    return (
      <Select
        placeholder="Branches"
        showSearch
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        onChange={this.props.onChange}
        value={this.props.value}
        style={this.props.style}
        allowClear={this.props.allowClear || true}
      >
        {this.state.branches.map((item, index) => {
          return (
            <Select.Option value={item.id} key={index}>
              {item.name} - {item.code}
            </Select.Option>
          );
        })}
      </Select>
    );
  }
}

export default BranchSelectionDropdown;
