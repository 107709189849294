import React from "react";
import { Switch, Route } from "react-router-dom";
import EmployeeAttendanceList from "./EmployeeAttendanceList";
import UpdateEmployeeAttendance from "./UpdateEmployeeAttendance";

const EmployeeAttendanceRouter = () => {
  return (
    <Switch>
      <Route
        exact
        path="/employee/attendance"
        component={EmployeeAttendanceList}
      />
      <Route
        exact
        path="/employee/attendance/update"
        component={UpdateEmployeeAttendance}
      />
    </Switch>
  );
};

export { EmployeeAttendanceRouter };
