import React, { Component } from "react";
import { Row, Col, Button, Table, message, Icon, Popconfirm, Spin } from "antd";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { api } from "../../../config";
import ChangePasswordModal from "./ChangePasswordModal";

class TeachersList extends Component {
  state = {
    teachers: [],
    spinning: false,
    show_password_reset: false,
    teacher: {},
  };
  componentDidMount() {
    this.get_teachers();
  }

  get_teachers = () => {
    this.setState({ spinning: true });
    api
      .get("/teachers/")
      .then((res) => {
        if (res.data && res.data.success) {
          this.props.get_teachers(res.data.data);
          this.setState({ teachers: res.data.data });
        } else {
          message.error(res.data.error);
        }
        this.setState({ spinning: false });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ spinning: false });
      });
  };

  handleDelete = (record) => {
    api
      .delete("/teacher/" + record.id + "/")
      .then((res) => {
        if (res.data && res.data.success) {
          message.success(record.fullname + " is removed from teachers");
          this.get_teachers();
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  closeModal = () => {
    this.setState({ show_password_reset: false, teacher: {} });
  };

  render() {
    const columns = [
      {
        title: "Name",
        key: "fullname",
        dataIndex: "fullname",
      },
      {
        title: "Employee ID",
        dataIndex: "employee_id",
      },
      {
        title: "Job Title",
        dataIndex: "job_title",
      },
      {
        title: "Email",
        key: "email",
        dataIndex: "email",
      },
      {
        title: "Mobile",
        key: "mobile",
        dataIndex: "mobile",
      },
      {
        title: "Actions",
        render: (record) => (
          <span>
            <Icon
              type="lock"
              className="action-icon absent"
              title="Change Password"
              onClick={() => {
                this.setState({ show_password_reset: true, teacher: record });
              }}
            />
            <Icon
              type="edit"
              className="action-icon"
              onClick={() => {
                this.props.history.push("/teachers/" + record.id);
              }}
            />
            <Popconfirm
              title="Are you sure?"
              onConfirm={this.handleDelete.bind(this, record)}
            >
              <Icon type="delete" className="action-icon" />
            </Popconfirm>
          </span>
        ),
      },
    ];
    const { show_password_reset, teacher } = this.state;
    return (
      <div>
        <Row className="ribbon-row">
          <Col sm={12}>
            <h1>Teachers List</h1>
          </Col>
          <Col sm={12} className="action-buttons">
            <Link to="/teachers/new/">
              <Button type="primary" icon="user-add" className="action-button">
                Add Teacher
              </Button>
            </Link>
          </Col>
        </Row>
        <Spin spinning={this.state.spinning}>
          <Table
            columns={columns}
            dataSource={this.state.teachers}
            rowKey="id"
          />
        </Spin>
        {show_password_reset && (
          <ChangePasswordModal teacher={teacher} closeModal={this.closeModal} />
        )}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    get_teachers: (payload) => {
      dispatch({ type: "GET_TEACHERS", payload });
    },
  };
}

export default connect(null, mapDispatchToProps)(TeachersList);
