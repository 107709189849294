import React from "react";
import { Switch, Route } from "react-router-dom";
import MarksEditor from "../../admin/marks/MarksEditor";
import MarksViewer from "../../admin/marks/MarksViewer";

const TeacherMarksRouter = props => (
  <Switch>
    <Route exact path="/marks/" component={MarksViewer} />
    <Route exact path="/marks/update" component={MarksEditor} />
  </Switch>
);

export { TeacherMarksRouter };
