import { Card, Descriptions, Layout, message, Tag } from "antd";
import React, { Component } from "react";
import { api } from "../../../config";

class QuestionViewer extends Component {
  state = {
    id: undefined,
    question: {},
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      this.setState(
        { id: this.props.match.params.id },
        this.__get_question_details
      );
    } else {
      message.error("Invalid Link");
    }
  }

  __get_question_details = () => {
    let { id } = this.state;
    api
      .get(`/ot/question/${id}/`)
      .then((res) => {
        if (res.data.success) {
          this.setState({ question: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  __get_question_type = (type) => {
    switch (type) {
      case 0:
        return { text: "Multiple Choice (Sigle Correct Answer)" };
      case 1:
        return { text: "Multiple Choice (Multiple Correct Answer)" };
      case 2:
        return { text: "Subjective (Text Entry)" };
      case 3:
        return { text: "Media (Student uploads Image/Audio/Video)" };
      default:
        return { text: "Unknown" };
    }
  };

  render() {
    const { Item } = Descriptions;
    const { question } = this.state;
    const qfiles = question.files ? JSON.parse(question.files) : [];
    const qtype = this.__get_question_type(question.type);
    return (
      <Layout.Content>
        <h1>Question Viewer</h1>
        <Card title="Question" style={{ marginBottom: 16 }}>
          <Descriptions bordered>
            <Item label="Class">{question.class}</Item>
            <Item label="Subject">{question.subject}</Item>
            <Item label="Topic">{question.topic || " - "}</Item>
            <Item label="Question" span={3}>
              {question.question}
            </Item>
            <Item label="Description" span={3}>
              {question.description || " - "}
            </Item>
            <Item label="Correct Marks">{question.correct_marks}</Item>
            <Item label="Incorrect Marks">{question.incorrect_marks}</Item>
            <Item label="No Answer Marks">{question.no_answer_marks}</Item>
            <Item label="Files" span={3}>
              {qfiles.length > 0
                ? qfiles.map((item, index) => {
                    return (
                      <p key={index} style={{ margin: 0 }}>
                        <a
                          href={item.url}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          {item.name}
                        </a>
                      </p>
                    );
                  })
                : " - "}
            </Item>
            <Item label="Question Type">{qtype.text}</Item>
          </Descriptions>
        </Card>
        {question.options && (
          <Card title="Options">
            {question.options.map((item, index) => {
              let files = item.files ? JSON.parse(item.files) : [];
              return (
                <Card key={index} style={{ marginBottom: 16 }}>
                  <Descriptions title={`Option ${index + 1}`}>
                    <Item label="Text" span={2}>
                      {item.text}
                    </Item>
                    <Item label="Answer">
                      <Tag color={item.is_correct === 0 ? "red" : "green"}>
                        {item.is_correct === 0 ? "Incorrect" : "Correct"}
                      </Tag>
                    </Item>
                    <Item label="Explanation" span={3}>
                      {item.expanation || " - "}
                    </Item>
                    {files.length > 0 && (
                      <Item label="Files" span={3}>
                        {files.map((file, ind) => {
                          return (
                            <p key={ind}>
                              <a
                                href={file.url}
                                rel="noopener noreferrer"
                                target="_blank"
                              >
                                {file.name}
                              </a>
                            </p>
                          );
                        })}
                      </Item>
                    )}
                  </Descriptions>
                </Card>
              );
            })}
          </Card>
        )}
      </Layout.Content>
    );
  }
}

export default QuestionViewer;
