import React, { Component } from "react";
import { Layout, Row, Col, Form, Input, Button, message, Result } from "antd";
import Axios from "axios";

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      params: {},
      baseurl: undefined,
      invalid_link: false,
    };
  }

  componentDidMount() {
    let params = this.getQueryStringParams(this.props.location.search);
    this.setState({ params }, this.set_base_url);
  }

  set_base_url = () => {
    let { sch_code } = this.state.params;
    let url =
      "https://ambegaalu.in/eduga/schools_list.json?nocache=" +
      new Date().getTime();
    Axios.get(url)
      .then((res) => {
        let school = res.data.schools.find((item) => {
          return item.code.toUpperCase() === sch_code.toUpperCase();
        });
        if (!school) {
          message.error("Invalid Password reset link");
          this.setState({ invalid_link: true });
        } else {
          this.setState({ baseurl: school.api });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getQueryStringParams = (query) => {
    return query
      ? (/^[?#]/.test(query) ? query.slice(1) : query)
          .split("&")
          .reduce((params, param) => {
            let [key, value] = param.split("=");
            params[key] = value
              ? decodeURIComponent(value.replace(/\+/g, " "))
              : "";
            return params;
          }, {})
      : {};
  };

  handleSubmit = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        if (val.password !== val.confirm_password) {
          this.props.form.setFields({
            confirm_password: {
              value: val.confirm_password,
              errors: [new Error("Password and Confirm password must be same")],
            },
          });
        } else {
          const { params, baseurl } = this.state;
          let data = {
            user_id: params.user,
            password: val.password,
            email: val.email,
            reset_key: params.key,
          };
          Axios.post(`${baseurl}/reset-password/`, JSON.stringify(data), {
            headers: { "Content-Type": "application/json" },
          })
            .then((res) => {
              if (res.data && res.data.success) {
                this.setState({ submitted: true });
              } else {
                message.error(res.data.error);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    });
  };

  render() {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    let { submitted, invalid_link } = this.state;
    return (
      <Layout.Content>
        {!invalid_link ? (
          <Row>
            {submitted === false ? (
              <Col sm={24} offset={0}>
                <h1 style={{ textAlign: "center" }}>Reset Password</h1>
                <Form labelCol={{ sm: 8 }} wrapperCol={{ sm: 8 }}>
                  <FormItem label="Email">
                    {getFieldDecorator("email", {
                      rules: [
                        { required: true, message: "Email is required!" },
                        {
                          type: "email",
                          message: "Please enter valid email address",
                        },
                      ],
                      validateTrigger: ["onBlur"],
                    })(<Input type="email" placeholder="Email" />)}
                  </FormItem>
                  <FormItem label="New Password">
                    {getFieldDecorator("password", {
                      rules: [
                        {
                          required: true,
                          message: "New password is required!",
                        },
                        {
                          min: 8,
                          message: "Password must be atleast 8 characters",
                        },
                      ],
                      validateTrigger: ["onBlur"],
                    })(<Input type="password" placeholder="New Password" />)}
                  </FormItem>
                  <FormItem label="Confirm Password">
                    {getFieldDecorator("confirm_password", {
                      rules: [
                        {
                          required: true,
                          message: "Confirm password is required!",
                        },
                        {
                          min: 8,
                          message: "Password must be atleast 8 characters",
                        },
                      ],
                      validateTrigger: ["onBlur"],
                    })(
                      <Input type="password" placeholder="Confirm Password" />
                    )}
                  </FormItem>
                  <FormItem wrapperCol={{ offset: 8 }}>
                    <Button onClick={this.handleSubmit}>Reset Password</Button>
                  </FormItem>
                </Form>
              </Col>
            ) : (
              <Col sm={8} offset={8}>
                <h3>Your password has been reset. Please login now.</h3>
                <div className="align-center">
                  <Button
                    type="primary"
                    onClick={() => this.props.history.push("/")}
                  >
                    Back Home
                  </Button>
                </div>
              </Col>
            )}
          </Row>
        ) : (
          <Result
            status="error"
            title="Invalid Password Reset Link"
            extra={
              <Button
                type="primary"
                onClick={() => {
                  this.props.history.push("/");
                }}
              >
                Back Home
              </Button>
            }
          />
        )}
      </Layout.Content>
    );
  }
}

export default Form.create()(ResetPassword);
