import React from "react";
import { Switch, Route } from "react-router";
import OnlineTestList from "../../admin/online_tests/OnlineTestList";
import OnlineTestQuestions from "../../admin/online_tests/OnlineTestQuestions";
import OnlineTestAddQuestions from "../../admin/online_tests/OnlineTestAddQuestions";
import OnlineTestAssignments from "../../admin/online_tests/OnlineTestAssignments";
import OnlineTestAttempts from "../../admin/online_tests/OnlineTestAttempts";
import OnlineTestAnswers from "../../admin/online_tests/OnlineTestAnswers";
import Questions from "../../admin/online_tests/Questions";
import QuestionForm from "../../admin/online_tests/QuestionForm";
import QuestionViewer from "../../admin/online_tests/QuestionViewer";

const TeacherOnlineTestRouter = (props) => (
  <Switch>
    <Route exact path="/online-test/" component={OnlineTestList} />
    <Route
      exact
      path="/online-test/:test_id/questions"
      component={OnlineTestQuestions}
    />
    <Route
      exact
      path="/online-test/:test_id/questions/add"
      component={OnlineTestAddQuestions}
    />
    <Route
      exact
      path="/online-test/:test_id/assign/"
      component={OnlineTestAssignments}
    />
    <Route
      exact
      path="/online-test/:test_id/attempts/:test_assign_id/"
      component={OnlineTestAttempts}
    />
    <Route
      exact
      path="/online-test/:test_id/attempt/:test_attempt_id/answers"
      component={OnlineTestAnswers}
    />
    <Route exact path="/online-test/questions/" component={Questions} />
    <Route exact path="/online-test/question/new" component={QuestionForm} />
    <Route exact path="/online-test/question/:id" component={QuestionViewer} />
  </Switch>
);

export { TeacherOnlineTestRouter };
