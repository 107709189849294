import React, { Component } from "react";
import { Layout, Form, Steps } from "antd";

import AddUserForm from "./AddUser";
import NewStudentDetails from "./NewStudentDetails";
import NewStudentProfile from "./NewStudentProfile";

class AddStudent extends Component {
  state = {
    current: 0,
    user_id: null,
    data: {}
  };

  gotoStudentDetails = user_id => {
    this.setState({ current: 1, user_id });
  };

  gotoStudentProfile = data => {
    if (data) {
      this.setState({ current: 2, data });
    }
  };

  render() {
    const Step = Steps.Step;
    const current = this.state.current;
    return (
      <Layout.Content className="add-student">
        <h1>Add Student</h1>
        <Steps current={current} style={{ width: "80%", margin: "24px auto" }}>
          <Step title="Add/Select User" />
          <Step title="Student Details" />
          <Step title="Student Profile" />
        </Steps>
        <div className="steps-content">
          {current === 0 && (
            <AddUserForm {...this.props} nextScreen={this.gotoStudentDetails} />
          )}
          {current === 1 && (
            <NewStudentDetails
              {...this.props}
              nextScreen={this.gotoStudentProfile}
              user_id={this.state.user_id}
            />
          )}
          {current === 2 && (
            <NewStudentProfile {...this.props} data={this.state.data} />
          )}
        </div>
      </Layout.Content>
    );
  }
}

export default Form.create()(AddStudent);
