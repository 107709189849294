import React, { Component } from "react";
import {
  Layout,
  Row,
  Col,
  Button,
  Table,
  message,
  Icon,
  Popconfirm,
} from "antd";
import { Link } from "react-router-dom";
import { api } from "../../../config";
import {
  HomeworkViewer,
  ClassSectionDropdown,
  SubjectSelectionDropdown,
} from "../../../components";
import { connect } from "react-redux";

var moment = require("moment");

let state = {
  homeworks: [],
  spinning: false,
  hwModal: false,
  homework: {},
  class_id: null,
  subject_id: null,
  page: undefined,
};

class HomeworkList extends Component {
  constructor(props) {
    super(props);
    this.state = state;
  }

  componentDidMount() {
    if (this.state.homeworks.length > 0) {
    } else if (this.state.subject_id) {
      this.get_homeworks_list(this.state.subject_id);
    } else {
      this.get_homeworks_list();
    }
  }

  componentWillUnmount() {
    state = this.state;
  }

  closeModal = () => {
    this.setState({ hwModal: false });
  };

  get_homeworks_list = (subject_id) => {
    this.setState({ spinning: true });
    let url = "/homework" + (subject_id ? "/subject/" + subject_id + "/" : "/");
    api
      .get(url)
      .then((res) => {
        if (res.data && res.data.success) {
          this.handleHomeworkFilter(res.data.data);
        } else {
          message.error(res.data.error);
        }
        this.setState({ spinning: false });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ spinning: false });
      });
  };

  handleHomeworkFilter = (hw_list) => {
    let { user, teacher_settings } = this.props;
    if (
      user.role === 2 &&
      teacher_settings &&
      teacher_settings.teacher_access_mode === "restricted"
    ) {
      let hw = hw_list.filter((item) => {
        return item.user_id === user.id;
      });
      this.setState({ homeworks: hw });
    } else {
      this.setState({ homeworks: hw_list });
    }
  };

  delete_homework = (id) => {
    api
      .delete("/homework/" + id + "/")
      .then((res) => {
        if (res.data && res.data.success) {
          message.success("Homework deleted");
          this.get_homeworks_list();
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const { class_id, subject_id, page } = this.state;
    const columns = [
      { title: "ID", dataIndex: "id" },
      {
        title: "Title",
        dataIndex: "title",
        render: (v, r) => (
          <Link to={{ pathname: "/homework/" + r.id }}>{v}</Link>
        ),
      },
      {
        title: "Class",
        render: (record) => <span>{record.class + " " + record.section}</span>,
      },
      { title: "Subject", dataIndex: "subject" },
      {
        title: "Date",
        render: (record) => (
          <span>{moment(record.timestamp).format("DD/MM/YYYY")}</span>
        ),
      },
      {
        title: "Actions",
        render: (record) => (
          <span>
            <Icon
              type="read"
              className="action-icon"
              onClick={() => {
                this.setState({ hwModal: true, homework: record });
              }}
            />
            <Popconfirm
              title="Are you sure?"
              onConfirm={this.delete_homework.bind(this, record.id)}
            >
              <Icon type="delete" className="action-icon absent" />
            </Popconfirm>
          </span>
        ),
      },
    ];
    return (
      <Layout.Content>
        <Row gutter={32}>
          <Col sm={6}>
            <h1>Homework List</h1>
          </Col>
          <Col sm={12}>
            <span>
              <strong>Class : </strong>
              <ClassSectionDropdown
                value={class_id}
                onChange={(class_id) => this.setState({ class_id })}
              />
            </span>
            <span>
              <strong>Subject : </strong>
              <SubjectSelectionDropdown
                class={class_id}
                value={subject_id}
                onChange={(subject_id) => {
                  this.setState({ subject_id });
                  this.get_homeworks_list(subject_id);
                }}
              />
            </span>
          </Col>
          <Col sm={4} style={{ textAlign: "right" }}>
            <Link to="/homework/new/">
              <Button type="primary" icon="plus">
                Add Home Work
              </Button>
            </Link>
          </Col>
        </Row>
        <Table
          columns={columns}
          dataSource={this.state.homeworks}
          rowKey="id"
          pagination={{ pageSize: 50, current: page }}
          onChange={(pagination) => this.setState({ page: pagination.current })}
        />
        {this.state.hwModal && (
          <HomeworkViewer
            visible={this.state.hwModal}
            closeModal={this.closeModal}
            homework={this.state.homework}
          />
        )}
      </Layout.Content>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    teacher_settings: state.teacher_settings,
  };
}

export default connect(mapStateToProps, null)(HomeworkList);
