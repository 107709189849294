import React, { Component } from "react";
import {
  Layout,
  Row,
  Col,
  Table,
  Checkbox,
  message,
  Form,
  Input,
  Button,
} from "antd";
import {
  ClassSectionDropdown,
  AcademicYearDropdown,
} from "../../../components";
import { api } from "../../../config";

class VirtualClassroomAccess extends Component {
  state = { class_id: null, students: [], ac_year_id: null };

  get_students_list = (class_id, ac_year_id) => {
    // let { class_id, ac_year_id } = this.state;
    let url =
      "/settings/student/modules/virtual_classroom/" +
      class_id +
      "/" +
      ac_year_id +
      "/";
    api
      .get(url)
      .then((res) => {
        if (res.data.success) {
          this.setState({ students: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleClassChange = (class_id) => {
    if (this.state.ac_year_id) {
      this.get_students_list(class_id, this.state.ac_year_id);
    }
    this.setState({ class_id });
  };

  handleACYearChange = (ac_year_id) => {
    if (this.state.class_id) {
      this.get_students_list(this.state.class_id, ac_year_id);
    }
    this.setState({ ac_year_id });
  };

  handleSave = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        let { class_id, ac_year_id } = this.state;
        api
          .post(
            "/settings/student/modules/" + class_id + "/",
            JSON.stringify(val)
          )
          .then((res) => {
            if (res.data.success) {
              message.success("Access List Updated");
              this.get_students_list(class_id, ac_year_id);
            } else {
              message.error(res.data.error);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  render() {
    const { class_id, students, ac_year_id } = this.state;
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    const columns = [
      { title: "ID", dataIndex: "id" },
      { title: "Student Name", dataIndex: "fullname" },
      {
        title: "Access Enabled",
        dataIndex: "module_status",
        render: (v, r, i) => (
          <span>
            <FormItem style={{ marginBottom: 0 }}>
              {getFieldDecorator(`students[${i}].student_id`, {
                initialValue: r.id,
              })(<Input hidden={true} />)}
            </FormItem>
            <FormItem style={{ marginBottom: 0 }}>
              {getFieldDecorator(`students[${i}].module_name`, {
                initialValue: "virtual_classroom",
              })(<Input hidden={true} />)}
            </FormItem>
            <FormItem style={{ marginBottom: 0 }}>
              {getFieldDecorator(`students[${i}].module_status`, {
                initialValue: v === 1 ? true : false,
                valuePropName: "checked",
              })(<Checkbox />)}
            </FormItem>
          </span>
        ),
      },
    ];
    return (
      <Layout.Content>
        <Row>
          <Col sm={12}>
            <h1>Virutal Classroom Student Access</h1>
          </Col>
          <Col sm={12} style={{ textAlign: "right" }}>
            <span>
              <strong>Class : </strong>
              <ClassSectionDropdown
                value={class_id}
                onChange={this.handleClassChange}
              />
            </span>
            <span>
              <strong>AC Year : </strong>
              <AcademicYearDropdown
                value={ac_year_id}
                onChange={this.handleACYearChange}
              />
            </span>
            <Button
              type="primary"
              onClick={this.handleSave}
              style={{ marginLeft: 16 }}
            >
              Save
            </Button>
          </Col>
        </Row>
        <Form>
          <Table columns={columns} dataSource={students} rowKey="id" pagination={false} />
        </Form>
      </Layout.Content>
    );
  }
}

export default Form.create()(VirtualClassroomAccess);
