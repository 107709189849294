import { Button, Col, Layout, message, Row, Table, Tag } from "antd";
import React, { Component } from "react";
import { api } from "../../../config";

class HomeworkDetails extends Component {
  state = {
    id: undefined,
    students: [],
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      this.setState(
        { id: this.props.match.params.id },
        this._get_student_homework_submissions
      );
    }
  }

  _get_student_homework_submissions = () => {
    let { id } = this.state;
    api
      .get(`/homework/${id}/solutions/`)
      .then((res) => {
        if (res.data.success) {
          this.setState({ students: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const { students, id } = this.state;
    const columns = [
      { title: "ID", dataIndex: "id" },
      {
        title: "Student Name",
        dataIndex: "fullname",
        sorter: (a, b) =>
          a.fullname.toLowerCase() < b.fullname.toLowerCase() ? -1 : 1,
      },
      { title: "Admission No", dataIndex: "admission_no" },
      { title: "Roll No", dataIndex: "roll_no" },
      {
        title: "Submitted",
        dataIndex: "submitted",
        render: (v) => <Tag color={v > 0 ? "green" : "red"}>Submitted {v}</Tag>,
      },
      {
        title: "Corrected",
        dataIndex: "corrected",
        render: (v) => <Tag color={v > 0 ? "green" : "red"}>Corrected {v}</Tag>,
      },
    ];
    return (
      <Layout.Content>
        <Row gutter={16}>
          <Col sm={12}>
            <h1>Homework Details</h1>
          </Col>
          <Col sm={12} className="action-buttons">
            <Button
              type="primary"
              onClick={() => this.props.history.push(`/homework/${id}`)}
            >
              View solutions
            </Button>
          </Col>
        </Row>
        <Table
          columns={columns}
          dataSource={students}
          rowKey="id"
          pagination={{ pageSize: 50 }}
        />
      </Layout.Content>
    );
  }
}

export default HomeworkDetails;
