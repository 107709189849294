import React from "react";
import { Route, Switch } from "react-router";
import SimpleFinanceDashboard from "./SimpleFinanceDashboard";

const AdminSimpleFinanceDashboardRouter = (props) => (
  <Switch>
    <Route exact path="/simple-finance/" component={SimpleFinanceDashboard} />
  </Switch>
);

export { AdminSimpleFinanceDashboardRouter };
