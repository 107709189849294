import React, { Component } from "react";
import { Modal, Form, DatePicker, Input, message } from "antd";
import { api } from "../../../../config";

class NewHoliday extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  handleSubmit = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        this.setState({ loading: true });
        api
          .put("/hr/holidays/", JSON.stringify(val))
          .then((res) => {
            if (res.data.success) {
              message.success("New Holiday Added");
              this.props.closeModal();
            } else {
              message.error(res.data.error);
              this.setState({ loading: false });
            }
          })
          .catch((err) => {
            console.log(err);
            this.setState({ loading: false });
          });
      }
    });
  };

  render() {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    return (
      <Modal
        title="New Holiday"
        visible={true}
        onCancel={() => this.props.closeModal()}
        onOk={this.handleSubmit.bind(this)}
      >
        <Form>
          <FormItem label="Date">
            {getFieldDecorator("date", {
              rules: [{ required: true, message: "Date is required" }],
            })(<DatePicker />)}
          </FormItem>
          <FormItem label="Reason">
            {getFieldDecorator("reason", {
              rules: [{ required: true, message: "Reason is required!" }],
            })(<Input />)}
          </FormItem>
        </Form>
      </Modal>
    );
  }
}

export default Form.create()(NewHoliday);
