import React, { Component } from "react";
import { Layout, notification, Button, message } from "antd";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  StudentLayout,
  GuestLayout,
  TeacherLayout,
  AdminLayout,
  LibrarianLayout,
} from "./pages/app";
import { api } from "./config";

class App extends Component {
  state = {
    loading: true,
    loggedin: false,
    collapsed: false,
    role: null,
    about_school: {},
  };

  componentDidMount() {
    document.getElementById("loading").style.display = "none";
    this.checkAutoLogin();
    this.getAboutSchool();
    this.checkLogin();
    this.show_add_to_home_screen();
    this.check_network_state();
  }

  checkAutoLogin = () => {
    const handler = (e) => {
      if (e.data.token && e.data.api_base_url) {
        window.localStorage.clear();
        window.sessionStorage.clear();
        window.sessionStorage.setItem("token", e.data.token);
        window.sessionStorage.setItem("api_base_url", e.data.api_base_url);
        window.location.replace("/");
        window.location.reload();
        window.removeEventListener("message", handler);
      }
    };
    if (window.opener) {
      window.addEventListener("message", handler);
      window.opener.postMessage({ loaded: true }, "*");
    }
  };

  check_network_state = () => {
    window.addEventListener("online", () => {
      message.destroy();
      message.info("Online");
      document.getElementById("root").className = "online";
    });

    window.addEventListener("offline", () => {
      message.warning("Check internet connection", 10);
      document.getElementById("root").className = "offline";
    });

    if (window.navigator.onLine) {
      document.getElementById("root").className = "online";
    } else {
      message.warning("Check internet connection", 10);
      document.getElementById("root").className = "offline";
    }
  };

  getAboutSchool = () => {
    api
      .get("/about/school/")
      .then((res) => {
        if (res.data && res.data.success) {
          this.props.about_school(res.data.data);
          this.setState({ about_school: res.data.data });
        } else {
          // message.error(res.data.error);
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  checkLogin = () => {
    api
      .get("/auth/?platform=web")
      .then((res) => {
        if (res.data && res.data.id) {
          this.props.authenticate(res.data);
          this.setState({ role: res.data.role });
          if (res.data.role === 1) {
            this.get_students();
          } else if (res.data.role === 2) {
            this.get_teacher_settings();
          } else if (res.data.role) {
            this.setState({
              role: res.data.role,
              loggedin: true,
              loading: false,
            });
          }
        } else {
          this.setState({
            loggedin: false,
            loading: false,
          });
        }
      })
      .catch((err) => {
        // console.log("Not Logged In");
        this.setState({ loading: false });
      });
  };

  get_students = () => {
    api
      .get("/students-list/")
      .then((res) => {
        this.props.get_students(res.data.data);
        this.setState({ loading: false, loggedin: true });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  get_teacher_settings = async () => {
    try {
      let res = await api.get("/teacher/settings/");
      if (res.data.success) {
        this.props.get_teacher_settings(res.data.data);
      }
      let teacher = await api.get("/teacher/");
      if (teacher.data.success) {
        this.props.get_teacher(teacher.data.data);
      }
    } catch (err) {
      console.log(err);
    }
    this.setState({ role: 2, loggedin: true, loading: false });
  };

  show_add_to_home_screen = () => {
    let deferredPrompt;
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      deferredPrompt = e;
      notification.open({
        message: "Add to Homescreen",
        description: (
          <Button
            type="primary"
            onClick={() => {
              deferredPrompt.prompt();
              deferredPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === "accepted") {
                  console.log("User accepted the A2HS prompt");
                } else {
                  console.log("User dismissed the A2HS prompt");
                }
                deferredPrompt = null;
              });
            }}
          >
            Click to Add
          </Button>
        ),
        placement: "bottomRight",
      });
    });
  };

  render() {
    const { about_school } = this.state;
    if (this.state.loading === true) {
      return (
        <div style={{ position: "fixed", width: "100%", height: "100%" }}>
          <div
            style={{
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <img
              src={"/images/loading.gif"}
              alt="Loading"
              style={{ transform: "scale(0.66)" }}
            />
          </div>
        </div>
      );
    } else {
      if (this.state.loggedin === false) {
        return <GuestLayout />;
      } else {
        if (this.state.role === 1) {
          return <StudentLayout />;
        } else if (this.state.role === 2) {
          return <TeacherLayout />;
        } else if (this.state.role === 3 || this.state.role === 4) {
          return (
            <AdminLayout about_school={about_school} role={this.state.role} />
          );
        } else if (this.state.role === 7) {
          return <LibrarianLayout />;
        } else {
          return (
            <Layout>
              <h1>404 Page Not Found</h1>
            </Layout>
          );
        }
      }
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    authenticate: (credentials) => {
      dispatch({ type: "AUTH", payload: credentials });
    },
    get_students: (payload) => {
      dispatch({ type: "GET_STUDENTS", payload });
    },
    about_school: (payload) => {
      dispatch({ type: "GET_ABOUT_SCHOOL", payload });
    },
    get_teacher_settings: (payload) => {
      dispatch({ type: "GET_TEACHER_SETTINGS", payload });
    },
    get_teacher: (payload) => {
      dispatch({ type: "GET_TEACHER", payload });
    },
  };
}

export default withRouter(connect(null, mapDispatchToProps)(App));
