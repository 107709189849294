import React, { Component } from "react";
import { Layout, Form, Input, Button, DatePicker, message, Spin } from "antd";
import { api } from "../../../../config";
import moment from "moment";

class DriverForm extends Component {
  state = {
    update: null,
    spinning: false,
    loading: false
  };

  componentDidMount() {
    this.changePassword();
    if (this.props.match.params.id) {
      this.get_driver_details(this.props.match.params.id);
      this.setState({ update: this.props.match.params.id });
    }
  }

  get_driver_details = id => {
    this.setState({ spinning: true });
    api
      .get("/bus/driver/" + id + "/")
      .then(res => {
        if (res.data.success) {
          let data = res.data.data;
          data.dob = moment(data.dob);
          this.props.form.setFieldsValue(data);
        } else {
          message.error(res.data.error);
        }
        this.setState({ spinning: false });
      })
      .catch(err => {
        console.log(err);
        this.setState({ spinning: false });
      });
  };

  changePassword = () => {
    let password = Math.random()
      .toString(36)
      .slice(-8);
    this.props.form.setFieldsValue({ password });
  };

  handleSubmit = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        if (this.state.update) {
          this.updateDriver(this.state.update, val);
        } else {
          this.addNewDriver(val);
        }
      }
    });
  };

  addNewDriver = data => {
    this.setState({ loading: true });
    api
      .put("/bus/drivers/", JSON.stringify(data))
      .then(res => {
        if (res.data && res.data.success) {
          message.success("New driver added");
          this.props.history.push("/transportation/drivers/");
        } else {
          message.error(res.data.error);
        }
        this.setState({ loading: false });
      })
      .catch(err => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  updateDriver = (id, data) => {
    this.setState({ loading: true });
    api
      .post("/bus/driver/" + id + "/", JSON.stringify(data))
      .then(res => {
        if (res.data.success) {
          message.success("Driver details updated");
        } else {
          message.error("Failed to update driver details");
        }
        this.setState({ loading: false });
      })
      .catch(err => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  render() {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    return (
      <Layout.Content>
        <h1 className="align-center">
          {this.state.update ? "Update Driver" : "Add Driver"}
        </h1>
        <Spin spinning={this.state.spinning}>
          <Form labelCol={{ sm: 8 }} wrapperCol={{ sm: 8 }}>
            <FormItem label="Driver's Email">
              {getFieldDecorator("email", {
                rules: [
                  { required: true, message: "Please enter email" },
                  { type: "email", message: "Invalid email address" }
                ]
              })(
                <Input
                  type="email"
                  disabled={this.state.update ? true : false}
                />
              )}
            </FormItem>
            {!this.state.update && (
              <span>
                <FormItem label="Password">
                  {getFieldDecorator("password", {
                    rules: [
                      { required: true, message: "Please enter password" }
                    ]
                  })(<Input style={{ width: "88%", marginRight: 10 }} />)}
                  <Button icon="reload" onClick={this.changePassword} />
                </FormItem>
              </span>
            )}
            <FormItem label="Driver's Name">
              {getFieldDecorator("name", {
                rules: [{ required: true, message: "Please enter driver name" }]
              })(<Input placeholder="Driver's Name" />)}
            </FormItem>
            <FormItem label="Mobile No">
              {getFieldDecorator("mobile_no", {
                rules: [
                  { required: true, message: "Please enter mobile number" },
                  {
                    pattern: "^[6-9][0-9]{9}",
                    message: "Enter valid mobile number"
                  }
                ],
                validateTrigger: ["onBlur"]
              })(<Input type="tel" placeholder="Mobile Number" />)}
            </FormItem>
            <FormItem label="Date of Birth">
              {getFieldDecorator("dob", {
                rules: [
                  { required: true, message: "Please select Date of Birth" }
                ]
              })(<DatePicker />)}
            </FormItem>
            <FormItem label="Driving License Number">
              {getFieldDecorator("dl_no", {
                rules: [{ required: true, message: "Please enter DL No." }]
              })(<Input />)}
            </FormItem>
            <FormItem wrapperCol={{ offset: 8 }}>
              <Button loading={this.state.loading} onClick={this.handleSubmit}>
                {this.state.update ? "Update Driver" : "Add Driver"}
              </Button>
            </FormItem>
          </Form>
        </Spin>
      </Layout.Content>
    );
  }
}

export default Form.create()(DriverForm);
