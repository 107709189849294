import { Select } from "antd";
import React, { Component } from "react";
import { api } from "../config";

class FeeItemDropdown extends Component {
  state = {
    fee_items: [],
  };

  componentDidMount() {
    this.getFeesItem();
  }

  getFeesItem = () => {
    api
      .get("/fees/items/")
      .then((res) => {
        if (res.data && res.data.success) {
          this.setState({ fee_items: res.data.data });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleChange = (id) => {
    this.props.onChange && this.props.onChange(id);
    if (this.props.getItem) {
      let item = this.state.fee_items.find((item) => item.id === id);
      this.props.getItem(item);
    }
  };

  render() {
    const { Option } = Select;
    return (
      <Select
        showSearch
        placeholder="Select Item"
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        style={{ width: "100%", ...(this.props.style || {}) }}
        value={this.props.value || undefined}
        onChange={this.handleChange}
        allowClear
      >
        {this.state.fee_items.map((item, index) => {
          return (
            <Option value={item.id} key={index}>
              {item.name + " - ₹" + item.amount}
            </Option>
          );
        })}
      </Select>
    );
  }
}

export default FeeItemDropdown;
