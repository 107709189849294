import {
  Button,
  Col,
  Icon,
  Layout,
  message,
  Popconfirm,
  Row,
  Table,
  Card,
} from "antd";
import React, { Component } from "react";
import { api } from "../../../../config";
import moment from "moment";
import { Bar } from "react-chartjs-2";
import { Currency } from "../../../../components";

class AccountEntries extends Component {
  state = {
    entries: [],
    db_data: {},
    graph_data: {},
  };

  componentDidMount() {
    this._get_account_entries();
    this._get_dashboard_data();
  }

  _get_account_entries = () => {
    api
      .get("/simple/accounts/")
      .then((res) => {
        if (res.data.success) {
          this.setState({ entries: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  _delete_account_entry = (id) => {
    api
      .delete(`/simple/account/${id}/`)
      .then((res) => {
        if (res.data.success) {
          message.success("Entry deleted");
          this._get_account_entries();
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  _get_dashboard_data = () => {
    api
      .get("/simple/accounts/dashboard/")
      .then((res) => {
        if (res.data.success) {
          this.setState({ db_data: res.data.data });
          this._rearrange_dashboard_graph_data(res.data.data.monthly_report);
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  _rearrange_dashboard_graph_data = (monthly_report) => {
    let labels = [],
      datasets = [],
      credit = [],
      debit = [],
      data = {};

    monthly_report.forEach((item) => {
      labels.push(moment(item.month).format("MMM YY"));
      credit.push(item.credit);
      debit.push(item.debit);
    });

    datasets.push({
      data: credit,
      label: "Credit",
      backgroundColor: "rgba(75, 192, 192, 0.4)",
      borderColor: "rgba(75, 192, 192, 1)",
      barThickness: 6,
    });
    datasets.push({
      data: debit,
      label: "Debit",
      backgroundColor: "rgba(255, 99, 132, 0.4)",
      borderColor: "rgba(255, 99, 132, 1)",
      barThickness: 6,
    });

    data = {
      labels,
      datasets,
    };

    this.setState({ graph_data: data });
  };

  render() {
    const { entries, db_data, graph_data } = this.state;
    const columns = [
      { title: "ID", dataIndex: "id" },
      { title: "Name", dataIndex: "title" },
      {
        title: "Credit",
        align: "right",
        render: (r) => (
          <span>{r.txn_type === 0 && <Currency value={r.amount} />}</span>
        ),
      },
      {
        title: "Debit",
        align: "right",
        render: (r) => (
          <span>{r.txn_type === 1 && <Currency value={r.amount} />}</span>
        ),
      },
      {
        title: "Date",
        dataIndex: "date",
        render: (v) => (
          <span>{moment.utc(v).local().format("YYYY-MM-DD")}</span>
        ),
      },
      {
        title: "Actions",
        render: (r) => (
          <span>
            <Icon
              type="edit"
              className="action-icon"
              title="Edit"
              onClick={() => this.props.history.push(`/accounts/${r.id}/`)}
            />
            <Popconfirm
              title="Are you sure?"
              onConfirm={this._delete_account_entry.bind(this, r.id)}
            >
              <Icon
                type="delete"
                className="action-icon absent"
                title="Delete"
              />
            </Popconfirm>
          </span>
        ),
      },
    ];
    return (
      <Layout.Content>
        <Row gutter={16}>
          <Col sm={12}>
            <h1>Account Entries</h1>
          </Col>
          <Col sm={12} className="action-buttons">
            <Button
              icon="plus"
              className="action-button"
              type="primary"
              onClick={() => this.props.history.push("/accounts/new")}
            >
              New Transaction Entry
            </Button>
          </Col>
        </Row>
        <Row gutter={16} type="flex" style={{ marginBottom: 16 }}>
          <Col sm={8} className="card-row">
            <Card title="Credit" className="admin-card card-elevation">
              <CardTable
                data={[
                  {
                    name: <h3>Today</h3>,
                    value: (
                      <h3>
                        <Currency value={db_data.today_credit || 0} />
                      </h3>
                    ),
                  },
                  {
                    name: "This Month",
                    value: <Currency value={db_data.month_credit || 0} />,
                  },
                  {
                    name: "Total",
                    value: <Currency value={db_data.total_credit || 0} />,
                  },
                ]}
              />
            </Card>
          </Col>
          <Col sm={8} className="card-row">
            <Card title="Debit" className="admin-card card-elevation">
              <CardTable
                data={[
                  {
                    name: <h3>Today</h3>,
                    value: (
                      <h3>
                        <Currency value={db_data.today_debit || 0} />
                      </h3>
                    ),
                  },
                  {
                    name: "This Month",
                    value: <Currency value={db_data.month_debit || 0} />,
                  },
                  {
                    name: "Total",
                    value: <Currency value={db_data.total_debit || 0} />,
                  },
                ]}
              />
            </Card>
          </Col>
          <Col sm={8} className="card-row">
            <Card title="Balance" className="admin-card card-elevation">
              <CardTable
                data={[
                  {
                    name: <h3>Total</h3>,
                    value: (
                      <h3>
                        <Currency
                          value={
                            db_data.total_credit - db_data.total_debit || 0
                          }
                        />
                      </h3>
                    ),
                  },
                ]}
              />
            </Card>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginBottom: 16 }}>
          <Col sm={24}>
            <Card title="Monthly Reports">
              <Bar
                data={graph_data}
                options={{
                  maintainAspectRatio: false,
                  scales: {
                    yAxes: [
                      {
                        ticks: { beginAtZero: true },
                        scaleLabel: { display: true, labelString: "Amount" },
                      },
                    ],
                    xAxes: [
                      { scaleLabel: { display: true, labelString: "Date" } },
                    ],
                  },
                }}
                height={400}
              />
            </Card>
          </Col>
        </Row>
        <Card title="Account Entries">
          <Table columns={columns} dataSource={entries} rowKey="id" />
        </Card>
      </Layout.Content>
    );
  }
}

export default AccountEntries;

const CardTable = (props) => (
  <div>
    <table style={{ width: "100%" }}>
      <tbody>
        {props.data &&
          props.data.map((item, index) => {
            return (
              <tr key={index}>
                <td style={{ width: "80%" }}>{item.name}</td>
                <td style={{ width: "20%", textAlign: "right" }}>
                  {item.value}
                </td>
              </tr>
            );
          })}
      </tbody>
    </table>
  </div>
);
