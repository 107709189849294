const userReducer = (state = {}, action) => {
  switch (action.type) {
    case "LOGIN":
      return { ...state, ...action.payload };
    case "LOGOUT":
      return null;
    case "AUTH":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default userReducer;
