import React, { Component } from "react";
import { Layout, Row, Col, Button, Table, message, Icon } from "antd";
import { Link } from "react-router-dom";
import { api } from "../../../../config";

class RoutesList extends Component {
  state = {
    routes: []
  };

  componentDidMount() {
    this.get_routes_list();
  }

  get_routes_list = () => {
    api
      .get("/bus/routes/")
      .then(res => {
        if (res.data && res.data.success) {
          this.setState({ routes: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  render() {
    const columns = [
      { title: "ID", dataIndex: "id" },
      { title: "Name", dataIndex: "name" },
      { title: "Source", dataIndex: "src" },
      { title: "Destination", dataIndex: "dest" },
      { title: "Driver", dataIndex: "driver" },
      { title: "Vehicle", dataIndex: "vehicle" },
      {
        title: "Actions",
        render: record => (
          <span>
            <Icon
              type="edit"
              className="action-icon"
              onClick={() => {
                this.props.history.push(
                  "/transportation/route/" + record.id + "/"
                );
              }}
            />
            <Icon
              type="form"
              className="action-icon"
              onClick={() => {
                this.props.history.push(
                  "/transportation/routes/stops/" + record.id
                );
              }}
            />
            <Icon
              type="ordered-list"
              className="action-icon"
              onClick={() => {
                this.props.history.push(
                  "/transportation/students/" + record.id
                );
              }}
            />
          </span>
        )
      }
    ];
    return (
      <Layout.Content>
        <Row>
          <Col sm={24} lg={12}>
            <h1>Routes List</h1>
          </Col>
          <Col sm={24} lg={12} style={{ textAlign: "right" }}>
            <Link to="/transportation/routes/new">
              <Button icon="plus" type="primary">
                Add Bus Route
              </Button>
            </Link>
          </Col>
        </Row>
        <Table columns={columns} dataSource={this.state.routes} rowKey="id" />
      </Layout.Content>
    );
  }
}

export default RoutesList;
