import React from "react";
import { Switch, Route } from "react-router-dom";
import StudentDashboard from "./StudentDashboard";

const StudentDashboardRouter = props => (
  <Switch>
    <Route exact path="/" component={StudentDashboard} />
    <Route exact path="/" component={StudentDashboard} />
  </Switch>
);

export { StudentDashboardRouter };
