import { Button, Layout, Result, Steps } from "antd";
import React, { Component } from "react";
import ParentDetails from "./ParentDetails";
import StudentDetails from "./StudentDetails";
import StudentProfile from "./StudentProfile";

class AddStudentPage extends Component {
  state = {
    current: 0,
    student_id: undefined,
  };

  _set_page = (page_no) => {
    this.setState({ current: page_no });
  };

  _set_student_id = (student_id) => {
    this.setState({ student_id });
  };

  render() {
    const { Step } = Steps;
    const { current, student_id } = this.state;
    return (
      <Layout.Content>
        <Steps progressDot current={current}>
          <Step title="Student Details" />
          <Step title="Student Profile" />
          <Step title="Father Details" />
          <Step title="Mother Details" />
          <Step title="Finish" />
        </Steps>
        <div
          style={{
            marginTop: 16,
            border: "1px dashed #e9e9e9",
            borderRadius: 6,
            backgroundColor: "#fafafa",
            minHeight: 200,
            paddingTop: 40,
          }}
        >
          {current === 0 && (
            <StudentDetails current={current} set_page={this._set_page} />
          )}
          {current === 1 && (
            <StudentProfile
              current={current}
              student_id={student_id}
              set_page={this._set_page}
            />
          )}
          {current === 2 && (
            <ParentDetails
              current={current}
              student_id={student_id}
              relation="Father"
              set_page={this._set_page}
            />
          )}
          {current === 3 && (
            <ParentDetails
              current={current}
              student_id={student_id}
              relation="Mother"
              set_page={this._set_page}
            />
          )}
          {current === 4 && (
            <Result
              status="success"
              title="Student Added"
              extra={[
                <Button>Student Details</Button>,
                <Button>Pay Fees</Button>,
                <Button>Students List</Button>,
              ]}
            />
          )}
        </div>
      </Layout.Content>
    );
  }
}

export default AddStudentPage;
