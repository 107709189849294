import React from "react";
import { Modal } from "antd";
import { baseurl } from "../config";

const HomeworkViewer = (props) => {
  const files = JSON.parse(props.homework.files);
  return (
    <Modal title="Homework" visible={props.visible} onCancel={props.closeModal}>
      <div className="homework-viewer">
        <h1>{props.homework.title}</h1>
        <div className="homework-files-list">
          <p>Date: {props.homework.timestamp}</p>
          <p>Submission Date: {props.homework.submission_date}</p>
          <p>Subject: {props.homework.subject}</p>
        </div>
        <p className="homework-description">{props.homework.description}</p>
        {files.length > 0 && (
          <span>
            <h4>Files</h4>
            <ul>
              {files.map((item, index) => {
                return (
                  <li>
                    {item.name ? (
                      <a href={item.url} target="_blank" rel="noopener noreferrer">
                        {item.name}
                      </a>
                    ) : (
                      <a href={baseurl + "/" + item} target="_blank" rel="noopener noreferrer">
                        {"Attachment - " + index + 1}
                      </a>
                    )}
                  </li>
                );
              })}
            </ul>
          </span>
        )}
      </div>
    </Modal>
  );
};

export { HomeworkViewer };
