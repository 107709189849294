import React from "react";
import { Switch, Route } from "react-router-dom";
import SimpleTestsList from "./SimpleTestsList";
import SimpleTestDetails from "./SimpleTestDetails";
import SimpleTestPage from "./SimpleTestPage";

const StudentSimpleTestRouter = (props) => (
  <Switch>
    <Route exact path="/simple-test/" component={SimpleTestsList} />
    <Route exact path="/simple-test/test/" component={SimpleTestPage} />
    <Route exact path="/simple-test/:id" component={SimpleTestDetails} />
  </Switch>
);

export { StudentSimpleTestRouter };
