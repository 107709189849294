import React, { Component } from "react";
import {
  Layout,
  Form,
  Select,
  Input,
  Icon,
  Button,
  InputNumber,
  message,
} from "antd";
import { api } from "../../../config";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 8 },
    xl: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 8 },
    xl: { span: 8 },
  },
};
const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 24, offset: 0 },
    md: { span: 8, offset: 8 },
    xl: { span: 8, offset: 8 },
  },
};

class QuestionForm extends Component {
  state = {
    options_count: 4,
    id: null,
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      this.setState({ id: this.props.match.params.id });
    }
  }

  removeOption = (index) => {
    if (this.state.options_count === 1) return;
    const { form } = this.props;
    const options = form.getFieldValue("options");
    form.setFieldsValue({
      options: options.slice(0, index).concat(options.slice(index + 1)),
    });
    this.setState({ options_count: this.state.options_count - 1 });
  };

  addOption = () => {
    this.setState({ options_count: this.state.options_count + 1 });
  };

  handleSave = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        let { id } = this.state;
        api
          .put("/exam/" + id + "/questions/", JSON.stringify(val))
          .then((res) => {
            if (res.data.success) {
              message.success("Question Added");
              this.props.history.push("/exam/questions/" + id + "/");
            } else {
              message.error(res.data.error);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const FormItem = Form.Item;
    const { Option } = Select;
    const { options_count } = this.state;
    return (
      <Layout.Content>
        <h1 className="align-center">Question Form</h1>
        <Form {...formItemLayout}>
          <FormItem label="Question Type">
            {getFieldDecorator("question_type", {
              rules: [{ required: true, message: "This field is required!" }],
            })(
              <Select>
                <Option value={1}>MCQ</Option>
                {/* <Option value={2}>Subjective</Option> */}
              </Select>
            )}
          </FormItem>
          <FormItem label="Question">
            {getFieldDecorator("question", {
              rules: [{ required: true, message: "This field is required!" }],
            })(<Input.TextArea />)}
          </FormItem>

          {[...Array(options_count).keys()].map((item, index) => {
            return (
              <FormItem
                label={index === 0 ? "Options" : ""}
                {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                key={index}
              >
                <div style={{ display: "flex" }}>
                  <div style={{ paddingRight: 10 }}>({index + 1})</div>
                  <div style={{ flex: 1 }}>
                    {getFieldDecorator(`options[${item}]`, {
                      rules: [
                        { required: true, message: "This field is required!" },
                      ],
                    })(<Input />)}
                  </div>
                  <div style={{ paddingLeft: 10 }}>
                    <Icon
                      type="minus-circle"
                      style={{ fontSize: 18 }}
                      onClick={this.removeOption.bind(this, index)}
                    />
                  </div>
                </div>
              </FormItem>
            );
          })}
          <FormItem {...formItemLayoutWithOutLabel}>
            <Button type="dashed" icon="plus" onClick={this.addOption}>
              Add Option
            </Button>
          </FormItem>
          <FormItem label="Answer">
            {getFieldDecorator("answer", {
              rules: [{ required: true, message: "This field is required!" }],
            })(
              <Select>
                {getFieldValue("options").map((item, index) => {
                  return (
                    <Option value={index} key={index}>
                      {item}
                    </Option>
                  );
                })}
              </Select>
            )}
          </FormItem>
          <FormItem label="Explanation">
            {getFieldDecorator("explanation", {
              rules: [{ required: true, message: "This field is required!" }],
            })(<Input.TextArea />)}
          </FormItem>
          <FormItem label="Marks for correct answer">
            {getFieldDecorator("correct_marks", {
              rules: [{ required: true, message: "This field is required!" }],
            })(<InputNumber />)}
          </FormItem>
          <FormItem label="Marks for incorrect answer">
            {getFieldDecorator("incorrect_marks", {
              rules: [{ required: true, message: "This field is required!" }],
            })(<InputNumber />)}
          </FormItem>
          <FormItem label="Marks for no answer">
            {getFieldDecorator("no_answer_marks", { initialValue: 0.0 })(
              <InputNumber />
            )}
          </FormItem>
          <FormItem {...formItemLayoutWithOutLabel}>
            <Button type="primary" onClick={this.handleSave}>
              Save
            </Button>
          </FormItem>
        </Form>
      </Layout.Content>
    );
  }
}

export default Form.create()(QuestionForm);
